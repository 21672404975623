<div *ngIf="review" class="d-flex flex-column review-box border border-5 py-3">
  <div>{{ review.firstName ? review.firstName : anonymous }}</div>
  <div>
    <app-star-rating [rate]="review.rating"></app-star-rating>
    <span class="font-weight-bold pl-2">{{ review.reviewHeadline }}</span>
  </div>
  <div>
    {{ truncateText(review.reviewText) }}
  </div>
  <a class="align-self-end" i18n (click)="openFullReviewModal()">View</a>
</div>
<app-product-review-modal
  #productReviewModal
  [productDetailsItem]="productDetailsItem"
  [review]="review"
  [readOnly]="true"
></app-product-review-modal>
