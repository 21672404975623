import { Component, Input, ViewChild } from '@angular/core';
import { ProductReview } from '@core/store/product-review/product-review-state-models';
import { ProductDetailsItem } from '@core/store/product/product-state-models';
import { ProductReviewModalComponent } from '@product/components/product-review-modal/product-review-modal.component';

@Component({
  selector: 'app-product-review-box',
  templateUrl: './product-review-box.component.html',
  styleUrls: ['./product-review-box.component.scss'],
})
export class ProductReviewBoxComponent {
  @Input() review: ProductReview;
  @Input() productDetailsItem: ProductDetailsItem;
  readonly numberOfWordsToShow = 15;
  readonly anonymous = $localize`Anonymous`;
  @ViewChild('productReviewModal')
  private productReviewModal: ProductReviewModalComponent;

  constructor() {}

  openFullReviewModal() {
    this.productReviewModal.openModal();
  }

  truncateText(text: string): string {
    const words = text.split(' ');
    if (words.length <= this.numberOfWordsToShow) {
      return words.join(' ');
    }
    let truncatedText = words.slice(0, this.numberOfWordsToShow).join(' ');
    truncatedText = truncatedText + ' ...';
    return truncatedText;
  }
}
