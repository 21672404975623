import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  GetProductDetailsResponse,
  GetProductQuickViewResponse,
  ProductRecommendationsResponse,
  RecipeProductRecommendationsResponse,
} from '@core/dto/product-details.dto';
import { GetProductsResponse } from '@core/dto/product.dto';
import { Locale } from '@core/enums/locale';
import { LoadProductsProps } from '@core/models/load-products.model';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  baseUrl: string = environment.ecommerceBackendUri;
  culture: Locale;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {
    this.culture = getCulture(localeId);
  }

  fetchProducts(payload: LoadProductsProps): Observable<GetProductsResponse> {
    const date = moment.utc();
    return this.http.get<GetProductsResponse>(this.baseUrl + 'api/product', {
      params: new HttpParams({
        fromObject: {
          pageNumber: payload.pageNumber.toString(),
          pageSize: payload.pageSize.toString(),
          date: date.toString(),
          catalogUrlName: payload.catalogUrlName,
          culture: this.culture,
        },
      }),
    });
  }

  fetchProductDetails(sku: string, date: string = null): Observable<GetProductDetailsResponse> {
    return this.http.get<GetProductDetailsResponse>(this.baseUrl + 'api/product/' + sku, {
      params: new HttpParams({
        fromObject: { date, culture: this.culture },
      }),
    });
  }

  fetchProductQuickView(sku: string): Observable<GetProductQuickViewResponse> {
    return this.http.get<GetProductQuickViewResponse>(`${this.baseUrl}api/product/minimal/${sku}`, {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }

  fetchProductRecommendations(
    sku: string,
    date: string = null,
  ): Observable<ProductRecommendationsResponse> {
    return this.http.get<ProductRecommendationsResponse>(
      `${this.baseUrl}api/product/recommendation`,
      { params: new HttpParams({ fromObject: { sku, date, culture: this.culture } }) },
    );
  }

  fetchRelatedProducts(recipeUrl: string): Observable<RecipeProductRecommendationsResponse> {
    return this.http.get<RecipeProductRecommendationsResponse>(
      `${this.baseUrl}api/recipe/${recipeUrl}/relatedproducts`,
      {
        params: new HttpParams({
          fromObject: { culture: this.culture },
        }),
      },
    );
  }

  fetchMoreProductFromCollection(
    catalogUrlName: string,
    date: string = null,
  ): Observable<ProductRecommendationsResponse> {
    return this.http.get<ProductRecommendationsResponse>(
      `${this.baseUrl}api/product/recommendation/collection`,
      {
        params: new HttpParams({
          fromObject: { catalogUrlName, date, culture: this.culture },
        }),
      },
    );
  }

  fetchEcommerceCustomerSpecialOffers(date: string): Observable<GetProductsResponse> {
    return this.http.get<GetProductsResponse>(`${this.baseUrl}api/promotion/ecommerce`, {
      params: new HttpParams({
        fromObject: {
          date,
          culture: this.culture,
        },
      }),
    });
  }

  fetchHealthySavingsCustomerSpecialOffers(date: string): Observable<GetProductsResponse> {
    return this.http.get<GetProductsResponse>(`${this.baseUrl}api/promotion/healthySavings`, {
      params: new HttpParams({
        fromObject: {
          date,
          culture: this.culture,
        },
      }),
    });
  }

  fetchCollectAndSaveCustomerSpecialOffers(date: string): Observable<GetProductsResponse> {
    return this.http.get<GetProductsResponse>(`${this.baseUrl}api/promotion/collectAndSave`, {
      params: new HttpParams({
        fromObject: {
          date,
          culture: this.culture,
        },
      }),
    });
  }

  fetchRegularCustomerSpecialOffers(date: string): Observable<GetProductsResponse> {
    return this.http.get<GetProductsResponse>(
      `${this.baseUrl}api/promotion/regularCustomerSpecial`,
      {
        params: new HttpParams({
          fromObject: {
            date,
            culture: this.culture,
          },
        }),
      },
    );
  }
}
