import { SearchResultApiPageSize } from '@core/constants/search';
import produce from 'immer';
import { SearchState } from './search-state-models';
import { SearchActionTypes } from './search.actions';

export const initialState: SearchState = {
  loading: false,
  count: 0,
  documents: [],
  facets: [],
  isAllFetched: false,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case SearchActionTypes.resetSearchState:
      return initialState;

    case SearchActionTypes.fetchSearchResult:
      draft.loading = true;
      if (action.isFirstPage) {
        draft.documents = initialState.documents;
        draft.isAllFetched = initialState.isAllFetched;
      }
      return;

    case SearchActionTypes.fetchSearchResultSuccess:
      draft.loading = false;
      draft.count = action.count;
      draft.documents = [...draft.documents, ...action.documents];
      draft.isAllFetched = action.documents.length < SearchResultApiPageSize;
      if (!action.keepFacets) {
        draft.facets = action.facets;
      }
      return;

    case SearchActionTypes.fetchSearchResultFailure:
      draft.loading = false;
      return;

    default:
      return draft;
  }
}, initialState);
