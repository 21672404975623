import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { RelatedCarouselSlide } from '@core/models/related-carousel-slide.model';
import { ProductUtilService } from '@shared/utils/product-util.service';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-related-carousel',
  templateUrl: './related-carousel.component.html',
  styleUrls: ['./related-carousel.component.scss'],
})
export class RelatedCarouselComponent implements OnDestroy {
  @Input() title: string;
  @Input() slides$: Observable<RelatedCarouselSlide[]>;
  @ViewChild('sliderRef') set sliderRef(element: ElementRef<HTMLElement>) {
    element && this.initSlider(element);
  }

  dragging: boolean = false;
  createUrlName = this.productUtil.createProductDetailsUrl;
  thumbnail = Thumbnail;

  slider: KeenSliderInstance = null;
  currentSlide: number = 0;
  dotHelper: Array<Number> = [];

  constructor(private router: Router, private productUtil: ProductUtilService) {}

  showDots(): boolean {
    return (
      this.dotHelper.length > this.slider?.options.slides['perView'] &&
      this.slider?.options.slides['perView'] === 1
    );
  }

  showArrows(): boolean {
    return this.slider?.options.slides['perView'] === 3;
  }

  navigateTo(url: string) {
    !this.dragging && this.router.navigateByUrl(url);
  }

  beforeChange() {
    this.dragging = true;
  }

  afterChange() {
    this.dragging = false;
  }

  trackBy(slide: any) {
    return slide.targetUrl;
  }

  ngOnDestroy(): void {
    if (this.slider) this.slider.destroy();
  }

  private initSlider(sliderRef) {
    this.slider = new KeenSlider(sliderRef.nativeElement, {
      initial: this.currentSlide,
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel;
      },
      breakpoints: {
        '(min-width: 768px)': {
          slides: { perView: 3, spacing: 5 },
        },
      },
      slides: { perView: 1, spacing: 5 },
    });
    this.dotHelper = [...Array(this.slider.slides.length).keys()];
  }
}
