<div class="d-flex flex-column align-items-center justify-content-center">
  <a
    (click)="changeStepId()"
    class="dot mb-1"
    [ngClass]="{
      active: step.active,
      completed: step.completed,
      touched: step.touched,
      disabled: step.disabled
    }"
  >
    <ng-container *ngIf="step.completed; else stepId">
      <i class="bi bi-check"></i>
    </ng-container>
    <ng-template #stepId>
      <span>{{ step.id }}</span>
    </ng-template>
  </a>
  <span
    class="text-uppercase"
    [ngClass]="{
      'font-weight-bold': step.active || step.touched || step.completed
    }"
    >{{ step.title }}</span
  >
</div>
