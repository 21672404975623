import { AfterViewInit, Component, Host, Input, Optional, SkipSelf } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { getValidationErrorMessageList, validInput } from '@shared/utils/validation.utils';

export interface SelectOption {
  name: string;
  value: any;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true,
    },
  ],
})
export class SelectComponent implements ControlValueAccessor, AfterViewInit {
  @Input() identifier: string;
  @Input() label: string;
  @Input() formErrorMessages: { [key: string]: string } = FormErrorMessages;
  @Input() formControlName: string;
  @Input() options: SelectOption[] | string[];
  @Input() labelStyle: Record<string, unknown> = {};
  @Input() defaultValue: any = $localize`Select`;
  @Input() value: string;
  @Input() readonly: boolean = false;

  disabled = false;
  control: AbstractControl;

  getErrorMessages = getValidationErrorMessageList;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  ngAfterViewInit(): void {
    this.control = this.controlContainer?.control.get(this.formControlName);
  }

  onChange: (value: string) => void = () => {};

  onTouched = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  isValueEmpty() {
    return !this.value;
  }

  isMatchingWithValue(option: any) {
    return option === this.value;
  }

  onSelectChange(value: string) {
    this.value = value;
    this.onChange(value);
  }

  get isValidInput(): boolean {
    if (this.control) {
      return validInput(this.control);
    }
    return true;
  }

  get errorMessages(): string[] {
    return getValidationErrorMessageList(this.control, this.formErrorMessages);
  }
}
