<div class="container px-0">
  <div class="d-flex justify-content-between align-items-center">
    <app-account-header i18n-title title="My Wishlist"></app-account-header>
  </div>

  <div *ngIf="loading$ | async; else content" class="d-flex justify-content-center m-5">
    <app-ph-spinner width="60px"></app-ph-spinner>
  </div>

  <ng-template #content>
    <div class="d-md-none">
      <ng-container *ngFor="let wishlistItem of computedRows$ | async">
        <div class="row p-2 background-gray">
          <div class="col-4 p-0 d-flex align-items-center">
            <img
              class="align-self-center w-100"
              phImage
              [images]="wishlistItem.images"
              [thumbnailDesktop]="thumbnail.productCheckoutDesktop"
              [thumbnailMobile]="thumbnail.productCheckoutMobile"
            />
          </div>
          <div class="col-8">
            <div class="row">
              <div class="col">
                <div>
                  <div class="category-name">{{ wishlistItem.collectionName }}</div>
                  <div class="product-name">{{ wishlistItem.productName | titlecase }}</div>
                  <div class="sku mb-2">#{{ wishlistItem.sku }}</div>
                </div>
              </div>
              <div class="col-auto px-0 d-flex justify-content-end">
                <a class="pointer">
                  <i class="bi bi-trash" (click)="removeFromWishList(wishlistItem.sku)"></i>
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button
                  (click)="addToCart(wishlistItem)"
                  class="btn btn-primary btn-block px-1"
                  *ngIf="!!wishlistItem.discountedPrice"
                >
                  Add to Cart
                </button>
              </div>
              <div
                class="
                  col-4
                  total-price
                  pr-0
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-end
                "
              >
                <div
                  class="text-black-50 line-through font-size-smaller"
                  *ngIf="
                    !!wishlistItem.discountedPrice &&
                    wishlistItem.discountedPrice !== wishlistItem.originalPrice
                  "
                >
                  ${{ wishlistItem.originalPrice }}
                </div>

                <div
                  [ngClass]="{
                    'discounted-price':
                      !!wishlistItem.discountedPrice &&
                      wishlistItem.discountedPrice !== wishlistItem.originalPrice
                  }"
                  *ngIf="!!wishlistItem.discountedPrice"
                >
                  ${{ wishlistItem.discountedPrice }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="d-none d-md-flex">
      <table class="desktop table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              class="text-uppercase"
              (click)="onSort(wishlistColumnType.productName)"
              role="button"
            >
              <span i18n>product</span>
              <i
                *ngIf="sortColumn === wishlistColumnType.productName"
                [ngClass]="{
                  bi: true,
                  'bi-caret-up-fill': direction === 'asc',
                  'bi-caret-down-fill': direction === 'desc'
                }"
              ></i>
            </th>
            <th
              scope="col"
              class="text-uppercase pr-5"
              (click)="onSort(wishlistColumnType.discountedPrice)"
              role="button"
            >
              <span i18n>price</span>
              <i
                *ngIf="sortColumn === wishlistColumnType.discountedPrice"
                [ngClass]="{
                  bi: true,
                  'bi-caret-up-fill': direction === 'asc',
                  'bi-caret-down-fill': direction === 'desc'
                }"
              ></i>
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let wishlistItem of computedRows$ | async">
            <td>
              <div class="row">
                <div class="col d-flex align-items-center">
                  <a [routerLink]="'/product/p/' + wishlistItem.sku">
                    <img
                      class="img-thumbnail"
                      phImage
                      [images]="wishlistItem.images"
                      [thumbnailDesktop]="thumbnail.productCheckoutDesktop"
                      [thumbnailMobile]="thumbnail.productCheckoutMobile"
                    />
                  </a>
                </div>
                <div class="col d-flex flex-column align-self-center">
                  <div class="category-name">{{ wishlistItem.collectionName }}</div>
                  <div class="product-name font-weight-bold">{{
                    wishlistItem.productName | titlecase
                  }}</div>
                  <div class="sku font-weight-bold">#{{ wishlistItem.sku }}</div>
                </div>
              </div>
            </td>
            <td class="position-relative">
              <div class="px-0 d-flex flex-column price">
                <div
                  class="text-black-50 line-through font-size-smaller"
                  *ngIf="
                    !!wishlistItem.discountedPrice &&
                    wishlistItem.discountedPrice !== wishlistItem.originalPrice
                  "
                >
                  {{ wishlistItem.originalPrice | phCurrency }}
                </div>
                <p
                  [ngClass]="{
                    'mb-0': true,
                    'discounted-price':
                      !!wishlistItem.discountedPrice &&
                      wishlistItem.discountedPrice !== wishlistItem.originalPrice
                  }"
                  *ngIf="!!wishlistItem.discountedPrice"
                >
                  {{ wishlistItem.discountedPrice | phCurrency }}
                </p>
              </div>
            </td>
            <td>
              <button
                *ngIf="!!wishlistItem.discountedPrice"
                [disabled]="addToCartLoading$ | async"
                (click)="addToCart(wishlistItem)"
                class="btn btn-primary"
              >
                <i class="bi bi-plus-circle pr-2"></i>
                <span i18n>Add to Cart</span>
              </button>
            </td>
            <td>
              <a class="pointer font-size-bigger mr-3">
                <i class="bi bi-trash" (click)="removeFromWishList(wishlistItem.sku)"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</div>

<app-add-to-cart-confirmation></app-add-to-cart-confirmation>
