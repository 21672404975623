import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { isMexEnv } from '@shared/utils/environment-utils';
import { SavedCardInfo } from './saved-card-info.model';

@Component({
  selector: 'app-saved-card',
  templateUrl: './saved-card.component.html',
  styleUrls: ['./saved-card.component.scss'],
})
export class SavedCardComponent implements OnChanges {
  @Input() cardTypeDisplayValue: string;
  @Input() cardInfo: SavedCardInfo;
  @Input() canChangeSavedCard: boolean = true;
  @Output() resetPayment: EventEmitter<void> = new EventEmitter();

  public isMexEnv = isMexEnv;

  public accountCardHolderFullName = '';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardInfo?.currentValue) {
      if (this.cardInfo.account) {
        this.accountCardHolderFullName =
          this.cardInfo.account.firstName + ' ' + this.cardInfo.account.lastName;
      }
    }
  }

  resetPaymentClick() {
    this.resetPayment.emit();
  }
}
