<ng-container>
  <div class="row">
    <app-product-list-image
      [images]="product.primaryImages"
      [alt]="product.collection + ' ' + product.name + ' ' + altSuffix"
      [sku]="product.sku"
      [onOpenQuickView]="onOpenQuickView"
      [navigateTo]="productDetailsUrl"
      [isWishlisted]="isWishlisted"
      [toggleWish]="toggleWish"
    >
    </app-product-list-image>
  </div>
  <div class="row mt-2 product-info">
    <div class="col-12 px-0">
      <div class="text-left product-title">
        <a [routerLink]="productDetailsUrl" class="text-decoration-none text-black">
          {{ product.name }}
        </a>
      </div>
    </div>
    <div class="col-12 px-0 text-left font-italic">
      <span class="text-sku">{{ product.sku }}</span>
    </div>
    <div class="col-12 px-0 mb-1">
      <app-product-price-message [product]="product"></app-product-price-message>
    </div>
    <div class="col-12 px-0">
      <div class="row">
        <div class="col-12 col-md-5 px-0 d-flex align-items-center price">
          <app-product-price [product]="product"></app-product-price>
        </div>
        <div
          *ngIf="productUtil.getAddToCartButtonVisibility$(product) | async"
          class="col-12 col-md-7 px-0 d-lt-none d-flex align-items-end"
        >
          <button
            [disabled]="addToCartLoading$ | async"
            type="button"
            class="btn btn-primary w-100 text-uppercase d-none d-md-block"
            (click)="addToCart()"
            i18n
          >
            Add to cart
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
