import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalConfig } from '@core/models/modal-config.model';
import { AppState } from '@core/store';
import { selectIsRegisteredUserWithoutLogin, selectLoginModal } from '@core/store/checkout';
import { resetLoginModal, userContinuesWithoutLogin } from '@core/store/checkout/checkout.actions';
import { Store } from '@ngrx/store';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Observable, Subscription } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit, OnDestroy {
  @Input() isAuthenticated$: Observable<boolean>;
  @Input() email: string;

  modalConfig: ModalConfig;

  private canOpen: boolean = true;

  private subscriptions: Subscription = new Subscription();

  constructor(private store$: Store<AppState>) {}

  @ViewChild('modal') private loginModalComponent: ModalComponent;

  ngOnInit(): void {
    this.modalConfig = {
      title: $localize`You have an active registration`,
      hideCloseButton: true,
      hideDismissButton: true,
      onDismiss: this.closeModal.bind(this),
    };

    this.subscriptions.add(
      this.store$
        .select(selectLoginModal)
        .pipe(
          withLatestFrom(this.isAuthenticated$),
          withLatestFrom(this.store$.select(selectIsRegisteredUserWithoutLogin)),
          map(([[login, isAuthenticated], loginCancelled]) => ({
            login,
            isAuthenticated,
            loginCancelled,
          })),
          filter(({ login }) => login?.isRegistered),
          filter(({ isAuthenticated }) => !isAuthenticated),
          filter(({ loginCancelled }) => !loginCancelled),
        )
        .subscribe(() => {
          this.openModal();
        }),
    );

    this.subscriptions.add(
      this.isAuthenticated$.pipe(filter((auth) => auth)).subscribe(() => this.closeModal()),
    );
  }

  ngOnDestroy(): void {
    this.store$.dispatch(resetLoginModal());
    this.subscriptions.unsubscribe();
  }

  openModal(): void {
    if (this.canOpen) {
      this.loginModalComponent.open();
    }
  }

  closeModal() {
    this.store$.dispatch(resetLoginModal());
    this.store$.dispatch(userContinuesWithoutLogin());
    this.loginModalComponent?.close();
  }

  continueWithoutLogin() {
    this.closeModal();
    this.canOpen = false;
  }
}
