<div class="desktop">
  <div class="head py-1 text-uppercase font-weight-bold" i18n>Name</div>
  <div class="head py-1 text-uppercase font-weight-bold" i18n>Shipping date</div>
  <div class="head py-1 text-uppercase font-weight-bold" i18n>Weight</div>
  <div class="head py-1 text-uppercase font-weight-bold" i18n>Tracking</div>
  <div class="head py-1"></div>
  <ng-container *ngIf="cartons$ | async as cartons">
    <ng-container class="carton" *ngFor="let carton of cartons; let i = index">
      <div class="cell py-1 pl-1">{{ carton.name }}</div>
      <div class="cell py-1">{{ carton.shippingDate | date: 'MM/dd/YYYY' }}</div>
      <div class="cell py-1">{{ carton.weight | number }}<span i18n>lb</span></div>
      <div class="cell py-1">
        <div *ngIf="carton.trackingUrl">
          <a href="{{ carton.trackingUrl }}" target="_blank" class="tracking-url">
            {{ carton.trackingNumber }} - {{ CarrierName[carton.carrier] }}
          </a>
        </div>
        <div *ngIf="!carton.trackingUrl">
          {{ carton.trackingNumber }}
        </div>
      </div>
      <a
        class="cell py-1 pr-1 pointer text-right font-size-smaller show-link"
        (click)="collapseState[carton.id] = !collapseState[carton.id]"
      >
        {{ getCollapseText(carton.id) }}
      </a>
      <div class="carton-items" #collapse="ngbCollapse" [(ngbCollapse)]="!collapseState[carton.id]">
        <app-carton-items [cartonItems]="carton.cartonItems"></app-carton-items>
      </div>
      <ng-container *ngIf="cartons.length > 1 && i < cartons.length - 1">
        <div *ngFor="let i of [].constructor(5)" class="pt-1"></div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
<hr class="w-100 mt-1 d-md-block d-none" />

<div class="mobile">
  <div class="mb-2" *ngFor="let carton of cartons$ | async">
    <div class="carton">
      <div class="cell text-uppercase text-double-dot" i18n>Name</div>
      <div class="cell font-weight-bold text-right">{{ carton.name }}</div>
      <div class="cell text-uppercase text-double-dot" i18n>Shipping date</div>
      <div class="cell font-weight-bold text-right">{{
        carton.shippingDate | date: 'MM/dd/YYYY'
      }}</div>

      <div class="cell text-uppercase text-double-dot" i18n>Weight</div>
      <div class="cell font-weight-bold text-right">{{ carton.weight }}<span i18n>lb</span></div>

      <div class="cell text-uppercase text-double-dot" i18n>Tracking</div>
      <div class="cell font-weight-bold text-right" *ngIf="carton.trackingUrl">
        <a href="{{ carton.trackingUrl }}" target="_blank" class="tracking-url">
          {{ carton.trackingNumber }} - {{ CarrierName[carton.carrier] }}
        </a>
      </div>
      <div class="cell font-weight-bold text-right" *ngIf="!carton.trackingUrl">
        {{ carton.trackingNumber }}
      </div>
      <div
        class="carton-items px-3 pt-3"
        #collapse="ngbCollapse"
        [(ngbCollapse)]="!collapseState[carton.id]"
      >
        <div class="carton-details p-2 mb-3" *ngFor="let item of carton.cartonItems">
          <div class="pt-2 pl-2 title">{{ item.name }}</div>
          <div class="pt-2 pl-2">#{{ item.sku }}</div>
          <div class="py-2 pl-2">
            <span class="qty"> {{ item.qty }}X</span>
          </div>
        </div>
      </div>
      <a
        class="py-1 pointer text-right font-size-smaller"
        (click)="collapseState[carton.id] = !collapseState[carton.id]"
      >
        {{ getCollapseText(carton.id) }}
      </a>
    </div>
  </div>
</div>
