import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BlogDetailsResponse, BlogsResponse } from '@core/dto/blog.dto';
import { BlogCategoriesResponse } from '@core/dto/tag.dto';
import { Locale } from '@core/enums/locale';
import { LoadBlogsProps } from '@core/models/load-blogs.model';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  baseUrl: string = environment.ecommerceBackendUri;
  culture: Locale;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {
    this.culture = getCulture(localeId);
  }

  fetchBlogs(payload: LoadBlogsProps) {
    return this.http.get<BlogsResponse>(this.baseUrl + 'api/blog', {
      params: new HttpParams({
        fromObject: {
          pageNumber: payload.pageNumber.toString(),
          pageSize: payload.pageSize.toString(),
          categoryUrl: payload.categoryUrl,
          ...(payload.sortType ? { sortType: String(payload.sortType) } : {}),
          culture: this.culture,
        },
      }),
    });
  }

  fetchBlogCategories(): Observable<BlogCategoriesResponse> {
    return this.http.get<BlogCategoriesResponse>(this.baseUrl + 'api/blog/categories', {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }

  fetchBlogDetails(id: string): Observable<BlogDetailsResponse> {
    return this.http.get<BlogDetailsResponse>(`${this.baseUrl}api/blog/${id}`, {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }
}
