import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { findConsultantResultPageSize } from '@core/constants/find-consultant';
import { CountryStateResponse } from '@core/dto/country-state.dto';
import {
  AddressVerificationRequest,
  ClientTokenRequest,
  ClientTokenResponse,
  ConfigurationResponse,
  ConsultantDto,
  CreateOrderRequest,
  CreateOrderResponse,
  CreatePaymentMethodRequest,
  CreatePaymentMethodResponse,
  CreateVoucherRequest,
  CreateVoucherResponse,
  EmailUniquenessCheckResponse,
  FindConsultantByAreaRequest,
  FindConsultantByCodeRequest,
  FindConsultantByZipCodeRequest,
  FindConsultantByZipCodeResponse,
  FindConsultantResponse,
  GetReCaptchaResponse,
  MetaConsultantResponse,
  OrderSummaryRequest,
  OrderSummaryResponse,
  PhoneNumberValidationRequest,
  PhoneNumberValidationResponse,
  ReceiveVouchersRequest,
  ReceiveVouchersResponse,
  SaveAddressInfoRequest,
  SaveContactInfoRequest,
  SaveLogCardAttemptRequest,
  SaveSsnNumberRequest,
  SaveStarterKitRequest,
  SaveUserInfoResponse,
  StarterKitResponse,
  SubmitConsultantRequest,
  SubmitConsultantResponse,
  UpdateOrderRequest,
  UpdateOrderResponse,
  ValidateRegistrationCodeRequest,
  ValidateRegistrationCodeResponse,
  VerifyAddressResponse,
} from '@core/dto/start-now-app.dto';
import { LanguagePreferenceType } from '@core/enums/language-preference-type.enum';
import { Locale } from '@core/enums/locale';
import { RequestSource } from '@core/enums/request-source.enum';
import { UserInfo } from '@core/store/start-now-app/start-now-app-state-models';
import { environment } from '@env';
import { mapUserInfoToSubmitUserInfoParamsDto } from '@shared/utils/create-submit-consultant-info-utils';
import { HtmlParamEncoder } from '@shared/utils/http-param-encoder';
import { getCulture } from '@shared/utils/locale-utils';
import { removeNilProperties } from '@shared/utils/object-utils';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

const agreementLocaleMapper = {
  en: '"EnUs"',
  'en-US': '"EnUs"',
  'es-US': '"EsUs"',
  'es-MX': '"EsMx"',
};
const snaLanguageLocaleMapper = {
  en: 'EnUs',
  'en-US': 'EnUs',
  'es-US': 'EsUs',
  'es-MX': 'EsMx',
};

@Injectable({
  providedIn: 'root',
})
export class StartNowAppService {
  baseUrl: string = environment.startNowAppBackendUri;
  culture: Locale;
  agreementLanguage: string;
  snaLanguageCode: string;

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private localeId: Locale,
  ) {
    this.culture = getCulture(localeId);
    this.agreementLanguage = agreementLocaleMapper[this.culture];
    this.snaLanguageCode = snaLanguageLocaleMapper[this.culture];
  }

  validateReCaptcha(token: string): Observable<GetReCaptchaResponse> {
    return this.http.get<GetReCaptchaResponse>(`${this.baseUrl}api/Consultant/googlerecaptcha`, {
      params: new HttpParams({
        fromObject: {
          token,
        },
      }),
    });
  }

  fetchStartNowAppConfiguration(): Observable<ConfigurationResponse> {
    return this.http.get<ConfigurationResponse>(`${this.baseUrl}api/Configuration`);
  }

  findConsultant(request: FindConsultantByAreaRequest): Observable<FindConsultantResponse> {
    return this.http.get<FindConsultantResponse>(`${this.baseUrl}api/Consultant/find`, {
      params: new HttpParams({
        fromObject: {
          city: request.city,
          pageNumber: request.pageNumber?.toString() || '1',
          pageSize: request.pageSize?.toString() || findConsultantResultPageSize.toString(),
          firstName: request.firstName,
          lastName: request.lastName,
          stateCode: request.state,
          mobilePhoneNumber: request.mobilePhoneNumber,
          country: request.country,
          requestSource: RequestSource.sna,
        },
      }),
    });
  }

  findConsultantByConsultantCode(request: FindConsultantByCodeRequest): Observable<ConsultantDto> {
    return this.http.get<ConsultantDto>(`${this.baseUrl}api/Consultant/vanityname`, {
      params: new HttpParams({
        encoder: new HtmlParamEncoder(),
        fromObject: {
          ...removeNilProperties(request),
          languageCode: this.culture,
        },
      }),
    });
  }

  findConsultantByZipCode(
    request: FindConsultantByZipCodeRequest,
  ): Observable<FindConsultantByZipCodeResponse> {
    return this.http.get<FindConsultantByZipCodeResponse>(
      `${this.baseUrl}api/Consultant/nearby/${request.zipCode}`,
      {
        params: new HttpParams({
          fromObject: {
            preferredLanguageCode: request.preferredLanguageCode
              ? request.preferredLanguageCode
              : this.culture,
            country: environment.country,
            pageNumber: request.pageNumber.toString(),
            pageSize: request.pageSize.toString(),
            requestSource: RequestSource.sna,
          },
        }),
      },
    );
  }

  validateRegistrationCode(
    request: ValidateRegistrationCodeRequest,
  ): Observable<ValidateRegistrationCodeResponse> {
    return this.http.post<ValidateRegistrationCodeResponse>(
      `${this.baseUrl}api/Consultant/validateregistrationcode`,
      request,
    );
  }

  getAgreementPdf(): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    const body = this.agreementLanguage;

    return this.http
      .post(`${this.baseUrl}api/Export/PrintAgreements`, body, {
        headers: headers,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        take(1),
        map((response) => response.body),
      );
  }

  saveUserInfo(
    userInfo: UserInfo,
    applicationRegistrationId: number,
    securityKey: string,
  ): Observable<SaveUserInfoResponse> {
    return this.http.post<SaveUserInfoResponse>(`${this.baseUrl}api/SubmitConsultant/userinfo`, {
      ...mapUserInfoToSubmitUserInfoParamsDto(userInfo),
      applicationRegistrationId,
      securityKey,
    });
  }

  saveContactInfo(request: SaveContactInfoRequest): Observable<MetaConsultantResponse> {
    return this.http.post<MetaConsultantResponse>(
      `${this.baseUrl}api/SubmitConsultant/contactinfo`,
      request,
    );
  }

  saveAddressInfo(request: SaveAddressInfoRequest): Observable<MetaConsultantResponse> {
    return this.http.post<MetaConsultantResponse>(
      `${this.baseUrl}api/SubmitConsultant/addressinfo`,
      request,
    );
  }

  saveStarterKit(request: SaveStarterKitRequest): Observable<MetaConsultantResponse> {
    return this.http.post<MetaConsultantResponse>(
      `${this.baseUrl}api/SubmitConsultant/starterkit`,
      request,
    );
  }

  emailUniquenessCheck(email: string): Observable<EmailUniquenessCheckResponse> {
    return this.http.post<EmailUniquenessCheckResponse>(
      `${this.baseUrl}api/Consultant/ValidateEmail`,
      {
        email: email,
      },
    );
  }

  fetchStarterKits(preferredLanguage: LanguagePreferenceType): Observable<StarterKitResponse> {
    return this.http.get<StarterKitResponse>(`${this.baseUrl}api/StarterKit`, {
      params: new HttpParams({
        fromObject: {
          languageCode: this.snaLanguageCode,
          starterKitLanguageCode: snaLanguageLocaleMapper[preferredLanguage],
        },
      }),
    });
  }

  logCardAttempt(request: SaveLogCardAttemptRequest): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/Payment/LogCardAttempt`, request);
  }

  /**
   * Check if new payment required because with back/forth UI navigation can occur invalid payment & starter kit
   * combinations. In case of US there is the 25% advance option and in Mexico case there are multiple starter kits
   */
  checkIfNewPaymentRequired(
    applicationRegistrationId: number,
    starterKitId: number,
    isDepositPayment: boolean,
    isReducedDepositPayment: boolean,
    isZeroDepositPayment: boolean,
  ): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}api/Payment/IsNewPaymentRequired`, {
      params: new HttpParams({
        fromObject: {
          applicationRegistrationId: applicationRegistrationId.toString(),
          starterKitId: starterKitId.toString(),
          isDepositPayment: String(isDepositPayment),
          isReducedDepositPayment: String(isReducedDepositPayment),
          isZeroDepositPayment: String(isZeroDepositPayment),
        },
      }),
    });
  }

  fetchDepositPaymentValue(): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}api/Payment/DepositPaymentValue`);
  }

  fetchReducedDepositPaymentValue(): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}api/Payment/ReducedDepositPaymentValue`);
  }

  fetchIsReducedDepositAvailable(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}api/starterkit/isReducedDepositPaymentAvailable`);
  }

  fetchIsZeroDepositAvailable(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}api/starterkit/isZeroDepositPaymentAvailable`);
  }

  fetchStates(): Observable<CountryStateResponse[]> {
    return this.http.get<CountryStateResponse[]>(`${this.baseUrl}api/Address/states`);
  }

  fetchClientToken(request: ClientTokenRequest): Observable<ClientTokenResponse> {
    return this.http.post<ClientTokenResponse>(`${this.baseUrl}api/Payment/clienttoken`, {
      ...request,
      languageCode: this.snaLanguageCode,
    });
  }

  createOrder(request: CreateOrderRequest): Observable<CreateOrderResponse> {
    return this.http.post<CreateOrderResponse>(`${this.baseUrl}api/Payment/createOrder`, request);
  }

  updateOrder(request: UpdateOrderRequest): Observable<UpdateOrderResponse> {
    return this.http.post<UpdateOrderResponse>(`${this.baseUrl}api/Payment/updateOrder`, request);
  }

  createPaymentMethod(
    request: CreatePaymentMethodRequest,
  ): Observable<CreatePaymentMethodResponse> {
    return this.http.post<CreatePaymentMethodResponse>(`${this.baseUrl}api/Payment`, {
      ...request,
    });
  }

  createVoucher(request: CreateVoucherRequest): Observable<CreateVoucherResponse> {
    return this.http.post<CreateVoucherResponse>(
      `${this.baseUrl}api/Payment/CreateVoucher`,
      request,
    );
  }

  receiveVouchers(request: ReceiveVouchersRequest): Observable<ReceiveVouchersResponse> {
    return this.http.post<ReceiveVouchersResponse>(
      `${this.baseUrl}api/Payment/ReceiveVouchers`,
      request,
    );
  }

  verifyAddress(request: AddressVerificationRequest): Observable<VerifyAddressResponse> {
    return this.http.post<VerifyAddressResponse>(`${this.baseUrl}api/Address/verify`, request);
  }

  /** In some cases the consultants in the SNA db are not synced with the ones from UserManagement. From this cause
   * before Voucher activation, we need to check it, otherwise the vouchers will be created and sent out,
   * but the consultant submission could fail! */
  checkIfCanSubmitConsultant(
    request: SubmitConsultantRequest,
  ): Observable<SubmitConsultantResponse> {
    return this.http.post<SubmitConsultantResponse>(
      `${this.baseUrl}api/SubmitConsultant/CanSubmitConsultant`,
      {
        ...request,
      },
    );
  }

  submitConsultant(request: SubmitConsultantRequest): Observable<SubmitConsultantResponse> {
    return this.http.post<SubmitConsultantResponse>(`${this.baseUrl}api/SubmitConsultant`, {
      ...request,
    });
  }

  saveSsnNumber(request: SaveSsnNumberRequest): Observable<MetaConsultantResponse> {
    return this.http.post<MetaConsultantResponse>(
      `${this.baseUrl}api/SubmitConsultant/governmentid`,
      request,
    );
  }

  fetchVoucherPdf(applicationRegistrationId: number): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http
      .post(`${this.baseUrl}api/Export/PrintVoucher`, applicationRegistrationId, {
        headers: headers,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        take(1),
        map((response) => response.body),
      );
  }

  fetchVoucherBarCodeUrls(applicationRegistrationId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseUrl}api/Payment/VoucherBarcodeUrls`, {
      params: new HttpParams({
        fromObject: {
          applicationRegistrationId: applicationRegistrationId.toString(),
        },
      }),
    });
  }

  fetchOrderSummary(request: OrderSummaryRequest): Observable<OrderSummaryResponse> {
    return this.http.post<OrderSummaryResponse>(
      `${this.baseUrl}api/Payment/GetOrderPaymentSummary`,
      request,
    );
  }

  checkPhoneNumberUniqueness(
    request: PhoneNumberValidationRequest,
  ): Observable<PhoneNumberValidationResponse> {
    return this.http.post<PhoneNumberValidationResponse>(
      `${this.baseUrl}api/Consultant/ValidatePhoneNumber`,
      request,
    );
  }
}
