import { Injectable } from '@angular/core';
import { ProductReviewService } from '@core/services/product-review.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { convertToLocalizedDate } from '@shared/utils/localize-date-utils';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { selectProductReviewsState } from '.';
import { AppState } from '..';
import * as productReviewActions from './product-review.action';
@Injectable()
export class ProductReviewEffects {
  fetchProductReviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productReviewActions.fetchProductReviews),
      mergeMap(({ payload }) =>
        this.productReviewService.fetchProductReviews({ ...payload, pageNumber: 1 }).pipe(
          map((res) =>
            productReviewActions.fetchProductReviewsSuccess({
              productReviews: res.productReviews.map((review) => ({
                ...review,
                date: convertToLocalizedDate(review.date),
              })),
              pageSize: payload.pageSize,
            }),
          ),
          catchError(() => of(productReviewActions.fetchProductReviewsFailure())),
        ),
      ),
    ),
  );

  fetchMoreProductReviews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productReviewActions.fetchMoreProductReviews),
      concatLatestFrom(() => this.store$.select(selectProductReviewsState)),
      filter(([, productReviewsState]) => !productReviewsState?.isAllFetched),
      mergeMap(([{ payload }, productReviewsState]) =>
        this.productReviewService
          .fetchProductReviews({
            ...payload,
            pageNumber: Math.ceil(productReviewsState.productReviews.length / payload.pageSize) + 1,
          })
          .pipe(
            map((res) =>
              productReviewActions.fetchProductReviewsSuccess({
                productReviews: res.productReviews.map((review) => ({
                  ...review,
                  date: convertToLocalizedDate(review.date),
                })),
                pageSize: payload.pageSize,
              }),
            ),
            catchError(() => of(productReviewActions.fetchProductReviewsFailure())),
          ),
      ),
    ),
  );

  fetchProductReviewsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productReviewActions.fetchProductReviewsFailure),
        tap(() => {
          this.toastr.error($localize`Failed to fetch review`, $localize`Review fetching error`);
        }),
      ),
    { dispatch: false },
  );

  createProductReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productReviewActions.createProductReview),
      mergeMap(({ request }) =>
        this.productReviewService.createProductReview(request).pipe(
          map((productReview) =>
            productReviewActions.createProductReviewSuccess({
              productReview: {
                ...productReview,
                date: convertToLocalizedDate(productReview.date),
              },
            }),
          ),
          catchError(() => of(productReviewActions.createProductReviewFailure())),
        ),
      ),
    ),
  );

  createProductReviewSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productReviewActions.createProductReviewSuccess),
        tap(() => {
          this.toastr.success($localize`Review added`, $localize`Success`);
        }),
      ),
    { dispatch: false },
  );

  createProductReviewFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productReviewActions.createProductReviewFailure),
        tap(() => {
          this.toastr.error($localize`Failed to create review`, $localize`Create review error`);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private productReviewService: ProductReviewService,
    private store$: Store<AppState>,
  ) {}
}
