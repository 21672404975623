import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppState } from '@core/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectCart } from '@core/store/cart';

@Injectable({
  providedIn: 'root',
})
export class EmptyCartGuardService implements CanActivate {
  constructor(private store$: Store<AppState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store$.select(selectCart).pipe(
      filter((cart) => !!cart),
      map(({ orderLines }) => {
        if (!orderLines.length) {
          this.router.navigateByUrl('/');
        }
        return !!orderLines.length;
      }),
    );
  }
}
