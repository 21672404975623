<div
  class="container"
  *ngIf="(isLoading$ | async) === false && (cartonDetails$ | async) as cartonDetails; else loading"
>
  <div *ngIf="!cartonDetailsError; else error">
    <h4 i18n>Carton Information Page</h4>
    <hr />
    <h5 i18n class="text-primary">Master Order ID</h5>
    <p>{{ cartonDetails.masterOrderId }}</p>
    <hr />
    <h5 i18n *ngIf="cartonDetails.backorderItems?.length > 0">Unshipped Items</h5>
    <app-carton-table
      *ngIf="cartonDetails.backorderItems?.length > 0"
      [cartonItems]="cartonDetails.backorderItems"
    ></app-carton-table>

    <div class="mt-5">
      <div *ngFor="let carton of cartonDetails.shippedCartons">
        <h4 class="mt-4" i18n
          ><span class="rubine-red">Carton</span> {{ carton.cartonNumber }} of
          {{ cartonDetails.shippedCartons.length }}</h4
        >
        <hr />
        <p class="mb-0"
          ><span i18n class="text-primary">Receiver's Name: </span>{{ carton.receiverName }}</p
        >
        <p class="mb-0"
          ><span i18n class="text-primary">Shipping Date: </span
          >{{ carton.shippingDate | date: 'longDate' }}</p
        >
        <p class="mb-0"
          ><span i18n class="text-primary">Tracking Number: </span
          ><a rel="stylesheet" [href]="carton.trackingUrl"
            >{{ carton.trackingNumber
            }}<span i18n *ngIf="carton.carrier"> - Shipped via {{ carton.carrier }}</span></a
          ></p
        >
        <app-carton-table
          [heading]="'Carton ' + carton.cartonNumber + ' contains:'"
          [cartonItems]="carton.skus"
        ></app-carton-table>
      </div>
    </div>

    <table class="table packing-list">
      <thead
        ><tr><td i18n colspan="2">Packing List Documents</td></tr></thead
      >
      <tbody>
        <tr><th i18n>Packing List#</th><th i18n>Packing List Link</th></tr>
        <tr *ngFor="let doc of cartonDetails.pickingSlipDocuments">
          <td>{{ doc.pickSlipNumber }}</td>
          <td><a [href]="doc.pdfUrl" i18n>Packing List Document</a></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #loading>
  <div class="container text-center">
    <img src="assets/images/misc/phLoading.gif" alt="loading" i18n-alt />
  </div>
</ng-template>

<ng-template #error>
  <p i18n>Failed to find delivery information for order with Master Order ID: {{ orderId }}</p>
</ng-template>
