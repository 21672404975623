import { Component } from '@angular/core';
import { EcAppSettings } from '@core/models/app-settings.model';
import { AppInitService } from '@core/services/app-init.service';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@env';
import { isMexEnv } from '@shared/utils/environment-utils';
import { ProductUtilService } from '@shared/utils/product-util.service';
import { UserUtilService } from '@shared/utils/user-util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ph-ecommerce-ui';
  feature = environment.feature;
  appSettings: EcAppSettings;
  isMexEnv = isMexEnv;
  isIframe = window.self !== window.top;

  constructor(
    appInitService: AppInitService,
    authService: AuthService,
    public productUtilService: ProductUtilService,
    public userUtilService: UserUtilService,
  ) {
    this.appSettings = appInitService.Settings.ec;
    authService.setActiveAccount();
  }

  onActivate(): void {
    window.scrollTo(0, 0);
  }
}
