/* eslint-disable @typescript-eslint/naming-convention, max-len */
export const phErrorTitles = {
  ProductNotFoundException: $localize`Product fetching error`,
  ProductNotInCollectionException: $localize`Product fetching error`,
  StateNotFoundException: $localize`State not found`,
  PrimaryImageNotFoundException: $localize`Product fetching error`,
  PriceNotFoundException: $localize`Product fetching error`,
  OrderLineNotFoundException: $localize`OrderLine not found.`,
  MismatchCustomerException: $localize`Mismatching customers. Customer of the address is not the authorized user.`,
  DefaultProductRelationTypeNotFoundException: $localize`Product fetching error`,
  CustomerNotFoundException: $localize`Customer not found`,
  CountryNotFoundException: $localize`Country not found`,
  CategoryNotFoundException: $localize`Product fetching error`,
  AddressNotFoundException: $localize`Address not found`,
  AddressNameNotUniqueException: $localize`Name is not unique`,
  WishListOrderStatusNotFoundException: $localize`Wishlist error`,
  OrderSubmitTransactionFailedException: $localize`Payment failure. Transaction failed.`,
  OrderSubmitCvvDoesNotMatchException: $localize`Payment failure. CVV does not match.`,
  OrderSubmitZipDoesNotMatchException: $localize`Payment failure. Zip does not match.`,
  PaymentTransactionFailedException: $localize`Payment failure. Transaction failed.`,
  PaymentTransactionFailedNoneException: $localize`Payment failure. Transaction failed.`,
  PaymentTransactionFailedSoftException: $localize`Payment failure. Transaction failed.`,
  PaymentTransactionFailedHardException: $localize`Payment failure. Transaction failed.`,
  PaymentTransactionFailedUnrecognizedException: $localize`Payment failure. Transaction failed.`,
  PaymentMethodCreationFailedException: $localize`Payment failure. Transaction failed.`,
  PipelineException: $localize`Unknown error`,
};
