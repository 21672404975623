export function deleteContactInfo(contactInfo) {
  delete contactInfo.email;
  delete contactInfo.emailConfirm;
  delete contactInfo.homeNumber;
  delete contactInfo.cellPhoneNumber;
  delete contactInfo.phoneNumber;
  return contactInfo;
}

export function deleteAddressesDetails(addresses) {
  return addresses.map((address) => deleteAddressDetails(address));
}

export function deleteAddressDetails(address) {
  delete address.address1;
  delete address.address2;
  delete address.address3;
  delete address.addressLine1;
  delete address.addressLine2;
  delete address.addressLine3;
  delete address.city;
  delete address.address;
  return address;
}
