<form [formGroup]="form">
  <div class="row">
    <div class="col-12 mb-2">
      <h3>{{ isCreditOrDebitCard ? billingAddressTexts.cardTitle : billingAddressTexts.title }}</h3>
    </div>
    <div class="col-12 form-group">
      <div class="form-check">
        <input
          id="same-as-shipping-address"
          class="form-check-input"
          type="checkbox"
          [formControlName]="BillingAddressFormKeys.sameAsShippingAddress"
          (change)="toggleSameAsShippingFlag($event.target.checked)"
        />
        <label class="form-check-label" for="same-as-shipping-address" i18n
          >Billing address is the same as shipping address</label
        >
      </div>
    </div>
    <div class="col-12 form-group">
      <app-text-field-input
        #firstNameInput
        [identifier]="BillingAddressFormKeys.firstName"
        [label]="
          isCreditOrDebitCard
            ? billingAddressTexts.cardFirstNameLabel
            : billingAddressTexts.firstNameLabel
        "
        placeholder="Your first name goes here"
        i18n-placeholder
        [formControlName]="BillingAddressFormKeys.firstName"
        [isDataPrivate]="true"
      ></app-text-field-input>
    </div>
    <div class="col-12 form-group">
      <app-text-field-input
        #lastNameInput
        [identifier]="BillingAddressFormKeys.lastName"
        [label]="
          isCreditOrDebitCard
            ? billingAddressTexts.cardLastNameLabel
            : billingAddressTexts.lastNameLabel
        "
        placeholder="Your last name goes here"
        i18n-placeholder
        [formControlName]="BillingAddressFormKeys.lastName"
        [isDataPrivate]="true"
      ></app-text-field-input>
    </div>
    <ng-container [formGroupName]="BillingAddressFormKeys.address">
      <div class="col-12 form-group">
        <app-text-field-input
          [identifier]="BillingAddressFormKeys.addressLine1"
          label="Address Line 1"
          i18n-label
          placeholder="Address line 1 goes here"
          i18n-placeholder
          [formControlName]="BillingAddressFormKeys.addressLine1"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>
      <div class="col-12 form-group">
        <app-text-field-input
          [identifier]="BillingAddressFormKeys.addressLine2"
          label="Address Line 2"
          i18n-label
          placeholder="Address line 2 goes here"
          i18n-placeholder
          [formControlName]="BillingAddressFormKeys.addressLine2"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>
      <div class="col-12 form-group" *ngIf="isMexEnv">
        <app-text-field-input
          [identifier]="BillingAddressFormKeys.addressLine3"
          label="Address Line 3"
          i18n-label
          placeholder="Address line 3 goes here"
          i18n-placeholder
          [formControlName]="BillingAddressFormKeys.addressLine3"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>
      <div class="col-12 form-group">
        <app-select
          [identifier]="BillingAddressFormKeys.state"
          label="State"
          i18n-label
          [formControlName]="BillingAddressFormKeys.state"
          [options]="countryStates$ | async"
        ></app-select>
      </div>
      <div class="col-6 form-group">
        <app-text-field-input
          [identifier]="BillingAddressFormKeys.city"
          label="City"
          i18n-label
          placeholder="City goes here"
          i18n-placeholder
          [formControlName]="BillingAddressFormKeys.city"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>
      <div class="col-6 form-group">
        <app-text-field-input
          [identifier]="BillingAddressFormKeys.zipCode"
          label="Zip Code"
          i18n-label
          placeholder="Zip code goes here"
          i18n-placeholder
          [formControlName]="BillingAddressFormKeys.zipCode"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>
    </ng-container>
  </div>
</form>
