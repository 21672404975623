import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { OrderStatusLocale } from '@core/constants/shipment-status-locale';
import { TableSortDirection } from '@core/enums/table-sort-direction.enum';
import { SortableTableColumn } from '@core/models/sortable-table-column.model';
import { AppState } from '@core/store';
import { selectCCOrderHistory } from '@core/store/order';
import { CCOrderHistoryItem } from '@core/store/order/order-state-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-cc-order-history',
  templateUrl: './cc-order-history.component.html',
  styleUrls: ['./cc-order-history.component.scss'],
})
export class CCOrderHistoryComponent implements OnInit {
  @Input() actionIcon: string = 'bi bi-eye';
  @Input() actionText: string = $localize`Details`;
  @Input() actionField: string = 'id';
  @Input() enableActionMethod: boolean = false;
  @Output() actionMethod: EventEmitter<any> = new EventEmitter<any>();

  ccOrderHistory$: Observable<CCOrderHistoryItem[]>;
  ccOrderHistoryTableData$: Observable<object[]>;
  readonly OrderStatusLocale = OrderStatusLocale;
  readonly ActionPath = 'cc/';

  columns: SortableTableColumn[];

  constructor(private store$: Store<AppState>, @Inject(LOCALE_ID) private localeId: string) {}

  ngOnInit(): void {
    this.initColumns();

    this.ccOrderHistory$ = this.store$.select(selectCCOrderHistory);
    this.ccOrderHistoryTableData$ = this.store$.select(selectCCOrderHistory).pipe(
      map((ccOrderHistory) =>
        ccOrderHistory.map((item) => ({
          orderNumber: item.poeOrderId,
          createdDate: formatDate(item.dateReceived, 'longDate', this.localeId),
          orderStatus: this.OrderStatusLocale[item.ecommerceStatus],
          total: +item.totalMerchCost.toFixed(2),
          id: item.poeOrderId,
        })),
      ),
    );
  }

  emitAction(value: any) {
    this.actionMethod.emit(value);
  }

  private initColumns() {
    this.columns = [
      { field: 'orderNumber', headerName: $localize`Order number`, sortable: true },
      {
        field: 'createdDate',
        headerName: $localize`Date`,
        sortable: true,
        isDate: true,
        sort: TableSortDirection.desc,
      },
      { field: 'orderStatus', headerName: $localize`Status`, sortable: true },
      { field: 'total', headerName: $localize`Total`, sortable: true, currency: '$' },
      {
        field: this.actionField,
        headerName: '',
        action: true,
        actionIcon: this.actionIcon,
        actionPath: this.ActionPath,
        ariaLabel: $localize`View order`,
        ...(this.enableActionMethod ? { actionMethod: this.emitAction.bind(this) } : {}),
      },
    ];
  }
}
