import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VoucherState } from './voucher-state-models';

export const voucherStateFeatureKey = 'voucherState';
export const selectVoucherStateFeature =
  createFeatureSelector<VoucherState>(voucherStateFeatureKey);

export const selectVoucherStateLoading = createSelector(
  selectVoucherStateFeature,
  (state: VoucherState) => (state ? state.loading : false),
);

export const selectVouchers = createSelector(selectVoucherStateFeature, (state: VoucherState) =>
  state ? state.vouchers : [],
);

export const selectVoucherEmail = createSelector(
  selectVoucherStateFeature,
  (state: VoucherState) => (state ? state.email : null),
);

export const selectVoucherRetryCounter = createSelector(
  selectVoucherStateFeature,
  (state: VoucherState) => (state ? state.retryCounter : 0),
);

export const selectCreateVoucherError = createSelector(
  selectVoucherStateFeature,
  (state: VoucherState) => (state ? state.error : null),
);

export const selectVoucherPrevalidation = createSelector(
  selectVoucherStateFeature,
  (state: VoucherState) =>
    state
      ? { prevalidated: state.prevalidated, error: state.error }
      : { prevalidated: false, error: null },
);
