<div class="saved-voucher-container col-10">
  <div>
    <div class="row">
      <div class="col-8 saved-voucher-header">
        <span i18n>
          Saved Voucher
          <i class="bi bi-check"></i>
        </span>
      </div>
      <div class="col-4 change-voucher" *ngIf="!hideChange" (click)="resetPaymentClick()">
        <span i18n>Change</span>
      </div>
    </div>

    <div class="voucher-details" *ngIf="email">
      <section class="row">
        <div class="col-10">
          <div class="form-group">
            <label for="saved-voucher-email" i18n>Email address</label>
            <input
              id="saved-voucher-email"
              type="text"
              readonly
              [ngModel]="email"
              name="email"
              data-private
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
