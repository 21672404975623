import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ConsultantRoutes } from '@core/constants/consultant-routes';
import { AppState } from '@core/store';
import { selectCurrentConsultantParties, selectParty } from '@core/store/consultant';
import { Party } from '@core/store/consultant/consultant-state-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LeavePartyPopupComponent } from './leave-party-popup/leave-party-popup.component';

@Component({
  selector: 'app-party-header',
  templateUrl: './party-header.component.html',
  styleUrls: ['./party-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartyHeaderComponent implements OnInit {
  party$: Observable<Party>;
  openPartyExists$: Observable<boolean>;

  myOpenPartiesRoute = ConsultantRoutes.MyOpenPartiesRoute;

  @ViewChild('leavePopup') private leavePopup: LeavePartyPopupComponent;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.party$ = this.store$.select(selectParty);
    this.openPartyExists$ = this.store$
      .select(selectCurrentConsultantParties)
      .pipe(map((parties) => !!parties?.length));
  }

  openLeavePopup() {
    this.leavePopup.open();
  }
}
