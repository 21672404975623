/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { TableSortDirectionCycle } from '@core/constants/table-sort-direction-cycle';
import { TableSortDirection } from '@core/enums/table-sort-direction.enum';

export type SortColumn = string | '';

export interface SortEvent {
  column: SortColumn;
  direction: TableSortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'changeDirection()',
  },
})
export class SortableTableHeaderDirective {
  @Input() sortable: SortColumn = '';
  @Input() direction: TableSortDirection = TableSortDirection.none;
  @Output() sort = new EventEmitter<SortEvent>();

  changeDirection() {
    this.direction = TableSortDirectionCycle[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}
