import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output } from '@angular/core';
import { OrderStatusLocale } from '@core/constants/shipment-status-locale';
import { TableSortDirection } from '@core/enums/table-sort-direction.enum';
import { SortableTableColumn } from '@core/models/sortable-table-column.model';
import { AppState } from '@core/store';
import { selectOrderHistory, selectOrderStateLoading } from '@core/store/order';
import { OrderHistoryItem } from '@core/store/order/order-state-models';
import { fetchOrderHistory } from '@core/store/order/order.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
})
export class OrderHistoryComponent implements OnInit {
  @Input() actionIcon: string = 'bi bi-eye';
  @Input() actionText: string = $localize`Details`;
  @Input() actionField: string = 'id';
  @Input() enableActionMethod: boolean = false;
  @Output() actionMethod: EventEmitter<any> = new EventEmitter<any>();

  orderHistory$: Observable<OrderHistoryItem[]>;
  orderHistoryTableData$: Observable<object[]>;
  loading$: Observable<boolean>;
  readonly orderStatusLocale = OrderStatusLocale;

  columns: SortableTableColumn[];

  constructor(private store$: Store<AppState>, @Inject(LOCALE_ID) private localeId: string) {}

  ngOnInit(): void {
    this.initColumns();
    this.store$.dispatch(fetchOrderHistory());

    this.orderHistory$ = this.store$.select(selectOrderHistory);
    this.orderHistoryTableData$ = this.store$.select(selectOrderHistory).pipe(
      map((orderHistory) =>
        orderHistory.map((item) => ({
          orderNumber: item.orderNumber,
          createdDate: formatDate(item.createdDate, 'longDate', this.localeId),
          orderStatus: this.orderStatusLocale[item.orderStatus],
          total: +item.total.toFixed(2),
          id: item.orderId,
        })),
      ),
    );
    this.loading$ = this.store$.select(selectOrderStateLoading);
  }

  emitAction(value: any) {
    this.actionMethod.emit(value);
  }

  private initColumns() {
    this.columns = [
      { field: 'orderNumber', headerName: $localize`Order number`, sortable: true },
      {
        field: 'createdDate',
        headerName: $localize`Date`,
        sortable: true,
        isDate: true,
        sort: TableSortDirection.desc,
      },
      { field: 'orderStatus', headerName: $localize`Status`, sortable: true },
      { field: 'total', headerName: $localize`Total`, sortable: true, currency: '$' },
      {
        field: this.actionField,
        headerName: '',
        action: true,
        actionIcon: this.actionIcon,
        ariaLabel: $localize`View order`,
        ...(this.enableActionMethod ? { actionMethod: this.emitAction.bind(this) } : {}),
      },
    ];
  }
}
