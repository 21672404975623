export const Months: string[] = [
  $localize`January`,
  $localize`February`,
  $localize`March`,
  $localize`April`,
  $localize`May`,
  $localize`June`,
  $localize`July`,
  $localize`August`,
  $localize`September`,
  $localize`October`,
  $localize`November`,
  $localize`December`,
];
