<div class="container">
  <app-breadcrumb-wrapper>
    <li i18n class="breadcrumb-item active" aria-current="page">Products</li>
  </app-breadcrumb-wrapper>
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeCatalogType">
    <li ngbNavItem="category">
      <a ngbNavLink class="text-center" (click)="onCatalogTypeChange()" i18n>
        Browse by category
      </a>
      <div *ngIf="activeCatalogType === CatalogType.Category" class="arrow-down center"></div>
      <ng-template ngbNavContent>
        <div
          infinite-scroll
          [infiniteScrollThrottle]="100"
          [infiniteScrollDistance]="0.3"
          (scrolled)="onScrollDown()"
        >
          <div class="row">
            <div class="col-6 col-md-4" *ngFor="let catalog of catalogs$ | async">
              <app-product-catalog-item
                [catalog]="catalog"
                [catalogType]="activeCatalogType"
              ></app-product-catalog-item>
            </div>
          </div>
          <div class="row">
            <div *ngIf="loading$ | async" class="col-12 d-flex justify-content-center">
              <app-ph-spinner width="100px"></app-ph-spinner>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="collection">
      <a ngbNavLink class="text-center" (click)="onCatalogTypeChange()" i18n
        >Browse by collection</a
      >
      <div *ngIf="activeCatalogType === CatalogType.Collection" class="arrow-down center"></div>
      <ng-template ngbNavContent>
        <div
          infinite-scroll
          [infiniteScrollThrottle]="100"
          [infiniteScrollDistance]="0.3"
          (scrolled)="onScrollDown()"
        >
          <div class="row">
            <div class="col-6 col-md-4" *ngFor="let catalog of catalogs$ | async">
              <app-product-catalog-item
                [catalog]="catalog"
                [catalogType]="activeCatalogType"
              ></app-product-catalog-item>
            </div>
          </div>
          <div class="row">
            <div *ngIf="loading$ | async" class="col-12 d-flex justify-content-center">
              <app-ph-spinner width="100px"></app-ph-spinner>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-3"> </div>
</div>
