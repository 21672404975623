import { Injectable } from '@angular/core';
import { FooterlinkService } from '@core/services/footerlink.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as footerActions from './footer.actions';

@Injectable()
export class FooterEffects {
  fetchPrimaryFooterLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(footerActions.fetchPrimaryFooterLinks),
      mergeMap(() =>
        this.footerLinksService.fetchPrimaryLinks().pipe(
          map((links) => footerActions.fetchPrimaryFooterLinksSuccess({ links })),
          catchError((error) => of(footerActions.fetchFooterLinksError({ error }))),
        ),
      ),
    ),
  );

  fetchSecondaryFooterLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(footerActions.fetchSecondaryFooterLinks),
      mergeMap(() =>
        this.footerLinksService.fetchSecondaryLinks().pipe(
          map((links) => footerActions.fetchSecondaryFooterLinksSuccess({ links })),
          catchError((error) => of(footerActions.fetchFooterLinksError({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private footerLinksService: FooterlinkService) {}
}
