import { Locale } from '@core/enums/locale';

export function getCulture(localeId: Locale): Locale {
  if (localeId.split('-')[0] === Locale.En) {
    return Locale.En;
  }

  return localeId;
}

export function getLanguage(localeId: Locale): string {
  return localeId.split('-')[0];
}
