import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CartModule } from '@cart/cart.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaymentModule } from '@payment/payment.module';
import { SharedModule } from '@shared/shared.module';
import { UserRoutingModule } from '@user/user-routing.module';

import { ConsultantModule } from '../consultant/consultant.module';
import { AccountComponent } from './components/account/account.component';
import { AddressEditorComponent } from './components/account/addresses/address-editor/address-editor.component';
import { AddressesComponent } from './components/account/addresses/addresses.component';
import { CcOrderDetailsTableComponentComponent } from './components/account/orders/cc-order-details/cc-order-details-table/cc-order-details-table.component';
import { CcOrderDetailsTotalComponent } from './components/account/orders/cc-order-details/cc-order-details-total/cc-order-details-total.component';
import { CcOrderDetailsComponent } from './components/account/orders/cc-order-details/cc-order-details.component';
import { CCOrderHistoryComponent } from './components/account/orders/cc-order-history/cc-order-history.component';
import { OrderDetailsBackOrderItemsComponent } from './components/account/orders/order-details/order-details-back-order-items/order-details-back-order-items.component';
import { CartonItemsComponent } from './components/account/orders/order-details/order-details-cartons/carton-items/carton-items.component';
import { OrderDetailsCartonsComponent } from './components/account/orders/order-details/order-details-cartons/order-details-cartons.component';
import { OrderDetailsTableComponent } from './components/account/orders/order-details/order-details-table/order-details-table.component';
import { OrderDetailsTotalComponent } from './components/account/orders/order-details/order-details-total/order-details-total.component';
import { OrderDetailsComponent } from './components/account/orders/order-details/order-details.component';
import { OrderHistoryComponent } from './components/account/orders/order-history/order-history.component';
import { OrdersComponent } from './components/account/orders/orders.component';
import { AddressRequiredModalComponent } from './components/account/payment-methods/address-required-modal/address-required-modal.component';
import { CreatePaymentMethodComponent } from './components/account/payment-methods/create-payment-method/create-payment-method.component';
import { PaymentMethodsComponent } from './components/account/payment-methods/payment-methods.component';
import { ProfileComponent } from './components/account/profile/profile.component';
import { RecipesComponent } from './components/account/recipes/recipes.component';
import { NewReturnComponent } from './components/account/returns/new-return/new-return.component';
import { ReturnStepNavigationButtonsComponent } from './components/account/returns/new-return/return-step-navigation-buttons/return-step-navigation-buttons.component';
import { ReturnStepperNavigatorComponent } from './components/account/returns/new-return/return-stepper-navigator/return-stepper-navigator.component';
import { ReturnSummaryStepComponent } from './components/account/returns/new-return/return-summary-step/return-summary-step.component';
import { SelectOrderStepComponent } from './components/account/returns/new-return/select-order-step/select-order-step.component';
import { SelectProductsStepComponent } from './components/account/returns/new-return/select-products-step/select-products-step.component';
import { AddressFormModalComponent } from './components/account/returns/order-information/address-form-modal/address-form-modal.component';
import { MyReturnsInfoCardComponent } from './components/account/returns/order-information/my-returns-info-card/my-returns-info-card.component';
import { OrderInformationComponent } from './components/account/returns/order-information/order-information.component';
import { ReturnDetailsPageComponent } from './components/account/returns/return-details-page/return-details-page.component';
import { ReturnHistoryComponent } from './components/account/returns/return-history/return-history.component';
import { ReturnOrderLineComponent } from './components/account/returns/return-order-lines/return-order-line/return-order-line.component';
import { ReturnOrderLinesComponent } from './components/account/returns/return-order-lines/return-order-lines.component';
import { ReturnsComponent } from './components/account/returns/returns.component';
import { WishlistComponent } from './components/account/wishlist/wishlist.component';
import { PasswordResetPageComponent } from './components/password-reset-page/password-reset-page.component';
import { RegistrationComponent } from './components/registration/registration.component';

@NgModule({
  declarations: [
    AccountComponent,
    ProfileComponent,
    OrdersComponent,
    WishlistComponent,
    AddressesComponent,
    PaymentMethodsComponent,
    RecipesComponent,
    ReturnsComponent,
    RegistrationComponent,
    ProfileComponent,
    AddressEditorComponent,
    OrderDetailsComponent,
    OrderDetailsTotalComponent,
    OrderDetailsTableComponent,
    OrderInformationComponent,
    CcOrderDetailsComponent,
    CcOrderDetailsTableComponentComponent,
    CcOrderDetailsTotalComponent,
    PasswordResetPageComponent,
    CreatePaymentMethodComponent,
    SelectOrderStepComponent,
    ReturnStepperNavigatorComponent,
    MyReturnsInfoCardComponent,
    AddressFormModalComponent,
    OrderDetailsCartonsComponent,
    OrderDetailsBackOrderItemsComponent,
    ReturnStepNavigationButtonsComponent,
    SelectProductsStepComponent,
    ReturnOrderLinesComponent,
    ReturnOrderLineComponent,
    ReturnHistoryComponent,
    NewReturnComponent,
    OrderHistoryComponent,
    ReturnSummaryStepComponent,
    ReturnDetailsPageComponent,
    CartonItemsComponent,
    AddressRequiredModalComponent,
    CCOrderHistoryComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    UserRoutingModule,
    RouterModule,
    SharedModule,
    LayoutModule,
    FormsModule,
    CartModule,
    ConsultantModule,
    PaymentModule,
  ],
  exports: [AccountComponent, RegistrationComponent],
})
export class UserModule {}
