<div class="row font-size-smaller d-lt-none text-uppercase font-weight-bold">
  <div class="col-md-5 mb-1" i18n>Product(s)</div>
  <div class="col-md-3 mb-1" i18n>Price</div>
  <div class="col-md-4 mb-1" i18n>Reason of return</div>
</div>
<hr class="my-0 d-lt-none" />
<ng-container *ngFor="let orderLine of orderLines; let i = index">
  <app-return-order-line
    [readOnly]="readOnly"
    [orderLine]="orderLine"
    (orderLineChange)="orderLineChange($event, i)"
  ></app-return-order-line>
</ng-container>
