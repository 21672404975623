<div class="row">
  <div *ngIf="type === ConsultantFinderType.find" class="col-12" i18n>
    Search for your consultant by name or location using the form below
  </div>
  <div *ngIf="type === ConsultantFinderType.locate" class="col-12" i18n>
    Please enter your ZIP Code and Preferred language so we can find you a consultant.
  </div>
</div>

<div class="row my-4">
  <div class="col-12">
    <ng-container [ngSwitch]="type">
      <ng-container
        *ngSwitchCase="ConsultantFinderType.locate"
        [ngTemplateOutlet]="locate"
      ></ng-container>
      <ng-container
        *ngSwitchCase="ConsultantFinderType.find"
        [ngTemplateOutlet]="find"
      ></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #locate>
  <form [formGroup]="form" id="locate">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <app-text-field-input
          [identifier]="LocatorFormKey.zipCode"
          label="Your ZIP Code"
          i18n-label
          i18n-placeholder
          placeholder="ZIP code"
          [formControlName]="LocatorFormKey.zipCode"
          labelAsterix="true"
        >
        </app-text-field-input>
      </div>
      <div class="col-12 col-md-6">
        <label class="text-asterix" i18n>Language preference</label>
        <div class="combo-container">
          <div class="form-check form-check-inline" *ngFor="let language of languages">
            <input
              class="form-check-input"
              type="radio"
              [formControlName]="LocatorFormKey.language"
              [id]="language.value"
              [value]="language.value"
            />
            <label class="form-check-label text-uppercase" [for]="language.value">{{
              language.name
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row"> <div class="col mt-4" i18n>* Required</div></div>

    <div class="row validation-error-message mt-2">
      <span class="col" *ngIf="consultantLocatorErrorType$ | async as errorType">
        {{ ConsultantLocatorErrorMessage[errorType] }}
      </span>
    </div>
  </form>
</ng-template>

<ng-template #find>
  <form [formGroup]="form" id="find">
    <div class="control-container">
      <app-text-field-input
        [identifier]="FinderFormKey.firstName"
        label="First Name"
        i18n-label
        i18n-placeholder
        placeholder="Consultant's First Name"
        [formControlName]="FinderFormKey.firstName"
      >
      </app-text-field-input>

      <app-text-field-input
        [identifier]="FinderFormKey.lastName"
        label="Last Name"
        i18n-label
        i18n-placeholder
        placeholder="Consultant's Last Name"
        [formControlName]="FinderFormKey.lastName"
      >
      </app-text-field-input>

      <app-text-field-input
        [identifier]="FinderFormKey.mobilePhoneNumber"
        i18n-label
        label="Mobile Number"
        i18n-placeholder
        placeholder="Consultant's Mobile Number"
        type="tel"
        [formControlName]="FinderFormKey.mobilePhoneNumber"
      ></app-text-field-input>

      <app-text-field-input
        [identifier]="FinderFormKey.city"
        label="City"
        i18n-label
        i18n-placeholder
        placeholder="Consultant's City"
        [formControlName]="FinderFormKey.city"
      >
      </app-text-field-input>

      <app-select
        [identifier]="FinderFormKey.stateCode"
        label="State"
        i18n-label
        i18n-placeholder
        placeholder="Consultant's State"
        [formControlName]="FinderFormKey.stateCode"
        [options]="countryStates$ | async"
      ></app-select>
    </div>
  </form>
</ng-template>
