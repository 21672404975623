import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@core/store';
import { selectSearchLoading } from '@core/store/search';
import { Store } from '@ngrx/store';
import { scrollToTop } from '@shared/utils/scroll-utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxComponent implements OnInit {
  searchTerm: string = '';
  loading$: Observable<boolean>;

  constructor(private router: Router, private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.loading$ = this.store$.select(selectSearchLoading);
  }

  @HostListener('window:keydown./', ['$event'])
  highlightSearch(event: KeyboardEvent) {
    if ((<HTMLElement>event.target).matches('input')) {
      return;
    }
    event.preventDefault();
    const htmlElement: HTMLElement = document.querySelector('#search');

    scrollToTop();
    htmlElement.focus();
  }

  search() {
    if (this.searchTerm.length) {
      this.router.navigate(['search', this.searchTerm]);
      this.searchTerm = '';
    }
  }
}
