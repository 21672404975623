import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-plus-minus-counter',
  templateUrl: './plus-minus-counter.component.html',
  styleUrls: ['./plus-minus-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PlusMinusCounterComponent,
      multi: true,
    },
  ],
})
export class PlusMinusCounterComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output()
  quantityChange: EventEmitter<object> = new EventEmitter<object>();

  @Input()
  identifier: string;

  @Input()
  min: number;

  @Input()
  max: number;

  @Input()
  size: 'sm' | 'lg';

  @Input()
  value: number;

  disabled = false;

  private onChange$: Subject<number>;
  private onChangeSubscription: Subscription;

  constructor() {
    this.onChange$ = new Subject<number>();
    this.onChangeSubscription = this.onChange$
      .pipe(debounceTime(300))
      .subscribe((value) => this.quantityChange.emit({ value }));
  }

  ngOnInit(): void {
    this.value = this.value ? this.value : this.min ? this.min : this.max ? this.max : 0;
  }

  onChange: (value: string) => void = () => {};
  onTouched = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getSizeClass(): string {
    return [
      this.size === 'sm' ? 'input-group-sm' : '',
      this.size === 'lg' ? 'input-group-lg' : '',
    ].join(' ');
  }

  increment(): void {
    if (this.max) this.max > this.value && this.value++;
    else this.value++;
    this.onChange$.next(this.value);
  }

  decrement(): void {
    if (this.min) this.min < this.value && this.value--;
    else this.value--;
    this.onChange$.next(this.value);
  }

  ngOnDestroy(): void {
    this.onChangeSubscription.unsubscribe();
  }
}
