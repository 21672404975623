import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { AppState } from '@core/store';
import {
  selectOrderStateLoading,
  selectShippedCartonDetailsData,
  selectShippedCartonsError,
} from '@core/store/order';
import { ShippedCartonDetailsData } from '@core/store/order/order-state-models';
import { fetchShippedCartonsData } from '@core/store/order/order.actions';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-deliver-info-page',
  templateUrl: './delivery-info-page.component.html',
  styleUrls: ['./delivery-info-page.component.scss'],
})
export class DeliveryInfoPageComponent implements OnInit, OnDestroy {
  public orderUID: string;
  public orderId: string;
  public cartonDetails$: Observable<ShippedCartonDetailsData>;
  public cartonDetailsError: boolean;
  public isLoading$: Observable<boolean>;

  private subscription: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private store$: Store<AppState>,
  ) {
    const orderId = this.route.snapshot.queryParamMap.get(RouterQueryParams.id);
    const orderUID = this.route.snapshot.queryParamMap.get(RouterQueryParams.idg);
    if (orderUID) {
      this.orderId = orderId;
      this.orderUID = orderUID;
    } else {
      if(orderId){
        this.orderId = orderId;
      }
      else{
        this.cartonDetailsError = true;
      }
    }
  }

  ngOnInit(): void {
    this.isLoading$ = this.store$.select(selectOrderStateLoading);
    this.cartonDetails$ = this.store$.select(selectShippedCartonDetailsData);
    this.store$
      .select(selectShippedCartonsError)
      .subscribe((error) => (this.cartonDetailsError = !!error));

    this.store$.dispatch(
      fetchShippedCartonsData({ orderUID: this.orderUID, orderId: this.orderId }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
