import { Injectable } from '@angular/core';
import { BlogService } from '@core/services/blog.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import * as blogActions from './blog.actions';

@Injectable()
export class BlogEffects {
  fetchBlogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(blogActions.fetchBlogs),
      exhaustMap((payload) =>
        this.blogService.fetchBlogs(payload).pipe(
          map((res) =>
            blogActions.fetchBlogsSuccess({
              items: res.blogs,
              pageSize: payload.pageSize,
              isFirstPage: payload.isFirstPage,
            }),
          ),
          catchError((error) => of(blogActions.fetchBlogsFailure({ error }))),
        ),
      ),
    ),
  );

  fetchBlogsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(blogActions.fetchBlogsFailure),
        tap(() => {
          this.toastr.error($localize`Failed to fetch Blogs`, $localize`Blog fetching error`);
        }),
      ),
    { dispatch: false },
  );

  fetchBlogCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(blogActions.fetchBlogCategories),
      mergeMap(() =>
        this.blogService.fetchBlogCategories().pipe(
          map(
            ({ blogCategories }) => blogActions.fetchBlogCategoriesSuccess({ blogCategories }),
            catchError((error) => of(blogActions.fetchBlogCategoriesFailure({ error }))),
          ),
        ),
      ),
    ),
  );

  fetchBlogCategoriesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(blogActions.fetchBlogCategoriesFailure),
        tap(() => {
          this.toastr.error(
            $localize`Failed to fetch Blog categories`,
            $localize`Blog category fetching error`,
          );
        }),
      ),
    { dispatch: false },
  );

  fetchBlogDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(blogActions.fetchBlogDetails),
      mergeMap(({ url }) =>
        this.blogService.fetchBlogDetails(url).pipe(
          map((item) => blogActions.fetchBlogDetailsSuccess({ item })),
          catchError(() => of(blogActions.fetchBlogDetailsFailure())),
        ),
      ),
    ),
  );

  fetchBlogDetailsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(blogActions.fetchBlogDetailsFailure),
        tap(() => {
          this.toastr.error(
            $localize`Failed to fetch blog details`,
            $localize`Blog details fetching error`,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private blogService: BlogService,
    private toastr: ToastrService,
  ) {}
}
