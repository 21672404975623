import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  AddPaymentToPartyOrderRequest,
  AddPaymentToPartyOrderResponse,
  CCOrderDetailsResponse,
  CCOrderHistoryResponse,
  ClientTokenRequest,
  ClientTokenResponse,
  LogCardAttemptRequest,
  OrderDetailsResponse,
  OrderHistoryResponse,
  PartyOrderDataResponse,
  ShippedCartonDetailsResponse,
  SubmitOrderRequest,
  SubmitOrderResponse,
} from '@core/dto/order.dto';
import { Locale } from '@core/enums/locale';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
// tslint:disable-next-line:max-line-length
@Injectable({
  providedIn: 'root',
})
export class OrderService {
  baseUrl: string = environment.ecommerceBackendUri;
  options = { withCredentials: true };
  culture: Locale;

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private localeId: Locale,
  ) {
    this.culture = getCulture(localeId);
  }

  fetchOrderHistory(): Observable<OrderHistoryResponse> {
    return this.http.get<OrderHistoryResponse>(`${this.baseUrl}api/order`, {
      ...this.options,
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }

  fetchCCOrderHistory(): Observable<CCOrderHistoryResponse> {
    return this.http.get<CCOrderHistoryResponse>(`${this.baseUrl}api/order/cc`, {
      ...this.options,
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }

  fetchOrderDetails(orderId: string): Observable<OrderDetailsResponse> {
    return this.http.get<OrderDetailsResponse>(`${this.baseUrl}api/order/${orderId}`, {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }

  fetchCCOrderDetails(poeOrderId: string): Observable<CCOrderDetailsResponse> {
    return this.http.get<CCOrderDetailsResponse>(`${this.baseUrl}api/order/cc/${poeOrderId}`, {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }

  fetchClientToken(request: ClientTokenRequest): Observable<ClientTokenResponse> {
    return this.http.post<ClientTokenResponse>(
      `${this.baseUrl}api/order/payment/token`,
      { ...request, language: this.culture },
      this.options,
    );
  }

  fetchPartyOrderClientToken(request: ClientTokenRequest): Observable<ClientTokenResponse> {
    return this.http.post<ClientTokenResponse>(
      `${this.baseUrl}api/partyorder/token`,
      { ...request, language: this.culture },
      this.options,
    );
  }

  submitOrder(request: SubmitOrderRequest): Observable<SubmitOrderResponse> {
    return this.http.post<SubmitOrderResponse>(`${this.baseUrl}api/order/submit`, request, {
      ...this.options,
    });
  }

  fetchPartyOrderData(orderId: string): Observable<PartyOrderDataResponse> {
    return this.http.get<PartyOrderDataResponse>(`${this.baseUrl}api/partyorder/paymentdata`, {
      params: new HttpParams({
        fromObject: { orderId },
      }),
    });
  }

  submitPartyOrder(request: AddPaymentToPartyOrderRequest) {
    return this.http.post<AddPaymentToPartyOrderResponse>(
      `${this.baseUrl}api/partyorder/payment`,
      request,
      {
        ...this.options,
      },
    );
  }

  logCardAttempt(request: LogCardAttemptRequest): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}api/order/payment/logcardattempt`, request);
  }

  printSummary(orderNumber: string): void {
    this.http
      .get(
        `${this.baseUrl}api/order/printSummary?orderNumber=${orderNumber}&culture=${this.culture}`,
        {
          responseType: 'blob',
        },
      )
      .subscribe((pdf) => {
        saveAs(pdf, $localize`OrderConfirmation` + '.pdf');
      });
  }

  fetchShippedCartons(orderUID: string, orderId: string): Observable<ShippedCartonDetailsResponse> {
    //let parm = orderId == null ? `${orderUID}` : `${orderUID}/${orderId}`;
    //Suja 6/28/24 If only guid is passed then orderId is null so pass only OrderUID (GUID)
    //If orderid is passed, check if guid is passed and send both
    //If OrderId is passed and QUID is not passed, then send only the OrderID
    let parm = orderId == null ? `${orderUID}`:
        (orderUID == null ? `${orderId}`:`${orderUID}/${orderId}`);
    if(orderUID == null){
      return this.http.get<ShippedCartonDetailsResponse>
      (`${this.baseUrl}api/order/v2/shipped/${parm}`, {
        ...this.options,
        params: new HttpParams({
          fromObject: { culture: this.culture },
        }),
      });

    }
    else{
      return this.http.get<ShippedCartonDetailsResponse>
      (`${this.baseUrl}api/order/shipped/${parm}`, {
        ...this.options,
        params: new HttpParams({
          fromObject: { culture: this.culture },
        }),
      });
    }
    // return this.http.get<ShippedCartonDetailsResponse>(`${this.baseUrl}api/order/shipped/${parm}`, {
    //   ...this.options,
    //   params: new HttpParams({
    //     fromObject: { culture: this.culture },
    //   }),
    // });
  }
}
