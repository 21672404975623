import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-return-stepper-navigator',
  templateUrl: './return-stepper-navigator.component.html',
  styleUrls: ['./return-stepper-navigator.component.scss'],
})
export class ReturnStepperNavigatorComponent {
  @Input() activeStep: number;
  @Input() steps: string[];
}
