import { SearchFilterType } from '@core/models/search';
import { createAction, props } from '@ngrx/store';
import { SearchResultFacet, SearchResultDocument } from './search-state-models';

export enum SearchActionTypes {
  resetSearchState = '[Search] Reset search state',
  fetchSearchResult = '[Search] Fetch search result',
  fetchSearchResultSuccess = '[Search] Fetch search result success',
  fetchSearchResultFailure = '[Search] Fetch search result failure',
}

export const resetSearchState = createAction(SearchActionTypes.resetSearchState);

export const fetchSearchResult = createAction(
  SearchActionTypes.fetchSearchResult,
  props<{
    searchTerm: string;
    pageNumber?: number;
    pageSize?: number;
    isFirstPage: boolean;
    keepFacets: boolean;
    filter?: SearchFilterType;
    withDiscontinuedProducts?: boolean;
  }>(),
);

export const fetchSearchResultSuccess = createAction(
  SearchActionTypes.fetchSearchResultSuccess,
  props<{
    count: number;
    documents: SearchResultDocument[];
    facets: SearchResultFacet[];
    keepFacets: boolean;
  }>(),
);

export const fetchSearchResultFailure = createAction(SearchActionTypes.fetchSearchResultFailure);
