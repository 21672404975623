import produce from 'immer';
import { WishlistState } from './wishlist-state-models';
import { WishlistActionTypes } from './wishlist.actions';

export const initialState: WishlistState = {
  loading: false,
  wishListItems: [],
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case WishlistActionTypes.resetWishlistState:
      return initialState;

    case WishlistActionTypes.fetchWishlist:
      draft.loading = true;
      return;

    case WishlistActionTypes.fetchWishlistSuccess:
      draft.loading = false;
      draft.wishListItems = action.wishListItems;
      return;

    case WishlistActionTypes.fetchWishlistFailure:
      draft.loading = false;
      return;

    case WishlistActionTypes.addToWishlistSuccess:
    case WishlistActionTypes.deleteFromWishlistSuccess:
      draft.wishListItems = action.wishListResponse.wishListItems;
      return;

    default:
      return draft;
  }
}, initialState);
