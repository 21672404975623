import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FooterLinksResponse } from '@core/dto/footerlink.dto';
import { Locale } from '@core/enums/locale';
import { FooterLink } from '@core/store/footer/footer-state-models';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { keysToCamelCase } from '@shared/utils/object-transform-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FooterlinkService {
  baseUrl: string = environment.ecommerceBackendUri;
  culture: Locale;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {
    this.culture = getCulture(localeId);
  }

  fetchPrimaryLinks(): Observable<FooterLink[]> {
    let params = this.getFetchParams('1');
    return this.http
      .get<FooterLinksResponse>(this.baseUrl + 'api/default/footerlinks', params)
      .pipe(map((res) => keysToCamelCase(res.value)));
  }

  fetchSecondaryLinks(): Observable<FooterLink[]> {
    let params = this.getFetchParams('2');
    return this.http
      .get<FooterLinksResponse>(this.baseUrl + 'api/default/footerlinks', params)
      .pipe(map((res) => keysToCamelCase(res.value)));
  }

  // prettier-ignore
  private getFetchParams(linkType: string): object {
    return {
      params: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        sf_culture: this.culture,
        $orderBy: 'Order',
        $filter: 'cast(LinkType,Edm.String) eq \'' + linkType + '\''
      },
    };
  }
}
