<div class="container">
  <div class="mt-5 mb-3 text-center">
    <h1 class="title text-uppercase mb-3" i18n>Application password</h1>
    <span class="subtitle" i18n>
      Please enter the application password provided by your Consultant to continue.
    </span>
  </div>
  <div class="row justify-content-center my-4">
    <div class="col-12 col-md-7">
      <form [formGroup]="passwordForm" class="mb-2">
        <app-text-field-input
          [identifier]="Password"
          label="Password"
          i18n-label
          placeholder="Password provided by your Consultant"
          i18n-placeholder
          [formControlName]="Password"
          [labelStyle]="FormLabelStyle"
          [formErrorMessages]="ErrorMessages"
        ></app-text-field-input>
      </form>
      <div *ngIf="(registrationCodeValidity$ | async) === false" class="validation-error-message">
        <span i18n>Invalid registration code!</span>
      </div>
      <span i18n> If you do not know the password, please contact your Consultant. </span>
    </div>
  </div>
  <div class="row justify-content-center my-5">
    <div class="col col-md-3">
      <div class="white-button-container">
        <button
          class="btn btn-outline-primary text-uppercase w-100"
          i18n
          (click)="previousStepClick()"
        >
          Back
        </button>
      </div>
    </div>
    <div class="col col-md-3">
      <button class="btn btn-primary text-uppercase w-100" i18n (click)="nextStepClick()">
        Start!
      </button>
    </div>
  </div>
</div>
