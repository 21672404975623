import {
  AfterViewInit,
  Component,
  ElementRef,
  Host,
  Input,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { environment } from '@env';
import {
  getValidationErrorMessageList,
  validGroup,
  validInput,
} from '@shared/utils/validation.utils';
import { MaskPipe } from 'ngx-mask';

type InputType = 'text' | 'tel' | 'email' | 'password';

@Component({
  selector: 'app-text-field-input',
  templateUrl: './text-field-input.component.html',
  styleUrls: ['./text-field-input.component.scss'],
  providers: [
    MaskPipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextFieldInputComponent,
      multi: true,
    },
  ],
})
export class TextFieldInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() identifier: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() validationErrorMessage: string;
  @Input() formErrorMessages: { [key: string]: string } = FormErrorMessages;
  @Input() groupValidationErrorType: string;
  @Input() invalidGroupErrorMessage: string = $localize`Email confirmation does not match`;
  @Input() readonly: boolean = false;
  @Input() type: InputType = 'text';
  @Input() labelStyle: any = {};
  @Input() labelAsterix: boolean = false;
  @Input() isDataPrivate: boolean = false;
  @Input() formControlName: string;
  @ViewChild('input') input: ElementRef;

  readonly envTel = environment.tel;

  disabled = false;
  value: string;
  control: AbstractControl;
  form: FormGroup;

  private telSelectionStart: number;
  private telSelectionEnd: number;

  constructor(
    private maskPipe: MaskPipe,
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  ngAfterViewInit(): void {
    if (this.isDataPrivate) {
      document.getElementById(this.identifier)?.setAttribute('data-private', '');
    }
    this.control = this.controlContainer?.control.get(this.formControlName);
    this.form = this.controlContainer?.control as FormGroup;
  }

  onChange: (value: string) => void = () => {};

  onTouched = () => {};

  writeValue(value: string): void {
    if (this.type === 'tel') {
      /**
       * This value check is needed because, if we don't check it and it is an empty string
       * than it will override with the prefix (by masking process) value and the placeholders won't be visible
       */
      if (!!value) {
        // This setTimeout needed because the ngx-masked has a bug, you can see it in the bellow link
        // https://github.com/JsDaddy/ngx-mask/issues/1041
        setTimeout(() => (this.value = this.getTelPrettyValue(value)));
      }
    } else {
      this.value = value;
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onKeyUp(event: any) {
    this.changeValue(event.target.value);
    this.onTouched();
  }

  onBlur(event: any) {
    const value = event.target.value;
    if (!!value) {
      this.changeValue(value);
    }
  }

  onMouseUp(event: any): void {
    this.telSelectionStart =
      event.target.selectionStart < this.envTel.prefix.length
        ? this.envTel.prefix.length
        : event.target.selectionStart;
    this.telSelectionEnd =
      event.target.selectionEnd < this.envTel.prefix.length
        ? this.envTel.prefix.length
        : event.target.selectionEnd;
  }

  onClick(): void {
    this.input.nativeElement.setSelectionRange(this.telSelectionStart, this.telSelectionEnd);
  }

  get isValidInput(): boolean {
    if (this.control) {
      return validInput(this.control);
    }
    return true;
  }

  get isValidGroup(): boolean {
    if (this.form && this.groupValidationErrorType) {
      return validGroup(this.form, this.groupValidationErrorType);
    }
    return true;
  }

  get errorMessages(): string[] {
    return getValidationErrorMessageList(this.control, this.formErrorMessages);
  }

  private getTelRawValue(value: string) {
    return this.removeTelMask(this.removeTelPrefix(value));
  }

  private getTelPrettyValue(value: string) {
    return this.addTelPrefix(this.addTelMask(value));
  }

  private removeTelPrefix(value: string) {
    if (environment.tel.prefix.length) {
      return value.replace(environment.tel.prefix, '').replace(/\D+/g, '');
    }
    return value;
  }

  private removeTelMask(value: string) {
    if (environment.tel.mask.length) {
      return value.replace(/\D+/g, '');
    }
    return value;
  }

  private addTelMask(value: string) {
    if (environment.tel.mask) {
      return this.maskPipe.transform(value, environment.tel.mask);
    }
    return value;
  }

  private addTelPrefix(value: string) {
    if (environment.tel.prefix) {
      return `${environment.tel.prefix}${value}`;
    }
    return value;
  }

  private changeValue(value: string): void {
    this.value = value;
    if (this.type === 'tel') {
      this.onChange(this.getTelRawValue(value));
    } else {
      this.onChange(value);
    }
  }
}
