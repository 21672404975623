import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-back-forward-cache-initializer',
  template: '',
})
export class BackForwardCacheInitializerComponent {
  @HostListener('window:pageshow', ['$event']) pageshow(e) {
    if (e.persisted) {
      window.location.reload();
    }
  }
}
