import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreateVoucherResponse,
  ReceiveVoucherResponse,
  ReceiveVouchersRequest,
} from '@core/dto/voucher.dto';
import { CreatedVoucher } from '@core/store/voucher/voucher-state-models';
import { environment } from '@env';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  baseUrl: string = environment.ecommerceBackendUri;
  options = {
    withCredentials: true,
  };

  constructor(private http: HttpClient) {}

  createVoucher(email: string): Observable<CreateVoucherResponse> {
    return this.http.post<CreateVoucherResponse>(
      `${this.baseUrl}api/order/payment/createvoucher`,
      { email },
      this.options,
    );
  }

  receiveVoucher(
    request: ReceiveVouchersRequest,
  ): Observable<{ vouchers: ReceiveVoucherResponse[] }> {
    return this.http.post<{ vouchers: ReceiveVoucherResponse[] }>(
      `${this.baseUrl}api/order/payment/receivevouchers`,
      request,
      this.options,
    );
  }

  generateVoucher(vouchers: CreatedVoucher[]): Observable<string[]> {
    const vouchersToGenerate$ = new BehaviorSubject<CreatedVoucher[]>(vouchers);

    for (let i = 0; i < vouchers.length; i++) {
      let iframe = document.createElement('iframe') as HTMLIFrameElement;

      iframe.hidden = true;
      iframe.src = vouchers[i].voucherUrl;

      document.body.appendChild(iframe);

      iframe.onload = () => {
        const remainingVouchers = vouchersToGenerate$.getValue();
        vouchersToGenerate$.next(
          remainingVouchers.filter((voucher) => voucher.token !== vouchers[i].token),
        );
      };
    }

    return vouchersToGenerate$.pipe(
      filter((vouchersToGenerate) => vouchersToGenerate.length === 0),
      map(() => vouchers.map((voucher) => voucher.token)),
    );
  }

  downloadVoucher(id: string) {
    this.http
      .get(`${this.baseUrl}api/order/${id}/downloadVoucher`, { responseType: 'blob' })
      .subscribe((pdf) => {
        saveAs(pdf, $localize`voucher` + '.pdf');
      });
  }

  prevalidateVoucher(email: string) {
    return this.http.get(`${this.baseUrl}api/order/payment/prevalidatevoucher`, {
      ...this.options,
      params: new HttpParams({
        fromObject: {
          email,
        },
      }),
    });
  }
}
