import { RelatedCarouselSlide } from '@core/models/related-carousel-slide.model';
import { RecipeItem } from '@core/store/recipe/recipe-state-models';

export function createRecipeDetailsUrl(recipeUrl: string): string {
  return `/recipe/detail/${recipeUrl}`;
}

export function createBlogDetailsUrl(blogUrl: string): string {
  return `/blog/detail/${blogUrl}`;
}

export function getRelatedSlidesFromRelatedRecipes(recipes: RecipeItem[]): RelatedCarouselSlide[] {
  return recipes.map((r) => ({
    images: r.images,
    targetUrl: createRecipeDetailsUrl(r.url),
  }));
}
