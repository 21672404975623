export interface ContactUsState {
  sendingMessage: SendingContactUsMessage;
}

export enum SendingContactUsMessage {
  notReady,
  inProgress,
  successful,
  failure,
}
