import { Component, Input } from '@angular/core';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { FailedOrderItem } from '@core/store/order/order-state-models';

@Component({
  selector: 'app-failed-order-item',
  templateUrl: './failed-order-item.component.html',
  styleUrls: ['./failed-order-item.component.scss'],
})
export class FailedOrderItemComponent {
  @Input() failedOrderItem: FailedOrderItem;
  @Input() isUpdatedItem: boolean = true;

  thumbnail = Thumbnail;

  constructor() {}
}
