<app-confirm-modal
  #modal
  i18n-title
  title="Leave party"
  (yesClick)="leaveParty()"
  i18n-noLabel
  i18n-yesLabel
  noLabel="Stay with this one"
  yesLabel="Leave party"
>
  <div class="text-center" i18n>
    <p class="font-size-bigger"
      >You are shopping with
      <span class="text-uppercase font-italic">{{ (party$ | async)?.partyName }}</span> party.</p
    >
    <p>
      <i class="bi bi-exclamation-circle"></i>
    </p>
    <p>Are you sure that you want to leave this party and find a different one?</p>
  </div>
</app-confirm-modal>
