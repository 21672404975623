import { CatalogType } from '@core/enums/catalog-type.enum';

export const DEFAULT_PAGE_TITLE = $localize`Princess House`;
// eslint-disable-next-line max-len
export const DEFAULT_PAGE_DESCRIPTION = $localize`Check out all Princess House has to offer to help make life easier, healthier and more beautiful.`;

export const CategoryCollectionPageTitle = {
  [CatalogType.Category]: $localize`Categories`,
  [CatalogType.Collection]: $localize`Collections`,
};
