<div class="container pl-md-0">
  <app-account-header i18n-title title="My Recipes"></app-account-header>

  <div *ngIf="loading$ | async; else content" class="d-flex justify-content-center m-5">
    <app-ph-spinner width="60px"></app-ph-spinner>
  </div>
</div>

<ng-template #content>
  <ng-container [ngTemplateOutlet]="isInMobileView() ? mobile : desktop"> </ng-container>
</ng-template>

<ng-template #desktop>
  <app-sortable-table
    [columns]="columns"
    [rows]="recipes$ | async"
    identifierField="url"
  ></app-sortable-table>
</ng-template>

<ng-template #mobile>
  <div class="mobile recipe-container">
    <div class="recipe mb-2 p-2" *ngFor="let recipe of recipes$ | async">
      <a class="center-content" [routerLink]="'/recipe/detail/' + recipe.url">
        <img
          class="w-100"
          phImage
          [images]="recipe.images"
          [thumbnailMobile]="Thumbnail.recipeListMobile"
        />
      </a>
      <div>
        <div class="font-weight-light small-text">{{ recipe.categories }}</div>
        <a class="font-weight-bold" [routerLink]="'/recipe/detail/' + recipe.url">
          {{ recipe.name }}
        </a>
      </div>

      <a (click)="dislike(recipe.id, recipe.url)"><i class="bi bi-trash"></i></a>
    </div>
  </div>
</ng-template>
