import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PayPalDropinUi } from '@core/models/dropin-instance.model';
import { LogCardAttemptData } from '@core/models/log-card-attempt-data.model';
import { LoggerService } from '@core/services/logger.service';
import { PaymentInfo } from '@core/store/payment/payment-state-models';
import { environment } from '@env';
import { CardPaymentProviderBaseComponent } from '@payment/components/card-payment-provider-base-component';
import {
  IOnApproveCallbackData,
  IOrderDetails,
  IPayPalConfig,
  NgxPaypalComponent,
} from 'ngx-paypal';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * PayPal dropin-ui that implements the PayPal buttons and the corresponding handlers.
 * - In case of PayPal on their dropin-ui we have to call an extra step compared to the other providers that we use.
 * This is the order creation step and it's called when we click on PayPal or Card Payment buttons. Besides that
 * we can't take the control of their submit button, so we have to click on it - no matters if we have another
 * Next or Submit button.
 * - In our general PMO request architecture we can take the control of the dropin-ui submit where we request the PMO in
 * our requestPaymentMethodObject and we store the payment info data and emit successful or fail status.
 * Here the PMO creation happens fully on PayPal side, so we store the results and late on by our flow we are able
 * to pass to our PMO request logic.
 */
@Component({
  selector: 'app-pay-pal',
  templateUrl: './pay-pal.component.html',
  styleUrls: ['./pay-pal.component.scss'],
})
export class PayPalComponent
  extends CardPaymentProviderBaseComponent<PayPalDropinUi>
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() isCardPaymentEnabled: boolean = false;
  @Output() isPaymentApproved: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() cardAttempt: EventEmitter<LogCardAttemptData> = new EventEmitter<LogCardAttemptData>();

  @ViewChild('PayPalDropInContainer') payPalDivContainer: ElementRef<HTMLElement>;
  @ViewChild('payPalElement') paypalInstance?: NgxPaypalComponent;
  isLoading: boolean = false;
  readonly DefaultDisableFunding =
    'paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo';
  readonly DisableCardPayment = 'card,';

  public payPalConfig?: IPayPalConfig;

  /** Stores the Payment Method Object request status. */
  public isPmoRequestSuccessful = false;

  private locale: string = environment.languages[0].code.replace('-', '_');

  ngOnInit(): void {
    this.loading.emit(true);
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.initDropinUiByToken(PaymentProviderType.PayPal));
  }

  onScriptLoaded(): void {
    this.isLoading = true;
    this.loading.emit(true);
    this.detectChanges();
  }

  constructor(
    public loggerService: LoggerService,
    private cdRef: ChangeDetectorRef,
  ) {
    super(loggerService);
  }

  public get dropInInstance(): PayPalDropinUi {
    return this._dropInInstance as PayPalDropinUi;
  }

  public get dropInContainer(): ElementRef<HTMLElement> {
    return this.payPalDivContainer;
  }

  public initDropinInstance(token: string): void {
    this._dropInInstance = this.paypalInstance;
    this.dropInComponentInitialized.emit(this);
    const disableFunding = this.isCardPaymentEnabled
      ? this.DefaultDisableFunding
      : this.DisableCardPayment + this.DefaultDisableFunding;

    this.loggerService.logTimerStart('pay-pal init');
    /** Note: The authorization happens on our server side by createPaymentMethod in payment handlers */
    this.payPalConfig = {
      clientId: token,
      currency: environment.currency,
      advanced: {
        commit: 'true',
        extraQueryParams: [
          {
            name: 'intent',
            value: 'authorize',
          },
          {
            name: 'locale',
            value: this.locale,
          },
          {
            name: 'disable-funding',
            value: disableFunding,
          },
        ],
      },
      createOrderOnServer: () => {
        this.isLoading = true;
        this.loading.emit(true);
        return this.paymentHandler
          .createOrder()
          .pipe(
            tap(() => {
              this.isLoading = false;
              this.loading.emit(false);
            }),
          )
          .toPromise();
      },
      onApprove: (data: IOnApproveCallbackData, actions) => {
        this.isPaymentMethodRequestable.emit(true);

        actions.order
          .get()
          .then((details: IOrderDetails) => {
            const objectFromResponse = {
              data: data,
              actions: actions,
            };
            this.cardAttempt.emit({ success: true, response: JSON.stringify(objectFromResponse) });

            const paymentInfo = {
              order: {
                orderId: data.orderID,
              },
              cardholderName: details.payer.name.given_name + ' ' + details.payer.name.surname,
              email: details.payer.email_address,
            } as PaymentInfo;
            this.paymentHandler.handleRequestedPaymentMethodObject(null, paymentInfo);
            this.isPmoRequestSuccessful = true;
            this.isPaymentApproved.emit(this.isPmoRequestSuccessful);
            this.detectChanges();
          })
          .catch((error) => {
            this.cardAttempt.emit({ success: false, response: error });
            this.paymentHandler.handleRequestedPaymentMethodObject(error, null);
            this.isPmoRequestSuccessful = false;
            this.isPaymentApproved.emit(this.isPmoRequestSuccessful);
            this.detectChanges();
          });
      },
      onError: (err) => {
        this.loggerService.logTimerEnd('pay-pal init');
        this.cardAttempt.emit({ success: false, response: err });
        this.paymentHandler.handleRequestedPaymentMethodObject(err, null);
        this.isPmoRequestSuccessful = false;
        this.isPaymentApproved.emit(this.isPmoRequestSuccessful);
        this.detectChanges();
      },
      onInit: () => {
        this.loggerService.logTimerEnd('pay-pal init');
        this.isLoading = false;
        this.loading.emit(false);
      },
    };
    this.detectChanges();
  }

  public resetToken(): void {
    /*
      Note: We don't have to reset the token, because the clientId will be the same, but as we create a new order by
      clicking on the buttons will create anyway a new order
    */
  }

  public getCardPaymentInputs(): Array<HTMLElement> | undefined {
    const iframes = this.payPalDivContainer?.nativeElement.querySelectorAll('iframe');
    if (iframes !== undefined) return Array.from(iframes);
  }

  requestPaymentMethodObject(): Observable<boolean> {
    /* As mentioned in the component description the PMO is already created by PayPal when we get here - so we imitate
    the execution by small time out and returning back the status of PMO creation */
    setTimeout(() => {
      this.requestPaymentSuccessSubject.next(this.isPmoRequestSuccessful);
    }, 200);
    return this.requestPaymentSuccessSubject.asObservable();
  }

  private detectChanges() {
    // https://github.com/webcomponents/polyfills/issues/238
    this.cdRef.detectChanges();
  }
}
