export function convertToLocalizedDate(stringDate: string) {
  const dateObject = new Date(stringDate);
  const utcTimeStamp = Date.UTC(
    dateObject.getFullYear(),
    dateObject.getMonth(),
    dateObject.getDate(),
    dateObject.getHours(),
    dateObject.getMinutes(),
    dateObject.getSeconds(),
  );
  const localizedDate = new Date(utcTimeStamp);
  return localizedDate;
}
