import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { EmailShareModalComponent } from './email-share-modal/email-share-modal.component';

@Component({
  selector: 'app-share-buttons',
  templateUrl: './share-buttons.component.html',
  styleUrls: ['./share-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareButtonsComponent {
  @ViewChild('modal') private modal: EmailShareModalComponent;

  constructor() {}

  facebookClickHandler() {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
      'pop',
      'width=600, height=400, scrollbars=no',
    );
  }

  twitterClickHandler() {
    window.open(`https://twitter.com/intent/tweet?url=${window.location.href}`);
  }

  pinterestClickHandler() {
    window.open(`https://www.pinterest.com/pin/create/button/?url=${window.location.href}`);
  }

  emailClickHandler() {
    this.modal.open();
  }
}
