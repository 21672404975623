<div class="product-list-image-container">
  <a [routerLink]="navigateTo">
    <img
      class="img-fluid"
      [altText]="alt"
      phImage
      [images]="images"
      [thumbnailDesktop]="thumbnail.productListDesktop"
      [thumbnailMobile]="thumbnail.productListMobile"
    />
  </a>
  <button
    *ngIf="userUtilService.isUserFeatureEnabled$ | async"
    class="btn-wishlist"
    [ngClass]="{
      bi: true,
      'bi-heart': !isWishlisted,
      'bi-heart-fill': isWishlisted
    }"
    (click)="toggleWish(sku)"
    aria-label="Add to wishlist"
    i18n-aria-label
  ></button>
  <button class="btn btn-primary quick-view-btn" (click)="onOpenQuickView()" i18n>
    QUICK VIEW
  </button>
</div>
