import { CreateProductReviewRequest } from '@core/dto/product-review.dto';
import { createAction, props } from '@ngrx/store';
import { FetchProductPayload, ProductReview } from './product-review-state-models';

export enum ProductReviewActionTypes {
  fetchProductReviews = '[ProductReview] Fetch product reviews',
  fetchMoreProductReviews = '[ProductReviews] Fetch more product reviews',
  fetchProductReviewsSuccess = '[ProductReview] Fetch product reviews success',
  fetchProductReviewsFailure = '[ProductReview] Fetch product reviews failure',
  createProductReview = '[ProductReview] Create product review',
  createProductReviewSuccess = '[ProductReview] Create product review success',
  createProductReviewFailure = '[ProductReview] Create product review failure',
  resetProductReviewState = '[ProductReview] Reset product review state',
}

/**
 * Fetch product reviews
 */
export const fetchProductReviews = createAction(
  ProductReviewActionTypes.fetchProductReviews,
  props<{ payload: FetchProductPayload }>(),
);
export const fetchMoreProductReviews = createAction(
  ProductReviewActionTypes.fetchMoreProductReviews,
  props<{ payload: FetchProductPayload }>(),
);
export const fetchProductReviewsSuccess = createAction(
  ProductReviewActionTypes.fetchProductReviewsSuccess,
  props<{ productReviews: ProductReview[]; pageSize: number }>(),
);
export const fetchProductReviewsFailure = createAction(
  ProductReviewActionTypes.fetchProductReviewsFailure,
);

/**
 * Create product review
 */
export const createProductReview = createAction(
  ProductReviewActionTypes.createProductReview,
  props<{ request: CreateProductReviewRequest }>(),
);
export const createProductReviewSuccess = createAction(
  ProductReviewActionTypes.createProductReviewSuccess,
  props<{ productReview: ProductReview }>(),
);
export const createProductReviewFailure = createAction(
  ProductReviewActionTypes.createProductReviewFailure,
);

/**
 * Reset product review state
 */
export const resetProductReviewState = createAction(
  ProductReviewActionTypes.resetProductReviewState,
);
