<div class="d-none d-md-block position-relative">
  <div class="position-absolute w-100">
    <div class="d-flex">
      <hr class="dark-line my-2" [style.width]="darkLineWidth" />
      <hr class="light-line my-2" [style.width]="lightLineWidth" />
    </div>
  </div>
  <p class="dot position-relative" [style.left]="dotPosition">
    {{ step }}
  </p>
</div>

<div class="d-flex d-md-none my-2">
  <svg height="45" width="45">
    <circle
      class="gray-circle"
      cx="22"
      cy="22"
      r="20"
      stroke-width="3"
      fill="transparent"
      [attr.stroke-dasharray]="dasharray"
      stroke-dashoffset="0"
    />
    <circle
      class="bar"
      cx="22"
      cy="22"
      r="20"
      stroke-width="3"
      fill="transparent"
      [attr.stroke-dasharray]="dasharray"
      [attr.stroke-dashoffset]="dashoffset"
    />
  </svg>
  <div class="count position-absolute d-flex justify-content-center align-items-center">
    {{ step }}/8
  </div>
</div>
