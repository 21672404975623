import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppState } from '@core/store';
import { selectOrderStateLoading } from '@core/store/order';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ReturnOrderInformationType, ReturnOrderLineType } from '../new-return.component';

@Component({
  selector: 'app-select-products-step',
  templateUrl: './select-products-step.component.html',
  styleUrls: ['./select-products-step.component.scss'],
})
export class SelectProductsStepComponent implements OnInit {
  @Input() activeStep: number;
  @Output() activeStepChange = new EventEmitter<number>();

  @Input() orderInfo: ReturnOrderInformationType;
  @Output() orderInfoChange = new EventEmitter<ReturnOrderInformationType>();

  @Input() orderLines: ReturnOrderLineType[];
  @Output() orderLinesChange = new EventEmitter<ReturnOrderLineType[]>();

  loading$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.loading$ = this.store$.select(selectOrderStateLoading);
  }

  next() {
    if (this.isProductSelectionValid()) {
      this.activeStepChange.emit(this.activeStep + 1);
    }
  }

  private isProductSelectionValid(): boolean {
    const hasCheckedItem = this.orderLines.filter((orderLine) => orderLine.checked).length > 0;
    const allCheckedItemHasReason =
      this.orderLines.filter((orderLine) => orderLine.checked && !orderLine.reason).length === 0;

    return hasCheckedItem && allCheckedItemHasReason;
  }
}
