<app-modal #modal [modalConfig]="modalConfig">
  <form [formGroup]="emailFormGroup" (ngSubmit)="sendEmail()">
    <div class="mb-3">
      <app-text-field-input
        [identifier]="emailFormKey.to"
        label="To"
        i18n-label
        placeholder="Please enter your friend's email address"
        i18n-placeholder
        [formControlName]="emailFormKey.to"
        type="email"
        [isDataPrivate]="true"
      >
      </app-text-field-input>
    </div>
    <div class="mb-3">
      <app-text-field-input
        [identifier]="emailFormKey.yourName"
        label="Your name"
        i18n-label
        placeholder="Please enter your name"
        i18n-placeholder
        [formControlName]="emailFormKey.yourName"
      >
      </app-text-field-input>
    </div>
    <div class="mb-3">
      <app-text-field-input
        [identifier]="emailFormKey.friendName"
        label="Your friend's name"
        i18n-label
        placeholder="Please enter your friend's name"
        i18n-placeholder
        [formControlName]="emailFormKey.friendName"
      >
      </app-text-field-input>
    </div>
    <div class="mb-3">
      <app-text-field-input
        [identifier]="emailFormKey.subject"
        label="Subject"
        i18n-label
        placeholder="Please enter subject for email"
        i18n-placeholder
        [formControlName]="emailFormKey.subject"
      >
      </app-text-field-input>
    </div>
    <div class="mb-3">
      <app-textarea
        [identifier]="emailFormKey.message"
        i18n-label
        label="Message"
        placeholder="Please enter message for email"
        i18n-placeholder
        [formControlName]="emailFormKey.message"
      ></app-textarea>
    </div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary" type="submit" [disabled]="!emailFormGroup.valid" i18n
        >Send</button
      >
    </div>
  </form>
</app-modal>
