import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from '@base/components/layout/default-layout/default-layout.component';
import { UserRoute } from '@core/constants/route.const';
import { AuthGuardService } from '@core/services/auth-guard.service';
import { GuestGuardService } from '@core/services/guest-guard.service';
import { UsaCountryGuardService } from '@core/services/usa-country-guard.service';
import { UserFeatureGuardService } from '@core/services/user-feature-guard.service';
import { AccountComponent } from '@user/components/account/account.component';
import { AddressesComponent } from '@user/components/account/addresses/addresses.component';
import { OrderDetailsComponent } from '@user/components/account/orders/order-details/order-details.component';
import { OrdersComponent } from '@user/components/account/orders/orders.component';
import { PaymentMethodsComponent } from '@user/components/account/payment-methods/payment-methods.component';
import { RecipesComponent } from '@user/components/account/recipes/recipes.component';
import { ReturnsComponent } from '@user/components/account/returns/returns.component';
import { WishlistComponent } from '@user/components/account/wishlist/wishlist.component';
import { RegistrationComponent } from '@user/components/registration/registration.component';
import { AddressEditorComponent } from './components/account/addresses/address-editor/address-editor.component';
import { CcOrderDetailsComponent } from './components/account/orders/cc-order-details/cc-order-details.component';
import { CreatePaymentMethodComponent } from './components/account/payment-methods/create-payment-method/create-payment-method.component';
import { ProfileComponent } from './components/account/profile/profile.component';
import { NewReturnComponent } from './components/account/returns/new-return/new-return.component';
import { ReturnDetailsPageComponent } from './components/account/returns/return-details-page/return-details-page.component';
import { PasswordResetPageComponent } from './components/password-reset-page/password-reset-page.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'registration',
        component: RegistrationComponent,
        canActivate: [GuestGuardService, UserFeatureGuardService],
        data: {
          title: $localize`PH - Registration Page - Title`,
        },
      },
      {
        path: UserRoute.MyAccount,
        component: AccountComponent,
        children: [
          {
            path: 'profile',
            component: ProfileComponent,
            data: { title: $localize`PH - Profile Page - Title` },
          },
          {
            path: 'orders',
            component: OrdersComponent,
            data: { title: $localize`PH - My Orders Page - Title` },
          },
          { path: 'orders/:orderId', component: OrderDetailsComponent },
          { path: 'orders/cc/:poeOrderId', component: CcOrderDetailsComponent },
          {
            path: 'wishlist',
            component: WishlistComponent,
            data: { title: $localize`PH - My Wishlist Page - Title` },
          },
          {
            path: 'addresses',
            component: AddressesComponent,
            data: { title: $localize`PH - My Addresses Page - Title` },
          },
          { path: 'addresses/:addressId', component: AddressEditorComponent },
          {
            path: UserRoute.PaymentMethods,
            component: PaymentMethodsComponent,
            canActivate: [UsaCountryGuardService],
            data: { title: $localize`PH - My Payment Method Page - Title` },
          },
          {
            path: 'payment-methods/new',
            component: CreatePaymentMethodComponent,
            canActivate: [UsaCountryGuardService],
            data: { title: $localize`PH - New Payment Method Page - Title` },
          },
          {
            path: 'recipes',
            component: RecipesComponent,
            data: { title: $localize`PH - Recipes Page - Title` },
          },
          {
            path: 'returns',
            component: ReturnsComponent,
            data: { title: $localize`PH - My Returns Page - Title` },
          },
          { path: 'returns/new', component: NewReturnComponent },
          { path: 'returns/:returnId', component: ReturnDetailsPageComponent },
        ],
        canActivate: [AuthGuardService],
      },
      {
        path: 'reset-password',
        component: PasswordResetPageComponent,
        data: { title: $localize`PH - Reset Password - Title` },
        canActivate: [GuestGuardService],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule {}
