<app-modal #modal [modalConfig]="ModalConfig">
  <div class="text-center">
    <span>
      <i class="h1 bi bi-exclamation-triangle text-danger"></i>
    </span>
    <h3 class="title mt-2 mb-3" i18n> The already created payment will be ignored! </h3>
    <div class="text-center mb-3">
      <span *ngIf="previousPaymentType === PaymentType.CreditOrDebitCard" i18n
        >The previously introduced card and payment will be removed! Are you sure?</span
      >
    </div>
  </div>

  <div class="row mt-4">
    <div class="col text-center">
      <button class="btn btn-outline-dark col-10 text-uppercase" (click)="cancel()" i18n>
        Cancel
      </button>
    </div>
    <div class="col text-center">
      <button class="btn btn-primary col-10 text-uppercase" (click)="continue()" i18n>Yes</button>
    </div>
  </div>
</app-modal>
