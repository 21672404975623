import { Component, Input, OnInit } from '@angular/core';
import { TableSortDirectionCycle } from '@core/constants/table-sort-direction-cycle';
import { TableSortDirection } from '@core/enums/table-sort-direction.enum';
import { ConsultantCornerOrderItem } from '@core/store/order/order-state-models';
import { compare } from '../../common/order-sort-common';

@Component({
  selector: 'app-cc-order-details-table',
  templateUrl: './cc-order-details-table.component.html',
  styleUrls: ['./cc-order-details-table.component.scss'],
})
export class CcOrderDetailsTableComponentComponent implements OnInit {
  @Input() ccOrderItems: ConsultantCornerOrderItem[];

  readonly OrderItemColumns = [
    { title: $localize`Product`, type: 'productName' },
    { title: $localize`Qty`, type: 'quantity' },
    { title: $localize`Price`, type: 'price' },
    { title: $localize`Total`, type: 'total' },
  ];

  sortableCcOrderItems: ConsultantCornerOrderItem[];
  tableSortDirection = TableSortDirection;
  sortColumn: string | null;
  direction: TableSortDirection;

  constructor() {}

  ngOnInit() {
    this.sortableCcOrderItems = this.ccOrderItems;
  }

  onSort(column: string): void {
    if (column !== this.sortColumn) {
      this.direction = TableSortDirection.none;
    }
    this.direction = TableSortDirectionCycle[this.direction];
    this.sortColumn = column;

    if (this.direction === TableSortDirection.none) {
      this.sortableCcOrderItems = this.ccOrderItems;
    } else {
      this.sort(column, this.direction);
    }
  }

  private sort(column: string, direction: TableSortDirection) {
    this.sortableCcOrderItems = this.sortableCcOrderItems.slice().sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === TableSortDirection.asc ? res : -res;
    });
  }
}
