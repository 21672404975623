import produce from 'immer';
import { PaymentMethodType, PaymentState } from './payment-state-models';
import { PaymentActionTypes } from './payment.actions';
import { mapCreateCardPaymentMethodError } from './payment.effects';

export const initialState: PaymentState = {
  customerId: null,
  partyOrderCustomerId: null,
  loading: false,
  paymentInfo: null,
  cardInfo: {},
  createdCardPaymentMethod: null,
  hasSavedCard: false,
  paymentMethods: {
    loading: false,
    items: [],
    createFailed: false,
  },
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case PaymentActionTypes.fetchClientToken:
    case PaymentActionTypes.fetchPartyOrderClientToken:
      return draft;

    case PaymentActionTypes.fetchClientTokenSuccess:
      draft.cardInfo.clientToken = action.payload.clientToken;
      draft.customerId = action.payload.customerId;
      return draft;

    case PaymentActionTypes.fetchPartyOrderClientTokenSuccess:
      draft.cardInfo.clientToken = action.payload.clientToken;
      draft.partyOrderCustomerId = action.payload.customerId;
      return draft;

    case PaymentActionTypes.fetchClientTokenFailure:
    case PaymentActionTypes.fetchPartyOrderClientTokenFailure:
      return draft;

    case PaymentActionTypes.resetCardPaymentInfo:
      draft.cardInfo = initialState.cardInfo;
      draft.hasSavedCard = initialState.hasSavedCard;
      return draft;

    case PaymentActionTypes.fetchPaymentMethods:
      draft.paymentMethods.loading = true;
      draft.paymentMethods.items = [];
      return;

    case PaymentActionTypes.fetchPaymentMethodsSuccess:
      draft.paymentMethods.loading = false;
      draft.paymentMethods.items = action.paymentMethods;
      return;

    case PaymentActionTypes.fetchPaymentMethodsFailure:
      draft.paymentMethods.loading = false;
      return;

    case PaymentActionTypes.storePaymentInfoSuccess:
      draft.paymentInfo = action.payload;
      draft.hasSavedCard = true;
      return;

    case PaymentActionTypes.storePaymentInfoFailure:
      draft.paymentInfo = initialState.paymentInfo;
      draft.hasSavedCard = false;
      return;

    case PaymentActionTypes.storeCardPaymentInfo:
      draft.cardInfo.cardType = action.payload;
      return;

    case PaymentActionTypes.resetPaymentInfo:
      draft.paymentInfo = initialState.paymentInfo;
      return;

    case PaymentActionTypes.resetPaymentMethods:
      draft.paymentMethods = initialState.paymentMethods;
      return;

    case PaymentActionTypes.createPaymentMethod:
      draft.paymentMethods.loading = true;
      draft.paymentMethods.createFailed = false;
      return;

    case PaymentActionTypes.createPaymentMethodSuccess:
      draft.paymentMethods.loading = false;
      draft.createdCardPaymentMethod = action.payload;
      return;

    case PaymentActionTypes.createPaymentMethodFailure:
      draft.paymentMethods.loading = false;
      draft.createdCardPaymentMethod = mapCreateCardPaymentMethodError(action.error.error);
      draft.paymentMethods.createFailed = true;
      return;

    case PaymentActionTypes.resetCreatedPaymentMethod:
      draft.createdCardPaymentMethod = initialState.createdCardPaymentMethod;
      draft.paymentMethods.createFailed = initialState.paymentMethods.createFailed;
      return;

    case PaymentActionTypes.setAsDefault:
      const newItems = draft.paymentMethods.items.map((item) => ({
        ...item,
        type: item.id === action.token ? PaymentMethodType.default : PaymentMethodType.other,
      }));
      draft.paymentMethods.items = newItems;
      return;

    case PaymentActionTypes.deletePaymentMethod:
      draft.paymentMethods.loading = true;
      draft.paymentMethods.items = draft.paymentMethods.items.filter(
        (item) => item.id !== action.token,
      );
      return;

    case PaymentActionTypes.createCheckoutPayPalOrderSuccess:
      draft.paymentInfo = {
        ...draft.paymentInfo,
        order: action.payload,
      };
      return;

    case PaymentActionTypes.updateCheckoutPayPalOrderSuccess:
      draft.paymentInfo = {
        ...draft.paymentInfo,
        order: { orderId: action.payload.payPalOrderId },
      };
      return;

    case PaymentActionTypes.createPartyOrderPayPalOrderSuccess:
      draft.paymentInfo = {
        ...draft.paymentInfo,
        order: { orderId: action.payload.payPalOrderId },
      };
      return;

    default:
      return draft;
  }
}, initialState);
