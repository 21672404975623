import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppState } from '@core/store';
import { selectIsAuthenticated } from '@core/store/auth';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private store$: Store<AppState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store$.select(selectIsAuthenticated).pipe(
      filter((isAuthenticated) => isAuthenticated !== undefined),
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.router.navigateByUrl('/login');
        }
        return isAuthenticated;
      }),
    );
  }
}
