import { Component, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-gtm-initializer',
  template: '',
})
export class GtmInitializerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    const script0 = document.createElement('script');
    script0.innerText = 'window.dataLayer = window.dataLayer || [];';

    const script1 = document.createElement('script');
    script1.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${environment.gtmContainerId}');`;

    const script2 = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.setAttribute(
      'src',
      `https://www.googletagmanager.com/ns.html?id=${environment.gtmContainerId}`,
    );
    iframe.setAttribute('height', '0');
    iframe.setAttribute('width', '0');
    iframe.setAttribute('style', 'display:none;visibility:hidden');
    script2.appendChild(iframe);

    document.head.appendChild(script0);
    document.head.appendChild(script1);
    document.body.appendChild(script2);
  }
}
