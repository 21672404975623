<div class="container text-center">
  <h3 i18n>Paypal account added successfully</h3>
  <div class="mb-3" i18n>
    After submitting your order you will receive a payment confirmation email shortly
  </div>
  <div class="d-flex justify-content-center">
    <button class="btn btn-outline-dark text-uppercase w-50" (click)="resetPayment.emit()" i18n>
      Change paypal
    </button>
  </div>
</div>
