import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { getProductTypeMessage } from '@core/constants/product-type-message';
import { AddToCartConfirmModelData } from '@core/store/cart/cart-state-models';
import {
  ProductDetailsItem,
  ProductItem,
  ProductQuickView,
} from '@core/store/product/product-state-models';
import { PhCurrencyPipe } from '@shared/pipes/ph-currency.pipe';

@Component({
  selector: 'app-product-price-message',
  templateUrl: './product-price-message.component.html',
  styleUrls: ['./product-price-message.component.scss'],
  providers: [PhCurrencyPipe],
})
export class ProductPriceMessageComponent implements OnChanges {
  @Input() product: ProductDetailsItem | ProductItem | ProductQuickView | AddToCartConfirmModelData;
  ProductTypeMessage;

  constructor(private phCurrencyPipe: PhCurrencyPipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    let product: ProductDetailsItem | ProductItem | ProductQuickView | AddToCartConfirmModelData =
      changes.product?.currentValue;

    if (product) {
      this.ProductTypeMessage = getProductTypeMessage(
        this.phCurrencyPipe.transform(product.discountedPrice),
      );
    }
  }
}
