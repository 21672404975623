import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { ModalConfig } from '@core/models/modal-config.model';
import { ModalComponent } from '@shared/components/modal/modal.component';

@Component({
  selector: 'app-delete-from-cart-confirm-dialog',
  templateUrl: './delete-from-cart-confirm-dialog.component.html',
  styleUrls: ['./delete-from-cart-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteFromCartConfirmDialogComponent {
  @Input() removeItem: () => void;

  modalConfig: ModalConfig = {
    title: $localize`Remove product`,
    dismissButtonLabel: $localize`Cancel`,
    closeButtonLabel: $localize`Remove it`,
    onClose: this.onClose.bind(this),
    ngbModalOptions: { size: 'sm' },
  };

  @ViewChild('modal') private modalComponent: ModalComponent;

  constructor() {}

  onClose() {
    this.removeItem && this.removeItem();
  }

  async open() {
    return await this.modalComponent.open();
  }
}
