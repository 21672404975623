<h1 class="text-title mt-3" i18n>Access to Your backoffice portal</h1>
<h6 class="text-subtitle mt-3 mb-5 d-none d-sm-block" i18n
  >Password for Consultant Corner and Consultant Agreement</h6
>
<h6 class="text-subtitle mt-3 mb-5 d-block d-sm-none" i18n>Password for Consultant Corner</h6>

<p class="first-paragraph mb-4" i18n
  >Set a password so you can
  <span class="highlighted-text"
    >login to our free mobile friendly Consultant's Corner website</span
  >
  to enter your orders and learn more about your new Princess House business.</p
>
<form [formGroup]="passwordFormGroup">
  <div class="row">
    <div class="col-12 col-md-4 mb-4">
      <app-password-input
        [identifier]="Password"
        label="Password"
        i18n-label
        placeholder="Password"
        i18n-placeholder
        (passwordChange)="handlePasswordChange($event)"
        required="true"
        [labelStyle]="passwordLabel"
        [maxLength]="PasswordMaxLength"
        [formErrorMessages]="ErrorMessages"
        [formControlName]="Password"
      ></app-password-input>
      <app-password-strength-bar [password]="passwordContent"></app-password-strength-bar>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-4 mb-4">
      <app-password-input
        [identifier]="PasswordConfirm"
        label="Re-enter Password"
        i18n-label
        placeholder="Re-enter your password"
        i18n-placeholder
        required="true"
        noMatchingPasswordWarningText="Please confirm your password. It must match the password above."
        i18n-noMatchingPasswordWarningText
        [labelStyle]="passwordLabel"
        [maxLength]="PasswordMaxLength"
        [formErrorMessages]="ErrorMessages"
        [groupValidationErrorType]="FormErrorTypes.passwordConfirm"
        [formControlName]="PasswordConfirm"
      ></app-password-input>
    </div>
  </div>
</form>

<p class="mention mt-3 mb-5 pb-3 pb-md-5" i18n>
  Minimum {{ PasswordMinLength }}, maximum {{ PasswordMaxLength }} characters, at least one upper
  case letter and one number or symbol
</p>
