import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Locale } from '@core/enums/locale';
import { MediaSuffix } from '@core/enums/media-suffix.enum';
import { ImageDetails } from '@core/models/image.model';
import { VimeoVideoDetails } from '@core/models/vimeo-video-details';

export const MediaTypeLocale = {
  [MediaSuffix.primaryImage]: $localize`primary image`,
  [MediaSuffix.secondaryImage]: $localize`secondary image`,
  [MediaSuffix.video]: $localize`video`,
};

export interface productAltTextParams {
  productName?: string;
  collectionName?: string;
  suffix?: MediaSuffix;
}

@Injectable({
  providedIn: 'root',
})
export class AltTextUtilService {
  constructor(@Inject(LOCALE_ID) private localeId: Locale) {}

  getPhImagesWithAltText(images: ImageDetails[], altText: string) {
    return images.map((image) => ({ ...image, altText }));
  }

  getProductImagesWithAltText(
    images: ImageDetails[],
    params?: productAltTextParams,
  ): ImageDetails[] {
    let altText = '';

    switch (this.localeId) {
      case Locale.EsMX:
      case Locale.EsUS:
        altText = `${params.productName} ${params.collectionName}`;
        break;

      default:
        altText = `${params.collectionName} ${params.productName}`;
        break;
    }

    if (params?.suffix) {
      altText = `${altText} ${MediaTypeLocale[params.suffix]}`;
    }

    if (params) {
      return images.map((image) => ({ ...image, altText }));
    }

    return images;
  }

  getVimeoDetailsWithAltText(
    vimeoDetails: VimeoVideoDetails,
    params?: productAltTextParams,
  ): VimeoVideoDetails {
    let altText = '';

    switch (this.localeId) {
      case Locale.EsUS:
        altText = `${params.productName} ${params.collectionName}`;
        break;

      default:
        altText = `${params.collectionName} ${params.productName}`;
        break;
    }

    altText = `${altText} ${MediaTypeLocale[MediaSuffix.video]}`;

    return { ...vimeoDetails, altText };
  }
}
