import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CartResponse } from '@core/dto/cart.dto';
import { ProductRecommendationsResponse } from '@core/dto/product-details.dto';
import { Locale } from '@core/enums/locale';
import { AddToCart } from '@core/store/cart/cart-state-models';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { keysToCamelCase } from '@shared/utils/object-transform-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  baseUrl: string = environment.ecommerceBackendUri;
  options: object;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {
    this.options = {
      withCredentials: true,
      params: new HttpParams({
        fromObject: { culture: getCulture(localeId) },
      }),
    };
  }

  fetchCart(): Observable<CartResponse> {
    return this.http.get<CartResponse>(`${this.baseUrl}api/cart`, this.options);
  }

  updateOrderLine(orderLineId: number, quantity: number): Observable<CartResponse> {
    return this.http.put<CartResponse>(
      `${this.baseUrl}api/cart`,
      { orderLineId, quantity },
      this.options,
    );
  }

  mergeCarts(): Observable<any> {
    return this.http.post(`${this.baseUrl}api/cart/merge`, {}, this.options);
  }

  newCart(): Observable<any> {
    return this.http.post(`${this.baseUrl}api/cart`, {}, this.options);
  }

  addToCart(sku: string, quantity: number): Observable<AddToCart> {
    return this.http
      .post<AddToCart>(`${this.baseUrl}api/cart/add`, { sku, quantity }, this.options)
      .pipe(map((res) => keysToCamelCase(res)));
  }

  deleteOrderLine(orderLineId: number): Observable<CartResponse> {
    return this.http.delete<CartResponse>(`${this.baseUrl}api/cart/${orderLineId}`, this.options);
  }

  fetchProductsRecommendations(skus: string[]): Observable<ProductRecommendationsResponse> {
    return this.http.get<ProductRecommendationsResponse>(
      `${this.baseUrl}api/product/recommendation/multiple`,
      {
        params: new HttpParams({
          fromObject: { 'skus[]': skus, culture: getCulture(this.localeId) },
        }),
      },
    );
  }

  addSurpriseBuy(): Observable<CartResponse> {
    return this.http.put<CartResponse>(`${this.baseUrl}api/cart/addSurprise`, {}, this.options);
  }
}
