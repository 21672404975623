import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
} from '@angular/core';
import { AppState } from '@core/store';
import { selectIsAuthenticated } from '@core/store/auth';
import { selectCart } from '@core/store/cart';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { ProductUtilService } from '@shared/utils/product-util.service';
import { UserUtilService } from '@shared/utils/user-util.service';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderContentComponent implements OnInit {
  @Input() menuCollapsed: boolean = true;
  @Output() toggleMenu = new EventEmitter<void>();

  readonly feature = environment.feature;
  quantityCount$: Observable<number>;
  isAuthenticated$: Observable<boolean>;
  isSpanishLanguage: boolean;

  constructor(
    private store$: Store<AppState>,
    @Inject(LOCALE_ID) private localeId: string,
    public productUtilService: ProductUtilService,
    public userUtilService: UserUtilService,
  ) {}

  ngOnInit(): void {
    this.isAuthenticated$ = this.store$.select(selectIsAuthenticated);
    this.quantityCount$ = this.store$.select(selectCart).pipe(
      distinctUntilChanged(
        (a, b) => JSON.stringify(a?.orderLines) === JSON.stringify(b?.orderLines),
      ),
      map((x) =>
        x?.orderLines.length
          ? x?.orderLines.map((orderLine) => orderLine.quantity).reduce((sum, qt) => sum + qt)
          : 0,
      ),
    );
    this.isSpanishLanguage = !!this.localeId.includes('es');
  }
}
