<app-page-title
  *ngIf="activeNavId !== CheckoutPages.OrderConfirmation"
  title="Checkout - Step {{ activeNavId }} of 4"
  i18n-title
></app-page-title>
<app-page-title
  *ngIf="activeNavId === CheckoutPages.OrderConfirmation"
  title="Order Confirmation"
  i18n-title
></app-page-title>
<div class="container">
  <div class="row d-lt-none">
    <div class="col">
      <app-navigator
        [navSteps]="navigationStep"
        [activeId]="activeNavId"
        (activeIdChange)="activeIdChange($event)"
      ></app-navigator>
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeNavId" class="nav-tabs border-bottom-0">
        <li [ngbNavItem]="navigationStep[0].id">
          <ng-template ngbNavContent>
            <app-checkout-personal-info
              (clickNext)="navigateTo(navigationStep[$event].id)"
              [title]="activeStepTitle"
            ></app-checkout-personal-info>
          </ng-template>
        </li>
        <li [ngbNavItem]="navigationStep[1].id">
          <ng-template ngbNavContent>
            <app-checkout-order-summary
              (clickNext)="navigateTo(navigationStep[2].id)"
              (clickBack)="navigateTo(navigationStep[0].id)"
              [title]="activeStepTitle"
            ></app-checkout-order-summary>
          </ng-template>
        </li>
        <li [ngbNavItem]="navigationStep[2].id">
          <ng-template ngbNavContent>
            <app-checkout-payment
              (clickNext)="navigateTo(navigationStep[3].id)"
              (clickBack)="navigateTo(navigationStep[1].id)"
              [nextStepId]="navigationStep[3].id"
            ></app-checkout-payment>
          </ng-template>
        </li>
        <li [ngbNavItem]="navigationStep[3].id">
          <ng-template ngbNavContent>
            <app-checkout-order-confirmation></app-checkout-order-confirmation>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
  <div class="row d-md-none mb-0">
    <div class="col-auto pr-0"></div>
    <div class="col-5 font-weight-bold d-flex align-items-center border-y-1 pl-0">
      <span>{{ activeStepTitle }}</span>
    </div>
    <div class="col font-size-tiny d-flex align-items-center justify-content-end border-y-1 pl-0">
      <span *ngIf="nextStepTitle" i18n>Next: {{ nextStepTitle | titlecase }}</span>
    </div>
    <div class="col-2 d-flex align-items-center justify-content-end">
      <app-circular-progress
        [numberOfSteps]="navigationStep.length"
        [currentStep]="activeNavId"
      ></app-circular-progress>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col" [ngbNavOutlet]="nav"></div>
  </div>
  <hr />
  <div class="row">
    <div
      class="col-md-1 offset-md-1 d-none d-md-flex align-items-center justify-content-end text-right"
    >
      <i class="bi bi-lock"></i>
    </div>
    <div class="col">
      <span i18n>
        When you order online with Princess House, rest assure that you're using secret site. Also
        we are respect your privacy and do NOT share any customer information. For details please
        review our privacy policy.
      </span>
    </div>
    <div class="col-auto col-md-1 px-0"></div>
  </div>
</div>
