import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RecipeState } from './recipe-state-models';

export const recipeFeatureKey = 'recipeState';

export const selectRecipeStateFeature = createFeatureSelector<RecipeState>(recipeFeatureKey);

export const selectRecipes = createSelector(selectRecipeStateFeature, (state: RecipeState) =>
  state ? state.recipes.items : [],
);

export const selectAllRecipesFetched = createSelector(
  selectRecipeStateFeature,
  (state: RecipeState) => (state ? state.recipes.isAllFetched : false),
);

export const selectRecipeCategories = createSelector(
  selectRecipeStateFeature,
  (state: RecipeState) => (state ? state.recipeCategories : []),
);

export const selectRecipeDetails = createSelector(selectRecipeStateFeature, (state: RecipeState) =>
  state ? state.recipeDetails : null,
);

export const selectUserRecipes = createSelector(selectRecipeStateFeature, (state: RecipeState) =>
  state ? state.userRecipes : null,
);

export const selectIsRecipeCategoriesFetched = createSelector(
  selectRecipeStateFeature,
  (state: RecipeState) => (state ? state.isRecipeCategoriesFetched : false),
);

