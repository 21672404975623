<div class="container">
  <div class="row">
    <h3 class="col-12" i18n>Find a consultant</h3>
  </div>

  <app-consultant-finder
    #consultantFinder
    [type]="ConsultantFinderType.find"
    (valid)="onFinderValidChange($event)"
    (submitted)="onFinderSubmitted($event)"
  ></app-consultant-finder>

  <div class="mt-5 d-flex justify-content-start">
    <button
      class="btn btn-primary text-uppercase mb-4"
      [disabled]="!finderIsValid"
      (click)="search()"
      i18n
      >Search</button
    >
  </div>

  <div class="font-italic mb-4">
    <span i18n>Don't have a Consultant?</span>
    <a class="link-highlight" routerLink="/consultant-locator"
      ><span class="mx-1" i18n>Search for one near me</span>>
    </a></div
  >

  <app-consultant-finder-result
    [filter]="filter"
    [finderType]="ConsultantFinderType.find"
    (consultantSelected)="selected()"
  ></app-consultant-finder-result>
</div>
