<div class="error-message-container col-12 col-md-10">
  <div>
    <div class="row">
      <div class="col-8 error-header">
        <span i18n>
          Card error
          <i class="bi bi-exclamation-triangle"></i>
        </span>
      </div>
      <div class="col-4 change-card" (click)="resetPaymentClick()">
        <span i18n>Change</span>
      </div>
    </div>
    <div class="error-name">
      {{ getPaymentErrorMessage() }}
    </div>

    <div class="card-details" *ngIf="cardInfo">
      <section class="row">
        <div class="col-12 col-md-10">
          <div class="form-group">
            <label for="error-card-type" i18n>Card type</label>
            <input
              id="error-card-type"
              type="text"
              readonly
              [ngModel]="cardTypeDisplayValue"
              name="installment"
            />
          </div>
        </div>
      </section>
      <section class="row">
        <div class="col-12 col-md-10">
          <div class="form-group">
            <label for="error-card-holder-name" i18n>Cardholder Name</label>
            <input
              id="error-card-holder-name"
              type="text"
              readonly
              [ngModel]="cardInfo.cardholderName"
              name="cardholderName"
            />
          </div>
        </div>
      </section>

      <section class="row">
        <div class="col-12 col-md-10">
          <div class="form-group">
            <label for="error-card-number" i18n>Card Number</label>
            <input
              id="error-card-number"
              type="text"
              readonly
              [ngModel]="cardInfo.maskedNumber"
              name="maskedNumber"
            />
          </div>
        </div>
      </section>

      <section class="row">
        <div class="col-12 col-md-10">
          <div class="form-group">
            <label for="error-expiration-date" i18n>Expiration date</label>
            <input
              id="error-expiration-date"
              type="text"
              readonly
              [ngModel]="cardInfo.expirationDate"
              name="expirationDate"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
