import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BlogState } from './blog-state-models';

export const blogFeatureKey = 'blogState';

export const selectBlogStateFeature = createFeatureSelector<BlogState>(blogFeatureKey);

export const selectBlogs = createSelector(selectBlogStateFeature, (state: BlogState) =>
  state ? state.blogs.items : [],
);

export const selectAllBlogsFetched = createSelector(selectBlogStateFeature, (state: BlogState) =>
  state ? state.blogs.isAllFetched : false,
);

export const selectBlogCategories = createSelector(selectBlogStateFeature, (state: BlogState) =>
  state ? state.blogCategories : [],
);

export const selectBlogDetails = createSelector(selectBlogStateFeature, (state: BlogState) =>
  state ? state.blogDetails : null,
);

export const selectIsBlogCategoriesFetched = createSelector(
  selectBlogStateFeature,
  (state: BlogState) => (state ? state.isBlogCategoriesFetched : false),
);
