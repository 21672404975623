<div *ngIf="hasAddressErrorCode(codes.AE08)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    An address element after the house number, in most cases the sub-premise, was not valid.
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AE09)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    An address element after the house number, in most cases the sub-premise, was missing.
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AE12)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    The PO (Post Office Box), RR (Rural Route), or HC (Highway Contract) Box number is invalid.
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AE13)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    The PO (Post Office Box), RR (Rural Route), or HC (Highway Contract) Box number is missing.
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AC13)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    The sub premise (suite) type was added or changed, such as from “STE” to “APT.”
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AC14)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">The sub premise (suite) unit number was added or changed.</span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AC16)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    <!-- prettier-ignore -->
    The subadministrative area was added or changed.
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AE10)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    The premise (house or building) number for the address is not valid.
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AE11)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    The premise (house or building) number for the address is missing.
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AC10)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    The thoroughfare (street) name was added or changed due to a spelling correction.
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AC11)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    <!-- prettier-ignore -->
    The thoroughfare (street) leading or trailing type was added or changed, such as from "St" to "Rd."
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AC12)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    <!-- prettier-ignore -->
    The thoroughfare (street) pre-directional or post-directional was added or changed, such as from "N" to "NW."
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AE02)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    <!-- prettier-ignore -->
    Could not match the input street to a unique street name. Either no matches or too many matches found.
  </span>
</div>
<div *ngIf="hasAddressErrorCode(codes.AE03)" class="warning-message">
  <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
  <span i18n="Address error|shared">
    <!-- prettier-ignore -->
    The combination of directionals (N, E, SW, etc) and the suffix (AVE, ST, BLVD) is not correct and produced multiple possible matches.
  </span>
</div>
