import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { AppState } from '@core/store';
import { Cart, OrderLine } from '@core/store/cart/cart-state-models';
import { deleteFromCart, updateOrderLineQty } from '@core/store/cart/cart.actions';
import { Store } from '@ngrx/store';
import { DeleteFromCartConfirmDialogComponent } from '../delete-from-cart-confirm-dialog/delete-from-cart-confirm-dialog.component';

@Component({
  selector: 'app-order-lines',
  templateUrl: './order-lines.component.html',
  styleUrls: ['./order-lines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderLinesComponent {
  @Input() readOnly: boolean;
  @Input() disableHeader: boolean = false;
  @Input() cart: Cart;
  @Input() productClickable: boolean = false;

  selectedOrderLineId: number;

  @ViewChild('deleteConfirmModal') private deleteConfirmModal: DeleteFromCartConfirmDialogComponent;

  constructor(private store$: Store<AppState>) {}

  openDeleteConfirmationModal = (orderLineId: number): void => {
    this.selectedOrderLineId = orderLineId;
    this.deleteConfirmModal.open();
  };

  deleteCartItem = () => {
    this.store$.dispatch(deleteFromCart({ orderLineId: this.selectedOrderLineId }));
  };

  updateOrderLineQty = (orderLine: OrderLine, option: any): void => {
    this.store$.dispatch(
      updateOrderLineQty({
        orderLineId: orderLine.orderLineId,
        quantity: +option.value,
      }),
    );
  };
}
