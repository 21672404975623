import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CardPaymentTypes, PaymentImageSrc } from '@core/constants/payment.consts';
import { Country } from '@core/enums/country.enum';
import { CreateCardPaymentErrorType } from '@core/enums/create-card-payment-error-type.enum';
import { PaymentCardType, PaymentType } from '@core/enums/payment-type.enum';
import { PaymentMethodCardInfo } from '@core/store/payment/payment-state-models';
import { environment } from '@env';
import { SelectOption } from '@shared/components/select/select.component';
import { isMexEnv } from '@shared/utils/environment-utils';

// TODO: Payment Method - clean up file

@Component({
  template: '',
})
export abstract class PaymentMethodBaseComponent {
  public readonly Country = Country;
  public readonly PaymentType = PaymentType;
  public readonly PaymentCardType = PaymentCardType;
  public readonly CreatePaymentErrorType = CreateCardPaymentErrorType;
  public readonly PaymentImageSrc = PaymentImageSrc;
  public readonly storeLocatorUrl = environment.storeLocatorUrl;
  public readonly CardPaymentTypes = CardPaymentTypes;

  public readonly FormGroup = {
    PaymentCard: 'paymentCard',
  };

  public isMexEnv = isMexEnv;
  public isPaymentMethodRequestable: boolean = false;
  public isSubmitted: boolean = false;
  public selectedPaymentType: PaymentType = PaymentType.CreditOrDebitCard;
  public selectedCardPaymentType: PaymentCardType = PaymentCardType.DebitCard;
  public cardPaymentTypeOptions: SelectOption[] = Object.keys(this.CardPaymentTypes).map((key) => ({
    name: this.CardPaymentTypes[key],
    value: key,
  }));
  public paymentFormGroup: FormGroup;
  public isCardPaymentError: boolean = false;
  public cardPaymentErrorType: CreateCardPaymentErrorType = CreateCardPaymentErrorType.None;
  public cardPaymentMethodCardInfo: PaymentMethodCardInfo;
}
