import * as FileSaver from 'file-saver';

export function saveFile(blob: Blob, fileName: string, type: string): void {
  let file: File | undefined;
  file = new File([blob], fileName, { type: type });

  if (file) {
    FileSaver.saveAs(file);
  }
}
