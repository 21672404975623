<div class="root row mb-2">
  <div
    class="col-12 text-center"
    *ngIf="
      (loading$ | async) === false &&
        (consultants$ | async).length === 0 &&
        (consultantFinder$ | async).hasMore === false;
      else content
    "
  >
    <div class="my-4" i18n>Sorry, we couldn't find the Consultant you're looking for.</div>
  </div>

  <div *ngIf="loading$ | async" class="d-flex justify-content-center w-100">
    <app-ph-spinner width="40px"></app-ph-spinner>
  </div>
</div>

<ng-template #content>
  <ng-container *ngIf="consultants$ | async as consultants">
    <ng-container *ngIf="consultants.length">
      <div class="col-12 text-center mb-3" *ngIf="(consultants$ | async).length" i18n>
        Select a consultant to continue the process
      </div>
      <div i18n class="col-12">Search result(s)</div>

      <div class="col-12 listWrapper w-100" #listWrapper>
        <ng-container *ngFor="let consultant of consultants">
          <ng-container
            [ngTemplateOutlet]="consultantCard"
            [ngTemplateOutletContext]="{ consultant: consultant }"
          >
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngIf="consultantFinder$ | async as consultantFinder">
        <div
          *ngIf="loadMoreButtonIsVisible && consultantFinder.hasMore && !consultantFinder.loading"
          class="col-12 my-2 d-flex justify-content-center"
        >
          <button class="btn btn-outline-primary" (click)="loadMore()">{{
            ButtonText[finderType]
          }}</button>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #consultantCard let-consultant="consultant">
  <div class="consultantCard d-flex align-items-center w-100 p-2 my-1">
    <img
      [src]="consultant.profileImage.url || imagePlaceholder"
      [altText]="consultant.profileImage.altText"
    />
    <div class="flex-grow-1 mx-2">{{ consultant.fullName }}</div>
    <button
      class="btn btn-primary btn-sm text-uppercase"
      (click)="selectConsultant(consultant)"
      i18n
      >Select and continue</button
    >
  </div>
</ng-template>
