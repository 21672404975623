import {
  AddressValidationResponse,
  CreateAddressRequest,
  UpdateAddressRequest,
} from '@core/dto/address.dto';
import { createAction, props } from '@ngrx/store';
import { Address, CountryState } from './address-state-models';
import { PhErrorResponse } from '@core/dto/ph-error';

export enum AddressActionTypes {
  resetAddressState = '[Address] Reset address state',

  fetchAddresses = '[Address] Fetch addresses',
  fetchAddressesSuccess = '[Address] Fetch addresses success',
  fetchAddressesFailure = '[Address] Fetch addresses failure',

  updateAddress = '[Address] Update address',
  updateAddressSuccess = '[Address] Update address success',
  updateAddressFailure = '[Address] Update address failure',

  createAddress = '[Address] Create address',
  createAddressSuccess = '[Address] Create address success',
  createAddressFailure = '[Address] Create address failure',

  deleteAddress = '[Address] Delete address',
  deleteAddressSuccess = '[Address] Delete address success',
  deleteAddressFailure = '[Address] Delete address failure',

  fetchCountryStates = '[Address] Fetch country states',
  fetchCountryStatesSuccess = '[Address] Fetch country states success',
  fetchCountryStatesFailure = '[Address] Fetch country states failure',

  resetAddressValidationModal = '[Address] Reset address validation modal',

  addressValidation = '[Address] Address validation',
}

export const resetAddressState = createAction(AddressActionTypes.resetAddressState);
export const fetchAddresses = createAction(AddressActionTypes.fetchAddresses);
export const fetchAddressesSuccess = createAction(
  AddressActionTypes.fetchAddressesSuccess,
  props<{ addresses: Address[] }>(),
);
export const fetchAddressesFailure = createAction(
  AddressActionTypes.fetchAddressesFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Update
 */
export const updateAddress = createAction(
  AddressActionTypes.updateAddress,
  props<{ address: UpdateAddressRequest }>(),
);
export const updateAddressSuccess = createAction(
  AddressActionTypes.updateAddressSuccess,
  props<{ addressValidation: AddressValidationResponse }>(),
);
export const updateAddressFailure = createAction(
  AddressActionTypes.updateAddressFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Create
 */
export const createAddress = createAction(
  AddressActionTypes.createAddress,
  props<{ address: CreateAddressRequest }>(),
);
export const createAddressSuccess = createAction(
  AddressActionTypes.createAddressSuccess,
  props<{ addressValidation: AddressValidationResponse }>(),
);
export const createAddressFailure = createAction(
  AddressActionTypes.createAddressFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Delete
 */
export const deleteAddress = createAction(
  AddressActionTypes.deleteAddress,
  props<{ id: number }>(),
);
export const deleteAddressSuccess = createAction(AddressActionTypes.deleteAddressSuccess);
export const deleteAddressFailure = createAction(
  AddressActionTypes.deleteAddressFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Fetch Country States
 */
export const fetchCountryStates = createAction(AddressActionTypes.fetchCountryStates);
export const fetchCountryStatesSuccess = createAction(
  AddressActionTypes.fetchCountryStatesSuccess,
  props<{ countryStates: CountryState[] }>(),
);
export const fetchCountryStatesFailure = createAction(
  AddressActionTypes.fetchCountryStatesFailure,
  props<{ error: PhErrorResponse }>(),
);

export const resetAddressValidationModal = createAction(
  AddressActionTypes.resetAddressValidationModal,
);

export const addressValidation = createAction(
  AddressActionTypes.addressValidation,
  props<{ addressValidation: AddressValidationResponse }>(),
);
