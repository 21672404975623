import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PartyOrderPaymentPages } from '@core/enums/party-order-payment-pages.enum';
import { RiskData } from '@core/models/risk-data.model';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { fetchPartyOrderData, resetPartyOrderFetched } from '@core/store/order/order.actions';
import { Store } from '@ngrx/store';
import { NavigationStep } from '@shared/components/stepper-base/navigation-step.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-party-order-payment',
  templateUrl: './party-order-payment.component.html',
  styleUrls: ['./party-order-payment.component.scss'],
})
export class PartyOrderPaymentComponent implements OnInit, OnDestroy {
  pages = PartyOrderPaymentPages;
  activePage: PartyOrderPaymentPages;
  navigationSteps: NavigationStep[];
  isCollectRiskDataEnabled: boolean = false;
  title: string;
  nextTitle: string;

  public riskData?: RiskData;

  private subscriptions: Subscription = new Subscription();

  constructor(
    appInitService: AppInitService,
    private store$: Store<AppState>,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isCollectRiskDataEnabled = appInitService.Settings.ec.partyOrderCollectRiskDataFromInput;
  }

  ngOnInit(): void {
    this.navigationSteps = this.initNavigationSteps();
    this.activePage = this.navigationSteps[0].id;
    this.changeStepTitles(PartyOrderPaymentPages.SearchPage);

    this.subscriptions.add(
      this.activatedRoute.paramMap.subscribe((pMap) => {
        const orderNumber = pMap.get('orderNumber');
        if (orderNumber) {
          this.store$.dispatch(fetchPartyOrderData({ orderNumber }));
        }
      }),
    );
  }

  reset(): void {
    this.riskData = undefined;
    this.store$.dispatch(resetPartyOrderFetched());
  }

  changePage(page: PartyOrderPaymentPages): void {
    if (page === this.pages.SearchPage) {
      this.reset();
    }
    this.changeStepTitles(page);
    this.activePage = page;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initNavigationSteps(): NavigationStep[] {
    return [
      { id: PartyOrderPaymentPages.SearchPage, title: $localize`Order number` },
      { id: PartyOrderPaymentPages.AddressPage, title: $localize`Billing Address` },
      { id: PartyOrderPaymentPages.InfoPage, title: $localize`Payment` },
    ].sort((a, b) => a.id - b.id);
  }

  private changeStepTitles(stepId: number): void {
    this.title = this.navigationSteps.find((navStep) => navStep.id === stepId)?.title;
    this.nextTitle = this.navigationSteps.find((navStep) => navStep.id === stepId + 1)?.title;
  }
}
