import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartModule } from '@cart/cart.module';
import { ConsultantModule } from '@consultant/consultant.module';
import { CookiebotConfigSerivce } from '@core/services/cookiebot-config.service';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { PaymentModule } from '@payment/payment.module';
import { ProductModule } from '@product/product.module';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from 'app/app-routing.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BaseRoutingModule } from './base-routing.module';
import { AccessibeInitializerComponent } from './components/accessibe-initializer/accessibe-initializer.component';
import { AppcuesInitializerComponent } from './components/appcues-initializer/appcues-initializer.component';
import { AuthInitializerComponent } from './components/auth-initializer/auth-initializer.component';
import { BackForwardCacheInitializerComponent } from './components/back-forward-cache-initializer/back-forward-cache-initializer';
import { CartInitializerComponent } from './components/cart-initializer/cart-initializer.component';
import { ClearConsultantComponent } from './components/clear-consultant/clear-consultant.component';
import { ConsultantCheckerComponent } from './components/consultant-checker/consultant-checker.component';
import { ConsultantInitializerComponent } from './components/consultant-initializer/consultant-initializer.component';
import { ContactUsFormComponent } from './components/contact-us-page/contact-us-form/contact-us-form.component';
import { ContactUsPageComponent } from './components/contact-us-page/contact-us-page.component';
import { ContactUsSuccessComponent } from './components/contact-us-page/contact-us-success/contact-us-success.component';
import { CookiebotInitializerComponent } from './components/cookiebot-initializer/cookiebot-initializer.component';
import { CustomerSpecialsPageComponent } from './components/customer-specials-page/customer-specials-page.component';
import { DatadogInitializerComponent } from './components/datadog-initializer/datadog-initializer.component';
import { FooterLinkInitializerComponent } from './components/footer-link-initializer/footer-link-initializer.component';
import { GtmInitializerComponent } from './components/gtm-initializer/gtm-initializer';
import { FooterBottomSnaComponent } from './components/layout/components/footer-bottom-sna/footer-bottom-sna.component';
import { FooterBottomComponent } from './components/layout/components/footer-bottom/footer-bottom.component';
import { FooterContainerComponent } from './components/layout/components/footer-container/footer-container.component';
import { FooterlinkComponent } from './components/layout/components/footer-links/footer-link/footerlink.component';
import { FooterLinksComponent } from './components/layout/components/footer-links/footer-links.component';
import { FooterMyStoryComponent } from './components/layout/components/footer-my-story/footer-my-story.component';
import { FooterTopComponent } from './components/layout/components/footer-top/footer-top.component';
import { HeaderConsultantComponent } from './components/layout/components/header-consultant/header-consultant.component';
import { HeaderContainerComponent } from './components/layout/components/header-container/header-container.component';
import { HeaderContentComponent } from './components/layout/components/header-content/header-content.component';
import { HeaderNavigationComponent } from './components/layout/components/header-navigation/header-navigation.component';
import { HeaderOffersComponent } from './components/layout/components/header-offers/header-offers.component';
import { HeaderSnaComponent } from './components/layout/components/header-sna/header-sna.component';
import { CartIconComponent } from './components/layout/components/header-top/cart-icon/cart-icon.component';
import { HeaderTopComponent } from './components/layout/components/header-top/header-top.component';
import { LanguageSelectorComponent } from './components/layout/components/header-top/language-selector/language-selector.component';
import { LeavePartyPopupComponent } from './components/layout/components/header-top/party-header/leave-party-popup/leave-party-popup.component';
import { PartyHeaderComponent } from './components/layout/components/header-top/party-header/party-header.component';
import { SearchBoxComponent } from './components/layout/components/search-box/search-box.component';
import { DefaultLayoutComponent } from './components/layout/default-layout/default-layout.component';
import { DeliveryInfoLayoutComponent } from './components/layout/delivery-info-layout/delivery-info-layout.component';
import { StartNowAppLayoutComponent } from './components/layout/start-now-app-layout/start-now-app-layout.component';
import { LogRocketInitializerComponent } from './components/logrocket-initializer/logrocket-initializer.component';
import { MagazineListComponent } from './components/magazine-list/magazine-list.component';
import { NotAllowedToPlaceOrderNotificationInitializerComponent } from './components/not-allowed-to-place-order-notification-initializer/not-allowed-to-place-order-notification-initializer.component';
import { OpenPartyNotificationInitializerComponent } from './components/open-party-notification-initializer/open-party-notification-initializer.component';
import { PartyInitializerComponent } from './components/party-initializer/party-initializer.component';
import { PartyOrderPaymentAddressComponent } from './components/party-order-payment/party-order-payment-address/party-order-payment-address.component';
import { PartyOrderPaymentConfirmationComponent } from './components/party-order-payment/party-order-payment-confirmation/party-order-payment-confirmation.component';
import { PartyOrderPaymentInfoComponent } from './components/party-order-payment/party-order-payment-info/party-order-payment-info.component';
import { PartyOrderPaymentSearchComponent } from './components/party-order-payment/party-order-payment-search/party-order-payment-search.component';
import { PartyOrderPaymentComponent } from './components/party-order-payment/party-order-payment.component';
import { ReplicatedSiteInitializerComponent } from './components/replicated-site-initializer/replicated-site-initializer.component';
import { TitleInitializerComponent } from './components/title-initializer/title-initializer.component';
import { WebsiteModeInitializerComponent } from './components/websitemod-initializer/website-mode-initializer.component';
import { WishlistInitializerComponent } from './components/wishlist-initializer/wishlist-initializer.component';
import { PartyOrderPaymentOrderInformationComponent } from './components/party-order-payment/party-order-payment-order-information/party-order-payment-order-information.component';

@NgModule({
  declarations: [
    MagazineListComponent,
    FooterlinkComponent,
    AuthInitializerComponent,
    CartInitializerComponent,
    CartIconComponent,
    ConsultantInitializerComponent,
    ClearConsultantComponent,
    WishlistInitializerComponent,
    LanguageSelectorComponent,
    ContactUsPageComponent,
    ContactUsFormComponent,
    ContactUsSuccessComponent,
    ConsultantCheckerComponent,
    GtmInitializerComponent,
    OpenPartyNotificationInitializerComponent,
    PartyHeaderComponent,
    LeavePartyPopupComponent,
    PartyInitializerComponent,
    DefaultLayoutComponent,
    StartNowAppLayoutComponent,
    FooterContainerComponent,
    FooterMyStoryComponent,
    FooterTopComponent,
    FooterLinksComponent,
    FooterBottomComponent,
    HeaderContainerComponent,
    HeaderTopComponent,
    HeaderContentComponent,
    HeaderNavigationComponent,
    SearchBoxComponent,
    HeaderOffersComponent,
    HeaderSnaComponent,
    FooterBottomSnaComponent,
    PartyOrderPaymentComponent,
    PartyOrderPaymentSearchComponent,
    PartyOrderPaymentInfoComponent,
    PartyOrderPaymentConfirmationComponent,
    HeaderConsultantComponent,
    LogRocketInitializerComponent,
    AppcuesInitializerComponent,
    ReplicatedSiteInitializerComponent,
    CustomerSpecialsPageComponent,
    TitleInitializerComponent,
    WebsiteModeInitializerComponent,
    CookiebotInitializerComponent,
    DatadogInitializerComponent,
    FooterLinkInitializerComponent,
    AccessibeInitializerComponent,
    BackForwardCacheInitializerComponent,
    NotAllowedToPlaceOrderNotificationInitializerComponent,
    DeliveryInfoLayoutComponent,
    PartyOrderPaymentAddressComponent,
    PartyOrderPaymentOrderInformationComponent,
  ],
  imports: [
    NgxCookiebotModule.forRoot(CookiebotConfigSerivce),
    CommonModule,
    AppRoutingModule,
    SharedModule,
    InfiniteScrollModule,
    BaseRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ConsultantModule,
    PaymentModule,
    ProductModule,
    CartModule,
  ],
  exports: [
    MagazineListComponent,
    AuthInitializerComponent,
    CartInitializerComponent,
    ConsultantInitializerComponent,
    WishlistInitializerComponent,
    ConsultantCheckerComponent,
    GtmInitializerComponent,
    OpenPartyNotificationInitializerComponent,
    PartyInitializerComponent,
    LogRocketInitializerComponent,
    AppcuesInitializerComponent,
    ReplicatedSiteInitializerComponent,
    TitleInitializerComponent,
    WebsiteModeInitializerComponent,
    CookiebotInitializerComponent,
    DatadogInitializerComponent,
    FooterLinkInitializerComponent,
    AccessibeInitializerComponent,
    BackForwardCacheInitializerComponent,
    NotAllowedToPlaceOrderNotificationInitializerComponent,
    DeliveryInfoLayoutComponent,
  ],
})
export class BaseModule {}
