import {
  Directive,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Renderer2,
} from '@angular/core';
import { insertAtIndex, localizeRelativeLinks } from '@shared/utils/localize-relative-links';
import { Locale } from 'moment';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[phInnerHTML]',
})
export class PhInnerHtmlDirective implements OnChanges {
  @Input() phInnerHTML: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Inject(LOCALE_ID) private localeId: Locale,
  ) {}

  ngOnChanges(): void {
    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      this.localizeAbsoluteLinks(localizeRelativeLinks(this.phInnerHTML, this.localeId)),
    );
  }

  private localizeAbsoluteLinks(html: string): string {
    const hostname = window.location.hostname.replace('www.', '');

    const productPattern = `${hostname}/product`;
    const recipePattern = `${hostname}/recipe`;
    const blogPattern = `${hostname}/blog`;

    const localePath = `/${this.localeId}`;

    return html
      .replaceAll(productPattern, insertAtIndex(productPattern, localePath, hostname.length))
      .replaceAll(recipePattern, insertAtIndex(recipePattern, localePath, hostname.length))
      .replaceAll(blogPattern, insertAtIndex(blogPattern, localePath, hostname.length))
      .replaceAll('/products/warranties', '/product-warranties');
  }
}
