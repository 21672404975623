<div *ngIf="reviews?.length" class="ph-keen-slider">
  <div class="keen-slider keen-slider-container" #sliderRef>
    <div class="keen-slider__slide">
      <app-product-review-box
        *ngIf="reviews[indexes[0]]"
        [review]="reviews[indexes[0]]"
        [productDetailsItem]="productDetailsItem"
        class="review-box"
      ></app-product-review-box>
    </div>
    <div class="keen-slider__slide">
      <app-product-review-box
        *ngIf="reviews[indexes[1]]"
        [review]="reviews[indexes[1]]"
        [productDetailsItem]="productDetailsItem"
        class="review-box"
      ></app-product-review-box>
    </div>
    <div class="keen-slider__slide">
      <app-product-review-box
        *ngIf="reviews[indexes[2]]"
        [review]="reviews[indexes[2]]"
        [productDetailsItem]="productDetailsItem"
        class="review-box"
      ></app-product-review-box>
    </div>
    <div class="keen-slider__slide">
      <app-product-review-box
        *ngIf="reviews[indexes[3]]"
        [review]="reviews[indexes[3]]"
        [productDetailsItem]="productDetailsItem"
        class="review-box"
      ></app-product-review-box>
    </div>
    <i
      *ngIf="this.indexes[this.currentSlide] !== 0"
      role="button"
      aria-label="Move image carousel up"
      i18n-aria-label
      (click)="slider.prev()"
      class="arrow-x arrow--left bi bi-chevron-left"
    >
    </i>
    <i
      *ngIf="this.indexes[this.currentSlide] + batchSize < reviews.length"
      role="button"
      aria-label="Move image carousel down"
      i18n-aria-label
      (click)="slider.next()"
      class="arrow-x arrow--right bi bi-chevron-right"
    >
    </i>
  </div>
</div>
