import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalConfig } from '@core/models/modal-config.model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-no-consultant-found-modal',
  templateUrl: './no-consultant-found-modal.component.html',
  styleUrls: ['./no-consultant-found-modal.component.scss'],
})
export class NoConsultantFoundModalComponent implements OnInit, OnDestroy {
  @Input() dismiss: () => void;
  @Input() openModal$: Observable<boolean>;

  modalConfig: ModalConfig;

  private subscriptions: Subscription = new Subscription();

  @ViewChild('modal') private modalComponent: ModalComponent;

  constructor() {}

  ngOnInit(): void {
    this.modalConfig = {
      onDismiss: this.dismiss.bind(this),
      hideCloseButton: true,
      hideDismissButton: true,
      hideHeader: true,
    };
    this.subscriptions.add(
      this.openModal$?.pipe(filter((openModal) => !!openModal)).subscribe(() => {
        this.modalComponent.open();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  closeModal(): void {
    this.modalComponent.dismiss();
  }
}
