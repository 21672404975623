import { Component, OnInit } from '@angular/core';
import { FooterBackgroundShade } from '@core/enums/footer-bg-shade.enum';
import { AppState } from '@core/store';
import { selectPrimaryFooterLinks, selectSecondaryFooterLinks } from '@core/store/footer';
import { FooterLink } from '@core/store/footer/footer-state-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  readonly shade = FooterBackgroundShade;

  primaryLinks$: Observable<FooterLink[]>;
  secondaryLinks$: Observable<FooterLink[]>;
  menuCollapsed: boolean = true;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.loadPrimaryLinks();
    this.loadSecondaryLinks();
  }

  toggleMenu() {
    this.menuCollapsed = !this.menuCollapsed;
  }

  private loadPrimaryLinks() {
    this.primaryLinks$ = this.store$.select(selectPrimaryFooterLinks);
  }

  private loadSecondaryLinks() {
    this.secondaryLinks$ = this.store$.select(selectSecondaryFooterLinks);
  }
}
