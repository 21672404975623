<ng-container>
  <label
    *ngIf="label"
    for="{{ identifier }}"
    [ngClass]="{
      'font-weight-bold': !isValidInput,
      'label-position': isLabelOnInput
    }"
    [ngStyle]="labelStyle"
  >
    {{ label }}
  </label>
  <div class="input-group" [ngClass]="{ 'mt-md-4': isLabelOnInput }">
    <input
      #input
      id="{{ identifier }}"
      [type]="Type"
      name="{{ identifier }}"
      class="form-control"
      [ngClass]="{ 'error-border border-right-0': !isValidInput }"
      [ngStyle]="inputStyle"
      placeholder="{{ placeholder }}"
      (keyup)="onKeyUp($event)"
      (blur)="onBlur($event)"
      [value]="displayValue"
      [readonly]="readonly"
      [disabled]="disabled"
    />
    <div class="input-group-append" *ngIf="!isValidInput">
      <span class="input-group-text bg-transparent error-border border-left-0">
        <i class="bi bi-exclamation-triangle error-color"></i>
      </span>
    </div>
  </div>
  <div *ngIf="!isValidInput && errorMessages" class="validation-error-message">
    <div *ngFor="let item of errorMessages">{{ item }}</div>
  </div>
</ng-container>
