import { Component, OnInit } from '@angular/core';
import { DefaultConsultantImagePlaceholderSrc } from '@core/constants/image-placeholders';
import { AppState } from '@core/store';
import { selectCurrentConsultant } from '@core/store/consultant';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-my-story-banner',
  templateUrl: './my-story-banner.component.html',
  styleUrls: ['./my-story-banner.component.scss'],
})
export class MyStoryBannerComponent implements OnInit {
  imagePlaceholder = DefaultConsultantImagePlaceholderSrc;
  consultant$: Observable<Consultant>;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.consultant$ = this.store$.select(selectCurrentConsultant);
  }
}
