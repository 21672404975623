<div>
  <div class="row mb-3 align-items-center">
    <div class="col-12 col-md-6 mb-2 mb-md-0">
      <div ngbDropdown class="d-flex flex-column d-md-inline-block">
        <div class="text-double-dot" i18n>Filter by</div>
        <button
          class="btn btn-outline-primary text-uppercase"
          id="categorySelector"
          ngbDropdownToggle
        >
          {{ selectedCategoryName }}
        </button>
        <div ngbDropdownMenu aria-labelledby="categorySelector">
          <button
            ngbDropdownItem
            *ngFor="let opt of recipeCategories"
            class="text-uppercase"
            [ngClass]="{ selected: selectedCategoryUrl === opt.urlName }"
            [routerLink]="['/recipes-more/recipes', opt.urlName]"
          >
            {{ opt.title }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 text-right">
      <div ngbDropdown class="d-flex flex-column d-md-inline-block">
        <div class="text-left text-double-dot" i18n>Sort by</div>
        <button
          class="btn btn-outline-primary text-uppercase"
          id="sortOrderSelector"
          ngbDropdownToggle
        >
          {{ selectedSortOption }}
        </button>
        <div ngbDropdownMenu aria-labelledby="sortOrderSelector">
          <button
            ngbDropdownItem
            class="text-uppercase"
            *ngFor="let opt of sortOptions"
            [ngClass]="{ selected: selectedSortOption === opt.name }"
            (click)="sortOrderClickHandler(opt.key)"
          >
            {{ opt.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
