<div class="recipe-image-container">
  <a [routerLink]="detailsUrl" class="text-decoration-none text-black">
    <img
      class="img-fluid"
      [altText]="recipe.name + ' ' + altSuffix"
      phImage
      [images]="recipe.images"
      [thumbnailDesktop]="thumbnail.recipeListDesktop"
      [thumbnailMobile]="thumbnail.recipeListMobile"
    />
  </a>
  <button
    *ngIf="isAuthenticated$ | async"
    class="btn-wishlist"
    [ngClass]="{
      bi: true,
      'bi-heart': !isLiked,
      'bi-heart-fill': isLiked
    }"
    (click)="toggleLike()"
  ></button>
</div>
