import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, Injector, LOCALE_ID, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MobileMedia } from '@core/constants/screen-sizes';
import { CongratulationType } from '@core/enums/congratulation-type.enum';
import { AppState } from '@core/store';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import {
  selectCongratulationStepMode,
  selectStartNowAppData,
  selectSubmittedConsultantInfo,
  selectVoucherBarcodeUrls,
} from '@core/store/start-now-app';
import {
  SubmittedConsultantInfo,
  UserInfo,
} from '@core/store/start-now-app/start-now-app-state-models';
import { fetchVoucherBarCodeUrls } from '@core/store/start-now-app/start-now-app.actions';
import { environment } from '@env';
import { Store, select } from '@ngrx/store';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-congratulation-step',
  templateUrl: './congratulation.component.html',
  styleUrls: ['./congratulation.component.scss'],
})
export class CongratulationComponent extends StartNowStepBaseComponent implements OnInit {
  userInfo$: Observable<UserInfo>;
  applicationRegistrationId$: Observable<number>;
  submittedConsultantInfo$: Observable<SubmittedConsultantInfo>;
  selectedConsultant$: Observable<Consultant>;
  voucherBarcodeUrls$: Observable<string[]>;
  public congratulationType$: Observable<CongratulationType>;
  public readonly CongratulationTypes = CongratulationType;
  public readonly VoucherDeadline: number = 3;

  isMobile$: Observable<boolean>;

  constructor(
    private store$: Store<AppState>,
    private breakpointObserver: BreakpointObserver,
    injector: Injector,
    @Inject(LOCALE_ID) private localeId: string,
  ) {
    super(injector, 'SNA Step - 9 Congratulation');
  }

  ngOnInit(): void {
    this.initObservables();

    this.isMobile$ = this.breakpointObserver.observe(MobileMedia).pipe(select((o) => o.matches));
  }

  get consultantCornerUrl(): string {
    return `${environment.consultantCorner2Uri}/${this.localeId}/`;
  }

  submitStep(): void {
    //there is no next button, so keep empty
  }

  onLoginToConsultantsCornerClick() {
    window.location.href = this.consultantCornerUrl;
  }

  openVoucherUrls(urls: string[]): void {
    urls.forEach((url) => window.open(url));
  }

  protected createFormGroup(): FormGroup {
    throw new Error('Method not implemented.');
  }

  private initObservables(): void {
    this.userInfo$ = this.store$
      .select(selectStartNowAppData)
      .pipe(map((startNowApp) => startNowApp.userInfo));
    this.submittedConsultantInfo$ = this.store$.select(selectSubmittedConsultantInfo);
    this.congratulationType$ = this.store$.select(selectCongratulationStepMode);
    this.selectedConsultant$ = this.store$
      .select(selectStartNowAppData)
      .pipe(select((startNowApp) => startNowApp.selectedConsultant));
    this.applicationRegistrationId$ = this.store$
      .select(selectStartNowAppData)
      .pipe(select((startNowApp) => startNowApp.applicationRegistrationId));
    if (isMexEnv) {
      this.applicationRegistrationId$
        .pipe(
          filter((applicationRegistrationId) => !!applicationRegistrationId),
          take(1),
        )
        .subscribe((applicationRegistrationId) =>
          this.store$.dispatch(fetchVoucherBarCodeUrls({ applicationRegistrationId })),
        );
      this.voucherBarcodeUrls$ = this.store$.select(selectVoucherBarcodeUrls);
    }
  }
}
