<div class="container">
  <div *ngIf="loading$ | async; else content" class="d-flex justify-content-center m-5">
    <app-ph-spinner></app-ph-spinner>
  </div>

  <ng-template #content>
    <!-- Customer offers TOP -->
    <app-conditional-content-by-page-category
      [pageCategory]="PageCategoryTop"
    ></app-conditional-content-by-page-category>

    <div
      *ngIf="
        !(ecommerceCustomerSpecialOffers$ | async).length &&
        !(healthySavingsCustomerSpecialOffers$ | async).length &&
        !(collectAndSaveCustomerSpecialOffers$ | async).length &&
        !(regularCustomerSpecialOffers$ | async).length
      "
    >
      <h3 class="text-center" i18n>No customer special offers found.</h3>
    </div>

    <!-- Customer Offers - customer specials -->
    <app-conditional-content-by-page-category
      [pageCategory]="HealthySavingsPageCategory"
    ></app-conditional-content-by-page-category>

    <!-- Active Non-Qualifying customer specials -->
    <div class="row">
      <div
        class="col-6 col-md-4 pb-2 pb-md-3"
        *ngFor="let product of healthySavingsCustomerSpecialOffers$ | async"
      >
        <app-product-list-item
          [product]="product"
          [onAddToCart]="addToCart"
          [openQuickView]="openQuickView"
        ></app-product-list-item>
      </div>
    </div>

    <!-- Active Regular Customer Specials -->
    <div class="row">
      <div
        class="col-6 col-md-4 pb-2 pb-md-3"
        *ngFor="let product of regularCustomerSpecialOffers$ | async"
      >
        <app-product-list-item
          [product]="product"
          [onAddToCart]="addToCart"
          [openQuickView]="openQuickView"
        ></app-product-list-item>
      </div>
    </div>

    <!-- Customer Offers - Collect and save -->
    <app-conditional-content-by-page-category
      [pageCategory]="CollectAndSavePageCategory"
    ></app-conditional-content-by-page-category>

    <!-- Active Collect and save specials -->
    <div class="row">
      <div
        class="col-6 col-md-4 pb-2 pb-md-3"
        *ngFor="let product of collectAndSaveCustomerSpecialOffers$ | async"
      >
        <app-product-list-item
          [product]="product"
          [onAddToCart]="addToCart"
          [openQuickView]="openQuickView"
        ></app-product-list-item>
      </div>
    </div>

    <!-- Customer Offers - Regular customer specials -->
    <app-conditional-content-by-page-category
      [pageCategory]="RegularCustomerSpecialOffersPageCategory"
    ></app-conditional-content-by-page-category>

    <!-- Active ECommerce special products -->
    <div class="row">
      <div
        class="col-6 col-md-4 pb-2 pb-md-3"
        *ngFor="let product of ecommerceCustomerSpecialOffers$ | async"
      >
        <app-product-list-item
          [product]="product"
          [onAddToCart]="addToCart"
          [openQuickView]="openQuickView"
        ></app-product-list-item>
      </div>
    </div>

    <!-- Customer Offers BOTTOM -->
    <app-conditional-content-by-page-category
      [pageCategory]="PageCategoryBottom"
    ></app-conditional-content-by-page-category>
  </ng-template>
</div>

<app-add-to-cart-confirmation></app-add-to-cart-confirmation>
<app-product-quickview-dialog></app-product-quickview-dialog>
