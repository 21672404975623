import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { AppState } from '@core/store';
import {
  selectConditionalContent,
  selectConditionalContentState,
} from '@core/store/conditional-content';
import { ConditionalContent } from '@core/store/conditional-content/conditional-content-state-models';
import {
  fetchConditionalContentByUrlName,
  resetConditionalContentState,
} from '@core/store/conditional-content/conditional-content.actions';
import { Store } from '@ngrx/store';

import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-conditional-content-page',
  templateUrl: './conditional-content-page.component.html',
})
export class ConditionalContentPageComponent implements OnInit, OnDestroy {
  conditionalContent$: Observable<ConditionalContent[]>;
  private subscriptions = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const viewAs = this.activatedRoute.snapshot.queryParamMap.get(RouterQueryParams.viewAs);

    this.conditionalContent$ = this.store$.select(selectConditionalContent(viewAs));

    this.subscriptions.add(
      this.activatedRoute.paramMap.subscribe((pMap) => {
        const urlName = pMap.get('conditionalContent');
        this.store$.dispatch(resetConditionalContentState());
        this.store$.dispatch(fetchConditionalContentByUrlName({ urlName }));
      }),
    );

    this.subscriptions.add(
      this.store$
        .select(selectConditionalContentState)
        .pipe(
          filter(
            (conditionalContentState) =>
              conditionalContentState.fetched > 0 &&
              conditionalContentState.conditionalContent.length === 0,
          ),
        )
        .subscribe(() => this.router.navigateByUrl('/')),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.store$.dispatch(resetConditionalContentState());
  }
}
