import { HttpErrorResponse } from '@angular/common/http';
import { PhErrorResponse } from '@core/dto/ph-error';

/*
 TODO: ERROR HANDLING: check if there is place where PhErrorResponse is sent as error response and not
 HttpErrorResponse. If PhErrorResponse is not sent should change in store actions for HttpErrorResponse.
 Could be introduced a PhHttpErrorResponse the extends HttpErrorResponse and it's error is PhErrorResponse
*/

export function getPhError(error: any): PhErrorResponse | any {
  if (error instanceof HttpErrorResponse) {
    return error.error as PhErrorResponse;
  }

  if (error.hasOwnProperty('ErrorType') && error.hasOwnProperty('ErrorMessage')) {
    return error as PhErrorResponse;
  }

  return undefined;
}
