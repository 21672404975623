import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductCatalogType } from '@core/constants/product-catalog-type';
import { AppState } from '@core/store';
import { addToCart } from '@core/store/cart/cart.actions';
import {
  selectAllProductsFetched,
  selectProducts,
  selectProductsLoading,
} from '@core/store/product';
import { selectCatalogName } from '@core/store/product/index';
import { ProductItem } from '@core/store/product/product-state-models';
import {
  fetchProductQuickView,
  fetchProducts,
  resetProducts,
} from '@core/store/product/product.actions';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-product-list-page',
  templateUrl: './product-list-page.component.html',
  styleUrls: ['./product-list-page.component.scss'],
})
export class ProductListPageComponent implements OnInit, OnDestroy {
  readonly ProductCatalogType = ProductCatalogType;
  readonly titlePrefix = $localize`Browse products in`;

  products$: Observable<ProductItem[]>;
  loading$: Observable<boolean>;
  catalogName$: Observable<string>;
  fetchNextSubscription: Subscription;
  pageNumber: number = 1;
  pageSize: number = 9;
  scrollDown$ = new Subject<number>();
  catalogUrlName: string;
  catalogType: string;

  constructor(private store$: Store<AppState>, private activatedRoute: ActivatedRoute) {
    this.catalogUrlName = activatedRoute.snapshot.paramMap.get('catalogName');
    this.catalogType = activatedRoute.snapshot.paramMap.get('catalogType');
  }

  ngOnInit(): void {
    this.fetchFirstPageOnInit();

    this.products$ = this.store$.select(selectProducts);
    this.loading$ = this.store$.select(selectProductsLoading);
    this.catalogName$ = this.store$.select(selectCatalogName);
    this.fetchNextPagesOnScrollDown();
  }

  onScrollDown(): void {
    this.scrollDown$.next(this.pageNumber);
  }

  ngOnDestroy(): void {
    this.fetchNextSubscription.unsubscribe();
    this.store$.dispatch(resetProducts());
  }

  openQuickView = (sku: string): void => {
    this.store$.dispatch(fetchProductQuickView({ sku }));
  };

  addToCart = (product: ProductItem): void => {
    this.store$.dispatch(
      addToCart({
        payload: {
          images: product.primaryImages,
          collection: product.collection,
          productName: product.name,
          sku: product.sku,
          discountedPrice: product.discountedPrice,
          price: product.price,
          quantity: 1,
          categoryNames: product.categoryNames,
          type: product.type,
        },
      }),
    );
  };

  private fetchFirstPageOnInit(): void {
    this.store$.dispatch(
      fetchProducts({
        catalogUrlName: this.catalogUrlName,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }),
    );
    this.pageNumber++;
  }

  private fetchNextPagesOnScrollDown(): void {
    this.fetchNextSubscription = this.scrollDown$
      .pipe(
        withLatestFrom(this.loading$),
        filter(([, loading]) => !loading),
        withLatestFrom(this.store$.select(selectAllProductsFetched)),
        filter(([, isAllFetched]) => !isAllFetched),
        tap(([[pageNumber]]) => {
          this.store$.dispatch(
            fetchProducts({
              catalogUrlName: this.catalogUrlName,
              pageNumber: pageNumber,
              pageSize: this.pageSize,
            }),
          );
          this.pageNumber++;
        }),
      )
      .subscribe();
  }
}
