import produce from 'immer';
import { ShareState } from './share-state-models';
import { ShareActionTypes } from './share.actions';

export const initialState: ShareState = {
  loading: false,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case ShareActionTypes.shareInEmail:
      draft.loading = true;
      return;

    case ShareActionTypes.shareInEmailSuccess:
    case ShareActionTypes.shareInEmailFailure:
      draft.loading = false;
      return;

    default:
      return;
  }
}, initialState);
