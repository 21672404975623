import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Locale } from '@core/enums/locale';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AuthService } from '@core/services/auth.service';
import { LoggerService } from '@core/services/logger.service';
import { AppState } from '@core/store';
import { selectIsAuthenticated } from '@core/store/auth';
import { selectCurrentConsultant } from '@core/store/consultant';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNavigationComponent implements OnInit, OnDestroy {
  @Input() menuCollapsed: boolean = true;
  @Output() toggleMenu = new EventEmitter<void>();
  @ViewChild('firstElement') firstElement: ElementRef;

  readonly ec1ReplicatedSiteUrlBase = environment.ec1StartYourBusinessUrl;
  readonly feature = environment.feature;

  isAuthenticated$: Observable<Boolean>;
  isStartYourBusinessRedirectedToEc1: boolean;
  private subscriptions: Subscription = new Subscription();

  constructor(
    @Inject(LOCALE_ID) private localeId: Locale,
    private store$: Store<AppState>,
    private router: Router,
    public appInitService: AppInitService,
    public loggerService: LoggerService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.isAuthenticated$ = this.store$.select(selectIsAuthenticated);
    this.isStartYourBusinessRedirectedToEc1 =
      this.appInitService.Settings.ec.startYourBusinessRedirectToEC1;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.authService.logOut();
    this.router.navigate(['/']);
  }

  onShown() {
    this.firstElement.nativeElement.focus();
  }

  toggle() {
    this.toggleMenu.emit();
    const navigationToggler: HTMLElement = document.querySelector('#navigationToggler');
    navigationToggler.focus();
  }

  onStartYourBusinessClick() {
    this.subscriptions.add(
      this.store$
        .select(selectCurrentConsultant)
        .pipe(take(1))
        .subscribe((consultant) => {
          if (this.isStartYourBusinessRedirectedToEc1) {
            let localeIdUrlParam;
            if (this.localeId === Locale.En || this.localeId === Locale.EnUS) {
              localeIdUrlParam = 0;
            } else if (this.localeId === Locale.EsUS) {
              localeIdUrlParam = 1;
            }

            let ec1ReplicatedSiteUrl;
            if (window.location.href.includes(RouterQueryParams.cid) && consultant) {
              // eslint-disable-next-line max-len
              ec1ReplicatedSiteUrl = `${this.ec1ReplicatedSiteUrlBase}${consultant.vanityName}?lid=${localeIdUrlParam}`;
            } else {
              ec1ReplicatedSiteUrl = `${this.ec1ReplicatedSiteUrlBase}?lid=${localeIdUrlParam}`;
            }

            if (localeIdUrlParam != undefined) {
              window.open(ec1ReplicatedSiteUrl, '_blank');
            } else {
              this.loggerService.error('Error: no locale id provided.');
            }
          } else {
            this.router.navigateByUrl('/start-your-business');
          }
        }),
    );
  }
}
