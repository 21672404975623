import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { LocaleLid } from '@core/enums/locale';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { UrlVariable } from '@core/enums/url-variable.enum';
import { ConditionalContent } from '@core/store/conditional-content/conditional-content-state-models';
import { environment } from '@env';
import { Locale } from 'moment';

@Pipe({
  name: 'fillUrls',
})
export class FillUrlsPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: Locale) {}

  transform(value: ConditionalContent[]): SafeResourceUrl {
    const result = this.fillEc1StartYourBusinessUrl(value);

    return result;
  }

  private fillEc1StartYourBusinessUrl(
    conditionalContents: ConditionalContent[],
  ): ConditionalContent[] {
    const result: ConditionalContent[] = [];
    const cid = new URLSearchParams(window.location.search).get(RouterQueryParams.cid);

    conditionalContents.forEach((element) => {
      let content = element.content.replaceAll(
        UrlVariable.Ec1StartYourBusinessUrl,
        `${environment.ec1StartYourBusinessUrl}${cid ? cid : ''}?lid=${
          LocaleLid[String(this.localeId)]
        }`,
      );

      result.push({ ...element, content });
    });

    return result;
  }
}
