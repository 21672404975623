<ng-container>
  <label
    *ngIf="label"
    for="{{ identifier }}"
    [ngClass]="{
      'font-weight-bold': !isValidInput || !isValidGroup,
      'text-asterix': labelAsterix
    }"
    [ngStyle]="labelStyle"
    >{{ label }}</label
  >
  <div class="input-group">
    <input
      trim
      *ngIf="type !== 'tel'; else telInput"
      #input
      id="{{ identifier }}"
      [type]="type"
      name="{{ identifier }}"
      class="form-control"
      [ngClass]="{
        'error-border border-right-0': !isValidInput || !isValidGroup
      }"
      placeholder="{{ placeholder }}"
      (keyup)="onKeyUp($event)"
      (blur)="onBlur($event)"
      [value]="value"
      [readonly]="readonly"
    />
    <ng-template #telInput>
      <input
        #input
        [mask]="envTel.mask"
        [prefix]="envTel.prefix"
        id="{{ identifier }}"
        [type]="type"
        name="{{ identifier }}"
        class="form-control"
        [ngClass]="{
          'error-border border-right-0': !isValidInput || !isValidGroup
        }"
        placeholder="{{ placeholder }}"
        (keyup)="onKeyUp($event)"
        (blur)="onBlur($event)"
        (mouseup)="onMouseUp($event)"
        (click)="onClick()"
        [value]="value"
        [readonly]="readonly"
      />
    </ng-template>
    <div class="input-group-append" *ngIf="!isValidInput || !isValidGroup">
      <span class="input-group-text bg-transparent error-border border-left-0">
        <i class="bi bi-exclamation-triangle error-color"></i>
      </span>
    </div>
  </div>
  <div *ngIf="!isValidInput && validationErrorMessage" class="validation-error-message">
    <span>{{ validationErrorMessage }}</span>
  </div>
  <div
    *ngIf="!isValidInput && !validationErrorMessage && errorMessages"
    class="validation-error-message"
  >
    <div *ngFor="let item of errorMessages">{{ item }}</div>
  </div>
  <div *ngIf="!isValidGroup" class="validation-error-message">
    <span>{{ invalidGroupErrorMessage }}</span>
  </div>
</ng-container>
