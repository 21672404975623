export enum ConsultantFinancialStateEnum {
  OneYearPrepaid = '1 Year Prepaid',
  NinetyDayPrepaid = '90 Day Prepaid',
  GoodStanding = 'Good Standing',
  NotAllowedToDoBusiness = 'NotAllowedToDoBusiness',
  NotAllowedToPlaceOrders = 'NotAllowedToPlaceOrders',
  PrepaidNPOOnly = 'Prepaid – NPO Only',
  Review = 'Review',
  WriteOff = 'Write Off',
}
