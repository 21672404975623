import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@core/store';
import { selectCart } from '@core/store/cart';
import { fetchCart } from '@core/store/cart/cart.actions';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-cart-initializer',
  template: '',
})
export class CartInitializerComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.subscription = this.store
      .select(selectCart)
      .pipe(
        first(),
        tap((cart) => {
          if (!cart) {
            this.store.dispatch(fetchCart());
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
