<div class="header-top px-3 px-md-5">
  <div class="d-flex justify-content-end">
    <app-language-selector></app-language-selector>
  </div>
</div>
<div class="header-content d-flex align-items-center justify-content-center px-3 px-md-5">
  <div class="logo-container w-100 text-center">
    <img class="icon img-fluid" src="assets/images/header/LOGO.svg" alt="logo" />
  </div>
</div>
<hr class="mt-0" />
