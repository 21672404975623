import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-start-now-app-progress-bar',
  templateUrl: './start-now-app-progress-bar.component.html',
  styleUrls: ['./start-now-app-progress-bar.component.scss'],
})
export class StartNowAppProgressBarComponent implements OnChanges {
  @Input() step: number;

  readonly stepDistance = 12.5;
  dasharray: string = `${Math.PI * 40} ${Math.PI * 40}`;
  dashoffset: string;
  dotPosition: string;
  darkLineWidth: string;
  lightLineWidth: string;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.step) {
      this.dashoffset = this.calcDashoffset();
      this.dotPosition = this.calcDotPosition();
      this.darkLineWidth = this.calcDarkLineWidth();
      this.lightLineWidth = this.calcLightLineWidth();
    }
  }

  calcDashoffset() {
    return (Math.PI * 40 - ((this.step * this.stepDistance) / 100) * (Math.PI * 40)).toString();
  }

  calcDotPosition(): string {
    const pixel = this.step === 8 ? '32px' : '16px';
    return 'calc( ' + this.step * this.stepDistance + '% - ' + pixel + ')';
  }

  calcDarkLineWidth(): string {
    return (this.step * this.stepDistance).toString() + '%';
  }

  calcLightLineWidth(): string {
    return (100 - this.step * this.stepDistance).toString() + '%';
  }
}
