import { Injectable } from '@angular/core';
import { ShareService } from '@core/services/share.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as shareActions from './share.actions';

@Injectable()
export class ShareEffects {
  shareInEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(shareActions.shareInEmail),
      mergeMap(({ payload }) =>
        this.shareService.shareInEmail(payload).pipe(
          map(() => shareActions.shareInEmailSuccess()),
          catchError(() => of(shareActions.shareInEmailFailure())),
        ),
      ),
    ),
  );

  shareInEmailSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(shareActions.shareInEmailSuccess),
        tap(() => {
          this.toastr.success($localize`Email sent!`, $localize`Success`);
        }),
      ),
    { dispatch: false },
  );

  shareInEmailFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(shareActions.shareInEmailFailure),
        tap(() => {
          this.toastr.error($localize`Email sending failed!`, $localize`Error`);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private toastr: ToastrService,
    private shareService: ShareService,
  ) {}
}
