import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DateFormat } from '@core/constants/date-format';
import { AppState } from '@core/store';
import { selectReturnDetails, selectReturnStateLoading } from '@core/store/return';
import { ReturnDetails } from '@core/store/return/return-state-models';
import { fetchReturnDetails } from '@core/store/return/return.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
  ReturnOrderInformationType,
  ReturnOrderLineType,
} from '../new-return/new-return.component';

@Component({
  templateUrl: './return-details-page.component.html',
  styleUrls: ['./return-details-page.component.scss'],
  providers: [DatePipe],
})
export class ReturnDetailsPageComponent implements OnInit {
  returnDetails$: Observable<ReturnDetails>;
  loading$: Observable<boolean>;

  title: string;
  returnDate: string;
  returnInfo: ReturnOrderInformationType;
  productLines: ReturnOrderLineType[] = [];

  constructor(
    private store$: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store$.select(selectReturnStateLoading);
    this.returnDetails$ = this.store$.select(selectReturnDetails);
    this.store$.dispatch(
      fetchReturnDetails({ returnId: this.activatedRoute.snapshot.params['returnId'] }),
    );

    this.returnDetails$
      .pipe(
        filter((returnDetials) => !!returnDetials),
        take(1),
      )
      .subscribe((returnDetails) => {
        this.title = `${$localize`Return ID #`}${returnDetails.returnId}`;
        this.returnDate = `${$localize`Return date:`} ${this.datePipe.transform(
          returnDetails.submittedDate,
          DateFormat.DayMonthNameYear,
        )}`;

        this.productLines = returnDetails.returnProductDetails.map((productLine) => ({
          reason: productLine.reason,
          productName: productLine.productName,
          collectionName: productLine.collectionName,
          sku: productLine.sku,
          price: productLine.price,
          images: productLine.images,
        }));

        this.returnInfo = {
          shippingAddress: returnDetails.shippingAddress,
          billingAddress: returnDetails.shippingAddress,
          party: {
            consultant: `${returnDetails.consultantFirstName} ${returnDetails.consultantLastName}`,
            host: returnDetails.partyHost,
          },
        };
      });
  }
}
