<div class="root container my-4">
  <div class="row">
    <div class="col-12 col-md-6 container">
      <div class="row my-4">
        <div class="col-12">
          <h2 i18n>Forgot Your Password?</h2>
        </div>
      </div>
      <div *ngIf="loading$ | async" class="d-flex justify-content-center">
        <app-ph-spinner></app-ph-spinner>
      </div>
      <ng-container *ngIf="(loading$ | async) === false">
        <ng-container *ngIf="passwordResetSent$ | async; else resetForm">
          <div class="row my-3">
            <div class="col-12" i18n
              >Please <a class="underline" routerLink="/login">sign in</a> with the new password we
              sent to {{ email }}.
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #resetForm>
  <form [formGroup]="form">
    <div class="row my-3">
      <div class="col-12" i18n>
        Enter your email address or mobile number you used to register. We will send you a code to
        reset your password.
      </div>
    </div>
    <div class="row my-3">
      <div class="col-12 my-3">
        <label i18n for="email">Email address</label>
        <input
          id="email"
          class="form-control"
          formControlName="email"
          type="email"
          i18n-placeholder
          placeholder="Your email goes here"
          data-private
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button
          i18n
          class="w-100 btn btn-primary btn-lg btn-uppercase-lg"
          (click)="submit()"
          [disabled]="!form.valid"
        >
          Send password reset
        </button>
      </div>
    </div>
  </form>
</ng-template>
