import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConsultantRoutes } from '@core/constants/consultant-routes';
import { AppState } from '@core/store';
import { selectCurrentConsultant, selectHasCurrentConsultant } from '@core/store/consultant';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderTopComponent {
  readonly feature = environment.feature;
  readonly ConsultantRoutes = ConsultantRoutes;
  currentConsultant$: Observable<Consultant> = this.store$.select(selectCurrentConsultant);
  hasCurrentCurrentConsultant: Observable<Boolean> = this.store$.select(selectHasCurrentConsultant);

  constructor(private store$: Store<AppState>) {}

  skipTo(element: string) {
    const htmlElement: HTMLElement = document.querySelector(element);

    htmlElement.scrollIntoView();
    htmlElement.setAttribute('tabindex', '-1');
    htmlElement.focus();
  }
}
