import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartModule } from '@cart/cart.module';
import { ProductDetailsMediaComponent } from '@shared/components/media-gallery/media-gallery.component';
import { SharedModule } from '@shared/shared.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProductCatalogItemComponent } from './components/product-catalog-page/product-catalog-item/product-catalog-item.component';
import { ProductCatalogPageComponent } from './components/product-catalog-page/product-catalog-page.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { ProductListImageComponent } from './components/product-list-page/product-list-item/product-list-image/product-list-image.component';
import { ProductListItemComponent } from './components/product-list-page/product-list-item/product-list-item.component';
import { ProductListPageComponent } from './components/product-list-page/product-list-page.component';
import { ProductQuickviewDialogComponent } from './components/product-quickview-dialog/product-quickview-dialog.component';
import { ProductReviewModalComponent } from './components/product-review-modal/product-review-modal.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { ProductRoutingModule } from './product-routing.module';
import { ProductReviewAndRatingComponent } from './components/product-details/product-review-and-rating/product-review-and-rating.component';
import { ProductReviewBoxComponent } from './components/product-details/product-review-and-rating/product-review-box/product-review-box.component';
import { ProductReviewsCarouselComponent } from './components/product-details/product-review-and-rating/product-reviews-carousel/product-reviews-carousel.component';

@NgModule({
  declarations: [
    ProductListPageComponent,
    ProductListItemComponent,
    ProductListImageComponent,
    ProductCatalogPageComponent,
    ProductCatalogItemComponent,
    ProductDetailsComponent,
    ProductDetailsMediaComponent,
    ProductQuickviewDialogComponent,
    StarRatingComponent,
    ProductReviewModalComponent,
    ProductReviewAndRatingComponent,
    ProductReviewBoxComponent,
    ProductReviewsCarouselComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ProductRoutingModule,
    InfiniteScrollModule,
    SharedModule,
    CartModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    ProductListPageComponent,
    ProductListItemComponent,
    ProductDetailsComponent,
    ProductQuickviewDialogComponent,
  ],
})
export class ProductModule {}
