/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { GtmEvent } from '@core/enums/gtm-event.enum';
import { GtmEcommerce } from '@core/models/gtm.model';
import { AddToCartConfirmModelData, Cart, OrderLine } from '@core/store/cart/cart-state-models';
import { ProductDetailsItem } from '@core/store/product/product-state-models';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
/* Google Tag Manager service */
export class GtmService {
  private readonly GtmEventCategoryCount = 5;

  constructor() {}

  productDetailsView(product: ProductDetailsItem) {
    this.clearEcommerce();
    (<any>window).dataLayer.push({
      event: GtmEvent.viewItem,
      ecommerce: this.mapProductDetailToGtmEcommerce(product),
    });
  }

  addToCart(product: AddToCartConfirmModelData) {
    this.clearEcommerce();
    (<any>window).dataLayer.push({
      event: GtmEvent.addToCart,
      ecommerce: this.mapAddToCartProductToGtmEcommerce(product),
    });
  }

  removeFromCart(product: OrderLine) {
    this.clearEcommerce();
    (<any>window).dataLayer.push({
      event: GtmEvent.removeFromCart,
      ecommerce: this.mapOrderLineToGtmEcommerce(product),
    });
  }

  beginCheckout(cart: Cart) {
    this.clearEcommerce();
    (<any>window).dataLayer.push({
      event: GtmEvent.beginCheckout,
      ecommerce: this.mapCartToGtmEcommerce(cart),
    });
  }

  purchase(submittedOrderId: string, cart: Cart, customer: string) {
    this.clearEcommerce();
    (<any>window).dataLayer.push({
      event: GtmEvent.purchase,
      ecommerce: this.mapSubmittedOrderToGtmEcommerce(submittedOrderId, cart, customer),
    });
  }

  addPaymentInfo(cart: Cart) {
    this.clearEcommerce();
    (<any>window).dataLayer.push({
      event: GtmEvent.addPaymentInfo,
      ecommerce: this.mapCartToGtmEcommerce(cart),
    });
  }

  addShippingInfo(cart: Cart) {
    this.clearEcommerce();
    (<any>window).dataLayer.push({
      event: GtmEvent.addShippingInfo,
      ecommerce: this.mapCartToGtmEcommerce(cart),
    });
  }

  private clearEcommerce() {
    (<any>window).dataLayer.push({ ecommerce: null });
  }

  private mapCategoryNames(categoryNames: string[]) {
    const categoryObject = {};
    categoryNames?.forEach((c, index) => {
      if (index < this.GtmEventCategoryCount) {
        if (index === 0) {
          categoryObject['item_category'] = c;
        } else {
          categoryObject[`item_category${index + 1}`] = c;
        }
      }
    });
    return categoryObject;
  }

  private mapProductDetailToGtmEcommerce(product: ProductDetailsItem): GtmEcommerce {
    return {
      currency: environment.currency,
      value: product.discountedPrice || product.price,
      items: [
        {
          item_id: product.sku,
          item_name: product.name,
          price: product.discountedPrice || product.price,
          item_brand: product.collection,
          ...this.mapCategoryNames(product.categoryNames),
        },
      ],
    };
  }

  private mapAddToCartProductToGtmEcommerce(product: AddToCartConfirmModelData): GtmEcommerce {
    return {
      currency: environment.currency,
      value: product.discountedPrice || product.price,
      items: [
        {
          item_id: product.sku,
          item_name: product.productName,
          price: product.discountedPrice || product.price,
          item_brand: product.collection,
          ...this.mapCategoryNames(product.categoryNames),
        },
      ],
    };
  }

  private mapOrderLineToGtmEcommerce(product: OrderLine): GtmEcommerce {
    return {
      currency: environment.currency,
      value: product.discountedPrice || product.originalPrice,
      items: [
        {
          item_id: product.sku,
          item_name: product.productName,
          price: product.discountedPrice || product.originalPrice,
          item_brand: product.collectionName,
          ...this.mapCategoryNames(product.categoryNames),
        },
      ],
    };
  }

  private mapCartToGtmEcommerce(cart: Cart): GtmEcommerce {
    return {
      currency: environment.currency,
      value: cart.merchandiseTotal,
      items: cart.orderLines.map((orderLine) => ({
        item_id: orderLine.sku,
        item_name: orderLine.productName,
        price: orderLine.discountedPrice || orderLine.originalPrice,
        item_brand: orderLine.collectionName,
        quantity: orderLine.quantity,
        ...this.mapCategoryNames(orderLine.categoryNames),
      })),
    };
  }

  private mapSubmittedOrderToGtmEcommerce(
    submittedOrderId,
    cart: Cart,
    customer?: string,
  ): GtmEcommerce {
    return {
      currency: environment.currency,
      value: cart.merchandiseTotal,
      transaction_id: submittedOrderId,
      affiliation: 'PH-ecommerce',
      tax: cart.salesTaxTotal,
      shipping: cart.deliveryFeeTotal,
      customer,
      items: cart.orderLines.map((orderLine) => ({
        item_id: orderLine.sku,
        item_name: orderLine.productName,
        price: orderLine.discountedPrice || orderLine.originalPrice,
        item_brand: orderLine.collectionName,
        quantity: orderLine.quantity,
        ...this.mapCategoryNames(orderLine.categoryNames),
      })),
    };
  }
}
