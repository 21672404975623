<app-page-title
  *ngIf="productDetailsItem$ | async as product"
  title="Product details for {{ product.collection }} {{ product.name }}"
  [description]="product.description"
  i18n-title
></app-page-title>
<div class="root d-flex justify-content-center">
  <div
    [ngClass]="{
      container: (isMobile$ | async) === false
    }"
    *ngIf="productDetails$ | async as productDetails"
  >
    <div
      *ngIf="productDetails.isLoading; else productDetailsSection"
      class="d-flex justify-content-center"
    >
      <app-ph-spinner width="100px"></app-ph-spinner>
    </div>
    <ng-template #productDetailsSection>
      <div class="row max-width w-m-100vw">
        <app-breadcrumb-wrapper class="w-100 my-4 px-4">
          <li class="breadcrumb-item"><a i18n routerLink="/products">Products</a></li>
          <li class="breadcrumb-item"><a i18n routerLink="/products">Collection</a></li>
          <li class="breadcrumb-item">
            <a routerLink="/products/collection/{{ productDetails.item.collectionUrlName }}">
              {{ productDetails.item.collection }}
            </a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ productDetails.item.name }}
          </li>
        </app-breadcrumb-wrapper>
      </div>
      <div
        *ngIf="productDetailsItem$ | async as productDetailsItem; else productNotFound"
        class="max-width d-flex flex-column align-items-center my-4 px-4 w-m-100vw"
      >
        <div class="row">
          <div class="w-m-100vw col-xs-12 col-md-auto">
            <app-media-gallery
              [images$]="productImageSlides$"
              [videos$]="productVideoSlides$"
            ></app-media-gallery>
            <div
              class="d-flex justify-content-between align-self-center mt-2 rating-container"
              *ngIf="feature.productReview"
            >
              <div class="d-flex align-self-center col px-md-0 ml-md-3">
                <div>
                  <app-star-rating [rate]="productDetailsItem.rating"></app-star-rating>
                  {{ productDetailsItem.numberOfRatings }} <span i18n>ratings | </span>
                  <button
                    class="btn btn-primary text-uppercase py-0"
                    (click)="scrollToReviews(productReviews)"
                    i18n
                  >
                    Read reviews
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="details col">
            <button
              *ngIf="feature.user"
              class="float-right pointer btn-wishlist"
              [ngClass]="{
                bi: true,
                'bi-heart': !isWishlisted,
                'bi-heart-fill': isWishlisted
              }"
              (click)="toggleWish(productDetailsItem.sku)"
              aria-label="Add to wishlist"
              i18n-aria-label
            ></button>
            <div>
              <span class="text-collection d-block text-uppercase mb-2">
                {{ productDetailsItem.collection }}
              </span>
              <span class="text-product d-block font-weight-bold">
                {{ productDetailsItem.name }}
              </span>
              <span class="text-sku d-block mb-2">{{ productDetailsItem.sku }}</span>
              <app-product-price-message [product]="productDetailsItem"></app-product-price-message>
              <app-product-price [product]="productDetailsItem"></app-product-price>
            </div>
            <div class="row mx-0 my-3 d-flex align-items-end">
              <ng-container
                *ngIf="productUtil.getAddToCartButtonVisibility$(productDetailsItem) | async"
              >
                <div class="col-12 px-0 mb-3">
                  <label class="text-double-dot" for="quantitySelector" i18n>Qty</label>
                  <select id="quantitySelector" class="form-control w-auto" [(ngModel)]="quantity">
                    <option *ngFor="let i of quantities" [ngValue]="i">
                      {{ i }}
                    </option>
                  </select>
                </div>
                <button
                  [disabled]="addToCartLoading$ | async"
                  class="btn btn-primary col-12"
                  (click)="addToCart(productDetailsItem)"
                  i18n
                >
                  Add to cart<i class="bi bi-plus-lg ml-2"></i>
                </button>
              </ng-container>
            </div>
            <div class="row d-none d-md-block">
              <app-share-buttons></app-share-buttons>
            </div>
          </div>
        </div>
        <div class="row d-md-none">
          <app-share-buttons></app-share-buttons>
        </div>
        <div *ngIf="productDetailsItem.description" class="row w-100 justify-content-center mt-5">
          <h4 i18n class="mb-2">Product description</h4>
          <div class="col-12 text-description d-block">
            <app-romance-content-wrapper
              [html]="productDetailsItem.description"
            ></app-romance-content-wrapper>
          </div>
        </div>
        <div *ngIf="productDetailsItem.webContent" class="row w-100 justify-content-center mt-5">
          <hr class="w-100 my-5" />
          <div class="col-12">
            <app-romance-content-wrapper
              [html]="productDetailsItem.webContent"
            ></app-romance-content-wrapper>
          </div>
        </div>
        <div
          *ngIf="productDetailsItem.careInstructions"
          class="row justify-content-center w-100 care-instructions"
        >
          <hr class="w-100 my-5" />
          <h4 i18n class="mb-2">Care & Use</h4>
          <div class="col-12">
            <app-romance-content-wrapper
              [html]="productDetailsItem.careInstructions"
            ></app-romance-content-wrapper>
          </div>
        </div>
        <div
          *ngIf="productDetailsItem.warranty"
          class="row d-flex justify-content-center w-100 text-center"
        >
          <hr class="w-100 my-5" />
          <h4 i18n class="mb-2"> Warranty </h4>
          <div class="col-12 warranty-content">
            <app-romance-content-wrapper
              [html]="productDetailsItem.warranty"
            ></app-romance-content-wrapper>
          </div>
        </div>
        <div *ngIf="(moreFromThisCollectionSlides$ | async).length > 0" class="row w-100">
          <hr class="w-100 my-5" />
          <div class="col-12">
            <app-related-carousel
              i18n-title
              title="More from this collection"
              [slides$]="moreFromThisCollectionSlides$"
            ></app-related-carousel>
          </div>
        </div>
        <div *ngIf="(youMightAlsoLikeSlides$ | async).length > 0" class="row w-100">
          <hr class="w-100 my-5" />
          <div class="col-12">
            <app-related-carousel
              i18n-title
              title="You Might also Like"
              [slides$]="youMightAlsoLikeSlides$"
            ></app-related-carousel>
          </div>
        </div>
        <div
          *ngIf="(tryTheseRecipesSlides$ | async).length > 0 && feature.recipe"
          class="row w-100"
        >
          <hr class="w-100 my-5" />
          <div class="col-12">
            <app-related-carousel
              i18n-title
              title="Try these recipes"
              [slides$]="tryTheseRecipesSlides$"
            ></app-related-carousel>
          </div>
        </div>
        <div class="row w-100" #productReviews [hidden]="!feature.productReview">
          <hr class="w-100 my-5" />
          <div class="col-12">
            <app-product-review-and-rating
              [productDetailsItem]="productDetailsItem$ | async"
              (openReviewModal)="openReviewModal()"
            ></app-product-review-and-rating>
          </div>
        </div>
      </div>
      <ng-template #productNotFound>
        <div class="my-4">
          <span class="text-not-found font-weight-bold" i18n>Product not found</span>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
<app-add-to-cart-confirmation></app-add-to-cart-confirmation>
<app-product-review-modal
  #productReviewModal
  [productDetailsItem]="productDetailsItem$ | async"
  [readOnly]="false"
></app-product-review-modal>
