import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageCategory } from '@core/enums/page-category.enum';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './category-conditional-content-page.component.html',
  styleUrls: ['./category-conditional-content-page.component.scss'],
})
export class CategoryConditionalContentPageComponent implements OnInit {
  pageCategory$: Observable<PageCategory>;

  constructor(private activatedroute: ActivatedRoute) {}

  ngOnInit(): void {
    this.pageCategory$ = this.activatedroute.data.pipe(select((d) => d?.pageCategory));
  }
}
