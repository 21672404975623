import { PaymentState } from '@core/store/payment/payment-state-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const paymentStateFeatureKey = 'paymentState';

export const selectPaymentFeature = createFeatureSelector<PaymentState>(paymentStateFeatureKey);

export const selectClientToken = createSelector(selectPaymentFeature, (state: PaymentState) =>
  state ? state.cardInfo?.clientToken : '',
);

export const selectCustomerId = createSelector(selectPaymentFeature, (state: PaymentState) =>
  state ? state.customerId : '',
);

export const selectPartyOrderCustomerId = createSelector(
  selectPaymentFeature,
  (state: PaymentState) => (state ? state.partyOrderCustomerId : ''),
);

export const selectPaymentMethodsState = createSelector(
  selectPaymentFeature,
  (state: PaymentState) => (state ? state.paymentMethods : null),
);

export const selectPaymentInfo = createSelector(selectPaymentFeature, (state: PaymentState) =>
  state ? state.paymentInfo : null,
);

export const selectCreatePaymentMethodResponse = createSelector(
  selectPaymentFeature,
  (state: PaymentState) => (state ? state.createdCardPaymentMethod : null),
);

export const selectCreatePaymentMethodFailed = createSelector(
  selectPaymentFeature,
  (state: PaymentState) => (state ? state.paymentMethods.createFailed : false),
);

export const selectPaymentMethodLoading = createSelector(
  selectPaymentFeature,
  (state: PaymentState) => (state ? state.paymentMethods.loading : false),
);

export const selectPayPalOrder = createSelector(selectPaymentFeature, (state: PaymentState) =>
  state ? state.paymentInfo?.order?.orderId : null,
);

export const selectPaymentLoading = createSelector(selectPaymentFeature, (state: PaymentState) =>
  state ? state.loading || state.paymentMethods?.loading : false,
);
