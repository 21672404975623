/* eslint-disable max-len */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ContactUsFormData,
  ContactUsReasonKey,
} from '@base/components/contact-us-page/contact-us-page.component';
import { ValidEmailRegExp } from '@core/constants/patterns';
import { ContactUsRequest } from '@core/dto/contact-us.dto';
import { FormErrorTypes } from '@core/enums/form-error-type.enum';
import { AppState } from '@core/store';
import { selectCountryStates } from '@core/store/address';
import { fetchCountryStates } from '@core/store/address/address.actions';
import { sendEmail } from '@core/store/contact-us/contact-us.actions';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { SelectOption } from '@shared/components/select/select.component';
import { countryStatesToSelectOptions } from '@shared/utils/address-utils';
import { emailValidator } from '@shared/utils/email-validator-utils';
import { isMexEnv } from '@shared/utils/environment-utils';
import { matchValidator, validInput } from '@shared/utils/validation.utils';
import { combineLatest, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.scss'],
})
export class ContactUsFormComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly ContactUsReasonKey = ContactUsReasonKey;
  readonly feature = environment.feature;
  readonly tel = environment.tel;
  readonly isMexEnv = isMexEnv;
  readonly InfoFormGroup = 'infoFormGroup';
  readonly FirstName = 'firstName';
  readonly LastName = 'lastName';
  readonly MobilePhone = 'mobilePhone';
  readonly HomePhone = 'homePhone';
  readonly Email = 'email';
  readonly EmailConfirm = 'emailConfirm';
  readonly AddressLine1 = 'addressLine1';
  readonly AddressLine2 = 'addressLine2';
  readonly AddressLine3 = 'addressLine3';
  readonly City = 'city';
  readonly State = 'state';
  readonly ZipCode = 'zipCode';
  readonly Comment = 'comment';
  readonly Reasons = 'reasons';
  readonly FormErrorTypes = FormErrorTypes;

  subjects: string[] = [
    $localize`I have a general inquiry or question`,
    $localize`Personal Data Usage inquiry`,
  ];
  reasonTexts: Object = {
    [ContactUsReasonKey.copy]: $localize`I want a copy of the personal data Princess House has collected about me.`,
    [ContactUsReasonKey.understand]: $localize`I want to understand the categories of data for which Princess House has collected my personal information.`,
    [ContactUsReasonKey.deleteData]: $localize`I want you to erase all of my data.`,
    [ContactUsReasonKey.unsubscribe]: $localize`I want to unsubscribe from your marketing and sales efforts.`,
  };
  contactUsFormGroup: FormGroup;
  countryStates$: Observable<SelectOption[]>;
  subjectId: number;

  @Output()
  contactUsFormDataChange: EventEmitter<ContactUsFormData> = new EventEmitter<ContactUsFormData>();
  @Input()
  contactUsFormData: ContactUsFormData;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.store$.dispatch(fetchCountryStates());
    this.countryStates$ = countryStatesToSelectOptions(this.store$.select(selectCountryStates));
    this.subjectId = this.contactUsFormData?.subjectId;

    this.createForm();
  }

  ngAfterViewInit(): void {
    this.listenPhoneValueChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submit(): void {
    const reasons: string[] = this.checkOutReasons();

    this.contactUsFormGroup.markAllAsTouched();
    this.updateForm();

    if (this.contactUsFormGroup.valid) {
      const contactUsRequest: ContactUsRequest = {
        ...this.contactUsFormGroup.get(this.InfoFormGroup).value,
        phone:
          this.contactUsFormGroup.get(`${this.InfoFormGroup}.${this.HomePhone}`).value !== ''
            ? this.contactUsFormGroup.get(`${this.InfoFormGroup}.${this.HomePhone}`).value
            : this.contactUsFormGroup.get(`${this.InfoFormGroup}.${this.MobilePhone}`).value,
        reasons: reasons,
        subject: this.subjects[this.subjectId],
        isPersonalData: !!this.subjectId,
      };

      this.store$.dispatch(sendEmail({ contactUsRequest }));

      this.emitContactUsFormDataChange();
    }
  }

  validReasonsGroup(groupName: string): boolean {
    return this.contactUsFormGroup.get(groupName).valid;
  }

  validPhoneInput(): boolean {
    return (
      validInput(this.contactUsFormGroup?.get(`${this.InfoFormGroup}.${this.MobilePhone}`)) ||
      validInput(this.contactUsFormGroup?.get(`${this.InfoFormGroup}.${this.HomePhone}`))
    );
  }

  private createForm(): void {
    this.contactUsFormGroup = this.fb.group({
      [this.InfoFormGroup]: this.fb.group(
        {
          [this.FirstName]: [
            this.contactUsFormData?.infoFormData.firstName || '',
            Validators.required,
          ],
          [this.LastName]: [
            this.contactUsFormData?.infoFormData.lastName || '',
            Validators.required,
          ],
          [this.HomePhone]: [
            this.contactUsFormData?.infoFormData.homePhone || '',
            this.phoneValidator(),
          ],
          [this.MobilePhone]: [
            this.contactUsFormData?.infoFormData.mobilePhone || '',
            this.phoneValidator(),
          ],
          [this.Email]: [
            this.contactUsFormData?.infoFormData.email || '',
            [Validators.required, emailValidator(ValidEmailRegExp)],
          ],
          [this.EmailConfirm]: [
            this.contactUsFormData?.infoFormData.email || '',
            [Validators.required, emailValidator(ValidEmailRegExp)],
          ],
          [this.AddressLine1]: [
            this.contactUsFormData?.infoFormData.addressLine1 || '',
            [Validators.required],
          ],
          [this.AddressLine2]: [this.contactUsFormData?.infoFormData.addressLine2 || ''],
          [this.City]: [this.contactUsFormData?.infoFormData.city || '', Validators.required],
          [this.State]: [this.contactUsFormData?.infoFormData.state || ''],
          [this.ZipCode]: [this.contactUsFormData?.infoFormData.zipCode || '', Validators.required],
          [this.Comment]: [this.contactUsFormData?.infoFormData.comment || '', Validators.required],
        },
        {
          validators: matchValidator(
            this.Email,
            this.EmailConfirm,
            this.FormErrorTypes.emailConfirm,
          ),
        },
      ),
      [this.Reasons]: this.fb.group(
        {
          [ContactUsReasonKey.copy]: [this.contactUsFormData?.reasons.copy || false],
          [ContactUsReasonKey.understand]: [this.contactUsFormData?.reasons.understand || false],
          [ContactUsReasonKey.deleteData]: [this.contactUsFormData?.reasons.deleteData || false],
          [ContactUsReasonKey.unsubscribe]: [this.contactUsFormData?.reasons.unsubscribe || false],
        },
        { validators: this.reasonsValidator() },
      ),
    });

    if (this.isMexEnv) {
      const infoFormGroup = this.contactUsFormGroup.get(this.InfoFormGroup) as FormGroup;
      infoFormGroup.addControl(
        this.AddressLine3,
        new FormControl(this.contactUsFormData?.infoFormData.addressLine3),
      );
    }
  }

  private phoneValidator(): ValidatorFn {
    return (): ValidationErrors =>
      (this.contactUsFormGroup?.get(`${this.InfoFormGroup}.${this.HomePhone}`).touched ||
        this.contactUsFormGroup?.get(`${this.InfoFormGroup}.${this.MobilePhone}`).touched) &&
      this.contactUsFormGroup?.get(`${this.InfoFormGroup}.${this.HomePhone}`).value === '' &&
      this.contactUsFormGroup?.get(`${this.InfoFormGroup}.${this.MobilePhone}`).value === ''
        ? { phone: true }
        : null;
  }

  private emitContactUsFormDataChange(): void {
    const updateContactUsFormData: ContactUsFormData = {
      infoFormData: this.contactUsFormGroup.get(this.InfoFormGroup).value,
      reasons: this.contactUsFormGroup.get(this.Reasons).value,
      subjectId: this.subjectId,
    };
    this.contactUsFormDataChange.emit(updateContactUsFormData);
  }

  private reasonsValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      let isReasonsValid: boolean = false;
      if (!this.subjectId) {
        return null;
      }
      Object.keys(group.controls).forEach((key) => {
        if (group.controls[key].value) {
          isReasonsValid = true;
        }
      });
      if (isReasonsValid) {
        return null;
      }
      return { reasons: { reasons: true } };
    };
  }

  private updateForm(): void {
    this.contactUsFormGroup.get(this.Reasons).updateValueAndValidity();
    this.contactUsFormGroup.get(`${this.InfoFormGroup}.${this.HomePhone}`).updateValueAndValidity();
    this.contactUsFormGroup
      .get(`${this.InfoFormGroup}.${this.MobilePhone}`)
      .updateValueAndValidity();
  }

  private checkOutReasons(): string[] {
    const reasons: string[] = [];
    if (this.contactUsFormGroup.get(`${this.Reasons}.${ContactUsReasonKey.copy}`).value) {
      reasons.push(ContactUsReasonKey.copy);
    }
    if (this.contactUsFormGroup.get(`${this.Reasons}.${ContactUsReasonKey.understand}`).value) {
      reasons.push(ContactUsReasonKey.understand);
    }
    if (this.contactUsFormGroup.get(`${this.Reasons}.${ContactUsReasonKey.deleteData}`).value) {
      reasons.push(ContactUsReasonKey.deleteData);
    }
    if (this.contactUsFormGroup.get(`${this.Reasons}.${ContactUsReasonKey.unsubscribe}`).value) {
      reasons.push(ContactUsReasonKey.unsubscribe);
    }
    return reasons;
  }

  private listenPhoneValueChanges(): void {
    let homePhone = this.contactUsFormGroup.get(`${this.InfoFormGroup}.${this.HomePhone}`);
    let mobilePhone = this.contactUsFormGroup.get(`${this.InfoFormGroup}.${this.MobilePhone}`);
    this.subscriptions.add(
      combineLatest([homePhone.valueChanges, mobilePhone.valueChanges]).subscribe(() => {
        homePhone.updateValueAndValidity({ emitEvent: false });
        mobilePhone.updateValueAndValidity({ emitEvent: false });
      }),
    );
  }
}
