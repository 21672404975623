import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ShareInEmailRequest, ShareInEmailResponse } from '@core/dto/share-in-email.dto';
import { Locale } from '@core/enums/locale';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  baseUrl: string = environment.ecommerceBackendUri;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {}

  shareInEmail(req: ShareInEmailRequest): Observable<ShareInEmailResponse> {
    return this.http.post<ShareInEmailResponse>(`${this.baseUrl}api/share/email`, req, {
      params: new HttpParams({
        fromObject: { culture: getCulture(this.localeId) },
      }),
    });
  }
}
