<div class="root container-fluid text-uppercase">
  <div class="row align-items-center justify-content-center">
    <div *ngIf="party$ | async as party" class="p-1">
      <a (click)="openLeavePopup()">{{ party.partyName }}</a></div
    >
    <div *ngIf="(party$ | async) === null && (openPartyExists$ | async)" class="p-1">
      <a [routerLink]="'/' + myOpenPartiesRoute" i18n>Visit My Open Parties!</a>
    </div>
  </div>
</div>

<app-leave-party-popup #leavePopup></app-leave-party-popup>
