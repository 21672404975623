import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@core/store';
import { selectPartyOrderStatus } from '@core/store/order';
import { SubmittedOrderStatus } from '@core/store/order/order-state-models';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { filter } from 'rxjs/operators';

// TODO: EC-2420 finalize payment accepted page
@Component({
  selector: 'app-party-order-payment-confirmation',
  templateUrl: './party-order-payment-confirmation.component.html',
  styleUrls: ['./party-order-payment-confirmation.component.scss'],
})
export class PartyOrderPaymentConfirmationComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Output()
  backToSearch: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  reset: EventEmitter<void> = new EventEmitter<void>();

  readonly SubmittedOrderStatus = SubmittedOrderStatus;

  paymentResult: SubmittedOrderStatus;

  constructor(
    private store$: Store<AppState>,
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.store$
        .select(selectPartyOrderStatus)
        .pipe(
          filter(
            (status) =>
              status == SubmittedOrderStatus.success || status == SubmittedOrderStatus.failure,
          ),
        )
        .subscribe((status: SubmittedOrderStatus) => {
          this.paymentResult = status;
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onBackToSearch(): void {
    this.backToSearch.emit();
  }

  onGoToHome(): void {
    this.reset.emit();
    this.router.navigate(['/']);
  }
}
