<div class="container">
  <div class="row my-3 mt-md-5 mb-md-3 justify-content-center">
    <div class="col-auto">
      <h1 class="title text-center mb-3 text-uppercase">{{ Title }}</h1>
    </div>
  </div>
  <div class="row main-text justify-content-center mb-3">
    <div class="col-md-6">
      <div class="text-center">
        <span
          *ngIf="
            selectedCountry !== EnvironmentCountry ||
              activeNavId === consultantFinderType.InvitationCode;
            else byArea
          "
        >
          {{ SubTitles[InvitationCode] }}
        </span>
        <ng-template #byArea>
          <span>
            {{ SubTitles[ByArea] }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
  <div
    class="row mb-3 justify-content-center"
    ngbRadioGroup
    *ngIf="isMexEnv"
    [ngModel]="selectedCountry"
    (ngModelChange)="onCountryChange($event)"
  >
    <div class="col-6 col-md-3 d-flex justify-content-start">
      <label ngbButtonLabel for="americanFlag" class="px-0 shadow-none">
        <img
          class="flag"
          [src]="FlagImageSrc.americanFlag"
          [ngClass]="{
            country: selectedCountry !== Country.Usa,
            'selected-country': selectedCountry === Country.Usa
          }"
        />
        <input ngbButton id="americanFlag" type="radio" [value]="Country.Usa" />
      </label>
    </div>
    <div class="col-6 col-md-3 d-flex justify-content-end">
      <label ngbButtonLabel for="mexicanFlag" class="px-0 shadow-none">
        <img
          class="flag"
          [src]="FlagImageSrc.mexicanFlag"
          [ngClass]="{
            country: selectedCountry !== Country.Mexico,
            'selected-country': selectedCountry === Country.Mexico
          }"
        />
        <input ngbButton id="mexicanFlag" type="radio" [value]="Country.Mexico" />
      </label>
    </div>
  </div>
  <div
    class="row mb-3 mb-md-5 justify-content-center"
    *ngIf="selectedCountry === EnvironmentCountry; else otherCountry"
  >
    <div class="col-12 col-md-10">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeNavId" class="nav-tabs border-primary">
        <li [ngbNavItem]="1">
          <a i18n ngbNavLink>By Area</a>
          <ng-template ngbNavContent>
            <form [formGroup]="findConsultantByLocationForm">
              <div class="row tabbed-form mx-0 py-3">
                <div class="col-12 col-md-3">
                  <app-text-field-input
                    [identifier]="this.FirstName"
                    label="First Name"
                    i18n-label
                    placeholder="First name"
                    i18n-placeholder
                    [formControlName]="this.FirstName"
                    [labelStyle]="FormLabelStyle"
                  ></app-text-field-input>
                </div>
                <div class="col-12 col-md-3">
                  <app-text-field-input
                    [identifier]="this.LastName"
                    label="Last Name"
                    i18n-label
                    placeholder="Last name"
                    i18n-placeholder
                    [formControlName]="this.LastName"
                    [labelStyle]="FormLabelStyle"
                  ></app-text-field-input>
                </div>
                <div class="col-12 col-md-3">
                  <app-text-field-input
                    [identifier]="this.PhoneNumber"
                    label="Mobile Number"
                    i18n-label
                    placeholder="(012) 456-7890"
                    [formControlName]="this.PhoneNumber"
                    [labelStyle]="FormLabelStyle"
                    type="tel"
                  ></app-text-field-input>
                </div>
                <div class="col-12 col-md-3">
                  <app-ph-select
                    [identifier]="this.State"
                    label="State"
                    i18n-label
                    defaultValue="State"
                    i18n-defaultValue
                    [formControlName]="this.State"
                    [options]="countryStates$ | async"
                    [labelStyle]="FormLabelStyle"
                  ></app-ph-select>
                </div>
                <div class="col-12 col-md-3 mt-md-3">
                  <app-text-field-input
                    [identifier]="this.City"
                    label="City"
                    i18n-label
                    placeholder="City"
                    i18n-placeholder
                    [formControlName]="this.City"
                    [labelStyle]="FormLabelStyle"
                  ></app-text-field-input>
                </div>
              </div>
            </form>
            <div *ngIf="tooManyResult" class="validation-error-message">
              <span i18n>Too many results, please refine your search!</span>
            </div>
            <div *ngIf="isEmptyForm" class="validation-error-message">
              <span i18n>Please fill in at least one field!</span>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink i18n>Invitation Code</a>
          <ng-template ngbNavContent>
            <div class="row tabbed-form justify-content-center mx-0 py-3">
              <div class="col col-md-7 px-md-0">
                <form [formGroup]="invitationCodeForm">
                  <app-text-field-input
                    [identifier]="InvitationCode"
                    label="Consultant registration CODE"
                    i18n-label
                    placeholder="Password provided by your Consultant"
                    i18n-placeholder
                    [formControlName]="InvitationCode"
                    [labelStyle]="FormLabelStyle"
                  ></app-text-field-input>
                </form>
                <div *ngIf="(findIsSuccess$ | async) === false" class="validation-error-message">
                  <span i18n>Sorry there is no Consultant with this code</span>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>

    <div class="col-12 col-md-10 validation-error-message mt-3">
      <span *ngIf="(reCaptchaValidation$ | async) === SuccessfulStatus.Failed" i18n>
        ReCaptcha validation failed. Please try again later.
      </span>
    </div>
  </div>
  <ng-template #otherCountry>
    <div class="row mb-3 mb-md-5 justify-content-center">
      <div class="col col-md-6">
        <form [formGroup]="invitationCodeForm">
          <app-text-field-input
            [identifier]="InvitationCode"
            label="Consultant registration CODE"
            i18n-label
            placeholder="Password provided by your Consultant"
            i18n-placeholder
            [formControlName]="InvitationCode"
            [labelStyle]="FormLabelStyle"
          ></app-text-field-input>
        </form>
        <div class="validation-error-message">
          <span *ngIf="(findIsSuccess$ | async) === false" i18n>
            Sorry there is no Consultant with this code
          </span>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="row mb-5 justify-content-center">
    <div class="col col-md-3">
      <div class="white-button-container">
        <button
          class="btn btn-outline-primary text-uppercase w-100"
          i18n
          (click)="previousStepClick()"
        >
          Back
        </button>
      </div>
    </div>
    <div class="col col-md-3">
      <button
        id="next-button"
        class="btn btn-primary text-uppercase w-100"
        [disabled]="stepProcessing$ | async"
        (click)="nextStepClick()"
      >
        <span i18n>Next</span>
        <div class="spinner-border spinner-border-sm" *ngIf="stepProcessing$ | async" role="status">
          <span class="sr-only" i18n>Loading...</span>
        </div>
      </button>
    </div>
  </div>
</div>

<app-no-consultant-found-modal
  [dismiss]="onDismissModal"
  [openModal$]="noConsultantFindModalOpen$"
></app-no-consultant-found-modal>

<app-sna-recaptcha #reCaptcha></app-sna-recaptcha>
