import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidEmailRegExp } from '@core/constants/patterns';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { selectVoucherEmail, selectVoucherPrevalidation } from '@core/store/voucher';
import { prevalidateVoucher, resetVoucherErrors } from '@core/store/voucher/voucher.actions';
import { Store } from '@ngrx/store';
import { emailValidator } from '@shared/utils/email-validator-utils';
import { getVoucherErrorMessage } from '@shared/utils/voucher-error-utils';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-checkout-voucher',
  templateUrl: './checkout-voucher.component.html',
  styleUrls: ['./checkout-voucher.component.scss'],
})
export class CheckoutVoucherComponent implements OnInit, OnDestroy {
  @Output() emailChange: EventEmitter<string> = new EventEmitter<string>();

  readonly emailKey = 'email';

  subscriptions: Subscription = new Subscription();

  email: string;
  form: FormGroup;
  prevalidateErrorMessage: string;

  constructor(
    private store$: Store<AppState>,
    private fb: FormBuilder,
    private appInitService: AppInitService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      [this.emailKey]: ['', [Validators.required, emailValidator(ValidEmailRegExp)]],
    });
    this.initVoucherPreValidator();
    this.initCreateVoucherPayload();
    this.subscriptions.add(
      this.store$.select(selectVoucherPrevalidation).subscribe(({ prevalidated, error }) => {
        if (prevalidated && !error?.errors?.length) {
          this.emailChange.emit(this.form.get(this.emailKey).value);
        } else {
          this.emailChange.emit(null);
        }

        this.prevalidateErrorMessage = getVoucherErrorMessage(
          error?.errors,
          error?.email,
          this.appInitService.Settings.ec.voucherLimits,
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initCreateVoucherPayload() {
    const voucherEmail$ = this.store$.select(selectVoucherEmail);

    voucherEmail$.pipe(take(1)).subscribe((email) => {
      this.form.patchValue({ email });
    });
  }

  private initVoucherPreValidator() {
    this.subscriptions.add(
      this.form
        .get(this.emailKey)
        .valueChanges.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          filter((email) => !!email),
        )
        .subscribe((email) => {
          if (this.form.get(this.emailKey).valid) {
            this.store$.dispatch(prevalidateVoucher({ email }));
          }
        }),
    );

    this.subscriptions.add(
      this.form.get(this.emailKey).valueChanges.subscribe(() => {
        if (!this.form.get(this.emailKey).valid) {
          this.store$.dispatch(resetVoucherErrors());
        }
      }),
    );
  }
}
