import {
  AfterViewInit,
  Component,
  Inject,
  Injector,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  SecurityContext,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FormErrorTypes } from '@core/enums/form-error-type.enum';
import { Locale } from '@core/enums/locale';
import { StartNowAppService } from '@core/services/start-now-app.service';
import { AppState } from '@core/store';
import {
  selectAgreementStep,
  selectAreTermsConditionsAgreed,
  selectUserInfo,
} from '@core/store/start-now-app';
import { AgreementStep } from '@core/store/start-now-app/start-now-app-state-models';
import {
  resetAgreementStep,
  stepProcessing,
  updateAgreementStepNavigableFlag,
  updateAreTermsConditionsAgreed,
  updateSsnNumber,
} from '@core/store/start-now-app/start-now-app.actions';
import { environment } from '@env';
import { select, Store } from '@ngrx/store';
import { SafeResourceUrlPipe } from '@shared/pipes/safe-url.pipe';
import { saveFile } from '@shared/utils/save-file.utils';
import { getFormValidationErrorMessage, validInput } from '@shared/utils/validation.utils';
import { StatusCodes } from 'http-status-codes';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-consultant-agreement-step',
  templateUrl: './consultant-agreement-step.component.html',
  styleUrls: ['./consultant-agreement-step.component.scss'],
})
export class ConsultantAgreementStepComponent
  extends StartNowStepBaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  termsConditionsURL: string = '';
  readonly TermsConditions: string = 'termsConditions';
  readonly SsnNumberFieldName: string = 'ssnNumber';
  readonly StatusCodes = StatusCodes;
  readonly SsnErrorMessage: string = $localize`Please enter a valid Social Security Number`;
  readonly ErrorMessages = {
    [FormErrorTypes.required]: $localize`You need to agree before you got to the next step!`,
  };

  getFormValidationErrorMessage = getFormValidationErrorMessage;

  public agreementFormGroup: FormGroup;
  public ssnNumberFormGroup: FormGroup;
  areTermConditionsAgreed$: Observable<boolean>;
  termsConditionsSafeUrl: string;
  agreementStep$: Observable<AgreementStep>;

  constructor(
    private store$: Store<AppState>,
    private fb: FormBuilder,
    private safeResourceUrlPipe: SafeResourceUrlPipe,
    private domSanitizer: DomSanitizer,
    private startNowAppService: StartNowAppService,
    @Inject(LOCALE_ID) private localeId: Locale,
    injector: Injector,
  ) {
    super(injector, 'SNA Step - 7 Consultant Agreement');
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.store$.dispatch(stepProcessing({ stepProcessing: false }));
    }, 500);
  }

  ngOnInit(): void {
    this.agreementStep$ = this.store$.select(selectAgreementStep);
    this.termsConditionsURL = `${environment.agreementHtmlBaseUrl}/agreements/consultantAgreement-${
      this.localeId === 'en' ? 'en-US' : this.localeId
    }.html`;
    this.agreementFormGroup = this.createFormGroup();
    this.areTermConditionsAgreed$ = this.store$.select(selectAreTermsConditionsAgreed);
    this.listenIsNextEnabled();

    if (this.isUsaEnv) {
      this.ssnNumberFormGroup = this.createSsnFormGroup();
      this.prePopulateSsnNumberForm();
    }
    this.subscriptions.add(
      this.areTermConditionsAgreed$.subscribe((value) => {
        this.agreementFormGroup.setValue({
          termsConditions: value,
        });
      }),
    );
    this.termsConditionsSafeUrl = this.getAgreementSafeUrl();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store$.dispatch(resetAgreementStep());
  }

  submitStep(): void {
    this.agreementFormGroup.markAllAsTouched();
    if (this.agreementFormGroup.valid) {
      if (this.isUsaEnv && this.ssnNumberFormGroup.valid) {
        this.store$.dispatch(
          updateSsnNumber({
            ssnNumber: this.ssnNumberFormGroup.get(this.SsnNumberFieldName).value,
          }),
        );
      } else if (!this.isUsaEnv) {
        this.store$.dispatch(updateAgreementStepNavigableFlag({ isNextEnabled: true }));
      }
    }
  }

  getAgreementSafeUrl(): string {
    return this.domSanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.safeResourceUrlPipe.transform(this.termsConditionsURL),
    );
  }

  get termsConditionValidInput(): boolean {
    return validInput(this.agreementFormGroup.get(this.TermsConditions));
  }

  public printAgreement() {
    this.startNowAppService.getAgreementPdf().subscribe((blob) => {
      saveFile(blob, $localize`ConsultantAgreement.pdf`, 'application/pdf');
    });
  }

  protected createFormGroup(): FormGroup {
    return this.fb.group({
      [this.TermsConditions]: [this.areTermConditionsAgreed$, [Validators.requiredTrue]],
    });
  }

  private createSsnFormGroup(): FormGroup {
    return this.fb.group({
      [this.SsnNumberFieldName]: ['', [Validators.minLength(11)]],
    });
  }

  private nextStep(): void {
    this.store$.dispatch(
      updateAreTermsConditionsAgreed({
        areTermsConditionsAgreed: this.agreementFormGroup.get(this.TermsConditions).value,
      }),
    );
    this.goToNextStep.emit();
  }

  private listenIsNextEnabled(): void {
    this.subscriptions.add(
      this.agreementStep$
        .pipe(
          filter(
            (agreementStep) =>
              agreementStep?.isNextEnabled &&
              !agreementStep?.errors?.length &&
              !agreementStep?.statusCode,
          ),
          take(1),
        )
        .subscribe(() => this.nextStep()),
    );
  }

  private prePopulateSsnNumberForm(): void {
    this.store$
      .select(selectUserInfo)
      .pipe(
        select((userInfo) => userInfo?.ssnNumber),
        take(1),
        filter((ssnNumber) => !!ssnNumber),
      )
      .subscribe((ssnNumber) => {
        this.ssnNumberFormGroup.patchValue({ [this.SsnNumberFieldName]: ssnNumber });
      });
  }
}
