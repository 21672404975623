<div class="container">
  <div class="row">
    <h3 class="col-12" i18n>Find a consultant</h3>
  </div>

  <app-consultant-finder
    #consultantFinder
    [type]="ConsultantFinderType.locate"
    (valid)="onFinderValidChange($event)"
    (submitted)="onFinderSubmitted($event)"
  ></app-consultant-finder>

  <div class="d-flex justify-content-start">
    <button
      class="btn btn-primary text-uppercase mb-4"
      [disabled]="!finderIsValid"
      (click)="search()"
      i18n
      >Search</button
    >
  </div>

  <div class="font-italic mb-4">
    <span i18n>Already know a Consultant?</span>
    <a class="link-highlight" routerLink="/consultant-finder"
      ><span class="mx-1" i18n>Search by name</span>></a
    ></div
  >

  <app-consultant-finder-result
    [filter]="filter"
    [finderType]="ConsultantFinderType.locate"
    (consultantSelected)="selected()"
  ></app-consultant-finder-result>
</div>
