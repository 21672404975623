<ng-container [ngSwitch]="activeStepId">
  <ng-container *ngSwitchCase="StartNowAppStep.DoYouKnowConsultant">
    <app-do-you-know-consultant-step
      (knowConsultantChoice)="onKnowConsultantChoice($event)"
    ></app-do-you-know-consultant-step>
  </ng-container>
  <!-- Do you know Consultant - NO case -->
  <ng-container
    *ngSwitchCase="
      activeStepId === StartNowAppStep.FindConsultantByZipCode && doIKnowAConsultant === true
        ? activeStepId
        : ''
    "
  >
    <app-select-consultant-step
      [doIKnowAConsultant]="doIKnowAConsultant"
      (goToNextStep)="goToNextStep()"
      (goToPreviousStep)="goToPreviousStep()"
    ></app-select-consultant-step>
  </ng-container>
  <!-- Do you know Consultant - YES case -->
  <ng-container
    *ngSwitchCase="
      activeStepId === StartNowAppStep.SelectConsultant && doIKnowAConsultant === false
        ? activeStepId
        : ''
    "
  >
    <app-find-consultant-by-zip-code
      [doIKnowAConsultant]="doIKnowAConsultant"
      (goToNextStep)="goToNextStep()"
      (goToPreviousStep)="goToPreviousStep()"
    ></app-find-consultant-by-zip-code>
  </ng-container>
  <ng-container *ngSwitchCase="StartNowAppStep.ConfirmConsultant">
    <app-confirm-consultant-step
      [isGoToPreviousDisabled]="isGoToPreviousDisabled()"
      (goToNextStep)="goToNextStep()"
      (goToPreviousStep)="goToPreviousStep()"
      (goToOtherStep)="goToOtherStep($event)"
    ></app-confirm-consultant-step>
  </ng-container>
  <ng-container *ngSwitchCase="StartNowAppStep.RegistrationCode">
    <app-registration-code-step
      (goToNextStep)="goToNextStep()"
      (goToPreviousStep)="goToPreviousStep()"
    ></app-registration-code-step>
  </ng-container>
</ng-container>
