import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-saved-voucher',
  templateUrl: './saved-voucher.component.html',
  styleUrls: ['./saved-voucher.component.scss'],
})
export class SavedVoucherComponent {
  @Input() email: string;
  @Input() hideChange: boolean = false;
  @Output() resetPayment: EventEmitter<void> = new EventEmitter();

  constructor() {}

  resetPaymentClick() {
    this.resetPayment.emit();
  }
}
