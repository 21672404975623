import { Component, Input } from '@angular/core';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { ImageDetails } from '@core/models/image.model';

@Component({
  selector: 'app-blog-image',
  templateUrl: './blog-image.component.html',
  styleUrls: ['./blog-image.component.scss'],
})
export class BlogImageComponent {
  @Input() images: ImageDetails[];

  thumbnail = Thumbnail;

  constructor() {}
}
