<div class="error-message-container col-10">
  <div>
    <div class="row">
      <div class="col-8 error-header">
        <span i18n>
          Voucher error
          <i class="bi bi-exclamation-triangle"></i>
        </span>
      </div>
      <div class="col-4 change-voucher" (click)="resetPaymentClick()">
        <span i18n>Change</span>
      </div>
    </div>
    <div class="error-name">
      {{ errorMessage }}
    </div>

    <div class="voucher-details" *ngIf="email">
      <section class="row">
        <div class="col-10">
          <div class="form-group">
            <label for="error-voucher-email" i18n>Email address</label>
            <input
              id="error-voucher-email"
              type="text"
              readonly
              [ngModel]="email"
              name="email"
              [isDataPrivate]="true"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
