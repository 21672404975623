import { Injectable } from '@angular/core';
import { LogLevel } from '@core/enums/log-level.enum';
import { environment } from '@env';
import LogRocket from 'logrocket';
import { TimerService } from './timer.service';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(private timerService: TimerService) {}

  log(message: string, error: any = undefined): void {
    this.logWith(LogLevel.Log, message, error);
  }

  logTimerStart(label: string): void {
    if (LogLevel.Log <= environment.highestConsolLogLevel) {
      console.time(label);
    } else {
      this.timerService.startTimer(label, (millisec) => {
        LogRocket.log(`Timer ${label} took ${millisec} ms`);
      });
    }
  }

  logTimerEnd(label: string): void {
    if (LogLevel.Log <= environment.highestConsolLogLevel) {
      console.timeEnd(label);
    } else {
      this.timerService.endTimer(label);
    }
  }

  info(message: string, error: any = undefined): void {
    this.logWith(LogLevel.Info, message, error);
  }

  debug(message: string, error: any = undefined): void {
    this.logWith(LogLevel.Debug, message, error);
  }

  warn(message: string, error: any = undefined): void {
    this.logWith(LogLevel.Warn, message, error);
  }

  error(message: string, error: any = undefined): void {
    this.logWith(LogLevel.Error, message, error);
  }

  private logWith(logLevel: LogLevel, message: string, error: any = undefined): void {
    if (logLevel <= environment.highestConsolLogLevel) {
      switch (logLevel) {
        case LogLevel.Log:
          console.log(message, error);
          return;
        case LogLevel.Info:
          console.info(message, error);
          return;
        case LogLevel.Debug:
          console.debug(message, error);
          return;
        case LogLevel.Warn:
          console.warn(message, error);
          return;
        case LogLevel.Error:
          console.error(message, error);
          return;
        default:
          console.log(message, error);
          return;
      }
    } else {
      switch (logLevel) {
        case LogLevel.Log:
          LogRocket.log(message, error);
          return;
        case LogLevel.Info:
          LogRocket.info(message, error);
          return;
        case LogLevel.Debug:
          LogRocket.debug(message, error);
          return;
        case LogLevel.Warn:
          LogRocket.warn(message, error);
          return;
        case LogLevel.Error:
          LogRocket.error(message, error);
          return;
        default:
          LogRocket.log(message, error);
          return;
      }
    }
  }
}
