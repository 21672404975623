import produce from 'immer';
import { FooterLink } from './footer-state-models';
import { FooterActionTypes } from './footer.actions';

export interface FooterState {
  primaryFooterLinks: FooterLink[];
  secondaryFooterLinks: FooterLink[];
  error: any;
}

export const initialState: FooterState = {
  primaryFooterLinks: null,
  secondaryFooterLinks: null,
  error: null,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case FooterActionTypes.fetchPrimaryFooterLinksSuccess:
      draft.primaryFooterLinks = action.links;
      return draft;

    case FooterActionTypes.fetchSecondaryFooterLinksSuccess:
      draft.secondaryFooterLinks = action.links;
      return draft;

    case FooterActionTypes.fetchFooterLinksError:
      draft.error = action.error;
      return draft;

    default:
      return draft;
  }
}, initialState);
