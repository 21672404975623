import { Injectable, OnDestroy } from '@angular/core';
import { TimerModel } from '@core/models/timer.model';
import { Subject } from 'rxjs';
import { filter, map, pairwise, take, timeInterval } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TimerService implements OnDestroy {
  readonly StartEvent = 'START';
  readonly EndEvent = 'END';

  private timerEventsArray: Map<string, TimerModel> = new Map();

  ngOnDestroy(): void {
    if (this.timerEventsArray.entries.length > 0) {
      this.timerEventsArray.forEach((element) => {
        if (!element.eventSubject.closed) {
          element.eventSubject.complete();
        }

        element.timer = null;
        element.subscription.unsubscribe();
      });
    }
  }

  startTimer(label: string, subscriber: (millisec: number) => void) {
    this.timerEventsArray.set(label, {
      eventSubject: new Subject(),
    });

    const element = this.timerEventsArray.get(label);
    element.timer = element.eventSubject.pipe(
      timeInterval(),
      pairwise(),
      filter(
        ([first, second]) => first.value === this.StartEvent && second.value === this.EndEvent,
      ),
      take(1),
      map(([, second]) => second.interval),
    );
    element.subscription = element.timer.subscribe(subscriber);
    element.eventSubject.next(this.StartEvent);
  }

  endTimer(label: string): void {
    const element = this.timerEventsArray.get(label);
    element.eventSubject.next(this.EndEvent);
    element.eventSubject.complete();
  }
}
