<ng-container>
  <label
    for="{{ identifier }}"
    [ngClass]="{ 'font-weight-bold': !isValidInput }"
    [ngStyle]="labelStyle"
    *ngIf="label"
  >
    {{ label }}
  </label>
  <div class="input-group">
    <input
      #input
      id="{{ identifier }}"
      type="number"
      name="{{ identifier }}"
      class="form-control"
      [ngClass]="{ 'error-border border-right-0': !isValidInput }"
      placeholder="{{ placeholder }}"
      [value]="value"
      [readonly]="readonly"
      (change)="updateValue($event)"
      [min]="min"
      [max]="max"
      (keydown)="onKeyDown($event)"
    />
    <div class="input-group-append" *ngIf="!isValidInput">
      <span class="input-group-text bg-transparent error-border border-left-0">
        <i class="bi bi-exclamation-triangle error-color"></i>
      </span>
    </div>
  </div>
  <div *ngIf="!isValidInput && errorMessages" class="validation-error-message">
    <div *ngFor="let item of errorMessages">{{ item }}</div>
  </div>
</ng-container>
