import { Component, Input } from '@angular/core';
import { MaintenanceErrorMessage } from '@core/constants/sna-error-messages';

@Component({
  selector: 'app-sna-disabled-page',
  templateUrl: './sna-disabled-page.component.html',
  styleUrls: ['./sna-disabled-page.component.scss'],
})
export class SnaDisabledPageComponent {
  @Input() isProwessDown: boolean = false;

  readonly errorMessages = {
    prowessDown: MaintenanceErrorMessage,
    manuallyDisabled: $localize`The Start Now App registration is currently turned
    off by PrincessHouse!`,
  };

  constructor() {}
}
