<ng-container [ngSwitch]="!!actionMethod">
  <button
    *ngSwitchCase="true"
    (click)="actionMethod(identifier)"
    class="bg-transparent border-0 p-0"
    [attr.aria-label]="ariaLabel"
    type="button"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
  <button
    *ngSwitchCase="false"
    [routerLink]="(actionPath || '') + identifier"
    class="bg-transparent border-0 p-0"
    [attr.aria-label]="ariaLabel"
    type="button"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
