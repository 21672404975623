<div class="header-top px-3 px-md-5">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-center">
      <button
        class="skip-to-link btn btn-light btn-sm"
        (click)="skipTo('#navigation')"
        type="button"
        i18n
        >Skip to navigation</button
      >
      <button
        class="skip-to-link btn btn-light btn-sm"
        (click)="skipTo('#mainContent')"
        type="button"
        i18n
        >Skip to main content</button
      >
      <button
        class="skip-to-link btn btn-light btn-sm"
        (click)="skipTo('#footer')"
        type="button"
        i18n
        >Skip to footer</button
      >
      <div class="offset-0 offset-sm-0 offset-md-2 col-10 col-sm-10 col-md-8 p-0 name-container">
        <ng-container *ngIf="feature.consultantFinder">
          <ng-container *ngIf="currentConsultant$ | async as consultant; else findAConsultant">
            <img class="icon" src="assets/images/header/INFO.svg" alt="info" />
            <a class="link pl-2" [routerLink]="'/' + ConsultantRoutes.MyStoryPageRoute">{{
              consultant.displayName
            }}</a>
          </ng-container>
          <ng-template #findAConsultant>
            <a routerLink="/consultant-finder" i18n>Find a consultant ></a>
          </ng-template>
        </ng-container>
      </div>
      <div class="col-2 col-sm-2 col-md-2 p-0 d-flex align-items-center justify-content-end">
        <app-language-selector></app-language-selector>
      </div>
    </div>
  </div>
</div>
<app-party-header></app-party-header>
