import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageKey } from '@core/enums/local-storage-key.enum';
import { EcAppSettings } from '@core/models/app-settings.model';
import { RelatedCarouselSlide } from '@core/models/related-carousel-slide.model';
import { AppInitService } from '@core/services/app-init.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { AppState } from '@core/store';
import { selectCart, selectLoading } from '@core/store/cart';
import { Cart } from '@core/store/cart/cart-state-models';
import { fetchCart, fetchProductsRecommendations } from '@core/store/cart/cart.actions';
import { ProductRecommendation } from '@core/store/product/product-state-models';
import { select, Store } from '@ngrx/store';
import { ProductUtilService } from '@shared/utils/product-util.service';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { CartItemUpdatedWarningModalComponent } from '../cart-item-updated-warning-modal/cart-item-updated-warning-modal.component';

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss'],
})
export class CartPageComponent implements OnInit, OnDestroy, AfterViewInit {
  showCouponTextField: boolean = false;
  cart$: Observable<Cart>;
  recommendations$: Observable<ProductRecommendation[]>;
  slides$: Observable<RelatedCarouselSlide[]>;
  loading$: Observable<boolean>;
  appSettings: EcAppSettings;

  private subscriptions: Subscription = new Subscription();

  @ViewChild('itemUpdatedModal') private itemUpdatedModal: CartItemUpdatedWarningModalComponent;

  constructor(
    private store$: Store<AppState>,
    private productUtil: ProductUtilService,
    private router: Router,
    private localStorageService: LocalStorageService,
    appInitService: AppInitService,
  ) {
    this.appSettings = appInitService.Settings.ec;
  }

  showCouponField(): void {
    this.showCouponTextField = true;
  }

  checkout(): void {
    this.router.navigate(['/checkout']);
  }

  ngOnInit(): void {
    this.store$.dispatch(fetchCart());
    this.cart$ = this.store$.select(selectCart).pipe(filter((x) => !!x));
    this.recommendations$ = this.cart$.pipe(select((x) => x.recommendations || []));
    this.loading$ = this.store$.select(selectLoading);

    this.slides$ = this.recommendations$.pipe(
      filter((r) => !!r && !!r.length),
      map((r) => this.productUtil.getRelatedSlidesFromRecommendations(r)),
    );

    this.subscriptions.add(
      this.cart$
        .pipe(
          distinctUntilChanged(
            (a, b) => JSON.stringify(a.orderLines) === JSON.stringify(b.orderLines),
          ),
        )
        .subscribe((cart) => {
          const skus = cart.orderLines.map((orderLine) => orderLine.sku);
          skus?.length && this.store$.dispatch(fetchProductsRecommendations({ skus }));
          this.checkIsCartUpdated();
        }),
    );
  }

  ngAfterViewInit(): void {
    this.checkIsCartUpdated();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private checkIsCartUpdated(): void {
    if (this.localStorageService.getItem(LocalStorageKey.isCartUpdated)) {
      this.itemUpdatedModal.open();
    }
  }
}
