import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sortable-table-action',
  templateUrl: './sortable-table-action.component.html',
  styleUrls: ['./sortable-table-action.component.scss'],
})
export class SortableTableActionComponent {
  @Input() actionMethod: () => void;
  @Input() actionPath: string;
  @Input() identifier: string;
  @Input() ariaLabel: string;

  constructor() {}
}
