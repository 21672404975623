import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AddressType } from '@core/enums/address-type.enum';
import { InfoCardAttributes } from '@core/models/info-card-attributes.model';
import { OrderAddress } from '@core/store/order/order-state-models';
import { createAddressCardFromOrderAddress } from '@shared/utils/create-shipping-info-card-attributes';
import { ReturnOrderInformationType } from '../new-return/new-return.component';
import { AddressFormModalComponent } from './address-form-modal/address-form-modal.component';

@Component({
  selector: 'app-order-information',
  templateUrl: './order-information.component.html',
  styleUrls: ['./order-information.component.scss'],
})
export class OrderInformationComponent implements OnChanges {
  @Input() orderInfo: ReturnOrderInformationType;
  @Input() readOnly: boolean = true;
  @Output() orderInfoChange = new EventEmitter<ReturnOrderInformationType>();
  @ViewChild('addressFormModal') addressFormModal: AddressFormModalComponent;

  shippingAddressCardInformation: InfoCardAttributes;
  billingAddressCardInformation: InfoCardAttributes;
  partyCardInformation: InfoCardAttributes;
  selectedAddressType: AddressType;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.orderInfo.currentValue) {
      this.createCards();
    }
  }

  openModal(): void {
    this.addressFormModal.openModal();
  }

  addressChange(address: OrderAddress): void {
    this.orderInfo.shippingAddress = address;
    this.createCards();
  }

  private createCards(): void {
    this.shippingAddressCardInformation = createAddressCardFromOrderAddress(
      this.orderInfo.shippingAddress,
      $localize`Shipping information`,
    );
    this.shippingAddressCardInformation.readOnly = this.readOnly;
    this.partyCardInformation = { ...this.orderInfo.party, title: $localize`Party information` };
  }
}
