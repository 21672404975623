import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { CardholderName } from '@core/models/cardholder-name.model';
import { DropinInstance } from '@core/models/dropin-instance.model';
import { LogCardAttemptData } from '@core/models/log-card-attempt-data.model';
import { CardPaymentMethodHandlerComponent } from '@payment/components/card-payment-method-handler.component';
import { Observable } from 'rxjs';
import { CardPaymentProviderBaseComponent } from '../card-payment-provider-base-component';

@Component({
  selector: 'app-card-payment-wrapper',
  templateUrl: './card-payment-wrapper.component.html',
  styleUrls: ['./card-payment-wrapper.component.scss'],
})
export class CardPaymentWrapperComponent
  extends CardPaymentMethodHandlerComponent
  implements OnDestroy
{
  @Input() paymentProviderType: PaymentProviderType;
  @Input() payPalTopMargin: string = 'mt-5';
  @Input() disableInput: boolean = false;
  @Input() cardholderName: CardholderName;
  @Input() isPayPalCardPaymentEnabled: boolean = false;

  @Output() isPaymentApproved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isPaymentProviderLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cardAttempt: EventEmitter<LogCardAttemptData> = new EventEmitter<LogCardAttemptData>();

  public readonly PaymentProviderType = PaymentProviderType;
  public isPaymentHandlerLoading: boolean = true;

  private readonly OneSec = 1000;
  private paymentProviderComponent: CardPaymentProviderBaseComponent<DropinInstance> = undefined;

  constructor(private cdRef: ChangeDetectorRef) {
    super();
    this.isPaymentMethodRequestable.emit(false);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onDropInComponentInitialized(component: CardPaymentProviderBaseComponent<DropinInstance>): void {
    this.paymentProviderComponent = component;
    // TODO: StartNowApp - try/retry timeout would be safer here
    // have to wait a bit as the third party returns that is requestable it's still loading
    setTimeout(() => {
      this.isPaymentHandlerLoading = false;
      this.cdRef.detectChanges();
    }, this.OneSec);
  }

  handlePaymentRequestable(isPaymentMethodRequestable: boolean): void {
    this.isPaymentMethodRequestable.emit(isPaymentMethodRequestable);
  }

  /** Provides a way to wait the third party payment method process and stores the payment info */
  requestPaymentMethodObject(): Observable<boolean> {
    if (this.paymentProviderComponent) {
      return this.paymentProviderComponent.requestPaymentMethodObject();
    } else {
      return new Observable((subscribe) =>
        subscribe.error(new Error('Payment provider component not defined')),
      );
    }
  }

  /** Provides a way to wait the creation of payment method from BE
   * @param isPmoRequestSuccessful is Payment Method Object request is successful
   */
  createPaymentMethod(isPmoRequestSuccessful?: boolean): void {
    if (this.paymentProviderComponent) {
      if (isPmoRequestSuccessful) {
        this.paymentProviderComponent.createPaymentMethod();
      } else {
        // In case of Nexio we have to send back the Cvv and Avs results by Nexio iframe pmo
        if (this.paymentProviderType === PaymentProviderType.Nexio) {
          this.paymentProviderComponent.createPaymentMethod();
        } else {
          throw new Error('RequestPaymentMethodObject failed!');
        }
      }
    }
  }

  resetToken(cardholderFirstName: string = '', cardholderLastName: string = ''): void {
    this.isPaymentHandlerLoading = true;
    if (this.paymentProviderComponent) {
      this.paymentProviderComponent.resetToken(cardholderFirstName, cardholderLastName);
    }
  }

  paymentApproved(isCreated: boolean): void {
    this.isPaymentApproved.emit(isCreated);
  }

  forwardCardAttempt(cardAttemptData: LogCardAttemptData): void {
    this.cardAttempt.emit(cardAttemptData);
  }

  handleLoadingChange(isLoading: boolean): void {
    this.isPaymentProviderLoading.emit(isLoading);
    this.cdRef.detectChanges();
  }
}
