import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Locale } from '@core/enums/locale';
import { AppState } from '@core/store';
import { selectIsAuthenticated } from '@core/store/auth';
import { selectProductReviews, selectProductReviewsLoading } from '@core/store/product-review';
import { ProductReview } from '@core/store/product-review/product-review-state-models';
import {
  fetchMoreProductReviews,
  fetchProductReviews,
  resetProductReviewState,
} from '@core/store/product-review/product-review.action';
import { ProductDetailsItem } from '@core/store/product/product-state-models';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { getCulture } from '@shared/utils/locale-utils';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-product-review-and-rating',
  templateUrl: './product-review-and-rating.component.html',
  styleUrls: ['./product-review-and-rating.component.scss'],
})
export class ProductReviewAndRatingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() productDetailsItem: ProductDetailsItem;
  @Output() openReviewModal: EventEmitter<void> = new EventEmitter<void>();

  readonly initialBatchSize = 6;
  readonly allOption = 'all';
  readonly reviewFilterOptions = [
    this.allOption,
    ...environment.languages.map((language) => language.code),
  ];
  readonly filterLabel = {
    [this.allOption]: $localize`All`,
    [Locale.En]: $localize`English Reviews`,
    [Locale.EnUS]: $localize`English Reviews`,
    [Locale.EsUS]: $localize`Spanish Reviews`,
    [Locale.EsMX]: $localize`Spanish Reviews`,
  };

  isAuthenticated$: Observable<boolean>;
  productReviews$: Observable<ProductReview[]>;
  loading$: Observable<{}>;
  loadReviews$ = new Subject<{
    nextPage: number;
    pageSize: number;
    culture: 'all' | Locale;
  }>();
  selectedCulture: Locale | 'all' = this.allOption;

  private subscriptions: Subscription = new Subscription();

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.loading$ = this.store$.select(selectProductReviewsLoading);
    this.isAuthenticated$ = this.store$.select(selectIsAuthenticated);

    this.productReviews$ = this.store$.select(selectProductReviews);
  }

  ngOnChanges(): void {
    this.productDetailsItem && this.fetchReviews(this.allOption);
  }

  ngOnDestroy(): void {
    this.store$.dispatch(resetProductReviewState());
    this.subscriptions.unsubscribe();
  }

  onAddReview(): void {
    this.openReviewModal.emit();
  }

  fetchReviews(option: Locale | 'all') {
    this.selectedCulture = option;
    this.store$.dispatch(resetProductReviewState());

    this.store$.dispatch(
      fetchProductReviews({
        payload: {
          culture:
            this.selectedCulture === this.allOption ? null : getCulture(this.selectedCulture),
          sku: this.productDetailsItem.sku,
          pageSize: this.initialBatchSize,
        },
      }),
    );
  }

  onLoadReviewsNextPage(pageSize) {
    this.subscriptions.add(
      this.loading$
        .pipe(
          take(1),
          filter((loading) => !loading),
        )
        .subscribe(() => {
          this.store$.dispatch(
            fetchMoreProductReviews({
              payload: {
                culture:
                  this.selectedCulture === this.allOption ? null : getCulture(this.selectedCulture),
                sku: this.productDetailsItem.sku,
                pageSize: pageSize,
              },
            }),
          );
        }),
    );
  }
}
