import { Component, Input, OnInit } from '@angular/core';
import { BlogItem } from '@core/store/blog/blog-state-models';
import { createBlogDetailsUrl } from '@shared/utils/recipe-utils';

@Component({
  selector: 'app-blog-list-item',
  templateUrl: './blog-list-item.component.html',
  styleUrls: ['./blog-list-item.component.scss'],
})
export class BlogListItemComponent implements OnInit {
  @Input()
  blog: BlogItem;

  @Input() isOnSearchPage: boolean = false;

  detailsUrl: string;

  ngOnInit(): void {
    this.detailsUrl = createBlogDetailsUrl(this.blog?.url);
  }
}
