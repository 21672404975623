import { Component, OnInit } from '@angular/core';
import { AppState } from '@core/store';
import {
  fetchPrimaryFooterLinks,
  fetchSecondaryFooterLinks,
} from '@core/store/footer/footer.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-footer-link-initializer',
  template: '',
})
export class FooterLinkInitializerComponent implements OnInit {
  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.store$.dispatch(fetchPrimaryFooterLinks());
    this.store$.dispatch(fetchSecondaryFooterLinks());
  }
}
