import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit {
  title$: Observable<string>;
  message$: Observable<string>;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.message$ = this.route.data.pipe(select((data) => data?.message));
    this.title$ = this.route.data.pipe(select((data) => data?.title));
  }
}
