<div class="saved-card-container">
  <div>
    <div class="d-flex justify-content-between px-2">
      <div class="saved-card-header">
        <span i18n>
          Saved Card
          <i class="bi bi-check"></i>
        </span>
      </div>
      <div *ngIf="canChangeSavedCard" class="change-card" (click)="resetPaymentClick()">
        <span i18n>Change</span>
      </div>
    </div>

    <div class="card-details px-2" *ngIf="cardInfo?.card">
      <ng-container *ngIf="isMexEnv">
        <section class="row">
          <div class="col-12 col-md-10">
            <div class="form-group">
              <label for="saved-card-type" i18n>Card type</label>
              <input
                id="saved-card-type"
                type="text"
                readonly
                [ngModel]="cardTypeDisplayValue"
                name="installment"
              />
            </div>
          </div>
        </section>
      </ng-container>
      <section class="row">
        <div class="col-12 col-md-10">
          <div class="form-group">
            <label for="saved-card-holder-name" i18n>Cardholder Name</label>
            <input
              id="saved-card-holder-name"
              type="text"
              readonly
              [ngModel]="cardInfo.card.cardholderName"
              name="cardholderName"
            />
          </div>
        </div>
      </section>

      <section class="row">
        <div class="col-12 col-md-10">
          <div class="form-group">
            <label for="saved-card-number" i18n>Card Number</label>
            <input
              id="saved-card-number"
              type="text"
              readonly
              [ngModel]="cardInfo.card.maskedNumber"
              name="maskedNumber"
            />
          </div>
        </div>
      </section>

      <section class="row">
        <div class="col-12 col-md-10">
          <div class="form-group">
            <label for="saved-card-expiration-date" i18n>Expiration date</label>
            <input
              id="saved-card-expiration-date"
              type="text"
              readonly
              [ngModel]="cardInfo.card.expirationDate"
              name="expirationDate"
            />
          </div>
        </div>
      </section>
    </div>
    <div class="card-details" *ngIf="cardInfo?.account">
      <section class="row">
        <div class="col-12 col-md-10">
          <div class="form-group">
            <label for="saved-card-holder-name" i18n>Cardholder Name</label>
            <input
              id="saved-card-holder-name"
              type="text"
              readonly
              [ngModel]="accountCardHolderFullName"
              name="cardholderName"
            />
          </div>
        </div>
      </section>

      <section class="row">
        <div class="col-12 col-md-10">
          <div class="form-group">
            <label for="saved-card-number" i18n>Email</label>
            <input
              id="saved-card-number"
              type="text"
              readonly
              [ngModel]="cardInfo.account.email"
              name="maskedNumber"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
