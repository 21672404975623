import produce from 'immer';
import { ReturnState } from './return-state-models';
import { ReturnActionTypes } from './return.actions';

export const initialState: ReturnState = {
  loading: false,
  returnHistory: [],
  returnDetails: null,
  returnSucceeded: false,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case ReturnActionTypes.resetReturnState:
      return initialState;

    case ReturnActionTypes.fetchReturnHistory:
      draft.loading = true;
      draft.returnHistory = initialState.returnHistory;
      return;

    case ReturnActionTypes.fetchReturnHistorySuccess:
      draft.loading = false;
      draft.returnHistory = action.returnHistory;
      return;

    case ReturnActionTypes.fetchReturnHistoryFailure:
      draft.loading = false;
      return;

    case ReturnActionTypes.submitReturn:
      draft.loading = true;
      draft.returnSucceeded = false;
      return;

    case ReturnActionTypes.submitReturnFailure:
      draft.loading = false;
      draft.returnSucceeded = false;
      return;

    case ReturnActionTypes.submitReturnSuccess:
      draft.loading = false;
      draft.returnSucceeded = true;

    case ReturnActionTypes.fetchReturnDetails:
      draft.loading = true;
      draft.returnDetails = initialState.returnDetails;
      return;

    case ReturnActionTypes.fetchReturnDetailsSuccess:
      draft.loading = false;
      draft.returnDetails = action.returnDetails;
      return;

    case ReturnActionTypes.fetchReturnDetailsFailure:
      draft.loading = false;
      return;

    default:
      return draft;
  }
}, initialState);
