import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidEmailRegExp } from '@core/constants/patterns';
import { AppState } from '@core/store';
import { selectPasswordResetSent, selectUserLoading } from '@core/store/user';
import { resetPassword, resetResetPasswordState } from '@core/store/user/user.actions';
import { Store } from '@ngrx/store';
import { emailValidator } from '@shared/utils/email-validator-utils';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './password-reset-page.component.html',
  styleUrls: ['./password-reset-page.component.scss'],
})
export class PasswordResetPageComponent implements OnInit {
  loading$: Observable<boolean>;
  passwordResetSent$: Observable<boolean>;
  email: string;

  form = this.fb.group({
    email: ['', [Validators.required, emailValidator(ValidEmailRegExp)]],
  });

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.store$.dispatch(resetResetPasswordState());
    this.loading$ = this.store$.select(selectUserLoading);
    this.passwordResetSent$ = this.store$.select(selectPasswordResetSent);
  }

  submit() {
    this.email = this.form.value.email;
    this.store$.dispatch(resetPassword({ email: this.form.value.email }));
  }
}
