<div class="input-group">
  <label for="search" i18n class="sr-only sr-only-focusable">Search</label>
  <input
    class="form-control rounded-left py-2 border shadow-none"
    i18n-placeholder
    placeholder="Search"
    type="search"
    id="search"
    [(ngModel)]="searchTerm"
    (keyup.enter)="search()"
    [disabled]="loading$ | async"
  />
  <div class="input-group-append">
    <button
      aria-label="Search"
      i18n-aria-label
      class="btn border search-button shadow-none"
      type="button"
      (click)="search()"
      [disabled]="loading$ | async"
    >
      <i alt="" class="bi bi-search"></i
    ></button>
  </div>
</div>
