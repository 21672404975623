<hr class="d-none d-md-block mb-0" />
<div class="row">
  <div class="col-12 col-md-6">
    <app-my-returns-info-card
      [data]="shippingAddressCardInformation"
      (openChangeAddressModal)="openModal()"
    ></app-my-returns-info-card>
  </div>
  <div class="col-12 col-md-6" *ngIf="partyCardInformation.host">
    <app-my-returns-info-card [data]="partyCardInformation"></app-my-returns-info-card>
  </div>
</div>
<hr class="d-none d-md-block mt-0" />

<app-address-form-modal
  #addressFormModal
  [address]="orderInfo?.shippingAddress"
  (addressChange)="addressChange($event)"
></app-address-form-modal>
