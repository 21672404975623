import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MobileMedia } from '@core/constants/screen-sizes';
import { TableSortDirection } from '@core/enums/table-sort-direction.enum';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { SortableTableColumn } from '@core/models/sortable-table-column.model';
import { AppState } from '@core/store';
import { selectUserRecipes } from '@core/store/recipe';
import { RecipeItem } from '@core/store/recipe/recipe-state-models';
import { dislikeRecipe, fetchUserRecipes } from '@core/store/recipe/recipe.actions';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss'],
})
export class RecipesComponent implements OnInit {
  readonly Thumbnail = Thumbnail;
  readonly columns: SortableTableColumn[] = [
    {
      field: 'name',
      headerName: $localize`Recipe name`,
      sortable: true,
      sort: TableSortDirection.asc,
      imageField: 'images',
      thumbnail: Thumbnail.recipeListDesktop,
      action: true,
      actionPath: '/recipe/detail/',
      actionField: 'url',
      styles: { fontWeight: 'bold' },
    },
    { field: 'categories', headerName: $localize`Category`, styles: { fontSize: '0.8rem' } },
    {
      field: 'id',
      headerName: '',
      action: true,
      actionIcon: 'bi bi-trash',
      actionMethod: this.dislike.bind(this),
      ariaLabel: $localize`Remove from wishlist`,
    },
  ];

  loading$: Observable<boolean>;
  recipes$: Observable<RecipeItem[]>;

  constructor(private store$: Store<AppState>, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.store$.dispatch(fetchUserRecipes());

    const userRecipes = this.store$.select(selectUserRecipes);

    this.recipes$ = userRecipes.pipe(
      map((ur) => ur.items.slice().sort((a, b) => (a.name > b.name ? 1 : -1))),
    );

    this.loading$ = userRecipes.pipe(select((ur) => ur.loading));
  }

  isInMobileView(): boolean {
    return this.breakpointObserver.isMatched(MobileMedia);
  }

  dislike(id: string, url: string) {
    this.store$.dispatch(dislikeRecipe({ id, url }));
  }
}
