import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DEFAULT_PAGE_DESCRIPTION, DEFAULT_PAGE_TITLE } from '@core/constants/page-title';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-title-initializer',
  template: '',
})
export class TitleInitializerComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private titleService: Title,
    private meta: Meta,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => {
            let route: ActivatedRoute = this.router.routerState.root;
            while (route.firstChild) {
              route = route.firstChild;
            }
            return [
              this.getTitle(route.snapshot.data['title']),
              this.getTitle(route.snapshot.data['description']),
            ];
          }),
        )
        .subscribe(([title, description]) => {
          this.titleService.setTitle(title);
          this.meta.updateTag({ name: 'description', content: this.getDescription(description) });
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getTitle(title: string): string {
    return title ? title : DEFAULT_PAGE_TITLE;
  }

  private getDescription(description: string): string {
    return description ? description : DEFAULT_PAGE_DESCRIPTION;
  }
}
