<ng-container *ngIf="disableHeader !== true">
  <div class="row font-size-smaller d-lt-none text-uppercase font-weight-bold">
    <div class="col-md-5">
      <p i18n>Product(s)</p>
    </div>
    <div class="col-md-2">
      <p i18n>Price</p>
    </div>
    <div class="col-md-2">
      <p i18n>Qty</p>
    </div>
    <div class="col-md-1">
      <p i18n>Total</p>
    </div>
  </div>
  <hr class="mt-0 d-lt-none" />
</ng-container>
<ng-container *ngFor="let orderLine of cart.orderLines">
  <app-order-line
    [readOnly]="readOnly"
    [orderLine]="orderLine"
    [updateQty]="updateOrderLineQty"
    [remove]="openDeleteConfirmationModal"
    [productClickable]="productClickable"
  ></app-order-line>
</ng-container>
<app-delete-from-cart-confirm-dialog
  #deleteConfirmModal
  [removeItem]="deleteCartItem"
></app-delete-from-cart-confirm-dialog>
