import { createAction, props } from '@ngrx/store';
import { PhErrorResponse } from '@core/dto/ph-error';
import { WishlistItem } from '@core/store/wishlist/wishlist-state-models';
import { WishlistResponse } from '@core/dto/wishlist.dto';
import { HttpErrorResponse } from '@angular/common/http';
export enum WishlistActionTypes {
  resetWishlistState = '[Wishlist] Reset wishlist state',

  fetchWishlist = '[Wishlist] Fetch wishlist',
  fetchWishlistSuccess = '[Wishlist] Fetch wishlist success',
  fetchWishlistFailure = '[Wishlist] Fetch wishlist failure',

  addToWishlist = '[Wishlist] Add to wishlist',
  addToWishlistSuccess = '[Wishlist] Add to wishlist success',
  addToWishlistFailure = '[Wishlist] Add to wishlist failure',

  mergeWishlist = '[Wishlist] Merge Wishlist',
  mergeWishlistSuccess = '[Wishlist] Merge Wishlist Success',
  mergeWishlistFailure = '[Wishlist] Merge Wishlist Failure',

  deleteFromWishlist = '[Wishlist] Delete from wishlist',
  deleteFromWishlistSuccess = '[Wishlist] Delete from wishlist success',
  deleteFromWishlistFailure = '[Wishlist] Delete from wishlist failure',

  newWishlist = '[Wishlist] New wishlist',
  newWishlistSuccess = '[Wishlist] New wishlist success',
  newWishlistFailure = '[Wishlist] New wishlist failure',
}

export const resetWishlistState = createAction(WishlistActionTypes.resetWishlistState);
export const fetchWishlist = createAction(WishlistActionTypes.fetchWishlist);
export const fetchWishlistSuccess = createAction(
  WishlistActionTypes.fetchWishlistSuccess,
  props<{ wishListItems: WishlistItem[] }>(),
);
export const fetchWishlistFailure = createAction(
  WishlistActionTypes.fetchWishlistFailure,
  props<{ error: PhErrorResponse }>(),
);

export const addToWishList = createAction(
  WishlistActionTypes.addToWishlist,
  props<{ sku: string }>(),
);

export const addToWishlistSuccess = createAction(
  WishlistActionTypes.addToWishlistSuccess,
  props<{ wishListResponse: WishlistResponse }>(),
);

export const addToWishlistFailure = createAction(
  WishlistActionTypes.addToWishlistFailure,
  props<{ error: PhErrorResponse }>(),
);

export const mergeWishlist = createAction(WishlistActionTypes.mergeWishlist);
export const mergeWishlistSuccess = createAction(WishlistActionTypes.mergeWishlistSuccess);
export const mergeWishlistFailure = createAction(
  WishlistActionTypes.mergeWishlistFailure,
  props<{ error: HttpErrorResponse }>(),
);

export const deleteFromWishlist = createAction(
  WishlistActionTypes.deleteFromWishlist,
  props<{ sku: string }>(),
);

export const deleteFromWishlistSuccess = createAction(
  WishlistActionTypes.deleteFromWishlistSuccess,
  props<{ wishListResponse: WishlistResponse }>(),
);

export const deleteFromWishlistFailure = createAction(
  WishlistActionTypes.deleteFromWishlistFailure,
  props<{ error: PhErrorResponse }>(),
);

export const newWishlist = createAction(WishlistActionTypes.newWishlist);
export const newWishlistSuccess = createAction(WishlistActionTypes.newWishlistSuccess);
export const newWishlistFailure = createAction(WishlistActionTypes.newWishlistFailure);
