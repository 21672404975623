<h1 class="text-title" i18n>Your information is important to us</h1>
<h6 class="text-subtitle" i18n> Please double-check that everything is correct </h6>

<div *ngIf="startNowAppData" class="mb-3">
  <div class="row">
    <div class="col-12 col-md-6 mt-5">
      <hr class="d-md-none mb-0" />
      <div class="d-flex justify-content-between">
        <h5 class="font-weight-bold title my-3 my-md-2" i18n>Personal Information</h5>
        <div *ngIf="(stepProcessing$ | async) === false && (consultantDataCanNotBeModified$ | async) === false">
          <a
            class="d-md-block d-none text-uppercase font-weight-bold my-2"
            (click)="navigateTo(StartNowAppStep.AboutYou)"
            i18n
          >
            Change
          </a>
          <a class="d-md-none title my-2" (click)="navigateTo(StartNowAppStep.AboutYou)">
            <i class="bi bi-pencil-square"></i>
          </a>
        </div>
      </div>
      <hr class="d-md-none mt-0" />
      <div class="card">
        <div class="ph-row p-md-2">
          <span class="text-double-dot" i18n>Name</span>
          <strong>{{ fullName(startNowAppData.userInfo) }}</strong>
        </div>
        <div class="ph-row px-md-2 pb-md-2">
          <span class="text-double-dot" i18n>Date of Birth</span>
          <strong *ngIf="isMexEnv; else usaDate">
            {{ startNowAppData.userInfo.birthday | date: 'd-MMMM-y' : 'UTC' }}
          </strong>
          <ng-template #usaDate>
            <strong>
              {{ startNowAppData.userInfo.birthday | date: 'MM/dd/yyyy' : 'UTC' }}
            </strong>
          </ng-template>
        </div>
        <div class="ph-row px-md-2 pb-md-2" *ngIf="isUsaEnv">
          <span class="text-double-dot" i18n>Social security number</span>
          <strong data-private>{{ ssnMasking(startNowAppData.userInfo.ssnNumber) }}</strong>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 mt-md-5 mt-3">
      <hr class="d-md-none mb-0" />
      <div class="d-flex justify-content-between">
        <h5 class="font-weight-bold title my-3 my-md-2" i18n>Contact Information</h5>
        <div *ngIf="(stepProcessing$ | async) === false && (consultantDataCanNotBeModified$ | async) === false">
          <a
            class="d-md-block d-none text-uppercase font-weight-bold pointer my-2"
            (click)="navigateTo(StartNowAppStep.ConnectWithYou)"
            i18n
          >
            Change
          </a>
          <a class="d-md-none title my-2" (click)="navigateTo(StartNowAppStep.ConnectWithYou)">
            <i class="bi bi-pencil-square"></i>
          </a>
        </div>
      </div>
      <hr class="d-md-none mt-0" />
      <div class="card">
        <div class="ph-row p-md-2">
          <span class="text-double-dot" i18n>E-mail Address</span>
          <strong data-private>{{ startNowAppData.contactInfo.email }}</strong>
        </div>
        <div class="ph-row px-md-2 pb-md-2">
          <span class="text-double-dot" i18n>Cell Phone Number</span>
          <strong data-private>{{ startNowAppData.contactInfo.cellPhoneNumber }}</strong>
        </div>
        <div class="ph-row px-md-2 pb-md-2" *ngIf="!!startNowAppData.contactInfo.homeNumber">
          <span class="text-double-dot" i18n>Home Phone Number</span>
          <strong data-private>{{ startNowAppData.contactInfo.homeNumber }}</strong>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 mt-3">
      <app-address-card
        [title]="addressTitles[AddressType.Home]"
        [address]="selectAddress(startNowAppData.addressInfo.addresses, AddressType.Home)"
        [stepProcessing]="stepProcessing$ | async"
        [consultantDataCanNotBeModified]="consultantDataCanNotBeModified$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.WaitBusinessTools)"
        [countryStates]="countryStates$ | async"
      >
      </app-address-card>
    </div>

    <div
      class="col-12 col-md-6 mt-3"
      *ngIf="selectAddress(startNowAppData.addressInfo.addresses, AddressType.Billing) as billingAddress"
    >
      <app-address-card
        [title]="addressTitles[AddressType.Billing]"
        [address]="billingAddress"
        [stepProcessing]="stepProcessing$ | async"
        [consultantDataCanNotBeModified]="consultantDataCanNotBeModified$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.ConsultantAgreement)"
        [countryStates]="countryStates$ | async"
      ></app-address-card>
    </div>

    <div
      *ngIf="selectAddress(startNowAppData.addressInfo.addresses, AddressType.Shipping) as shippingAddress"
      class="col-12 col-md-6 mt-3"
    >
      <app-address-card
        [title]="addressTitles[AddressType.Shipping]"
        [address]="shippingAddress"
        [stepProcessing]="stepProcessing$ | async"
        [consultantDataCanNotBeModified]="consultantDataCanNotBeModified$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.WaitBusinessTools)"
        [countryStates]="countryStates$ | async"
      ></app-address-card>
    </div>

    <div class="col-12 col-md-6 mt-3">
      <hr class="d-md-none mb-0" />
      <div class="d-flex justify-content-between">
        <h5 class="font-weight-bold title my-3 my-md-2" i18n>Kit</h5>
        <div *ngIf="(stepProcessing$ | async) === false && (consultantDataCanNotBeModified$ | async) === false">
          <a
            class="d-md-block d-none text-uppercase font-weight-bold pointer my-2"
            (click)="navigateTo(StartNowAppStep.SelectStarterKit)"
            i18n
          >
            Change
          </a>
          <a class="d-md-none title my-2" (click)="navigateTo(StartNowAppStep.SelectStarterKit)">
            <i class="bi bi-pencil-square"></i>
          </a>
        </div>
      </div>
      <hr class="d-md-none mt-0" />
      <div class="card">
        <div class="ph-row p-md-2">
          <span class="text-double-dot" i18n>Kit Selection</span>
          <strong>
            {{ isMexEnv ? startNowAppData.selectedStarterKit.description1 : usaStarterKitName }}
          </strong>
        </div>
        <div *ngIf="isMexEnv; else usa">
          <div class="ph-row px-md-2 pb-md-2">
            <span class="text-double-dot" i18n>Total cost</span>
            <strong>{{ startNowAppData.selectedStarterKit.price | phCurrency }}</strong>
          </div>
          <div class="ph-row px-md-2 pb-md-2">
            <span i18n>Includes VAT and shipping.</span>
          </div>
        </div>
        <ng-template #usa>
          <div *ngIf="orderSummary$ | async as orderSummary">
            <div class="ph-row p-md-2">
              <span class="text-double-dot" i18n>Price</span>
              <strong>
                {{ orderSummary?.price | phCurrency }}
              </strong>
            </div>
            <div class="ph-row p-md-2">
              <span class="text-double-dot" i18n>State & local taxes</span>
              <strong>
                {{ orderSummary?.taxAmount | phCurrency }}
              </strong>
            </div>
            <div class="ph-row p-md-2">
              <span class="text-double-dot" i18n>Total cost</span>
              <strong>
                {{ orderSummary?.totalCost | phCurrency }}
              </strong>
            </div>
            <div
              *ngIf="
                !!orderSummary?.lessDeposit ||
                ((isZeroDepositAvailable$ | async) === true &&
                  (paymentStepSkipped$ | async) === true &&
                  orderSummary?.lessDeposit === 0)
              "
              class="ph-row p-md-2"
            >
              <span class="text-double-dot" i18n>Less deposit</span>
              <strong>
                {{ orderSummary?.lessDeposit | phCurrency }}
              </strong>
            </div>
            <div
              *ngIf="
                !!orderSummary?.lessDeposit ||
                ((isZeroDepositAvailable$ | async) === true &&
                  (paymentStepSkipped$ | async) === true &&
                  orderSummary?.lessDeposit === 0)
              "
              class="ph-row p-md-2"
            >
              <span class="text-double-dot" i18n>Balance due</span>
              <strong>
                {{ orderSummary?.balanceDue | phCurrency }}
              </strong>
            </div>
            <div
              *ngIf="
                !!orderSummary?.lessDeposit ||
                ((isZeroDepositAvailable$ | async) === true &&
                  (paymentStepSkipped$ | async) === true &&
                  orderSummary?.lessDeposit === 0)
              "
              class="ph-row p-md-2"
            >
              <span class="text-double-dot" i18n>You pay</span>
              <strong>
                {{ orderSummary?.lessDeposit | phCurrency }}
              </strong>
            </div>
          </div>
        </ng-template>
      </div>
    </div>

    <div *ngIf="startNowAppData.addressInfo.addresses.length === 3" class="col-12 col-md-6 mt-3">
      <app-password-card
        [password]="startNowAppData.userInfo.password"
        [stepProcessing]="stepProcessing$ | async"
        [consultantDataCanNotBeModified]="consultantDataCanNotBeModified$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.PasswordBackofficePortal)"
      ></app-password-card>
    </div>

    <div class="col-12 col-md-6 mt-3">
      <hr class="d-md-none mb-0" />
      <div class="d-flex justify-content-between">
        <h5 class="font-weight-bold title my-3 my-md-2" i18n>Recruiter</h5>
        <div
          *ngIf="
            !replicatedSiteConsultant &&
            (stepProcessing$ | async) === false &&
            (consultantDataCanNotBeModified$ | async) === false
          "
        >
          <a
            class="d-md-block d-none text-uppercase font-weight-bold pointer my-2"
            (click)="navigateTo(StartNowAppStep.ConfirmConsultant)"
            i18n
          >
            Change
          </a>
          <a class="d-md-none title my-2" (click)="navigateTo(StartNowAppStep.ConfirmConsultant)">
            <i class="bi bi-pencil-square"></i>
          </a>
        </div>
      </div>
      <hr class="d-md-none mt-0" />
      <div class="card" *ngIf="startNowAppData.selectedConsultant">
        <div class="ph-row p-md-2">
          <span class="text-double-dot" i18n>Your Recruiter</span>
          <strong>{{ startNowAppData.selectedConsultant.fullName }}</strong>
        </div>
        <div class="ph-row px-md-2 pb-md-2">
          <div class="form-check custom-checkbox">
            <input
              id="recruiter"
              type="checkbox"
              class="form-check-input custom-control-input"
              [(ngModel)]="confirmRecruiter"
              (ngModelChange)="confirmRecruiterChange($event)"
            />
            <label class="form-check-label text-uppercase mb-0 custom-control-label" for="recruiter" i18n>
              I confirm this recruiter is correct
            </label>
          </div>
        </div>
      </div>
      <span class="small-text" i18n>
        <span class="text-purple">Note:</span> If the recruiter listed above is not correct,
        <a
          (click)="navigateToStartNowApp()"
          role="button"
          class="font-weight-bold"
          [class]="{
            pointer: (stepProcessing$ | async) === false && (consultantDataCanNotBeModified$ | async) === false,
            disabled: (stepProcessing$ | async) || (consultantDataCanNotBeModified$ | async)
          }"
        >
          please click here to return to the home page.
        </a>
        You will need to complete the application under the correct recruiters website to continue.
      </span>
      <div *ngIf="!confirmRecruiter && isSubmitted">
        <span class="validation-error-message" i18n>
          Please confirm on your recruiter before finalizing your application!
        </span>
      </div>
    </div>

    <div class="col-12 col-md-6 mt-3">
      <app-password-card
        [password]="startNowAppData.userInfo.password"
        [stepProcessing]="stepProcessing$ | async"
        [consultantDataCanNotBeModified]="consultantDataCanNotBeModified$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.PasswordBackofficePortal)"
      ></app-password-card>
    </div>
  </div>
  <div class="col-12 mt-4">
    <span
      *ngIf="(isZeroDepositAvailable$ | async) === true && (paymentStepSkipped$ | async) === true"
      class="font-italic text-span"
    >
      <p class="pl-3 pr-4" i18n>
        *You selected the $0 deposit option for your Starter Kit. Once you reach $750 or more in personal sales during
        the first 60 days**, you’ll receive a Starter Kit with over $600 in products and business supplies – the full
        cost of $199*** will be automatically deducted from your profits.
      </p>
      <p class="pl-3 pr-5" i18n>
        If you do not reach $750 or more in sales during your first 60 days following submission of your application &
        agreement, you will continue to have access to Consultant’s Corner and your online store. However, you will
        forfeit the opportunity to purchase the Starter Kit for $199*** (which includes products and business supplies
        valued at over $600).
      </p>
    </span>
    <span
      *ngIf="(isZeroDepositAvailable$ | async) === true && (paymentStepSkipped$ | async) === true"
      class="font-italic text-span-no-background"
    >
      <p class="pl-3 pr-4" i18n>
        **All references to “sales” refer to Qualifying Volume (QV), which can come from any combination of party and/or
        eCommerce orders submitted with a deposit of 50% or more during Period 9, 2024 (August 10, 2024 – September 6,
        2024).
      </p>
      <p class="pl-3 pr-4" i18n>
        ***Plus tax, no delivery fee. There is no guarantee that a Consultant will earn any income or qualify for
        Princess House incentives and/or trips. In 2023, the average commissions paid to active Princess House
        Consultants was $1,103. And, on average, 1,089 hardworking Consultants achieve the Incentive Trip each year.
        Visit
        <a href="https://www.princesshouse.com/en-US/income-disclosure" target="_blank" class="tracking-url"
          >princesshouse.com/income-disclosure</a
        >
        for more information.
      </p>
    </span>
  </div>

  <app-sna-consultant-submit-component
    #snaConsultantSubmit
    [paymentHandler]="paymentHandler"
    [isPaymentProviderFirstLoading]="isLoading"
    (consultantSubmitted)="onConsultantSubmitted($event)"
  >
  </app-sna-consultant-submit-component>
</div>
