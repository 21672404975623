import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { LocalStorageService } from '@core/services/local-storage.service';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-party-initializer',
  template: '',
})
export class PartyInitializerComponent extends BaseComponent implements OnInit, OnDestroy {
  constructor(private router: Router, private localStorageService: LocalStorageService) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(filter((e) => e instanceof NavigationStart))
        .subscribe((e: NavigationStart) => {
          const partyId = this.router.parseUrl(e.url).queryParams?.[RouterQueryParams.partyId];

          if (partyId) {
            this.localStorageService.setItem(RouterQueryParams.partyId, partyId);
          }
        }),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
