import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { FooterLink } from './footer-state-models';

export enum FooterActionTypes {
  fetchPrimaryFooterLinks = '[Base Footer component] Fetch primary footer links',
  fetchSecondaryFooterLinks = '[Base Footer component] Fetch secondary footer links',
  fetchPrimaryFooterLinksSuccess = '[Footer] Fetch primary footer links success',
  fetchSecondaryFooterLinksSuccess = '[Footer] Fetch secondary footer links success',
  fetchFooterLinksError = '[Footer] Fetch Footer links Error',
}

export const fetchPrimaryFooterLinks = createAction(FooterActionTypes.fetchPrimaryFooterLinks);

export const fetchSecondaryFooterLinks = createAction(FooterActionTypes.fetchSecondaryFooterLinks);

export const fetchPrimaryFooterLinksSuccess = createAction(
  FooterActionTypes.fetchPrimaryFooterLinksSuccess,
  props<{ links: FooterLink[] }>(),
);

export const fetchSecondaryFooterLinksSuccess = createAction(
  FooterActionTypes.fetchSecondaryFooterLinksSuccess,
  props<{ links: FooterLink[] }>(),
);

export const fetchFooterLinksError = createAction(
  FooterActionTypes.fetchFooterLinksError,
  props<{ error: HttpErrorResponse }>(),
);
