import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BlogListSortType, defaultBlogListSortOption } from '@core/enums/blog-list-sort-type';
import { AppState } from '@core/store';
import {
  selectAllBlogsFetched,
  selectBlogCategories,
  selectBlogs,
  selectIsBlogCategoriesFetched,
} from '@core/store/blog';
import { BlogCategory, BlogItem } from '@core/store/blog/blog-state-models';
import { fetchBlogCategories, fetchBlogs, resetBlogs } from '@core/store/blog/blog.actions';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { filter, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-blog-list-page',
  templateUrl: './blog-list-page.component.html',
  styleUrls: ['./blog-list-page.component.scss'],
})
export class BlogListPageComponent implements OnInit, OnDestroy {
  blogs$: Observable<BlogItem[]>;
  blogCategories$: Observable<BlogCategory[]>;
  pageNumber: number = 0;
  pageSize: number = 12;
  fetchNextSubscription: Subscription;
  routeActivationSubscription: Subscription;
  scrollDown$ = new Subject<number>();
  categoryUrl: string;
  category: BlogCategory;
  sortType: BlogListSortType = defaultBlogListSortOption;
  isBlogCategoriesFetched$: Observable<boolean>;

  constructor(private store$: Store<AppState>, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.blogCategories$ = this.store$.select(selectBlogCategories);
    this.isBlogCategoriesFetched$ = this.store$.select(selectIsBlogCategoriesFetched);

    this.routeActivationSubscription = combineLatest([
      this.activatedRoute.paramMap,
      this.blogCategories$,
    ])
      .pipe(filter(([pMap, blogCategories]) => this.blogCategoryIsValid(pMap, blogCategories)))
      .subscribe(([pMap]) => {
        this.categoryUrl = this.getCategoryUrlParam(pMap);
        this.fetchFirstPage();
      });

    this.store$.dispatch(fetchBlogCategories());
    this.blogs$ = this.store$.select(selectBlogs);
    this.fetchNextPagesOnScrollDown();
  }

  ngOnDestroy(): void {
    this.fetchNextSubscription.unsubscribe();
    this.routeActivationSubscription.unsubscribe();
    this.store$.dispatch(resetBlogs());
  }

  onScrollDown(): void {
    this.scrollDown$.next(this.pageNumber);
  }

  sortChangeHandler(key: BlogListSortType): void {
    this.sortType = key;
    this.fetchFirstPage();
  }

  private fetchFirstPage() {
    this.pageNumber = 1;
    this.store$.dispatch(
      fetchBlogs({
        categoryUrl: this.categoryUrl,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        isFirstPage: true,
        sortType: this.sortType,
      }),
    );
    this.pageNumber++;
  }

  private fetchNextPagesOnScrollDown(): void {
    this.fetchNextSubscription = this.scrollDown$
      .pipe(
        withLatestFrom(this.store$.select(selectAllBlogsFetched)),
        filter(([, isAllFetched]) => !isAllFetched),
        tap(([pageNumber]) => {
          this.store$.dispatch(
            fetchBlogs({
              categoryUrl: this.categoryUrl,
              pageNumber: pageNumber,
              pageSize: this.pageSize,
              isFirstPage: false,
              sortType: this.sortType,
            }),
          );
          this.pageNumber++;
        }),
      )
      .subscribe();
  }

  private blogCategoryIsValid(pMap: ParamMap, blogCategories: BlogCategory[]) {
    return (
      blogCategories.length > 1 &&
      blogCategories.some((r) => r.urlName === this.getCategoryUrlParam(pMap))
    );
  }

  private getCategoryUrlParam(pMap: ParamMap) {
    return pMap.get('category') || '';
  }
}
