import { Component, Input, ViewChild } from '@angular/core';
import { OfflineMessages } from '@core/constants/start-now-app-offline-messages';
import { Country } from '@core/enums/country.enum';
import { PaymentType } from '@core/enums/payment-type.enum';
import { ModalConfig } from '@core/models/modal-config.model';
import { StartNowAppPaymentInfo } from '@core/store/start-now-app/start-now-app-state-models';
import { ModalComponent } from '@shared/components/modal/modal.component';

@Component({
  selector: 'app-start-now-app-offline-modal',
  templateUrl: './start-now-app-offline-modal.component.html',
  styleUrls: ['./start-now-app-offline-modal.component.scss'],
})
export class StartNowAppOfflineModalComponent {
  @Input() applicationRegistrationId: number;
  @Input() paymentInfo: StartNowAppPaymentInfo;
  @Input() country: String;

  message: string;
  offlineMessages = OfflineMessages;
  phoneNumber: string = '+1 609 123 4567';

  offlineModalConfig: ModalConfig = {
    hideCloseButton: true,
    hideDismissButton: true,
    hideHeader: true,
    ngbModalOptions: {
      size: 'md',
      modalDialogClass: 'modal',
      backdrop: 'static',
      keyboard: false,
    },
  };

  @ViewChild('offlineModal') private modalComponent: ModalComponent;

  constructor() {}

  open(): void {
    if (
      this.country == Country.Mexico &&
      this.paymentInfo.externalPaymentInfo != null &&
      this.paymentInfo.paymentType == PaymentType.CreditOrDebitCard &&
      this.paymentInfo.createdCardPaymentMethod !== null
    ) {
      this.message = OfflineMessages.AfterCardPayment;
    } else {
      this.message = OfflineMessages.DefaultMessage;
    }
    this.modalComponent.open();
  }

  close(): void {
    this.modalComponent.close();
  }
}
