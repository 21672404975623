import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ZipCodeMinimumLength } from '@core/constants/zip-code-minimum-length';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { CardholderName } from '@core/models/cardholder-name.model';
import { AppState } from '@core/store';
import { selectCountryStates } from '@core/store/address';
import { selectPersonalInfo } from '@core/store/checkout';
import { PersonalInfo } from '@core/store/checkout/checkout-state-models';
import { Store } from '@ngrx/store';
import { SelectOption } from '@shared/components/select/select.component';
import { TextFieldInputComponent } from '@shared/components/text-field-input/text-field-input.component';
import { countryStatesToSelectOptions } from '@shared/utils/address-utils';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Observable, Subscription, combineLatest, fromEvent } from 'rxjs';
import { filter, startWith, take } from 'rxjs/operators';

export const BillingAddressFormKeys = {
  sameAsShippingAddress: 'SameAsShippingAddress',
  firstName: 'firstName',
  lastName: 'lastName',
  address: 'address',
  addressLine1: 'addressLine1',
  addressLine2: 'addressLine2',
  addressLine3: 'addressLine3',
  city: 'city',
  state: 'state',
  zipCode: 'zipCode',
};

@Component({
  selector: 'app-billing-address-form',
  templateUrl: './billing-address-form.component.html',
  styleUrls: ['../../checkout-slide.shared.scss', './billing-address-form.component.scss'],
})
export class BillingAddressFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isCreditOrDebitCard: boolean = true;
  @Input() paymentProviderType: PaymentProviderType;
  @Output() reInitializeToken: EventEmitter<CardholderName> = new EventEmitter<CardholderName>();

  readonly BillingAddressFormKeys = BillingAddressFormKeys;

  isMexEnv = isMexEnv;
  billingAddressTexts = {
    title: $localize`Billing Address`,
    firstNameLabel: $localize`First Name`,
    lastNameLabel: $localize`Last Name`,
    cardTitle: $localize`Card Billing Address`,
    cardFirstNameLabel: $localize`Cardholder First Name`,
    cardLastNameLabel: $localize`Cardholder Last Name`,
  };
  form: FormGroup;
  countryStates$: Observable<SelectOption[]>;
  usePersonalInfoAsBillingInfo: boolean = false;

  private personalInfo: PersonalInfo;
  private subscriptions: Subscription = new Subscription();
  @ViewChild('firstNameInput') private firstNameInput: TextFieldInputComponent;
  @ViewChild('lastNameInput') private lastNameInput: TextFieldInputComponent;

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.createFormGroup();
    this.initPersonalInfo();
    this.countryStates$ = countryStatesToSelectOptions(this.store$.select(selectCountryStates));
  }

  ngAfterViewInit(): void {
    this.listenFormValidity();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleSameAsShippingFlag(same: boolean): void {
    this.usePersonalInfoAsBillingInfo = same;
    this.form.patchValue({
      [BillingAddressFormKeys.firstName]: same ? this.personalInfo.shippingAddress.firstName : '',
      [BillingAddressFormKeys.lastName]: same ? this.personalInfo.shippingAddress.lastName : '',
      [BillingAddressFormKeys.address]: {
        [BillingAddressFormKeys.addressLine1]: same
          ? this.personalInfo.shippingAddress.address.addressLine1
          : '',
        [BillingAddressFormKeys.addressLine2]: same
          ? this.personalInfo.shippingAddress.address.addressLine2
          : '',
        [BillingAddressFormKeys.city]: same ? this.personalInfo.shippingAddress.address.city : '',
        [BillingAddressFormKeys.state]: same ? this.personalInfo.shippingAddress.address.state : '',
        [BillingAddressFormKeys.zipCode]: same
          ? this.personalInfo.shippingAddress.address.zipCode
          : '',
      },
    });
  }

  private createFormGroup() {
    this.form = this.fb.group({
      [BillingAddressFormKeys.sameAsShippingAddress]: [false],
      [BillingAddressFormKeys.firstName]: ['', [Validators.required]],
      [BillingAddressFormKeys.lastName]: ['', [Validators.required]],
      [BillingAddressFormKeys.address]: this.fb.group({
        [BillingAddressFormKeys.addressLine1]: ['', [Validators.required]],
        [BillingAddressFormKeys.addressLine2]: [''],
        [BillingAddressFormKeys.city]: ['', [Validators.required]],
        [BillingAddressFormKeys.state]: ['', [Validators.required]],
        [BillingAddressFormKeys.zipCode]: [
          '',
          [Validators.required, Validators.minLength(ZipCodeMinimumLength)],
        ],
      }),
    });

    if (isMexEnv) {
      let addressLine3 = new FormControl('');
      (this.form.get(BillingAddressFormKeys.address) as FormGroup).addControl(
        BillingAddressFormKeys.addressLine3,
        addressLine3,
      );
    }
  }

  private initPersonalInfo(): void {
    this.subscriptions.add(
      this.store$
        .select(selectPersonalInfo)
        .pipe(take(1))
        .subscribe((personalInfo) => (this.personalInfo = personalInfo)),
    );
  }

  private listenFormValidity(): void {
    this.subscriptions.add(
      combineLatest([
        this.form.get(BillingAddressFormKeys.address).statusChanges,
        fromEvent(this.firstNameInput.input.nativeElement, 'blur').pipe(startWith({})),
        fromEvent(this.lastNameInput.input.nativeElement, 'blur').pipe(startWith({})),
      ])
        .pipe(
          filter(() => this.usePersonalInfoAsBillingInfo || this.form.valid),
          take(1),
        )
        .subscribe(() => {
          const firstName = this.usePersonalInfoAsBillingInfo
            ? this.personalInfo.billingCustomer.firstName
            : this.form.get(BillingAddressFormKeys.firstName).value;
          const lastName = this.usePersonalInfoAsBillingInfo
            ? this.personalInfo.billingCustomer.lastName
            : this.form.get(BillingAddressFormKeys.lastName).value;

          if (this.paymentProviderType !== PaymentProviderType.PayPal) {
            this.reInitializeToken.emit({
              firstName,
              lastName,
            });
          }
        }),
    );
  }
}
