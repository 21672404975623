import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MelissaErrorCode } from '@core/enums/melissa-error-code';
import { ModalConfig } from '@core/models/modal-config.model';
import { AddressVerification } from '@core/store/start-now-app/start-now-app-state-models';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SelectOption } from '@shared/components/select/select.component';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

// TODO: StartNowApp - this component is very similar to AddressValidationComponent
// the EC and SNA address validation response is different

@Component({
  selector: 'app-sna-address-validation-modal',
  templateUrl: './sna-address-validation-modal.component.html',
  styleUrls: ['./sna-address-validation-modal.component.scss'],
})
export class SnaAddressValidationModalComponent implements OnInit, OnDestroy {
  @Input() addressValidation$: Observable<AddressVerification>;
  @Input() countryStates: SelectOption[];
  @Input() isForbiddenState: boolean;
  @Input() finalizeAddress: (
    formGroup: FormGroup,
    isCorrectedAddress: boolean,
    isPostOfficeBox: boolean,
  ) => void;
  @Input() dismiss: () => void;

  readonly AddressLine1: string = 'address1';
  readonly AddressLine2: string = 'address2';
  readonly AddressLine3: string = 'address3';
  readonly City: string = 'city';
  readonly State: string = 'state';
  readonly ZipCode: string = 'zip';
  readonly isMexEnv = isMexEnv;
  readonly isCorrectedAddress = true;
  codes = MelissaErrorCode;
  originalAddressFormGroup: FormGroup;
  correctedAddressFormGroup: FormGroup;
  isOriginalAddress: boolean = true;
  isPostOfficeBox: boolean = true;
  validationCategoryAddresses: MelissaErrorCode[];
  validationCategoryLocations: MelissaErrorCode[];

  constructor(private fb: FormBuilder) {}

  modalConfig: ModalConfig;
  private modalSubscription: Subscription;

  @ViewChild('modal') private modalComponent: ModalComponent;

  ngOnInit(): void {
    this.modalConfig = {
      title: $localize`Address Verification`,
      ngbModalOptions: {
        size: 'lg',
      },
      hideCloseButton: true,
      hideDismissButton: true,
      onDismiss: this.cancel?.bind(this),
    };

    this.modalSubscription = this.addressValidation$
      ?.pipe(filter((data) => !!data && !data.isValid && !!data.correctedAddress))
      .subscribe((data) => {
        this.validationCategoryAddresses = data.validationCategoryAddresses;
        this.validationCategoryLocations = data.validationCategoryLocations;
        this.isPostOfficeBox = data.isPostOfficeBox;
        this.modalComponent.open();

        this.originalAddressFormGroup = this.fb.group({
          [this.AddressLine1]: [data.originalAddress.address1, [this.addressValidationFn()]],
          [this.AddressLine2]: [data.originalAddress.address2, [this.addressValidationFn()]],
          [this.City]: [data.originalAddress.city, [this.cityValidationFn()]],
          [this.State]: [data.originalAddress.state, [this.stateValidationFn()]],
          [this.ZipCode]: [data.originalAddress.zip, [this.zipValidationFn()]],
        });

        const correctedAddressLine2 = data.correctedAddress.address2
          ? data.correctedAddress.address2
          : '';

        this.correctedAddressFormGroup = this.fb.group({
          [this.AddressLine1]: [data.correctedAddress.address1.trim()],
          [this.AddressLine2]: [correctedAddressLine2.trim()],
          [this.City]: [data.correctedAddress.city],
          [this.State]: [data.correctedAddress.state],
          [this.ZipCode]: [data.correctedAddress.zip],
        });

        if (isMexEnv) {
          const originalAddressLine3Control = new FormControl(data.originalAddress.address3, [
            this.addressValidationFn(),
          ]);
          const correctedAddressLine3Control = new FormControl(
            data.correctedAddress.address3 ? data.correctedAddress.address3 : '',
          );
          this.originalAddressFormGroup.addControl(this.AddressLine3, originalAddressLine3Control);
          this.correctedAddressFormGroup.addControl(
            this.AddressLine3,
            correctedAddressLine3Control,
          );
        }

        setTimeout(() => this.originalAddressFormGroup.markAllAsTouched());
      });
  }

  ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }

  cancel(): void {
    this.originalAddressFormGroup.markAllAsTouched();
    this.originalAddressFormGroup.updateValueAndValidity();
    this.dismiss();
    this.modalComponent.close();
  }

  continue(): void {
    if (this.isOriginalAddress) {
      this.finalizeAddress(
        this.originalAddressFormGroup,
        !this.isCorrectedAddress,
        this.isPostOfficeBox,
      );
    } else {
      this.finalizeAddress(
        this.correctedAddressFormGroup,
        this.isCorrectedAddress,
        this.isPostOfficeBox,
      );
    }
    this.modalComponent.close();
  }

  public hasZipError(): boolean {
    return this.hasLocationErrorCode(this.codes.AE01) || this.hasLocationErrorCode(this.codes.AC01);
  }

  public hasStateError(): boolean {
    return this.hasLocationErrorCode(this.codes.AE01) || this.hasLocationErrorCode(this.codes.AC02);
  }

  public hasCityError(): boolean {
    return (
      this.hasLocationErrorCode(this.codes.AE01) ||
      this.hasLocationErrorCode(this.codes.AC03) ||
      this.hasLocationErrorCode(this.codes.AC09)
    );
  }

  public hasAddressError(): boolean {
    return (
      this.validationCategoryAddresses != undefined && this.validationCategoryAddresses.length > 0
    );
  }

  public hasAddressErrorCode = (code: MelissaErrorCode): boolean =>
    this.validationCategoryAddresses?.includes(code) ?? false;

  public hasLocationErrorCode(code: MelissaErrorCode): boolean {
    return this.validationCategoryLocations?.some((error) => error.startsWith(code)) ?? false;
  }

  private addressValidationFn(): ValidatorFn {
    return (): ValidationErrors => (this.hasAddressError() ? { melissaAddress: true } : null);
  }

  private zipValidationFn(): ValidatorFn {
    return (): ValidationErrors => (this.hasZipError() ? { melissaZip: true } : null);
  }

  private stateValidationFn(): ValidatorFn {
    return (): ValidationErrors => (this.hasStateError() ? { melissaState: true } : null);
  }

  private cityValidationFn(): ValidatorFn {
    return (): ValidationErrors => (this.hasCityError() ? { melissaCity: true } : null);
  }
}
