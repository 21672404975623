import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { MobileMedia } from '@core/constants/screen-sizes';
import { AppState } from '@core/store';
import { selectIsOrderCreditToHostParty, selectParty } from '@core/store/consultant';
import { Party } from '@core/store/consultant/consultant-state-models';
import {
  resetParty,
  selectIsOrderCreditToHostParty as selectIsOrderCreditToHostPartyAction,
  selectParty as selectPartyAction,
} from '@core/store/consultant/consultant.actions';
import { Store, select } from '@ngrx/store';
import { getFormValidationErrorMessage, validInput } from '@shared/utils/validation.utils';
import { dropdownRequiredValidator } from '@shared/validators/dropdown-required.validator';
import { Observable, Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'app-party-box',
  templateUrl: './party-box.component.html',
  styleUrls: ['./party-box.component.scss'],
})
export class PartyBoxComponent implements OnInit, OnDestroy {
  @Input() consultantParties: Party[];

  readonly DefaultDropdownText = $localize`Select party`;
  readonly PartyFormKeys = {
    isOrderCreditToHostParty: 'isOrderCreditToHostParty',
    specificParty: 'specificParty',
  };
  readonly validInput = validInput;
  readonly DropdownErrorMessage = FormErrorMessages.dropdownRequired;

  isMobile$: Observable<boolean>;
  selectedParty$: Observable<Party>;
  isOrderCreditToHostParty$: Observable<boolean>;
  showSpecificPartyDropdown: boolean = false;
  partyFormGroup: FormGroup;
  getValidationError = getFormValidationErrorMessage;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private breakpointObserver: BreakpointObserver,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.partyFormGroup = this.createFormGroup();

    this.isMobile$ = this.breakpointObserver.observe(MobileMedia).pipe(select((o) => o.matches));
    this.selectedParty$ = this.store$.select(selectParty);
    this.isOrderCreditToHostParty$ = this.store$.select(selectIsOrderCreditToHostParty);

    this.subscribePrepopulateForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  partyClickHandler(party: Party) {
    this.store$.dispatch(selectPartyAction({ party }));
    this.setSpecificPartyDropdownValue(party.partyName);
  }

  yesClick() {
    this.store$.dispatch(
      selectIsOrderCreditToHostPartyAction({
        isOrderCreditToHostParty: true,
      }),
    );
    this.store$.dispatch(resetParty());

    this.setSpecificPartyDropdownValue(null);
    this.setSpecificPartyDropdownValidator(true);
    this.partyFormGroup.markAllAsTouched();
  }

  noClick() {
    this.store$.dispatch(
      selectIsOrderCreditToHostPartyAction({
        isOrderCreditToHostParty: false,
      }),
    );
    this.store$.dispatch(resetParty());

    // previously could be stored by changing the radio buttons
    this.setSpecificPartyDropdownValue(null);
    this.setSpecificPartyDropdownValidator(false);
    this.partyFormGroup.markAllAsTouched();
  }

  private createFormGroup(): FormGroup {
    const form = this.fb.group({
      [this.PartyFormKeys.isOrderCreditToHostParty]: [''],
      [this.PartyFormKeys.specificParty]: [''],
    });

    if (this.consultantParties.length) {
      form.get(this.PartyFormKeys.isOrderCreditToHostParty).setValidators(Validators.required);
    }

    return form;
  }

  private subscribePrepopulateForm(): void {
    this.subscriptions.add(
      combineLatest([this.isOrderCreditToHostParty$, this.selectedParty$]).subscribe(
        ([isOrderCreditToHostParty, selectedParty]) => {
          isOrderCreditToHostParty = selectedParty ? !!selectedParty : isOrderCreditToHostParty;
          this.showSpecificPartyDropdown = !!isOrderCreditToHostParty;
          this.populateForm(isOrderCreditToHostParty, selectedParty);
        },
      ),
    );
  }

  private populateForm(isOrderCreditToHostParty: boolean, selectedParty: Party): void {
    this.partyFormGroup.patchValue({
      [this.PartyFormKeys.isOrderCreditToHostParty]: isOrderCreditToHostParty,
    });

    if (isOrderCreditToHostParty) {
      this.partyFormGroup.markAllAsTouched();
      this.setSpecificPartyDropdownValue(selectedParty?.partyName);
    }
  }

  private setSpecificPartyDropdownValidator(isOrderCreditToHostParty: boolean) {
    const specificPartyFormControl = this.partyFormGroup.get(this.PartyFormKeys.specificParty);
    if (isOrderCreditToHostParty) {
      specificPartyFormControl?.setValidators(dropdownRequiredValidator());
    } else {
      specificPartyFormControl?.clearValidators();
    }

    specificPartyFormControl?.updateValueAndValidity();
  }

  private setSpecificPartyDropdownValue(partyName: string) {
    this.partyFormGroup.get([this.PartyFormKeys.specificParty]).setValue(partyName);
  }
}
