import { AfterViewInit, Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalConfig } from '@core/models/modal-config.model';
import { AppState } from '@core/store';
import {
  selectAssignedConsultant,
  selectCurrentConsultant,
  selectIsAssignedConsultantFetched,
  selectIsCurrentConsultantFetched,
} from '@core/store/consultant';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import {
  consultantCheckingInProgress,
  consultantCheckingIsDone,
  fetchAssignedConsultant,
  fetchCurrentConsultantByVanityName,
} from '@core/store/consultant/consultant.actions';
import { selectUser } from '@core/store/user';
import { updateAssignedConsultant } from '@core/store/user/user.actions';
import { Store } from '@ngrx/store';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-consultant-checker',
  templateUrl: './consultant-checker.component.html',
  styleUrls: ['./consultant-checker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsultantCheckerComponent implements AfterViewInit, OnDestroy {
  modalConfig: ModalConfig = {
    title: $localize`Confirm consultant connection`,
    hideCloseButton: true,
    hideDismissButton: true,
    onDismiss: this.consultantCheckingDone.bind(this),
    ngbModalOptions: {
      size: 'lg',
      modalDialogClass: 'checkerModal',
    },
  };

  currentConsultant$: Observable<Consultant>;
  assignedConsultant$: Observable<Consultant>;
  isCurrentConsultantFetched$: Observable<boolean>;
  isAssignedConsultantFetched$: Observable<boolean>;

  private subscriptions: Subscription = new Subscription();

  @ViewChild('checkerModal') private checkerModal: ModalComponent;
  @ViewChild('confirmModal') private confirmModal: ConfirmModalComponent;

  constructor(private store$: Store<AppState>) {}

  ngAfterViewInit(): void {
    this.currentConsultant$ = this.store$.select(selectCurrentConsultant);
    this.isCurrentConsultantFetched$ = this.store$.select(selectIsCurrentConsultantFetched);
    this.assignedConsultant$ = this.store$.select(selectAssignedConsultant);
    this.isAssignedConsultantFetched$ = this.store$.select(selectIsAssignedConsultantFetched);
    this.subscriptions.add(
      combineLatest([this.store$.select(selectUser), this.currentConsultant$])
        .pipe(
          distinctUntilChanged(
            ([prevUser, prevConsultant], [currUser, currConsultant]) =>
              prevUser?.activeConsultantProwessId === currUser?.activeConsultantProwessId &&
              prevConsultant?.beeNumber === currConsultant?.beeNumber,
          ),
          filter(([user]) => !!user?.activeConsultantProwessId),
          filter(([user, consultant]) => user.activeConsultantProwessId !== consultant?.beeNumber),
        )
        .subscribe(() => {
          this.store$.dispatch(consultantCheckingInProgress());
          this.store$.dispatch(fetchAssignedConsultant());
        }),
    );
    this.listenFetchedCurrentAndAssignedConsultant();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  fetchConsultant(vanityName: string) {
    this.store$.dispatch(fetchCurrentConsultantByVanityName({ vanityName, filterCountry: true }));
    this.checkerModal.close();
  }

  close() {
    this.checkerModal.close();
    this.consultantCheckingDone();
  }

  openConfirmDialog(beeNumber: number) {
    this.confirmModal.open(beeNumber);
    this.checkerModal.close();
  }

  onConfirm(consultantProwessId: number) {
    this.store$.dispatch(updateAssignedConsultant({ consultantProwessId }));
    this.consultantCheckingDone();
  }

  onCancel() {
    this.checkerModal.open();
  }

  private consultantCheckingDone() {
    this.store$.dispatch(consultantCheckingIsDone());
  }

  private listenFetchedCurrentAndAssignedConsultant(): void {
    this.subscriptions.add(
      combineLatest([this.isCurrentConsultantFetched$, this.isAssignedConsultantFetched$])
        .pipe(
          filter(
            ([isCurrentConsultantFetched, isAssignedConsultantFetched]) =>
              isCurrentConsultantFetched && isAssignedConsultantFetched,
          ),
          withLatestFrom(this.currentConsultant$, this.assignedConsultant$),
          filter(
            ([, currentConsultant, assignedConsultant]) =>
              currentConsultant?.beeNumber !== assignedConsultant?.beeNumber,
          ),
        )
        .subscribe(([, currentConsultant, assignedConsultant]) => {
          if (!!currentConsultant && !!assignedConsultant) {
            this.checkerModal.open();
          } else if (!!currentConsultant && !assignedConsultant) {
            this.store$.dispatch(
              updateAssignedConsultant({ consultantProwessId: currentConsultant.beeNumber }),
            );
          } else if (!currentConsultant && !!assignedConsultant) {
            this.store$.dispatch(
              fetchCurrentConsultantByVanityName({
                vanityName: assignedConsultant.vanityName,
                filterCountry: true,
              }),
            );
          }
        }),
    );
  }
}
