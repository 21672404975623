import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SearchState } from './search-state-models';

export const searchFeatureKey = 'searchState';

export const selectSearchStateFeature = createFeatureSelector<SearchState>(searchFeatureKey);

export const selectSearchState = createSelector(selectSearchStateFeature, (state: SearchState) =>
  state ? state : null,
);

export const selectSearchLoading = createSelector(selectSearchStateFeature, (state: SearchState) =>
  state ? state.loading : false,
);

export const selectSearchFacets = createSelector(selectSearchStateFeature, (state: SearchState) =>
  state ? state.facets : [],
);

export const selectSearchDocumentsCount = createSelector(
  selectSearchStateFeature,
  (state: SearchState) => (state ? state.documents.length : 0),
);
