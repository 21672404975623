<div class="desktop">
  <div class="head py-1 text-uppercase font-weight-bold" i18n>Name</div>
  <div class="head py-1 text-uppercase font-weight-bold" i18n>Sku</div>
  <div class="head py-1 text-uppercase font-weight-bold" i18n>Quantity</div>
  <div class="head py-1"></div>
  <ng-container *ngFor="let backOrderItem of backOrderItems$ | async">
    <div class="cell py-1 pl-1">{{ backOrderItem.name }}</div>
    <div class="cell py-1 d-flex align-items-center">#{{ backOrderItem.skuCode }}</div>
    <div class="cell py-1 d-flex align-items-center">{{ backOrderItem.quantity }}</div>
    <div class="cell"></div>
  </ng-container>
</div>
<hr class="w-100 mt-1 d-md-block d-none" />

<div class="mobile">
  <ng-container *ngFor="let backOrderItem of backOrderItems$ | async">
    <div class="item">
      <div class="pt-2 pl-2 title">{{ backOrderItem.name }}</div>
      <div class="pt-2 pl-2">#{{ backOrderItem.skuCode }}</div>
      <div class="py-2 pl-2">
        <span class="qty"> {{ backOrderItem.quantity }}X </span>
      </div>
    </div>
  </ng-container>
</div>
