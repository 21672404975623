import produce from 'immer';
import { ProductState } from './product-state-models';
import { ProductActionTypes } from './product.actions';

export const initialState: ProductState = {
  catalogName: null,
  products: {
    loading: false,
    items: [],
    isAllFetched: false,
  },
  catalogs: {
    items: [],
    isAllFetched: false,
    loading: false,
  },
  productDetails: {
    isLoading: false,
    item: null,
    recommendations: [],
    relatedRecipes: [],
    moreFromCollection: [],
  },
  modals: {
    productQuickView: null,
  },
  customerSpecialOffersTypes: {
    ecommerce: {
      items: [],
      loading: false,
    },
    healthySavings: {
      items: [],
      loading: false,
    },
    collectAndSave: {
      items: [],
      loading: false,
    },
    regularCustomerSpecial: {
      items: [],
      loading: false,
    },
  },
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case ProductActionTypes.fetchProducts:
      draft.products.loading = true;
      return;

    case ProductActionTypes.fetchProductsSuccess:
      draft.products.items = [...draft.products.items, ...action.payload];
      draft.catalogName = action.catalogName;
      draft.products.isAllFetched = action.payload.length < action.pageSize;
      draft.products.loading = false;

      return draft;

    case ProductActionTypes.fetchProductsFailure:
      draft.products.loading = false;
      return;

    case ProductActionTypes.resetProducts:
      draft.products = initialState.products;
      draft.catalogName = initialState.catalogName;
      return draft;

    case ProductActionTypes.fetchCatalogs:
      draft.catalogs.loading = true;
      if (action.pageNumber === 1) {
        draft.catalogs.items = initialState.catalogs.items;
      }
      return;

    case ProductActionTypes.fetchCatalogsSuccess:
      draft.catalogs.items = [...draft.catalogs.items, ...action.payload];
      draft.catalogs.isAllFetched = action.payload.length < action.pageSize;
      draft.catalogs.loading = false;
      return draft;

    case ProductActionTypes.fetchCatalogsFailure:
      draft.catalogs.loading = false;
      return;

    case ProductActionTypes.resetCatalogs:
      draft.catalogs = initialState.catalogs;
      return draft;

    case ProductActionTypes.fetchProductDetails:
      draft.productDetails.isLoading = true;
      return draft;

    case ProductActionTypes.fetchProductDetailsSuccess:
      draft.productDetails = {
        item: action.product,
        isLoading: false,
        recommendations: [],
        relatedRecipes: [],
        moreFromCollection: [],
      };
      return draft;

    case ProductActionTypes.fetchProductDetailsFailure:
      draft.productDetails.isLoading = false;
      return draft;

    case ProductActionTypes.resetProductDetails:
      draft.productDetails = initialState.productDetails;
      return draft;

    case ProductActionTypes.fetchProductQuickView:
      draft.modals.productQuickView = null;
      return draft;

    case ProductActionTypes.fetchProductQuickViewSuccess:
      draft.modals.productQuickView = action.product;
      return draft;

    case ProductActionTypes.resetProductQuickView:
      draft.modals.productQuickView = initialState.modals.productQuickView;
      return draft;

    case ProductActionTypes.fetchProductRecommendationsSuccess:
      draft.productDetails.recommendations = action.recommendations;
      return draft;

    case ProductActionTypes.fetchRelatedRecipesSuccess:
      draft.productDetails.relatedRecipes = action.recipes;
      return draft;

    case ProductActionTypes.fetchMoreFromCollectionSuccess:
      draft.productDetails.moreFromCollection = action.moreFromCollection;
      return draft;

    case ProductActionTypes.fetchEcommerceCustomerSpecialOffers:
      draft.customerSpecialOffersTypes.ecommerce.loading = true;
      return;

    case ProductActionTypes.fetchEcommerceCustomerSpecialOffersSuccess:
      draft.customerSpecialOffersTypes.ecommerce.items = action.payload;
      draft.customerSpecialOffersTypes.ecommerce.loading = false;
      return draft;

    case ProductActionTypes.fetchEcommerceCustomerSpecialOffersFailure:
      draft.customerSpecialOffersTypes.ecommerce.loading = false;
      return;

    case ProductActionTypes.fetchHealthySavingsCustomerSpecialOffers:
      draft.customerSpecialOffersTypes.healthySavings.loading = true;
      return;

    case ProductActionTypes.fetchHealthySavingsCustomerSpecialOffersSuccess:
      draft.customerSpecialOffersTypes.healthySavings.items = action.payload;
      draft.customerSpecialOffersTypes.healthySavings.loading = false;
      return draft;

    case ProductActionTypes.fetchHealthySavingsCustomerSpecialOffersFailure:
      draft.customerSpecialOffersTypes.healthySavings.loading = false;
      return;

    case ProductActionTypes.fetchCollectAndSaveCustomerSpecialOffers:
      draft.customerSpecialOffersTypes.collectAndSave.loading = true;
      return;

    case ProductActionTypes.fetchCollectAndSaveCustomerSpecialOffersSuccess:
      draft.customerSpecialOffersTypes.collectAndSave.items = action.payload;
      draft.customerSpecialOffersTypes.collectAndSave.loading = false;
      return draft;

    case ProductActionTypes.fetchCollectAndSaveCustomerSpecialOffersFailure:
      draft.customerSpecialOffersTypes.collectAndSave.loading = false;
      return;

    case ProductActionTypes.fetchRegularCustomerSpecialOffers:
      draft.customerSpecialOffersTypes.regularCustomerSpecial.loading = true;
      return;

    case ProductActionTypes.fetchRegularCustomerSpecialOffersSuccess:
      draft.customerSpecialOffersTypes.regularCustomerSpecial.items = action.payload;
      draft.customerSpecialOffersTypes.regularCustomerSpecial.loading = false;
      return draft;

    case ProductActionTypes.fetchRegularCustomerSpecialOffersFailure:
      draft.customerSpecialOffersTypes.regularCustomerSpecial.loading = false;
      return;

    case ProductActionTypes.fetchProductVideoDetailsSuccess:
      const i = draft.productDetails?.item?.videos.findIndex(
        (video) => video.id === action.vimeoId,
      );

      if (draft.productDetails.item.videos[i]) {
        draft.productDetails.item.videos[i].thumbnail = action.thumbnail;
      }
      return;

    case ProductActionTypes.fetchProductQuickViewVideoDetailsSuccess:
      const j = draft.modals.productQuickView?.videos.findIndex(
        (video) => video.id === action.vimeoId,
      );
      draft.modals.productQuickView.videos[j].thumbnail = action.thumbnail;
      return;

    default:
      return draft;
  }
}, initialState);
