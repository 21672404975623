<form [formGroup]="addressFormGroup" class="checkout-slide">
  <div class="mt-4"></div>
  <div class="row">
    <div class="col">
      <div class="title border-bottom-0">
        <span class="mt-4">{{ title }}</span>
        <div *ngIf="isAuthenticated">
          <a class="link-highlight font-weight-bold pointer" i18n (click)="selectAddress()">
            My saved addresses
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-2" *ngIf="showCheckbox">
    <div class="col">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="newsletters"
          (change)="toggleShippingName.emit($event.target.checked)"
        />
        <label i18n class="form-check-label" for="newsletters">
          Shipping address name is the same as personal info name entered above.
        </label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <app-text-field-input
          [identifier]="FirstName + id"
          label="First Name"
          i18n-label
          placeholder="Your first name goes here"
          i18n-placeholder
          [formControlName]="FirstName"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <app-text-field-input
          [identifier]="LastName + id"
          label="Last Name"
          i18n-label
          placeholder="Your last name goes here"
          i18n-placeholder
          [formControlName]="LastName"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
      </div>
    </div>
  </div>
  <div class="row" [formGroupName]="Address">
    <div class="col-md-6">
      <div class="form-group">
        <app-text-field-input
          [identifier]="AddressLine1 + id"
          label="Address Line 1"
          i18n-label
          placeholder="Address line 1 goes here"
          i18n-placeholder
          [formControlName]="AddressLine1"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <app-text-field-input
          [identifier]="AddressLine2 + id"
          label="Address Line 2"
          i18n-label
          placeholder="Address line 2 goes here"
          i18n-placeholder
          [formControlName]="AddressLine2"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6" [formGroupName]="Address">
      <div class="form-group" *ngIf="isMexEnv">
        <app-text-field-input
          [identifier]="AddressLine3 + id"
          label="Address Line 3"
          i18n-label
          placeholder="Address Line 3 goes here"
          i18n-placeholder
          [formControlName]="AddressLine3"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
      </div>
      <div class="form-group">
        <app-text-field-input
          [identifier]="City + id"
          label="City"
          i18n-label
          placeholder="City goes here"
          i18n-placeholder
          [formControlName]="City"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
      </div>
      <div class="form-group">
        <app-select
          [identifier]="State + id"
          label="State"
          i18n-label
          [formControlName]="State"
          [options]="countryStates$ | async"
        ></app-select>
      </div>
      <div class="form-group w-25">
        <app-text-field-input
          [identifier]="ZipCode + id"
          label="ZIP Code"
          i18n-label
          placeholder="ZIP code goes here"
          i18n-placeholder
          [formControlName]="ZipCode"
        >
        </app-text-field-input>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <app-text-field-input
          [identifier]="PhoneNumber + id"
          label="Phone Number"
          i18n-label
          placeholder="Phone number goes here"
          i18n-placeholder
          [formControlName]="PhoneNumber"
          type="tel"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>
    </div>
  </div>
</form>
