export enum PageCategory {
  home = 'home',
  hostSpecials = 'hostess-special-offers',
  about = 'about',
  startYourBusiness = 'start-your-business',
  host = 'host',
  customerOffersTop = 'customer-special-offers-top',
  customerOffersBottom = 'customer-special-offers-bottom',
  healthySavings = 'customer-special-offers-healthy-savings',
  collectAndSave = 'customer-special-offers-collect-and-save',
  regularCustomerSpecialOffers = 'regular-customer-special-offers',
  event = 'event',
  eventStaging1 = 'event-staging-1',
  eventStaging2 = 'event-staging-2',
}
