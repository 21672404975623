<app-page-title
  *ngIf="recipeDetailsItem$ | async as recipeDetailsItem"
  [title]="[titlePrefix, recipeDetailsItem.name]"
  delimiter=" "
  [description]="recipeDetailsItem.description"
></app-page-title>
<div class="d-flex justify-content-center m-3 m-md-5">
  <ng-container *ngIf="loading$ | async; else content">
    <app-ph-spinner></app-ph-spinner>
  </ng-container>
</div>

<ng-template #content>
  <div class="container" *ngIf="recipeDetailsItem$ | async as recipe">
    <div class="row">
      <div class="col-12 d-md-none">
        <img
          class="w-100 mb-3"
          phImage
          [images]="recipe.images"
          [thumbnailDesktop]="thumbnail.recipeMainDesktop"
          [thumbnailMobile]="thumbnail.recipeMainMobile"
          [altText]="recipe.name"
        />
        <div class="d-flex justify-content-center mb-3">
          <app-share-buttons></app-share-buttons>
        </div>
      </div>
      <div class="col-12 col-md-5">
        <h5 class="font-weight-bold">
          <span>{{ recipe.name }}</span>
          <button
            *ngIf="isAuthenticated$ | async"
            class="float-right pointer btn-wishlist"
            [ngClass]="{
              bi: true,
              'bi-heart': !isLiked,
              'bi-heart-fill': isLiked
            }"
            (click)="toggleLike()"
            aria-label="Add to wishlist"
            i18n-aria-label
          ></button
        ></h5>
        <div>
          <app-romance-content-wrapper
            [html]="formatHtmlIngredients(recipe.ingredients)"
          ></app-romance-content-wrapper>
          <app-romance-content-wrapper
            [html]="formatHtmlInstructions(recipe.instructions)"
            class="d-md-none"
          ></app-romance-content-wrapper>
        </div>
      </div>
      <div class="col-md-7 pl-4 d-none d-md-block">
        <img
          class="w-100 mb-3"
          phImage
          [images]="recipe.images"
          [thumbnailDesktop]="thumbnail.recipeMainDesktop"
          [thumbnailMobile]="thumbnail.recipeMainMobile"
          [altText]="recipe.name"
        />
        <div class="d-flex justify-content-center">
          <app-share-buttons></app-share-buttons>
        </div>
        <app-romance-content-wrapper
          [html]="formatHtmlIngredients(recipe.instructions)"
        ></app-romance-content-wrapper>
      </div>
    </div>
    <div class="row" *ngIf="(youMightAlsoLikeSlides$ | async).length > 0">
      <hr class="w-100" />
      <div class="col-12">
        <app-related-carousel
          i18n-title
          title="You Might also Like"
          [slides$]="youMightAlsoLikeSlides$"
        ></app-related-carousel>
      </div>
    </div>
    <ng-container *ngIf="feature.product">
      <div class="row" *ngIf="(relatedProductsSlides$ | async).length > 0">
        <hr class="w-100" />
        <div class="col-12">
          <app-related-carousel
            i18n-title
            title="Related Products"
            [slides$]="relatedProductsSlides$"
          ></app-related-carousel>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
