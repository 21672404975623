<app-confirm-modal
  #notificationModal
  i18n-title
  title="Consultant has Open Parties"
  (yesClick)="onYes()"
  i18n-noLabel
  i18n-yesLabel
  noLabel="No"
  yesLabel="Yes, show me the open parties"
>
  <div class="text-center" i18n>
    Do you want to shop in one of the open parties so that the host gets credit for the order?
  </div>
</app-confirm-modal>
