import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@core/store';
import { selectConsultantState } from '@core/store/consultant';
import { selectConsultantFinderStep } from '@core/store/start-now-app';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sna-query-param-checker',
  template: '',
})
export class SnaQueryParamCheckerComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(private router: Router, private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.snaQueryParamCheckerEcNotFoundConsultant();
    this.snaQueryParamCheckerSnaNotFoundConsultant();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private snaQueryParamCheckerEcNotFoundConsultant(): void {
    this.subscriptions.add(
      this.store$
        .select(selectConsultantState)
        .pipe(
          filter((consultantState) => consultantState?.isCurrentConsultantFetched),
          filter((consultantState) => !consultantState.currentConsultant),
        )
        .subscribe(() => {
          this.removeQueryParam();
        }),
    );
  }

  private snaQueryParamCheckerSnaNotFoundConsultant(): void {
    this.subscriptions.add(
      this.store$
        .select(selectConsultantFinderStep)
        .pipe(
          filter((finder) => finder?.isConsultantsFetched),
          filter((finder) => !finder.findIsSuccess),
        )
        .subscribe(() => {
          this.removeQueryParam();
        }),
    );
  }

  private removeQueryParam(): void {
    this.router.navigate([], {
      queryParams: { cid: null, lnid: null },
      queryParamsHandling: 'merge',
    });
  }
}
