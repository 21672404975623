<div class="container mt-2">
  <div class="row">
    <div
      class="col h-100"
      *ngFor="let tile of passwordBarLevels; let i = index"
      [ngStyle]="{
        'background-color': currentLevel?.level > i ? currentLevel?.activeColor : 'silver'
      }"
    >
    </div>
  </div>
</div>
<div *ngIf="currentLevel" [style.color]="currentLevel?.activeColor" class="text-right">{{
  currentLevel?.category
}}</div>
