/* eslint-disable max-len */
import { ProductType } from '@core/enums/product-type.enum';

export function getProductTypeMessage(price) {
  return {
    [ProductType.ItemNoLongerAvailable]: $localize`Item no longer available.`,
    [ProductType.ActiveInCatalog]: $localize``,
    [ProductType.CatalogItemOutOfStock]: $localize`Available for Party orders only. Not available for eCommerce orders. Contact your Consultant for details.`,
    [ProductType.HostSpecialInCatalog]: $localize`Limited Time Offer for Hosts - ${price}`,
    [ProductType.HostSpecialOutOfCatalog]: $localize`Limited Time Offer for Hosts - ${price}`,
    [ProductType.CustomerSpecial]: $localize`Limited Time Offer!`,
    [ProductType.RegularCustomerSpecial]: $localize`Limited Time Offer!`,
    [ProductType.HealthySavingsSpecial]: $localize`Limited Time Offer!`,
    [ProductType.SurpriseBuy]: $localize`Limited Time Offer!`,
    [ProductType.ECommerceCustomerSpecial]: $localize`Limited Time Offer!`,
    [ProductType.ThreeBooking]: $localize`One of our Three Booking Gifts – Ask your Consultant for details.`,
    [ProductType.BuyOneGetOne50]: $localize``,
    [ProductType.CollectAndSave]: $localize`Limited Time Offer!`,
    [ProductType.HostBookSpec]: $localize`One of our Three Booking Gifts – Ask your Consultant for details.`,
    [ProductType.ComingSoon]: $localize`Coming soon`,
  };
}
