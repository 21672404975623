import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IS_USA_ENV } from '@shared/utils/environment-utils';

@Injectable({
  providedIn: 'root',
})
export class UsaCountryGuardService implements CanActivate {
  constructor(private router: Router, @Inject(IS_USA_ENV) private isUsaEnv: boolean) {}

  canActivate(): boolean {
    if (!this.isUsaEnv) {
      this.router.navigateByUrl('/');
    }
    return this.isUsaEnv;
  }
}
