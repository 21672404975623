import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidEmailRegExp } from '@core/constants/patterns';
import { Locale } from '@core/enums/locale';
import { AppState } from '@core/store';
import { selectUser, selectUserLoading } from '@core/store/user';
import { UserModel } from '@core/store/user/user-state-models';
import { fetchUser, updateUser } from '@core/store/user/user.actions';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { emailValidator } from '@shared/utils/email-validator-utils';
import { getLanguage } from '@shared/utils/locale-utils';
import { B2C_SIGNIN_TARGET_ROUTE } from 'app/auth-config';
import { Observable, Subscription } from 'rxjs';

enum profileFormKey {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
  newsletters = 'newsletters',
  shareWithConsultant = 'shareWithConsultant',
}

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  readonly profileFormKey = profileFormKey;
  readonly OldPasswordRequired = 'oldPasswordRequired';

  user$: Observable<any>;
  disableSave$: Observable<boolean>;
  profileForm: FormGroup;
  userSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
    @Inject(LOCALE_ID) private localeId: Locale,
  ) {}

  ngOnInit(): void {
    this.profileForm = this.fb.group({
      [profileFormKey.firstName]: ['', Validators.required],
      [profileFormKey.lastName]: ['', Validators.required],
      [profileFormKey.email]: ['', [Validators.required, emailValidator(ValidEmailRegExp)]],
      [profileFormKey.phoneNumber]: '',
      [profileFormKey.newsletters]: false,
      [profileFormKey.shareWithConsultant]: true,
    });

    this.store$.dispatch(fetchUser());
    this.user$ = this.store$.select(selectUser);
    this.disableSave$ = this.store$.select(selectUserLoading);
    this.userSubscription = this.user$.subscribe((user) => {
      this.updateForm(user);
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  onCancel = (): void => {
    this.store$.dispatch(fetchUser());
  };

  onSave = (): void => {
    if (this.profileForm.valid) {
      this.store$.dispatch(updateUser({ user: this.profileForm.value }));
    }
  };

  changePassword(): void {
    const email = this.profileForm.get(profileFormKey.email).value;
    const redirectUri = `${window.location.origin}/${B2C_SIGNIN_TARGET_ROUTE}`;

    const passwordChangeUrl = `https://${environment.b2c.domain}/${
      environment.b2c.domain
    }/oauth2/v2.0/authorize?p=B2C_1A_PROFILEEDITPASSWORDCHANGE&client_id=${
      environment.b2c.clientId
      // eslint-disable-next-line max-len
    }&nonce=defaultNonce&redirect_uri=${redirectUri}&scope=openid&response_type=id_token&prompt=login&ui_locales=${getLanguage(
      this.localeId,
    )}&prefill_email=${email}`;

    if (email) {
      window.location.href = passwordChangeUrl;
    }
  }

  updateForm = (user: UserModel): void => {
    this.profileForm.patchValue(user);
  };

  validInput = (controlName: string): boolean => {
    const formControl = this.profileForm.get(controlName);
    if (!formControl.touched) {
      return true;
    }
    return formControl.valid;
  };
}
