/* eslint-disable max-len */
import { PromotionMessage } from '@core/enums/promotion-message.enum';

export const PromotionMessageLocale = {
  [PromotionMessage.RegularCustomerSpecialWithoutFullPrice]: $localize`There are one or more Customer Special item(s) in your shopping cart. To take advantage of the special price, add a regular-price item to your cart. Note: you may purchase up to TWO Customer Specials with each regular-price purchase.`,
  [PromotionMessage.FullPriceWithoutRegularCustomerSpecial]: $localize`Don’t miss your chance to save! You’re eligible to purchase up to TWO Customer Specials with each regular-price purchase. Click the button below to view and take advantage of our current Specials.`,
  [PromotionMessage.NonQualifyingPurchaseCustomerSpecial]: $localize`Be sure to check out our current Specials to purchase select items on sale with NO REGULAR price PURCHASE REQUIRED.`,
  [PromotionMessage.BuyOneGetOne50]: $localize`For a limited time only, YOU get to decide what’s on sale – buy any regular-priced catalog item and get another item of equal or lesser value half-off! Add another regular-priced item to your cart to take advantage of the savings.`,
  [PromotionMessage.SurpriseBuyProgram]: $localize`For a limited time only, with every $40 purchase (excluding tax and delivery), you can purchase a Surprise Buy for $11*! Surprise Buy items are retired or soon-to-be retired products with a retail value of $24.95 or more, and randomly selected to ship with the Party order. *Tax and delivery fee is calculated as part of the tax and delivery fee of the entire order.`,
  [PromotionMessage.NonQualifyingPurchaseCollectAndSave]: $localize`Be sure to check out our current Specials to purchase select items on sale with NO REGULAR price PURCHASE REQUIRED.`,
};
