import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FooterBackgroundShade } from '@core/enums/footer-bg-shade.enum';
import { AppState } from '@core/store';
import { selectHasCurrentConsultant } from '@core/store/consultant';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer-my-story',
  templateUrl: './footer-my-story.component.html',
  styleUrls: ['../footer.common.scss', './footer-my-story.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterMyStoryComponent implements OnInit {
  @Input() shade: FooterBackgroundShade;
  hasCurrentConsultant$: Observable<Boolean>;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.hasCurrentConsultant$ = this.store$.select(selectHasCurrentConsultant);
  }
}
