<div class="container pt-5" *ngIf="actualConsultantList$ | async as actualConsultantList">
  <div class="text-center" *ngIf="!actualConsultantList.length; else consultantListContent">
    <span i18n>Consultant sync failed please retry or connect admin</span>
  </div>
  <ng-template #consultantListContent>
    <h1 class="text-title text-center mt-md-4">{{ titles?.mainTitle }}</h1>
    <h6 class="text-subtitle text-center mt-3 mt-md-5 mb-5">
      {{ titles?.subTitle }}
    </h6>
    <ng-container *ngFor="let consultant of actualConsultantList">
      <app-consultant-box
        [consultant]="consultant"
        [boxHasButton]="actualConsultantList.length > 1"
        [isSelected]="
          actualConsultantList.length > 1 ? consultant.id === selectedConsultant?.id : true
        "
        (selectedConsultant)="changeSelectedConsultant($event)"
      ></app-consultant-box>
    </ng-container>

    <div
      *ngIf="(consultantStepsSkipped$ | async) === false && hasMoreConsultant$ | async"
      class="row justify-content-center mt-5"
    >
      <div class="text-center col col-md-3">
        <div class="white-button-container">
          <button
            class="btn btn-outline-primary text-uppercase w-100"
            [disabled]="stepProcessing$ | async"
            (click)="showMoreConsultants()"
          >
            <span i18n>More consultants</span>
            <div
              class="spinner-border spinner-border-sm"
              *ngIf="stepProcessing$ | async"
              role="status"
            >
              <span class="sr-only" i18n>Loading...</span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="actualConsultantList.length === 1" [formGroup]="recruiterConfirmationFormGroup">
      <div class="row mt-5">
        <div class="col d-flex justify-content-center">
          <div class="confirm">
            <div class="mb-3 mb-md-1 form-check custom-checkbox">
              <input
                class="form-check-input custom-control-input"
                type="checkbox"
                [formControlName]="Confirmation"
                id="confirmation"
              />
              <label
                i18n
                class="form-check-label custom-control-label text-uppercase"
                for="confirmation"
              >
                I confirm this recruiter is correct
              </label>
            </div>
            <div class="text-md-justify note" i18n>
              Note: If the recruiter listed above is not correct, please
              <a (click)="navigateToStartNowApp()" role="button" class="link">click here</a> to
              return to the home page. You will need to complete the application under the correct
              recruiters website to continue.
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!confirmValidInput" class="validation-error-message text-center">
        <span>{{ getValidationError(Confirmation) }}</span>
      </div>
    </div>

    <div
      *ngIf="actualConsultantList.length > 1 && onNextClicked && !isConsultantSelected()"
      class="validation-error-message text-center"
    >
      <span>{{ titles.errorTitle.required }}</span>
    </div>
  </ng-template>

  <div class="row justify-content-center my-5 pt-5">
    <div class="col col-md-3">
      <div class="white-button-container">
        <button
          class="btn btn-outline-primary text-uppercase w-100 no-button"
          i18n
          (click)="previousStepClick()"
          [disabled]="isGoToPreviousDisabled"
        >
          Back
        </button>
      </div>
    </div>
    <div class="col col-md-3" *ngIf="!!(actualConsultantList$ | async).length">
      <button
        class="btn btn-primary text-uppercase w-100"
        i18n
        (click)="nextStepClick(actualConsultantList.length)"
      >
        Next
      </button>
    </div>
  </div>
</div>
