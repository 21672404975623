import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { PaymentType } from '@core/enums/payment-type.enum';
import { ModalConfig } from '@core/models/modal-config.model';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { PaymentTypeChangedModalResult } from './payment-type-changed-modal-result.model';

@Component({
  selector: 'app-payment-type-changed-modal',
  templateUrl: './payment-type-changed-modal.component.html',
  styleUrls: ['./payment-type-changed-modal.component.scss'],
})
export class PaymentTypeChangedModalComponent extends BaseComponent {
  @Output() changePaymentType: EventEmitter<PaymentTypeChangedModalResult> =
    new EventEmitter<PaymentTypeChangedModalResult>();
  public readonly PaymentType = PaymentType;

  public readonly ModalConfig: ModalConfig = {
    hideCloseButton: true,
    hideDismissButton: true,
    hideHeader: true,
    ngbModalOptions: {
      backdrop: 'static',
      keyboard: false,
    },
  };

  previousPaymentType: PaymentType;
  currentPaymentType: PaymentType;

  @ViewChild('modal') private modalComponent: ModalComponent;

  constructor() {
    super();
  }

  async open(previousPaymentType: PaymentType, currentPaymentType: PaymentType) {
    this.previousPaymentType = previousPaymentType;
    this.currentPaymentType = currentPaymentType;
    return await this.modalComponent.open();
  }

  continue(): void {
    this.changePaymentType.emit({
      previousPaymentType: this.previousPaymentType,
      currentPaymentType: this.currentPaymentType,
      isPaymentTypeChangeAccepted: true,
    });
    this.modalComponent.close();
  }

  cancel(): void {
    this.changePaymentType.emit({
      previousPaymentType: this.previousPaymentType,
      currentPaymentType: this.currentPaymentType,
      isPaymentTypeChangeAccepted: false,
    });
    this.modalComponent.close();
  }
}
