export const SearchResultApiPageSize = 50;
export const SearchResultPageSize = 50;

export const LocalFacetNames = {
  'recipe/categories': $localize`recipe/categories`,
  'blog/categories': $localize`blog/categories`,
  'product/categories': $localize`product/categories`,
  'product/collections': $localize`product/collections`,
};

export const LocalFacetTypes = {
  'recipe/categories': $localize`Recipes`,
  'blog/categories': $localize`Blogs`,
  'product/categories': $localize`Products`,
  'product/collections': $localize`Products`,
};
