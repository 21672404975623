import { Component, Input } from '@angular/core';

export interface CartonItem {
  skuCode: string;
  skuDescription: string;
  quantity: number;
}

@Component({
  selector: 'app-carton-table',
  templateUrl: './carton-table.component.html',
  styleUrls: ['./carton-table.component.scss'],
})
export class CartonTableComponent {
  @Input() heading: string = '';
  @Input() cartonItems: CartonItem[];

  constructor() {}
}
