<ng-container>
  <i *ngIf="isSelected" class="bi bi-check-circle-fill check-selected selected"></i>
  <div
    class="p-1 pointer"
    (click)="onClick(this.starterKit)"
    [ngClass]="{
      'border-selected': isSelected,
      border: !isSelected
    }"
  >
    <div
      [ngClass]="{
        'content-selected': isSelected,
        content: !isSelected
      }"
    >
      <img class="w-100" src="{{ starterKit.imageUrl }}" />
      <div class="row">
        <div class="col-12">
          <div class="mx-2 mt-2">
            <h4 class="catalog-name">{{ starterKit.description1 }}</h4>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="mx-2 mb-2">
            <span>{{ starterKit.description2 }}</span>
          </div>
        </div>
      </div>
    </div>
    <h6
      *ngIf="isMexEnv"
      class="font-weight-bold text-center text-uppercase mt-2"
      [ngClass]="{ 'selected-text-color': isSelected }"
    >
      {{ isSelected ? Selected : Select }}
    </h6>
  </div>
  <div class="d-flex justify-content-between">
    <a
      class="text-primary text-uppercase mt-1 w-40 font-weight-bold pointer"
      i18n
      target="_blank"
      [href]="starterKit.pdfUrl | safeUrl"
    >
      Starter kit details
    </a>
    <span *ngIf="!isMexEnv" class="font-italic" i18n>*Plus tax</span>
  </div>
</ng-container>
