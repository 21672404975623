import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RecipeListPageComponent } from './components/recipe-list-page/recipe-list-page.component';
import { RecipeMoreRoutingModule } from './recipe-more-routing.module';
import { RecipeListItemComponent } from './components/recipe-list-page/recipe-list-item/recipe-list-item.component';
import { RecipeImageComponent } from './components/recipe-list-page/recipe-list-item/recipe-image/recipe-image.component';
import { RecipeListHeaderComponent } from './components/recipe-list-page/recipe-list-header/recipe-list-header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { RecipeDetailsComponent } from './components/recipe-details/recipe-details.component';
import { BlogListPageComponent } from './components/blog-list-page/blog-list-page.component';
import { BlogListHeaderComponent } from './components/blog-list-page/blog-list-header/blog-list-header.component';
import { BlogListItemComponent } from './components/blog-list-page/blog-list-item/blog-list-item.component';
import { BlogImageComponent } from './components/blog-list-page/blog-list-item/blog-image/blog-image.component';
import { RecipeMoreCatalogPageComponent } from './components/recipe-more-catalog-page/recipe-more-catalog-page.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';

@NgModule({
  declarations: [
    RecipeListPageComponent,
    RecipeListItemComponent,
    RecipeImageComponent,
    RecipeListHeaderComponent,
    RecipeDetailsComponent,
    BlogListPageComponent,
    BlogListItemComponent,
    BlogImageComponent,
    BlogListHeaderComponent,
    RecipeMoreCatalogPageComponent,
    BlogDetailsComponent,
  ],
  imports: [CommonModule, InfiniteScrollModule, RecipeMoreRoutingModule, NgbModule, SharedModule],
  exports: [RecipeListItemComponent, BlogListItemComponent],
})
export class RecipeMoreModule {}
