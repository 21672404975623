import { Component, Input, ViewChild } from '@angular/core';
import { MaintenanceErrorMessage } from '@core/constants/sna-error-messages';
import { ModalConfig } from '@core/models/modal-config.model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { StatusCodes } from 'http-status-codes';

@Component({
  selector: 'app-consultant-submit-failed-modal',
  templateUrl: './consultant-submit-failed-modal.component.html',
  styleUrls: ['./consultant-submit-failed-modal.component.scss'],
})
export class ConsultantSubmitFailedModalComponent {
  @Input() statusCode: StatusCodes;

  readonly StatusCodes = StatusCodes;
  readonly MaintenanceErrorMessage = MaintenanceErrorMessage;

  errorMessages: string[] = [];
  modalConfig: ModalConfig = {
    hideDismissButton: true,
    hideCloseButton: true,
    hideHeader: true,
    ngbModalOptions: {
      size: 'md',
    },
  };

  @ViewChild('modal') private modal: ModalComponent;

  constructor() {}

  open(errorMessages: string[]): void {
    this.errorMessages = errorMessages;
    this.modal.open();
  }

  close(): void {
    this.errorMessages = [];
    this.modal.close();
  }
}
