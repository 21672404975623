import { Component, Input, OnInit } from '@angular/core';
import { TableSortDirectionCycle } from '@core/constants/table-sort-direction-cycle';
import { OrderStatus } from '@core/enums/shipment-status-enum';
import { TableSortDirection } from '@core/enums/table-sort-direction.enum';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { OrderLineDetails } from '@core/store/order/order-state-models';
import { compare } from '../../common/order-sort-common';

@Component({
  selector: 'app-order-details-table',
  templateUrl: './order-details-table.component.html',
  styleUrls: ['./order-details-table.component.scss'],
})
export class OrderDetailsTableComponent implements OnInit {
  @Input() orderLines: OrderLineDetails[];

  sortableOrderLines: OrderLineDetails[];
  tableSortDirection = TableSortDirection;
  shippingStatus = OrderStatus;
  orderLineColumns = [
    { title: $localize`Product`, type: 'productName' },
    { title: $localize`Qty`, type: 'quantity' },
    { title: $localize`Price`, type: 'discountedPrice' },
    { title: $localize`Total`, type: 'total' },
  ];

  sortColumn: string | null;
  direction: TableSortDirection;
  thumbnail = Thumbnail;

  constructor() {
    this.direction = TableSortDirection.none;
    this.sortColumn = null;
  }

  ngOnInit(): void {
    this.sortableOrderLines = this.orderLines;
  }

  onSort(column: string): void {
    if (column !== this.sortColumn) {
      this.direction = TableSortDirection.none;
    }
    this.direction = TableSortDirectionCycle[this.direction];
    this.sortColumn = column;

    if (this.direction === TableSortDirection.none) {
      this.sortableOrderLines = this.orderLines;
    } else {
      this.sort(column, this.direction);
    }
  }

  private sort(column: string, direction: TableSortDirection) {
    this.sortableOrderLines = this.sortableOrderLines.slice().sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === TableSortDirection.asc ? res : -res;
    });
  }
}
