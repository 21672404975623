import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DefaultVideoThumbnailPlaceholderSrc } from '@core/constants/image-placeholders';
import { MobileMedia } from '@core/constants/screen-sizes';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { ImageDetails } from '@core/models/image.model';
import { VimeoVideoDetails } from '@core/models/vimeo-video-details';
import { select } from '@ngrx/store';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

enum MediaType {
  image = 'image',
  video = 'video',
}

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss'],
})
export class ProductDetailsMediaComponent implements OnInit, OnDestroy {
  @Input() images$: Observable<ImageDetails[][]>;
  @Input() size: 'sm' | 'lg' = 'lg';
  @Input() videos$: Observable<VimeoVideoDetails[]>;

  @ViewChild('sliderRef') set sliderRef(element: ElementRef<HTMLElement>) {
    this.initSlider(element);
  }

  readonly DefaultVideoThumbnailPlaceholderSrc = DefaultVideoThumbnailPlaceholderSrc;
  readonly MediaType = MediaType;
  readonly VideoSize = {
    sm: { width: 300, height: 250 },
    lg: { width: 664, height: 400 },
  };

  isMobile$: Observable<boolean>;
  selectedMedia: ImageDetails[] | VimeoVideoDetails;
  selectedMediaType: MediaType;
  slider: KeenSliderInstance = null;
  currentSlide: number = 0;
  dotHelper: Array<Number> = [];
  desktopPerView = 5;

  thumbnail = Thumbnail;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.isMobile$ = this.breakpointObserver.observe(MobileMedia).pipe(select((o) => o.matches));
    this.images$.pipe(take(1)).subscribe((s) => {
      this.selectedMedia = s[0];
      this.selectedMediaType = MediaType.image;
    });
  }

  ngOnDestroy(): void {
    this.slider?.destroy();
  }

  showDots(): boolean {
    return (
      this.dotHelper.length > this.slider?.options.slides['perView'] &&
      this.slider?.options.slides['perView'] === 1
    );
  }

  showArrows(): boolean {
    return this.slider?.options.slides['perView'] === this.desktopPerView;
  }

  selectMedia(media: ImageDetails[] | VimeoVideoDetails, type: MediaType): void {
    this.selectedMedia = media;
    this.selectedMediaType = type;
  }

  getVideoUrl(id: string): string {
    // eslint-disable-next-line max-len
    return `https://player.vimeo.com/video/${id}?h=2db2743e8c&color=B093B4&autoplay=0&title=0&byline=0&portrait=0`;
  }

  private initSlider(sliderRef) {
    this.slider = new KeenSlider(sliderRef.nativeElement, {
      initial: this.currentSlide,
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel;
      },
      breakpoints: {
        '(min-width: 768px)': {
          slides: { perView: this.desktopPerView, spacing: 5 },
          vertical: true,
        },
      },
      slides: { perView: 1 },
      vertical: false,
    });
    this.dotHelper = [...Array(this.slider.slides.length).keys()];
  }
}
