<ng-container *ngIf="!!failedOrderItem">
  <div class="row my-2">
    <div class="col-8 col-md-6">
      <div class="row">
        <div class="col">
          <img
            phImage
            [images]="failedOrderItem.images"
            [thumbnailDesktop]="thumbnail.productCheckoutDesktop"
            [thumbnailMobile]="thumbnail.productCheckoutMobile"
            class="img-thumbnail"
          />
        </div>
        <div class="col d-flex flex-column align-self-center">
          <div class="category-name">{{ failedOrderItem.collectionName }}</div>
          <div class="product-name font-size-bigger">
            {{ failedOrderItem.productName }}
          </div>
          <div class="sku">#{{ failedOrderItem.sku }}</div>
        </div>
      </div>
    </div>
    <div class="col-md-3 d-none d-md-flex flex-column justify-content-center" *ngIf="isUpdatedItem">
      <p
        *ngIf="
          failedOrderItem.discountedPrice === failedOrderItem.originalPrice;
          else discountPrice
        "
        class="font-size-bigger"
      >
        {{ failedOrderItem.originalPrice | phCurrency }}
      </p>
      <ng-template #discountPrice>
        <p class="font-size-bigger">{{ failedOrderItem.discountedPrice | phCurrency }}</p>
      </ng-template>
    </div>
    <div
      class="col-md-3 d-none d-md-block font-size-bigger align-self-center"
      *ngIf="isUpdatedItem"
    >
      <p>{{ failedOrderItem.actualPrice | phCurrency }}</p>
    </div>

    <div class="col d-block d-md-none" *ngIf="isUpdatedItem">
      <strong i18n>Previous price</strong>
      <p class="font-size-bigger">{{
        (failedOrderItem.discountedPrice === failedOrderItem.originalPrice
          ? failedOrderItem.discountedPrice
          : failedOrderItem.originalPrice
        ) | phCurrency
      }}</p>
      <strong i18n>Actual price</strong>
      <p class="font-size-bigger">{{ failedOrderItem.actualPrice }}</p>
    </div>
  </div>
</ng-container>
