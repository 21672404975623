<div class="container">
  <div class="row">
    <div id="navBarContainer" class="col-md-3 col-12" [ngClass]="{ hide: navBarActive }">
      <h3 class="nav-title" i18n>My Account</h3>
      <div class="dropdown-divider mx-3"></div>
      <ul id="navBar" ngbNav class="nav-pills" orientation="vertical" [(activeId)]="activeId">
        <li [ngbNavItem]="UserRoute.Profile">
          <a ngbNavLink [routerLink]="UserRoute.Profile" i18n>My Profile</a>
          <div class="dropdown-divider mx-3"></div>
        </li>
        <li [ngbNavItem]="UserRoute.Orders">
          <a ngbNavLink [routerLink]="UserRoute.Orders" i18n>My Orders</a>
          <div class="dropdown-divider mx-3"></div>
        </li>
        <li [ngbNavItem]="UserRoute.Wishlist">
          <a ngbNavLink [routerLink]="UserRoute.Wishlist" i18n>My Wishlist</a>
          <div class="dropdown-divider mx-3"></div>
        </li>
        <li [ngbNavItem]="UserRoute.Addresses">
          <a ngbNavLink [routerLink]="UserRoute.Addresses" i18n>My Shipping Addresses</a>
          <div class="dropdown-divider mx-3"></div>
        </li>
        <li *ngIf="isUsaEnv" [ngbNavItem]="UserRoute.PaymentMethods">
          <a ngbNavLink [routerLink]="UserRoute.PaymentMethods" i18n>My Payment Methods</a>
          <div class="dropdown-divider mx-3"></div>
        </li>
        <li [ngbNavItem]="UserRoute.Recipes">
          <a ngbNavLink [routerLink]="UserRoute.Recipes" i18n>My Recipes</a>
          <div class="dropdown-divider mx-3"></div>
        </li>
        <li [ngbNavItem]="UserRoute.Returns">
          <a ngbNavLink [routerLink]="UserRoute.Returns" i18n>My Returns</a>
          <div class="dropdown-divider mx-3"></div>
        </li>
        <li ngbNavItem="logout">
          <a ngbNavLink (click)="logout()" i18n>Logout</a>
          <div class="dropdown-divider mx-3"></div>
        </li>
      </ul>
    </div>

    <div class="col-md-9 col-12" id="router-outlet">
      <router-outlet
        (activate)="activate()"
        (deactivate)="deactivate()"
        [ngClass]="{ hide: !navBarActive }"
      ></router-outlet>
    </div>
  </div>
</div>
