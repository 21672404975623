<p i18n>Need to add simple one sentence instruction on what to do on this return product screen.</p>
<app-ph-spinner *ngIf="loading$ | async; else content"></app-ph-spinner>
<ng-template #content>
  <app-order-information [(orderInfo)]="orderInfo" [readOnly]="false"></app-order-information>
  <div class="w-100">
    <app-return-order-lines
      [orderLines]="orderLines"
      (orderLinesChange)="orderLinesChange.emit($event)"
    ></app-return-order-lines>
  </div>

  <div class="row mt-3">
    <div class="col-12 col-md-6">
      <app-return-step-navigation-buttons
        (negativeButtonClick)="activeStepChange.emit(activeStep - 1)"
        (positiveButtonClick)="next()"
        [positiveButtonDisabled]="!isProductSelectionValid()"
      ></app-return-step-navigation-buttons>
    </div>
  </div>
</ng-template>
