<!-- Mobile Design -->
<div class="d-md-none">
  <ng-container *ngFor="let orderItem of sortableCcOrderItems">
    <div class="row order-card p-2">
      <div class="col">
        <div class="row card-text">
          <div class="col">
            <span class="card-text-productname product-name">
              {{ orderItem.productName | titlecase }}
            </span>
            <div class="font-weight-bold">#{{ orderItem.skuCode }}</div>
          </div>
        </div>
        <div class="row mt-2 mb-3">
          <div class="col">
            <span class="qty mr-2">{{ orderItem.quantity }}X</span>
            <ng-container>
              <span class="price">{{ orderItem.price | phCurrency }}</span>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="col"> {{ orderItem.totalPrice | phCurrency }} </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- Desktop Design -->
<div class="d-none d-md-flex">
  <div class="d-flex justify-content-center align-items-center order-content-row">
    <table class="table">
      <thead>
        <tr>
          <th
            *ngFor="let column of OrderItemColumns"
            scope="col"
            class="text-uppercase"
            (click)="onSort(column.type)"
            role="button"
          >
            <span>{{ column.title }}</span>
            <i
              *ngIf="sortColumn === column.type"
              [ngClass]="{
                bi: true,
                'bi-caret-up-fill': direction === tableSortDirection.asc,
                'bi-caret-down-fill': direction === tableSortDirection.desc
              }"
            ></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let orderItem of sortableCcOrderItems">
          <td class="w-auto">
            <div class="row">
              <div class="col py-2 pl-0 align-self-center">
                <span class="font-weight-bold product-name">
                  {{ orderItem.productName | titlecase }}
                </span>
                <div class="product text-secondary font-weight-bold">#{{ orderItem.skuCode }}</div>
              </div>
            </div>
          </td>
          <td class="w-7">
            <div>
              <p>{{ orderItem.quantity }}</p>
            </div>
          </td>
          <td class="w-auto">
            <div>
              <p>{{ orderItem.price | phCurrency }}</p>
            </div>
          </td>
          <td class="w-auto">
            <div>
              <p>{{ orderItem.totalPrice | phCurrency }}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
