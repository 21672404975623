import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalConfig } from '@core/models/modal-config.model';
import { AppState } from '@core/store';
import { selectCountryStates } from '@core/store/address';
import { fetchCountryStates } from '@core/store/address/address.actions';
import { OrderAddress } from '@core/store/order/order-state-models';
import { Store } from '@ngrx/store';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SelectOption } from '@shared/components/select/select.component';
import { countryStatesToSelectOptions } from '@shared/utils/address-utils';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-address-form-modal',
  templateUrl: './address-form-modal.component.html',
  styleUrls: ['./address-form-modal.component.scss'],
})
export class AddressFormModalComponent implements OnInit {
  @Input() address: OrderAddress;
  @Output() addressChange: EventEmitter<OrderAddress> = new EventEmitter<OrderAddress>();

  readonly FirstName: string = 'firstName';
  readonly LastName: string = 'lastName';
  readonly AddressLine1: string = 'addressLine1';
  readonly AddressLine2: string = 'addressLine2';
  readonly AddressLine3: string = 'addressLine3';
  readonly City: string = 'city';
  readonly State: string = 'state';
  readonly ZipCode: string = 'zipCode';
  readonly isMexEnv = isMexEnv;

  countryStates$: Observable<SelectOption[]>;
  addressFormGroup: FormGroup;
  modalConfig: ModalConfig = {
    title: $localize`Change address`,
    hideCloseButton: true,
    hideDismissButton: true,
    onDismiss: this.cancel.bind(this),
    ngbModalOptions: {
      size: 'md',
    },
  };

  @ViewChild('modal') private modal: ModalComponent;

  constructor(
    private store$: Store<AppState>,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.store$.dispatch(fetchCountryStates());
    this.countryStates$ = countryStatesToSelectOptions(this.store$.select(selectCountryStates));
    this.createFormGroup();
  }

  openModal(): void {
    this.addressFormGroup.patchValue(this.address);
    this.modal.open();
  }

  apply(): void {
    this.addressChange.emit(this.addressFormGroup.value);
    this.modal.close();
  }

  cancel(): void {
    this.modal.close();
  }

  private createFormGroup(): void {
    this.addressFormGroup = this.fb.group({
      [this.FirstName]: ['', Validators.required],
      [this.LastName]: ['', Validators.required],
      [this.AddressLine1]: ['', Validators.required],
      [this.AddressLine2]: [''],
      [this.City]: ['', Validators.required],
      [this.State]: ['', Validators.required],
      [this.ZipCode]: ['', Validators.required],
    });

    if (isMexEnv) {
      const addressLine3Control = new FormControl('');
      this.addressFormGroup.addControl(this.AddressLine3, addressLine3Control);
    }
  }
}
