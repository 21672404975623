import { formatCurrency, getCurrencySymbol } from '@angular/common';
import {
  AfterViewInit,
  Component,
  DEFAULT_CURRENCY_CODE,
  ElementRef,
  Host,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { Currency } from '@core/enums/currency.enum';
import { environment } from '@env';
import { getValidationErrorMessageList, validInput } from '@shared/utils/validation.utils';

@Component({
  selector: 'app-currency-field-input',
  templateUrl: './currency-field-input.component.html',
  styleUrls: ['./currency-field-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CurrencyFieldInputComponent,
      multi: true,
    },
  ],
})
// eslint-disable-next-line max-len
export class CurrencyFieldInputComponent implements OnInit, OnChanges, ControlValueAccessor, AfterViewInit
{
  @Input()
  identifier: string;

  @Input()
  label: string;

  @Input()
  placeholder: string;

  @Input()
  formErrorMessages: { [key: string]: string } = FormErrorMessages;

  @Input()
  formControlName: string;

  @Input()
  readonly: boolean = false;

  @Input()
  labelStyle: any = {};

  @Input()
  inputStyle: any = {};

  @Input()
  isLabelOnInput: boolean = false;

  @Input()
  isDecimal: boolean = true;

  @Input()
  value: number;

  @Input()
  disabled = false;

  @ViewChild('input') input: ElementRef;

  readonly Type: string = 'text';
  readonly DecimalDigitsInfo: string = '0.2-2';
  readonly IntegerDigitsInfo: string = '0.0-0';

  control: AbstractControl;

  public displayValue = '';

  public currency: Currency;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) private currencyCode,
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  ngOnInit(): void {
    this.currency = environment.currency;
  }

  ngAfterViewInit(): void {
    this.control = this.controlContainer?.control.get(this.formControlName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value?.currentValue) {
      this.displayValue = changes.value.currentValue
        ? this.convertToCurrencyFormat(this.convertToNumber(String(changes.value.currentValue)))
        : '';
    }
  }

  onChanged: (value: number) => void = () => {};

  onTouched = () => {};

  writeValue(value): void {
    this.value = value;

    this.displayValue = value
      ? this.convertToCurrencyFormat(this.convertToNumber(String(value)))
      : '';
  }

  registerOnChange(onChanged: any): void {
    this.onChanged = onChanged;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onKeyUp(event: any) {
    this.value = this.convertToNumber(event.target.value);
    this.onChanged(this.value);
    this.onTouched();
  }

  onBlur(event: any) {
    this.value = this.convertToNumber(event.target.value);
    this.displayValue = '';
    // Needs a small delay so the displayValue actually refreshes
    setTimeout(() => (this.displayValue = this.convertToCurrencyFormat(this.value)));
  }

  get isValidInput(): boolean {
    if (this.control) {
      return validInput(this.control);
    }
    return true;
  }

  get errorMessages(): string[] {
    return getValidationErrorMessageList(this.control, this.formErrorMessages);
  }

  private isNumeric(number): boolean {
    return !isNaN(number);
  }

  private convertToCurrencyFormat(numberValue: number) {
    const currencySymbol = getCurrencySymbol(this.currencyCode, 'narrow');
    const formattedValue = formatCurrency(
      numberValue,
      this.locale,
      currencySymbol,
      this.currencyCode,
      this.isDecimal ? this.DecimalDigitsInfo : this.IntegerDigitsInfo,
    );

    return formattedValue;
  }

  private convertToNumber(value: string): number {
    if (value) {
      const currencySymbol = getCurrencySymbol(this.currencyCode, 'narrow');
      const thousandsSeparator = Number(10000).toLocaleString().charAt(2);
      const regExp = new RegExp(thousandsSeparator, 'g');
      value = value.replace(currencySymbol, '');
      value = value.replace(regExp, '');
    }

    return this.isNumeric(value) ? Number(value) : null;
  }
}
