import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppState } from '@core/store';
import { selectPaymentStepSkipped } from '@core/store/start-now-app';
import { Store } from '@ngrx/store';
import { StepperBaseComponent } from '@shared/components/stepper-base/stepper-base.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-back-forth-buttons',
  templateUrl: './back-forth-buttons.component.html',
  styleUrls: ['./back-forth-buttons.component.scss'],
})
export class BackForthButtonsComponent extends StepperBaseComponent implements OnInit {
  @Output() navigateBack: EventEmitter<void> = new EventEmitter();
  @Output() navigateForward: EventEmitter<void> = new EventEmitter();
  @Input() nextProcessing: boolean;
  @Input() isFinalStep: boolean;
  @Input() isBackButtonDisabled: boolean;

  readonly Next: string = $localize`Next`;
  readonly Finalize: string = $localize`Finalize`;
  finalizeClicked: boolean = false;
  paymentStepSkipped$: Observable<boolean>;
  skipPayment: boolean;

  constructor(private store$: Store<AppState>) {
    super();
  }

  ngOnInit(): void {
    this.paymentStepSkipped$ = this.store$
      .select(selectPaymentStepSkipped)
      .pipe(map((skipPayment) => skipPayment));
    this.subscriptions.add(
      this.paymentStepSkipped$.subscribe((skipPayment) => {
        this.skipPayment = skipPayment;
      }),
    );
  }

  previousStepClick(): void {
    if (!this.nextProcessing) {
      this.navigateBack.emit();
    }
  }

  nextStepClick(): void {
    if (this.isFinalStep && this.skipPayment) {
      this.finalizeClicked = true;
    }
    if (!this.nextProcessing) {
      this.navigateForward.emit();
    }
  }
}
