<div class="my-3 d-md-none">
  <h5 class="d-flex font-weight-bold">
    <a routerLink=".."><i class="bi bi-arrow-left"></i></a>
    <span class="ml-2 d-flex">{{ title }}</span>
  </h5>
  <div *ngIf="rightText" class="right-text">{{ rightText }}</div>
</div>
<h3 class="my-3 d-none d-md-block">
  <a routerLink=".."><i class="bi bi-arrow-left"></i></a>
  <span class="ml-2 d-flex justify-content-between align-items-center">
    <span>{{ title }}</span>
    <span *ngIf="rightText" class="right-text">{{ rightText }}</span>
  </span>
</h3>
