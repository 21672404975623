<hr class="d-md-none mb-0" />
<div class="d-flex justify-content-between">
  <h5 class="font-weight-bold title my-3 my-md-2" i18n>Consultant's Corner Password</h5>
  <div *ngIf="!stepProcessing && !consultantDataCanNotBeModified">
    <a
      class="d-md-block d-none text-uppercase font-weight-bold pointer my-2"
      (click)="navigate()"
      i18n
    >
      Change
    </a>
    <a class="d-md-none title my-2" (click)="navigate()">
      <i class="bi bi-pencil-square"></i>
    </a>
  </div>
</div>
<hr class="d-md-none mt-0" />
<div class="card d-md-none">
  <div class="ph-row p-md-2">
    <span class="text-double-dot" i18n>Password</span>
    <strong>
      <span>
        {{ showPassword ? password : '*'.repeat(password.length) }}
      </span>
      <span class="pointer" (click)="togglePasswordShowing()">
        <i
          [ngClass]="{
            bi: true,
            'bi-eye-slash': showPassword,
            'bi-eye': !showPassword
          }"
        >
        </i>
      </span>
    </strong>
  </div>
</div>
<div class="card d-none d-md-block">
  <div class="ph-row p-md-2 d-flex justify-content-between">
    <span>
      <span class="text-double-dot" i18n>Password</span>
      <strong>
        {{ showPassword ? password : '*'.repeat(password.length) }}
      </strong>
    </span>
    <span class="pointer" (click)="togglePasswordShowing()">
      <i
        [ngClass]="{
          bi: true,
          'bi-eye-slash': showPassword,
          'bi-eye': !showPassword
        }"
      >
      </i>
    </span>
  </div>
</div>
