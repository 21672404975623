import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AppcuesEvent } from '@core/constants/appcues-event';
import {
  ShippedCartonDetailsResponse,
  ShippedCartonSkuDto,
  SubmitValidationOrderItem,
} from '@core/dto/order.dto';
import { Locale } from '@core/enums/locale';
import { PhExceptionErrorType } from '@core/enums/ph-exception-error-type.enum';
import { AppcuesService } from '@core/services/appcues.service';
import { GtmService } from '@core/services/gtm.service';
import { LoggerService } from '@core/services/logger.service';
import { OrderService } from '@core/services/order.service';
import { AppState } from '@core/store';
import { selectCart } from '@core/store/cart';
import { newCart } from '@core/store/cart/cart.actions';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getCreatePaymentMethodFailureMessage,
  getErrorMessage,
} from '@shared/utils/get-error-message';
import { getErrorTitle } from '@shared/utils/get-error-title';
import { getLanguage } from '@shared/utils/locale-utils';
import { convertToLocalizedDate } from '@shared/utils/localize-date-utils';
import { StatusCodes } from 'http-status-codes';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Cart } from '../cart/cart-state-models';
import { selectPersonalInfo } from '../checkout';
import { FailedOrderItem, ShippedCartonDetailsData, ShippedCartonSku } from './order-state-models';
import * as orderActions from './order.actions';

@Injectable()
export class OrderEffects {
  fetchOrderHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.fetchOrderHistory),
      mergeMap(() =>
        this.orderService.fetchOrderHistory().pipe(
          map((res) =>
            orderActions.fetchOrderHistorySuccess({
              payload: res.orders.map((order) => ({
                ...order,
                createdDate: convertToLocalizedDate(order.createdDate),
              })),
            }),
          ),
          catchError((error) => of(orderActions.fetchOrderHistoryFailure({ error }))),
        ),
      ),
    ),
  );

  fetchOrderHistoryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.fetchOrderHistoryFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchCCOrderHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.fetchCCOrderHistory),
      mergeMap(() =>
        this.orderService.fetchCCOrderHistory().pipe(
          map((res) =>
            orderActions.fetchCCOrderHistorySuccess({
              payload: res.orders.map((order) => ({
                ...order,
                createdDate: convertToLocalizedDate(order.dateReceived),
              })),
            }),
          ),
          catchError((error) => of(orderActions.fetchCCOrderHistoryFailure({ error }))),
        ),
      ),
    ),
  );

  fetchCCOrderHistoryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.fetchCCOrderHistoryFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchOrderDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.fetchOrderDetails),
      mergeMap(({ orderId }) =>
        this.orderService.fetchOrderDetails(orderId).pipe(
          map((res) =>
            orderActions.fetchOrderDetailsSuccess({
              payload: {
                ...res,
                createdDate: convertToLocalizedDate(res.createdDate),
                cartons: res.cartons.map((carton) => ({ ...carton, trackingUrl: carton.tracking })),
              },
            }),
          ),
          catchError((error) => of(orderActions.fetchOrderDetailsFailure({ error }))),
        ),
      ),
    ),
  );

  fetchOrderDetailsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.fetchOrderDetailsFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchCCOrderDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.fetchCCOrderDetails),
      mergeMap(({ poeOrderId }) =>
        this.orderService.fetchCCOrderDetails(poeOrderId).pipe(
          map((res) =>
            orderActions.fetchCCOrderDetailsSuccess({
              payload: {
                ...res,
                dateReceived: convertToLocalizedDate(res.dateReceived),
              },
            }),
          ),
          catchError((error) => of(orderActions.fetchCCOrderDetailsFailure({ error }))),
        ),
      ),
    ),
  );

  fetchCCOrderDetailsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.fetchCCOrderDetailsFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  submitOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.submitOrder),
      concatLatestFrom(() => this.store$.select(selectCart)),
      mergeMap(([{ request }, cart]) =>
        this.orderService.submitOrder(request).pipe(
          map((res) => orderActions.submitOrderSuccess({ payload: { cart, response: res } })),
          catchError((error) => {
            const updatedItems = this.mapOrderLineToFailedOrderItem(
              cart,
              error.error?.updatedItems,
            );
            const deletedItems = this.mapOrderLineToFailedOrderItem(
              cart,
              error.error?.deletedItems,
            );
            return of(orderActions.submitOrderFailure({ error, updatedItems, deletedItems }));
          }),
        ),
      ),
    ),
  );

  submitOrderSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.submitOrderSuccess),
      concatLatestFrom(() => this.store$.select(selectPersonalInfo)),
      tap(([{ payload }, personalInfo]) => {
        this.gtmService.purchase(
          payload.response.orderId,
          payload.cart,
          personalInfo?.billingCustomer?.email,
        );
        this.appcuesService.track(AppcuesEvent.SubmitOrder);
      }),
      map(() => newCart()),
    ),
  );

  submitOrderFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.submitOrderFailure),
        tap(({ error, updatedItems, deletedItems }) => {
          if (!updatedItems.length && !deletedItems.length) {
            this.toastr.error(getErrorMessage(error), getErrorTitle(error));
          }
        }),
      ),
    { dispatch: false },
  );

  fetchPartyOrderData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.fetchPartyOrderData),
      mergeMap(({ orderNumber }) =>
        this.orderService.fetchPartyOrderData(orderNumber).pipe(
          map((partyOrderData) =>
            orderActions.fetchPartyOrderDataSuccess({
              partyOrderData: {
                hostessName: partyOrderData.hostessName,
                consultantName: partyOrderData.consultantName,
                orderNumber: partyOrderData.masterOrderId,
                receivedDate: partyOrderData.receivedDate,
                totalOrderAmount: partyOrderData.totalOrderAmount,
                paymentReceived: partyOrderData.paymentReceived,
                balanceDue: partyOrderData.balanceDue,
                consultantBeeNumber: partyOrderData.consultantBeeNumber,
              },
            }),
          ),
          catchError((res) => of(orderActions.fetchPartyOrderDataFailure(res))),
        ),
      ),
    ),
  );

  fetchPartyOrderDataFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.fetchPartyOrderDataFailure),
        tap((res) => {
          if (res.status != StatusCodes.BAD_REQUEST) {
            return this.toastr.error(
              $localize`Failed to fetch party order!`,
              $localize`Party order fetching error`,
            );
          }
        }),
      ),
    { dispatch: false },
  );

  submitPartyOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.submitPartyOrder),
      mergeMap(({ request }) =>
        this.orderService.submitPartyOrder(request).pipe(
          map((res) => orderActions.submitPartyOrderSuccess({ payload: res })),
          catchError((error) => of(orderActions.submitPartyOrderFailure({ error: error.error }))),
        ),
      ),
    ),
  );

  submitPartyOrderFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.submitPartyOrderFailure),
        tap(({ error }) => {
          let message =
            error.ErrorType === PhExceptionErrorType.PaymentMethodCreationFailedException
              ? getCreatePaymentMethodFailureMessage(error)
              : getErrorMessage(error);

          this.toastr.error(message, getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  logCardAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.logCardAttempt),
      mergeMap(({ request }) =>
        this.orderService.logCardAttempt(request).pipe(
          map(() => orderActions.logCardAttemptSuccess()),
          catchError(({ error }) => of(orderActions.logCardAttemptFailure({ error }))),
        ),
      ),
    ),
  );

  logCardAttemptFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(orderActions.logCardAttemptFailure),
        tap(() => this.loggerService.error('Log card attempt failure')),
      ),
    { dispatch: false },
  );

  fetchShippedCartonsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(orderActions.fetchShippedCartonsData),
      mergeMap(({ orderUID, orderId }) =>
        this.orderService.fetchShippedCartons(orderUID, orderId).pipe(
          map((res) =>
            orderActions.fetchShippedCartonsSuccess({
              payload: this.mapShippedCartonDetailsResponseToShippedCartonDetailsData(
                res,
                this.language,
              ),
            }),
          ),
          catchError(({ error }) => of(orderActions.fetchShippedCartonsFailure({ error }))),
        ),
      ),
    ),
  );

  private language: string;

  constructor(
    private actions$: Actions,
    private orderService: OrderService,
    private toastr: ToastrService,
    private store$: Store<AppState>,
    private gtmService: GtmService,
    private appcuesService: AppcuesService,
    private loggerService: LoggerService,
    @Inject(LOCALE_ID) private localeId: Locale,
  ) {
    this.language = getLanguage(this.localeId);
  }

  private mapOrderLineToFailedOrderItem(
    cart: Cart,
    failedOrderItems: SubmitValidationOrderItem[],
  ): FailedOrderItem[] {
    if (failedOrderItems?.length) {
      return failedOrderItems.map((orderItem) => {
        const cartItem = cart.orderLines.find((item) => item.sku === orderItem.skuCode);
        return { ...cartItem, actualPrice: orderItem.unitPrice };
      });
    }
    return [];
  }

  private mapShippedCartonDetailsResponseToShippedCartonDetailsData(
    cartonDetailsDTO: ShippedCartonDetailsResponse,
    language: string,
  ): ShippedCartonDetailsData {
    return {
      ...cartonDetailsDTO,
      backorderItems: cartonDetailsDTO.backorderItems.skus.map((sku) =>
        this.mapSkuItemDTOToSkuItem(sku, language),
      ),
      shippedCartons: cartonDetailsDTO.shippedCartons
        .map((sc) => ({
          ...sc,
          skus: sc.skus.map((sku) => this.mapSkuItemDTOToSkuItem(sku, language)),
        }))
        .sort((a, b) => a.cartonNumber - b.cartonNumber),
    };
  }

  private mapSkuItemDTOToSkuItem(sku: ShippedCartonSkuDto, language: string): ShippedCartonSku {
    return {
      ...sku,
      skuDescription: language === Locale.En ? sku.skuDescriptionEn : sku.skuDescriptionEs,
    };
  }
}
