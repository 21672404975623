export enum MelissaErrorCode {
  AE01 = 'AE01',
  AE02 = 'AE02',
  AE03 = 'AE03',
  AE04 = 'AE04',
  AE05 = 'AE05',
  AE06 = 'AE06',
  AE07 = 'AE07',
  AE08 = 'AE08',
  AE09 = 'AE09',
  AE10 = 'AE10',
  AE11 = 'AE11',
  AE12 = 'AE12',
  AE13 = 'AE13',
  AE14 = 'AE14',
  AE15 = 'AE15',
  AE16 = 'AE16',
  AE17 = 'AE17',
  AE19 = 'AE19',
  AE20 = 'AE20',
  AE21 = 'AE21',
  AC01 = 'AC01',
  AC02 = 'AC02',
  AC03 = 'AC03',
  AC09 = 'AC09',
  AC10 = 'AC10',
  AC11 = 'AC11',
  AC12 = 'AC12',
  AC13 = 'AC13',
  AC14 = 'AC14',
  AC16 = 'AC16',
  AC19 = 'AC19',
  AC20 = 'AC20',
}
