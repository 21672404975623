<app-modal #modal [modalConfig]="ModalConfig">
  <div class="d-flex justify-content-center">
    <app-ph-spinner class="my-5" *ngIf="isVoucherLoading; else loaded" width="100px">
    </app-ph-spinner>
  </div>

  <app-oxxo-voucher-container
    *ngFor="let voucher of vouchers"
    [voucher]="voucher"
    (voucherLoaded)="handleLoadedVoucher($event)"
  >
  </app-oxxo-voucher-container>

  <ng-template #loaded>
    <div class="row text-center">
      <div class="col-12">
        <img class="my-2 w-50" [src]="PaymentImageSrc.oxxo" />
      </div>
      <div class="col-12">
        <span>
          {{
            vouchers?.length < 2
              ? VoucherMessages[NounsType.Singular]
              : VoucherMessages[NounsType.Plural]
          }}
        </span>
      </div>
      <div class="mb-4 w-100">
        <div class="col-12" *ngFor="let voucher of vouchers">
          <a class="pointer" (click)="openVoucher(voucher.voucherUrl)">
            {{ voucher.voucherUrl }}
          </a>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="d-flex justify-content-center">
    <button
      class="btn btn-primary w-25 text-uppercase"
      (click)="onClose()"
      [disabled]="isVoucherLoading"
      i18n
    >
      Got it!
    </button>
  </div>
</app-modal>
