import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalConfig } from '@core/models/modal-config.model';
import { AppState } from '@core/store';
import { FailedOrderItem } from '@core/store/order/order-state-models';
import { resetFailedOrderItems } from '@core/store/order/order.actions';
import { Store } from '@ngrx/store';
import { ModalComponent } from '@shared/components/modal/modal.component';

@Component({
  selector: 'app-failed-order-submit-modal',
  templateUrl: './failed-order-submit-modal.component.html',
  styleUrls: ['./failed-order-submit-modal.component.scss'],
})
export class FailedOrderSubmitModalComponent {
  @Input() updatedOrderItems: FailedOrderItem[];
  @Input() deletedOrderItems: FailedOrderItem[];

  modalConfig: ModalConfig = {
    title: $localize`Item(s) updated or removed from cart`,
    hideCloseButton: true,
    hideDismissButton: true,
    hideHeaderDismissButton: true,
    ngbModalOptions: {
      size: 'lg',
      backdrop: 'static',
    },
  };

  @ViewChild('modal') private modal: ModalComponent;

  constructor(
    private router: Router,
    private store$: Store<AppState>,
  ) {}

  open(): void {
    this.modal.open();
  }

  close(): void {
    this.modal.close();
    this.store$.dispatch(resetFailedOrderItems());
    this.router.navigate(['/shopping-cart']);
  }
}
