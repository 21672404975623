import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ConditionalContent } from '@core/store/conditional-content/conditional-content-state-models';
import {
  localizeAbsoluteLinks,
  localizeRelativeLinks,
} from '@shared/utils/localize-relative-links';
import { Locale } from 'moment';

@Pipe({
  name: 'localizeAbsUrls',
})
export class LocalizeAbsUrlsPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private localeId: Locale) {}

  transform(value: ConditionalContent[]): ConditionalContent[] {
    const result: ConditionalContent[] = [];
    value.forEach((element) => {
      element.content = localizeAbsoluteLinks(
        localizeRelativeLinks(element.content, this.localeId),
        this.localeId,
      );
      result.push(element);
    });

    return result;
  }
}
