/* eslint-disable @typescript-eslint/member-ordering */
import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateFormat } from '@core/constants/date-format';
import { OrderStatus } from '@core/enums/shipment-status-enum';
import { ImageDetails } from '@core/models/image.model';
import { AppState } from '@core/store';
import { selectOrderDetails } from '@core/store/order';
import { OrderAddress } from '@core/store/order/order-state-models';
import { resetOrderDetailsState, resetOrderHistoryState } from '@core/store/order/order.actions';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export type ReturnOrderLineType = {
  checked?: boolean;
  reason: string;
  orderLineId?: string;
  collectionName: string;
  productName: string;
  sku: string;
  price: number;
  orderStatus?: OrderStatus;
  shippingDate?: string;
  images: ImageDetails[];
};

export type ReturnOrderInformationType = {
  shippingAddress: OrderAddress;
  billingAddress: OrderAddress;
  party: {
    consultant: string;
    host: string;
  };
};

const initialValues = {
  title: $localize`New Return`,
  orderDate: null,
  orderLines: [],
  orderInfo: null,
};

@Component({
  templateUrl: './new-return.component.html',
  styleUrls: ['./new-return.component.scss'],
  providers: [DatePipe],
})
export class NewReturnComponent implements OnInit, OnDestroy {
  title = initialValues.title;
  orderDate: string;

  orderLines: ReturnOrderLineType[] = [];
  orderInfo: ReturnOrderInformationType;

  subscriptions = new Subscription();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _activeStep = 0;
  get activeStep(): number {
    return this._activeStep;
  }
  set activeStep(value: number) {
    if (value === 0) {
      this.resetOrderDetialValues();
    }
    this._activeStep = value;
  }

  readonly steps: string[] = [
    $localize`Select order`,
    $localize`Select product`,
    $localize`Return summary`,
  ];

  constructor(private store$: Store<AppState>, private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.resetOrderState();
    this.initOrderDetails();
  }

  ngOnDestroy(): void {
    this.resetOrderState();
    this.subscriptions.unsubscribe();
  }

  private resetOrderState(): void {
    this.store$.dispatch(resetOrderHistoryState());
    this.store$.dispatch(resetOrderDetailsState());
  }

  private initOrderDetails(): void {
    this.subscriptions.add(
      this.store$
        .select(selectOrderDetails)
        .pipe(filter((orderDetails) => !!orderDetails))
        .subscribe((orderDetails) => {
          this.title = `${$localize`Order #`}${orderDetails.orderNumber}`;
          this.orderDate = `${$localize`Order date:`} ${this.datePipe.transform(
            orderDetails.createdDate,
            DateFormat.DayMonthNameYear,
          )}`;

          this.orderInfo = {
            shippingAddress: { ...orderDetails.shippingAddress },
            billingAddress: { ...orderDetails.billingAddress },
            party: {
              consultant: `${orderDetails.consultantFirstName} ${orderDetails.consultantLastName}`,
              host: orderDetails.partyHost,
            },
          };

          orderDetails.orderLines.forEach((orderLine) => {
            for (let index = 0; index < orderLine.quantity; index++) {
              this.orderLines.push({
                ...orderLine,
                price: orderLine.discountedPrice || orderLine.originalPrice,
                checked: false,
                reason: null,
              });
            }
            this.orderLines.sort((a, b) => (a.productName < b.productName ? -1 : 1));
          });
        }),
    );
  }

  private resetOrderDetialValues() {
    this.title = initialValues.title;
    this.orderDate = initialValues.orderDate;
    this.orderLines = initialValues.orderLines;
    this.orderInfo = initialValues.orderInfo;
  }
}
