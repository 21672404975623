<div>
  <button
    class="btn"
    (click)="facebookClickHandler()"
    aria-label="Share on Facebook"
    i18n-aria-label
  >
    <i class="bi bi-facebook" alt=""></i>
  </button>
  <button class="btn" (click)="twitterClickHandler()" aria-label="Share on Twitter" i18n-aria-label>
    <i class="bi bi-twitter" alt=""></i>
  </button>
  <button
    class="btn"
    (click)="pinterestClickHandler()"
    aria-label="Share on Pinterest"
    i18n-aria-label
  >
    <i class="bi bi-pinterest" alt=""></i>
  </button>
  <button class="btn" (click)="emailClickHandler()" aria-label="Share with email" i18n-aria-label>
    <i class="bi bi-envelope-fill" alt=""></i>
  </button>
</div>

<app-email-share-modal #modal></app-email-share-modal>
