import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageCategory } from '@core/enums/page-category.enum';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { AppState } from '@core/store';
import { selectConditionalContentByPageCategory } from '@core/store/conditional-content';
import { ConditionalContent } from '@core/store/conditional-content/conditional-content-state-models';
import {
  fetchConditionalContentByPageCategory,
  resetConditionalContentState,
} from '@core/store/conditional-content/conditional-content.actions';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-conditional-content-by-page-category',
  templateUrl: './conditional-content-by-page-category.component.html',
  styleUrls: ['./conditional-content-by-page-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionalContentByPageCategoryComponent implements OnInit, OnDestroy {
  @Input() pageCategory: PageCategory;

  pageTemplate$: Observable<ConditionalContent[]>;
  subscriptions: Subscription = new Subscription();

  constructor(private store$: Store<AppState>, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParamMap.subscribe((queryParamMap) => {
        const viewAs = queryParamMap.get(RouterQueryParams.viewAs);
        this.store$.dispatch(
          fetchConditionalContentByPageCategory({ pageCategory: this.pageCategory, viewAs }),
        );
        this.loadPageTemplate(this.pageCategory);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.store$.dispatch(resetConditionalContentState());
  }

  private loadPageTemplate(pageCategory: string) {
    this.pageTemplate$ = this.store$.select(selectConditionalContentByPageCategory(pageCategory));
  }
}
