<div [ngClass]="{ 'search-result-document': isOnSearchPage }">
  <div class="mb-2">
    <app-recipe-image [recipe]="recipe"></app-recipe-image>
  </div>
  <div class="recipe-header">
    <div class="recipe-type font-weight-bold mb-1 text-uppercase" *ngIf="isOnSearchPage" i18n
      >recipe</div
    >
    <a class="recipe-title" [routerLink]="detailsUrl">
      {{ recipe?.name }}
    </a>
  </div>
</div>
