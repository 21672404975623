import { createAction, props } from '@ngrx/store';
import { CreatedVoucher, ReceivedVoucher } from './voucher-state-models';

export enum VoucherActionTypes {
  resetVoucherStore = '[Voucher] Reset voucher store',
  createVoucher = '[Voucher] Create voucher',
  createVoucherSuccess = '[Voucher] Create voucher success',
  createVoucherFailure = '[Voucher] Create voucher failure',
  setVoucherEmail = '[Voucher] Set email',

  receiveVoucher = '[Voucher] Receive voucher',
  receiveVoucherSuccess = '[Voucher] Receive voucher success',
  receiveVoucherFailure = '[Voucher] Receive voucher failure',
  retryReceiveVoucher = '[Voucher] Retry receive voucher',

  generateVoucher = '[Voucher] Generate voucher',
  generateVoucherSuccess = '[Voucher] Generate voucher success',
  generateVoucherFailure = '[Voucher] Generate voucher failure',

  prevalidateVoucher = '[Voucher] Prevalidate voucher',
  prevalidateVoucherSuccess = '[Voucher] Prevalidate voucher success',
  prevalidateVoucherFailure = '[Voucher] Prevalidate voucher failure',

  resetVoucherErrors = '[Voucher] Reset voucher errors',
}

export const resetVoucherStore = createAction(VoucherActionTypes.resetVoucherStore);

export const createVoucher = createAction(
  VoucherActionTypes.createVoucher,
  props<{ email: string }>(),
);

export const createVoucherSuccess = createAction(
  VoucherActionTypes.createVoucherSuccess,
  props<{ vouchers: CreatedVoucher[] }>(),
);

export const createVoucherFailure = createAction(
  VoucherActionTypes.createVoucherFailure,
  props<{ error: any }>(),
);

export const setVoucherEmail = createAction(
  VoucherActionTypes.setVoucherEmail,
  props<{ email: string }>(),
);

export const receiveVoucher = createAction(
  VoucherActionTypes.receiveVoucher,
  props<{ voucherTokens: string[] }>(),
);

export const receiveVoucherSuccess = createAction(
  VoucherActionTypes.receiveVoucherSuccess,
  props<{ vouchers: ReceivedVoucher[] }>(),
);

export const retryReceiveVoucher = createAction(
  VoucherActionTypes.retryReceiveVoucher,
  props<{ voucherTokens: string[] }>(),
);

export const receiveVoucherFailure = createAction(VoucherActionTypes.receiveVoucherFailure);

export const generateVoucher = createAction(
  VoucherActionTypes.generateVoucher,
  props<{ vouchers: CreatedVoucher[] }>(),
);

export const generateVoucherSuccess = createAction(
  VoucherActionTypes.generateVoucherSuccess,
  props<{ voucherTokens: string[] }>(),
);

export const generateVoucherFailure = createAction(VoucherActionTypes.generateVoucherFailure);

export const resetVoucherErrors = createAction(VoucherActionTypes.resetVoucherErrors);

export const prevalidateVoucher = createAction(
  VoucherActionTypes.prevalidateVoucher,
  props<{ email: string }>(),
);

export const prevalidateVoucherSuccess = createAction(VoucherActionTypes.prevalidateVoucherSuccess);
export const prevalidateVoucherFailure = createAction(
  VoucherActionTypes.prevalidateVoucherFailure,
  props<{ error: any }>(),
);
