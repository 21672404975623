import { Component, Input, OnInit } from '@angular/core';
import { RecipeItem } from '@core/store/recipe/recipe-state-models';
import { createRecipeDetailsUrl } from '@shared/utils/recipe-utils';

@Component({
  selector: 'app-recipe-list-item',
  templateUrl: './recipe-list-item.component.html',
  styleUrls: ['./recipe-list-item.component.scss'],
})
export class RecipeListItemComponent implements OnInit {
  @Input()
  recipe: RecipeItem;
  @Input() isOnSearchPage: boolean = false;
  detailsUrl: string;

  constructor() {}

  ngOnInit() {
    this.detailsUrl = createRecipeDetailsUrl(this.recipe?.url);
  }
}
