import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-recipe-catalog-page',
  templateUrl: './recipe-more-catalog-page.component.html',
  styleUrls: ['./recipe-more-catalog-page.component.scss'],
})
export class RecipeMoreCatalogPageComponent implements OnInit {
  activeId: string = '';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activeId = 'recipes';
    this.router.navigate(['recipes'], { relativeTo: this.activatedRoute });
  }
}
