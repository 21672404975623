import { Component, Input } from '@angular/core';
import { Step } from '@core/models/step.model';

@Component({
  selector: 'app-step-ruler',
  templateUrl: './step-ruler.component.html',
  styleUrls: ['./step-ruler.component.scss'],
})
export class StepRulerComponent {
  @Input()
  step: Step;

  constructor() {}
}
