import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent {
  @Input() title: string;
  @Input() noLabel: string = $localize`No`;
  @Input() yesLabel: string = $localize`Yes`;
  @Input() size: 'sm' | 'lg' | 'xl';

  @Output() noClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() yesClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modal') private modalContent: TemplateRef<ConfirmModalComponent>;

  private payload: any;
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  open(payload: any = null) {
    this.payload = payload;
    this.modalRef = this.modalService.open(this.modalContent, {
      centered: true,
      size: this.size,
      backdrop: 'static',
    });
  }

  onYes() {
    this.yesClick.emit(this.payload);
    this.close();
  }

  onNo() {
    this.noClick.emit(this.payload);
    this.close();
  }

  private close() {
    this.modalRef.dismiss();
  }
}
