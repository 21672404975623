/* eslint-disable @typescript-eslint/naming-convention */
export type RecipeListSortType = { [key in keyof typeof RecipeListSortOptions]: string };

export const RecipeListSortOptions = {
  NewestFirst: $localize`Most recent`,
  NameAscending: $localize`Name: A - Z`,
  NameDescending: $localize`Name: Z - A`,
};

export const defaultRecipeListSortOption: RecipeListSortType =
  'NewestFirst' as unknown as RecipeListSortType;
