import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MaskPipe } from 'ngx-mask';

/**
 * SSN number looks like 123-45-6789.
 * This component converts the first 5 numbers into X (XXX-XX-6789).
 */

@Component({
  selector: 'app-ssn-number-field-input',
  templateUrl: './ssn-number-field-input.component.html',
  styleUrls: ['./ssn-number-field-input.component.scss'],
  providers: [MaskPipe],
})
export class SsnNumberFieldInputComponent {
  @Input() control: FormControl;

  @Input() label: string;

  @Input() labelStyle: any = {};

  @Input() labelAsterix: boolean = false;

  readonly validationErrorMessage: string = $localize`Please enter a valid Social Security Number`;
  readonly identifier = Date.now();
  readonly ssnPattern = {
    X: { pattern: new RegExp('\\d'), symbol: 'X' },
    0: { pattern: new RegExp('\\d') },
  };
}
