<div *ngIf="languages.length > 1" ngbDropdown container="body">
  <button
    class="btn text-white"
    id="dropdown"
    ngbDropdownToggle
    aria-label="Select a language"
    i18n-aria-label
  >
    <i class="bi bi-globe" alt=""></i>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdown">
    <button
      *ngFor="let lang of languages"
      [ngClass]="{
        active: lang.code === currentLocale
      }"
      (click)="handleLanguageClick(lang)"
      ngbDropdownItem
    >
      {{ locale[lang.code] || lang.code }}
    </button>
  </div>
</div>
