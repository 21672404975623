/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RedirectRequest } from '@azure/msal-browser';
import { AuthService } from '@core/services/auth.service';

@Component({
  templateUrl: 'b2c-signin.component.html',
  styleUrls: ['./b2c-signin.component.scss'],
})
export class B2cSigninComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {}
  req: RedirectRequest;

  ngOnInit(): void {
    const prefill_email = this.route.snapshot.queryParamMap.get('prefill_email');
    if (prefill_email) {
      this.authService.logIn({ prefill_email });
    } else {
      this.authService.logIn();
    }
  }
}
