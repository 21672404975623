import { DateFormat } from '@core/constants/date-format';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import moment from 'moment';
import { MagazineState } from './magazine.reducer';

export const magazineStateFeatureKey = 'magazineState';

export const selectMagazineFeature = createFeatureSelector<MagazineState>(magazineStateFeatureKey);

export const selectMagazines = (date: string = null) => {
  const _date = date ? date : moment(new Date()).format(DateFormat.MonthDayYear);
  return createSelector(selectMagazineFeature, (state: MagazineState) =>
    state ? state.items.filter((i) => moment.utc(_date).isBetween(i.startDate, i.endDate)) : null,
  );
};

export const selectIsAllMagazinesFetched = createSelector(
  selectMagazineFeature,
  (state: MagazineState) => (state ? state.isAllFetched : false),
);
