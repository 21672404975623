import { Component, OnInit } from '@angular/core';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { environment } from '@env';

@Component({
  selector: 'app-datadog-initializer',
  template: '',
})
export class DatadogInitializerComponent implements OnInit {
  ngOnInit(): void {
    if (environment.datadog) {
      datadogRum.init(environment.datadog as RumInitConfiguration);
      datadogRum.startSessionReplayRecording();
    }
  }
}
