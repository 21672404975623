<div class="container pt-5">
  <h1 class="title text-center mb-3 text-uppercase" i18n>Your Consultant Preference</h1>

  <div class="main-text text-center my-5 pb-md-3" i18n>
    Please enter your ZIP Code and preferred language so we can find you a Consultant
  </div>

  <form [formGroup]="findConsultantByZipCodeForm">
    <div class="row justify-content-center my-md-5 mt-5 mb-3 pt-md-3">
      <div class="col-12 col-md-3">
        <app-text-field-input
          [identifier]="FindConsultantFormKeys.zipCode"
          label="ZIP Code"
          i18n-label
          placeholder="Zip Code"
          i18n-placeholder
          [formControlName]="FindConsultantFormKeys.zipCode"
          [labelStyle]="FormLabelStyle"
        ></app-text-field-input>
      </div>
      <div class="col-12 col-md-3 mt-3 mt-md-0" ngbRadioGroup>
        <span class="text-nowrap" i18n>Language Preference</span>
        <div class="row radio-button-color">
          <div class="col-6 mt-2 text-nowrap">
            <label ngbButtonLabel class="my-0 px-0 font-weight-normal shadow-none">
              <input
                ngbButton
                class="radio-input"
                type="radio"
                [id]="
                  FindConsultantFormKeys.languagePreference + '_' + LanguagePreferenceType.English
                "
                [name]="FindConsultantFormKeys.languagePreference"
                [formControlName]="FindConsultantFormKeys.languagePreference"
                [value]="LanguagePreferenceType.English"
              />
              <span i18n>English</span>
            </label>
          </div>
          <div class="col-6 mt-2 text-nowrap">
            <label ngbButtonLabel class="my-0 px-0 font-weight-normal shadow-none">
              <input
                ngbButton
                class="radio-input"
                type="radio"
                [id]="
                  FindConsultantFormKeys.languagePreference + '_' + LanguagePreferenceType.Spanish
                "
                [name]="FindConsultantFormKeys.languagePreference"
                [formControlName]="FindConsultantFormKeys.languagePreference"
                [value]="LanguagePreferenceType.Spanish"
              />
              <span i18n>Spanish</span>
            </label>
          </div>
        </div>
        <div class="row justify-content-center mt-1">
          <div class="col justify-content-center">
            <div class="validation-error-message">
              <span *ngIf="!radioButtonValidInput">{{
                getFormValidationErrorMessage(
                  findConsultantByZipCodeForm,
                  FindConsultantFormKeys.languagePreference
                )
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center validation-error-message">
        <span *ngIf="(reCaptchaValidation$ | async) === SuccessfulStatus.Failed" i18n>
          ReCaptcha validation failed. Please try again later.
        </span>
        <span *ngIf="noConsultantFound" i18n> No consultant found in this area. </span>
        <span class="col" *ngIf="findConsultantByZipCodeError$ | async as errorType">
          {{ ConsultantLocatorErrorMessage[errorType] }}
        </span>
      </div>
    </div>
  </form>

  <div class="row my-md-5 mb-5 mt-3 pt-md-5 pt-2 justify-content-center">
    <div class="col col-md-3">
      <div class="white-button-container">
        <button
          class="btn btn-outline-primary text-uppercase w-100"
          i18n
          (click)="previousStepClick()"
        >
          Back
        </button>
      </div>
    </div>
    <div class="col col-md-3">
      <button
        id="next-button"
        class="btn btn-primary text-uppercase w-100"
        [disabled]="stepProcessing$ | async"
        (click)="nextStepClick()"
      >
        <span i18n>Next</span>
        <div class="spinner-border spinner-border-sm" *ngIf="stepProcessing$ | async" role="status">
          <span class="sr-only" i18n>Loading...</span>
        </div>
      </button>
    </div>
  </div>
</div>

<app-sna-recaptcha #reCaptcha></app-sna-recaptcha>
