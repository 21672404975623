import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InfoCardAttributes } from '@core/models/info-card-attributes.model';

@Component({
  selector: 'app-my-returns-info-card',
  templateUrl: './my-returns-info-card.component.html',
  styleUrls: ['./my-returns-info-card.component.scss'],
})
export class MyReturnsInfoCardComponent {
  @Input() data: InfoCardAttributes;
  @Output() openChangeAddressModal: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  changeAddress(): void {
    this.openChangeAddressModal.emit();
  }
}
