<circle-progress
  [percent]="getPercent()"
  [radius]="20"
  [title]="getTitle()"
  titleFontSize="10"
  titleFontWeight="bold"
  [outerStrokeWidth]="4"
  [innerStrokeWidth]="4"
  space="-4"
  [outerStrokeColor]="'#682b8c'"
  [innerStrokeColor]="'#d0d0d0'"
  [clockwise]="true"
  animationDuration="300"
  [animation]="true"
  [startFromZero]="false"
  [showSubtitle]="false"
  [showUnits]="false"
></circle-progress>
