<app-modal #optionsModal [modalConfig]="optionsConfig">
  <div class="p-3">
    <div class="row">
      <div class="col text-center">{{ consultantFinderText }}</div>
    </div>
    <div class="row mt-3 justify-content-center">
      <div
        class="col-12 mb-1"
        [ngClass]="{
          'col-md-4': hasAutomaticAssignOption,
          'col-md-6': !hasAutomaticAssignOption
        }"
      >
        <app-action-card
          icon="person-check"
          i18n-actionLabel
          actionLabel="Look up consultant"
          (actionClick)="openFinder(consultantFinderType.find, true)"
          [disabled]="loading$ | async"
          i18n
        >
          I know / have a consultant. Help me find them.
        </app-action-card>
      </div>
      <div
        class="col-12 mb-1"
        [ngClass]="{
          'col-md-4': hasAutomaticAssignOption,
          'col-md-6': !hasAutomaticAssignOption
        }"
      >
        <app-action-card
          icon="question"
          i18n-actionLabel
          actionLabel="Locate consultant"
          (actionClick)="openFinder(consultantFinderType.locate, false)"
          [disabled]="loading$ | async"
          i18n
        >
          I don’t know / don’t have a consultant. Help me find one.
        </app-action-card>
      </div>

      <ng-container *ngIf="!hasAutomaticAssignOption; else automaticAssignOption">
        <div class="col-12 d-flex justify-content-center">
          <button class="btn btn-link" (click)="notNow()" i18n
            >I don't want to select a consultant at the moment.</button
          >
        </div>
      </ng-container>

      <ng-template #automaticAssignOption>
        <div class="col-12 mt-3 validation-error-message text-center">
          <span
            *ngIf="(consultantLocatorError$ | async) === LocatorErrorTypes.ZipCodeNotFound"
            i18n
          >
            Consultant autoassign error, please use the Locate consultant option
          </span>
        </div>

        <div class="col-12 mt-3 text-center">
          <button class="btn btn-link" (click)="autoAssign()" [disabled]="loading$ | async" i18n>
            Assign a Consultant to me Automatically
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</app-modal>

<app-modal #finderModal [modalConfig]="finderConfig">
  <div class="p-3">
    <div class="row" *ngIf="showExtraHint">
      <div class="col">{{ extraHint[finderType] }}</div>
    </div>
    <app-consultant-finder
      #consultantFinder
      [type]="finderType"
      (valid)="onFinderValidChange($event)"
      (submitted)="onFinderSubmitted($event)"
      [zipCode]="zipCode"
    ></app-consultant-finder>

    <div class="row">
      <div class="col action-container">
        <button class="btn btn-outline-secondary text-uppercase" (click)="closeFinder()" i18n>
          Back
        </button>
        <button
          class="btn btn-primary text-uppercase"
          [disabled]="!finderIsValid"
          (click)="search()"
          i18n
        >
          Search
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #resultModal [modalConfig]="resultConfig">
  <div class="p-3">
    <app-consultant-finder-result
      [filter]="filter"
      [finderType]="finderType"
      [loadMoreButtonIsVisible]="loadMoreButtonIsVisible"
      (consultantSelected)="closeResult()"
    ></app-consultant-finder-result>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <button class="btn btn-outline-secondary btn7 text-uppercase" (click)="resultBack()" i18n>
          Back
        </button>
      </div>
    </div>
  </div>
</app-modal>
