import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CountryName } from '@core/constants/country-name';
import { AppState } from '@core/store';
import {
  selectAddressValidation,
  selectAddresses,
  selectCountryStates,
  selectLoading,
  selectSaveInProgress,
} from '@core/store/address';
import { Address, AddressVerification } from '@core/store/address/address-state-models';
import {
  createAddress,
  deleteAddress,
  fetchAddresses,
  fetchCountryStates,
  resetAddressValidationModal,
  updateAddress,
} from '@core/store/address/address.actions';
import { environment } from '@env';
import { Store, select } from '@ngrx/store';
import { SelectOption } from '@shared/components/select/select.component';
import { countryStatesToSelectOptions } from '@shared/utils/address-utils';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  templateUrl: './address-editor.component.html',
  styleUrls: ['./address-editor.component.scss'],
})
export class AddressEditorComponent implements OnInit, OnDestroy {
  readonly isMexEnv = isMexEnv;
  readonly FirstName = 'firstName';
  readonly LastName = 'lastName';
  readonly Name = 'name';
  readonly AddressLine1 = 'addressLine1';
  readonly AddressLine2 = 'addressLine2';
  readonly AddressLine3 = 'addressLine3';
  readonly City = 'city';
  readonly State = 'state';
  readonly Country = 'country';
  readonly ZipCode = 'zipCode';
  readonly Default = 'default';

  addresses$: Observable<Address[]>;
  loading$: Observable<boolean>;
  countryStates$: Observable<SelectOption[]>;
  saveInProgress$: Observable<boolean>;
  addressId: string;
  addressForm: FormGroup;
  addressesSubscription: Subscription;
  addresses: Address[];
  currentAddress: Address;
  newAddressTitle = $localize`New address`;
  addressValidation$: Observable<AddressVerification>;

  private isFormPrefilled: boolean = false;

  constructor(
    private store$: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
  ) {
    this.addressId = this.activatedRoute.snapshot.paramMap.get('addressId');
  }

  ngOnInit(): void {
    this.store$.dispatch(fetchAddresses());
    this.store$.dispatch(fetchCountryStates());

    this.addresses$ = this.store$.select(selectAddresses);
    this.loading$ = this.store$.select(selectLoading);
    this.saveInProgress$ = this.store$.select(selectSaveInProgress);
    this.countryStates$ = countryStatesToSelectOptions(this.store$.select(selectCountryStates));
    this.addressValidation$ = this.store$
      .select(selectAddressValidation)
      .pipe(select((data) => data?.addressVerificationResult));

    this.addressForm = this.fb.group(
      {
        [this.FirstName]: ['', [Validators.required]],
        [this.LastName]: ['', [Validators.required]],
        [this.Name]: ['', [Validators.required, this.uniqueNameValidator()]],
        [this.AddressLine1]: ['', [Validators.required]],
        [this.AddressLine2]: [''],
        [this.City]: ['', [Validators.required]],
        [this.State]: ['', [Validators.required]],
        [this.Country]: [CountryName[environment.country]],
        [this.ZipCode]: ['', [Validators.required]],
        [this.Default]: [false],
      },
      { updateOn: 'submit' },
    );

    if (this.isMexEnv) {
      this.addressForm.addControl(this.AddressLine3, new FormControl(''));
    }

    this.addressesSubscription = this.addresses$
      .pipe(filter(() => !this.isFormPrefilled))
      .subscribe((a) => {
        this.addresses = a;
        this.currentAddress = a.find((i) => i.id.toString() == this.addressId);
        this.updateForm(this.currentAddress);
        this.isFormPrefilled = true;
      });
  }

  ngOnDestroy(): void {
    this.addressesSubscription.unsubscribe();
  }

  updateForm(address: Address): void {
    this.addressForm.patchValue(address);
  }

  onSave(): void {
    this.addressForm.markAllAsTouched();
    if (this.addressForm.valid) {
      if (this.addressId === 'new') {
        this.store$.dispatch(
          createAddress({
            address: { skipAddressVerification: false, ...this.addressForm.value },
          }),
        );
      } else {
        this.store$.dispatch(
          updateAddress({
            address: {
              id: this.addressId,
              skipAddressVerification: false,
              ...this.addressForm.value,
            },
          }),
        );
      }
    }
  }

  finalizeAddress = (formGroup: FormGroup): void => {
    const firstName = this.addressForm.get(this.FirstName).value;
    const lastName = this.addressForm.get(this.LastName).value;
    if (this.addressId === 'new') {
      this.store$.dispatch(
        createAddress({
          address: { skipAddressVerification: true, ...formGroup.value, firstName, lastName },
        }),
      );
    } else {
      this.store$.dispatch(
        updateAddress({
          address: {
            id: this.addressId,
            skipAddressVerification: true,
            ...formGroup.value,
            firstName,
            lastName,
          },
        }),
      );
    }
    this.store$.dispatch(resetAddressValidationModal());
  };

  onDismiss = (): void => {
    this.store$.dispatch(resetAddressValidationModal());
  };

  deleteAddress() {
    if (this.addressId !== 'new')
      this.store$.dispatch(deleteAddress({ id: parseInt(this.addressId) }));
  }

  uniqueNameValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const found = this.addresses?.find(
        (a) =>
          a.id.toString() !== this.addressId &&
          a.name.toLowerCase() === control.value.toLowerCase(),
      );

      return found ? { nameExists: { value: control.value } } : null;
    };
  }
}
