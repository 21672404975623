import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { MagazinesResponse } from '@core/dto/magazine.dto';
import { Locale } from '@core/enums/locale';
import { Magazine } from '@core/store/magazine/magazine-state-models';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { keysToCamelCase } from '@shared/utils/object-transform-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MagazineService {
  private culture: string;
  private baseUrl: string = environment.ecommerceBackendUri;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {
    this.culture = getCulture(localeId);
  }

  fetchMagazinePage(start: number, pageSize: number): Observable<Magazine[]> {
    return this.http
      .get<MagazinesResponse>(
        `${this.baseUrl}api/default/magazines`,
        this.getParams(start, pageSize),
      )
      .pipe(map((res) => keysToCamelCase(res.value)));
  }

  private getParams(start: number, pageSize: number): object {
    return {
      params: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        sf_culture: this.culture,
        $expand: 'CoverImage',
        $orderby: 'DateCreated desc',
        $skip: start,
        $top: pageSize,
      },
    };
  }
}
