import { Injectable } from '@angular/core';
import { SearchResultApiPageSize } from '@core/constants/search';
import { SearchFacetQueryParam } from '@core/enums/search.enum';
import { SearchService } from '@core/services/search.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { selectSearchDocumentsCount } from '.';
import { AppState } from '..';
import * as searchActions from './search.actions';

@Injectable()
export class SearchEffects {
  fetchSearchResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.fetchSearchResult),
      concatLatestFrom(() => this.store$.select(selectSearchDocumentsCount)),
      concatMap(([payload, documentCount]) =>
        this.searchService
          .search({
            searchTerm: payload.searchTerm,
            pageNumber: Math.ceil(documentCount / SearchResultApiPageSize) + 1,
            filter: payload.filter,
            displayDiscontinuedProducts: payload.withDiscontinuedProducts,
          })
          .pipe(
            map((res) =>
              searchActions.fetchSearchResultSuccess({
                count: res.count,
                documents: res.documents,
                keepFacets: payload.keepFacets,
                facets: res.facets
                  .filter((f) => f.name !== 'entity_type' && f.items.length)
                  .map((f) => ({
                    ...f,
                    id: SearchFacetQueryParam[f.name],
                  })),
              }),
            ),
            catchError(() => of(searchActions.fetchSearchResultFailure())),
          ),
      ),
    ),
  );

  fetchSearchResultFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(searchActions.fetchSearchResultFailure),
        tap(() => {
          this.toastr.error(
            $localize`Failed to fetch search result`,
            $localize`Search result fetching error`,
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private searchService: SearchService,
    private toastr: ToastrService,
    private store$: Store<AppState>,
  ) {}
}
