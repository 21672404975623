<app-modal [modalConfig]="modalConfig" #modal>
  <div class="text-center">
    <i class="bi bi-exclamation-triangle text-danger"></i>
    <ng-container [ngSwitch]="statusCode">
      <ng-container *ngSwitchCase="StatusCodes.SERVICE_UNAVAILABLE">
        <div class="font-weight-bold">
          {{ MaintenanceErrorMessage }}
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ GenericErrorMessage }}
        <div class="font-weight-bold" *ngFor="let errorMessage of errorMessages">
          {{ errorMessage }}
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="d-flex justify-content-center mt-2">
    <button class="btn btn-primary text-uppercase submit-button w-25" (click)="close()" i18n>
      Ok
    </button>
  </div>
</app-modal>
