import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { MetaProperty } from '@core/enums/meta-property.enum';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { SeoService } from '@core/services/seo.service';
import { AppState } from '@core/store';
import { selectProductDetails } from '@core/store/product';
import { ProductDetailsItem } from '@core/store/product/product-state-models';
import { fetchProductDetails } from '@core/store/product/product.actions';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductResolver implements Resolve<ProductDetailsItem> {
  constructor(private store$: Store<AppState>, private seoService: SeoService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ProductDetailsItem> {
    const sku = route.paramMap.get('sku');
    const viewAs = route.queryParamMap.get(RouterQueryParams.viewAs);

    this.store$.dispatch(fetchProductDetails({ sku, date: viewAs }));

    return this.store$.select(selectProductDetails).pipe(
      filter((details) => !details.isLoading && !!details.item),
      tap((details) => this.updatePageMeta(details.item)),
      select((details) => details.item),
      first(),
    );
  }

  private updatePageMeta(product: ProductDetailsItem) {
    this.seoService.updateMetaTags([
      { property: MetaProperty.url, content: window.location.href },
      { property: MetaProperty.type, content: 'website' },
      { property: MetaProperty.title, content: product.name },
      { property: MetaProperty.image, content: product.primaryImages[0].url },
      { property: MetaProperty.description, content: product.name },
    ]);
  }
}
