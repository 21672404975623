import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { BaseComponent } from '@shared/components/base-component/base-component';

@Component({
  selector: 'app-replicated-site-initializer',
  template: '',
})
export class ReplicatedSiteInitializerComponent extends BaseComponent {
  private shouldConfirm: boolean = true;

  constructor(private router: Router) {
    super();
  }

  @HostListener('window:beforeunload')
  leavingPageWithoutConfirm(): boolean {
    return this.shouldConfirm;
  }

  redirectToEc1(): void {
    this.shouldConfirm = false;
    window.location.href = 'http://www.princesshouse.com/';
  }

  urlContainsCid(url: string): boolean {
    return !!this.router.parseUrl(url).queryParams?.[RouterQueryParams.cid];
  }
}
