/* eslint-disable max-len */
import { CatalogResponse } from '@core/dto/catalog.dto';
import { PhErrorResponse } from '@core/dto/ph-error';
import { ProductResponse } from '@core/dto/product.dto';
import { LoadProductsProps } from '@core/models/load-products.model';
import { createAction, props } from '@ngrx/store';
import { RecipeItem } from '../recipe/recipe-state-models';
import {
  ProductDetailsItem,
  ProductItem,
  ProductQuickView,
  ProductRecommendation,
} from './product-state-models';

export enum ProductActionTypes {
  fetchProducts = '[Product] Fetch Products',
  fetchProductsSuccess = '[Product] Fetch Products Success',
  fetchProductsFailure = '[Product] Fetch Products Failure',
  resetProducts = '[Product] Reset Products',
  fetchProductDetails = '[Product] Fetch Product Details',
  fetchProductDetailsSuccess = '[Product] Fetch Product Details Success',
  fetchProductDetailsFailure = '[Product] Fetch Product Details Failed',
  resetProductDetails = '[Product] Reset Product Details',
  fetchCatalogs = '[Product] Fetch Catalogs',
  fetchCatalogsSuccess = '[Product] Fetch Catalogs Success',
  fetchCatalogsFailure = '[Product] Fetch Catalogs Failure',
  resetCatalogs = '[Product] Reset Catalogs',
  fetchProductQuickView = '[Product] Fetch Product QuickView',
  fetchProductQuickViewSuccess = '[Product] Fetch Product QuickView Success',
  fetchProductQuickViewFailure = '[Product] Fetch Product QuickView Failed',
  resetProductQuickView = '[Product] Reset Product QuickView',
  fetchProductRecommendations = '[Product] Fetch product recommendations',
  fetchProductRecommendationsSuccess = '[Product] Fetch product recommendations success',
  fetchProductRecommendationsFailure = '[Product] Fetch product recommendations failure',
  fetchRelatedRecipes = '[Product] Fetch related recipes',
  fetchRelatedRecipesSuccess = '[Product] Fetch related recipes success',
  fetchRelatedRecipesFailure = '[Product] Fetch related recipes failure',
  fetchMoreFromCollection = '[Product] Fetch more from collection',
  fetchMoreFromCollectionSuccess = '[Product] Fetch more from collection success',
  fetchMoreFromCollectionFailure = '[Product] Fetch more from collection failure',
  fetchEcommerceCustomerSpecialOffers = '[Product] Fetch ecommerce customer special offers',
  fetchEcommerceCustomerSpecialOffersSuccess = '[Product] Fetch ecommerce customer special offers success',
  fetchEcommerceCustomerSpecialOffersFailure = '[Product] Fetch ecommerce customer special offers failure',
  fetchHealthySavingsCustomerSpecialOffers = '[Product] Fetch healthy savings customer special offers',
  fetchHealthySavingsCustomerSpecialOffersSuccess = '[Product] Fetch healthy savings customer special offers success',
  fetchHealthySavingsCustomerSpecialOffersFailure = '[Product] Fetch healthy savings customer special offers failure',
  fetchCollectAndSaveCustomerSpecialOffers = '[Product] Fetch collect and save customer special offers',
  fetchCollectAndSaveCustomerSpecialOffersSuccess = '[Product] Fetch collect and save customer special offers success',
  fetchCollectAndSaveCustomerSpecialOffersFailure = '[Product] Fetch collect and save customer special offers failure',
  fetchRegularCustomerSpecialOffers = '[Product] Fetch regular customer special offers',
  fetchRegularCustomerSpecialOffersSuccess = '[Product] Fetch regular customer special offers success',
  fetchRegularCustomerSpecialOffersFailure = '[Product] Fetch regular customer special offers failure',
  fetchProductVideoDetails = '[Product] Fetch product video details',
  fetchProductVideoDetailsSuccess = '[Product] Fetch product video details success',
  fetchProductVideoDetailsFailure = '[Product] Fetch product video details failure',
  fetchProductQuickViewVideoDetails = '[Product] Fetch product quickview video details',
  fetchProductQuickViewVideoDetailsSuccess = '[Product] Fetch product quickview video details success',
  fetchProductQuickViewVideoDetailsFailure = '[Product] Fetch product quickview video details failure',
}

export const fetchProducts = createAction(
  ProductActionTypes.fetchProducts,
  props<LoadProductsProps>(),
);

export const fetchProductsSuccess = createAction(
  ProductActionTypes.fetchProductsSuccess,
  props<{
    payload: ProductResponse[];
    catalogName: string;
    pageSize: number;
  }>(),
);

export const fetchProductsFailure = createAction(
  ProductActionTypes.fetchProductsFailure,
  props<{ error: PhErrorResponse }>(),
);

export const resetProducts = createAction(ProductActionTypes.resetProducts);

export const fetchProductDetails = createAction(
  ProductActionTypes.fetchProductDetails,
  props<{ sku: string; date: string }>(),
);

export const fetchProductDetailsSuccess = createAction(
  ProductActionTypes.fetchProductDetailsSuccess,
  props<{ product: ProductDetailsItem }>(),
);

export const fetchProductDetailsFailure = createAction(
  ProductActionTypes.fetchProductDetailsFailure,
  props<{ error: PhErrorResponse }>(),
);

export const resetProductDetails = createAction(ProductActionTypes.resetProductDetails);

export const fetchCatalogs = createAction(
  ProductActionTypes.fetchCatalogs,
  props<{
    catalogType: string;
    pageNumber: number;
    pageSize: number;
    shouldResetStore?: boolean;
  }>(),
);

export const fetchCatalogsSuccess = createAction(
  ProductActionTypes.fetchCatalogsSuccess,
  props<{
    payload: CatalogResponse[];
    pageSize: number;
  }>(),
);

export const fetchCatalogsFailure = createAction(
  ProductActionTypes.fetchCatalogsFailure,
  props<{ error: PhErrorResponse }>(),
);

export const resetCatalogs = createAction(ProductActionTypes.resetCatalogs);

/**
 * Product quickview
 */
export const fetchProductQuickView = createAction(
  ProductActionTypes.fetchProductQuickView,
  props<{ sku: string }>(),
);
export const fetchProductQuickViewSuccess = createAction(
  ProductActionTypes.fetchProductQuickViewSuccess,
  props<{ product: ProductQuickView }>(),
);
export const fetchProductQuickViewFailure = createAction(
  ProductActionTypes.fetchProductQuickViewFailure,
  props<{ error: PhErrorResponse }>(),
);
export const resetProductQuickView = createAction(ProductActionTypes.resetProductQuickView);

/**
 * Fetch product recommendations
 */
export const fetchProductRecommendations = createAction(
  ProductActionTypes.fetchProductRecommendations,
  props<{ sku: string; date: string }>(),
);

export const fetchProductRecommendationsSuccess = createAction(
  ProductActionTypes.fetchProductRecommendationsSuccess,
  props<{ recommendations: ProductRecommendation[] }>(),
);
export const fetchProductRecommendationsFailure = createAction(
  ProductActionTypes.fetchProductRecommendationsFailure,
);

/**
 * Fetch related recipes
 */
export const fetchRelatedRecipes = createAction(
  ProductActionTypes.fetchRelatedRecipes,
  props<{ sku: string; date: string }>(),
);
export const fetchRelatedRecipesSuccess = createAction(
  ProductActionTypes.fetchRelatedRecipesSuccess,
  props<{ recipes: RecipeItem[] }>(),
);
export const fetchRelatedRecipesFailure = createAction(
  ProductActionTypes.fetchRelatedRecipesFailure,
);

/**
 * Fetch more product from this collection
 */
export const fetchMoreFromCollection = createAction(
  ProductActionTypes.fetchMoreFromCollection,
  props<{ catalogUrlName: string; sku: string; date: string }>(),
);
export const fetchMoreFromCollectionSuccess = createAction(
  ProductActionTypes.fetchMoreFromCollectionSuccess,
  props<{ moreFromCollection: ProductRecommendation[] }>(),
);
export const fetchMoreFromCollectionFailure = createAction(
  ProductActionTypes.fetchMoreFromCollectionFailure,
);

export const fetchEcommerceCustomerSpecialOffers = createAction(
  ProductActionTypes.fetchEcommerceCustomerSpecialOffers,
  props<{ viewAs?: string }>(),
);
export const fetchEcommerceCustomerSpecialOffersSuccess = createAction(
  ProductActionTypes.fetchEcommerceCustomerSpecialOffersSuccess,
  props<{ payload: ProductItem[] }>(),
);
export const fetchEcommerceCustomerSpecialOffersFailure = createAction(
  ProductActionTypes.fetchEcommerceCustomerSpecialOffersFailure,
  props<{ error: PhErrorResponse }>(),
);

export const fetchHealthySavingsCustomerSpecialOffers = createAction(
  ProductActionTypes.fetchHealthySavingsCustomerSpecialOffers,
  props<{ viewAs?: string }>(),
);
export const fetchHealthySavingsCustomerSpecialOffersSuccess = createAction(
  ProductActionTypes.fetchHealthySavingsCustomerSpecialOffersSuccess,
  props<{ payload: ProductItem[] }>(),
);
export const fetchHealthySavingsCustomerSpecialOffersFailure = createAction(
  ProductActionTypes.fetchHealthySavingsCustomerSpecialOffersFailure,
  props<{ error: PhErrorResponse }>(),
);

export const fetchCollectAndSaveCustomerSpecialOffers = createAction(
  ProductActionTypes.fetchCollectAndSaveCustomerSpecialOffers,
  props<{ viewAs?: string }>(),
);
export const fetchCollectAndSaveCustomerSpecialOffersSuccess = createAction(
  ProductActionTypes.fetchCollectAndSaveCustomerSpecialOffersSuccess,
  props<{ payload: ProductItem[] }>(),
);
export const fetchCollectAndSaveCustomerSpecialOffersFailure = createAction(
  ProductActionTypes.fetchCollectAndSaveCustomerSpecialOffersFailure,
  props<{ error: PhErrorResponse }>(),
);

export const fetchRegularCustomerSpecialOffers = createAction(
  ProductActionTypes.fetchRegularCustomerSpecialOffers,
  props<{ viewAs?: string }>(),
);
export const fetchRegularCustomerSpecialOffersSuccess = createAction(
  ProductActionTypes.fetchRegularCustomerSpecialOffersSuccess,
  props<{ payload: ProductItem[] }>(),
);
export const fetchRegularCustomerSpecialOffersFailure = createAction(
  ProductActionTypes.fetchRegularCustomerSpecialOffersFailure,
  props<{ error: PhErrorResponse }>(),
);

export const fetchProductVideoDetails = createAction(
  ProductActionTypes.fetchProductVideoDetails,
  props<{ vimeoId: string }>(),
);
export const fetchProductVideoDetailsSuccess = createAction(
  ProductActionTypes.fetchProductVideoDetailsSuccess,
  props<{ vimeoId: string; thumbnail: string }>(),
);
export const fetchProductVideoDetailsFailure = createAction(
  ProductActionTypes.fetchProductVideoDetailsFailure,
);

export const fetchProductQuickViewVideoDetails = createAction(
  ProductActionTypes.fetchProductQuickViewVideoDetails,
  props<{ vimeoId: string }>(),
);
export const fetchProductQuickViewVideoDetailsSuccess = createAction(
  ProductActionTypes.fetchProductQuickViewVideoDetailsSuccess,
  props<{ vimeoId: string; thumbnail: string }>(),
);
export const fetchProductQuickViewVideoDetailsFailure = createAction(
  ProductActionTypes.fetchProductQuickViewVideoDetailsFailure,
);
