/* eslint-disable @typescript-eslint/naming-convention, max-len */
export const phErrorMessages = {
  ProductNotFoundException: $localize`Product not found`,
  ProductNotInCollectionException: $localize`Product is not in a collection`,
  StateNotFoundException: $localize`State not found`,
  PrimaryImageNotFoundException: $localize`Primary image not found`,
  PriceNotFoundException: $localize`Price not found`,
  OrderLineNotFoundException: $localize`OrderLine not found.`,
  MismatchCustomerException: $localize`Mismatching customers. Customer of the address is not the authorized user.`,
  DefaultProductRelationTypeNotFoundException: $localize`Default product relation type not found`,
  CustomerNotFoundException: $localize`Customer not found`,
  CustomerAlreadyExistsException: $localize`Email address is already taken`,
  CountryNotFoundException: $localize`Country not found`,
  CategoryNotFoundException: $localize`Category not found`,
  AddressNotFoundException: $localize`Address not found`,
  AddressNameNotUniqueException: $localize`Name is not unique`,
  WishListOrderStatusNotFoundException: $localize`WishList Order Status not found`,
  PasswordFailedToChangeException: $localize`Failed to change password`,
  ValidationException: $localize`The provided data is not valid`,
  OrderSubmitTransactionFailedException: $localize`Please check your credit card details and resubmit your order`,
  OrderSubmitCvvDoesNotMatchException: $localize`Please check your credit card details and resubmit your order`,
  OrderSubmitZipDoesNotMatchException: $localize`Please check your credit card details and resubmit your order`,
  PaymentTransactionFailedException: $localize`Please check your credit card details and resubmit your order`,
  PaymentTransactionFailedNoneException: $localize`Please check your credit card details and resubmit your order`,
  PaymentTransactionFailedSoftException: $localize`Soft declined credit card!`,
  PaymentTransactionFailedHardException: $localize`Hard declined credit card!`,
  PaymentTransactionFailedUnrecognizedException: $localize`Unrecognized credit card issue!`,
  PaymentMethodCreationFailedException: $localize`Hard declined credit card!`,
  PipelineException: $localize`Unexpected error happened during the order placement. Please try again later or contact Princess House customer service!`,
  ForbiddenZipCodeException: $localize`Princess House does not ship to this zip code at this time. Please enter a new shipping address with a different zip code.`,
};
