<div *ngIf="congratulationType$ | async as congratulationType">
  <div class="container">
    <ng-container *ngIf="userInfo$ | async as userInfo">
      <h1 i18n>CONGRATULATIONS {{ userInfo.firstName }}!</h1>
    </ng-container>

    <ng-container [ngSwitch]="congratulationType">
      <ng-container *ngSwitchCase="CongratulationTypes.Normal">
        <h2 i18n>You are now an independent Princess House Consultant</h2>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <h2 i18n>You will be an independent Princess House Consultant soon.</h2>
      </ng-container>
    </ng-container>

    <ng-container
      [ngSwitch]="congratulationType"
      *ngIf="submittedConsultantInfo$ | async as submittedConsultantInfo"
    >
      <ng-container *ngSwitchCase="CongratulationTypes.Normal">
        <section class="consultant-box">
          <div>
            <span class="text-double-dot" i18n>Your Consultant ID Number is</span>
            <span class="value">{{ submittedConsultantInfo.consultantBeeNumber }}</span>
          </div>

          <div i18n class="text-medium">
            This number is also your username to login to our
            <a [href]="consultantCornerUrl">Consultant’s Corner website</a>.
          </div>
        </section>
      </ng-container>

      <ng-container *ngSwitchCase="CongratulationTypes.Cash">
        <section class="cash-voucher-box" *ngIf="voucherBarcodeUrls$ | async as urls">
          <div class="column-mobile">
            <span class="text-double-dot" i18n>Your Cash Voucher</span>
            <span class="value reset-margin-mobile" *ngIf="!!urls.length; else loading">
              <a class="pointer" (click)="openVoucherUrls(urls)" i18n> Download </a>
            </span>
            <ng-template #loading>
              <div class="spinner spinner-border spinner-border-sm" role="status">
                <span class="sr-only" i18n>Loading...</span>
              </div>
            </ng-template>
          </div>
        </section>

        <!-- TODO: StartNowApp - set href below -->
        <div i18n class="text-medium expiration-space">
          Your registration will be ready after you paid the voucher in any stores. The voucher
          <a [href]="">expires in {{ VoucherDeadline }} days</a>.
        </div>

        <section class="cash-consultant-box">
          <ng-container *ngTemplateOutlet="notIssuedYet"></ng-container>
        </section>
      </ng-container>

      <ng-container *ngSwitchCase="CongratulationTypes.BackOfficeIssue">
        <section class="consultant-box">
          <ng-container *ngTemplateOutlet="notIssuedYet"></ng-container>
        </section>
        <ng-container *ngIf="(isMobile$ | async) === true">
          <!-- TODO: StartNowApp - set href below -->
          <p i18n class="text-medium text-medium-mobile">
            Our team will be in touch with you
            <a [href]="">in 72 hours using your previously entered contact information</a>.
          </p>
        </ng-container>
      </ng-container>
    </ng-container>

    <section
      *ngIf="submittedConsultantInfo$ | async as submittedConsultantInfo"
      class="recruiter-box"
      [class.top-space]="congratulationType !== CongratulationTypes.Normal"
    >
      <div class="column-mobile" *ngIf="selectedConsultant$ | async as consultant">
        <span class="text-double-dot" i18n>Your Recruiter is</span>
        <span class="value reset-margin-mobile">
          {{ consultant.fullName }}
        </span>
      </div>
    </section>

    <ng-container *ngIf="congratulationType === CongratulationTypes.Normal">
      <p i18n>
        You will also be able to login to
        <a [href]="consultantCornerUrl">Consultant’s Corner</a> using your email address or mobile
        phone number.
      </p>

      <button
        (click)="onLoginToConsultantsCornerClick()"
        class="btn btn-primary text-uppercase"
        [ngClass]="{
          'btn-block': (isMobile$ | async) === true,
          'w-335': (isMobile$ | async) === false
        }"
        i18n
      >
        LOGIN TO CONSULTANT’S CORNER
      </button>
    </ng-container>

    <h2 i18n class="mt-5">Your Application Details</h2>
    <app-congratulation-summary></app-congratulation-summary>
  </div>

  <ng-template #notIssuedYet>
    <div>
      <span class="text-double-dot" i18n>Your Consultant ID Number is</span>
      <span class="value" i18n>Not issued yet</span>
    </div>

    <ng-container [ngSwitch]="congratulationType">
      <ng-container *ngSwitchCase="CongratulationTypes.Normal"> </ng-container>
      <ng-container *ngSwitchCase="CongratulationTypes.Cash">
        <div i18n class="text-medium">
          This is is the explanation of the new applications about Voucher payment and Consultant
          registration
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="CongratulationTypes.BackOfficeIssue">
        <!-- TODO: StartNowApp - set href below -->
        <ng-container *ngIf="(isMobile$ | async) === false">
          <div i18n class="text-medium">
            Our team will be in touch with you
            <a [href]="">in 72 hours using your previously entered contact information</a>.
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
