<div class="container">
  <div
    class="my-3 d-lt-none"
    *ngIf="activePage !== pages.PaymentConfirmation && isCollectRiskDataEnabled"
  >
    <app-navigator
      [navSteps]="navigationSteps"
      [activeId]="activePage"
      [disableAllInLastStep]="false"
      [canMoveForward]="false"
      (activeIdChange)="changePage($event)"
    >
    </app-navigator>
  </div>
  <div
    class="row my-3 d-md-none"
    *ngIf="activePage !== pages.PaymentConfirmation && isCollectRiskDataEnabled"
  >
    <div class="col-auto pr-0"></div>
    <div class="col-5 font-weight-bold d-flex align-items-center border-y-1 pl-0">
      <span>{{ title }}</span>
    </div>
    <div class="col font-size-tiny d-flex align-items-center justify-content-end border-y-1 pl-0">
      <span *ngIf="nextTitle" i18n>Next: {{ nextTitle }}</span>
    </div>
    <div class="col-2 d-flex align-items-center justify-content-end">
      <app-circular-progress
        [numberOfSteps]="navigationSteps.length"
        [currentStep]="activePage"
      ></app-circular-progress>
    </div>
  </div>
  <h2 i18n>Party order payment</h2>
  <ng-container [ngSwitch]="activePage">
    <ng-container *ngSwitchCase="pages.SearchPage">
      <app-party-order-payment-search
        (nextPage)="changePage($event)"
      ></app-party-order-payment-search>
    </ng-container>
    <ng-container *ngSwitchCase="pages.AddressPage">
      <app-party-order-payment-address
        (previousPage)="changePage($event)"
        (nextPage)="changePage($event)"
        [(riskData)]="riskData"
      ></app-party-order-payment-address>
    </ng-container>
    <ng-container *ngSwitchCase="pages.InfoPage">
      <app-party-order-payment-info
        (previousPage)="changePage($event)"
        (nextPage)="changePage($event)"
        [riskData]="riskData"
      ></app-party-order-payment-info>
    </ng-container>
    <ng-container *ngSwitchCase="pages.PaymentConfirmation">
      <app-party-order-payment-confirmation
        (backToSearch)="changePage(pages.SearchPage)"
        (reset)="reset()"
      ></app-party-order-payment-confirmation>
    </ng-container>
  </ng-container>
</div>
