import { ContactUsState, SendingContactUsMessage } from './contact-us-state-models';
import { produce } from 'immer';
import { ContactUsActionType } from './contact-us.actions';

export const initialState: ContactUsState = {
  sendingMessage: SendingContactUsMessage.notReady,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case ContactUsActionType.sendEmail:
      draft.sendingMessage = SendingContactUsMessage.inProgress;
      return;

    case ContactUsActionType.sendEmailSuccess:
      draft.sendingMessage = SendingContactUsMessage.successful;
      return;

    case ContactUsActionType.sendEmailFailure:
      draft.sendingMessage = SendingContactUsMessage.failure;
      return;

    case ContactUsActionType.resetContactUsState:
      return initialState;

    default:
      return;
  }
}, initialState);
