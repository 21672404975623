<div class="container">
  <ng-container *ngIf="currentConsultant$ | async as consultant">
    <app-breadcrumb-wrapper class="w-100">
      <li class="breadcrumb-item"
        ><a i18n [routerLink]="'/' + myStoryPageRoute">{{ consultant.displayName }}</a></li
      >
      <li class="breadcrumb-item active" i18n>My open parties</li>
    </app-breadcrumb-wrapper>
    <div class="d-flex justify-content-center" *ngIf="partiesLoading$ | async">
      <app-ph-spinner></app-ph-spinner>
    </div>

    <div
      *ngIf="!consultant.parties?.length && (partiesLoading$ | async) === false"
      class="font-size-bigger text-center"
      >No party found!</div
    >
    <div class="parties-wrapper">
      <div class="mx-5" *ngFor="let party of consultant.parties">
        <div class="d-flex align-items-center border-bottom py-4 flex-column flex-md-row">
          <div
            class="
              flex-grow-1
              mx-4
              mb-1 mb-md-0
              font-size-bigger
              text-uppercase
              d-flex
              align-items-center
              justify-content-center justify-content-md-start
            "
          >
            {{ party.partyName }}
          </div>
          <button
            class="btn btn-primary text-uppercase mx-4"
            (click)="selectParty(party)"
            type="button"
            i18n
            >Place an order</button
          >
        </div>
      </div>
    </div>
  </ng-container>
</div>
