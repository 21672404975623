<h2 class="mt-3 text-title" i18n>We want to connect with you</h2>

<div class="my-3">
  <h6 class="text-subtitle" i18n>
    Tell us how best to share information with you. (We will not send you spam emails, we promise.)
  </h6>
</div>

<form
  [formGroup]="connectWithYouFormGroup"
  *ngIf="contactUniquenessCheck$ | async as contactUniquenessCheck"
>
  <div class="row mb-3">
    <div class="col-12 col-md-6 mb-1">
      <app-text-field-input
        [identifier]="Email"
        label="Email"
        i18n-label
        [formErrorMessages]="ErrorMessages"
        placeholder="Your email address"
        i18n-placeholder
        [formControlName]="Email"
        [labelStyle]="FormLabelStyle"
        data-[isDataPrivate]="true"
      >
      </app-text-field-input>
    </div>
    <div class="col-12 col-md-6 mb-1">
      <app-text-field-input
        [identifier]="EmailConfirm"
        label="Confirm email"
        i18n-label
        [formErrorMessages]="ErrorMessages"
        placeholder="Confirm your email address"
        i18n-placeholder
        [groupValidationErrorType]="FormErrorTypes.emailConfirm"
        [formControlName]="EmailConfirm"
        [invalidGroupErrorMessage]="emailConfirmErrorMessage"
        [labelStyle]="FormLabelStyle"
        data-[isDataPrivate]="true"
      >
      </app-text-field-input>
    </div>

    <div
      *ngIf="contactUniquenessCheck.emailIsValid === false && isSubmitted"
      class="col-12 validation-error-message"
    >
      <span>
        {{ EmailValidationText }}
      </span>
    </div>
  </div>

  <div class="mb-5">
    <div class="row">
      <div class="col-12 col-md-4 mb-3">
        <app-text-field-input
          [identifier]="CellPhoneNumber"
          label="Cell phone number"
          i18n-label
          [formErrorMessages]="ErrorMessages"
          placeholder="(012) 345-6789"
          [formControlName]="CellPhoneNumber"
          [labelStyle]="FormLabelStyle"
          type="tel"
          data-[isDataPrivate]="true"
        >
        </app-text-field-input>
      </div>

      <div class="col-12 col-md-4 mb-3">
        <app-text-field-input
          [identifier]="HomeNumber"
          label="Home number (optional)"
          i18n-label
          [formErrorMessages]="ErrorMessages"
          placeholder="(012) 345-6789"
          [formControlName]="HomeNumber"
          [labelStyle]="FormLabelStyle"
          type="tel"
          data-[isDataPrivate]="true"
        >
        </app-text-field-input>
      </div>
    </div>

    <div class="row">
      <div class="col-12 validation-error-message">
        <div
          *ngIf="
            contactUniquenessCheck.phoneValidity[PhoneNumberType.Mobile] === false && isSubmitted
          "
        >
          <span>
            {{ CellPhoneNumberValidationText }}
          </span>
        </div>
        <div
          *ngIf="
            contactUniquenessCheck?.phoneValidity[PhoneNumberType.Home] === false && isSubmitted
          "
        >
          <span>
            {{ HomeNumberValidationText }}
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="contactStep$ | async as contactStep">
      <div
        *ngIf="
          contactUniquenessCheck?.phoneValidity[PhoneNumberType.Home] === null ||
          contactUniquenessCheck.phoneValidity[PhoneNumberType.Mobile] === null ||
          contactUniquenessCheck.emailValidity === null
        "
        class="validation-error-message"
      >
        <div
          *ngIf="
            (!!contactStep.statusCode && contactStep.statusCode !== StatusCodes.OK) ||
            !!contactStep.errors.length
          "
        >
          <app-sna-http-status-errors
            [statusCode]="contactStep.statusCode"
          ></app-sna-http-status-errors>
        </div>
      </div>
    </div>
  </div>

  <p class="font-italic mb-5" i18n>You will receive text notifications about your orders</p>

  <div class="row mb-5">
    <div class="col">
      <div *ngIf="isUsaEnv" class="form-check custom-checkbox">
        <input
          class="form-check-input custom-control-input"
          type="checkbox"
          [formControlName]="SMS"
          id="sms"
        />
        <label i18n class="form-check-label text-uppercase custom-control-label" for="sms">
          I allow princess house to contact me <strong>via SMS</strong>
        </label>
      </div>
      <div *ngIf="!isUsaEnv" class="form-check custom-checkbox">
        <input
          class="form-check-input custom-control-input"
          type="checkbox"
          [formControlName]="Whatsapp"
          id="whatsapp"
        />
        <label i18n class="form-check-label text-uppercase custom-control-label" for="whatsapp">
          I allow princess house to contact me <strong>via whatsapp</strong>
        </label>
      </div>
    </div>
  </div>
</form>
