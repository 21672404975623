import { HttpErrorResponse } from '@angular/common/http';
import { BlogResponse } from '@core/dto/blog.dto';
import { LoadBlogsProps } from '@core/models/load-blogs.model';
import { createAction, props } from '@ngrx/store';
import { BlogCategory, BlogDetailsItem } from './blog-state-models';

export enum BlogActionTypes {
  fetchBlogs = '[Blog] Fetch Blogs',
  fetchBlogsSuccess = '[Blog] Fetch Blogs success',
  fetchBlogsFailure = '[Blog] Fetch Blogs failure',
  fetchBlogCategories = '[Blog] Fetch Blog categories',
  fetchBlogCategoriesSuccess = '[Blog] Fetch Blog categories success',
  fetchBlogCategoriesFailure = '[Blog] Fetch Blog categories failure',
  resetBlogs = '[Blog] reset Blogs',
  fetchBlogDetails = '[Blog] Fetch Blog details',
  fetchBlogDetailsSuccess = '[Blog] Fetch Blog details success',
  fetchBlogDetailsFailure = '[Blog] Fetch Blog details failure',
  resetBlogDetails = '[Blog] Reset Blog details',
}

export const fetchBlogs = createAction(BlogActionTypes.fetchBlogs, props<LoadBlogsProps>());

export const fetchBlogsSuccess = createAction(
  BlogActionTypes.fetchBlogsSuccess,
  props<{ items: BlogResponse[]; pageSize: number; isFirstPage?: boolean }>(),
);

export const fetchBlogsFailure = createAction(
  BlogActionTypes.fetchBlogsFailure,
  props<{ error: HttpErrorResponse }>(),
);

export const fetchBlogCategories = createAction(BlogActionTypes.fetchBlogCategories);

export const fetchBlogCategoriesSuccess = createAction(
  BlogActionTypes.fetchBlogCategoriesSuccess,
  props<{ blogCategories: BlogCategory[] }>(),
);

export const fetchBlogCategoriesFailure = createAction(
  BlogActionTypes.fetchBlogCategoriesFailure,
  props<{ error: HttpErrorResponse }>(),
);

export const resetBlogs = createAction(BlogActionTypes.resetBlogs);

/**
 * Blog details
 */
export const fetchBlogDetails = createAction(
  BlogActionTypes.fetchBlogDetails,
  props<{ url: string }>(),
);

export const fetchBlogDetailsSuccess = createAction(
  BlogActionTypes.fetchBlogDetailsSuccess,
  props<{ item: BlogDetailsItem }>(),
);

export const fetchBlogDetailsFailure = createAction(BlogActionTypes.fetchBlogDetailsFailure);

export const resetBlogDetails = createAction(BlogActionTypes.resetBlogDetails);
