import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@core/store';
import { selectWishlist } from '@core/store/wishlist';
import { fetchWishlist } from '@core/store/wishlist/wishlist.actions';
import { Store } from '@ngrx/store';
import { StartNowAppRoutes } from 'app/modules/start-now-app/start-now-app-routing.module';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-wishlist-initializer',
  template: '',
})
export class WishlistInitializerComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    if (window.location.pathname == '/' + StartNowAppRoutes.startNowApp) {
      return;
    }

    this.subscription.add(
      this.store$
        .select(selectWishlist)
        .pipe(
          take(1),
          filter((wishList) => !wishList.length),
        )
        .subscribe(() => this.store$.dispatch(fetchWishlist())),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
