<div class="mb-3 d-flex flex-column align-items-center">
  <div i18n>You receive the voucher the following email address:</div>
  <div class="mt-2 px-4 w-100">
    <form [formGroup]="form">
      <app-text-field-input
        [identifier]="emailKey"
        type="email"
        placeholder="Your email address goes here"
        i18n-placeholder
        validationErrorMessage="Please enter a valid email address"
        i18n-validationErrorMessage
        [formControlName]="emailKey"
        [isDataPrivate]="true"
      >
      </app-text-field-input>
      <div class="validation-error-message mt-1" *ngIf="prevalidateErrorMessage">
        {{ prevalidateErrorMessage }}
      </div>
    </form>
  </div>
</div>
<app-voucher-instructions></app-voucher-instructions>
