import { ShareInEmailRequest } from '@core/dto/share-in-email.dto';
import { createAction, props } from '@ngrx/store';

export enum ShareActionTypes {
  shareInEmail = '[Share] Share in email',
  shareInEmailSuccess = '[Share] Share in email success',
  shareInEmailFailure = '[Share] Share in email failure',
}

export const shareInEmail = createAction(
  ShareActionTypes.shareInEmail,
  props<{ payload: ShareInEmailRequest }>(),
);
export const shareInEmailSuccess = createAction(ShareActionTypes.shareInEmailSuccess);
export const shareInEmailFailure = createAction(ShareActionTypes.shareInEmailFailure);
