import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductType } from '@core/enums/product-type.enum';
import { AddToCartConfirmModelData } from '@core/store/cart/cart-state-models';
import {
  ProductDetailsItem,
  ProductItem,
  ProductQuickView,
} from '@core/store/product/product-state-models';

@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss'],
})
export class ProductPriceComponent implements OnChanges {
  @Input() compact: boolean = false;
  @Input() product: ProductDetailsItem | ProductItem | ProductQuickView | AddToCartConfirmModelData;

  readonly ProductType = ProductType;

  isPriceVisible: boolean = true;
  isDiscountedPriceVisible: boolean;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    const product = changes.product?.currentValue;
    if (product) {
      this.setPriceVisibility(product);
      this.setDiscountedPriceVisibility(product);
    }
  }

  private setPriceVisibility(
    product: ProductDetailsItem | ProductItem | ProductQuickView | AddToCartConfirmModelData,
  ) {
    if (!product.price) {
      this.isPriceVisible = false;
    }
  }

  private setDiscountedPriceVisibility(
    product: ProductDetailsItem | ProductItem | ProductQuickView | AddToCartConfirmModelData,
  ) {
    switch (product.type) {
      case ProductType.CustomerSpecial:
      case ProductType.RegularCustomerSpecial:
      case ProductType.HealthySavingsSpecial:
      case ProductType.SurpriseBuy:
      case ProductType.CollectAndSave:
      case ProductType.ECommerceCustomerSpecial:
        this.isDiscountedPriceVisible = true;
        break;

      default:
        this.isDiscountedPriceVisible = false;
        break;
    }

    if (!product.discountedPrice) {
      this.isDiscountedPriceVisible = false;
    }
  }
}
