<ng-container *ngIf="slides$ | async as slides">
  <div *ngIf="slides.length" class="ph-keen-slider text-center">
    <h4 *ngIf="title" class="mb-2" i18n>{{ title }}</h4>
    <div class="keen-slider keen-slider-container" #sliderRef>
      <div *ngFor="let slide of slides; trackBy: trackBy; let i = index" class="keen-slider__slide">
        <img
          phImage
          class="carousel-img"
          [attr.tabindex]="showDots() ? '' : 0"
          [images]="slide.images"
          [thumbnailDesktop]="thumbnail.carouselDesktop"
          [thumbnailMobile]="thumbnail.carouselMobile"
          (click)="navigateTo(slide.targetUrl)"
          (keydown.enter)="navigateTo(slide.targetUrl)"
          (keydown.space)="navigateTo(slide.targetUrl)"
          (focus)="slider.moveToIdx(i)"
        />
      </div>
      <ng-container *ngIf="showArrows()">
        <i
          role="button"
          aria-label="Move image carousel up"
          i18n-aria-label
          (click)="slider.prev()"
          *ngIf="currentSlide !== 0"
          class="arrow-x arrow--left bi bi-chevron-left"
        >
        </i>
        <i
          role="button"
          aria-label="Move image carousel down"
          i18n-aria-label
          (click)="slider.next()"
          *ngIf="
            slider?.track.details.slides.length > 3 &&
            slider?.track.details.slides.length - 3 !== currentSlide
          "
          class="arrow-x arrow--right bi bi-chevron-right"
        >
        </i>
      </ng-container>
    </div>
    <div *ngIf="showDots()">
      <button
        (click)="slider.moveToIdx(i)"
        *ngFor="let slide of dotHelper; let i = index"
        [class]="'dot ' + (i === currentSlide ? 'active' : '')"
        [attr.aria-current]="i === currentSlide ? 'true' : ''"
      >
        <span class="sr-only" i18n>Go to slide {{ i + 1 }}</span>
      </button>
    </div>
  </div>
</ng-container>
