import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoModalComponent {
  @Input() title: string;
  @Input() actionLabel: string = $localize`Close`;
  @Input() size: 'sm' | 'lg' | 'xl';
  @Input() backdrop: boolean | 'static' = 'static';
  @Input() showX: boolean = false;

  @Output() actionClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('modal') private modalContent: TemplateRef<ConfirmModalComponent>;

  private payload: any;
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  open(payload: any = null) {
    this.payload = payload;
    this.modalRef = this.modalService.open(this.modalContent, {
      centered: true,
      size: this.size,
      backdrop: this.backdrop,
    });
  }

  onAction() {
    this.actionClick.emit(this.payload);
    this.close();
  }

  close() {
    this.modalRef.dismiss();
  }
}
