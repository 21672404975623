import { Component, Input } from '@angular/core';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { ImageDetails } from '@core/models/image.model';
import { environment } from '@env';
import { UserUtilService } from '@shared/utils/user-util.service';

@Component({
  selector: 'app-product-list-image',
  templateUrl: './product-list-image.component.html',
  styleUrls: ['./product-list-image.component.scss'],
})
export class ProductListImageComponent {
  @Input()
  sku: string;

  @Input()
  images: ImageDetails[];

  @Input()
  navigateTo: string;

  @Input()
  isWishlisted: boolean;

  @Input()
  onOpenQuickView: () => void;

  @Input()
  toggleWish: (sku: string) => void;

  @Input()
  alt: string;

  thumbnail = Thumbnail;
  feature = environment.feature;

  constructor(public userUtilService: UserUtilService) {}
}
