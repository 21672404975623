import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CartState } from './cart-state-models';

export const cartStateFeatureKey = 'cartState';

export const selectCartStateFeature = createFeatureSelector<CartState>(cartStateFeatureKey);

export const selectAddToCartConfirmModal = createSelector(
  selectCartStateFeature,
  (state: CartState) => (state ? state.modals.addToCartConfirm : null),
);

export const selectCart = createSelector(selectCartStateFeature, (state: CartState) =>
  state ? state.cart : null,
);

export const selectOrderLineById = (id: number) =>
  createSelector(selectCartStateFeature, (state: CartState) =>
    state ? state.cart.orderLines.find((o) => o.orderLineId === id) : null,
  );

export const selectLoading = createSelector(selectCartStateFeature, (state) =>
  state ? state.loading : false,
);

export const selectAddSurpriseBuyLoading = createSelector(selectCartStateFeature, (state) =>
  state ? state.addSurpriseBuyLoading : false,
);
