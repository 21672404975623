import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { AppState } from '@core/store';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import { resetCurrentConsultant } from '@core/store/consultant/consultant.actions';
import {
  selectConsultantFinderCriteria,
  selectConsultantFinderStep,
  selectConsultantStepsSkipped,
  selectRegistrationCodeStepSkipped,
  selectStepProcessing,
} from '@core/store/start-now-app';
import {
  findConsultantByAreaNextPage,
  findConsultantByZipCodeNextPage,
  resetFindConsultant,
  resetFirstConsultants,
  resetRegistrationCodeStepSkipped,
  resetSelectedConsultant,
  updateSelectedConsultant,
} from '@core/store/start-now-app/start-now-app.actions';
import { Store, select } from '@ngrx/store';
import { getFormValidationErrorMessage, validInput } from '@shared/utils/validation.utils';
import { StartNowAppStep } from 'app/modules/start-now-app/enums/start-now-app-step.enum';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

interface Titles {
  mainTitle: string;
  subTitle: string;
  errorTitle: { required: string };
}

@Component({
  selector: 'app-confirm-consultant-step',
  templateUrl: './confirm-consultant-step.component.html',
  styleUrls: ['./confirm-consultant-step.component.scss'],
})
export class ConfirmConsultantStepComponent extends StartNowStepBaseComponent implements OnInit {
  @Output() goToOtherStep: EventEmitter<number> = new EventEmitter<number>();
  readonly pageSize = 3;
  readonly Confirmation: string = 'confirmation';
  readonly listOfTitles: Titles[] = [
    {
      mainTitle: $localize`Success starts here`,
      subTitle: $localize`Congratulations on your decision to join us!`,
      errorTitle: {
        required: $localize`Please confirm your recruiter!`,
      },
    },
    {
      mainTitle: $localize`Your connections matters!`,
      subTitle: $localize`Please choose your recruiter`,
      errorTitle: {
        required: $localize`Please select a recruiter!`,
      },
    },
  ];

  public titles: Titles;
  pageNumber: number = 1;
  onNextClicked = false;
  recruiterConfirmationFormGroup: FormGroup;
  selectedConsultant: Consultant = null;
  hasMoreConsultant$: Observable<boolean>;
  actualConsultantList$: Observable<Consultant[]>;
  stepProcessing$: Observable<boolean>;
  consultantStepsSkipped$: Observable<boolean>;

  private isFindByZipCode: boolean;

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
    injector: Injector,
  ) {
    super(injector, 'SNA Step - 0.3 Confirm Consultant');
  }

  ngOnInit(): void {
    this.recruiterConfirmationFormGroup = this.createFormGroup();

    this.stepProcessing$ = this.store$.select(selectStepProcessing).pipe(distinctUntilChanged());
    this.actualConsultantList$ = this.store$
      .select(selectConsultantFinderStep)
      .pipe(select((consultantFinder) => consultantFinder.consultantFinderResult.items));
    this.hasMoreConsultant$ = this.store$
      .select(selectConsultantFinderStep)
      .pipe(select((consultantFinder) => consultantFinder.consultantFinderResult.hasMore));
    this.store$
      .select(selectConsultantFinderCriteria)
      .pipe(
        take(1),
        select((finderCriteria) => finderCriteria?.zipCode),
      )
      .subscribe((zipCode) => {
        this.isFindByZipCode = !!zipCode;
      });
    this.consultantStepsSkipped$ = this.store$.select(selectConsultantStepsSkipped);

    this.selectActualTitle();
  }

  public submitStep(): void {
    this.store$
      .select(selectRegistrationCodeStepSkipped)
      .pipe(take(1))
      .subscribe((registrationCodeStepSkipped) => {
        if (
          window.location.href.includes(RouterQueryParams.cid) ||
          window.location.href.includes(RouterQueryParams.lnid) ||
          !this.selectedConsultant.isPrivateRecruitment ||
          registrationCodeStepSkipped
        ) {
          this.goToOtherStep.emit(StartNowAppStep.AboutYou);
        } else {
          this.goToNextStep.emit();
        }
      });
  }

  public previousStepClick(): void {
    this.store$.dispatch(resetRegistrationCodeStepSkipped());
    this.store$.dispatch(resetFindConsultant());
    this.store$.dispatch(resetSelectedConsultant());
    this.goToPreviousStep.emit();
  }

  public nextStepClick(consultantListCount: number): void {
    this.onNextClicked = true;
    if (consultantListCount > 1 && !!this.selectedConsultant) {
      this.updateSelectedConsultant();
    } else {
      this.recruiterConfirmationFormGroup.markAllAsTouched();
      if (this.recruiterConfirmationFormGroup.valid) {
        this.updateSelectedConsultant();
      }
    }
  }

  public changeSelectedConsultant(selectedConsultant) {
    this.selectedConsultant = selectedConsultant;
  }

  public showMoreConsultants() {
    ++this.pageNumber;
    if (this.isFindByZipCode) {
      this.store$.dispatch(
        findConsultantByZipCodeNextPage({
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        }),
      );
    } else {
      this.store$.dispatch(
        findConsultantByAreaNextPage({ pageNumber: this.pageNumber, pageSize: this.pageSize }),
      );
    }
  }

  public isConsultantSelected() {
    return !!this.selectedConsultant;
  }

  navigateToStartNowApp() {
    this.store$.dispatch(resetCurrentConsultant());
    window.location.href = window.location.href.split('?')[0];
  }

  getValidationError(controlName: string): string {
    return getFormValidationErrorMessage(
      this.recruiterConfirmationFormGroup,
      controlName,
      this.titles.errorTitle,
    );
  }

  get confirmValidInput(): boolean {
    return validInput(this.recruiterConfirmationFormGroup.get(this.Confirmation));
  }

  protected createFormGroup(): FormGroup {
    return this.fb.group({
      [this.Confirmation]: [false, [Validators.requiredTrue]],
    });
  }

  private selectActualTitle(): void {
    this.actualConsultantList$
      .pipe(
        filter((actualList) => !!actualList.length),
        take(1),
      )
      .subscribe((actualList) => {
        this.titles = actualList.length > 1 ? this.listOfTitles[1] : this.listOfTitles[0];
      });
  }

  private updateSelectedConsultant() {
    this.store$.dispatch(updateSelectedConsultant({ selectedConsultant: this.selectedConsultant }));
    this.store$.dispatch(resetFirstConsultants());
    this.submitStep();
  }
}
