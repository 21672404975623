import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderStatusLocale } from '@core/constants/shipment-status-locale';
import { InfoCardAttributes } from '@core/models/info-card-attributes.model';
import { VoucherService } from '@core/services/voucher.service';
import { AppState } from '@core/store';
import { selectOrderDetails, selectOrderStateLoading } from '@core/store/order';
import { OrderAddress, OrderDetails, OrderLineDetails } from '@core/store/order/order-state-models';
import { fetchOrderDetails } from '@core/store/order/order.actions';
import { ReceivedVoucher } from '@core/store/voucher/voucher-state-models';
import { Store } from '@ngrx/store';
import {
  createConsultantInfoCard,
  createHostInfoCard,
  createPartyInfoCard,
  createPersonalInfoCard,
} from '@shared/utils/create-shipping-info-card-attributes';
import { getMaskedCardNumber } from '@shared/utils/get-masked-card-number-utils';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  orderDetails$: Observable<OrderDetails>;
  loading$: Observable<boolean>;
  orderLines$: Observable<OrderLineDetails[]>;
  cardNumber$: Observable<InfoCardAttributes>;
  vouchers$: Observable<ReceivedVoucher[]>;

  readonly AddressCardTitles = {
    shippingInfo: $localize`Shipping information`,
    billingInfo: $localize`Billing information`,
  };

  readonly OrderStatusLocale = OrderStatusLocale;
  createPersonalInfoCard = createPersonalInfoCard;
  createPartyInfoCard = createPartyInfoCard;
  createHostInfoCard = createHostInfoCard;
  createConsultantInfoCard = createConsultantInfoCard;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private voucherService: VoucherService,
  ) {}

  ngOnInit(): void {
    this.orderDetails$ = this.store$.select(selectOrderDetails);
    this.loading$ = this.store$.select(selectOrderStateLoading);
    this.orderLines$ = this.orderDetails$.pipe(map((orderDetails) => orderDetails.orderLines));
    this.cardNumber$ = this.orderDetails$.pipe(
      map((p) => ({
        title: $localize`Payment information`,
        cardNumber: getMaskedCardNumber(p.cardFirstFourDigits, p.cardLastFourDigits),
        readOnly: true,
      })),
    );

    this.vouchers$ = this.orderDetails$.pipe(map((orderDetails) => orderDetails?.vouchers));

    this.subscriptions.add(
      this.activatedRoute.paramMap.subscribe((pMap) => {
        const orderId = pMap.get('orderId');
        this.store$.dispatch(fetchOrderDetails({ orderId }));
      }),
    );
  }

  createAddressInfoCard(address: OrderAddress, title: string): InfoCardAttributes {
    return {
      title: title,
      name: address.firstName + ' ' + address.lastName,
      addressLine1: `${address.addressLine1}, ${address.addressLine2}`,
      addressLine2: `${address.city}, ${address.state}, ${address.zipCode}`,
      phone: address.phoneNumber,
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  download(voucherId: string) {
    this.voucherService.downloadVoucher(voucherId);
  }
}
