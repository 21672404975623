import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { validateReCaptcha } from '@core/store/start-now-app/start-now-app.actions';
import { Store } from '@ngrx/store';
import { hideRecaptchaBadge, showRecaptchaBadge } from '@shared/utils/recaptcha-utils';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-sna-recaptcha',
  template: '',
})
export class SnaReCaptchaComponent implements OnDestroy, AfterViewInit {
  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private appInitService: AppInitService,
    private reCaptchaService: ReCaptchaV3Service,
  ) {}

  ngAfterViewInit(): void {
    showRecaptchaBadge();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    hideRecaptchaBadge();
  }

  validateReCaptcha() {
    this.subscriptions.add(
      this.reCaptchaService
        .execute('importantAction')
        .pipe(
          filter((token) => !!token || !this.appInitService.Settings.sna.isReCaptchaEnabled),
          take(1),
        )
        .subscribe((token: string) => this.store$.dispatch(validateReCaptcha({ token }))),
    );
  }
}
