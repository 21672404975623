import { Component, EventEmitter, Injector, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppState } from '@core/store';
import {
  resetFindConsultant,
  resetSelectedConsultant,
} from '@core/store/start-now-app/start-now-app.actions';
import { Store } from '@ngrx/store';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-do-you-know-consultant-step',
  templateUrl: './do-you-know-consultant-step.component.html',
  styleUrls: ['./do-you-know-consultant-step.component.scss'],
})
export class DoYouKnowConsultantComponent extends StartNowStepBaseComponent {
  @Output() knowConsultantChoice: EventEmitter<boolean> = new EventEmitter();

  constructor(private store$: Store<AppState>, injector: Injector) {
    super(injector, 'SNA Step - 0.1 Do You Know A Consultant');
  }

  submitStep(): void {
    this.store$.dispatch(resetFindConsultant());
    this.store$.dispatch(resetSelectedConsultant());
  }

  public onKnowConsultantClick(doIKnow: boolean): void {
    this.submitStep();
    this.knowConsultantChoice.emit(doIKnow);
  }

  protected createFormGroup(): FormGroup {
    throw new Error('Method not implemented.');
  }
}
