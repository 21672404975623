<table class="table carton-data">
  <thead
    ><tr
      ><td colspan="3">{{ heading }}</td></tr
    ></thead
  >
  <tbody>
    <tr><th i18n>Item #</th><th i18n>Qty</th><th i18n>Description</th></tr>
    <tr *ngFor="let cartonItem of cartonItems">
      <td>{{ cartonItem.skuCode }}</td>
      <td>{{ cartonItem.quantity }}</td>
      <td>{{ cartonItem.skuDescription }}</td>
    </tr>
  </tbody>
</table>
