<div class="root">
  <app-header-container>
    <app-header-sna></app-header-sna>
  </app-header-container>

  <div>
    <router-outlet></router-outlet>
  </div>

  <app-footer-container class="mt-5">
    <app-footer-links
      [shade]="shade.Shade3"
      [links]="links$"
      [isSecondary]="true"
      linkTarget="_blank"
    ></app-footer-links>
    <app-footer-bottom-sna [shade]="shade.Shade4"></app-footer-bottom-sna>
  </app-footer-container>
</div>
