<ng-container>
  <div class="input-group" [ngClass]="getSizeClass()">
    <div class="input-group-prepend">
      <button (click)="decrement()" class="btn btn-outline-secondary">
        <i class="bi bi-dash-lg"></i>
      </button>
    </div>
    <input
      type="number"
      name="{{ identifier }}"
      class="form-control bg-transparent"
      [value]="value"
      (keypress)="$event.preventDefault()"
      disabled
    />
    <div class="input-group-append">
      <button (click)="increment()" class="btn btn-outline-secondary">
        <i class="bi bi-plus-lg"></i>
      </button>
    </div>
  </div>
</ng-container>
