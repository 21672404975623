/* eslint-disable max-len */
import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';
import { environment } from '@env';

export const B2C_SIGNIN_TARGET_ROUTE = 'b2c-signin-target';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const b2cPolicies = {
  names: {
    signInEmbedded: 'b2c_1a_signinembedded',
  },
  authorities: {
    signInEmbedded: {
      authority: `https://${environment.b2c.domain}/${environment.b2c.tenant}.onmicrosoft.com/b2c_1a_signinembedded`,
    },
  },
  authorityDomain: environment.b2c.domain,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.b2c.clientId,
    authority: b2cPolicies.authorities.signInEmbedded.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: () => {},
      piiLoggingEnabled: false,
    },
  },
};

export const protectedResources = {
  defaultScopes: [environment.b2c.customerScope, null],
  endpoints: [
    { endpoint: `${environment.ecommerceBackendUri}api/cart/merge` },
    { endpoint: `${environment.ecommerceBackendUri}api/returns*` },
    { endpoint: `${environment.ecommerceBackendUri}api/order*` },
    { endpoint: `${environment.ecommerceBackendUri}api/availability` },
    { endpoint: `${environment.ecommerceBackendUri}api/recipe/likes` },
    { endpoint: `${environment.ecommerceBackendUri}api/recipe/*/like` },
    { endpoint: `${environment.ecommerceBackendUri}api/address*` },
    {
      endpoint: `${environment.ecommerceBackendUri}api/user`,
      scopes: [
        { httpMethod: 'GET', scopes: [environment.b2c.customerScope] },
        { httpMethod: 'PUT', scopes: [environment.b2c.customerScope] },
      ],
    },
    { endpoint: `${environment.ecommerceBackendUri}api/user/consultant` },
    { endpoint: `${environment.ecommerceBackendUri}api/payment/methods*` },
    {
      endpoint: `${environment.ecommerceBackendUri}api/wishlist/merge`,
      scopes: [{ httpMethod: 'POST', scopes: [environment.b2c.customerScope] }],
    },
  ],
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ['offline_access'],
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "loginHint" property (such as a username). For more, visit:
 * https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-sso#sso-between-different-apps
 * If you do not receive the username claim in ID tokens, see also:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#why-is-getaccountbyusername-returning-null-even-though-im-signed-in
 */
export const silentRequest = {
  scopes: ['openid'],
  prompt: 'none',
};
