import { Component, OnInit } from '@angular/core';
import { TableSortDirectionCycle } from '@core/constants/table-sort-direction-cycle';
import { TableSortDirection } from '@core/enums/table-sort-direction.enum';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { AppState } from '@core/store';
import { selectAddToCartConfirmModal } from '@core/store/cart';
import { addToCart } from '@core/store/cart/cart.actions';
import { selectLoading, selectWishlist } from '@core/store/wishlist';
import { WishlistItem } from '@core/store/wishlist/wishlist-state-models';
import { deleteFromWishlist, fetchWishlist } from '@core/store/wishlist/wishlist.actions';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const compare = (v1: string | number, v2: string | number) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

enum WishlistColumnType {
  productName = 'productName',
  discountedPrice = 'discountedPrice',
}

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss'],
})
export class WishlistComponent implements OnInit {
  wishlistColumnType = WishlistColumnType;
  wishlist$: Observable<WishlistItem[]>;
  loading$: Observable<boolean>;
  direction: TableSortDirection;
  sortColumn: WishlistColumnType | null;
  computedRows$: Observable<WishlistItem[]>;
  addToCartLoading$: Observable<boolean>;

  thumbnail = Thumbnail;

  constructor(private store$: Store<AppState>) {
    this.direction = TableSortDirection.none;
    this.sortColumn = null;
  }

  ngOnInit(): void {
    this.store$.dispatch(fetchWishlist());

    this.addToCartLoading$ = this.store$
      .select(selectAddToCartConfirmModal)
      .pipe(select((state) => state.loading));

    this.wishlist$ = this.store$.select(selectWishlist);
    this.loading$ = this.store$.select(selectLoading);
    this.computedRows$ = this.wishlist$;
  }

  onSort(column: WishlistColumnType) {
    if (column !== this.sortColumn) {
      this.direction = TableSortDirection.none;
    }
    this.direction = TableSortDirectionCycle[this.direction];
    this.sortColumn = column;

    if (this.direction === TableSortDirection.none) {
      this.computedRows$ = this.wishlist$;
    } else {
      this.sort(column, this.direction);
    }
  }

  addToCart(product: WishlistItem) {
    this.store$.dispatch(
      addToCart({
        payload: {
          images: product.images,
          collection: product.collectionName,
          productName: product.productName,
          sku: product.sku,
          discountedPrice: product.discountedPrice,
          price: product.originalPrice,
          quantity: 1,
          type: product.type,
        },
      }),
    );
  }

  removeFromWishList(sku: string) {
    this.store$.dispatch(deleteFromWishlist({ sku }));
  }

  private sort(column: string, direction: TableSortDirection) {
    this.computedRows$ = this.wishlist$.pipe(
      map((wishlist) =>
        wishlist.slice().sort((a, b) => {
          const res = compare(a[column], b[column]);
          return direction === TableSortDirection.asc ? res : -res;
        }),
      ),
    );
  }
}
