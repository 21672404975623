import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-romance-content-wrapper',
  templateUrl: './romance-content-wrapper.component.html',
  styleUrls: ['./romance-content-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RomanceContentWrapperComponent {
  @Input() html: string;
}
