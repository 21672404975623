import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CreatePaymentErrorTypeTranslations } from '@core/constants/create-payment-error-type-translations';
import { CreateCardPaymentErrorType } from '@core/enums/create-card-payment-error-type.enum';
import { PaymentMethodCardInfo } from '@core/store/payment/payment-state-models';
import { isMexEnv } from '@shared/utils/environment-utils';

@Component({
  selector: 'app-create-card-payment-method-error',
  templateUrl: './create-card-payment-method-error.component.html',
  styleUrls: ['./create-card-payment-method-error.component.scss'],
})
export class CreateCardPaymentMethodErrorComponent {
  @Input() cardTypeDisplayValue: string;
  @Input() cardPaymentErrorType: CreateCardPaymentErrorType;
  @Input() cardInfo: PaymentMethodCardInfo;
  @Output() resetPayment: EventEmitter<void> = new EventEmitter();

  public isMexEnv = isMexEnv;

  constructor() {}

  getPaymentErrorMessage() {
    return CreatePaymentErrorTypeTranslations.get(this.cardPaymentErrorType) || '';
  }

  resetPaymentClick() {
    this.resetPayment.emit();
  }
}
