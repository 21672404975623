<div>
  <div
    *ngIf="(isBlogCategoriesFetched$ | async) === false; else content"
    class="d-flex justify-content-center m-5"
  >
    <app-ph-spinner></app-ph-spinner>
  </div>
</div>

<ng-template #content>
  <div *ngIf="(blogCategories$ | async).length > 1; else blogCategoriesNotFound">
    <app-blog-list-header
      (sortChange)="sortChangeHandler($event)"
      [blogCategories]="blogCategories$ | async"
    ></app-blog-list-header>
    <div
      role="region"
      infinite-scroll
      [infiniteScrollThrottle]="100"
      [infiniteScrollDistance]="0.3"
      (scrolled)="onScrollDown()"
      aria-label="Blog list"
      i18n-aria-label
    >
      <div class="row">
        <article
          class="col-12 col-sm-6 col-md-4 pb-2 pb-md-3"
          *ngFor="let blog of blogs$ | async"
          [attr.aria-label]="blog.title"
        >
          <app-blog-list-item [blog]="blog"> </app-blog-list-item>
        </article>
      </div>
    </div>
  </div>

  <ng-template #blogCategoriesNotFound>
    <div class="my-4 text-center">
      <span class="text-not-found font-weight-bold" i18n>Blog categories not found</span>
    </div>
  </ng-template>
</ng-template>
