import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WishlistState } from './wishlist-state-models';

export const wishlistStateFeatureKey = 'wishlistState';

export const selectWishlistFeature = createFeatureSelector<WishlistState>(wishlistStateFeatureKey);

export const selectWishlist = createSelector(selectWishlistFeature, (state: WishlistState) =>
  state ? state.wishListItems : [],
);

export const selectLoading = createSelector(selectWishlistFeature, (state: WishlistState) =>
  state ? state.loading : false,
);
