import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DefaultModalOptions } from '@core/constants/default-modal-options';
import { ModalConfig } from '@core/models/modal-config.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@Injectable()
export class ModalComponent {
  @Input() public modalConfig: ModalConfig;
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() dismissed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('modal') private modalContent: TemplateRef<ModalComponent>;

  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  open(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.modalRef = this.modalService.open(this.modalContent, {
        ...DefaultModalOptions,
        ...this.modalConfig.ngbModalOptions,
      });

      this.modalRef.dismissed
        .pipe(
          take(1),
          filter((e) => e === 0 || e === 1),
        )
        .subscribe(() => {
          this.dismissHeader();
        });
      this.modalRef.result.then(resolve, resolve);
    });
  }

  dismissOnly() {
    this.modalRef.dismiss();
    this.dismissed.emit();
  }

  async close(): Promise<void> {
    if (this.modalConfig.shouldClose === undefined || (await this.modalConfig.shouldClose())) {
      const result = this.modalConfig.onClose === undefined || (await this.modalConfig.onClose());
      this.modalRef?.close(result);
      this.closed.emit();
    }
  }

  async dismiss(): Promise<void> {
    if (this.modalConfig.shouldDismiss === undefined || (await this.modalConfig.shouldDismiss())) {
      const result =
        this.modalConfig.onDismiss === undefined || (await this.modalConfig.onDismiss());
      this.modalRef.dismiss(result);
      this.dismissed.emit();
    }
  }

  async dismissHeader(): Promise<void> {
    if (this.modalConfig.onDismissHeader === undefined) {
      this.dismiss();
    } else {
      if (
        this.modalConfig.shouldDismiss === undefined ||
        (await this.modalConfig.shouldDismiss())
      ) {
        const result =
          this.modalConfig.onDismissHeader === undefined ||
          (await this.modalConfig.onDismissHeader());
        this.modalRef.dismiss(result);
      }
    }
  }

  getDismissButtonLabel = () => this.modalConfig.dismissButtonLabel ?? $localize`Cancel`;

  getCloseButtonLabel = () => this.modalConfig.closeButtonLabel ?? $localize`Ok`;
}
