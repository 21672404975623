import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppState } from '@core/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectConsultantState } from '@core/store/consultant';

@Injectable({
  providedIn: 'root',
})
export class ConsultantGuardService implements CanActivate {
  constructor(private store$: Store<AppState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store$.select(selectConsultantState).pipe(
      filter(({ isCurrentConsultantFetched }) => isCurrentConsultantFetched),
      map(({ currentConsultant }) => {
        if (!currentConsultant) {
          this.router.navigateByUrl('/');
        }
        return !!currentConsultant;
      }),
    );
  }
}
