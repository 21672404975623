<h1 class="text-title mb-3" i18n>{{ titles[country] }}</h1>
<h6 class="text-subtitle mb-3" i18n>
  Your Starter Kit contains a selection of items to help you build your Princess House business.
</h6>

<div class="row" *ngIf="starterKits$ | async">
  <div
    class="col-12 pb-2 mb-md-3"
    [class]="{
      'col-md-8': isUsaEnv,
      'col-md-6': isMexEnv
    }"
    *ngFor="let starterKit of starterKits$ | async"
  >
    <app-starter-kit-card
      [starterKit]="starterKit"
      [isSelected]="isSelected(selectedStarterKit, starterKit)"
      (selectChange)="selectStarterKit($event)"
    ></app-starter-kit-card>
  </div>
</div>

<div class="row" *ngIf="isUsaEnv">
  <div class="col-12 col-md-8">
    <span class="font-italic" i18n>
      Note: Your Starter Kit may be received in two shipments. Products and printed material boxes
      may arrive separately in 5 – 7 business days after your order is processed for shipment. To
      ensure kits are current, products and tools included are subject to change.
    </span>
  </div>
</div>

<div

   *ngIf="isUsaEnv && {depositPaymentValue: depositPaymentValue$ | async, reducedDepositPaymentValue: reducedDepositPaymentValue$ | async} as payment"
  class="mb-4"
  ngbRadioGroup
  [(ngModel)]="selectedStartNowPaymentType"
  (ngModelChange)="onChangeStartNowPaymentType($event)"
>
  <h5 class="mt-3" i18n>Select payment option</h5>
  <div class="row radio-button-color">
    <div class="col-lg-12">
    <div class="col-12 col-md-4">
      <label ngbButtonLabel class="pl-0 py-0 font-weight-normal shadow-none">
        <input ngbButton type="radio" name="subject" [value]="StartNowPaymentType.FullPayment" />
        <span class="radio-span" i18n> Pay full price</span>
      </label>
    </div>
   
    <div class="col-12 col-md-4 text-nowrap">
      <label ngbButtonLabel class="pl-0 py-0 font-weight-normal shadow-none">
        <input ngbButton type="radio" name="subject" [value]="StartNowPaymentType.DepositAmount"  />
        <span class="radio-span"  i18n> Pay only {{ payment.depositPaymentValue | phCurrency }} deposit</span>
      </label>
    </div>
    <div class="col-12 col-md-4 text-nowrap" *ngIf="(isReducedDepositAvailable$ | async) === true">
      <label ngbButtonLabel class="pl-0 py-0 font-weight-normal shadow-none">
        <input ngbButton type="radio" name="subject" [value]="StartNowPaymentType.ReducedDepositAmount" />
        <span class="radio-span"  *ngIf="(isZeroDepositAvailable$ | async) === false" i18n>Pay {{ payment.reducedDepositPaymentValue | phCurrency }} Reduced Deposit</span>
        <span class="radio-span"  *ngIf="(isZeroDepositAvailable$ | async) === true" i18n>{{ payment.reducedDepositPaymentValue | phCurrency }}  Deposit Option*</span>
        
      </label>
    </div>
    </div>
  </div>
  <div class="col-lg-12">
    <span *ngIf="isDepositPayment" class="font-italic" i18n>
      If you only pay the {{ payment.depositPaymentValue | phCurrency }} deposit, the difference will be deducted from
      your
      first comissions.
    </span>
    <span
      *ngIf="(isZeroDepositAvailable$ | async) === false && (isReducedDepositAvailable$ | async) === true && selectedStartNowPaymentType === StartNowPaymentType.ReducedDepositAmount"
      class="font-italic">
  
      By selecting {{ payment.reducedDepositPaymentValue | phCurrency }} deposit, you acknowledge that your Starter Kit
      will
      not ship until you have submitted $750 in sales subject to profit (VSG) OR paid your Starter Kit in full within 60
      days.
      Your profits will be applied to your Starter Kit balance until it is paid in full.
    </span>
    <span
      *ngIf="(isZeroDepositAvailable$ | async) === true  && (isReducedDepositAvailable$ | async) === true && selectedStartNowPaymentType === StartNowPaymentType.ReducedDepositAmount"
      class="font-italic text-span">
      <p class="pl-3 pr-4" i18n>
        *You selected the $0 deposit option for your Starter Kit. Once you reach $750 or more in personal sales during the
        first 60 days**, you’ll receive a Starter Kit
        with over $600 in products and business supplies – the $199*** will be automatically deducted from your profits.
      </p>
      <p class="pl-3 pr-5" i18n>
        If you do not reach $750 or more in sales during your first 60 days following submission of your application &
        agreement, you will continue to have access to Consultant’s Corner and your online store. However, you will
        forfeit the opportunity to purchase the Starter Kit for $199*** (which includes products and business supplies
        valued at over $600).
      </p>
    </span>
    <span *ngIf="(isZeroDepositAvailable$ | async) === true  && (isReducedDepositAvailable$ | async) === true && selectedStartNowPaymentType === StartNowPaymentType.ReducedDepositAmount"
      class="font-italic text-span-no-background ">
      <p class="pl-3 pr-4" i18n>
        **All references to “sales” refer to Qualifying Volume (QV), which can come from any combination of party and/or
        eCommerce orders submitted with a deposit of 50% or more during Period 9, 2024 (August 10, 2024 – September 6, 2024).
      </p>
      <p class="pl-3 pr-4" i18n>
        ***Plus tax, no delivery fee. There is no guarantee that a Consultant will earn any income or qualify for Princess
        House incentives and/or trips. In 2023, the average commissions paid to active Princess House Consultants was
        $1,103. And, on average, 1,089 hardworking Consultants achieve the Incentive Trip each year. Visit
        <a href="https://www.princesshouse.com/en-US/income-disclosure" target="_blank" class="tracking-url">princesshouse.com/income-disclosure</a> for more information.
      </p>
    </span>
  </div>
<p class="font-italic mb-5" i18n *ngIf="isMexEnv">*Tax and delivery is included in the price.</p>
<div *ngIf="starterKitStepData$ | async as starterKitStepData">
  <div *ngIf="
      (!!starterKitStepData.statusCode && starterKitStepData.statusCode !== StatusCodes.OK) ||
      !!starterKitStepData.errors.length
    " class="validation-error-message mb-3">
    <app-sna-http-status-errors [statusCode]="starterKitStepData.statusCode"></app-sna-http-status-errors>
  </div>
</div>
</div>