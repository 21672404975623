<h1 class="text-title" i18n>Wait till you see all your business tools!</h1>

<form [formGroup]="addressFormGroup">
  <div [formGroupName]="HomeAddressFormGroup">
    <div class="row">
      <div class="col-12 col-md-4 mb-3">
        <app-text-field-input
          identifier="baAddressLine1"
          label="Address Line 1"
          i18n-label
          placeholder="Your address line 1"
          i18n-placeholder
          [labelStyle]="FormLabelStyle"
          [formControlName]="AddressLine1"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>

      <div class="col-12 col-md-4 mb-3">
        <app-text-field-input
          identifier="baAddressLine2"
          label="Address Line 2"
          i18n-label
          placeholder="Your address line 2"
          i18n-placeholder
          [labelStyle]="FormLabelStyle"
          [formControlName]="AddressLine2"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>

      <div *ngIf="isMexEnv" class="col-12 col-md-4 mb-3">
        <app-text-field-input
          identifier="baAddressLine3"
          label="Address Line 3"
          i18n-label
          placeholder="Your address line 3"
          i18n-placeholder
          [labelStyle]="FormLabelStyle"
          [formControlName]="AddressLine3"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4 mb-3">
        <app-text-field-input
          identifier="baCity"
          label="City"
          i18n-label
          placeholder="Your city"
          i18n-placeholder
          [labelStyle]="FormLabelStyle"
          [formControlName]="City"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>

      <div class="col-12 col-md-4 mb-3">
        <app-ph-select
          identifier="baState"
          label="State"
          i18n-label
          [labelStyle]="FormLabelStyle"
          i18n-defaultValue
          defaultValue="Your state"
          [formControlName]="State"
          [options]="countryStates$ | async"
        ></app-ph-select>
      </div>

      <div class="col-12 col-md-2 mb-3">
        <app-text-field-input
          identifier="baZipCode"
          label="ZIP Code"
          i18n-label
          placeholder="ZIP code"
          i18n-placeholder
          [labelStyle]="FormLabelStyle"
          [formControlName]="ZipCode"
        ></app-text-field-input>
      </div>
    </div>
  </div>
</form>

<div
  class="warning-message"
  *ngIf="!!addressValidationResultErrorCode && useHomeAddressForShipping"
>
  <strong>
    {{ AddressValidationErrorMessages[addressValidationResultErrorCode] }}
    <i class="bi bi-exclamation-triangle"></i>
  </strong>
</div>

<form *ngIf="isUsaEnv" [formGroup]="addressFormGroup">
  <span class="text-nowrap mt-3" i18n>Language Preference</span>
  <div class="row radio-button-color mt-2" ngbRadioGroup>
    <div class="col-auto text-nowrap">
      <label ngbButtonLabel class="my-0 px-0 font-weight-normal shadow-none">
        <input
          ngbButton
          class="mr-2"
          type="radio"
          [id]="LanguagePreferred + '_' + LanguagePreferenceType.English"
          [formControlName]="LanguagePreferred"
          [value]="LanguagePreferenceType.English"
        />
        <span i18n>English</span>
      </label>
    </div>
    <div class="col-auto text-nowrap">
      <label ngbButtonLabel class="my-0 px-0 font-weight-normal shadow-none">
        <input
          ngbButton
          class="mr-2"
          type="radio"
          [id]="LanguagePreferred + '_' + LanguagePreferenceType.Spanish"
          [formControlName]="LanguagePreferred"
          [value]="LanguagePreferenceType.Spanish"
        />
        <span i18n>Spanish</span>
      </label>
    </div>
  </div>
  <div *ngIf="!radioButtonValidInput" class="validation-error-message mt-1">
    <span i18n>This field is required</span>
  </div>
</form>

<div class="radio-group-container mb-4">
  <h6 class="radio-group-label" i18n>Should we ship all products to the address above?</h6>
  <div
    ngbRadioGroup
    class="d-flex"
    (ngModelChange)="toggleShippingAddressActivity($event)"
    [(ngModel)]="useHomeAddressForShipping"
  >
    <div class="px-1">
      <label ngbButtonLabel class="my-0 pe-5 text-uppercase radio-label yes-label">
        <input ngbButton type="radio" [value]="true" class="radio-input" />
        <span i18n>Yes</span>
      </label>
    </div>
    <div class="px-1">
      <label ngbButtonLabel class="my-0 px-3 text-uppercase radio-label">
        <input ngbButton type="radio" [value]="false" class="radio-input" />
        <span i18n>No, please use the address below</span>
      </label>
    </div>
  </div>
</div>

<form *ngIf="!useHomeAddressForShipping" [formGroup]="addressFormGroup" class="mb-5">
  <div [formGroupName]="ShippingAddressFromGroup">
    <div class="row">
      <div class="col-12 col-md-4 mb-3">
        <app-text-field-input
          identifier="saAddressLine1"
          label="Address Line 1"
          i18n-label
          placeholder="Your address"
          i18n-placeholder
          [labelStyle]="FormLabelStyle"
          [formControlName]="AddressLine1"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>

      <div class="col-12 col-md-4 mb-3">
        <app-text-field-input
          identifier="saAddressLine2"
          label="Address Line 2"
          i18n-label
          placeholder="Your colonia"
          i18n-placeholder
          [labelStyle]="FormLabelStyle"
          [formControlName]="AddressLine2"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>

      <div *ngIf="isMexEnv" class="col-12 col-md-4 mb-3">
        <app-text-field-input
          identifier="saAddressLine3"
          label="Address Line 3"
          i18n-label
          placeholder="Your municipio"
          i18n-placeholder
          [labelStyle]="FormLabelStyle"
          [formControlName]="AddressLine3"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4 mb-3">
        <app-text-field-input
          identifier="saCity"
          label="City"
          i18n-label
          placeholder="Your city"
          i18n-placeholder
          [labelStyle]="FormLabelStyle"
          [formControlName]="City"
          [isDataPrivate]="true"
        ></app-text-field-input>
      </div>

      <div class="col-12 col-md-4 mb-3">
        <app-ph-select
          identifier="saState"
          label="State"
          i18n-label
          [labelStyle]="FormLabelStyle"
          i18n-defaultValue
          defaultValue="Your state"
          formControlName="state"
          [options]="countryStates$ | async"
        ></app-ph-select>
      </div>

      <div class="col-12 col-md-2 mb-3">
        <app-text-field-input
          identifier="saZipCode"
          label="ZIP Code"
          i18n-label
          placeholder="ZIP code"
          i18n-placeholder
          [labelStyle]="FormLabelStyle"
          [formControlName]="ZipCode"
        ></app-text-field-input>
      </div>
    </div>
  </div>
  <div
    class="warning-message"
    *ngIf="!!addressValidationResultErrorCode && !useHomeAddressForShipping"
  >
    <strong>
      {{ AddressValidationErrorMessages[addressValidationResultErrorCode] }}
      <i class="bi bi-exclamation-triangle"></i>
    </strong>
  </div>
</form>

<div class="warning-message pb-4" *ngIf="addressStep$ | async as addressStep">
  <div
    *ngIf="
      (!!addressStep.statusCode && addressStep.statusCode !== StatusCodes.OK) ||
      !!addressStep.errors?.length
    "
  >
    <app-sna-http-status-errors [statusCode]="addressStep.statusCode"></app-sna-http-status-errors>
  </div>
</div>

<app-sna-address-validation-modal
  [addressValidation$]="addressValidation$"
  [countryStates]="countryStates$ | async"
  [finalizeAddress]="finalizeAddress"
  [isForbiddenState]="isForbiddenState"
  [dismiss]="dismissAddressValidationModal"
></app-sna-address-validation-modal>
