import { Injectable } from '@angular/core';
import { PhErrorResponse } from '@core/dto/ph-error';
import { CreateCardPaymentErrorType } from '@core/enums/create-card-payment-error-type.enum';
import { PhExceptionErrorType } from '@core/enums/ph-exception-error-type.enum';
import { LoggerService } from '@core/services/logger.service';
import { OrderService } from '@core/services/order.service';
import { PaymentService } from '@core/services/payment.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getCreatePaymentMethodFailureMessage,
  getErrorMessage,
  getErrorTypeFromPaymentMethodCreationFailedException,
} from '@shared/utils/get-error-message';
import { getErrorTitle } from '@shared/utils/get-error-title';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { CreatedCardPaymentMethod, PaymentMethodType } from './payment-state-models';
import * as paymentActions from './payment.actions';

@Injectable()
export class PaymentEffects {
  fetchClientToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.fetchClientToken),
      mergeMap(({ payload }) =>
        this.orderService.fetchClientToken(payload).pipe(
          map((res) => paymentActions.fetchClientTokenSuccess({ payload: res })),
          catchError((error) => of(paymentActions.fetchClientTokenFailure({ error }))),
        ),
      ),
    ),
  );

  fetchClientTokenFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(paymentActions.fetchClientTokenFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchPartyOrderClientToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.fetchPartyOrderClientToken),
      mergeMap(({ payload }) =>
        this.orderService.fetchPartyOrderClientToken(payload).pipe(
          map((res) => paymentActions.fetchPartyOrderClientTokenSuccess({ payload: res })),
          catchError((error) => of(paymentActions.fetchPartyOrderClientTokenFailure({ error }))),
        ),
      ),
    ),
  );

  fetchPartyOrderClientTokenFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(paymentActions.fetchPartyOrderClientTokenFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchPaymentMethods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.fetchPaymentMethods),
      switchMap(() =>
        this.paymentService.fetchPaymentMethods().pipe(
          map((res) =>
            paymentActions.fetchPaymentMethodsSuccess({
              paymentMethods: res.paymentMethods.map((item) => ({
                id: item.token,
                cardName: item.cardName,
                cardNumber: item.cardNumber,
                cardType: item.type,
                expiryDate: item.expiryDate,
                isExpired: item.isExpired,
                type: item.isDefault ? PaymentMethodType.default : PaymentMethodType.other,
                provider: item.provider,
              })),
            }),
          ),
          catchError((error) => of(paymentActions.fetchPaymentMethodsFailure(error))),
        ),
      ),
    ),
  );

  fetchPaymentMethodsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(paymentActions.fetchPaymentMethodsFailure),
        tap(() => this.toastr.error($localize`Fetching payment methods failed.`, $localize`Error`)),
      ),
    { dispatch: false },
  );

  storePaymentInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.storePaymentInfo),
      map(({ paymentInfo }) => paymentActions.storePaymentInfoSuccess({ payload: paymentInfo })),
    ),
  );

  createPaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.createPaymentMethod),
      mergeMap(({ payload }) =>
        this.paymentService.createPaymentMethod(payload).pipe(
          map((res) => paymentActions.createPaymentMethodSuccess({ payload: res })),
          catchError((error) => of(paymentActions.createPaymentMethodFailure({ error }))),
        ),
      ),
    ),
  );

  createPaymentMethodFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(paymentActions.createPaymentMethodFailure),
        tap((response) => {
          this.loggerService.error(
            'Create payment methods failed:',
            response.error.error?.ErrorMessage,
          );
          this.toastr.error(
            $localize`Create payment methods failed! ${getCreatePaymentMethodFailureMessage(
              response.error.error,
            )}`,
            $localize`Error`,
          );
        }),
      ),
    { dispatch: false },
  );

  setAsDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.setAsDefault),
      mergeMap(({ token, provider }) =>
        this.paymentService.setAsDefault(token, provider).pipe(
          map(() => paymentActions.setAsDefaultSuccess()),
          catchError(() => of(paymentActions.setAsDefaultFailure())),
        ),
      ),
    ),
  );

  setAsDefaultFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.setAsDefaultFailure),
      tap(() =>
        this.toastr.error($localize`Setting default payment method failed.`, $localize`Error`),
      ),
      map(() => paymentActions.fetchPaymentMethods()),
    ),
  );

  deletePaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.deletePaymentMethod),
      mergeMap(({ token, provider }) =>
        this.paymentService.deletePaymentMethod(token, provider).pipe(
          map(() => paymentActions.deletePaymentMethodSuccess()),
          catchError(() => of(paymentActions.deletePaymentMethodFailure())),
        ),
      ),
    ),
  );

  deletePaymentMethodSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.deletePaymentMethodSuccess),
      map(() => paymentActions.fetchPaymentMethods()),
    ),
  );

  deletePaymentMethodFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.deletePaymentMethodFailure),
      tap(() => this.toastr.error($localize`Deleting payment method failed.`, $localize`Error`)),
      map(() => paymentActions.fetchPaymentMethods()),
    ),
  );

  createCheckoutPayPalOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.createCheckoutPayPalOrder),
      mergeMap(() =>
        this.paymentService.createCheckoutPayPalOrder().pipe(
          map((res) => paymentActions.createCheckoutPayPalOrderSuccess({ payload: res })),
          catchError((error) => of(paymentActions.createCheckoutPayPalOrderFailure(error))),
        ),
      ),
    ),
  );

  createCheckoutPayPalOrderFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.createCheckoutPayPalOrderFailure),
      tap(() => this.toastr.error($localize`Create order failed.`, $localize`Error`)),
    ),
  );

  createPartyOrderPayPalOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.createPartyOrderPayPalOrder),
      mergeMap(({ payload }) =>
        this.paymentService.createPartyOrderPayPalOrder(payload.masterOrderId, payload.amount).pipe(
          map((res) => paymentActions.createPartyOrderPayPalOrderSuccess({ payload: res })),
          catchError((error) => of(paymentActions.createPartyOrderPayPalOrderFailure(error))),
        ),
      ),
    ),
  );

  createPartyOrderPayPalOrderFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.createPartyOrderPayPalOrderFailure),
      tap(() => this.toastr.error($localize`Create order failed.`, $localize`Error`)),
    ),
  );

  updatePayPalOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(paymentActions.updateCheckoutPayPalOrder),
      mergeMap(({ orderId }) =>
        this.paymentService.updateCheckoutPayPalOrder({ oldPayPalOrderId: orderId }).pipe(
          map((res) => paymentActions.updateCheckoutPayPalOrderSuccess({ payload: res })),
          catchError((error) => of(paymentActions.updateCheckoutPayPalOrderFailure(error))),
        ),
      ),
    ),
  );

  updatePayPalOrderFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(paymentActions.updateCheckoutPayPalOrderFailure),
        tap(() => this.toastr.error($localize`Update order failed.`, $localize`Error`)),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private orderService: OrderService,
    private paymentService: PaymentService,
    private toastr: ToastrService,
    private loggerService: LoggerService,
  ) {}
}

export function mapCreateCardPaymentMethodError(error: PhErrorResponse): CreatedCardPaymentMethod {
  const errorType =
    error.ErrorType === PhExceptionErrorType.PaymentMethodCreationFailedException
      ? CreateCardPaymentErrorType[getErrorTypeFromPaymentMethodCreationFailedException(error)]
      : (error.ErrorType as CreateCardPaymentErrorType);

  return {
    errorType: errorType,
    message: error.ErrorMessage,
    id: null,
    responseCode: null,
    token: null,
    errors: null,
    card: null,
    account: null,
  };
}
