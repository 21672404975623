<div
  class="container-xl p-0"
  infinite-scroll
  [infiniteScrollThrottle]="300"
  (scrolled)="onScrollDown()"
>
  <div class="img-container">
    <img class="desktop" src="assets/images/pages/magazine-desktop.png" />
    <img class="mobile" src="assets/images/pages/magazine-mobile.png" />
    <div class="text-container">
      <span class="text-title" i18n>Publications</span>
      <span class="text-body" i18n>Browse our latest +More Magazines and Collection Books</span>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-4 col-md-6 mt-3 mb-3" *ngFor="let magazineItem of magazines$ | async">
      <div class="card">
        <div class="card-body magazine-container">
          <a target="_blank" href="{{ magazineItem.uRL }}"
            ><img class="mb-3" src="{{ magazineItem.coverImage[0].url }}"
          /></a>
          <span class="card-type text-uppercase">{{ magazineItem.type }}</span>
          <span class="card-title">{{ magazineItem.name }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
