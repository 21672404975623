import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ProductReview } from '@core/store/product-review/product-review-state-models';
import { ProductDetailsItem } from '@core/store/product/product-state-models';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-product-reviews-carousel',
  templateUrl: './product-reviews-carousel.component.html',
  styleUrls: ['./product-reviews-carousel.component.scss'],
})
export class ProductReviewsCarouselComponent implements OnInit, OnDestroy {
  @Input() reviews$: Observable<ProductReview[]>;
  @Input() productDetailsItem: ProductDetailsItem;
  @Output() loadNextPage: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('sliderRef') set sliderRef(element) {
    element && this.initKeenSlider(element);
  }

  readonly batchSize = 3;

  reviews: ProductReview[];

  indexes: number[] = [0, 1, 2, 3];
  dragging: boolean = false;
  slider: KeenSliderInstance = null;
  currentSlide: number = 0;

  private subscriptions: Subscription = new Subscription();

  constructor() {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.reviews$?.subscribe((reviews) => {
        this.reviews = reviews;
        this.slider?.update(this.getSliderOptions());
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.slider) this.slider.destroy();
  }

  beforeChange() {
    this.dragging = true;
  }

  afterChange() {
    this.dragging = false;
  }

  fetchMoreIfNeeded() {
    if (this.currentSlide + this.batchSize === this.reviews.length) {
      this.loadNextPage.emit(this.batchSize);
    }
  }

  private initKeenSlider(sliderRef) {
    this.slider = new KeenSlider(sliderRef.nativeElement, this.getSliderOptions());
  }

  private getSliderOptions() {
    return {
      initial: this.currentSlide,
      loop: {
        min: 0,
        max: this.reviews.length - 1,
      },
      range: {
        align: true,
        min: 0,
        max: this.reviews.length - 1,
      },
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel;
        this.fetchMoreIfNeeded();
      },
      detailsChanged: (s) => {
        this.indexes = s.track.details.slides.map((slide) => slide.abs);
      },

      breakpoints: {
        '(min-width: 768px)': {
          slides: { number: 4, perView: this.batchSize, spacing: 5 },
        },
      },
      slides: { number: 4, perView: 1 },
    };
  }
}
