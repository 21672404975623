import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address, CountryState } from '@core/store/start-now-app/start-now-app-state-models';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss', '../double-check-step.component.scss'],
})
export class AddressCardComponent implements OnInit {
  @Input() title: string;
  @Input() address: Address;
  @Input() stepProcessing: boolean = false;
  @Input() consultantDataCanNotBeModified: boolean = false;
  @Input() countryStates: CountryState[] = [];

  @Output() navigateTo: EventEmitter<void> = new EventEmitter<void>();

  stateName: string;

  constructor() {}

  ngOnInit(): void {
    const countryName = this.countryStates.find((state) => state.code === this.address.state)?.name;
    this.stateName = countryName ? countryName : this.address.state;
  }

  navigate(): void {
    this.navigateTo.emit();
  }
}
