<app-modal #modal [modalConfig]="ModalConfig">
  <div class="text-center mt-2">
    <span i18n>There was an error while connecting your order to the selected party!</span>
  </div>
  <div class="text-center mb-2">
    <span i18n>The application will navigate you back to the first step of the checkout.</span>
  </div>

  <div class="d-flex justify-content-center mt-3">
    <button class="btn btn-primary text-uppercase" (click)="close()" i18n>Ok</button>
  </div>
</app-modal>
