<div class="d-flex flex-column" *ngIf="state.value !== 'inactive'">
  <div *ngIf="title" class="title-container d-flex justify-content-between">
    <div [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <a *ngIf="options.closeButton" (click)="remove()" class="btn close-btn">
      <i class="bi bi-x-circle"></i>
    </a>
  </div>
  <div *ngIf="title && message" class="divider"></div>
  <div
    *ngIf="message && options.enableHtml"
    role="alert"
    aria-live="polite"
    [class]="options.messageClass"
    [innerHTML]="message"
  ></div>
  <div
    *ngIf="message && !options.enableHtml"
    class="message-container d-flex justify-content-between"
  >
    <div role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
    <a *ngIf="options.closeButton && !title" (click)="remove()" class="btn close-btn">
      <i class="bi bi-x-circle"></i>
    </a>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
</div>
