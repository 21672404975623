import { CheckoutPersonalInfoResponse } from '@core/dto/checkout.dto';
import { PaymentType } from '@core/enums/payment-type.enum';
import { createAction, props } from '@ngrx/store';
import { Address } from '../address/address-state-models';
import { BillingAddressModel, PersonalInfo } from './checkout-state-models';

export enum CheckoutActionTypes {
  updatePersonalInfo = '[Checkout] Update Personal Info',
  updatePersonalInfoSuccess = '[Checkout] Update Personal Info Success',
  updatePersonalInfoFailure = '[Checkout] Update Personal Info Failure',

  checkoutAddressValidation = '[Checkout] Address validation',
  resetCheckoutAddressValidationModal = '[Checkout] Reset personal info modal',
  resetSaveSuccessful = '[Checkout] Reset Save successful',

  openSavedAddressesModal = '[Checkout] Open select addresses modal',
  resetSavedAddressesModal = '[Checkout] Reset select addresses modal',

  checkUserByEmail = '[Checkout] Check user by email',
  checkUserByEmailSuccess = '[Checkout] Check user by email success',
  checkUserByEmailFailure = '[Checkout] Check user by email failure',
  resetEmailCheck = '[Checkout] Reset email check',
  resetLoginModal = '[Checkout] Reset login modal',

  resetCheckoutState = '[Checkout] Reset Checkout State',

  cancelPersonalInfoUpdate = '[Checkout] Cancel Personal info update',

  setPaymentType = '[Checkout] Set payment type',

  userContinuesWithoutLogin = '[Checkout] Continue without login',

  storeBillingAddress = '[Checkout] Store billing address',
}

export const updatePersonalInfo = createAction(
  CheckoutActionTypes.updatePersonalInfo,
  props<{ personalInfo: PersonalInfo; skipAddressVerification: boolean }>(),
);
export const updatePersonalInfoSuccess = createAction(
  CheckoutActionTypes.updatePersonalInfoSuccess,
  props<{ personalInfo: PersonalInfo }>(),
);
export const updatePersonalInfoFailure = createAction(
  CheckoutActionTypes.updatePersonalInfoFailure,
  props<{ error: any }>(),
);
export const cancelPersonalInfoUpdate = createAction(CheckoutActionTypes.cancelPersonalInfoUpdate);

export const resetCheckoutState = createAction(CheckoutActionTypes.resetCheckoutState);

export const resetSaveSuccessful = createAction(CheckoutActionTypes.resetSaveSuccessful);

export const checkoutAddressValidation = createAction(
  CheckoutActionTypes.checkoutAddressValidation,
  props<{ addressValidation: CheckoutPersonalInfoResponse }>(),
);

export const openSavedAddressesModal = createAction(
  CheckoutActionTypes.openSavedAddressesModal,
  props<{ currentAddress: Address }>(),
);
export const resetSavedAddressesModal = createAction(CheckoutActionTypes.resetSavedAddressesModal);

export const resetCheckoutAddressValidationModal = createAction(
  CheckoutActionTypes.resetCheckoutAddressValidationModal,
);

export const checkUserByEmail = createAction(
  CheckoutActionTypes.checkUserByEmail,
  props<{ email: string }>(),
);
export const checkUserByEmailSuccess = createAction(CheckoutActionTypes.checkUserByEmailSuccess);
export const checkUserByEmailFailure = createAction(
  CheckoutActionTypes.checkUserByEmailFailure,
  props<{ error: any }>(),
);
export const resetEmailCheck = createAction(CheckoutActionTypes.resetEmailCheck);
export const resetLoginModal = createAction(CheckoutActionTypes.resetLoginModal);

export const setPaymentType = createAction(
  CheckoutActionTypes.setPaymentType,
  props<{ paymentType: PaymentType }>(),
);

export const userContinuesWithoutLogin = createAction(
  CheckoutActionTypes.userContinuesWithoutLogin,
);

/**
 * Billing address information
 */
export const storeBillingAddress = createAction(
  CheckoutActionTypes.storeBillingAddress,
  props<{ billingInfo: BillingAddressModel }>(),
);
