import { phErrorTitles } from '@core/constants/ph-error-titles';
import { getPhError } from './error-utils';

export function getErrorTitle(error): string {
  const phError = getPhError(error);

  if (phError && phError?.ErrorType) {
    return phErrorTitles[phError.ErrorType];
  }

  return $localize`Error`;
}
