import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppcuesService } from '@core/services/appcues.service';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { isMexEnv, isUsaEnv } from '@shared/utils/environment-utils';
import { filter, take } from 'rxjs/operators';

@Component({
  template: '',
})
export abstract class StartNowStepBaseComponent extends BaseComponent implements OnDestroy {
  @Input() isGoToPreviousDisabled: boolean;
  @Output() goToNextStep: EventEmitter<void> = new EventEmitter<void>();
  @Output() goToPreviousStep: EventEmitter<void> = new EventEmitter<void>();

  public readonly FormLabelStyle = { color: '#747487' };
  public readonly AppcuesEventName: string;

  public isMexEnv = isMexEnv;
  public isUsaEnv = isUsaEnv;

  protected appcuesService: AppcuesService;

  constructor(
    private injector: Injector,
    @Inject('appcuesEventName') appcuesEventName: string,
  ) {
    super();
    this.appcuesService = this.injector.get(AppcuesService);
    this.AppcuesEventName = appcuesEventName;

    this.scrollToTop();
    this.trackStepInAppcues();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  scrollToTop() {
    window.scroll(0, 0);
  }

  trackStepInAppcues() {
    this.subscriptions.add(
      this.appcuesService
        .isAppcuesLoaded()
        .pipe(
          filter((loaded) => loaded),
          take(1),
        )
        .subscribe(() => {
          this.appcuesService.track(this.AppcuesEventName);
        }),
    );
  }

  abstract submitStep(): void;

  protected abstract createFormGroup(): FormGroup;
}
