<div class="my-3 px-md-0">
  <div *ngIf="data?.title" class="title">
    <span>{{ data?.title }}</span>
    <button
      class="bg-transparent border-0 p-0 text-primary"
      *ngIf="data?.readOnly === false"
      (click)="changeAddress()"
      type="button"
      aria-label="Edit shipping address"
      i18n-aria-label
    >
      <i class="bi bi-pencil-square"></i>
    </button>
  </div>
  <div class="desktop-content-wrapper">
    <div *ngIf="data?.name">
      <span class="text-uppercase text-double-dot" i18n>Name</span>
    </div>
    <div *ngIf="data?.name">
      <span class="font-weight-bold">{{ data?.name }}</span>
    </div>
    <div *ngIf="data?.addressLine1 && data?.addressLine2">
      <span class="text-uppercase text-double-dot" i18n>Address</span>
    </div>
    <div *ngIf="data?.addressLine1 && data?.addressLine2">
      <span class="font-weight-bold" data-private>{{ data?.addressLine1 }}</span>
      <span class="font-weight-bold d-block" data-private>{{ data?.addressLine2 }}</span>
    </div>
    <div *ngIf="data?.consultant">
      <span class="text-uppercase text-double-dot" i18n>Consultant</span>
    </div>
    <div *ngIf="data?.consultant">
      <span class="font-weight-bold">{{ data?.consultant }}</span>
    </div>
    <div *ngIf="data?.host">
      <span class="text-uppercase text-double-dot" i18n>Host</span>
    </div>
    <div *ngIf="data?.host">
      <span class="font-weight-bold">{{ data?.host }}</span>
    </div>
  </div>

  <div class="d-md-none content-wrapper">
    <div *ngIf="data?.name" class="ph-row">
      <span i18n class="text-uppercase text-double-dot">Name</span>
      <span class="font-weight-bold">{{ data?.name }}</span>
    </div>
    <div *ngIf="data?.addressLine1 && data?.addressLine2" class="ph-row">
      <span i18n class="text-uppercase text-double-dot">Address</span>
      <div class="d-md-none">
        <span class="ml-2 d-block font-weight-bold" data-private>{{ data?.addressLine1 }}</span>
        <span class="ml-2 d-block font-weight-bold" data-private>{{ data?.addressLine2 }}</span>
      </div>
    </div>
    <div *ngIf="data?.consultant" class="ph-row">
      <span i18n class="text-uppercase text-double-dot">Consultant</span>
      <span class="font-weight-bold">{{ data?.consultant }}</span>
    </div>
    <div *ngIf="data?.host" class="ph-row">
      <span i18n class="text-uppercase text-double-dot">Host</span>
      <span class="font-weight-bold">{{ data?.host }}</span>
    </div>
  </div>
</div>
