import {
  Component,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { TableSortDirection } from '@core/enums/table-sort-direction.enum';
import { SortableTableColumn } from '@core/models/sortable-table-column.model';
import { SortableTableHeaderDirective, SortEvent } from './sortable-table-header.directive';

const compare = (v1: string | number | boolean, v2: string | number | boolean) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Component({
  selector: 'app-sortable-table',
  templateUrl: './sortable-table.component.html',
  styleUrls: ['./sortable-table.component.scss'],
})
export class SortableTableComponent implements OnInit, OnChanges {
  @Input() columns: SortableTableColumn[];
  @Input() rows: any[];
  @Input() showEditAction: boolean = true;
  @Input() rowStyle: object | ((row: any) => object);

  sortHistory: SortEvent;

  computedRows: any[];

  @ViewChildren(SortableTableHeaderDirective) headers: QueryList<SortableTableHeaderDirective>;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.sortHistory) {
      this.sort(this.sortHistory.column, this.sortHistory.direction);
    } else {
      this.computedRows = changes.rows.currentValue;
    }
  }

  ngOnInit(): void {
    const defaultSort = this.columns.find((c) => c.sort);
    if (defaultSort) {
      this.sort(defaultSort.field, defaultSort.sort);
    } else {
      this.computedRows = this.rows;
    }
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = TableSortDirection.none;
      }
    });

    if (direction === '' || column === '') {
      this.computedRows = this.rows;
    } else {
      this.sort(column, direction);
    }
  }

  getColumnStyles(column: SortableTableColumn, row: any) {
    switch (typeof column?.styles) {
      case 'object':
        return column.styles;

      case 'function':
        return column.styles(row);

      default:
        return {};
    }
  }

  getTooltip(column: SortableTableColumn, row: any): string {
    switch (typeof column?.tooltip) {
      case 'string':
        return column.tooltip;

      case 'function':
        return column.tooltip(row);

      default:
        return null;
    }
  }

  getWarningIcon(column: SortableTableColumn, row: any) {
    switch (typeof column?.warningIcon) {
      case 'boolean':
        return column.warningIcon;

      case 'function':
        return column.warningIcon(row);

      default:
        return false;
    }
  }

  getRowStyle(row: any) {
    switch (typeof this.rowStyle) {
      case 'object':
        return this.rowStyle;

      case 'function':
        return this.rowStyle(row);

      default:
        return {};
    }
  }

  getActionField(field1: string, field2: string) {
    return field1 ? field1 : field2;
  }

  private sort(column: string, direction: TableSortDirection) {
    this.sortHistory = { column, direction };
    this.computedRows = [...this.rows].sort((a, b) => {
      let res;
      const currentColumn = this.columns.find((c) => c.field === column);
      if (currentColumn.isDate) {
        res = compare(Date.parse(a[column]), Date.parse(b[column]));
      } else {
        res = compare(a[column], b[column]);
      }
      return direction === TableSortDirection.asc ? res : -res;
    });
  }
}
