<div class="my-4 px-md-0 d-block d-md-flex flex-row-reverse">
  <div class="content-wrapper text-uppercase">
    <div class="ph-row">
      <span class="text-double-dot" i18n>Merchandise total</span>
      <span>{{ merchandiseTotal | phCurrency }}</span>
    </div>
    <div class="ph-row" *ngIf="isUsaEnv">
      <span class="text-double-dot" i18n>Delivery fee total</span>
      <span>{{ deliveryFeeTotal | phCurrency }}</span>
    </div>
    <div class="ph-row" *ngIf="isUsaEnv">
      <span class="text-double-dot" i18n>Sales tax total</span>
      <span>{{ salesTaxTotal | phCurrency }}</span>
    </div>
    <div class="ph-row total font-weight-bold">
      <span class="text-double-dot" i18n>Total price</span>
      <span>{{ totalPrice | phCurrency }}</span>
    </div>
  </div>
</div>
