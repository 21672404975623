import produce from 'immer';
import { CartState } from './cart-state-models';
import { CartActionTypes } from './cart.actions';

export const initialState: CartState = {
  loading: false,
  addSurpriseBuyLoading: false,
  cart: null,
  modals: {
    addToCartConfirm: {
      loading: false,
      data: null,
    },
  },
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case CartActionTypes.resetCartState:
    case CartActionTypes.createCart:
      return initialState;

    case CartActionTypes.fetchCartSuccess:
      draft.cart = action.cart;
      return draft;

    case CartActionTypes.updateOrderLine:
      draft.loading = true;
      return;

    case CartActionTypes.updateOrderLineSuccess:
      draft.cart = action.cart;
      draft.loading = false;
      return draft;

    case CartActionTypes.updateOrderLineFailure:
      draft.loading = false;
      return;

    case CartActionTypes.addToCart:
      draft.modals.addToCartConfirm.data = null;
      draft.modals.addToCartConfirm.loading = true;
      return draft;

    case CartActionTypes.addToCartSuccess:
      draft.modals.addToCartConfirm.data = action.payload;
      draft.modals.addToCartConfirm.loading = false;
      return draft;

    case CartActionTypes.addToCartFailure:
      draft.modals.addToCartConfirm.loading = false;
      return draft;

    case CartActionTypes.deleteFromCart:
      draft.loading = true;
      return;

    case CartActionTypes.deleteFromCartSuccess:
      draft.cart = action.cart;
      draft.loading = false;
      return draft;

    case CartActionTypes.deleteFromCartFailure:
      draft.loading = false;
      return;

    case CartActionTypes.setAddToCartConfirmModal:
      draft.modals.addToCartConfirm.data = action.payload;
      draft.modals.addToCartConfirm.loading = false;
      return draft;

    case CartActionTypes.fetchProductsRecommendationsSuccess:
      draft.cart.recommendations = action.recommendations;
      return;

    case CartActionTypes.addSurpriseBuy:
      draft.addSurpriseBuyLoading = true;
      return;

    case CartActionTypes.addSurpriseBuySuccess:
      draft.cart = action.cart;
      draft.addSurpriseBuyLoading = false;
      return draft;

    case CartActionTypes.addSurpriseBuyFailure:
      draft.addSurpriseBuyLoading = false;
      return draft;

    default:
      return draft;
  }
}, initialState);
