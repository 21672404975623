<ng-container>
  <label
    [for]="identifier"
    *ngIf="label"
    [ngClass]="{ 'font-weight-bold': !isValidInput }"
    [ngStyle]="labelStyle"
  >
    {{ label }}
  </label>
  <div class="input-group">
    <ng-select
      [labelForId]="identifier"
      class="form-control p-0 ph-select"
      [ngClass]="{ 'error-border border-right-0': !isValidInput }"
      [placeholder]="defaultValue"
      (change)="onSelectChange($event)"
      [(ngModel)]="value"
      [readonly]="readonly"
      [clearable]="clearable"
    >
      <ng-option *ngFor="let option of options" [value]="option.value || option">
        {{ option.name || option }}
      </ng-option>
    </ng-select>
    <div class="input-group-append" *ngIf="!isValidInput">
      <span class="input-group-text bg-transparent error-border border-left-0">
        <i class="bi bi-exclamation-triangle error-color"></i>
      </span>
    </div>
  </div>
  <div *ngIf="!isValidInput && errorMessages" class="validation-error-message">
    <div *ngFor="let item of errorMessages">{{ item }}</div>
  </div>
</ng-container>
