import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { GetCatalogsResponse } from '@core/dto/catalog.dto';
import { Locale } from '@core/enums/locale';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  baseUrl: string = environment.ecommerceBackendUri;
  culture: Locale;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {
    this.culture = getCulture(localeId);
  }

  fetchCatalogs(
    catalogType: string,
    pageNumber: number,
    pageSize: number,
  ): Observable<GetCatalogsResponse> {
    return this.http.get<GetCatalogsResponse>(this.baseUrl + 'api/catalog', {
      params: new HttpParams({
        fromObject: {
          pageNumber: pageNumber.toString(),
          pageSize: pageSize.toString(),
          catalogType: catalogType,
          culture: this.culture,
        },
      }),
    });
  }
}
