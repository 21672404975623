<div class="card">
  <div class="d-none d-md-block p-3 big-font-size">
    <strong i18n>Order Summary</strong>
  </div>
  <div *ngIf="!!orderSummary?.price" class="ph-row px-3 py-3 py-md-2">
    <span i18n>Price</span>
    <strong>{{ orderSummary?.price | phCurrency }}</strong>
  </div>
  <div *ngIf="!!orderSummary?.taxAmount" class="ph-row px-3 pb-3 pb-md-2">
    <span i18n>State & local taxes</span>
    <strong>{{ orderSummary?.taxAmount | phCurrency }}</strong>
  </div>
  <div
    *ngIf="!!orderSummary?.totalCost && !!orderSummary?.lessDeposit"
    class="ph-row px-3 pb-3 pb-md-2"
  >
    <strong i18n>Total cost</strong>
    <strong>{{ orderSummary?.totalCost | phCurrency }}</strong>
  </div>
  <div *ngIf="!!orderSummary?.lessDeposit" class="ph-row px-3 pb-3 pb-md-2">
    <span i18n>Less deposit</span>
    <strong>{{ orderSummary?.lessDeposit | phCurrency }}</strong>
  </div>
  <div *ngIf="!!orderSummary?.lessDeposit" class="ph-row px-3 pb-4">
    <strong i18n>Balance due</strong>
    <strong>{{ orderSummary?.balanceDue | phCurrency }}</strong>
  </div>

  <div *ngIf="!!orderSummary?.lessDeposit">
    <div class="d-md-none ph-row px-3 pb-3 pb-md-2 big-font-size">
      <strong i18n>You pay</strong>
      <strong>{{ orderSummary?.lessDeposit | phCurrency }}</strong>
    </div>
    <div class="d-none d-md-block pr-3 pb-3 pb-md-2">
      <div class="row justify-content-end">
        <div class="col-auto align-self-end">
          <span i18n>You pay</span>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-auto align-self-end big-font-size">
          <strong>{{ orderSummary?.lessDeposit | phCurrency }}</strong>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!orderSummary?.lessDeposit && !!orderSummary?.totalCost">
    <div class="d-md-none ph-row px-3 pb-3 pb-md-2 big-font-size">
      <strong i18n>Total cost</strong>
      <strong>{{ orderSummary?.totalCost | phCurrency }}</strong>
    </div>
    <div class="d-none d-md-block pr-3 pb-3 pb-md-2">
      <div class="row justify-content-end">
        <div class="col-auto align-self-end">
          <span i18n>Total cost</span>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-auto align-self-end big-font-size">
          <strong>{{ orderSummary?.totalCost | phCurrency }}</strong>
        </div>
      </div>
    </div>
  </div>
</div>
