import { Injectable } from '@angular/core';
import { of } from 'rxjs';

Injectable({
  providedIn: 'root',
});
export class FakeRecaptchaService {
  // eslint-disable-next-line no-unused-vars
  execute(action: string) {
    return of(null);
  }
}
