/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { SearchResultApiPageSize } from '@core/constants/search';
import { SearchResponse } from '@core/dto/search.dto';
import { Locale } from '@core/enums/locale';
import { FetchSearchResultProp } from '@core/models/search';
import { environment } from '@env';
import { HtmlParamEncoder } from '@shared/utils/http-param-encoder';
import { getCulture } from '@shared/utils/locale-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  baseUrl: string = environment.ecommerceBackendUri;
  culture: Locale;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {
    this.culture = getCulture(localeId);
  }

  search({
    searchTerm,
    pageNumber = 1,
    filter = {},
    displayDiscontinuedProducts = false,
  }: FetchSearchResultProp): Observable<SearchResponse> {
    return this.http.get<SearchResponse>(`${this.baseUrl}api/search`, {
      params: new HttpParams({
        encoder: new HtmlParamEncoder(),
        fromObject: {
          query: searchTerm,
          pageNumber: pageNumber.toString(),
          pageSize: SearchResultApiPageSize.toString(),
          culture: this.culture,
          displayDiscontinuedProducts: String(displayDiscontinuedProducts),
          ...filter,
        },
      }),
    });
  }
}
