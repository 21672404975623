<app-modal [modalConfig]="modalConfig" #modal>
  <form *ngIf="!!addressFormGroup" [formGroup]="addressFormGroup">
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <app-text-field-input
            [identifier]="this.FirstName"
            label="First Name"
            labelAsterix="true"
            i18n-label
            [formControlName]="this.FirstName"
          ></app-text-field-input>
        </div>

        <div class="mb-3">
          <app-text-field-input
            [identifier]="this.LastName"
            label="Last Name"
            labelAsterix="true"
            i18n-label
            [formControlName]="this.LastName"
          ></app-text-field-input>
        </div>
        <div class="mb-3">
          <app-text-field-input
            [identifier]="this.AddressLine1"
            label="Address Line 1"
            labelAsterix="true"
            i18n-label
            [formControlName]="this.AddressLine1"
          ></app-text-field-input>
        </div>

        <div class="mb-3">
          <app-text-field-input
            [identifier]="this.AddressLine2"
            label="Address Line 2"
            i18n-label
            [formControlName]="this.AddressLine2"
          ></app-text-field-input>
        </div>

        <div class="mb-3" *ngIf="isMexEnv">
          <app-text-field-input
            [identifier]="this.AddressLine3"
            label="Address Line 3"
            i18n-label
            [formControlName]="this.AddressLine3"
          ></app-text-field-input>
        </div>

        <div class="mb-3">
          <app-text-field-input
            [identifier]="this.City"
            label="City"
            labelAsterix="true"
            i18n-label
            [formControlName]="this.City"
          ></app-text-field-input>
        </div>

        <div class="mb-3">
          <app-select
            [identifier]="this.State"
            label="State"
            i18n-label
            [formControlName]="this.State"
            [options]="countryStates$ | async"
          ></app-select>
        </div>

        <div class="mb-3">
          <app-text-field-input
            [identifier]="this.ZipCode"
            label="Zip Code"
            labelAsterix="true"
            i18n-label
            [formControlName]="this.ZipCode"
          ></app-text-field-input>
        </div>
      </div>
    </div>
  </form>

  <div class="row justify-content-between">
    <div class="col-6">
      <button class="btn btn-outline-dark text-uppercase w-100" (click)="cancel()" i18n>
        Cancel
      </button>
    </div>
    <div class="col-6">
      <button class="btn btn-primary text-uppercase w-100" (click)="apply()" i18n> Apply </button>
    </div>
  </div>
</app-modal>
