import { Component, OnInit } from '@angular/core';
import { AppState } from '@core/store';
import { fetchCCOrderHistory } from '@core/store/order/order.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.store$.dispatch(fetchCCOrderHistory());
  }
}
