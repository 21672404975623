<ng-template #modal>
  <div *ngIf="showX" class="x-button p-3">
    <button type="button" class="close btn-sm" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="font-size-bigger font-weight-bold text-center mb-3" *ngIf="title">{{ title }}</div>
    <ng-content></ng-content>
  </div>
  <div class="modal-footer d-flex justify-content-around">
    <button
      type="button"
      class="btn btn-sm btn-primary text-uppercase"
      ngbAutofocus
      (click)="onAction()"
    >
      {{ actionLabel }}
    </button>
  </div>
</ng-template>
