import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@auth/components/login/login.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { DefaultLayoutComponent } from '@base/components/layout/default-layout/default-layout.component';
import { GuestGuardService } from '@core/services/guest-guard.service';
import { B2C_SIGNIN_TARGET_ROUTE } from 'app/auth-config';
import { B2cSigninComponent } from './components/b2c-signin/b2c-signin.component';

const routes: Routes = [
  {
    path: 'b2c-signin',
    component: B2cSigninComponent,
  },
  {
    path: B2C_SIGNIN_TARGET_ROUTE,
    component: MsalRedirectComponent,
  },

  {
    path: 'login',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
        canActivate: [GuestGuardService],
        data: {
          title: $localize`Customer account login`,
          description: $localize`Customer account login - Description`,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
