<div class="container pt-5">
  <div class="d-none d-md-block text-center pb-5 pb-md-0">
    <img class="icon" src="assets/images/icons/logo.svg" alt="info" />
  </div>

  <div class="text-center my-5 pb-md-5 success" i18n>Success starts here!</div>

  <h1 class="text-title text-center mt-md-5" i18n>Do you know a consultant?</h1>

  <div class="row justify-content-center mb-5 mt-5 pt-5">
    <div class="col col-md-3">
      <div class="white-button-container">
        <button
          class="btn btn-outline-primary text-uppercase w-100"
          i18n
          (click)="onKnowConsultantClick(false)"
        >
          No
        </button>
      </div>
    </div>
    <div class="col col-md-3">
      <button
        class="btn btn-primary text-uppercase w-100"
        i18n
        (click)="onKnowConsultantClick(true)"
      >
        Yes
      </button>
    </div>
  </div>
</div>
