import produce from 'immer';
import { RecipeState } from './recipe-state-models';
import { RecipeActionTypes } from './recipe.actions';

export const initialState: RecipeState = {
  recipes: {
    items: [],
    isAllFetched: false,
  },
  recipeCategories: [{ title: $localize`All Recipes`, urlName: '' }],
  isRecipeCategoriesFetched: false,
  recipeDetails: {
    item: null,
    isLoading: false,
    recommendations: [],
    relatedProducts: [],
  },
  userRecipes: {
    loading: false,
    items: [],
  },
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case RecipeActionTypes.fetchRecipesSuccess:
      draft.recipes.items = action.isFirstPage
        ? action.items
        : [...draft.recipes.items, ...action.items];
      draft.recipes.isAllFetched = action.isFirstPage
        ? initialState.recipes.isAllFetched
        : action.items.length < action.pageSize;
      return draft;

    case RecipeActionTypes.resetRecipes:
      draft.recipes = initialState.recipes;
      return draft;

    case RecipeActionTypes.fetchRecipeCategories:
      draft.isRecipeCategoriesFetched = false;
      return;

    case RecipeActionTypes.fetchRecipeCategoriesSuccess:
      draft.isRecipeCategoriesFetched = true;
      draft.recipeCategories = [...initialState.recipeCategories, ...action.recipeCategories];
      return draft;

    case RecipeActionTypes.fetchRecipeCategoriesFailure:
      draft.isRecipeCategoriesFetched = false;
      return;

    case RecipeActionTypes.fetchRecipeDetails:
      draft.recipeDetails.isLoading = true;
      return draft;

    case RecipeActionTypes.fetchRecipeDetailsSuccess:
      draft.recipeDetails.isLoading = false;
      draft.recipeDetails.item = action.item;
      return draft;

    case RecipeActionTypes.fetchRecipeDetailsFailure:
      draft.recipeDetails.isLoading = false;
      return draft;

    case RecipeActionTypes.fetchRecipeRecommendationsSuccess:
      draft.recipeDetails.recommendations = action.items;
      return draft;

    case RecipeActionTypes.fetchRelatedProductsSuccess:
      draft.recipeDetails.relatedProducts = action.items;
      return draft;

    case RecipeActionTypes.resetRecipeDetails:
      draft.recipeDetails = initialState.recipeDetails;
      return draft;

    case RecipeActionTypes.fetchUserRecipes:
      draft.userRecipes.loading = true;
      draft.userRecipes.items = [];
      return;

    case RecipeActionTypes.fetchUserRecipesSuccess:
      draft.userRecipes.loading = false;
      draft.userRecipes.items = action.items;
      return;

    case RecipeActionTypes.fetchUserRecipesFailure:
      draft.userRecipes.loading = false;
      return;

    case RecipeActionTypes.dislikeRecipe:
      draft.userRecipes.items = draft.userRecipes.items.filter((item) => item.id !== action.id);
      return;

    default:
      return draft;
  }
}, initialState);
