<ng-container [ngSwitch]="sendingMessage$ | async">
  <ng-container *ngSwitchCase="sendingContactUsMessage.notReady">
    <app-contact-us-form [(contactUsFormData)]="contactUsFormData"></app-contact-us-form>
  </ng-container>

  <ng-container *ngSwitchCase="sendingContactUsMessage.inProgress">
    <div class="d-flex justify-content-center m-5">
      <app-ph-spinner width="100px"></app-ph-spinner>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="sendingContactUsMessage.successful">
    <app-contact-us-success></app-contact-us-success>
  </ng-container>

  <ng-container *ngSwitchCase="sendingContactUsMessage.failure">
    <app-contact-us-form [(contactUsFormData)]="contactUsFormData"></app-contact-us-form>
  </ng-container>
</ng-container>
