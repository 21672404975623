<div class="header-offers">
  <div class="container-fluid">
    <div class="row py-2" *ngIf="productUtilService.isShoppingEnabled$ | async">
      <div class="col-6 p-0 d-flex align-items-center">
        <div class="host-offers separator-right">
          <img class="icon" src="assets/images/header/USERS.svg" alt="" />
          <a class="link pl-2" routerLink="/hostess-special-offers" i18n>Host Offers</a>
        </div>
      </div>
      <div class="col-6 p-0 d-flex align-items-center">
        <div class="customer-offers">
          <img class="icon" src="assets/images/header/TAG.svg" alt="" />
          <a class="link pl-2" routerLink="/customer-special-offers" i18n>Customer Offers</a>
        </div>
      </div>
    </div>
  </div>
</div>
