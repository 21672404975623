import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PaymentImageSrc } from '@core/constants/payment.consts';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PaymentType } from '@core/enums/payment-type.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import {
  resetClientToken,
  updatePaymentProviderType,
} from '@core/store/start-now-app/start-now-app.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-payment-type-selector',
  templateUrl: './payment-type-selector.component.html',
  styleUrls: ['./payment-type-selector.component.scss'],
})
export class PaymentTypeSelectorComponent implements OnChanges {
  @Input() selectedPaymentType: PaymentType;
  @Input() isStepProcessing: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showVoucherPayment: boolean = true;
  @Output() selectedPaymentTypeChange: EventEmitter<PaymentType> = new EventEmitter<PaymentType>();

  readonly PaymentImageSrc = PaymentImageSrc;
  readonly PaymentType = PaymentType;

  isNexioCardPaymentEnabled: boolean;

  constructor(
    private store$: Store<AppState>,
    private cdRef: ChangeDetectorRef,
    appInitService: AppInitService,
  ) {
    this.isNexioCardPaymentEnabled = appInitService.Settings.sna.isNexioCardPaymentEnabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      this.cdRef.detectChanges();
    }
  }

  onChangePaymentType(paymentType: PaymentType) {
    switch (paymentType) {
      case PaymentType.CreditOrDebitCard:
        this.updatePaymentProvider(PaymentProviderType.Nexio);
        break;
      case PaymentType.PayPal:
        this.updatePaymentProvider(PaymentProviderType.PayPal);
        break;
      default:
        this.updatePaymentProvider(PaymentProviderType.Default);
        break;
    }

    this.store$.dispatch(resetClientToken());
    this.selectedPaymentTypeChange.emit(paymentType);
  }

  private updatePaymentProvider(provider: PaymentProviderType): void {
    this.store$.dispatch(updatePaymentProviderType({ provider }));
  }
}
