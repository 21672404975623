import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { ModalConfig } from '@core/models/modal-config.model';
import { AppState } from '@core/store';
import { selectAddToCartConfirmModal } from '@core/store/cart';
import { AddToCartConfirmModel } from '@core/store/cart/cart-state-models';
import { setAddToCartConfirmModal } from '@core/store/cart/cart.actions';
import { Store } from '@ngrx/store';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { ProductUtilService } from '@shared/utils/product-util.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-add-to-cart-confirmation',
  templateUrl: './add-to-cart-confirm-dialog.component.html',
  styleUrls: ['./add-to-cart-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToCartConfirmationComponent implements OnInit, OnDestroy {
  modalSubscription: Subscription;
  addToCartConfirmModal$: Observable<AddToCartConfirmModel>;
  thumbnail = Thumbnail;

  modalConfig: ModalConfig = {
    title: $localize`The product is successfully added to cart`,
    dismissButtonLabel: $localize`View cart`,
    closeButtonLabel: $localize`Continue shopping`,
    onDismiss: this.onDismiss.bind(this),
    onClose: this.onClose.bind(this),
    onDismissHeader: this.onClose.bind(this),
    ngbModalOptions: {
      size: 'md',
      beforeDismiss: this.onClose.bind(this),
    },
  };

  totalPrice: number;

  @ViewChild('modal') private modalComponent: ModalComponent;

  constructor(
    private router: Router,
    private store$: Store<AppState>,
    private productUtil: ProductUtilService,
  ) {}

  ngOnInit(): void {
    this.addToCartConfirmModal$ = this.store$.select(selectAddToCartConfirmModal);
    this.modalSubscription = this.addToCartConfirmModal$.subscribe((state) => {
      if (state?.data) {
        this.totalPrice = this.productUtil.getProductPrice(state.data) * state.data.quantity;
        this.modalComponent.open();
      }
    });
  }

  ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }

  onClose() {
    this.store$.dispatch(setAddToCartConfirmModal({ payload: null }));
  }

  onDismiss() {
    this.store$.dispatch(setAddToCartConfirmModal({ payload: null }));
    this.router.navigate(['/shopping-cart']);
  }
}
