/* TODO: grammatically the correct spelling would be without the 'd' at the end for
ConsultantProwessIdCouldNotRetrieved, ConsultantProwessIdCouldNotSaved */
export enum ConsultantSubmissionErrorType {
  None = 'None',
  ApplicationRegistrationNotSubmittable = 'ApplicationRegistrationNotSubmittable',
  ContractReleaseFailed = 'ContractReleaseFailed',
  ContractPaidByVoucher = 'ContractPaidByVoucher',
  ConsultantProwessIdCouldNotRetrieved = 'ConsultantProwessIdCouldNotRetrieved',
  ConsultantProwessIdCouldNotSaved = 'ConsultantProwessIdCouldNotSaved',
  DuplicateHomePhone = 'DuplicateHomePhone',
  DuplicateMobilePhone = 'DuplicateMobilePhone',
  DuplicateProwessId = 'DuplicateProwessId',
  EmailAlreadyExists = 'EmailAlreadyExists',
  ErrorWhileSync = 'ErrorWhileSync',
  InvalidAddress = 'InvalidAddress',
  InvalidBirthDate = 'InvalidBirthDate',
  InvalidConsultantLevel = 'InvalidConsultantLevel',
  InvalidConsultantStatus = 'InvalidConsultantStatus',
  InvalidEmailFormat = 'InvalidEmailFormat',
  InvalidHomePhone = 'InvalidHomePhone',
  InvalidMobilePhone = 'InvalidMobilePhone',
  InvalidPaymentType = 'InvalidPaymentType',
  InvalidRecruiterId = 'InvalidRecruiterId',
  InvalidSsnNumber = 'InvalidSsnNumber',
  InvalidState = 'InvalidState',
  InvalidStarterKitId = 'InvalidStarterKitId',
  InvalidStarterKitPrice = 'InvalidStarterKitPrice',
  InvalidZip = 'InvalidZip',
  MissingAddressLine1 = 'MissingAddressLine1',
  MissingCity = 'MissingCity',
  MissingEmail = 'MissingEmail',
  MissingFirstName = 'MissingFirstName',
  MissingLastName = 'MissingLastName',
  MissingPaymentMethodToken = 'MissingPaymentMethodToken',
  MissingProwessId = 'MissingProwessId',
  MissingRecruiter = 'MissingRecruiter',
  MissingRegistrationId = 'MissingRegistrationId',
  MissingPaymentMethod = 'MissingPaymentMethod',
  MissingState = 'MissingState',
  MissingZip = 'MissingZip',
  MissingContactInfo = 'MissingContactInfo',
  MissingUserInfo = 'MissingUserInfo',
  MissingHomeAddress = 'MissingHomeAddress',
  MissingLevel = 'MissingLevel',
  MissingStatus = 'MissingStatus',
  MissingVanityName = 'MissingVanityName',
  MissingAddressInfo = 'MissingAddressInfo',
  MissingB2CId = 'MissingB2CId',
  ProwessImportFailed = 'ProwessImportFailed',
  TooLongAddressLine1 = 'TooLongAddressLine1',
  TooLongAddressLine2 = 'TooLongAddressLine2',
  TooLongAddressLine3 = 'TooLongAddressLine3',
  TooLongCity = 'TooLongCity',
  TooLongFirstName = 'TooLongFirstName',
  TooLongLastName = 'TooLongLastName',
  TooLongMiddleName = 'TooLongMiddleName',
  TooLongHomeNumber = 'TooLongHomeNumber',
  TooLongMobileNumber = 'TooLongMobileNumber',
  TooLongEmail = 'TooLongEmail',
  TooLongVanityName = 'TooLongVanityName',
  TooLongZip = 'TooLongZip',
  TransactionFailed = 'TransactionFailed',
  UnsupportedLanguage = 'UnsupportedLanguage',
  UserManagementInAccessible = 'UserManagementInAccessible',
  VaultImportFailed = 'VaultImportFailed',
  MissingVoucherDetails = 'MissingVoucherDetails',
  InvalidRegistrationCode = 'InvalidRegistrationCode',
  DailyVoucherAmountReached = 'DailyVoucherAmountReached',
  MonthlyVoucherAmountReached = 'MonthlyVoucherAmountReached',
  YearlyVoucherAmountReached = 'YearlyVoucherAmountReached',
  Other = 'Other',
}
