<div class="desktop">
  <div class="head py-1"></div>
  <div class="head py-1 text-uppercase font-weight-bold" i18n>Sku</div>
  <div class="head py-1 text-uppercase font-weight-bold" i18n>Quantity</div>
  <div class="head py-1"></div>
  <ng-container *ngFor="let cartonItem of cartonItems">
    <div class="cell p-1 ml-3">
      <div>{{ cartonItem.name }}</div>
    </div>
    <div class="cell py-1 d-flex align-items-center">#{{ cartonItem.sku }}</div>
    <div class="cell py-1 d-flex align-items-center">{{ cartonItem.qty }}</div>
    <div class="cell"></div>
  </ng-container>
</div>
