import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@auth/auth.module';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { BaseModule } from '@base/base.module';
import { CoreModule } from '@core/core.module';
import { AppInitService } from '@core/services/app-init.service';
import { AuthGuardService } from '@core/services/auth-guard.service';
import { FakeRecaptchaService } from '@core/services/fake-recapthca.service';
import { GuestGuardService } from '@core/services/guest-guard.service';
import { LogRocketService } from '@core/services/logrocket.service';
import { metaReducers, reducers } from '@core/store';
import { environment } from '@env';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ProductModule } from '@product/product.module';
import { RecipeMoreModule } from '@recipe-more/recipe-more.module';
import { ToastComponent } from '@shared/components/toast/toast.component';
import { SharedModule } from '@shared/shared.module';
import { UserModule } from '@user/user.module';
import { RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service, RecaptchaV3Module } from 'ng-recaptcha';
import { ToastrModule } from 'ngx-toastr';
import { EffectModuleConfig } from './app-module.config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { loginRequest, msalConfig } from './auth-config';
import { HttpBearerTokenInterceptor } from './http-bearer-token.interceptor';
import { ConditionalContentWildcardRoutingModule } from './modules/conditional-content/conditional-content-wildcard-routing.module';
import { ConditionalContentModule } from './modules/conditional-content/conditional-content.module';
import { ConsultantModule } from './modules/consultant/consultant.module';
import { DeliveryInfoModule } from './modules/delivery-info/delivery-info.module';
import { StartNowAppModule } from './modules/start-now-app/start-now-app.module';

// eslint-disable-next-line no-unused-vars
export function appInitializerFactory(appInitService: AppInitService) {
  // TODO EC-3876
  // return () => appInitService.initEcConfiguration();
  return () => () =>
    new Promise<void>((resolve) => {
      resolve();
    });
}

export function logRocketFactory(logRocketService: LogRocketService) {
  return logRocketService.getMetaReducers();
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: loginRequest,
  };
}

export function recaptchaServiceFactory(
  zone: NgZone,
  injector: Injector,
  appInitService: AppInitService,
) {
  const platformId = injector.get(PLATFORM_ID);
  if (
    appInitService.Settings.ec.isRecaptchaEnabled ||
    appInitService.Settings.sna.isReCaptchaEnabled
  ) {
    return new ReCaptchaV3Service(zone, environment.recaptchaSiteKey, platformId);
  }
  return new FakeRecaptchaService();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    ConditionalContentModule,
    AuthModule,
    BaseModule,
    AppRoutingModule,
    ConsultantModule,
    BrowserModule,
    CoreModule,
    ProductModule,
    RecipeMoreModule,
    NgbModule,
    HttpClientModule,
    UserModule,
    DeliveryInfoModule,
    StartNowAppModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      closeButton: true,
      tapToDismiss: true,
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectModuleConfig,
    MsalModule,
    ConditionalContentWildcardRoutingModule,
    RecaptchaV3Module,
  ],
  providers: [
    AppInitService,
    AuthGuardService,
    GuestGuardService,
    LogRocketService,

    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: logRocketFactory,
      deps: [LogRocketService, AppInitService],
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    {
      provide: ReCaptchaV3Service,
      useFactory: recaptchaServiceFactory,
      deps: [NgZone, Injector, AppInitService],
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    {
      provide: ReCaptchaV3Service,
      useFactory: recaptchaServiceFactory,
      deps: [NgZone, Injector, AppInitService],
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpBearerTokenInterceptor,
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
