/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { findConsultantResultPageSize } from '@core/constants/find-consultant';
import {
  ConsultantFinderResponse,
  ConsultantPartiesResponse,
  ConsultantResponse,
  FindConsultantRequest,
  LocateConsultantRequest,
  SnaConsultantResponse,
} from '@core/dto/consultant.dto';
import { Locale } from '@core/enums/locale';
import { RequestSource } from '@core/enums/request-source.enum';
import { environment } from '@env';
import { HtmlParamEncoder } from '@shared/utils/http-param-encoder';
import { getCulture } from '@shared/utils/locale-utils';
import { Observable } from 'rxjs';
import { AppInitService } from './app-init.service';

@Injectable({
  providedIn: 'root',
})
export class ConsultantService {
  baseUrl: string = environment.ecommerceBackendUri;
  finderBaseUrl: string = environment.startNowAppBackendUri;
  culture: Locale;

  constructor(
    @Inject(LOCALE_ID) private localeId: Locale,
    private http: HttpClient,
    private appInitService: AppInitService,
  ) {
    this.culture = getCulture(localeId);
  }

  fetchConsultantByVanityName(
    vanityName: string,
    filterCountry: boolean,
  ): Observable<ConsultantResponse> {
    return this.http.get<ConsultantResponse>(`${this.baseUrl}api/consultant`, {
      params: new HttpParams({
        encoder: new HtmlParamEncoder(),
        fromObject: {
          vanityName,
          culture: this.culture,
          ...(filterCountry ? { country: environment.country } : {}),
        },
      }),
    });
  }

  fetchConsultantByLoginName(
    loginName: string,
    filterCountry: boolean,
  ): Observable<SnaConsultantResponse> {
    return this.http.get<SnaConsultantResponse>(`${this.finderBaseUrl}api/Consultant/loginname`, {
      params: new HttpParams({
        encoder: new HtmlParamEncoder(),
        fromObject: {
          loginName,
          culture: this.culture,
          ...(filterCountry ? { country: environment.country } : {}),
        },
      }),
    });
  }

  fetchConsultantById(
    consultantProwessId: number,
    includeAllProwessStatuses: boolean = true,
  ): Observable<ConsultantResponse> {
    return this.http.get<ConsultantResponse>(
      `${this.baseUrl}api/consultant/${consultantProwessId}`,
      {
        params: new HttpParams({
          fromObject: {
            culture: this.culture,
            country: environment.country,
            includeAllProwessStatuses,
          },
        }),
      },
    );
  }

  findConsultant(request: FindConsultantRequest): Observable<ConsultantFinderResponse> {
    return this.http.get<ConsultantFinderResponse>(`${this.finderBaseUrl}api/Consultant/find`, {
      params: new HttpParams({
        fromObject: {
          city: request.city,
          firstName: request.firstName,
          lastName: request.lastName,
          stateCode: request.stateCode,
          mobilePhoneNumber: request.mobilePhoneNumber,
          pageNumber: request.pageNumber?.toString() || '1',
          pageSize: request.pageSize?.toString() || findConsultantResultPageSize.toString(),
          country: environment.country,
          requestSource: RequestSource.ec,
        },
      }),
    });
  }

  locateConsultant(request: LocateConsultantRequest): Observable<ConsultantFinderResponse> {
    return this.http.get<ConsultantFinderResponse>(
      `${this.finderBaseUrl}api/Consultant/nearby/${request.zipCode}`,
      {
        params: new HttpParams({
          fromObject: {
            preferredLanguageCode: request.cultureCode,
            pageNumber: request.pageNumber?.toString() || '1',
            pageSize: request.pageSize?.toString() || findConsultantResultPageSize.toString(),
            country: environment.country,
            requestSource: RequestSource.ec,
          },
        }),
      },
    );
  }

  fetchConsultantParties(consultantProwessId: number): Observable<ConsultantPartiesResponse> {
    return this.http.get<ConsultantPartiesResponse>(
      `${this.baseUrl}api/consultant/${consultantProwessId}/party`,
    );
  }
}
