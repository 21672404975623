import { BreakpointObserver } from '@angular/cdk/layout';
import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';
import { DefaultImagePlaceholderSrc } from '@core/constants/image-placeholders';
import { MobileMedia } from '@core/constants/screen-sizes';

import { Thumbnail } from '@core/enums/thumbnail.enum';
import { ImageDetails } from '@core/models/image.model';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[phImage]',
})
export class PhImageDirective implements OnChanges {
  @Input() images: ImageDetails[];
  @Input() placeholderSrc: string;
  @Input() thumbnailDesktop: Thumbnail;
  @Input() thumbnailMobile: Thumbnail;
  @Input() altText: string;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnChanges(): void {
    const thumbnailKey = this.breakpointObserver.isMatched(MobileMedia)
      ? this.thumbnailMobile
      : this.thumbnailDesktop;

    const thumbnailImg =
      this.images?.find((i) => i.thumbnailProfile === thumbnailKey) || this.images?.[0];

    this.setSrc(thumbnailImg?.url);
    this.setAlt(thumbnailImg?.altText);
  }

  private setSrc(src: string) {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'src',
      src || this.el.nativeElement.src || this.placeholderSrc || DefaultImagePlaceholderSrc,
    );
  }

  private setAlt(altText: string) {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'alt',
      this.altText || altText || this.el.nativeElement.alt,
    );
  }
}
