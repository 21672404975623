<div *ngIf="addressValidation$ | async as validation">
  <div *ngIf="!validation.correctedAddress && validation.isValid === false && !isForbiddenState">
    <h6 class="warning-message mb-5" i18n>
      The address that you entered cannot be corrected by the address verification service. Please
      enter a valid address!
    </h6>
  </div>
</div>
<app-modal #modal [modalConfig]="modalConfig">
  <div>
    <p i18n>
      We are unable to verify the delivery address you specified. Please ensure it's a VALID address
      and/or make any necessary corrections. Then click Next to Continue. You may choose to use your
      address. If you user your address, taxes will be calculated based on your zip code. You may
      change the delivery address at any time prior to submitting the order and taxes will be
      recalculated.
    </p>
    <p class="font-weight-bold" i18n>
      Please note: If your delivery address has both a street address and a PO Box, please specify
      ONLY ONE as the "Ship To" address.
    </p>
  </div>

  <div ngbRadioGroup class="d-none d-md-flex" [(ngModel)]="isOriginalAddress">
    <div class="col-md px-0">
      <label ngbButtonLabel class="my-0 px-0 radio-label" i18n>
        <input ngbButton type="radio" [value]="true" />
        Use my address
      </label>
    </div>
    <div class="col-md px-0">
      <label ngbButtonLabel class="my-0 px-0 radio-label" i18n>
        <input ngbButton type="radio" [value]="false" />
        Use this address
      </label>
    </div>
  </div>
  <hr class="my-2 d-none d-md-block" />

  <div
    class="row mb-2"
    *ngIf="originalAddressFormGroup && correctedAddressFormGroup && addressValidation$ | async"
  >
    <div class="d-md-none col">
      <div ngbRadioGroup class="d-flex" [(ngModel)]="isOriginalAddress">
        <label ngbButtonLabel class="my-0 px-0 radio-label" i18n>
          <input ngbButton type="radio" [value]="true" />
          Use my address
        </label>
      </div>
    </div>
    <div class="col-md">
      <form [formGroup]="originalAddressFormGroup">
        <app-text-field-input
          identifier="oAddressLine1"
          label="Address line 1"
          i18n-label
          [formControlName]="AddressLine1"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
        <app-text-field-input
          identifier="oAddressLine2"
          label="Address line 2"
          i18n-label
          [formControlName]="AddressLine2"
          [isDataPrivate]="true"
        >
        </app-text-field-input>

        <app-sna-address-error-messages
          *ngIf="!isMexEnv"
          [hasAddressErrorCode]="hasAddressErrorCode"
        ></app-sna-address-error-messages>

        <app-text-field-input
          *ngIf="isMexEnv"
          identifier="oAddressLine3"
          label="Address line 3"
          i18n-label
          [formControlName]="AddressLine3"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
        <app-sna-address-error-messages
          *ngIf="isMexEnv"
          [hasAddressErrorCode]="hasAddressErrorCode"
        ></app-sna-address-error-messages>
        <app-text-field-input
          identifier="oCity"
          label="City"
          i18n-label
          [formControlName]="City"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
        <div class="row">
          <div class="col col-md-12">
            <app-ph-select
              identifier="oState"
              label="State"
              i18n-label
              [formControlName]="State"
              [options]="countryStates"
            ></app-ph-select>
          </div>
          <div class="col col-md-12">
            <app-text-field-input
              identifier="oZipCode"
              label="Zip Code"
              i18n-label
              [formControlName]="ZipCode"
            >
            </app-text-field-input>
          </div>
        </div>
      </form>
      <div *ngIf="hasLocationErrorCode(codes.AE01)" class="warning-message">
        <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
        <span i18n="|shared">
          The address could not be verified at least up to the postal code level.
        </span>
      </div>
      <div *ngIf="hasLocationErrorCode(codes.AC01)" class="warning-message">
        <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
        <span i18n="|shared">The postal code was changed or added.</span>
      </div>
      <div *ngIf="hasLocationErrorCode(codes.AC02)" class="warning-message">
        <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
        <span i18n="|shared">The administrative area (state, province) was added or changed.</span>
      </div>
      <div *ngIf="hasLocationErrorCode(codes.AC03)" class="warning-message">
        <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
        <span i18n="|shared">The locality (city, municipality) name was added or changed.</span>
      </div>
      <div *ngIf="hasLocationErrorCode(codes.AC09)" class="warning-message">
        <mat-icon svgIcon="warning" class="warning-icon"></mat-icon>
        <span i18n="|shared">The dependent locality (urbanization) was changed.</span>
      </div>
    </div>

    <div class="d-md-none col">
      <div ngbRadioGroup class="d-flex" [(ngModel)]="isOriginalAddress">
        <label ngbButtonLabel class="my-0 px-0 radio-label" i18n>
          <input ngbButton type="radio" [value]="false" />
          Use this address
        </label>
      </div>
    </div>

    <div class="col-md">
      <form [formGroup]="correctedAddressFormGroup">
        <app-text-field-input
          identifier="cAddressLine1"
          label="Address line 1"
          i18n-label
          [formControlName]="AddressLine1"
          [readonly]="true"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
        <app-text-field-input
          identifier="cAddressLine2"
          label="Address line 2"
          i18n-label
          [formControlName]="AddressLine2"
          [readonly]="true"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
        <app-text-field-input
          *ngIf="isMexEnv"
          identifier="cAddressLine3"
          label="Address line 3"
          i18n-label
          [formControlName]="AddressLine3"
          [readonly]="true"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
        <app-text-field-input
          identifier="cCity"
          label="City"
          i18n-label
          [formControlName]="City"
          [readonly]="true"
          [isDataPrivate]="true"
        >
        </app-text-field-input>
        <div class="row">
          <div class="col col-md-12">
            <app-ph-select
              identifier="cState"
              label="State"
              i18n-label
              [formControlName]="State"
              [options]="countryStates"
              [readonly]="true"
            ></app-ph-select>
          </div>
          <div class="col col-md-12">
            <app-text-field-input
              identifier="cZipCode"
              label="Zip Code"
              i18n-label
              [formControlName]="ZipCode"
              [readonly]="true"
            >
            </app-text-field-input>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <button class="btn btn-outline-dark col-8 offset-1 text-uppercase" (click)="cancel()" i18n>
        Cancel
      </button>
    </div>
    <div class="col">
      <button class="btn btn-primary col-8 offset-2 text-uppercase" (click)="continue()" i18n>
        Continue
      </button>
    </div>
  </div>
</app-modal>
