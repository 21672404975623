import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { WishlistResponse } from '@core/dto/wishlist.dto';
import { Locale } from '@core/enums/locale';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WishlistService {
  baseUrl: string = environment.ecommerceBackendUri;
  options: object;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {
    this.options = {
      withCredentials: true,
      params: new HttpParams({
        fromObject: { culture: getCulture(localeId) },
      }),
    };
  }

  fetchWishlist(): Observable<WishlistResponse> {
    return this.http.get<WishlistResponse>(`${this.baseUrl}api/wishlist`, this.options);
  }

  addToWishList(sku: string): Observable<WishlistResponse> {
    return this.http.post<WishlistResponse>(
      `${this.baseUrl}api/wishlist/add`,
      {},
      {
        withCredentials: true,
        params: new HttpParams({ fromObject: { sku, culture: getCulture(this.localeId) } }),
      },
    );
  }

  mergeWishlist(): Observable<any> {
    return this.http.post(`${this.baseUrl}api/wishlist/merge`, {}, this.options);
  }

  deleteFromWishList(sku: string): Observable<WishlistResponse> {
    return this.http.delete<WishlistResponse>(`${this.baseUrl}api/wishlist/${sku}`, this.options);
  }

  newWishlist(): Observable<any> {
    return this.http.post(`${this.baseUrl}api/wishlist`, {}, this.options);
  }
}
