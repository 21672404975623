import { PaymentImageSrc } from '@core/constants/payment.consts';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PaymentType } from '@core/enums/payment-type.enum';
import { SelectOption } from '@shared/components/select/select.component';
import { isMexEnv, isUsaEnv } from '@shared/utils/environment-utils';

export function getPaymentProviderType(paymentType: PaymentType): PaymentProviderType | undefined {
  if (isUsaEnv) {
    return PaymentProviderType.Braintree;
  }

  if (isMexEnv) {
    switch (paymentType) {
      case PaymentType.CreditOrDebitCard:
        return PaymentProviderType.Nexio;
      case PaymentType.PayPal:
        return PaymentProviderType.PayPal;
    }
  }

  return PaymentProviderType.Default;
}

export function initPaymentTypeOptionsAndImages(
  isVoucherEnabled: boolean,
  isNexioEnabled: boolean,
): PaymentTypeOptionsAndImages {
  const paymentTypeOptions: SelectOption[] = [];
  const availablePaymentMethodsImgSrc: string[] = [];

  if (isUsaEnv || isNexioEnabled) {
    paymentTypeOptions.push({
      name: $localize`Visa / Master card`,
      value: PaymentType.CreditOrDebitCard,
    });
    availablePaymentMethodsImgSrc.push(...[PaymentImageSrc.visaCard, PaymentImageSrc.masterCard]);
  }

  if (isMexEnv) {
    paymentTypeOptions.push({ name: $localize`Paypal`, value: PaymentType.PayPal });
    availablePaymentMethodsImgSrc.push(PaymentImageSrc.paypal);
  }

  if (isVoucherEnabled) {
    paymentTypeOptions.push({ name: $localize`OXXO Voucher`, value: PaymentType.PayByCash });
    availablePaymentMethodsImgSrc.push(PaymentImageSrc.oxxo);
  }

  return {
    paymentTypeOptions: paymentTypeOptions,
    availablePaymentMethodsImgSrc: availablePaymentMethodsImgSrc,
  };
}

export interface PaymentTypeOptionsAndImages {
  paymentTypeOptions: SelectOption[];
  availablePaymentMethodsImgSrc: string[];
}
