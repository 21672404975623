import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppState } from '@core/store';
import { fetchOrderDetails } from '@core/store/order/order.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-select-order-step',
  templateUrl: './select-order-step.component.html',
  styleUrls: ['./select-order-step.component.scss'],
})
export class SelectOrderStepComponent {
  @Input() activeStep: number;
  @Output() activeStepChange = new EventEmitter<number>();

  constructor(private store$: Store<AppState>) {}

  selectOrder(orderId: string) {
    this.store$.dispatch(fetchOrderDetails({ orderId }));
    this.activeStepChange.emit(this.activeStep + 1);
  }
}
