<ng-container>
  <label
    *ngIf="label"
    for="{{ identifier }}"
    [ngClass]="{ 'font-weight-bold': !isValidInput, 'text-asterix': labelAsterix }"
    >{{ label }}</label
  >
  <div class="input-group mb-3">
    <textarea
      id="{{ identifier }}"
      name="{{ identifier }}"
      [ngClass]="{ 'error-border border-right-0': !isValidInput }"
      [value]="value"
      class="form-control"
      [placeholder]="placeholder"
      (keyup)="onKeyUp($event)"
      [readonly]="readonly"
      [rows]="rows"
    ></textarea>
    <div class="input-group-append" *ngIf="!isValidInput">
      <span class="input-group-text bg-transparent error-border border-left-0 align-items-start">
        <i class="bi bi-exclamation-triangle error-color"></i>
      </span>
    </div>
  </div>
  <div *ngIf="!isValidInput && errorMessages" class="validation-error-message">
    <div *ngFor="let item of errorMessages">{{ item }}</div>
  </div>
</ng-container>
