/* eslint-disable max-len */
export enum PhExceptionErrorType {
  ConsultantNotFoundException = 'ConsultantNotFoundException',
  ProductNotFoundException = 'ProductNotFoundException',
  ProductNotInCollectionException = 'ProductNotInCollectionException',
  StateNotFoundException = 'StateNotFoundException',
  PrimaryImageNotFoundException = 'PrimaryImageNotFoundException',
  PriceNotFoundException = 'PriceNotFoundException',
  OrderLineNotFoundException = 'OrderLineNotFoundException.',
  MismatchCustomerException = 'MismatchCustomerException',
  DefaultProductRelationTypeNotFoundException = 'DefaultProductRelationTypeNotFoundException',
  CustomerNotFoundException = 'CustomerNotFoundException',
  CountryNotFoundException = 'CountryNotFoundException',
  CategoryNotFoundException = 'CategoryNotFoundException',
  AddressNotFoundException = 'AddressNotFoundException',
  AddressNameNotUniqueException = 'AddressNameNotUniqueException',
  WishListOrderStatusNotFoundException = 'WishListOrderStatusNotFoundException',
  OrderSubmitTransactionFailedException = 'OrderSubmitTransactionFailedException',
  OrderSubmitCvvDoesNotMatchException = 'OrderSubmitCvvDoesNotMatchException',
  OrderSubmitZipDoesNotMatchException = 'OrderSubmitZipDoesNotMatchException',
  ValidationException = 'ValidationException',
  Email = 'Email',
  PipelineException = 'PipelineException',
  ForbiddenZipCodeException = 'ForbiddenZipCodeException',
  PaymentTransactionFailedException = 'PaymentTransactionFailedException',
  PaymentTransactionFailedNoneException = 'PaymentTransactionFailedNoneException',
  PaymentTransactionFailedSoftException = 'PaymentTransactionFailedSoftException',
  PaymentTransactionFailedHardException = 'PaymentTransactionFailedHardException',
  PaymentTransactionFailedUnrecognizedException = 'PaymentTransactionFailedUnrecognizedException',
  PaymentMethodCreationFailedException = 'PaymentMethodCreationFailedException',
  CustomerDefaultAddressNotFoundException = 'CustomerDefaultAddressNotFoundException',
}
