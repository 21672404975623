<div *ngIf="loading$ | async; else content" class="d-flex justify-content-center m-5">
  <app-ph-spinner width="60px"></app-ph-spinner>
</div>

<ng-template #content>
  <div class="d-none d-md-flex">
    <app-sortable-table
      class="w-100"
      [columns]="columns"
      [rows]="returnHistoryTableData$ | async"
    ></app-sortable-table>
  </div>

  <div class="d-md-none">
    <div *ngFor="let return of returnHistory$ | async" class="return-container p-2 mb-3">
      <div class="font-size-bigger">
        <strong class="text-double-dot" i18n>Return ID</strong>
        {{ return.returnId }}
      </div>
      <div>
        <span class="text-double-dot" i18n>Return date</span>
        {{ return.submittedDate | date: 'longDate' }}
      </div>
      <div>
        <span class="text-double-dot" i18n>Qty</span>
        {{ return.quantity | number }}
      </div>
      <div class="d-flex justify-content-between align-items-center mt-2">
        <button class="btn btn-primary" [routerLink]="return.id" i18n>Details</button>
        <div class="total-price">
          {{ return.totalAmount | phCurrency }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
