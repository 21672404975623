<div class="root">
  <div class="container-fluid">
    <div class="row" *ngIf="hasCurrentCurrentConsultant$ | async">
      <div class="col-12">
        <ng-container [ngSwitch]="displayConsultantHeaderContent">
          <ng-container *ngSwitchCase="ConsultantHeaderContent.consultantWidget">
            <app-consultant-widget></app-consultant-widget>
          </ng-container>

          <ng-container *ngSwitchCase="ConsultantHeaderContent.consultantBanner">
            <app-my-story-banner></app-my-story-banner>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
