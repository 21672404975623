import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckoutPersonalInfoRequest, CheckoutPersonalInfoResponse } from '@core/dto/checkout.dto';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  baseUrl: string = environment.ecommerceBackendUri;
  options = { withCredentials: true };

  constructor(private http: HttpClient) {}

  addressValidation(
    request: CheckoutPersonalInfoRequest,
  ): Observable<CheckoutPersonalInfoResponse> {
    return this.http.post<CheckoutPersonalInfoResponse>(
      `${this.baseUrl}api/checkout/personalInfo`,
      request,
      this.options,
    );
  }

  checkEmail(email: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}api/user/check`, {
      params: new HttpParams({ fromObject: { email } }),
    });
  }
}
