import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@core/store';
import { resetCurrentConsultant } from '@core/store/consultant/consultant.actions';
import { Store } from '@ngrx/store';

@Component({
  templateUrl: './clear-consultant.component.html',
})
export class ClearConsultantComponent implements OnInit {
  constructor(private store$: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.store$.dispatch(resetCurrentConsultant());
    this.router.navigate(['..']);
  }
}
