import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReturnReasonLocale } from '@core/constants/retrun-reason-locale';
import { ReturnReason } from '@core/enums/return-reason';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { ReturnOrderLineType } from '../../new-return/new-return.component';

@Component({
  selector: 'app-return-order-line',
  templateUrl: './return-order-line.component.html',
  styleUrls: ['./return-order-line.component.scss'],
})
export class ReturnOrderLineComponent implements OnInit {
  @Input() readOnly: boolean;
  @Input() orderLine: ReturnOrderLineType;
  @Output() orderLineChange: EventEmitter<ReturnOrderLineType> =
    new EventEmitter<ReturnOrderLineType>();
  readonly ReturnReasonEnum = ReturnReason;
  readonly ReturnReasonLocale = ReturnReasonLocale;

  thumbnail = Thumbnail;
  returnReasonList = [];

  constructor() {}

  ngOnInit(): void {
    this.createReasonsOfReturnList();
  }

  toggleOrderLine() {
    this.orderLineChange.emit({ ...this.orderLine, checked: !this.orderLine.checked });
  }

  changeReturnReason(reason: any) {
    this.orderLineChange.emit({ ...this.orderLine, reason: reason.target.value });
  }

  createReasonsOfReturnList() {
    for (let key in this.ReturnReasonEnum) {
      if (!isNaN(Number(key))) {
        this.returnReasonList.push(this.ReturnReasonLocale[key]);
      }
    }
  }
}
