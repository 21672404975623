import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@core/store';
import { selectSubmitReturnSucceeded } from '@core/store/return';
import { resetReturnState, submitReturn } from '@core/store/return/return.actions';
import { Store } from '@ngrx/store';
import { InfoModalComponent } from '@shared/components/info-modal/info-modal.component';
import { Subscription } from 'rxjs';

import { CountryName } from '@core/constants/country-name';
import { OrderAddress } from '@core/store/order/order-state-models';
import { filter, take } from 'rxjs/operators';
import { ReturnOrderInformationType, ReturnOrderLineType } from '../new-return.component';

@Component({
  selector: 'app-return-summary-step',
  templateUrl: './return-summary-step.component.html',
  styleUrls: ['./return-summary-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnSummaryStepComponent implements OnChanges, OnInit, OnDestroy {
  @Input() activeStep: number;
  @Output() activeStepChange = new EventEmitter<number>();

  @Input() orderInfo: ReturnOrderInformationType;
  @Output() orderInfoChange = new EventEmitter<ReturnOrderInformationType>();

  @Input() orderLines: ReturnOrderLineType[];

  checkedOrderLines: ReturnOrderLineType[];
  subscriptions = new Subscription();

  @ViewChild('infoModal') private infoModal: InfoModalComponent;

  constructor(
    private store$: Store<AppState>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.store$
        .select(selectSubmitReturnSucceeded)
        .pipe(
          filter((succeeded) => succeeded),
          take(1),
        )
        .subscribe(() => {
          this.infoModal.open();
        }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderLines) {
      this.checkedOrderLines = this.orderLines.filter((orderLine) => orderLine.checked);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submitReturn() {
    this.store$.dispatch(
      submitReturn({
        payload: {
          firstName: this.orderInfo.shippingAddress.firstName,
          lastName: this.orderInfo.shippingAddress.lastName,
          shippingAddress: this.mapOrderInfoShippingAddressToReturnPayloadAddress(
            this.orderInfo.shippingAddress,
          ),
          items: this.checkedOrderLines.map((orderLine) => ({
            orderLineId: orderLine.orderLineId,
            reason: orderLine.reason.toString(),
          })),
        },
      }),
    );
  }

  confirm() {
    this.store$.dispatch(resetReturnState());
    this.router.navigateByUrl('/myaccount/returns');
  }

  private mapOrderInfoShippingAddressToReturnPayloadAddress(
    shippingAddress: OrderAddress,
  ): OrderAddress {
    const address = { ...shippingAddress };
    address.country = CountryName[address.country];
    return address;
  }
}
