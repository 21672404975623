import { WebsiteModeActionTypes } from './website-mode.actions';
import { produce } from 'immer';
import { WebsiteMode } from '@core/enums/website-mode.enum';
import { WebsiteModeState } from './website-mode-state-models';

export const initialState: WebsiteModeState = {
  websiteMode: WebsiteMode.corporate,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case WebsiteModeActionTypes.setWebsiteMode:
      draft.websiteMode = action.websiteMode;
      return;

    default:
      return;
  }
}, initialState);
