import { Component, Input } from '@angular/core';
import { MelissaErrorCode } from '@core/enums/melissa-error-code';

@Component({
  selector: 'app-sna-address-error-messages',
  templateUrl: './sna-address-error-messages.component.html',
  styleUrls: ['../sna-address-validation-modal.component.scss'],
})
export class SnaAddressErrorMessagesComponent {
  @Input() public hasAddressErrorCode: ((code: MelissaErrorCode) => boolean) | undefined;

  codes = MelissaErrorCode;
}
