import { Component } from '@angular/core';
import { FooterBackgroundShade } from '@core/enums/footer-bg-shade.enum';

@Component({
  selector: 'app-delivery-info-layout',
  templateUrl: './delivery-info-layout.component.html',
  styleUrls: ['./delivery-info-layout.component.scss'],
})
export class DeliveryInfoLayoutComponent {
  readonly shade = FooterBackgroundShade;

  constructor() {}
}
