import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-password-card',
  templateUrl: './password-card.component.html',
  styleUrls: ['./password-card.component.scss', '../double-check-step.component.scss'],
})
export class PasswordCardComponent {
  @Input() password: string;
  @Input() stepProcessing: boolean = false;
  @Input() consultantDataCanNotBeModified: boolean = false;
  @Output() navigateTo: EventEmitter<void> = new EventEmitter<void>();

  showPassword: boolean = false;

  constructor() {}

  togglePasswordShowing() {
    this.showPassword = !this.showPassword;
  }

  navigate(): void {
    this.navigateTo.emit();
  }
}
