import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from '@base/components/layout/default-layout/default-layout.component';
import { ProductResolver } from '@core/resolvers/product.resolver';
import { environment } from '@env';
import { NotFoundPageComponent } from '@shared/components/not-found-page/not-found-page.component';
import { ProductCatalogPageComponent } from './components/product-catalog-page/product-catalog-page.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { ProductListPageComponent } from './components/product-list-page/product-list-page.component';

const routes: Routes = environment.feature.product
  ? [
      {
        path: '',
        component: DefaultLayoutComponent,
        children: [
          {
            path: 'products',
            component: ProductCatalogPageComponent,
            data: {
              title: $localize`Shop for products by collection and category`,
              description: $localize`Shop for products by collection and category - Description`,
            },
          },
          { path: 'products/:catalogType/:catalogName', component: ProductListPageComponent },
          {
            path: 'product',
            runGuardsAndResolvers: 'always',
            children: [
              {
                path: 'details/:sku',
                resolve: { productDetailsItem: ProductResolver },
                runGuardsAndResolvers: 'always',
                component: ProductDetailsComponent,
              },
              {
                path: 'detail/:sku',
                resolve: { productDetailsItem: ProductResolver },
                runGuardsAndResolvers: 'always',
                component: ProductDetailsComponent,
              },
              {
                path: 'p/:sku',
                resolve: { productDetailsItem: ProductResolver },
                runGuardsAndResolvers: 'always',
                component: ProductDetailsComponent,
              },
              {
                path: ':collection/:nameAndSku',
                resolve: { productDetailsItem: ProductResolver },
                runGuardsAndResolvers: 'always',
                component: ProductDetailsComponent,
              },
              {
                path: ':collection/:name/p/:sku',
                resolve: { productDetailsItem: ProductResolver },
                component: ProductDetailsComponent,
                runGuardsAndResolvers: 'always',
              },
            ],
          },
          {
            path: 'product/notfound',
            component: NotFoundPageComponent,
            data: { title: $localize`404`, message: $localize`Product not found!` },
          },
        ],
      },
    ]
  : [];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductRoutingModule {}
