import { Injectable } from '@angular/core';
import { ConditionalContentService } from '@core/services/conditional-content.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as conditionalContentActions from './conditional-content.actions';

@Injectable()
export class ConditionalContentEffects {
  fetchConditionalContentByUrlName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conditionalContentActions.fetchConditionalContentByUrlName),
      mergeMap(({ urlName }) =>
        this.conditionalContentService.fetchConditionalContentByUrlName(urlName).pipe(
          map((content) => conditionalContentActions.fetchConditionalContentSuccess({ content })),
          catchError((error) =>
            of(conditionalContentActions.fetchConditionalContentError({ error })),
          ),
        ),
      ),
    ),
  );

  fetchConditionalContentByPageCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(conditionalContentActions.fetchConditionalContentByPageCategory),
      mergeMap(({ pageCategory, viewAs }) =>
        this.conditionalContentService
          .fetchConditionalContentByPageCategory(pageCategory, viewAs)
          .pipe(
            map((content) => conditionalContentActions.fetchConditionalContentSuccess({ content })),
            catchError((error) =>
              of(conditionalContentActions.fetchConditionalContentError({ error })),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private conditionalContentService: ConditionalContentService,
  ) {}
}
