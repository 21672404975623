import { Component, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { LogRocketService } from '@core/services/logrocket.service';
import { AppState } from '@core/store';
import { selectUser } from '@core/store/user';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-logrocket-initializer',
  template: '',
})
export class LogRocketInitializerComponent implements OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private initialized: boolean = false;

  private initRoutes: string[] = [
    'start-now-app',
    'product',
    'products',
    'search',
    'cart',
    'login',
    'registration',
  ];

  constructor(
    private store$: Store<AppState>,
    private logRocketService: LogRocketService,
    private router: Router,
  ) {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(() => !this.initialized),
          filter((e) => e instanceof NavigationStart),
          filter((e: NavigationStart) => this.initRoutes.includes(e.url.split(/[\/?]+/)?.[1])),
        )
        .subscribe(() => {
          this.logRocketService.initLogRocket();
          this.identify();
          this.initialized = true;
        }),
    );
  }

  identify(): void {
    this.subscriptions.add(
      this.store$.select(selectUser).subscribe((user) => {
        this.logRocketService.logRocketIdentify(user.email);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
