<div class="container cart-page" *ngIf="cart$ | async as cart; else loading">
  <app-full-page-spinner *ngIf="loading$ | async"></app-full-page-spinner>
  <h2 class="mb-3" i18n>Your cart</h2>
  <app-order-lines [cart]="cart" [productClickable]="true"></app-order-lines>
  <ng-container *ngIf="cart.promotionMessages?.length">
    <hr />
    <app-cart-promotion-message
      *ngFor="let promotionMessage of cart.promotionMessages"
      [message]="promotionMessage"
    ></app-cart-promotion-message>
  </ng-container>
  <div *ngIf="appSettings.isCouponsEnabled">
    <hr />
    <div class="row m-lt-03">
      <div class="col-12 d-flex align-items-center">
        <h5 class="font-italic mb-0 font-size-bigger mr-3" i18n>Have a coupon code?</h5>
        <a
          *ngIf="!showCouponTextField; else couponTextField"
          class="font-weight-bold font-size-bigger text-right pointer add-here-line-height"
          (click)="showCouponField()"
          i18n
        >
          +Add here
        </a>
      </div>
      <ng-template #couponTextField>
        <div class="d-flex flex-row flex-col-gap-16 align-items-stretch">
          <input id="couponTextField" type="text" class="form-control" />
          <button type="button" i18n class="btb btn-outline-primary btn-block">Apply</button>
        </div>
      </ng-template>
    </div>
  </div>
  <hr />
  <app-cart-totals [cart]="cart$"></app-cart-totals>
  <hr />
  <div class="row">
    <div class="col-6 col-md-3">
      <button
        routerLink="/products"
        type="button"
        class="btn btn-outline-dark btn-block btn-uppercase-lg"
        i18n
      >
        Back to shopping
      </button>
    </div>
    <div class="col-6 col-md-3 offset-md-6">
      <button
        (click)="checkout()"
        type="button"
        class="btn btn-primary btn-block btn-uppercase-lg"
        [disabled]="!(cart$ | async).orderLines.length"
        i18n
      >
        Checkout
      </button>
    </div>
  </div>
  <hr />
  <div *ngIf="(slides$ | async)?.length > 0">
    <div class="row justify-content-center">
      <div class="col-12">
        <app-related-carousel
          i18n-title
          title="You might also like"
          [slides$]="slides$"
        ></app-related-carousel>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="container text-center">
    <img src="assets/images/misc/phLoading.gif" alt="loading" />
  </div>
</ng-template>

<app-cart-item-updated-warning-modal #itemUpdatedModal></app-cart-item-updated-warning-modal>
