<div class="container">
  <div class="row bg-primary my-3 justify-content-center p-4">
    <h2 class="text-white" i18n>Contact <span class="text-nowrap">Princess House</span></h2>
  </div>

  <div class="ml-md-4">
    <div class="row">
      <div class="col-12 col-md-6">
        <h5 class="font-weight-bold" i18n>Require Customer Service?</h5>
        <p class="mb-0" i18n>M-F, 9 A.M.-10 P.M. EST</p>
        <a href="tel: {{ tel?.customerService?.prefix }}-{{ tel?.customerService?.number }}">{{
          tel?.customerService?.number
        }}</a>
        <hr class="d-md-none" />
      </div>

      <div class="col-12 col-md-6">
        <h5 class="font-weight-bold" i18n>Interested in the Hostess or Income Opportunity?</h5>
        <a href="tel: {{ tel?.career?.prefix }}-{{ tel?.career?.number }}">{{
          tel?.career?.number
        }}</a>
        <hr class="d-md-none" />
      </div>
    </div>
    <div *ngIf="feature.contactUsLocation" class="row mt-md-4">
      <div class="col-12 col-md-6">
        <h5 class="font-weight-bold" i18n>Contacting a Home Office Team Member?</h5>
        <a href="tel: {{ tel?.homeOfficeMember?.prefix }}-{{ tel?.homeOfficeMember?.number }}">
          {{ tel?.homeOfficeMember?.number }}
        </a>
        <hr class="d-md-none" />
      </div>

      <div class="col-12 col-md-6">
        <h5 class="font-weight-bold" i18n>Headquarters</h5>
        <p>
          20 Cabot Blvd. <br />
          Suite 105 <br />
          Mansfield, MA 02048
        </p>
        <hr class="d-md-none" />
      </div>
    </div>
  </div>

  <div class="row my-3 p-4 bg-light-purple">
    <h3 class="text-primary" i18n>Do you have a comment, question or suggestion?</h3>
    <h5 class="text-primary" i18n>
      Submit the form below and we'll answer within one business day.
    </h5>
  </div>

  <div class="ml-md-4">
    <h5 i18n>What type inquiry do you have? Or how can we help you?</h5>

    <div ngbRadioGroup [(ngModel)]="subjectId">
      <div>
        <label ngbButtonLabel class="pl-0 py-0 font-weight-normal shadow-none">
          <input ngbButton type="radio" name="subject" [value]="0" />
          {{ subjects[0] }}
        </label>
      </div>
      <div>
        <label ngbButtonLabel class="pl-0 py-0 font-weight-normal shadow-none">
          <input ngbButton type="radio" name="subject" [value]="1" />
          {{ subjects[1] }}
        </label>
      </div>
    </div>

    <form [formGroup]="contactUsFormGroup">
      <div [formGroupName]="InfoFormGroup">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <app-text-field-input
                [identifier]="FirstName"
                label="First Name"
                labelAsterix="true"
                i18n-label
                [formControlName]="FirstName"
              ></app-text-field-input>
            </div>

            <div class="mb-3">
              <app-text-field-input
                [identifier]="LastName"
                label="Last Name"
                labelAsterix="true"
                i18n-label
                [formControlName]="LastName"
              ></app-text-field-input>
            </div>

            <div class="mb-3">
              <app-text-field-input
                [identifier]="HomePhone"
                label="Phone"
                labelAsterix="true"
                i18n-label
                placeholder="home"
                i18n-placeholder
                [formControlName]="HomePhone"
                [isDataPrivate]="true"
              ></app-text-field-input>
            </div>

            <div class="mb-3">
              <app-text-field-input
                [identifier]="MobilePhone"
                i18n-label
                label="OR"
                placeholder="mobile"
                i18n-placeholder
                [formControlName]="MobilePhone"
                [isDataPrivate]="true"
              ></app-text-field-input>
            </div>
            <div *ngIf="!validPhoneInput()" class="validation-error-message">
              <span i18n>This field is required</span>
            </div>

            <div class="mb-3">
              <app-text-field-input
                [identifier]="Email"
                label="Email"
                labelAsterix="true"
                i18n-label
                [formControlName]="Email"
                [isDataPrivate]="true"
              ></app-text-field-input>
            </div>

            <div class="mb-3">
              <app-text-field-input
                [identifier]="EmailConfirm"
                label="Confirm Email"
                labelAsterix="true"
                i18n-label
                [formControlName]="EmailConfirm"
                [groupValidationErrorType]="FormErrorTypes.emailConfirm"
                [isDataPrivate]="true"
              ></app-text-field-input>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="mb-3">
              <app-text-field-input
                [identifier]="AddressLine1"
                label="Address Line 1"
                labelAsterix="true"
                i18n-label
                [formControlName]="AddressLine1"
                [isDataPrivate]="true"
              ></app-text-field-input>
            </div>

            <div class="mb-3">
              <app-text-field-input
                [identifier]="AddressLine2"
                label="Address Line 2"
                i18n-label
                [formControlName]="AddressLine2"
                [isDataPrivate]="true"
              ></app-text-field-input>
            </div>

            <div class="mb-3" *ngIf="isMexEnv">
              <app-text-field-input
                [identifier]="AddressLine3"
                label="Address Line 3"
                i18n-label
                [formControlName]="AddressLine3"
                [isDataPrivate]="true"
              ></app-text-field-input>
            </div>

            <div class="mb-3">
              <app-text-field-input
                [identifier]="City"
                label="City"
                labelAsterix="true"
                i18n-label
                [formControlName]="City"
                [isDataPrivate]="true"
              ></app-text-field-input>
            </div>

            <div class="mb-3">
              <app-select
                [identifier]="State"
                label="State"
                i18n-label
                [formControlName]="State"
                [options]="countryStates$ | async"
              ></app-select>
            </div>

            <div class="mb-3">
              <app-text-field-input
                [identifier]="ZipCode"
                label="Zip Code"
                labelAsterix="true"
                i18n-label
                [formControlName]="ZipCode"
              ></app-text-field-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="mb-3">
              <app-textarea
                [identifier]="Comment"
                label="Comment"
                labelAsterix="true"
                i18n-label
                [formControlName]="Comment"
              ></app-textarea>
            </div>
          </div>
        </div>
      </div>

      <h6 class="text-secondary mb-3">
        <span class="mr-1 text-asterix"></span>
        <span i18n>Required Field</span>
      </h6>

      <div class="mb-3" *ngIf="subjectId === 1">
        <h5 class="text-asterix" i18n
          >What type of inquiry are you requesting? (Select all that apply)</h5
        >
        <div [formGroupName]="Reasons">
          <div *ngIf="!validReasonsGroup(Reasons)" class="validation-error-message">
            <span i18n>Please select at least one</span>
          </div>
          <div>
            <label>
              <input type="checkbox" [formControlName]="ContactUsReasonKey.copy" />
              {{ reasonTexts[ContactUsReasonKey.copy] }}
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" [formControlName]="ContactUsReasonKey.understand" />
              {{ reasonTexts[ContactUsReasonKey.understand] }}
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" [formControlName]="ContactUsReasonKey.deleteData" />
              {{ reasonTexts[ContactUsReasonKey.deleteData] }}
            </label>
          </div>
          <div>
            <label>
              <input type="checkbox" [formControlName]="ContactUsReasonKey.unsubscribe" />
              {{ reasonTexts[ContactUsReasonKey.unsubscribe] }}
            </label>
          </div>
        </div>
        <h5 class="mt-3" i18n>Acknowledgment</h5>
        <span class="text-secondary" i18n>
          BY SUBMITTING THIS FORM I CONFIRM THAT THE INFORMATION I HAVE PROVIDED IS TRUE AND
          ACCURATE. I ACKNOWLEDGE THAT THE INFORMATION I AM SUBMITTING IS BEING USED BY PRINCESS
          HOUSE FOR VERIFICATION, AND RETAININED FOR PURPOSES OF RECORD-KEEPING AND AUDITS - BOTH
          INTERNALLY AND POTENTIALLY BY GOVERNMENT BODIES, IN THE CASE OF CALIFORNIA, FOR A PERIOD
          OF TWO YEARS.
        </span>
      </div>
      <hr />
      <button class="btn btn-primary text-uppercase submit-button" (click)="submit()" i18n>
        Submit
      </button>
    </form>
  </div>
</div>
