import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-card',
  templateUrl: './action-card.component.html',
  styleUrls: ['./action-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCardComponent {
  @Input() icon: string;
  @Input() actionLabel: string;
  @Input() primary: boolean;
  @Input() disabled: boolean = false;
  @Output() actionClick: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}
