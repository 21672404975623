/* eslint-disable max-len */
import { ConsultantSubmitErrorMessages } from '@core/constants/consultant-submit-error-message';
import { CongratulationType } from '@core/enums/congratulation-type.enum';
import { ConsultantSubmissionErrorType } from '@core/enums/consultant-submission-error-type.enum';
import { PaymentMethodType } from '@core/enums/payment-method-type.enum';
import { VoucherLimits } from '@core/models/app-settings.model';
import {
  StartNowAppData,
  SubmittedConsultantInfo,
} from '@core/store/start-now-app/start-now-app-state-models';
import { isArray } from '@shared/utils/array-utils';
import {
  mapContactInfoToSubmitContactInfoDto,
  mapUserInfoToSubmitUserInfoParamsDto,
} from '@shared/utils/create-submit-consultant-info-utils';
import { SubmitConsultantRequest } from '../../../../../core/dto/start-now-app.dto';

export function buildSubmitConsultantRequest(
  startNowAppData: StartNowAppData,
  skipPayment: boolean,
): SubmitConsultantRequest {
  return {
    applicationRegistrationId: startNowAppData.applicationRegistrationId,
    recruiterConsultantId: startNowAppData.selectedConsultant?.beeNumber.toString(),
    userInfo: mapUserInfoToSubmitUserInfoParamsDto(startNowAppData.userInfo),
    contactInfo: mapContactInfoToSubmitContactInfoDto(startNowAppData.contactInfo),
    starterKit: {
      starterKitId: startNowAppData.selectedStarterKit.id,
    },
    addressInfo: {
      addresses: startNowAppData.addressInfo.addresses,
      languagePreferred: startNowAppData.addressInfo.languagePreferred,
    },
    registrationCode: startNowAppData.registrationCode,
    securityKey: startNowAppData.securityKey,
    billingFirstName:
      startNowAppData.startNowAppPaymentInfo.additionalBillingInformation?.billingFirstName,
    billingLastName:
      startNowAppData.startNowAppPaymentInfo.additionalBillingInformation?.billingLastName,
    paymentType: skipPayment == true ? PaymentMethodType.Finance : PaymentMethodType.Unknown,
  } as SubmitConsultantRequest;
}

export function getConsultantSubmitErrorMessage(response: SubmittedConsultantInfo): string {
  const result: string[] = [];
  if (response && isArray(response.errorTypes) && response.errorTypes.length > 0) {
    response.errorTypes.forEach((errorType) => {
      const errorMessage = ConsultantSubmitErrorMessages[errorType];
      result.push(errorMessage ? errorMessage : errorType);
    });
  }

  return result.join(', ');
}

export class SubmitSuccessfulModel {
  isSuccessful: boolean;
  congratulationType: CongratulationType;
}

export function getDisplayedErrorMessages(
  displayErrorTypes: ConsultantSubmissionErrorType[],
  submissionErrors: ConsultantSubmissionErrorType[],
  voucherLimits: VoucherLimits,
): string[] {
  let errorMessages: string[] = [];
  submissionErrors?.forEach((errorType) => {
    const displayErrorType = displayErrorTypes.find((item) => item === errorType);
    if (!!displayErrorType) {
      let message;
      switch (displayErrorType) {
        case ConsultantSubmissionErrorType.DailyVoucherAmountReached:
          message = `${ConsultantSubmitErrorMessages[displayErrorType]} ${voucherLimits.dailyLimit}`;
          break;
        case ConsultantSubmissionErrorType.MonthlyVoucherAmountReached:
          message = `${ConsultantSubmitErrorMessages[displayErrorType]} ${voucherLimits.monthlyLimit}`;
          break;
        case ConsultantSubmissionErrorType.YearlyVoucherAmountReached:
          message = `${ConsultantSubmitErrorMessages[displayErrorType]} ${voucherLimits.yearlyLimit}`;
          break;
        default:
          message = ConsultantSubmitErrorMessages[displayErrorType];
          break;
      }
      errorMessages.push(message);
    }
  });
  return errorMessages;
}
