/* eslint-disable @typescript-eslint/naming-convention */
export enum SearchEntityType {
  Product = 'product',
  Recipe = 'recipe',
  Blog = 'blog',
  ConditionalContent = 'conditionalContent',
}

export enum SearchFacetQueryParam {
  'product/collections' = 'productCollections',
  'product/categories' = 'productCategories',
  'recipe/categories' = 'recipeCategories',
  'blog/categories' = 'blogCategories',
}
