import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AddressType } from '@core/enums/address-type.enum';
import { PaymentHandlerType } from '@core/enums/payment-handler-type.enum';
import { AppState } from '@core/store';
import { selectConsultantState } from '@core/store/consultant';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import {
  selectConfirmRecruiter,
  selectIsZeroDepositAvailable,
  selectOrderSummary,
  selectPaymentStepSkipped,
  selectSnaCountryStates,
  selectStartNowAppData,
} from '@core/store/start-now-app';
import {
  Address,
  CountryState,
  OrderSummary,
  StartNowAppData,
  UserInfo,
} from '@core/store/start-now-app/start-now-app-state-models';
import { Store } from '@ngrx/store';
import { PaymentHandlerBase } from '@payment/payment-handler/payment-handler-base.model';
import { PaymentHandlerFactory } from '@payment/payment-handler/payment-handler-factory';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { isMexEnv } from '@shared/utils/environment-utils';
import { ssnMasking } from '@shared/utils/ssn-masking-utils';
import { StartNowAppStep } from 'app/modules/start-now-app/enums/start-now-app-step.enum';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-congratulation-summary',
  templateUrl: './congratulation-summary.component.html',
  styleUrls: ['./congratulation-summary.component.scss'],
})
export class CongratulationSummaryComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() goToStep: EventEmitter<number> = new EventEmitter<number>();

  readonly AddressType = AddressType;

  ssnMasking = ssnMasking;
  public isMexEnv = isMexEnv;

  StartNowAppStep = StartNowAppStep;
  orderSummary$: Observable<OrderSummary>;
  stepProcessing$: Observable<boolean>;
  consultantDataCanNotBeModified$: Observable<boolean>;
  startNowAppData: StartNowAppData;
  confirmRecruiter: boolean = false;
  isSubmitted: boolean = false;
  replicatedSiteConsultant: Consultant;
  paymentHandler: PaymentHandlerBase;
  usaStarterKitName: string = $localize`Share + Earn`;
  countryStates$: Observable<CountryState[]>;
  isZeroDepositAvailable$: Observable<boolean>;
  paymentStepSkipped$: Observable<boolean>;
  addressTitles = {
    [AddressType.Home]: $localize`Address`,
    [AddressType.Shipping]: $localize`Shipping`,
    [AddressType.Billing]: $localize`Billing`,
  };

  constructor(
    private store$: Store<AppState>,
    private paymentHandlerFactory: PaymentHandlerFactory,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initObservables();
    this.paymentHandler = this.paymentHandlerFactory.getPaymentHandler(
      PaymentHandlerType.StartNowApp,
    );
    this.isZeroDepositAvailable$ = this.store$.select(selectIsZeroDepositAvailable);
    this.paymentStepSkipped$ = this.store$.select(selectPaymentStepSkipped);
    this.requestConfirmRecruiter();
    this.requestStartNowAppData();
    this.listenReplicatedSiteConsultant();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  fullName(userInfo: UserInfo): string {
    let fullName: string;
    if (userInfo.middleName === '') {
      fullName = userInfo.firstName + ' ' + userInfo.lastName;
    } else {
      fullName = userInfo.firstName + ' ' + userInfo.middleName + ' ' + userInfo.lastName;
    }
    return fullName;
  }

  selectAddress(addresses: Address[], addressType: AddressType): Address {
    let selectedAddress: Address;
    addresses.forEach((address) => {
      if (address.addressType === addressType) {
        selectedAddress = address;
      }
    });
    return selectedAddress;
  }

  private requestConfirmRecruiter() {
    this.subscriptions.add(
      this.store$
        .select(selectConfirmRecruiter)
        .pipe(take(1))
        .subscribe((confirmRecruiter) => (this.confirmRecruiter = confirmRecruiter)),
    );
  }

  private requestStartNowAppData() {
    this.subscriptions.add(
      this.store$
        .select(selectStartNowAppData)
        .pipe(
          filter((startNowAppData) => !!startNowAppData),
          take(1),
        )
        .subscribe((startNowAppData) => {
          this.startNowAppData = startNowAppData;
        }),
    );
  }

  private listenReplicatedSiteConsultant() {
    this.subscriptions.add(
      this.store$
        .select(selectConsultantState)
        .pipe(
          filter((state) => state.isCurrentConsultantFetched),
          take(1),
        )
        .subscribe((state) => {
          this.replicatedSiteConsultant = state.currentConsultant;
        }),
    );
  }

  private initObservables(): void {
    this.orderSummary$ = this.store$.select(selectOrderSummary);
    this.countryStates$ = this.store$.select(selectSnaCountryStates);
  }
}
