import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerSpecialOffersTypes, ProductState } from './product-state-models';

export const productFeatureKey = 'productState';

export const selectProductStateFeature = createFeatureSelector<ProductState>(productFeatureKey);

export const selectProductsLoading = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.products.loading : false),
);

export const selectProducts = createSelector(selectProductStateFeature, (state: ProductState) =>
  state ? state.products.items : null,
);

export const selectAllProductsFetched = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.products.isAllFetched : false),
);

export const selectCatalogs = createSelector(selectProductStateFeature, (state: ProductState) =>
  state ? state.catalogs.items : null,
);

export const selectAllCatalogsFetched = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.catalogs.isAllFetched : false),
);

export const selectCatalogsLoading = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.catalogs.loading : false),
);

export const selectProductDetails = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.productDetails : null),
);

export const selectProductRecommendations = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.productDetails.recommendations : []),
);

export const selectRelatedRecipes = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.productDetails.relatedRecipes : []),
);

export const selectProductQuickView = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.modals.productQuickView : null),
);

export const selectMoreFromCollection = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.productDetails.moreFromCollection : []),
);

export const selectCatalogName = createSelector(selectProductStateFeature, (state: ProductState) =>
  state ? state.catalogName : null,
);

export const selectEcommerceCustomerSpecialOffers = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.customerSpecialOffersTypes.ecommerce.items : []),
);

export const selectHealthySavingsCustomerSpecialOffers = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.customerSpecialOffersTypes.healthySavings.items : []),
);

export const selectCollectAndSaveCustomerSpecialOffers = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.customerSpecialOffersTypes.collectAndSave.items : []),
);

export const selectRegularCustomerSpecialOffers = createSelector(
  selectProductStateFeature,
  (state: ProductState) =>
    state ? state.customerSpecialOffersTypes.regularCustomerSpecial.items : [],
);

export const selectCustomerSpecialOffersLoading = createSelector(
  selectProductStateFeature,
  (state: ProductState) =>
    state ? isFetchCustomerSpecialOffers(state.customerSpecialOffersTypes) : false,
);

export const selectProductDetailsVideos = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.productDetails?.item?.videos : []),
);

export const selectProductQuickViewVideos = createSelector(
  selectProductStateFeature,
  (state: ProductState) => (state ? state.modals.productQuickView?.videos : []),
);

function isFetchCustomerSpecialOffers(
  customerSpecialOffersTypes: CustomerSpecialOffersTypes,
): boolean {
  return (
    customerSpecialOffersTypes.ecommerce.loading ||
    customerSpecialOffersTypes.healthySavings.loading ||
    customerSpecialOffersTypes.collectAndSave.loading ||
    customerSpecialOffersTypes.regularCustomerSpecial.loading
  );
}
