import { OrderState } from '@core/store/order/order-state-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const orderStateFeatureKey = 'orderState';

export const selectOrderFeature = createFeatureSelector<OrderState>(orderStateFeatureKey);

export const selectOrderHistory = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.orderHistory : [],
);

export const selectCCOrderHistory = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.ccOrderHistory : [],
);

export const selectOrderStateLoading = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.loading : false,
);

export const selectCCOrderStateLoading = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.loading : false,
);

export const selectOrderDetails = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.orderDetails : null,
);

export const selectCCOrderDetails = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.ccOrderDetails : null,
);

export const selectSubmittedOrder = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.submittedOrder : null,
);

export const selectIsFetchedPartyOrderPayment = createSelector(
  selectOrderFeature,
  (state: OrderState) => (state ? state.partyOrderPayment.fetched : null),
);

export const selectPartyOrderData = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.partyOrderPayment.partyOrderData : null,
);

export const selectPartyOrderStatus = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.partyOrderPayment.status : null,
);

export const selectOrderDetailCartons = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.orderDetails.cartons : [],
);

export const selectOrderDetailBackOrderItems = createSelector(
  selectOrderFeature,
  (state: OrderState) => (state ? state.orderDetails.backOrderItems : []),
);

export const selectSubmittedOrderStatus = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.submittedOrder.status : null,
);

export const selectPartyOrderError = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.partyOrderPayment.partyOrderError : null,
);

export const selectIsSubmitDisabled = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.isSubmitDisabled : false,
);

export const selectSubmissionErrorType = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.submittedOrder.submissionErrorType : null,
);

export const selectPartyOrderDataForPayPalOrder = createSelector(
  selectOrderFeature,
  (state: OrderState) => (state ? state.partyOrderPayment?.createPartyOrderPayPalOrder : null),
);

export const selectUpdatedOrderItems = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.submittedOrder.updatedItems : [],
);

export const selectDeletedOrderItems = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.submittedOrder.deletedItems : [],
);

export const selectShippedCartonDetailsData = createSelector(
  selectOrderFeature,
  (state: OrderState) => (state ? state.shippedCartonDetails.shippedCartonDetailsData : null),
);

export const selectShippedCartonsError = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.shippedCartonDetails.shippedCartonError : null,
);

export const selectOrderHasPaymentError = createSelector(selectOrderFeature, (state: OrderState) =>
  state ? state.hasPaymentError : false,
);
