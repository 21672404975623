/* eslint-disable max-len */
import { DefaultFailedSubmittedConsultantInfo } from '@core/constants/default-failed-submitted-consultant-info';
import { ConsultantLocatorErrorType } from '@core/enums/consultant-locator-error-type.enum';
import { ConsultantSubmissionErrorType } from '@core/enums/consultant-submission-error-type.enum';
import { PhoneNumberType } from '@core/enums/phone-number-type.enum';
import { SuccessfulStatus } from '@core/enums/successful-status.enum';
import { environment } from '@env';
import { isMexEnv, isUsaEnv } from '@shared/utils/environment-utils';
import { produce } from 'immer';
import { StartNowAppState } from './start-now-app-state-models';
import { StartNowAppActionTypes } from './start-now-app.actions';

export const initialState: StartNowAppState = {
  loading: false,
  consultantFinderStep: {
    consultantFinderResult: {
      items: [],
      hasMore: true,
      loading: false,
      skipConsultantList: null,
    },
    noConsultantFinderModalOpen: false,
    consultantFinderCriteria: null,
    findIsSuccess: true,
    isConsultantsFetched: false,
    isReCaptchaValidateSuccess: SuccessfulStatus.BeforeCall,
    findConsultantByZipCodeError: ConsultantLocatorErrorType.None,
  },
  addressValidationModal: {
    payload: null,
    validationResult: null,
  },
  addressValidationStep: {
    isAddressValidationSuccess: null,
    isNextEnabled: false,
    errors: [],
  },
  aboutYouStep: {
    isNextEnabled: false,
    errors: [],
  },
  contactStep: {
    contactUniquenessCheck: {
      emailIsValid: null,
      phoneValidity: {
        [PhoneNumberType.Mobile]: null,
        [PhoneNumberType.Home]: null,
      },
    },
    isNextEnabled: false,
    errors: [],
  },
  depositPaymentValue: null,
  starterKitStep: {
    fetched: false,
    isNextEnabled: false,
    errors: [],
  },
  paymentStep: {
    orderSummary: null,
  },
  agreementStep: {
    ssnNumberIsValid: null,
    isNextEnabled: false,
    errors: [],
  },
  startNowAppData: {
    selectedConsultant: null,
    userInfo: null,
    contactInfo: null,
    starterKits: [],
    selectedStarterKit: null,
    addressInfo: {
      addresses: [],
      languagePreferred: null,
      countryStates: [],
    },
    areTermsConditionsAgreed: false,
    applicationRegistrationId: null,
    securityKey: null,
    canSubmitConsultantInfo: null,
    registrationCode: null,
    submittedConsultantInfo: null,
    startNowAppPaymentInfo: {
      externalPaymentInfo: null,
      customerId: null,
      paymentType: null,
      createdCardPaymentMethod: null,
      cardPaymentInfo: {
        clientToken: null,
      },
      createdVoucherPaymentMethod: null,
      voucherPaymentInfo: {
        amount: 0,
        email: null,
        vouchers: [],
        vouchersReceived: null,
      },
      provider: null,
      additionalBillingInformation: null,
    },
  },
  stepProcessing: false,
  consultantStepsSkipped: false,
  registrationCodeStepSkipped: false,
  congratulationStepMode: null,
  voucherBarCodeUrls: [],
  confirmRecruiter: false,
  registrationCodeCheck: null,
  reducedDepositPaymentValue: null,
  isReducedDepositAvailable: false,
  isZeroDepositAvailable: false,
  canChangeSavedCard: true,
  paymentStepSkipped: false,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case StartNowAppActionTypes.validateReCaptcha:
      draft.consultantFinderStep.isReCaptchaValidateSuccess = SuccessfulStatus.BeforeCall;
      return;

    case StartNowAppActionTypes.validateReCaptchaSuccess:
      draft.consultantFinderStep.isReCaptchaValidateSuccess = action.isValid
        ? SuccessfulStatus.Success
        : SuccessfulStatus.Failed;
      return;

    case StartNowAppActionTypes.validateReCaptchaFailure:
      draft.consultantFinderStep.isReCaptchaValidateSuccess = SuccessfulStatus.Failed;
      return;

    case StartNowAppActionTypes.resetReCaptchaValidation:
      draft.consultantFinderStep.isReCaptchaValidateSuccess =
        initialState.consultantFinderStep.isReCaptchaValidateSuccess;
      return;

    case StartNowAppActionTypes.stepProcessing:
      draft.stepProcessing = action.stepProcessing;
      return;

    case StartNowAppActionTypes.updateConsultantStepsSkipped:
      draft.consultantStepsSkipped = action.consultantStepsSkipped;
      return;

    case StartNowAppActionTypes.updatePaymentStepSkipped:
      draft.paymentStepSkipped = action.paymentStepSkipped;
      return;

    case StartNowAppActionTypes.findConsultantByArea:
      draft.stepProcessing = true;
      draft.consultantFinderStep.consultantFinderCriteria = action.consultantFinder;
      return;

    case StartNowAppActionTypes.findConsultantByAreaNextPage:
      draft.stepProcessing = true;
      return;

    case StartNowAppActionTypes.findConsultantByConsultantCode:
      draft.stepProcessing = true;
      draft.consultantFinderStep.consultantFinderCriteria = {
        consultantCode: action.code,
        country: action.country,
      };
      return;

    case StartNowAppActionTypes.findConsultantSuccess:
      draft.consultantFinderStep.consultantFinderResult.items = [
        ...draft.consultantFinderStep.consultantFinderResult.items,
        ...action.items,
      ];
      draft.consultantFinderStep.consultantFinderResult.hasMore = action.hasMore;
      draft.consultantFinderStep.consultantFinderResult.skipConsultantList = true;
      draft.consultantFinderStep.findIsSuccess = true;
      draft.consultantFinderStep.isConsultantsFetched = true;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.findConsultantFailure:
      draft.consultantFinderStep.noConsultantFinderModalOpen = true;
      draft.consultantFinderStep.findIsSuccess = false;
      draft.consultantFinderStep.isConsultantsFetched = true;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.findConsultantByZipCode:
      draft.stepProcessing = true;
      draft.consultantFinderStep.consultantFinderCriteria = {
        zipCode: action.request.zipCode,
        country: environment.country,
      };
      return;

    case StartNowAppActionTypes.findConsultantByZipCodeNextPage:
      draft.stepProcessing = true;
      return;

    case StartNowAppActionTypes.findConsultantByZipCodeSuccess:
      /**
       * in Mexico if we don’t know a consultant we will return a default MEX Consultant.
       * In USA we will send back a list of consultants by zipCode and
       * we will navigate to the corresponding page to show them
       */
      if (isMexEnv && !!action.res.items[0]) {
        draft.startNowAppData.selectedConsultant = action.res.items[0];
      }
      draft.consultantFinderStep.consultantFinderResult.items.push(
        ...action.res.items.filter((item) => !!item),
      );
      draft.consultantFinderStep.consultantFinderResult.skipConsultantList =
        action.res.skipConsultantList;
      draft.consultantFinderStep.consultantFinderResult.hasMore = action.res.hasMore;
      draft.consultantFinderStep.findIsSuccess = true;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.findConsultantByZipCodeFailure:
      draft.consultantFinderStep.findConsultantByZipCodeError = action.error.status;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.resetFindConsultantByZipCodeErrorType:
      draft.consultantFinderStep.findConsultantByZipCodeError =
        initialState.consultantFinderStep.findConsultantByZipCodeError;
      return;

    case StartNowAppActionTypes.resetAddressValidationStep:
      draft.addressValidationStep = initialState.addressValidationStep;
      return;

    case StartNowAppActionTypes.resetNoFindConsultantModal:
      draft.consultantFinderStep.noConsultantFinderModalOpen = false;
      return;

    case StartNowAppActionTypes.resetFindConsultant:
      draft.consultantFinderStep = initialState.consultantFinderStep;
      return;

    case StartNowAppActionTypes.resetFirstConsultants:
      if (draft.consultantFinderStep.consultantFinderResult.items.length > 3) {
        draft.consultantFinderStep.consultantFinderResult.items =
          draft.consultantFinderStep.consultantFinderResult.items.slice(0, 3);
        draft.consultantFinderStep.consultantFinderResult.hasMore = true;
      }
      return;

    case StartNowAppActionTypes.validateRegistrationCodeSuccess:
      draft.registrationCodeCheck = action.res.isValid;
      return;

    case StartNowAppActionTypes.resetRegistrationCodeValidity:
      draft.registrationCodeCheck = initialState.registrationCodeCheck;
      return;

    case StartNowAppActionTypes.resetRegistrationCode:
      draft.startNowAppData.registrationCode = initialState.startNowAppData.registrationCode;
      return;

    case StartNowAppActionTypes.updateRegistrationCode:
      draft.startNowAppData.registrationCode = action.registrationCode;
      return;

    case StartNowAppActionTypes.updateRegistrationCodeStepSkipped:
      draft.registrationCodeStepSkipped = action.skipRegistrationCodeStep;
      draft.startNowAppData.registrationCode = action.registrationCode;
      return;

    case StartNowAppActionTypes.resetRegistrationCodeStepSkipped:
      draft.registrationCodeStepSkipped = initialState.registrationCodeStepSkipped;
      return;

    case StartNowAppActionTypes.updateStartNowAppUserInfo:
      draft.stepProcessing = true;
      draft.startNowAppData.userInfo = {
        ...draft.startNowAppData.userInfo,
        ...action.userInfo,
      };
      return;

    case StartNowAppActionTypes.updateStartNowAppUserInfoSuccess:
      draft.stepProcessing = false;
      draft.aboutYouStep.errors = action.saveUserInfoResult.errors;
      draft.aboutYouStep.isNextEnabled = action.saveUserInfoResult.isSuccess;
      draft.startNowAppData.applicationRegistrationId =
        action.saveUserInfoResult.applicationRegistrationId;
      if (!!action.saveUserInfoResult.securityKey) {
        draft.startNowAppData.securityKey = action.saveUserInfoResult.securityKey;
      }
      return;

    case StartNowAppActionTypes.updateStartNowAppUserInfoFailure:
      if (action.error?.errorTypes?.length) {
        draft.aboutYouStep.errors = [...action.error.errorTypes];
      }
      draft.aboutYouStep.statusCode = action.status;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.resetAboutYouStep:
      draft.aboutYouStep = initialState.aboutYouStep;
      return;

    case StartNowAppActionTypes.updateSelectedConsultant:
      draft.startNowAppData.selectedConsultant = action.selectedConsultant;
      return;

    case StartNowAppActionTypes.resetSelectedConsultant:
      draft.startNowAppData.selectedConsultant = initialState.startNowAppData.selectedConsultant;
      return;

    case StartNowAppActionTypes.emailUniquenessCheck:
    case StartNowAppActionTypes.phoneNumberUniquenessCheck:
      draft.stepProcessing = true;
      return;

    case StartNowAppActionTypes.emailUniquenessCheckSuccess:
      draft.contactStep.contactUniquenessCheck.emailIsValid = action.emailIsValid;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.emailUniquenessCheckFailure:
      draft.contactStep.statusCode = action.status;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.resetEmailValidity:
      draft.contactStep.statusCode = initialState.contactStep.statusCode;
      draft.contactStep.contactUniquenessCheck.emailIsValid =
        initialState.contactStep.contactUniquenessCheck.emailIsValid;
      return;

    case StartNowAppActionTypes.phoneNumberUniquenessCheckSuccess:
      draft.contactStep.contactUniquenessCheck.phoneValidity[action.response.phoneNumberType] =
        action.response.phoneNumberIsValid;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.phoneNumberUniquenessCheckFailure:
      draft.contactStep.statusCode = action.status;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.resetPhoneNumberValidity:
      draft.contactStep.contactUniquenessCheck.phoneValidity[action.phoneNumberType] =
        initialState.contactStep.contactUniquenessCheck.phoneValidity[action.phoneNumberType];
      draft.contactStep.statusCode = initialState.contactStep.statusCode;
      return;

    case StartNowAppActionTypes.updateStartNowAppContactInfo:
      draft.stepProcessing = true;
      draft.startNowAppData.contactInfo = action.contactInfo;
      return;

    case StartNowAppActionTypes.updateStartNowAppContactInfoSuccess:
      draft.stepProcessing = false;
      draft.contactStep.errors = action.saveConsultantDataResult.errors;
      draft.contactStep.isNextEnabled = action.saveConsultantDataResult.isSuccess;
      return;

    case StartNowAppActionTypes.updateStartNowAppContactInfoFailure:
      if (action.error?.errorTypes?.length) {
        draft.contactStep.errors = [...action.error.errorTypes];
      }
      draft.contactStep.statusCode = action.status;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.resetContactStep:
      draft.contactStep = initialState.contactStep;
      return;

    case StartNowAppActionTypes.updateStartNowAppAddressInfo:
      draft.stepProcessing = true;
      draft.startNowAppData.addressInfo.addresses = action.addresses;
      draft.startNowAppData.addressInfo.languagePreferred = action.languagePreferred;
      draft.addressValidationStep.isAddressValidationSuccess = true;
      return;

    case StartNowAppActionTypes.updateStartNowAppAddressInfoSuccess:
      draft.stepProcessing = false;
      draft.addressValidationStep.errors = action.saveConsultantDataResult.errors;
      draft.addressValidationStep.isNextEnabled = action.saveConsultantDataResult.isSuccess;
      return;

    case StartNowAppActionTypes.updateStartNowAppAddressInfoFailure:
      if (action.error?.errorTypes?.length) {
        draft.addressValidationStep.errors = [...action.error.errorTypes];
      }
      draft.addressValidationStep.statusCode = action.status;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.addAddress:
      let addressToUpdate = draft.startNowAppData.addressInfo.addresses.find(
        (address) => address.addressType === action.address.addressType,
      );
      if (addressToUpdate) {
        addressToUpdate = action.address;
      } else {
        draft.startNowAppData.addressInfo.addresses.push(action.address);
      }
      return;

    case StartNowAppActionTypes.fetchCountryStatesSuccess:
      draft.startNowAppData.addressInfo.countryStates = action.countryStates;
      return;

    case StartNowAppActionTypes.validateAddressSuccess:
      draft.addressValidationModal = action.addressValidation;
      draft.addressValidationStep.isAddressValidationSuccess =
        action.addressValidation.payload.isValid;
      draft.stepProcessing = action.addressValidation.payload.isValid;
      return;

    case StartNowAppActionTypes.validateAddressFailure:
      draft.stepProcessing = false;
      draft.addressValidationStep.isAddressValidationSuccess = false;
      return;

    case StartNowAppActionTypes.updateAddressValidationModalValidity:
      draft.addressValidationModal.isValid = action.isSuccess;
      return;

    case StartNowAppActionTypes.resetAddressValidationModal:
      draft.addressValidationModal = initialState.addressValidationModal;
      return;

    case StartNowAppActionTypes.createPassword:
      draft.startNowAppData.userInfo.password = action.password;
      return;

    case StartNowAppActionTypes.fetchStartNowAppStarterKits:
      draft.loading = true;
      return;

    case StartNowAppActionTypes.fetchStartNowAppStarterKitsSuccess:
      draft.loading = false;
      draft.startNowAppData.starterKits = action.starterKits;
      if (isUsaEnv) {
        draft.startNowAppData.selectedStarterKit = action.starterKits[0];
      }
      return;

    case StartNowAppActionTypes.fetchStartNowAppStarterKitsFailure:
      draft.loading = false;
      return;

    case StartNowAppActionTypes.fetchDepositPaymentValueSuccess:
      if (action.res) {
        draft.depositPaymentValue = action.res;
      }
      return;

    case StartNowAppActionTypes.fetchReducedDepositPaymentValueSuccess:
      if (action.res) {
        draft.reducedDepositPaymentValue = action.res;
      }
      return;

    case StartNowAppActionTypes.fetchIsReducedDepositAvailableSuccess:
      if (action.res) {
        draft.isReducedDepositAvailable = action.res;
      }
      return;

    case StartNowAppActionTypes.fetchIsZeroDepositAvailableSuccess:
      if (action.res) {
        draft.isZeroDepositAvailable = action.res;
      }
      return;

    case StartNowAppActionTypes.updateIsDepositPayment:
      draft.startNowAppData.selectedStarterKit.isDepositPayment = action.isDepositPayment;
      return;

    case StartNowAppActionTypes.updateIsReducedDepositPayment:
      draft.startNowAppData.selectedStarterKit.isReducedDepositPayment =
        action.isReducedDepositPayment;
      return;

    case StartNowAppActionTypes.updateStartNowAppSelectedStarterKit:
      draft.startNowAppData.selectedStarterKit = action.selectedStarterKit;
      return;

    case StartNowAppActionTypes.updateStartNowAppSelectedStarterKitSuccess:
      draft.stepProcessing = false;
      draft.starterKitStep.errors = action.saveConsultantDataResult.errors;
      draft.starterKitStep.isNextEnabled = action.saveConsultantDataResult.isSuccess;
      return;

    case StartNowAppActionTypes.updateStartNowAppSelectedStarterKitFailure:
      if (action.error?.errorTypes?.length) {
        draft.starterKitStep.errors = [...action.error.errorTypes];
      }
      draft.starterKitStep.statusCode = action.status;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.checkIfNewPaymentRequired:
      draft.starterKitStep = initialState.starterKitStep;
      return;

    case StartNowAppActionTypes.checkIfNewPaymentRequiredSuccess:
      draft.starterKitStep.fetched = true;
      return;

    case StartNowAppActionTypes.checkIfNewPaymentRequiredFailure:
      draft.starterKitStep.statusCode = action.error.status;
      return;

    case StartNowAppActionTypes.fetchOrderSummarySuccess:
      draft.paymentStep.orderSummary = action.res;
      return;

    case StartNowAppActionTypes.resetOrderSummary:
      draft.paymentStep.orderSummary = initialState.paymentStep.orderSummary;
      return;

    case StartNowAppActionTypes.resetStarterKitStep:
      draft.starterKitStep = initialState.starterKitStep;
      return;

    case StartNowAppActionTypes.updateAreTermsConditionsAgreed:
      draft.startNowAppData.areTermsConditionsAgreed = action.areTermsConditionsAgreed;
      return;

    case StartNowAppActionTypes.fetchClientTokenSuccess:
      draft.startNowAppData.startNowAppPaymentInfo.cardPaymentInfo.clientToken =
        action.payload.clientToken;
      draft.startNowAppData.startNowAppPaymentInfo.customerId = action.payload.customerId;
      return;

    case StartNowAppActionTypes.resetClientToken:
      draft.startNowAppData.startNowAppPaymentInfo.cardPaymentInfo.clientToken =
        initialState.startNowAppData.startNowAppPaymentInfo.cardPaymentInfo.clientToken;
      return;

    case StartNowAppActionTypes.storePaymentType:
      draft.startNowAppData.startNowAppPaymentInfo.paymentType = action.paymentType;
      return;

    case StartNowAppActionTypes.storeCardPaymentInfo:
      draft.startNowAppData.startNowAppPaymentInfo.cardPaymentInfo.cardType =
        action.payload.cardType;
      return draft;

    case StartNowAppActionTypes.storeVoucherPaymentInfo:
      draft.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo = action.payload;
      return draft;

    case StartNowAppActionTypes.storeExternalPaymentInfoSuccess:
      draft.startNowAppData.startNowAppPaymentInfo.externalPaymentInfo = action.payload;
      return draft;

    case StartNowAppActionTypes.updatePaymentProviderType:
      draft.startNowAppData.startNowAppPaymentInfo.provider = action.provider;
      return draft;

    case StartNowAppActionTypes.createOrderSuccess:
      draft.startNowAppData.startNowAppPaymentInfo.externalPaymentInfo = {
        order: action.payload,
      };
      return draft;

    case StartNowAppActionTypes.updateOrderSuccess:
      draft.startNowAppData.startNowAppPaymentInfo.externalPaymentInfo = {
        order: action.response,
      };
      return draft;

    case StartNowAppActionTypes.createOrderFailure:
      draft.startNowAppData.startNowAppPaymentInfo.externalPaymentInfo =
        initialState.startNowAppData.startNowAppPaymentInfo.externalPaymentInfo;
      return draft;

    case StartNowAppActionTypes.storeExternalPaymentInfoFailure:
      draft.startNowAppData.startNowAppPaymentInfo.externalPaymentInfo =
        initialState.startNowAppData.startNowAppPaymentInfo.externalPaymentInfo;
      return draft;

    case StartNowAppActionTypes.createPaymentMethodSuccess:
      draft.startNowAppData.startNowAppPaymentInfo.createdCardPaymentMethod = action.payload;
      return draft;

    case StartNowAppActionTypes.createPaymentMethodFailure:
      draft.startNowAppData.startNowAppPaymentInfo.createdCardPaymentMethod = action.error;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.resetPaymentMethod:
      draft.startNowAppData.startNowAppPaymentInfo.createdCardPaymentMethod =
        initialState.startNowAppData.startNowAppPaymentInfo.createdCardPaymentMethod;
      return draft;

    case StartNowAppActionTypes.updateAdditionalBillingInformation:
      draft.startNowAppData.startNowAppPaymentInfo.additionalBillingInformation =
        action.additionalBillingInformation;
      return;

    case StartNowAppActionTypes.resetAdditionalBillingInformation:
      draft.startNowAppData.startNowAppPaymentInfo.additionalBillingInformation =
        initialState.startNowAppData.startNowAppPaymentInfo.additionalBillingInformation;
      return;

    case StartNowAppActionTypes.createVoucher:
      draft.stepProcessing = true;
      return;

    case StartNowAppActionTypes.createVoucherSuccess:
      draft.startNowAppData.startNowAppPaymentInfo.createdVoucherPaymentMethod = action.payload;
      draft.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo.vouchers = action.payload
        .vouchers
        ? action.payload.vouchers
        : initialState.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo.vouchers;
      draft.stepProcessing = false;
      return draft;

    case StartNowAppActionTypes.createVoucherFailure:
      draft.startNowAppData.startNowAppPaymentInfo.createdVoucherPaymentMethod = {
        ...action.error,
        errorTypes: action.error.error.errors.map((error) => error.errorMessage),
      };
      draft.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo.vouchers =
        initialState.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo.vouchers;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.receiveVouchersSuccess:
      draft.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo.vouchersReceived = action
        .payload.vouchers
        ? action.payload.vouchers.every((item) => item.hasBarcodeUrl === true)
        : false;
      return draft;

    case StartNowAppActionTypes.receiveVouchersFailure:
      draft.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo.vouchersReceived = false;
      draft.stepProcessing = false;
      return draft;

    case StartNowAppActionTypes.resetReceiveVoucher:
      draft.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo.vouchersReceived =
        initialState.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo.vouchersReceived;
      return draft;

    case StartNowAppActionTypes.resetVoucherInfo:
      draft.startNowAppData.startNowAppPaymentInfo.createdVoucherPaymentMethod =
        initialState.startNowAppData.startNowAppPaymentInfo.createdVoucherPaymentMethod;
      draft.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo =
        initialState.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo;
      return;

    case StartNowAppActionTypes.resetPaymentInfo:
      draft.startNowAppData.startNowAppPaymentInfo =
        initialState.startNowAppData.startNowAppPaymentInfo;
      return;

    case StartNowAppActionTypes.resetAgreementStep:
      draft.agreementStep = initialState.agreementStep;
      return;

    case StartNowAppActionTypes.updateSsnNumber:
      draft.agreementStep = initialState.agreementStep;
      draft.stepProcessing = true;
      draft.startNowAppData.userInfo.ssnNumber = action.ssnNumber;
      return;

    case StartNowAppActionTypes.updateSsnNumberSuccess:
      draft.agreementStep.isNextEnabled = action.saveConsultantDataResult.isSuccess;
      draft.agreementStep.errors = action.saveConsultantDataResult.errors;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.updateSsnNumberFailure:
      if (action.error?.errorTypes?.length) {
        draft.agreementStep.errors = [...action.error.errorTypes];
        if (action.error.errorTypes.includes(ConsultantSubmissionErrorType.InvalidSsnNumber)) {
          draft.agreementStep.ssnNumberIsValid = false;
        }
      }
      draft.agreementStep.statusCode = action.status;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.updateAgreementStepNavigableFlag:
      draft.agreementStep.isNextEnabled = action.isNextEnabled;
      return;

    case StartNowAppActionTypes.checkIfCanSubmitConsultant:
      draft.stepProcessing = true;
      return;

    case StartNowAppActionTypes.checkIfCanSubmitConsultantSuccess:
      draft.startNowAppData.canSubmitConsultantInfo = action.payload;
      return;

    case StartNowAppActionTypes.checkIfCanSubmitConsultantFailure:
      draft.startNowAppData.canSubmitConsultantInfo =
        action?.error?.error || DefaultFailedSubmittedConsultantInfo;
      draft.startNowAppData.submitConsultantFailStatusCode = action.error.status;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.resetCanSubmitConsultantInfo:
      draft.startNowAppData.canSubmitConsultantInfo =
        initialState.startNowAppData.canSubmitConsultantInfo;
      draft.startNowAppData.submitConsultantFailStatusCode =
        initialState.startNowAppData.submitConsultantFailStatusCode;
      return;

    case StartNowAppActionTypes.submitConsultant:
      draft.stepProcessing = true;
      return;

    case StartNowAppActionTypes.submitConsultantSuccess:
      draft.stepProcessing = false;
      draft.startNowAppData.submittedConsultantInfo = action.payload;
      if (action.payload.errorTypes.includes(ConsultantSubmissionErrorType.VaultImportFailed)) {
        draft.canChangeSavedCard = false;
      }
      return;

    case StartNowAppActionTypes.submitConsultantFailure:
      const submittedConsultantInfo = action?.error?.error || DefaultFailedSubmittedConsultantInfo;
      draft.startNowAppData.submittedConsultantInfo = submittedConsultantInfo;
      if (
        submittedConsultantInfo.errorTypes.includes(ConsultantSubmissionErrorType.VaultImportFailed)
      ) {
        draft.canChangeSavedCard = false;
      }
      draft.startNowAppData.submitConsultantFailStatusCode = action.error.status;
      draft.stepProcessing = false;
      return;

    case StartNowAppActionTypes.resetSubmitConsultantInfo:
      draft.startNowAppData.submittedConsultantInfo =
        initialState.startNowAppData.submittedConsultantInfo;
      draft.startNowAppData.submitConsultantFailStatusCode =
        initialState.startNowAppData.submitConsultantFailStatusCode;
      return;

    case StartNowAppActionTypes.updateCongratulationStepMode:
      draft.congratulationStepMode = action.mode;
      return;

    case StartNowAppActionTypes.fetchVoucherBarCodeUrlsSuccess:
      draft.voucherBarCodeUrls = action.urls;
      return;

    case StartNowAppActionTypes.updateConfirmRecruiter:
      draft.confirmRecruiter = action.confirmRecruiter;
      return;

    default:
      return;
  }
}, initialState);
