<div class="root container my-4">
  <div class="row">
    <div class="col-12 col-md-6 container">
      <iframe
        id="loginFrame"
        #loginframe
        [src]="(prefillEmail ? 'b2c-signin?prefill_email=' + prefillEmail : 'b2c-signin') | safeUrl"
        frameborder="0"
        scrolling="no"
      >
      </iframe>
      <div *ngIf="showRegisterButton" class="row">
        <div class="col-12 my-3">
          <label i18n class="font-size-smaller"
            >Don't have an account yet? Create one by clicking the button below.</label
          >
          <button
            i18n
            class="w-100 btn btn-outline-dark btn-lg btn-uppercase-lg"
            routerLink="/registration"
          >
            Create an account
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
