<div class="d-none d-md-block">
  <div class="row">
    <div
      class="col d-flex align-items-center justify-content-center text-uppercase step"
      [class.active]="i === activeStep"
      *ngFor="let step of steps; let i = index"
    >
      <span class="step-number mr-1">
        <ng-container *ngIf="i + 1 > activeStep; else stepDone">{{ i + 1 }}</ng-container>
        <ng-template #stepDone>
          <i class="bi bi-check"></i>
        </ng-template>
      </span>
      <span>{{ step }}</span>
    </div>
  </div>
</div>

<div class="row d-md-none mb-0">
  <div class="col-auto pr-0"></div>
  <div class="col-5 font-weight-bold d-flex align-items-center border-y-1 pl-0">
    <span class="text-uppercase">{{ steps[activeStep] }}</span>
  </div>
  <div class="col font-size-tiny d-flex align-items-center justify-content-end border-y-1 pl-0">
    <span *ngIf="steps[activeStep + 1]" i18n>Next: {{ steps[activeStep + 1] }}</span>
  </div>
  <div class="col-2 d-flex align-items-center justify-content-end">
    <app-circular-progress
      [numberOfSteps]="steps.length"
      [currentStep]="activeStep + 1"
    ></app-circular-progress>
  </div>
</div>
