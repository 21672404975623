<div class="row navigator">
  <ng-container *ngFor="let step of steps; let i = index">
    <div class="col" [ngClass]="{ 'pr-0': i === 0, 'px-0': i > 0 }">
      <app-step-ruler [step]="step"></app-step-ruler>
    </div>
    <div class="col-auto px-0 w-32px text-nowrap">
      <app-step [step]="step" (changeStep)="changeStepId($event)"></app-step>
    </div>
  </ng-container>
  <div class="col pl-0">
    <app-step-ruler [step]="steps[lastStep]"></app-step-ruler>
  </div>
</div>
