import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-full-page-spinner',
  templateUrl: './full-page-spinner.component.html',
  styleUrls: ['./full-page-spinner.component.scss'],
})
export class FullPageSpinnerComponent {
  @Input() message: string = '';

  constructor() {}
}
