import { Component, Input } from '@angular/core';
import { DefaultImagePlaceholderSrc } from '@core/constants/image-placeholders';
import { CatalogType } from '@core/enums/catalog-type.enum';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { CatalogItem } from '@core/store/product/product-state-models';

@Component({
  selector: 'app-product-catalog-item',
  templateUrl: './product-catalog-item.component.html',
  styleUrls: ['./product-catalog-item.component.scss'],
})
export class ProductCatalogItemComponent {
  @Input() catalog: CatalogItem;
  @Input() catalogType: CatalogType;

  loading = true;

  readonly placeholder = DefaultImagePlaceholderSrc;

  readonly altSuffix = {
    [CatalogType.Collection]: $localize`collection (view products in collection)`,
    [CatalogType.Category]: $localize`category (view products in category)`,
  };

  thumbnail = Thumbnail;

  onLoad() {
    this.loading = false;
  }
}
