import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-saved-paypal-payment',
  templateUrl: './saved-paypal-payment.component.html',
  styleUrls: ['./saved-paypal-payment.component.scss'],
})
export class SavedPaypalPaymentComponent {
  @Output() resetPayment: EventEmitter<void> = new EventEmitter();

  constructor() {}
}
