<div>
  <h5 class="font-weight-bold my-4" i18n> Order Information </h5>
</div>
<div *ngIf="partyOrderData$ | async as partyOrderData" class="row">
  <div class="col-12 col-md-5 border-r-1 pr-md-5">
    <div
      class="d-flex justify-content-between"
      *ngIf="partyOrderData.hostessName; else consultantName"
    >
      <label i18n class="text-uppercase text-double-dot">Hostess Name</label>
      <i class="font-weight-bold">
        {{ partyOrderData.hostessName }}
      </i>
    </div>
    <ng-template #consultantName>
      <div class="d-flex justify-content-between">
        <label i18n class="text-uppercase text-double-dot">Consultant Name</label>
        <i class="font-weight-bold"> {{ partyOrderData.consultantName }} </i>
      </div>
    </ng-template>
    <div class="d-flex justify-content-between">
      <label i18n class="text-uppercase text-double-dot">Order Number</label>
      <span>
        {{ partyOrderData.orderNumber }}
      </span>
    </div>
    <div class="d-flex justify-content-between">
      <label i18n class="text-uppercase text-double-dot">Date Received</label>
      <span>
        {{ partyOrderData.receivedDate | date: 'longDate' }}
      </span>
    </div>
  </div>

  <div class="col-12 col-md-5 pl-md-5">
    <div class="d-flex justify-content-between">
      <label i18n class="text-uppercase text-double-dot">Total Order Amount</label>
      <span>
        {{ partyOrderData.totalOrderAmount | phCurrency }}
      </span>
    </div>
    <div class="d-flex justify-content-between">
      <label i18n class="text-uppercase text-double-dot">Payment Received</label>
      <span>
        {{ partyOrderData.paymentReceived | phCurrency }}
      </span>
    </div>
    <div class="d-flex justify-content-between">
      <label i18n class="text-uppercase text-double-dot font-weight-bold">Balance Due</label>
      <span class="font-weight-bold">
        {{ partyOrderData.balanceDue | phCurrency }}
      </span>
    </div>
  </div>
</div>
