import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private localStorage = window.localStorage;

  getItem(key: string): string | object {
    try {
      return JSON.parse(this.localStorage.getItem(key));
    } catch {
      return this.localStorage.getItem(key);
    }
  }

  setItem(key: string, value: string | object): void {
    this.localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
  }

  removeItem(key: string): void {
    this.localStorage.removeItem(key);
  }

  clearStorage(): void {
    this.localStorage.clear();
  }

  hasItem(key: string): boolean {
    const item = this.localStorage.getItem(key);
    return !!item;
  }
}
