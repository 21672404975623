import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConsultantRoutingModule } from './consultant-routing.module';
import { MyStoryPageComponent } from './my-story-page/my-story-page.component';
import { SharedModule } from '@shared/shared.module';
import { MyStoryBannerComponent } from './my-story-banner/my-story-banner.component';
import { ConsultantWidgetComponent } from './consultant-widget/consultant-widget.component';
import { ConsultantFinderModalComponent } from './consultant-finder-modal/consultant-finder-modal.component';
import { ConsultantFinderComponent } from './consultant-finder/consultant-finder.component';
import { ConsultantFinderResultComponent } from './consultant-finder-result/consultant-finder-result.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MyOpenPartiesPagesComponent } from './my-open-parties-pages/my-open-parties-pages.component';
import { ConsultantFinderPageComponent } from './consultant-finder-page/consultant-finder-page.component';
import { ConsultantLocatorPageComponent } from './consultant-locator-page/consultant-locator-page.component';
@NgModule({
  declarations: [
    MyStoryPageComponent,
    MyStoryBannerComponent,
    ConsultantWidgetComponent,
    ConsultantFinderModalComponent,
    ConsultantFinderComponent,
    ConsultantFinderResultComponent,
    MyOpenPartiesPagesComponent,
    ConsultantFinderPageComponent,
    ConsultantLocatorPageComponent,
  ],
  imports: [CommonModule, ConsultantRoutingModule, SharedModule, ReactiveFormsModule],
  exports: [MyStoryBannerComponent, ConsultantWidgetComponent, ConsultantFinderModalComponent],
})
export class ConsultantModule {}
