<div class="container">
  <div class="text-center my-5">
    <h1 i18n>Success!</h1>
    <div>
      <span i18n>We've received your information.</span>
    </div>
    <div>
      <span i18n>You'll be hearing from us soon.</span>
    </div>

    <button class="btn btn-primary my-4 text-uppercase" routerLink="/" i18n>Return to Home</button>
  </div>
</div>
