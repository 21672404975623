import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { InfoCardAttributes } from '@core/models/info-card-attributes.model';
import { VoucherService } from '@core/services/voucher.service';

@Component({
  selector: 'app-info-card',
  templateUrl: './app-info-card.component.html',
  styleUrls: ['./app-info-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoCardComponent {
  @Input() data: InfoCardAttributes;
  @Output() backToPreviousStep = new EventEmitter<void>();

  constructor(private voucherService: VoucherService) {}

  download(voucherId: string) {
    this.voucherService.downloadVoucher(voucherId);
  }

  clickModify(): void {
    this.backToPreviousStep.emit();
  }
}
