import produce from 'immer';
import { ConditionalContent, ConditionalContentState } from './conditional-content-state-models';
import { ConditionalContentActionTypes } from './conditional-content.actions';

export const initialState: ConditionalContentState = {
  conditionalContent: [],
  error: null,
  fetched: 0,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case ConditionalContentActionTypes.fetchConditionalContentSuccess:
      const contents: ConditionalContent[] = [...draft.conditionalContent, ...action.content];
      draft.conditionalContent = contents.sort((a, b) => a.displaySequence - b.displaySequence);
      draft.fetched++;
      return draft;

    case ConditionalContentActionTypes.resetConditionalContentState:
      return initialState;
  }
}, initialState);
