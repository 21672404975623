<div class="container rounded h-100 d-flex align-items-center justify-content-center">
  <div class="d-block">
    <div class="text-center pb-5">
      <img class="icon" src="assets/images/icons/logo-small.svg" alt="info" />
    </div>

    <div class="text-center font-size pb-3">
      {{ isProwessDown ? errorMessages.prowessDown : errorMessages.manuallyDisabled }}
    </div>

    <div class="text-center font-size">
      <a routerLink="/" i18n>Go to home page</a>
    </div>
  </div>
</div>
