import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartyOrderErrorType } from '@core/enums/party-order-error-type.enum';
import { PartyOrderPaymentPages } from '@core/enums/party-order-payment-pages.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import {
  selectIsFetchedPartyOrderPayment,
  selectOrderStateLoading,
  selectPartyOrderError,
} from '@core/store/order';
import { PartyOrderError } from '@core/store/order/order-state-models';
import { fetchPartyOrderData } from '@core/store/order/order.actions';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-party-order-payment-search',
  templateUrl: './party-order-payment-search.component.html',
  styleUrls: ['./party-order-payment-search.component.scss'],
})
export class PartyOrderPaymentSearchComponent implements OnInit, OnDestroy {
  @Output()
  nextPage: EventEmitter<PartyOrderPaymentPages> = new EventEmitter<PartyOrderPaymentPages>();
  readonly OrderNumber: string = 'orderNumber';
  readonly MaxLength: number = 9;
  readonly PartyOrderErrorType = PartyOrderErrorType;

  loading$: Observable<boolean>;
  fetched$: Observable<boolean>;
  errorMessage$: Observable<PartyOrderError>;
  partyOrderNumberForm: FormGroup;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
    private appInitService: AppInitService,
  ) {}

  ngOnInit(): void {
    this.partyOrderNumberForm = this.fb.group({
      [this.OrderNumber]: ['', [Validators.required, Validators.maxLength(this.MaxLength)]],
    });

    this.loading$ = this.store$.select(selectOrderStateLoading);
    this.fetched$ = this.store$.select(selectIsFetchedPartyOrderPayment);
    this.errorMessage$ = this.store$.select(selectPartyOrderError);

    this.subscriptions.add(
      this.fetched$
        .pipe(
          filter((success) => success),
          take(1),
        )
        .subscribe(() => {
          const next = this.appInitService.Settings.ec.partyOrderCollectRiskDataFromInput
            ? PartyOrderPaymentPages.AddressPage
            : PartyOrderPaymentPages.InfoPage;
          this.nextPage.emit(next);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submit(): void {
    if (this.partyOrderNumberForm.valid) {
      const orderNumber = this.partyOrderNumberForm.get(this.OrderNumber).value;
      this.store$.dispatch(fetchPartyOrderData({ orderNumber }));
    }
  }
}
