import { Component, OnDestroy, OnInit } from '@angular/core';
import { WebsiteMode } from '@core/enums/website-mode.enum';
import { AppState } from '@core/store';
import { selectCurrentConsultant, selectParty } from '@core/store/consultant';
import { setWebsiteMode } from '@core/store/website-mode/website-mode.actions';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'app-website-mode-initializer',
  template: '',
})
export class WebsiteModeInitializerComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([
        this.store$.select(selectCurrentConsultant),
        this.store$.select(selectParty),
      ]).subscribe(([consultant, party]) => {
        if (!!consultant && !!party) {
          this.store$.dispatch(setWebsiteMode({ websiteMode: WebsiteMode.order }));
        } else if (!!consultant && !party) {
          this.store$.dispatch(setWebsiteMode({ websiteMode: WebsiteMode.consultant }));
        } else if (!consultant && !party) {
          this.store$.dispatch(setWebsiteMode({ websiteMode: WebsiteMode.corporate }));
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
