import { Component } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-wrapper',
  templateUrl: './breadcrumb-wrapper.component.html',
  styleUrls: ['./breadcrumb-wrapper.component.scss'],
})
export class BreadcrumbWrapperComponent {
  constructor() {}
}
