import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from '@base/components/layout/default-layout/default-layout.component';
import { ConditionalContentPageComponent } from './components/conditional-content-page/conditional-content-page.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: DefaultLayoutComponent,
        children: [
          {
            path: ':conditionalContent',
            component: ConditionalContentPageComponent,
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class ConditionalContentWildcardRoutingModule {}
