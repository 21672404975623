import { AuthState } from '@core/store/auth/auth-state-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const authStateFeatureKey = 'authState';

export const selectAuthFeature = createFeatureSelector<AuthState>(authStateFeatureKey);

export const selectIsAuthenticated = createSelector(selectAuthFeature, (state: AuthState) =>
  state ? state.isAuthenticated : undefined,
);

export const selectIsNewLogin = createSelector(selectAuthFeature, (state: AuthState) =>
  state ? state.isNewLogin : false,
);

export const selectStateIsLogoutConcluded = createSelector(
  selectAuthFeature,
  (authState: AuthState) => Object.values(authState.resetConcluded).every((x) => x),
);
