import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { TableSortDirection } from '@core/enums/table-sort-direction.enum';
import { SortableTableColumn } from '@core/models/sortable-table-column.model';
import { AppState } from '@core/store';
import { selectReturnHistory, selectReturnStateLoading } from '@core/store/return';
import { ReturnHistoryItem } from '@core/store/return/return-state-models';
import { fetchReturnHistory } from '@core/store/return/return.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-return-history',
  templateUrl: './return-history.component.html',
  styleUrls: ['./return-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnHistoryComponent implements OnInit {
  returnHistory$: Observable<ReturnHistoryItem[]>;
  returnHistoryTableData$: Observable<object[]>;
  loading$: Observable<boolean>;

  columns: SortableTableColumn[] = [
    {
      field: 'returnNumber',
      headerName: $localize`Return number`,
      sortable: true,
      sort: TableSortDirection.desc,
    },
    { field: 'date', headerName: $localize`Date`, sortable: true, isDate: true },
    { field: 'qty', headerName: $localize`Qty`, sortable: true },
    { field: 'total', headerName: $localize`Total`, sortable: true, currency: '$' },
    {
      field: 'id',
      headerName: '',
      action: true,
      actionIcon: 'bi bi-eye',
      ariaLabel: $localize`View order`,
    },
  ];

  constructor(private store$: Store<AppState>, @Inject(LOCALE_ID) private localeId: string) {}

  ngOnInit(): void {
    this.store$.dispatch(fetchReturnHistory());

    this.loading$ = this.store$.select(selectReturnStateLoading);
    this.returnHistory$ = this.store$.select(selectReturnHistory);
    this.returnHistoryTableData$ = this.returnHistory$.pipe(
      map((returnHistory) =>
        returnHistory.map((item) => ({
          id: item.returnId,
          returnNumber: item.returnId,
          date: formatDate(item.submittedDate, 'longDate', this.localeId),
          qty: item.quantity,
          total: +item.totalAmount.toFixed(2),
        })),
      ),
    );
  }
}
