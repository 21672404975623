function getRecaptchaElement(): HTMLElement {
  return document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
}

export function showRecaptchaBadge(): void {
  const recaptchaHtmlElement = getRecaptchaElement();
  if (recaptchaHtmlElement) {
    recaptchaHtmlElement.style.visibility = 'visible';
  }
}

export function hideRecaptchaBadge(): void {
  const recaptchaHtmlElement = getRecaptchaElement();
  if (recaptchaHtmlElement) {
    recaptchaHtmlElement.style.visibility = 'hidden';
  }
}
