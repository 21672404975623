import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl',
})
export class SafeResourceUrlPipe implements PipeTransform {
  /**
   * Pipe Constructor
   *
   * @param sanitizer: DomSanitezer
   */
  // tslint:disable-next-line
  constructor(protected sanitizer: DomSanitizer) {}

  /**
   * Transform
   *
   * @param value: string
   */
  transform(value: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
