import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BrowserType } from '@core/enums/browser-type.enum';
import { Voucher } from '@core/store/payment/payment-state-models';
import { getBrowserType } from '@shared/utils/browser-utils';

/**
 * We are hiding the Oxxo voucher because it fails from cause of “Refused to display ... URL in a frame because it
 * set 'X-Frame-Options' to 'sameorigin'“. This is a security protection and protects form session hijacking,
 * but in the background activates the voucher. So in this way we hide the IFrame in default.
 */

@Component({
  selector: 'app-oxxo-voucher-container',
  templateUrl: './oxxo-voucher-container.component.html',
  styleUrls: ['./oxxo-voucher-container.component.scss'],
})
export class OxxoVoucherContainerComponent implements OnInit, AfterViewInit {
  @Input() voucher: Voucher;
  @Input() hideIframe: boolean = true;
  @Output() voucherLoaded: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('VoucherIframe') voucherIframe: ElementRef<HTMLIFrameElement>;

  /** In Safari if fails to load none of the voucher in iframe, then wants to download an empty file. User needs time
   * to read the pop-up message and choose action (Cancel, Allow). */
  private readonly SafariHealthCheckPeriod = 6000;

  private isVoucherLoaded: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.handleSafariVoucherOpenFailure();
  }

  ngAfterViewInit(): void {
    this.voucherIframe.nativeElement.onload = this.iframeLoaded.bind(this);
  }

  iframeLoaded(): void {
    this.isVoucherLoaded = true;
    this.voucherLoaded.emit(this.voucher?.token);
  }

  /** Handle Safari blocker iframe issue with a health checker.
   * In Safari in case of Voucher limit exceeding the iframe not sending back any event and the browser want to download
   * an empty file */
  private handleSafariVoucherOpenFailure() {
    if (getBrowserType() == BrowserType.Safari) {
      setTimeout(() => {
        if (!this.isVoucherLoaded) {
          this.voucherLoaded.emit(undefined);
        }
      }, this.SafariHealthCheckPeriod);
    }
  }
}
