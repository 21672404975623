<div class="container">
  <!-- Commented below code - IR-3177 -->
  <!-- <ng-container *ngIf="consultant$ | async as consultant">    
    <hr />
    <div class="row">
      <div *ngIf="consultant.story?.storyImage?.url" class="col-12 col-md-5">
        <img
          class="mb-3"
          width="100%"
          [src]="consultant.story?.storyImage?.url || imagePlaceholder"
          [altText]="consultant.story?.storyImage?.altText"
        />
      </div>
    </div>
  </ng-container> -->
</div>
