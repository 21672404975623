import produce from 'immer';
import { ProductReviewState } from './product-review-state-models';
import { ProductReviewActionTypes } from './product-review.action';

export const initialState: ProductReviewState = {
  loading: false,
  productReviews: [],
  isAllFetched: false,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case ProductReviewActionTypes.resetProductReviewState:
      return initialState;

    case ProductReviewActionTypes.fetchProductReviews:
      draft.productReviews = initialState.productReviews;
      draft.loading = true;
      return;

    case ProductReviewActionTypes.fetchMoreProductReviews:
      draft.loading = true;
      return;

    case ProductReviewActionTypes.fetchProductReviewsSuccess:
      draft.loading = false;
      draft.productReviews = [...draft.productReviews, ...action.productReviews];
      draft.isAllFetched = action.productReviews.length < action.pageSize;
      return;

    case ProductReviewActionTypes.fetchProductReviewsFailure:
      draft.loading = false;
      return;

    default:
      return draft;
  }
}, initialState);
