import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoute } from '@core/constants/route.const';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-address-required-modal',
  templateUrl: './address-required-modal.component.html',
  styleUrls: ['./address-required-modal.component.scss'],
})
export class AddressRequiredModalComponent {
  @ViewChild('modal') private modal: ConfirmModalComponent;

  constructor(private router: Router) {}

  open() {
    this.modal.open();
  }

  onConfirm(): void {}

  navigateToShippingAddress(): void {
    this.router.navigate([UserRoute.MyAccount, UserRoute.Addresses]);
  }
}
