<h1 class="text-title mt-3" i18n>You're almost done!</h1>

<div class="mt-4 mb-5" *ngIf="isUsaEnv">
  <div class="mb-2">
    <span class="font-weight-bold mr-2" i18n>Social Security Number - </span>
    <span class="font-italic" i18n>Don't worry this is a secure site!</span>
  </div>
  <div class="row">
    <div class="col-8 col-md-3" [formGroup]="ssnNumberFormGroup">
      <app-ssn-number-field-input
        [control]="ssnNumberFormGroup.controls[SsnNumberFieldName]"
      ></app-ssn-number-field-input>
    </div>
    <div class="col-auto">
      <i class="bi bi-lock-fill lock-size"></i>
    </div>
  </div>
  <div *ngIf="(agreementStep$ | async).ssnNumberIsValid === false" class="validation-error-message">
    <span>{{ SsnErrorMessage }}</span>
  </div>
</div>

<h6 class="text-subtitle pr-3 pr-md-0" i18n>
  Please review and agree the Consultant Agreement below
</h6>

<div>
  <iframe
    [src]="termsConditionsURL | safeUrl"
    scrolling="yes"
    class="agreement"
    id="agreementFrame"
  ></iframe>
</div>

<div class="my-3 mr-3" [formGroup]="agreementFormGroup">
  <div class="d-flex justify-content-between flex-column-reverse flex-md-row">
    <div class="form-check custom-checkbox">
      <input
        class="form-check-input custom-control-input"
        type="checkbox"
        [formControlName]="TermsConditions"
        id="termsConditions"
      />
      <label
        i18n
        class="form-check-label custom-control-label text-uppercase"
        for="termsConditions"
      >
        I agree to the terms and conditions outlined above
      </label>
    </div>

    <div>
      <button
        type="button"
        class="btn btn-link float-right pt-0 print-button"
        (click)="printAgreement()"
      >
        <i class="bi bi-printer print-icon"></i>
        <span class="text-uppercase print-text" i18n>Print agreement</span>
      </button>
    </div>
  </div>
  <div *ngIf="!termsConditionValidInput" class="validation-error-message">
    <span>{{
      getFormValidationErrorMessage(agreementFormGroup, TermsConditions, ErrorMessages)
    }}</span>
  </div>
</div>

<div *ngIf="agreementStep$ | async as agreement">
  <div
    *ngIf="
      ((!!agreement.statusCode && agreement.statusCode !== StatusCodes.OK) ||
        !!agreement.errors.length) &&
      agreement.ssnNumberIsValid !== false
    "
    class="validation-error-message mb-3"
  >
    <app-sna-http-status-errors [statusCode]="agreement.statusCode"></app-sna-http-status-errors>
  </div>
</div>
