import { Locale } from 'moment';

export function localizeRelativeLinks(html: string, localeId: Locale): string {
  const linkRx = /<a\s+(?:[^>]*?\s+)?href=(["'])(?!http)(.*?)\1/g;
  return html.replace(linkRx, (match, p1, p2) => {
    if (!p2.includes(`/${localeId}/`, 0)) {
      return match.replace(p2, `/${localeId}${p2}`);
    }
    return match;
  });
}

export function localizeAbsoluteLinks(html: string, localeId: Locale): string {
  const hostname = window.location.hostname.replace('www.', '');
  const rx = new RegExp(`${hostname}(?!(\/en-US|\/es-US|\/es-MX))`, 'g');

  return  html.replace(rx, `${hostname}/${localeId}`);

}

export function insertAtIndex(str: string, substring: string, index: number) {
  return str.slice(0, index) + substring + str.slice(index);
}
