import { Component, Input } from '@angular/core';
import { OrderSummary } from '@core/store/start-now-app/start-now-app-state-models';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent {
  @Input()
  orderSummary: OrderSummary;

  constructor() {}
}
