<ng-container>
  <div
    *ngIf="(isRecipeCategoriesFetched$ | async) === false; else content"
    class="d-flex justify-content-center m-5"
  >
    <app-ph-spinner></app-ph-spinner>
  </div>
</ng-container>

<ng-template #content>
  <div *ngIf="(recipeCategories$ | async).length > 1; else recipeNotFound">
    <app-recipe-list-header
      (sortChange)="sortChangeHandler($event)"
      [recipeCategories]="recipeCategories$ | async"
    >
    </app-recipe-list-header>
    <div
      role="region"
      infinite-scroll
      [infiniteScrollThrottle]="100"
      [infiniteScrollDistance]="0.3"
      (scrolled)="onScrollDown()"
      aria-label="Recipes list"
      i18n-aria-label
    >
      <div class="row">
        <article
          class="col-12 col-sm-6 col-md-4 pb-2 pb-md-3"
          *ngFor="let recipe of recipes$ | async"
          [attr.aria-label]="recipe.name"
        >
          <app-recipe-list-item [recipe]="recipe"></app-recipe-list-item>
        </article>
      </div>
    </div>
  </div>

  <ng-template #recipeNotFound>
    <div class="my-4 text-center">
      <span class="text-not-found font-weight-bold" i18n>Recipes not found</span>
    </div>
  </ng-template>
</ng-template>
