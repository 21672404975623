import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StarterKit } from '@core/store/start-now-app/start-now-app-state-models';
import { isMexEnv } from '@shared/utils/environment-utils';

@Component({
  selector: 'app-starter-kit-card',
  templateUrl: './starter-kit-card.component.html',
  styleUrls: ['./starter-kit-card.component.scss'],
})
export class StarterKitCardComponent {
  @Input()
  starterKit: StarterKit;

  @Input()
  isSelected: boolean;

  @Output() selectChange: EventEmitter<StarterKit> = new EventEmitter();

  readonly Select: string = $localize`Select`;
  readonly Selected: string = $localize`Selected`;

  readonly isMexEnv = isMexEnv;

  onClick(starterKit: StarterKit) {
    this.selectChange.emit(starterKit);
  }

  constructor() {}
}
