import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  OnDestroy,
  Optional,
  Output,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { GeneralPasswordMaxLength } from '@core/constants/password-max-length';
import {
  getValidationErrorMessageList,
  validGroup,
  validInput,
} from '@shared/utils/validation.utils';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PasswordInputComponent,
      multi: true,
    },
  ],
})
export class PasswordInputComponent implements ControlValueAccessor, AfterViewInit, OnDestroy {
  @Output()
  passwordChange: EventEmitter<object> = new EventEmitter<object>();

  @Input()
  identifier: string;

  @Input()
  label: string;

  @Input()
  placeholder: string = $localize`Your password goes here`;

  @Input()
  required: boolean = false;

  @Input()
  maxLength: number = GeneralPasswordMaxLength;

  @Input()
  labelStyle: any = {};

  @Input()
  noMatchingPasswordWarningText: string = $localize`Password confirmation does not match`;

  @Input()
  formErrorMessages: { [key: string]: string } = FormErrorMessages;

  @Input()
  formControlName: string;

  @Input()
  groupValidationErrorType: string;

  passwordFieldTextType: boolean;
  disabled = false;
  control: AbstractControl;
  form: FormGroup;

  getErrorMessages = getValidationErrorMessageList;

  value: string;

  @ViewChild('input') private input: ElementRef;
  private passwordChangeObs;

  private onChangeSubscription: Subscription;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  ngAfterViewInit(): void {
    this.control = this.controlContainer?.control.get(this.formControlName);
    this.form = this.controlContainer?.control as FormGroup;
    this.passwordChangeObs = fromEvent(this.input.nativeElement, 'keyup');
    this.onChangeSubscription = this.passwordChangeObs.pipe(debounceTime(300)).subscribe(() => {
      this.passwordChange.emit(this.input.nativeElement.value);
    });
  }

  onChange: (value: string) => void = () => {};
  onTouched = () => {};

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  togglePasswordFieldTextType(): void {
    this.passwordFieldTextType = !this.passwordFieldTextType;
  }

  onKeyUp(event: any) {
    this.value = event.target.value;
    this.onChange(this.value);
    this.onTouched();
  }

  focus() {
    this.input.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.onChangeSubscription.unsubscribe();
  }

  get isValidInput(): boolean {
    if (this.control) {
      return validInput(this.control);
    }
    return true;
  }

  get isValidGroup(): boolean {
    if (this.form && this.groupValidationErrorType) {
      return validGroup(this.form, this.groupValidationErrorType);
    }
    return true;
  }

  get errorMessages(): string[] {
    return getValidationErrorMessageList(this.control, this.formErrorMessages);
  }
}
