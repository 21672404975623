<ng-template #modal>
  <div *ngIf="!modalConfig.hideHeader" class="modal-header">
    <span class="modal-title font-weight-bold font-size-bigger" *ngIf="modalConfig.title">{{
      modalConfig.title
    }}</span>
    <button
      *ngIf="!modalConfig.hideHeaderDismissButton"
      type="button"
      class="close btn-sm"
      aria-label="Close"
      (click)="dismissHeader()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer" *ngIf="!modalConfig.hideCloseButton || !modalConfig.hideDismissButton">
    <button
      type="button"
      class="btn btn-outline-dark text-uppercase"
      (click)="dismiss()"
      *ngIf="!modalConfig.hideDismissButton"
      [disabled]="
        modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()
      "
    >
      {{ getDismissButtonLabel() }}
    </button>
    <button
      type="button"
      class="btn btn-primary text-uppercase"
      (click)="close()"
      *ngIf="!modalConfig.hideCloseButton"
      [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()"
    >
      {{ getCloseButtonLabel() }}
    </button>
  </div>
</ng-template>
