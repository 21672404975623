/* eslint-disable @typescript-eslint/naming-convention */
export enum Locale {
  En = 'en',
  EnUS = 'en-US',
  EsUS = 'es-US',
  EsMX = 'es-MX',
}

export const LocaleLid = {
  [Locale.En]: '0',
  [Locale.EnUS]: '0',
  [Locale.EsUS]: '1',
  [Locale.EsMX]: '2',
};
