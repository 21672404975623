import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Months } from '@core/constants/months-of-year';
import { InputMaxLength } from '@core/enums/input-max-length.enum';
import { AppState } from '@core/store';
import {
  selectAboutYouIsNextEnabled,
  selectAboutYouStep,
  selectUserInfo,
} from '@core/store/start-now-app';
import { AboutYouStep, UserInfo } from '@core/store/start-now-app/start-now-app-state-models';
import {
  resetAboutYouStep,
  updateStartNowAppUserInfo,
} from '@core/store/start-now-app/start-now-app.actions';
import { Store } from '@ngrx/store';
import { isMexEnv } from '@shared/utils/environment-utils';
import { StatusCodes } from 'http-status-codes';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-about-you-step',
  templateUrl: './about-you-step.component.html',
  styleUrls: ['./about-you-step.component.scss'],
})
export class AboutYouStepComponent extends StartNowStepBaseComponent implements OnInit, OnDestroy {
  readonly FirstName: string = 'firstName';
  readonly MiddleName: string = 'middleName';
  readonly LastName: string = 'lastName';
  readonly Month: string = 'month';
  readonly Day: string = 'day';
  readonly Year: string = 'year';
  readonly Conditions: string = 'conditions';
  readonly OlderThan18: string = 'olderThan18';
  readonly Policy: string = 'policy';
  readonly StatusCodes = StatusCodes;
  readonly BirthYearMin = 1900;
  readonly BirthYearMax = new Date().getFullYear() - 18;

  aboutYouFormGroup: FormGroup;
  isSubmitted: boolean = false;
  checkboxErrorMessage: string = isMexEnv
    ? $localize`Please fill in the checkboxes!`
    : $localize`Please fill in the checkbox!`;
  aboutYouStep$: Observable<AboutYouStep>;

  months = Months;

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
    injector: Injector,
  ) {
    super(injector, 'SNA Step - 1 About You');
  }

  ngOnInit(): void {
    this.aboutYouFormGroup = this.createFormGroup();
    this.aboutYouStep$ = this.store$.select(selectAboutYouStep);
    this.prepopulateForm();
    this.isNextEnabledListener();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store$.dispatch(resetAboutYouStep());
  }

  submitStep(): void {
    this.aboutYouFormGroup.markAllAsTouched();
    this.isSubmitted = true;

    if (this.aboutYouFormGroup.valid) {
      const userInfo: UserInfo = {
        firstName: this.aboutYouFormGroup.get(this.FirstName).value,
        middleName: this.aboutYouFormGroup.get(this.MiddleName).value,
        lastName: this.aboutYouFormGroup.get(this.LastName).value,
        birthday: this.createDate(),
      };
      this.store$.dispatch(
        updateStartNowAppUserInfo({
          userInfo,
        }),
      );
    }
  }

  isValid(): boolean {
    return this.isSubmitted ? this.aboutYouFormGroup.get(this.Conditions).valid : true;
  }

  getDaysOfMonth(): number[] {
    const month = this.aboutYouFormGroup.get(this.Month).value;
    const year =
      this.aboutYouFormGroup.get(this.Year).value === ''
        ? 0
        : this.aboutYouFormGroup.get(this.Year).value;
    if (month !== '') {
      const numberOfDays = new Date(year, this.months.indexOf(month) + 1, 0).getDate();
      return Array.from({ length: numberOfDays }, (_, i) => i + 1);
    }
    return [];
  }

  protected createFormGroup(): FormGroup {
    const formGroup = this.fb.group({
      [this.FirstName]: ['', [Validators.required, Validators.maxLength(InputMaxLength.Fifty)]],
      [this.MiddleName]: ['', [Validators.maxLength(InputMaxLength.Twenty)]],
      [this.LastName]: ['', [Validators.required, Validators.maxLength(InputMaxLength.Fifty)]],
      [this.Month]: ['', [Validators.required]],
      [this.Day]: ['', [Validators.required]],
      [this.Year]: [
        '',
        [Validators.required, Validators.max(this.BirthYearMax), Validators.min(this.BirthYearMin)],
      ],
      [this.Conditions]: this.fb.group({
        [this.OlderThan18]: [false, [Validators.requiredTrue]],
      }),
    });
    if (isMexEnv) {
      const conditionFormGroup = formGroup.get(this.Conditions) as FormGroup;
      conditionFormGroup.addControl(this.Policy, new FormControl(false, [Validators.requiredTrue]));
    }
    return formGroup;
  }

  private prepopulateForm(): void {
    this.store$
      .select(selectUserInfo)
      .pipe(take(1))
      .subscribe((userInfo) => {
        if (userInfo) {
          this.aboutYouFormGroup.patchValue({
            [this.FirstName]: userInfo.firstName,
            [this.LastName]: userInfo.lastName,
            [this.MiddleName]: userInfo.middleName,
            [this.Month]: this.months[userInfo.birthday.getUTCMonth()],
            [this.Day]: userInfo.birthday.getUTCDate(),
            [this.Year]: userInfo.birthday.getUTCFullYear(),
            [this.Conditions]: {
              [this.OlderThan18]: true,
            },
          });
          if (isMexEnv) {
            this.aboutYouFormGroup.patchValue({
              [this.Conditions]: {
                [this.Policy]: true,
              },
            });
          }
        }
      });
  }

  private isNextEnabledListener(): void {
    this.subscriptions.add(
      this.store$
        .select(selectAboutYouIsNextEnabled)
        .pipe(filter((isNextEnabled) => isNextEnabled))
        .subscribe(() => this.goToNextStep.emit()),
    );
  }

  private createDate(): Date {
    const day = this.aboutYouFormGroup.get(this.Day).value;
    const month = this.aboutYouFormGroup.get(this.Month).value;
    const year = this.aboutYouFormGroup.get(this.Year).value;
    return new Date(Date.UTC(year, this.months.indexOf(month), day));
  }
}
