<div class="container pl-md-0">
  <div class="d-flex justify-content-between align-items-center">
    <app-account-header i18n-title title="My payment methods"></app-account-header>
    <button [disabled]="loading$ | async" class="btn btn-primary" (click)="addCard()" i18n>
      Add card
    </button>
  </div>

  <div *ngIf="loading$ | async; else content" class="d-flex justify-content-center m-5">
    <app-ph-spinner width="60px"></app-ph-spinner>
  </div>

  <ng-template #content>
    <ng-container [ngTemplateOutlet]="isInMobileView() ? mobile : desktop"> </ng-container>
  </ng-template>
</div>

<ng-template #desktop>
  <app-sortable-table
    [columns]="columns"
    [rows]="paymentMethods"
    [rowStyle]="getRowStyle"
  ></app-sortable-table>
</ng-template>

<ng-template #mobile>
  <div class="mobile item-container">
    <div
      [ngClass]="{ 'item mb-2 p-2': true, expired: item.isExpired }"
      *ngFor="let item of paymentMethods"
    >
      <div class="trash" (click)="delete(item.id)"><i class="bi bi-trash"></i></div>
      <div>
        <div class="font-weight-bold">{{ item.cardName }}</div>
        <div class="font-weight-bold">{{ item.cardNumber }}</div>
        <div class="font-weight-light small-text">
          <span>{{ item.expiryDate }}</span>
          <i *ngIf="item.isExpired" class="bi bi-exclamation-circle ml-1"></i>
        </div>
      </div>
      <div
        class="card-type-mobile"
        [ngStyle]="getCardTypeStyle(item.type)"
        (click)="setAsDefault(item)"
        >{{ PaymentMethodTypeLocale[item.type] }}</div
      >
    </div>
  </div>
</ng-template>

<app-confirm-modal
  #confirmModal
  i18n-title
  title="Delete payment method"
  (yesClick)="onDeleteConfirm($event)"
  i18n-noLabel
  i18n-yesLabel
  noLabel="Cancel"
  yesLabel="Delete"
>
  <div class="text-center" i18n> Are you sure you want to delete this payment method? </div>
</app-confirm-modal>

<app-address-required-modal #addressRequiredModal></app-address-required-modal>
