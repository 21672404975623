import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WebsiteModeState } from './website-mode-state-models';

export const websiteModeStateFeatureKey = 'websiteModeState';

export const selectWebsiteModeStateFeature = createFeatureSelector<WebsiteModeState>(
  websiteModeStateFeatureKey,
);

export const selectWebsiteMode = createSelector(
  selectWebsiteModeStateFeature,
  (state: WebsiteModeState) => (state ? state.websiteMode : null),
);
