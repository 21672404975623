import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-footer-container',
  templateUrl: './footer-container.component.html',
  styleUrls: ['./footer-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterContainerComponent {
  constructor() {}
}
