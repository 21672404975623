<ng-container>
  <table class="desktop table table-striped">
    <thead>
      <tr>
        <th
          *ngFor="let column of columns"
          scope="col"
          class="text-uppercase"
          [sortable]="column.sortable ? column.field : null"
          [attr.sortable]="column.sortable ? column.field : null"
          [direction]="column.sort ? column.sort : ''"
          (sort)="onSort($event)"
        >
          {{ column.headerName }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of computedRows" [ngStyle]="getRowStyle(row)">
        <td *ngFor="let column of columns">
          <ng-container *ngIf="column.action">
            <app-sortable-table-action
              [actionMethod]="column.actionMethod"
              [actionPath]="column.actionPath"
              [identifier]="row[getActionField(column.actionField, column.field)]"
              [ariaLabel]="column.ariaLabel"
            >
              <ng-container
                [ngTemplateOutlet]="field"
                [ngTemplateOutletContext]="{ row: row, column: column }"
              >
              </ng-container>
            </app-sortable-table-action>
          </ng-container>
          <ng-container *ngIf="!column.action">
            <ng-container
              [ngTemplateOutlet]="field"
              [ngTemplateOutletContext]="{ row: row, column: column }"
            >
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-template let-column="column" let-row="row" #field>
  <div
    [ngStyle]="getColumnStyles(column, row)"
    class="field d-flex align-items-center"
    [ngbTooltip]="getTooltip(column, row)"
    [attr.data-private]="column.dataPrivate"
  >
    <ng-container *ngIf="column.imageField">
      <img
        class="mr-2"
        phImage
        [images]="row[column.imageField]"
        [thumbnailDesktop]="row[column.thumbnail]"
      />
    </ng-container>
    <span *ngIf="column.currency">{{ column.currency }}</span>
    <span *ngIf="column.actionIcon" class="action"> <i [ngClass]="column.actionIcon"></i></span>
    <span *ngIf="!column.actionIcon">{{ row[column.field] }}</span>
    <span *ngIf="getWarningIcon(column, row)"><i class="bi bi-exclamation-circle ml-1"></i></span>
  </div>
</ng-template>
