/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable indent */
import { Inject, Injectable } from '@angular/core';
import { ConsultantFinancialStateEnum } from '@core/enums/consultant-financial-state.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { selectConsultantFinancialState } from '@core/store/consultant';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IS_USA_ENV } from './environment-utils';

@Injectable({
  providedIn: 'root',
})
export class UserUtilService {
  isUserFeatureEnabled$: Observable<boolean> = this.isUsaEnv
    ? of(this.appInitService.Settings.ec.isUserEnabled)
    : this.store$
        .select(selectConsultantFinancialState)
        .pipe(
          map((financialState) =>
            financialState === ConsultantFinancialStateEnum.NotAllowedToPlaceOrders
              ? false
              : this.appInitService.Settings.ec.isUserEnabled,
          ),
        );

  constructor(
    private store$: Store<AppState>,
    private appInitService: AppInitService,
    @Inject(IS_USA_ENV) private isUsaEnv: boolean,
  ) {}
}
