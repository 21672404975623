import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultantFinderComponent } from '@consultant/consultant-finder/consultant-finder.component';
import { FindConsultantRequest, LocateConsultantRequest } from '@core/dto/consultant.dto';
import { ConsultantFinderType } from '@core/enums/consultant-finder-type.enum';
import { AppState } from '@core/store';
import { resetFindConsultantState } from '@core/store/consultant/consultant.actions';
import { Store } from '@ngrx/store';

@Component({
  templateUrl: './consultant-finder-page.component.html',
  styleUrls: ['./consultant-finder-page.component.scss'],
})
export class ConsultantFinderPageComponent implements OnInit {
  readonly ConsultantFinderType = ConsultantFinderType;
  finderIsValid: boolean = false;
  filter: FindConsultantRequest | LocateConsultantRequest;

  @ViewChild('consultantFinder') private consultantFinder: ConsultantFinderComponent;

  constructor(private store$: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.store$.dispatch(resetFindConsultantState());
  }

  onFinderValidChange(valid: boolean) {
    this.finderIsValid = valid;
  }

  onFinderSubmitted(value: FindConsultantRequest | LocateConsultantRequest) {
    this.filter = value;
  }

  search() {
    this.consultantFinder.submit();
  }

  selected() {
    this.router.navigateByUrl('/');
  }
}
