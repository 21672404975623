<p class="mb-0" i18n>Please enter your order number and click the submit button.</p>

<form [formGroup]="partyOrderNumberForm">
  <div class="row mb-2">
    <div class="col-12 col-md-6 mt-3">
      <app-text-field-input
        class="mb-md-0 mb-2"
        type="number"
        [identifier]="OrderNumber"
        placeholder="Enter your order number"
        i18n-placeholder
        [formControlName]="OrderNumber"
      >
      </app-text-field-input>
    </div>
    <div class="col-12 col-md-3 align-self-end mt-3">
      <button
        class="btn btn-primary w-100 text-uppercase submit-button"
        [disabled]="loading$ | async"
        (click)="submit()"
      >
        <span class="mr-1" i18n>Submit</span>
        <div class="spinner-border spinner-border-sm" *ngIf="loading$ | async" role="status">
          <span class="sr-only" i18n>Loading...</span>
        </div>
      </button>
    </div>
  </div>

  <div class="row mb-2 mt-6" *ngIf="errorMessage$ | async as error">
    <div class="col-12 col-md-6">
      <span
        class="validation-error-message"
        *ngIf="error.Message === PartyOrderErrorType.MasterOrderNotFound"
        i18n
      >
        Sorry, we couldn't find the order number. Please check the packing slip to verify that the
        order number is correct.
      </span>
      <span
        class="validation-error-message"
        *ngIf="
          error.Message === PartyOrderErrorType.BeeBusinessNotFound ||
          error.Message === PartyOrderErrorType.ConsultantNotFound
        "
        i18n
      >
        Inconsistent data error, consultant or beebusiness isn't found for order.
      </span>
      <span
        class="validation-error-message"
        *ngIf="error.Message === PartyOrderErrorType.IncorrectCountry"
        i18n
      >
        This order is not in your country.
      </span>
      <span
        class="validation-error-message"
        *ngIf="error.Message === PartyOrderErrorType.IncorrectPaymentStatus"
        i18n
      >
        This order can't receive payments currently.
      </span>
      <span
        class="validation-error-message"
        *ngIf="error.Message === PartyOrderErrorType.IncorrectOrderType"
        i18n
      >
        This order number doesn't belong to a party order.
      </span>
    </div>
  </div>
</form>
