// TODO: request from the BE to send errorType = Enum.Parse<ConsultantSubmissionErrorType>(error.ErrorCode)
export enum SnaCreateVoucherPaymentErrorType {
  None = 'None',
  InvalidStarterKitId = 'InvalidStarterKitId',
  InvalidStarterKitPrice = 'InvalidStarterKitPrice',
  MissingEmail = 'MissingEmail',
  TooLongEmail = 'TooLongEmail',
  InvalidEmailFormat = 'InvalidEmailFormat',
  MissingRecruiter = 'MissingRecruiter',
  InvalidRecruiterId = 'InvalidRecruiterId',
  MissingAddressInfo = 'MissingAddressInfo',
  MissingContactInfo = 'MissingContactInfo',
  MissingUserInfo = 'MissingUserInfo',
  Other = 'Other',
}

export enum EcCreateVoucherPaymentErrorType {
  DailyLimitReached = 1001,
  MonthlyLimitReached = 1002,
  YearlyLimitReached = 1003,
}
