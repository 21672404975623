import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaymentHandlerBase } from '@payment/payment-handler/payment-handler-base.model';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  template: '',
})
/** Base component for card payment method handling */
export abstract class CardPaymentMethodHandlerComponent extends BaseComponent {
  /** For payment method creation only */
  @Input() createOnly: boolean = false;

  /** For payment method creation default card */
  @Input() makeDefault: boolean = false;

  /** The payment handler that implements payment related functions */
  @Input() paymentHandler: PaymentHandlerBase;

  /** Listener for payment drop-in component filled out data validity (when data is valid the payment is requestable) */
  @Output() isPaymentMethodRequestable: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** Requests a Payment Method Object from third party and stores it and emits the success or fail status.
   * The PMO includes the properties needed for our side to bind and be able to make the transaction */
  abstract requestPaymentMethodObject(): Observable<boolean>;

  /** Creates a payment method from our side with the third party given properties */
  abstract createPaymentMethod(): void;
}
