import { Injectable } from '@angular/core';
import { ContactUsService } from '@core/services/contact-us.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as contactUsActions from './contact-us.actions';

@Injectable()
export class ContactUsEffects {
  sendEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactUsActions.sendEmail),
      mergeMap(({ contactUsRequest }) =>
        this.contactUsService.sendEmail(contactUsRequest).pipe(
          map((contactUsResponse) => {
            if (contactUsResponse.isSuccessful) {
              return contactUsActions.sendEmailSuccess();
            }
            return contactUsActions.sendEmailFailure();
          }),
          catchError(() => of(contactUsActions.sendEmailFailure())),
        ),
      ),
    ),
  );

  sendEmailFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(contactUsActions.sendEmailFailure),
        tap(() => this.toastr.error($localize`Failed to send email`, $localize`Send email error`)),
      ),
    { dispatch: false },
  );

  constructor(
    private contactUsService: ContactUsService,
    private actions$: Actions,
    private toastr: ToastrService,
  ) {}
}
