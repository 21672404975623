import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageCategory } from '@core/enums/page-category.enum';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { AppState } from '@core/store';
import { addToCart } from '@core/store/cart/cart.actions';
import {
  selectCollectAndSaveCustomerSpecialOffers,
  selectCustomerSpecialOffersLoading,
  selectEcommerceCustomerSpecialOffers,
  selectHealthySavingsCustomerSpecialOffers,
  selectRegularCustomerSpecialOffers,
} from '@core/store/product';
import { ProductItem } from '@core/store/product/product-state-models';
import {
  fetchCollectAndSaveCustomerSpecialOffers,
  fetchEcommerceCustomerSpecialOffers,
  fetchHealthySavingsCustomerSpecialOffers,
  fetchProductQuickView,
  fetchRegularCustomerSpecialOffers,
} from '@core/store/product/product.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './customer-specials-page.component.html',
  styleUrls: ['./customer-specials-page.component.scss'],
})
export class CustomerSpecialsPageComponent implements OnInit {
  readonly PageCategoryTop: PageCategory = PageCategory.customerOffersTop;
  readonly PageCategoryBottom: PageCategory = PageCategory.customerOffersBottom;
  readonly HealthySavingsPageCategory: PageCategory = PageCategory.healthySavings;
  readonly CollectAndSavePageCategory: PageCategory = PageCategory.collectAndSave;
  readonly RegularCustomerSpecialOffersPageCategory: PageCategory =
    PageCategory.regularCustomerSpecialOffers;
  ecommerceCustomerSpecialOffers$: Observable<ProductItem[]>;
  healthySavingsCustomerSpecialOffers$: Observable<ProductItem[]>;
  collectAndSaveCustomerSpecialOffers$: Observable<ProductItem[]>;
  regularCustomerSpecialOffers$: Observable<ProductItem[]>;
  loading$: Observable<boolean>;

  private viewAs: string;

  constructor(private store$: Store<AppState>, private route: ActivatedRoute) {
    this.viewAs = this.route.snapshot.queryParamMap.get(RouterQueryParams.viewAs);
  }

  ngOnInit(): void {
    this.fetchAndSelectCustomerOffers();

    this.loading$ = this.store$.select(selectCustomerSpecialOffersLoading);
  }

  addToCart = (product: ProductItem): void => {
    this.store$.dispatch(
      addToCart({
        payload: {
          images: product.primaryImages,
          collection: product.collection,
          productName: product.name,
          sku: product.sku,
          discountedPrice: product.discountedPrice,
          price: product.price,
          quantity: 1,
          categoryNames: product.categoryNames,
          type: product.type,
        },
      }),
    );
  };

  openQuickView = (sku: string): void => {
    this.store$.dispatch(fetchProductQuickView({ sku }));
  };

  private fetchAndSelectCustomerOffers(): void {
    this.store$.dispatch(fetchEcommerceCustomerSpecialOffers({ viewAs: this.viewAs }));
    this.ecommerceCustomerSpecialOffers$ = this.store$.select(selectEcommerceCustomerSpecialOffers);

    this.store$.dispatch(fetchHealthySavingsCustomerSpecialOffers({ viewAs: this.viewAs }));
    this.healthySavingsCustomerSpecialOffers$ = this.store$.select(
      selectHealthySavingsCustomerSpecialOffers,
    );

    this.store$.dispatch(fetchCollectAndSaveCustomerSpecialOffers({ viewAs: this.viewAs }));
    this.collectAndSaveCustomerSpecialOffers$ = this.store$.select(
      selectCollectAndSaveCustomerSpecialOffers,
    );

    this.store$.dispatch(fetchRegularCustomerSpecialOffers({ viewAs: this.viewAs }));
    this.regularCustomerSpecialOffers$ = this.store$.select(selectRegularCustomerSpecialOffers);
  }
}
