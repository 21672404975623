import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { RelatedCarouselSlide } from '@core/models/related-carousel-slide.model';
import { AppState } from '@core/store';
import { selectIsAuthenticated } from '@core/store/auth';
import { ProductRecommendation } from '@core/store/product/product-state-models';
import { selectRecipeDetails } from '@core/store/recipe';
import { RecipeDetailsItem, RecipeItem } from '@core/store/recipe/recipe-state-models';
import {
  dislikeRecipe,
  fetchRecipeDetails,
  fetchRecipeRecommendations,
  fetchRelatedProducts,
  likeRecipe,
  resetRecipeDetails,
} from '@core/store/recipe/recipe.actions';
import { environment } from '@env';
import { Store, select } from '@ngrx/store';
import { AltTextUtilService } from '@shared/utils/alt-text-util.service';
import { ProductUtilService } from '@shared/utils/product-util.service';
import { getRelatedSlidesFromRelatedRecipes } from '@shared/utils/recipe-utils';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  templateUrl: './recipe-details.component.html',
  styleUrls: ['./recipe-details.component.scss'],
})
export class RecipeDetailsComponent implements OnInit, OnDestroy {
  readonly titlePrefix = $localize`Recipe details for`;

  recipeId: string;
  recipeUrl: string;
  isLiked: boolean = false;
  recipeDetailsItem$: Observable<RecipeDetailsItem>;
  recommendations$: Observable<RecipeItem[]>;
  relatedProducts$: Observable<ProductRecommendation[]>;
  loading$: Observable<boolean>;
  isAuthenticated$: Observable<boolean>;
  thumbnail = Thumbnail;
  feature = environment.feature;

  youMightAlsoLikeSlides$: Observable<RelatedCarouselSlide[]>;
  relatedProductsSlides$: Observable<RelatedCarouselSlide[]>;

  subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private productUtil: ProductUtilService,
    private altTextUtil: AltTextUtilService,
  ) {
    this.recipeUrl = this.activatedRoute.snapshot.paramMap.get('recipeUrl');
  }

  ngOnInit(): void {
    this.recipeDetailsItem$ = this.store$
      .select(selectRecipeDetails)
      .pipe(select((details) => details?.item));

    this.loading$ = this.store$
      .select(selectRecipeDetails)
      .pipe(select((details) => details?.isLoading));

    this.recommendations$ = this.store$.select(selectRecipeDetails).pipe(
      select(
        (details) =>
          details?.recommendations.map((item) => ({
            ...item,
            images: this.altTextUtil.getPhImagesWithAltText(item.images, item.name),
          })) || [],
      ),
    );

    this.relatedProducts$ = this.store$
      .select(selectRecipeDetails)
      .pipe(select((details) => details?.relatedProducts || []));

    this.youMightAlsoLikeSlides$ = this.recommendations$.pipe(
      map((r) => getRelatedSlidesFromRelatedRecipes(r)),
    );

    this.relatedProductsSlides$ = this.relatedProducts$.pipe(
      map((r) => this.productUtil.getRelatedSlidesFromRecommendations(r)),
    );

    this.isAuthenticated$ = this.store$.select(selectIsAuthenticated);

    this.subscriptions.add(
      combineLatest([this.activatedRoute.paramMap, this.activatedRoute.queryParamMap]).subscribe(
        ([pMap, queryParamMap]) => {
          this.recipeUrl = pMap.get('recipeUrl');
          const viewAs = queryParamMap.get(RouterQueryParams.viewAs);
          this.store$.dispatch(fetchRecipeDetails({ url: this.recipeUrl, date: viewAs }));
          this.store$.dispatch(fetchRelatedProducts({ recipeUrl: this.recipeUrl }));
        },
      ),
    );

    this.subscriptions.add(
      this.recipeDetailsItem$.pipe(filter((recipeItem) => !!recipeItem)).subscribe((recipeItem) => {
        this.isLiked = recipeItem.isLiked || false;
        this.recipeId = recipeItem.id;
        this.store$.dispatch(fetchRecipeRecommendations({ recipeId: recipeItem.id }));
      }),
    );
  }

  ngOnDestroy(): void {
    this.store$.dispatch(resetRecipeDetails());
    this.subscriptions.unsubscribe();
  }

  formatHtmlIngredients(str: string): string {
    let formattedHtml = this.makeHrefLinkRelativeToRoot(str);
    formattedHtml = str.replace('col-xs-2', 'col-3');
    if (!this.feature.product) {
      formattedHtml = this.disableProductLinks(formattedHtml);
    }
    return formattedHtml.replace('col-xs-8', 'col');
  }

  formatHtmlInstructions(str: string): string {
    let formattedHtml = this.makeHrefLinkRelativeToRoot(str);
    if (!this.feature.product) {
      formattedHtml = this.disableProductLinks(formattedHtml);
    }
    return formattedHtml;
  }

  toggleLike() {
    if (this.isLiked) {
      this.store$.dispatch(dislikeRecipe({ id: this.recipeId, url: this.recipeUrl }));
    } else {
      this.store$.dispatch(likeRecipe({ id: this.recipeId, url: this.recipeUrl }));
    }
    this.isLiked = !this.isLiked;
  }

  private makeHrefLinkRelativeToRoot(str: string): string {
    const reg = new RegExp('/product/detail', 'g');
    return str.replace(reg, './product/detail');
  }

  private disableProductLinks(str: string): string {
    return str.replace(/<a href=".*\/product\/detail.*">/g, '').replace(/<\/a>/g, '');
  }
}
