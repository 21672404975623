import { Component, Input, OnInit } from '@angular/core';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { AppState } from '@core/store';
import { selectIsAuthenticated } from '@core/store/auth';
import { RecipeItem } from '@core/store/recipe/recipe-state-models';
import { dislikeRecipe, likeRecipe } from '@core/store/recipe/recipe.actions';
import { Store } from '@ngrx/store';
import { createRecipeDetailsUrl } from '@shared/utils/recipe-utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-recipe-image',
  templateUrl: './recipe-image.component.html',
  styleUrls: ['./recipe-image.component.scss'],
})
export class RecipeImageComponent implements OnInit {
  @Input() recipe: RecipeItem;

  readonly thumbnail = Thumbnail;
  readonly altSuffix = $localize`(view recipe)`;

  isLiked: boolean = false;
  detailsUrl: string;
  isAuthenticated$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.detailsUrl = createRecipeDetailsUrl(this.recipe?.url);
    this.isAuthenticated$ = this.store$.select(selectIsAuthenticated);
    this.isLiked = this.recipe.isLiked;
  }

  toggleLike() {
    if (this.isLiked) {
      this.store$.dispatch(dislikeRecipe({ id: this.recipe.id, url: this.recipe.url }));
    } else {
      this.store$.dispatch(likeRecipe({ id: this.recipe.id, url: this.recipe.url }));
    }
    this.isLiked = !this.isLiked;
  }
}
