import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { environment } from '@env';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  prefillEmail: string;
  showRegisterButton = true;
  readonly iframeMinHeight = '20rem';

  @ViewChild('loginframe') loginframe: ElementRef;

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    if (event.origin === `https://${environment.b2c.domain}`) {
      this.loginframe.nativeElement.style.height = event.data?.bodyHeight
        ? `${event.data.bodyHeight}px`
        : this.iframeMinHeight;

      if (event.data?.passwordReset) {
        this.titleService.setTitle($localize`PH - Reset Password - Title`);
        this.showRegisterButton = false;
      }
    }
  }

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
  ) {}

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.prefillEmail = this.route.snapshot.queryParamMap.get('prefill_email');
    this.subscriptions.add(
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((event: EventMessage) =>
            [
              EventType.LOGIN_SUCCESS,
              EventType.ACCOUNT_ADDED,
              EventType.ACQUIRE_TOKEN_SUCCESS,
              EventType.SSO_SILENT_SUCCESS,
            ].includes(event.eventType),
          ),
        )
        .subscribe(() => this.router.navigateByUrl('/')),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
