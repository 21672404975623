import { Component, OnDestroy, OnInit } from '@angular/core';
import { CategoryCollectionPageTitle } from '@core/constants/page-title';
import { CatalogType } from '@core/enums/catalog-type.enum';
import { AppState } from '@core/store';
import {
  selectAllCatalogsFetched,
  selectCatalogs,
  selectCatalogsLoading,
} from '@core/store/product';
import { CatalogItem } from '@core/store/product/product-state-models';
import { fetchCatalogs, resetCatalogs } from '@core/store/product/product.actions';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-product-catalog-page',
  templateUrl: './product-catalog-page.component.html',
  styleUrls: ['./product-catalog-page.component.scss'],
})
export class ProductCatalogPageComponent implements OnInit, OnDestroy {
  catalogs$: Observable<CatalogItem[]>;
  activeCatalogType: CatalogType = CatalogType.Collection;

  readonly CatalogType = CatalogType;
  readonly CategoryCollectionPageTitle = CategoryCollectionPageTitle;

  private readonly pageSize: number = 75;
  private pageNumber: number = 1;
  private loading$: Observable<boolean>;
  private isAllCatalogsFetched$: Observable<boolean>;
  private storeSubject: Subject<void> = new Subject<void>();
  private scrollDown$ = new Subject<number>();
  private subscription: Subscription;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.catalogs$ = this.store$.select(selectCatalogs);
    this.loading$ = this.store$.select(selectCatalogsLoading);
    this.isAllCatalogsFetched$ = this.store$.select(selectAllCatalogsFetched);
    this.fetchFirstPage();
    this.fetchNextPagesOnScrollDown();
  }

  ngOnDestroy(): void {
    this.store$.dispatch(resetCatalogs());
    this.storeSubject.next();
    this.storeSubject.complete();
    this.subscription.unsubscribe();
  }

  public onCatalogTypeChange(): void {
    this.fetchFirstPage();
  }

  onScrollDown(): void {
    this.scrollDown$.next(this.pageNumber);
  }

  private fetchFirstPage(): void {
    this.pageNumber = 1;
    this.store$.dispatch(
      fetchCatalogs({
        catalogType: this.activeCatalogType,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        shouldResetStore: true,
      }),
    );
    this.pageNumber++;
  }

  private fetchNextPagesOnScrollDown(): void {
    this.subscription = this.scrollDown$
      .pipe(
        withLatestFrom(this.loading$),
        filter(([, loading]) => !loading),
        withLatestFrom(this.isAllCatalogsFetched$),
        filter(([, isAllFetched]) => !isAllFetched),
      )
      .subscribe(([[pageNumber]]) => {
        this.store$.dispatch(
          fetchCatalogs({
            catalogType: this.activeCatalogType,
            pageNumber,
            pageSize: this.pageSize,
          }),
        );
        this.pageNumber++;
      });
  }
}
