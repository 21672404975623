<ng-container *ngIf="images$ | async as images">
  <div class="ph-keen-slider media-gallery-root" *ngIf="images.length">
    <div class="d-flex">
      <div class="keen-slider keen-slider-container-{{ size }} flex-shrink-0 mr-3" #sliderRef>
        <div *ngFor="let image of images; let i = index" class="keen-slider__slide">
          <img
            class="carousel-img"
            [attr.tabindex]="showDots() ? '' : 0"
            phImage
            [images]="image"
            [thumbnailDesktop]="thumbnail.productDetailThumbnailDesktop"
            [thumbnailMobile]="thumbnail.productDetailMainMobile"
            (click)="selectMedia(image, MediaType.image)"
            (keydown.enter)="selectMedia(image, MediaType.image)"
            (keydown.space)="selectMedia(image, MediaType.image)"
            (focus)="slider.moveToIdx(i)"
          />
        </div>

        <div *ngFor="let video of videos$ | async; let i = index" class="keen-slider__slide">
          <iframe
            *ngIf="isMobile$ | async"
            [src]="getVideoUrl(video.id) | safeUrl"
            class="carousel-video"
            frameborder="0"
            allow="fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>

          <img
            *ngIf="(isMobile$ | async) === false"
            class="carousel-img"
            [attr.tabindex]="showDots() ? '' : 0"
            [src]="video?.thumbnail || DefaultVideoThumbnailPlaceholderSrc"
            (click)="selectMedia(video, MediaType.video)"
            (keydown.enter)="selectMedia(video, MediaType.video)"
            (keydown.space)="selectMedia(video, MediaType.video)"
            (focus)="slider.moveToIdx(images.length + i)"
            [alt]="video.altText"
          />
        </div>

        <ng-container *ngIf="showArrows()">
          <i
            role="button"
            aria-label="Move image carousel up"
            i18n-aria-label
            (click)="slider.prev()"
            *ngIf="currentSlide !== 0"
            class="arrow-y arrow--up bi bi-chevron-up"
          >
          </i>
          <i
            role="button"
            aria-label="Move image carousel down"
            i18n-aria-label
            (click)="slider.next()"
            *ngIf="
              slider?.track.details.slides.length > desktopPerView &&
              slider?.track.details.slides.length - desktopPerView !== currentSlide
            "
            class="arrow-y arrow--down bi bi-chevron-down"
          >
          </i>
        </ng-container>
      </div>
      <div *ngIf="(isMobile$ | async) === false" class="mb-2 d-md-block">
        <img
          *ngIf="selectedMedia && selectedMediaType === MediaType.image"
          class="img img-main-{{ size }}"
          phImage
          [images]="selectedMedia"
          [thumbnailDesktop]="thumbnail.productDetailMainDesktop"
          [thumbnailMobile]="thumbnail.productDetailMainMobile"
        />
        <iframe
          *ngIf="selectedMedia && selectedMediaType === MediaType.video"
          [src]="getVideoUrl(selectedMedia.id) | safeUrl"
          [width]="VideoSize[size].width"
          [height]="VideoSize[size].height"
          frameborder="0"
          allow="fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
    <div *ngIf="showDots()" class="dots">
      <button
        (click)="slider.moveToIdx(i)"
        *ngFor="let slide of dotHelper; let i = index"
        [class]="'dot ' + (i === currentSlide ? 'active' : '')"
        [attr.aria-current]="i === currentSlide ? 'true' : ''"
      >
        <span class="sr-only" i18n>Go to slide {{ i + 1 }}</span>
      </button>
    </div>
  </div>
</ng-container>
