import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@core/store';
import { selectAddToCartConfirmModal } from '@core/store/cart';
import { ProductItem } from '@core/store/product/product-state-models';
import { selectWishlist } from '@core/store/wishlist';
import { addToWishList, deleteFromWishlist } from '@core/store/wishlist/wishlist.actions';
import { Store, select } from '@ngrx/store';
import { ProductUtilService } from '@shared/utils/product-util.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
})
export class ProductListItemComponent implements OnInit, OnDestroy {
  @Input()
  product: ProductItem;

  @Input()
  catalogName: string;

  @Input()
  onAddToCart: (product: ProductItem) => void;

  @Input()
  openQuickView: (sku: string) => void;

  readonly altSuffix = $localize`(view product)`;

  productDetailsUrl: string;
  isWishlisted: boolean;
  addToCartLoading$: Observable<boolean>;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    public productUtil: ProductUtilService,
  ) {}

  ngOnInit(): void {
    this.productDetailsUrl = this.productUtil.createProductDetailsUrl(
      this.product.collectionUrlName,
      this.product.productUrlName,
      this.product.sku,
    );

    this.addToCartLoading$ = this.store$
      .select(selectAddToCartConfirmModal)
      .pipe(select((state) => state.loading));

    this.subscriptions.add(
      this.store$.select(selectWishlist).subscribe((wishList) => {
        if (wishList.find((wishListItem) => wishListItem.sku === this.product.sku)) {
          this.isWishlisted = true;
        } else {
          this.isWishlisted = false;
        }
      }),
    );
  }

  addToCart(): void {
    this.onAddToCart(this.product);
  }

  onOpenQuickView = (): void => {
    this.openQuickView(this.product.sku);
  };

  toggleWish = (sku: string) => {
    if (this.isWishlisted) {
      this.store$.dispatch(deleteFromWishlist({ sku }));
    } else {
      this.store$.dispatch(addToWishList({ sku }));
    }
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
