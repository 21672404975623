export const UserRoute = {
  MyAccount: 'myaccount',
  Profile: 'profile',
  Orders: 'orders',
  PaymentMethods: 'payment-methods',
  Addresses: 'addresses',
  Wishlist: 'wishlist',
  Recipes: 'recipes',
  Returns: 'returns',
};
