import { Component } from '@angular/core';
import { AppInitService } from '@core/services/app-init.service';

@Component({
  selector: 'app-cookiebot-initializer',
  templateUrl: './cookiebot-initializer.component.html',
})
export class CookiebotInitializerComponent {
  isCookieBotEnabled: boolean;

  constructor(appInitService: AppInitService) {
    this.isCookieBotEnabled = appInitService.Settings.ec.isCookieBotEnabled;
  }
}
