import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { FooterBackgroundShade } from '@core/enums/footer-bg-shade.enum';
import { environment } from '@env';

@Component({
  selector: 'app-footer-top',
  templateUrl: './footer-top.component.html',
  styleUrls: ['../footer.common.scss', './footer-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterTopComponent {
  @Input() shade: FooterBackgroundShade;
  readonly feature = environment.feature;

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  consultantCornerUrl(): string {
    return `${environment.consultantCorner2Uri}/${this.localeId}/`;
  }
}
