import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CheckoutState } from './checkout-state-models';

export const checkoutStateFeatureKey = 'checkoutState';

export const selectCheckoutStateFeature =
  createFeatureSelector<CheckoutState>(checkoutStateFeatureKey);

export const selectPersonalInfo = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.personalInfo : null),
);

export const selectAddressVerification = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.personalInfoModal.addressValidation : null),
);

export const selectSaveSuccessful = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.saveSuccessful : false),
);

export const selectCurrentAddress = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.savedAddressesModal.currentAddress : null),
);

export const selectLoginModal = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.loginModal : null),
);

export const selectUpdatePersonalInfoIsLoading = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.isLoading : false),
);

export const selectPaymentType = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.paymentType : null),
);

export const selectIsRegisteredUserWithoutLogin = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.isRegisteredUserWithoutLogin : false),
);

export const selectEmailCheckErrorType = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.loginModal.checkEmailFailedErrorType : null),
);

export const selectEmailChecked = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.emailChecked : false),
);

export const selectAddressErrorType = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.addressErrorType : null),
);

export const selectBillingInfo = createSelector(
  selectCheckoutStateFeature,
  (state: CheckoutState) => (state ? state.billingInfo : null),
);
