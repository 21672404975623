import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { PasswordPattern } from '@core/constants/patterns';
import { FormErrorTypes } from '@core/enums/form-error-type.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { selectPersonalInfo } from '@core/store/checkout';
import { PersonalInfo } from '@core/store/checkout/checkout-state-models';
import { selectCurrentConsultant } from '@core/store/consultant';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import { registerUser } from '@core/store/user/user.actions';
import { Store } from '@ngrx/store';
import { hideRecaptchaBadge, showRecaptchaBadge } from '@shared/utils/recaptcha-utils';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable, Subscription } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';

enum RegisterFormKey {
  password = 'password',
  passwordConfirm = 'passwordConfirm',
  newsletters = 'newsletters',
  shareWithConsultant = 'shareWithConsultant',
}

@Component({
  selector: 'app-checkout-register-user',
  templateUrl: './checkout-register-user.component.html',
  styleUrls: ['./checkout-register-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutRegisterUserComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly PasswordMatchErrorMessages = FormErrorMessages.passwordConfirm;
  readonly PasswordMatchErrorType = 'passwordMatch';
  readonly RegisterFormKey = RegisterFormKey;
  readonly ErrorMessages = {
    [FormErrorTypes.required]: FormErrorMessages.required,
    // eslint-disable-next-line max-len
    [FormErrorTypes.pattern]: $localize`The password must contains 8 or more characters, upper and lower case letters, at least one number (0-9) and one symbol (!@#^&()+_,.{}?-)`,
  };

  personalInfo$: Observable<PersonalInfo>;

  form: FormGroup;
  passwordMatch: boolean = true;
  disabled: boolean = false;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
    private reCaptchaService: ReCaptchaV3Service,
    private appInitService: AppInitService,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group(
      {
        [RegisterFormKey.password]: [
          '',
          [Validators.required, Validators.pattern(PasswordPattern)],
        ],
        [RegisterFormKey.passwordConfirm]: ['', [Validators.required]],
        [RegisterFormKey.newsletters]: true,
        [RegisterFormKey.shareWithConsultant]: true,
      },
      { validators: [this.passwordMatchValidator] },
    );

    this.personalInfo$ = this.store$.select(selectPersonalInfo);
  }

  ngAfterViewInit(): void {
    showRecaptchaBadge();
  }

  ngOnDestroy(): void {
    hideRecaptchaBadge();
  }

  onSubmit() {
    this.checkIfPasswordMatch();
    this.form.markAllAsTouched();

    const currentConsultant$: Observable<Consultant> = this.store$.select(selectCurrentConsultant);
    if (this.form.valid) {
      this.disabled = true;
      this.subscriptions.add(
        this.reCaptchaService
          .execute('importantAction')
          .pipe(
            withLatestFrom(this.personalInfo$, currentConsultant$),
            filter(([token]) => !!token || !this.appInitService.Settings.ec.isRecaptchaEnabled),
            filter(([, personalInfo]) => !!personalInfo),
            take(1),
          )
          .subscribe(([token, personalInfo, currentConsultant]) => {
            const { firstName, lastName, email, emailConfirm, phoneNumber } =
              personalInfo.billingCustomer;
            const userToRegister = {
              name: { firstName, lastName },
              email: { email, confirmEmail: emailConfirm },
              password: {
                password: this.form.value[RegisterFormKey.password],
                confirmPassword: this.form.value[RegisterFormKey.passwordConfirm],
              },
              subscriptions: {
                newsletters: this.form.value[RegisterFormKey.newsletters],
              },
              activeConsultantId: currentConsultant.id,
              phoneNumber,
              shareWithConsultant: this.form.value[RegisterFormKey.shareWithConsultant],
            };

            this.store$.dispatch(
              registerUser({
                payload: userToRegister,
                token: token,
              }),
            );
          }),
      );
    }
  }

  private passwordMatchValidator(g: FormGroup) {
    const passwordField = g.get(RegisterFormKey.password);
    const passwordConfirmField = g.get(RegisterFormKey.passwordConfirm);
    if (!passwordField.touched || !passwordConfirmField.touched) {
      return null;
    }
    return passwordField.value === passwordConfirmField.value ? null : { passwordMatch: false };
  }

  private checkIfPasswordMatch() {
    switch (this.form?.errors?.passwordMatch) {
      case undefined:
        this.passwordMatch = true;
        break;

      case false:
        this.passwordMatch = false;
        break;
    }
  }
}
