<div class="p-3 position-relative">
  <div *ngIf="isLoading" class="row loading-indicator w-100 h-100">
    <app-ph-spinner
      class="justify-content-center align-items-center h-100 w-100 d-flex"
    ></app-ph-spinner>
  </div>
  <div #PayPalDropInContainer data-private>
    <ngx-paypal
      #payPalElement
      *ngIf="payPalConfig && !isPmoRequestSuccessful"
      [config]="payPalConfig"
      (scriptLoaded)="onScriptLoaded()"
    ></ngx-paypal>
  </div>
</div>
