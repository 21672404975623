export const PasswordPattern = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#^&()+_,.{}?-]).{8,}';

// string must contain one single any kind of character
export const PatternForOneAnyCharacter = new RegExp(
  /^(?=.*[a-z])*(?=.*[A-Z])*(?=.*[0-9])*(?=.*[!@#$%^&*"\'()+,-./\\:;<=>?[]^_`{|}~])*.{1,}/,
);

/* String must contain 4 any kind of character */
export const PatternForFourAnyCharacter = new RegExp(
  /^(?=.*[a-z])*(?=.*[A-Z])*(?=.*[0-9])*(?=.*[!@#$%^&*"\'()+,-./\\:;<=>?[]^_`{|}~])*.{4,}/,
);

// eslint-disable-next-line max-len
/** String must contain at least: one lowercase letter, one uppercase letter, a number/a special char and at least 8 characters */
export const PatternForLetterAndNumberOrSpecialChar = (minLength?) => {
  if (!minLength || minLength < 8) minLength = 8;

  return new RegExp(
    // eslint-disable-next-line max-len
    `^(?=.*[a-z])(?=.*[A-Z])((?=.*[0-9])|(?=.*[!@#$%^&*"'()+,-./\\\\:;<=>?\\[\\]^_\`{|}~])).{${minLength},}`,
  );
};

// eslint-disable-next-line max-len
/* String must contain at least: one lowercase letter, one uppercase letter, a number, a special char and at least 10 characters */
export const PatterForLettersNumbersAndSpecialChars = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*"'()+,-./\\:;<=>?\[\]^_`{|}~]).{10,}/,
);

export const ValidEmailRegExp = new RegExp(
  // eslint-disable-next-line max-len
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const ValidPhoneRegExp = new RegExp(/^$|^[02-9][0-9]*$/);

export const SocialSecurityNumberUrlRegExp = new RegExp(/\/socialsecuritynumberisvalid\/([^\/]*)/i);
