<div [ngClass]="{ 'search-result-document': isOnSearchPage }">
  <a [routerLink]="detailsUrl" class="text-decoration-none text-black">
    <div class="mb-2">
      <app-blog-image [images]="blog?.images"></app-blog-image>
    </div>
    <div class="blog-header">
      <div class="blog-type font-weight-bold mb-1 text-uppercase" *ngIf="isOnSearchPage" i18n
        >Blog</div
      >
      <div class="blog-title">
        {{ blog?.title }}
      </div>
    </div>
  </a>
</div>
