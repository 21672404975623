import { createAction, props } from '@ngrx/store';
import { CartResponse } from '@core/dto/cart.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { AddToCartConfirmModelData } from './cart-state-models';
import { ProductRecommendation } from '../product/product-state-models';
import { PhErrorResponse } from '@core/dto/ph-error';

export enum CartActionTypes {
  resetCartState = '[Cart] Reset Cart State',

  fetchCart = '[Cart] Fetch Cart',
  fetchCartSuccess = '[Cart] Fetch Cart Success',
  fetchCartFailure = '[Cart] Fetch Cart Failure',

  addToCart = '[Cart] Add To Cart',
  addToCartSuccess = '[Cart] Add To Cart Success',
  addToCartFailure = '[Cart] Add To Cart Failure',

  updateOrderLine = '[Cart] Update Order Line',
  updateOrderLineSuccess = '[Cart] Update Order Line Success',
  updateOrderLineFailure = '[Cart] Update Order Line Failure',

  mergeCarts = '[Cart] Merge Carts',
  mergeCartsSuccess = '[Cart] Merge Carts Success',
  mergeCartsFailure = '[Cart] Merge Carts Failure',

  createCart = '[Cart] Create Cart',
  createCartSuccess = '[Cart] Create Cart Success',
  createCartFailure = '[Cart] Create Cart Failure',

  deleteFromCart = '[Cart] Delete from Cart',
  deleteFromCartSuccess = '[Cart] Delete from Cart Success',
  deleteFromCartFailure = '[Cart] Delete from Cart Failure',

  fetchProductsRecommendations = '[Cart] Fetch Products Recommendations',
  fetchProductsRecommendationsSuccess = '[Cart] Fetch Products Recommendations Success',
  fetchProductsRecommendationsFailure = '[Cart] Fetch Products Recommendations Failure',

  addSurpriseBuy = '[Cart] Add Surprise Buy',
  addSurpriseBuySuccess = '[Cart] Add Surprise Buy Success',
  addSurpriseBuyFailure = '[Cart] Add Surprise Buy Failure',

  setAddToCartConfirmModal = '[Product] Set Add To Cart Confirmation Modal',
}

export const resetCartState = createAction(CartActionTypes.resetCartState);

/**
 * Fetch cart
 */
export const fetchCart = createAction(CartActionTypes.fetchCart);
export const fetchCartSuccess = createAction(
  CartActionTypes.fetchCartSuccess,
  props<{ cart: CartResponse }>(),
);
export const fetchCartFailure = createAction(
  CartActionTypes.fetchCartFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Add product to cart
 */
export const addToCart = createAction(
  CartActionTypes.addToCart,
  props<{ payload: AddToCartConfirmModelData }>(),
);
export const addToCartSuccess = createAction(
  CartActionTypes.addToCartSuccess,
  props<{ payload: AddToCartConfirmModelData }>(),
);
export const addToCartFailure = createAction(
  CartActionTypes.addToCartFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Update order line quantity
 */
export const updateOrderLineQty = createAction(
  CartActionTypes.updateOrderLine,
  props<{ orderLineId: number; quantity: number }>(),
);
export const updateOrderLineQtySuccess = createAction(
  CartActionTypes.updateOrderLineSuccess,
  props<{ cart: CartResponse }>(),
);
export const updateOrderLineQtyFailure = createAction(
  CartActionTypes.updateOrderLineFailure,
  props<{ error: HttpErrorResponse }>(),
);
/**
 * Merge guest and user carts
 */
export const mergeCarts = createAction(CartActionTypes.mergeCarts);
export const mergeCartsSuccess = createAction(CartActionTypes.mergeCartsSuccess);
export const mergeCartsFailure = createAction(
  CartActionTypes.mergeCartsFailure,
  props<{ error: HttpErrorResponse }>(),
);

/**
 * Create new, empty cart
 */
export const newCart = createAction(CartActionTypes.createCart);
export const newCartSuccess = createAction(CartActionTypes.createCartSuccess);
export const newCartFailure = createAction(
  CartActionTypes.createCartFailure,
  props<{ error: HttpErrorResponse }>(),
);

/**
 * Delete item from cart
 */
export const deleteFromCart = createAction(
  CartActionTypes.deleteFromCart,
  props<{ orderLineId: number }>(),
);
export const deleteFromCartSuccess = createAction(
  CartActionTypes.deleteFromCartSuccess,
  props<{ cart: CartResponse }>(),
);
export const deleteFromCartFailure = createAction(
  CartActionTypes.deleteFromCartFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Fetch products recommendations
 */
export const fetchProductsRecommendations = createAction(
  CartActionTypes.fetchProductsRecommendations,
  props<{ skus: string[] }>(),
);
export const fetchProductsRecommendationsSuccess = createAction(
  CartActionTypes.fetchProductsRecommendationsSuccess,
  props<{ recommendations: ProductRecommendation[] }>(),
);
export const fetchProductsRecommendationsFailure = createAction(
  CartActionTypes.fetchProductsRecommendationsFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * AddToCart confirm modal
 */
export const setAddToCartConfirmModal = createAction(
  CartActionTypes.setAddToCartConfirmModal,
  props<{ payload: AddToCartConfirmModelData }>(),
);

export const addSurpriseBuy = createAction(CartActionTypes.addSurpriseBuy);
export const addSurpriseBuySuccess = createAction(
  CartActionTypes.addSurpriseBuySuccess,
  props<{ cart: CartResponse }>(),
);
export const addSurpriseBuyFailure = createAction(
  CartActionTypes.addSurpriseBuyFailure,
  props<{ error: HttpErrorResponse }>(),
);
