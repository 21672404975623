import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReturnState } from './return-state-models';

export const returnStateFeatureKey = 'returnState';

export const selectReturnFeature = createFeatureSelector<ReturnState>(returnStateFeatureKey);

export const selectReturnStateLoading = createSelector(selectReturnFeature, (state: ReturnState) =>
  state ? state.loading : false,
);

export const selectReturnHistory = createSelector(selectReturnFeature, (state: ReturnState) =>
  state ? state.returnHistory : [],
);

export const selectReturnDetails = createSelector(selectReturnFeature, (state: ReturnState) =>
  state ? state.returnDetails : null,
);

export const selectSubmitReturnSucceeded = createSelector(
  selectReturnFeature,
  (state: ReturnState) => (state ? state.returnSucceeded : false),
);
