import { Component, Input } from '@angular/core';
import { MelissaErrorCode } from '@core/enums/melissa-error-code';

@Component({
  selector: 'app-address-error-messages',
  templateUrl: './address-error-messages.component.html',
  styleUrls: ['../address-validation.component.scss'],
})
export class AddressErrorMessagesComponent {
  @Input() public hasAddressErrorCode: (code: MelissaErrorCode) => boolean;

  codes = MelissaErrorCode;
}
