import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidEmailRegExp } from '@core/constants/patterns';
import { ZipCodeMinimumLength } from '@core/constants/zip-code-minimum-length';
import { PartyOrderPaymentPages } from '@core/enums/party-order-payment-pages.enum';
import { RiskData } from '@core/models/risk-data.model';
import { AppState } from '@core/store';
import { selectAddresses, selectCountryStates, selectLoading } from '@core/store/address';
import { fetchAddresses, fetchCountryStates } from '@core/store/address/address.actions';
import { resetPartyOrderFetched } from '@core/store/order/order.actions';
import { selectUser } from '@core/store/user';
import { UserModel } from '@core/store/user/user-state-models';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { SelectOption } from '@shared/components/select/select.component';
import { countryStatesToSelectOptions } from '@shared/utils/address-utils';
import { emailValidator } from '@shared/utils/email-validator-utils';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export enum PartyOrderPaymentAddressFormData {
  FirstName = 'firstName',
  LastName = 'lastName',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  AddressLine3 = 'addressLine3',
  City = 'city',
  State = 'state',
  Country = 'country',
  ZipCode = 'zipCode',
  EmailAddress = 'emailAddress',
  PhoneNumber = 'phoneNumber',
}

@Component({
  selector: 'app-party-order-payment-address',
  templateUrl: './party-order-payment-address.component.html',
  styleUrls: ['./party-order-payment-address.component.scss'],
})
export class PartyOrderPaymentAddressComponent extends BaseComponent implements OnInit {
  readonly isMexEnv = isMexEnv;
  readonly FormData = PartyOrderPaymentAddressFormData;

  @Output() public previousPage: EventEmitter<PartyOrderPaymentPages> = new EventEmitter();
  @Output() public nextPage: EventEmitter<PartyOrderPaymentPages> = new EventEmitter();
  @Output() public riskDataChange: EventEmitter<RiskData> = new EventEmitter();

  @Input() public riskData: RiskData;

  addressForm: FormGroup;
  loading$: Observable<boolean>;
  countryStates$: Observable<SelectOption[]>;

  constructor(
    private store$: Store<AppState>,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.addressForm = this.fb.group({
      [this.FormData.FirstName]: [this.riskData?.firstName, [Validators.required]],
      [this.FormData.LastName]: [this.riskData?.lastName, [Validators.required]],
      [this.FormData.AddressLine1]: [this.riskData?.addressLine1, [Validators.required]],
      [this.FormData.AddressLine2]: [this.riskData?.addressLine2],
      [this.FormData.City]: [this.riskData?.city, [Validators.required]],
      [this.FormData.State]: [this.riskData?.state, [Validators.required]],
      [this.FormData.ZipCode]: [
        this.riskData?.zipCode,
        [Validators.required, Validators.minLength(ZipCodeMinimumLength)],
      ],
      [this.FormData.EmailAddress]: [
        this.riskData?.emailAddress,
        [Validators.required, emailValidator(ValidEmailRegExp)],
      ],
      [this.FormData.PhoneNumber]: [this.riskData?.phoneNumber, [Validators.required]],
    });

    if (this.isMexEnv) {
      this.addressForm.addControl(
        this.FormData.AddressLine3,
        new FormControl(this.riskData?.addressLine3),
      );
    }

    this.store$.select(selectUser).subscribe((user?: UserModel) => {
      if (user.firstName && user.lastName) {
        this.store$.dispatch(fetchAddresses());
        this.addressForm.patchValue({
          [this.FormData.FirstName]: user.firstName,
          [this.FormData.LastName]: user.lastName,
          [this.FormData.EmailAddress]: user.email,
          [this.FormData.PhoneNumber]: user.phoneNumber,
        });
      }
    });
    this.store$.dispatch(fetchCountryStates());

    this.loading$ = this.store$.select(selectLoading);
    this.countryStates$ = countryStatesToSelectOptions(this.store$.select(selectCountryStates));

    this.subscriptions.add(
      this.store$
        .select(selectAddresses)
        .pipe(filter((a) => !!a))
        .subscribe((addresses) => {
          const found = addresses.find((a) => a.default);
          if (!!found)
            this.addressForm.patchValue({
              [this.FormData.AddressLine1]: found.addressLine1,
              [this.FormData.AddressLine2]: found.addressLine2,
              [this.FormData.AddressLine3]: found.addressLine3,
              [this.FormData.City]: found.city,
              [this.FormData.State]: found.state,
              [this.FormData.Country]: found.country,
              [this.FormData.ZipCode]: found.zipCode,
            });
        }),
    );
  }

  onCancel(): void {
    this.store$.dispatch(resetPartyOrderFetched());
    this.previousPage.emit(PartyOrderPaymentPages.SearchPage);
  }

  onNext(): void {
    this.riskDataChange.emit(this.addressFormValueToRiskData(this.addressForm.value));
    this.nextPage.emit(PartyOrderPaymentPages.InfoPage);
  }

  private addressFormValueToRiskData(addressFormValue: any): RiskData {
    return { country: environment.country, ...addressFormValue };
  }
}
