<app-page-title
  *ngIf="catalogName$ | async as catalogName"
  [title]="[titlePrefix, catalogName, ProductCatalogType[catalogType]]"
  delimiter=" "
  description="{{ catalogName }} {{ ProductCatalogType[catalogType] }}"
></app-page-title>
<div class="container">
  <app-breadcrumb-wrapper>
    <li class="breadcrumb-item"><a routerLink="/products" i18n>Products</a></li>
    <li class="breadcrumb-item"
      ><a routerLink="/products">{{ ProductCatalogType[catalogType] }}</a></li
    >
    <li class="breadcrumb-item active" aria-current="page">{{ catalogName$ | async }}</li>
  </app-breadcrumb-wrapper>
  <div *ngIf="!(products$ | async).length && (loading$ | async) === false">
    <h3 class="text-center" i18n>No products found.</h3>
  </div>
  <div
    role="region"
    class="container"
    infinite-scroll
    [infiniteScrollThrottle]="100"
    [infiniteScrollDistance]="0.3"
    (scrolled)="onScrollDown()"
    aria-label="Products list"
    i18n-aria-label
  >
    <div class="row">
      <article
        class="col-6 col-md-4 pb-2 pb-md-3"
        *ngFor="let product of products$ | async"
        [attr.aria-label]="product.name"
      >
        <app-product-list-item
          [product]="product"
          [catalogName]="catalogUrlName"
          [onAddToCart]="addToCart"
          [openQuickView]="openQuickView"
        ></app-product-list-item>
      </article>
    </div>
    <app-add-to-cart-confirmation></app-add-to-cart-confirmation>
    <app-product-quickview-dialog></app-product-quickview-dialog>
  </div>
  <div *ngIf="loading$ | async" class="d-flex justify-content-center m-5">
    <app-ph-spinner></app-ph-spinner>
  </div>
</div>
