<app-modal #modal [modalConfig]="modalConfig">
  <span i18n>
    We’re sorry, the Customer Special item(s) in your cart were removed because the offer timeframe
    has ended. Please see our
    <a routerLink="/customer-special-offers" (click)="close()">Customer Offers</a> for the latest
    Customer Specials available to purchase
  </span>
  <div class="d-flex justify-content-center">
    <button class="btn btn-primary" routerLink="/shopping-cart" (click)="close()" i18n
      >Cancel</button
    >
  </div>
</app-modal>
