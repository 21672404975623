import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FooterBackgroundShade } from '@core/enums/footer-bg-shade.enum';

@Component({
  selector: 'app-footer-bottom-sna',
  templateUrl: './footer-bottom-sna.component.html',
  styleUrls: [
    '../footer.common.scss',
    '../footer-bottom/footer-bottom.component.scss',
    './footer-bottom-sna.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterBottomSnaComponent implements OnInit {
  @Input() shade: FooterBackgroundShade;
  constructor() {}

  currentYear: number;

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }
}
