import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@core/store';
import { selectContactUsSendingMessage } from '@core/store/contact-us';
import { SendingContactUsMessage } from '@core/store/contact-us/contact-us-state-models';
import { resetContactUsState } from '@core/store/contact-us/contact-us.actions';
import { Store } from '@ngrx/store';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Observable } from 'rxjs';

export enum ContactUsReasonKey {
  copy = 'copy',
  understand = 'understand',
  deleteData = 'deleteData',
  unsubscribe = 'unsubscribe',
}
export interface ContactUsFormData {
  infoFormData: {
    firstName: string;
    lastName: string;
    homePhone: string;
    mobilePhone: string;
    email: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3?: string;
    city: string;
    state: string;
    zipCode: string;
    comment: string;
  };
  reasons: {
    [ContactUsReasonKey.copy]: boolean;
    [ContactUsReasonKey.understand]: boolean;
    [ContactUsReasonKey.deleteData]: boolean;
    [ContactUsReasonKey.unsubscribe]: boolean;
  };
  subjectId: number;
}

@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss'],
})
export class ContactUsPageComponent implements OnInit, OnDestroy {
  readonly isMexEnv = isMexEnv;
  sendingMessage$: Observable<SendingContactUsMessage>;
  sendingContactUsMessage = SendingContactUsMessage;
  contactUsFormData: ContactUsFormData = {
    infoFormData: {
      firstName: '',
      lastName: '',
      homePhone: '',
      mobilePhone: '',
      email: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      comment: '',
    },
    reasons: {
      copy: false,
      understand: false,
      deleteData: false,
      unsubscribe: false,
    },
    subjectId: 0,
  };

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.sendingMessage$ = this.store$.select(selectContactUsSendingMessage);

    if (this.isMexEnv) {
      this.contactUsFormData.infoFormData.addressLine3 = '';
    }
  }

  ngOnDestroy(): void {
    this.store$.dispatch(resetContactUsState());
  }
}
