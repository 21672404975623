<div class="row mt-5">
  <div class="col-12 col-md-4">
    <div class="row mb-3 mb-md-0">
      <div class="col-auto col-md-12">
        <div class="voucher-image-container text-center">
          <img [src]="PaymentImageSrc.voucherBarcode" />
        </div>
      </div>
      <div class="col col-md-12 align-self-center">
        <p i18n>Get your Paynet reference when selecting to pay in cash.</p>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4">
    <div class="row mb-3 mb-md-0">
      <div class="col-auto col-md-12">
        <div class="voucher-image-container text-center">
          <img [src]="PaymentImageSrc.voucherFile" />
        </div>
      </div>
      <div class="col col-md-12 align-self-center">
        <p i18n>Print it or take it from your cell phone to any affiliated store.</p>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-4 mb-3 mb-md-0">
    <div class="row">
      <div class="col-auto col-md-12">
        <div class="voucher-image-container text-center">
          <img [src]="PaymentImageSrc.voucherShop" />
        </div>
      </div>
      <div class="col col-md-12 align-self-center">
        <p i18n>Give it to the cashier and make your payment. Save the voucher.</p>
      </div>
    </div>
  </div>
</div>
