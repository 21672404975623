import produce from 'immer';
import { AddressState } from './address-state-models';
import { AddressActionTypes } from './address.actions';

export const initialState: AddressState = {
  loading: false,
  addressIsFetched: false,
  addresses: [],
  countryStates: [],
  saveInProgress: false,
  modals: {
    addressValidation: null,
  },
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case AddressActionTypes.resetAddressState:
      return initialState;

    case AddressActionTypes.fetchAddresses:
      draft.loading = true;
      return draft;

    case AddressActionTypes.fetchAddressesSuccess:
      draft.addressIsFetched = true;
      draft.loading = false;
      draft.addresses = action.addresses;
      return draft;

    case AddressActionTypes.fetchAddressesFailure:
      draft.addressIsFetched = true;
      draft.loading = false;
      return draft;

    case AddressActionTypes.fetchCountryStatesSuccess:
      draft.countryStates = action.countryStates;
      return draft;

    case AddressActionTypes.resetAddressValidationModal:
      draft.modals.addressValidation = initialState.modals.addressValidation;
      return draft;

    case AddressActionTypes.createAddress:
    case AddressActionTypes.updateAddress:
      draft.saveInProgress = true;
      return draft;

    case AddressActionTypes.createAddressFailure:
    case AddressActionTypes.updateAddressFailure:
      draft.saveInProgress = false;
      return draft;

    case AddressActionTypes.addressValidation:
      draft.modals.addressValidation = action.addressValidation;
      draft.saveInProgress = false;
      return draft;

    case AddressActionTypes.createAddressSuccess:
    case AddressActionTypes.updateAddressSuccess:
      draft.saveInProgress = false;
      return draft;

    default:
      return;
  }
}, initialState);
