import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductReviewState } from './product-review-state-models';

export const productReviewFeatureKey = 'productReviewState';

export const selectProductReviewStateFeature =
  createFeatureSelector<ProductReviewState>(productReviewFeatureKey);

export const selectLengthOfProductReviews = createSelector(
  selectProductReviewStateFeature,
  (state: ProductReviewState) => (state ? state.productReviews.length : 0),
);

export const selectProductReviewsState = createSelector(
  selectProductReviewStateFeature,
  (state: ProductReviewState) => (state ? state : null),
);

export const selectProductReviews = createSelector(
  selectProductReviewStateFeature,
  (state: ProductReviewState) => (state ? state.productReviews : []),
);

export const selectProductReviewsLoading = createSelector(
  selectProductReviewStateFeature,
  (state: ProductReviewState) => (state ? state.loading : false),
);

export const selectAllReviewsFetched = createSelector(
  selectProductReviewStateFeature,
  (state: ProductReviewState) => (state ? state.isAllFetched : false),
);
