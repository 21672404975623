import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FooterBackgroundShade } from '@core/enums/footer-bg-shade.enum';
import { FooterLink } from '@core/store/footer/footer-state-models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['../footer.common.scss', './footer-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLinksComponent {
  @Input() shade: FooterBackgroundShade;
  @Input() links: Observable<FooterLink>;
  @Input() isSecondary: boolean = false;
  @Input() linkTarget: '_blank' | '_self';

  constructor() {}
}
