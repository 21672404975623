import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { SnaPasswordMaxLength, SnaPasswordMinLength } from '@core/constants/password-max-length';
import { PatternForLetterAndNumberOrSpecialChar } from '@core/constants/patterns';
import { FormErrorTypes } from '@core/enums/form-error-type.enum';
import { AppState } from '@core/store';
import { selectPassword } from '@core/store/start-now-app';
import { createPassword, stepProcessing } from '@core/store/start-now-app/start-now-app.actions';
import { Store } from '@ngrx/store';
import { matchValidator } from '@shared/utils/validation.utils';
import { filter, take } from 'rxjs/operators';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-password-backoffice-portal-step',
  templateUrl: './password-backoffice-portal-step.component.html',
  styleUrls: ['./password-backoffice-portal-step.component.scss'],
})
export class PasswordBackOfficeStepComponent extends StartNowStepBaseComponent implements OnInit {
  readonly Password: string = 'password';
  readonly PasswordConfirm: string = 'passwordConfirm';
  readonly PasswordMinLength: number = SnaPasswordMinLength;
  readonly PasswordMaxLength: number = SnaPasswordMaxLength;
  readonly FormErrorTypes = FormErrorTypes;

  readonly ErrorMessages = {
    [FormErrorTypes.required]: FormErrorMessages.snaRequired,
    [FormErrorTypes.pattern]: FormErrorMessages.passwordPattern,
  };

  public passwordFormGroup: FormGroup;
  formSubmitted: boolean = false;
  passwordLabel = { color: '#747487' };
  public passwordContent: string;

  constructor(
    private store$: Store<AppState>,
    private fb: FormBuilder,
    injector: Injector,
  ) {
    super(injector, 'SNA Step - 6 Password Backoffice Portal');
  }

  ngOnInit(): void {
    this.passwordFormGroup = this.createFormGroup();
    this.prepopulateForm();
  }

  submitStep(): void {
    this.passwordFormGroup.markAllAsTouched();
    this.formSubmitted = true;
    if (this.passwordFormGroup.valid) {
      this.store$.dispatch(stepProcessing({ stepProcessing: true }));
      this.store$.dispatch(
        createPassword({
          password: this.passwordFormGroup.get(this.Password).value,
        }),
      );
      this.goToNextStep.emit();
    }
  }

  handlePasswordChange(value: string) {
    this.passwordContent = value;
  }

  protected createFormGroup(): FormGroup {
    return this.fb.group(
      {
        [this.Password]: [
          '',
          [
            Validators.required,
            Validators.pattern(PatternForLetterAndNumberOrSpecialChar(SnaPasswordMinLength)),
          ],
        ],
        [this.PasswordConfirm]: ['', [Validators.required]],
      },
      {
        validators: matchValidator(
          this.PasswordConfirm,
          this.Password,
          this.FormErrorTypes.passwordConfirm,
        ),
      },
    );
  }

  private prepopulateForm() {
    this.store$
      .select(selectPassword)
      .pipe(
        take(1),
        filter((password) => !!password),
      )
      .subscribe((password) => {
        this.passwordFormGroup.get(this.Password).setValue(password);
        this.passwordFormGroup.get(this.PasswordConfirm).setValue(password);
      });
  }
}
