import { CountryState } from '@core/store/address/address-state-models';
import { SelectOption } from '@shared/components/select/select.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function countryStatesToSelectOptions(
  countryStates$: Observable<CountryState[]>,
): Observable<SelectOption[]> {
  return countryStates$.pipe(
    map((countryStates) => countryStates.map((s) => ({ name: s.name, value: s.code }))),
  );
}
