import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderStatusLocale } from '@core/constants/shipment-status-locale';
import { InfoCardAttributes } from '@core/models/info-card-attributes.model';
import { AppState } from '@core/store';
import { selectCCOrderDetails, selectCCOrderStateLoading } from '@core/store/order';
import {
  AddressInformation,
  CCOrderDetails,
  CustomerNameInfo,
} from '@core/store/order/order-state-models';
import { fetchCCOrderDetails, resetCCOrderDetailsState } from '@core/store/order/order.actions';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-cc-order-details',
  templateUrl: './cc-order-details.component.html',
  styleUrls: ['./cc-order-details.component.scss'],
})
export class CcOrderDetailsComponent implements OnInit, OnDestroy {
  ccOrderDetails$: Observable<CCOrderDetails>;
  loading$: Observable<boolean>;
  poeOrderId: string;

  readonly AddressCardTitles = {
    shippingInfo: $localize`Shipping information`,
    billingInfo: $localize`Billing information`,
  };

  readonly OrderStatusLocale = OrderStatusLocale;

  private subscriptions: Subscription = new Subscription();

  constructor(private store$: Store<AppState>, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.ccOrderDetails$ = this.store$.select(selectCCOrderDetails);
    this.loading$ = this.store$.select(selectCCOrderStateLoading);

    this.subscriptions.add(
      this.activatedRoute.paramMap.subscribe((pMap) => {
        this.poeOrderId = pMap.get('poeOrderId');
        this.store$.dispatch(fetchCCOrderDetails({ poeOrderId: this.poeOrderId }));
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.store$.dispatch(resetCCOrderDetailsState());
  }

  createShippingAddressInfoCard(address: AddressInformation, title: string): InfoCardAttributes {
    return {
      title: title,
      addressLine1: `${address.address1}, ${address.address2}`,
      addressLine2: `${address.city}, ${address.stateProvince}, ${address.zipPostalCode}`,
    };
  }

  createCustomerInfoCard(customerShippingInfo: CustomerNameInfo): InfoCardAttributes {
    return { customer: customerShippingInfo.firstName + ' ' + customerShippingInfo.lastName };
  }

  getTotal(ccOrderDetails: CCOrderDetails): number {
    return (
      ccOrderDetails.totalMerchandiseCost +
      ccOrderDetails.shippingCost +
      ccOrderDetails.tax.finalAmount
    );
  }
}
