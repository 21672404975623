import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartonTableComponent } from './components/carton-table/carton-table.component';
import { DeliveryInfoPageComponent } from './components/deliver-info-page/delivery-info-page.component';
import { DeliveryInfoRoutingModule } from './delivery-info-routing.module';

@NgModule({
  declarations: [DeliveryInfoPageComponent, CartonTableComponent],
  imports: [CommonModule, DeliveryInfoRoutingModule],
})
export class DeliveryInfoModule {}
