import { SubmitContactInfoDto, SubmitUserInfoParamsDto } from '@core/dto/start-now-app.dto';
import { ContactInfo, UserInfo } from '@core/store/start-now-app/start-now-app-state-models';

export function mapUserInfoToSubmitUserInfoParamsDto(userInfo: UserInfo): SubmitUserInfoParamsDto {
  return {
    firstName: userInfo.firstName,
    middleName: userInfo.middleName,
    lastName: userInfo.lastName,
    birthday: userInfo.birthday,
    nickName: userInfo.firstName,
    password: userInfo.password,
    governmentId: userInfo.ssnNumber,
  };
}

export function mapContactInfoToSubmitContactInfoDto(
  contactInfo: ContactInfo,
): SubmitContactInfoDto {
  return {
    email: contactInfo.email,
    cellPhoneNumber: contactInfo.cellPhoneNumber,
    homeNumber: contactInfo.homeNumber,
    canBeContactedOnWhatsApp: contactInfo.whatsapp,
    canBeContactedOnSms: contactInfo.sms,
  };
}
