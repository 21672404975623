import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReturnOrderLineType } from '../new-return/new-return.component';

@Component({
  selector: 'app-return-order-lines',
  templateUrl: './return-order-lines.component.html',
  styleUrls: ['./return-order-lines.component.scss'],
})
export class ReturnOrderLinesComponent {
  @Input() readOnly: boolean;
  @Input() orderLines: ReturnOrderLineType[] = [];
  @Output() orderLinesChange: EventEmitter<ReturnOrderLineType[]> = new EventEmitter<
    ReturnOrderLineType[]
  >();

  constructor() {}

  orderLineChange(orderLine: ReturnOrderLineType, index: number) {
    const newOrderLines = [...this.orderLines];
    newOrderLines[index] = orderLine;
    this.orderLinesChange.emit(newOrderLines);
  }
}
