<div *ngIf="orderDetails$ | async as orderDetails">
  <a routerLink=".." type="button" class="d-none d-md-block">
    <i class="bi bi-arrow-left"></i>
    <span i18n> My Orders</span>
  </a>
  <div class="d-none d-md-flex justify-content-between align-items-center">
    <app-account-header title="{{ orderDetails.orderNumber }}"></app-account-header>
    <div>
      <span class="text-collection d-block info-block mb-3">
        <span class="text-double-dot" i18n>Order Date</span
        >{{ orderDetails.createdDate | date: 'longDate' }} |
        <strong>{{ OrderStatusLocale[orderDetails.orderStatus] }}</strong>
      </span>
    </div>
  </div>
  <div class="d-md-none">
    <div class="row order-text-row mb-0">
      <div class="col">
        <a routerLink=".." class="text-secondary" i18n>My Orders ></a>
        <span class="font-weight-bold ml-1" i18n>Order #{{ orderDetails.orderNumber }}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="font-weight-bold order-id">
          <a routerLink=".."><i class="bi bi-arrow-left"></i></a>
          <span class="ml-2" i18n>Order #{{ orderDetails.orderNumber }}</span>
        </div>

        <div class="order-date">
          <span>
            <span class="text-double-dot" i18n>Order date</span
            >{{ orderDetails.createdDate | date: 'longDate' }} |
            <strong>{{ OrderStatusLocale[orderDetails.orderStatus] }}</strong>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="my-3" *ngIf="(vouchers$ | async)?.length > 0">
    <div class="title" i18n>Download vouchers</div>
    <div class="d-flex flex-wrap justify-content-start">
      <ng-container *ngFor="let voucher of vouchers$ | async; index as i">
        <button class="btn btn-link btn-sm mr-1" (click)="download(voucher.token)">
          <span class="mr-1" i18n>Voucher</span>
          <span>{{ i + 1 }}</span>
        </button>
      </ng-container>
    </div>
  </div>

  <div *ngIf="loading$ | async; else content" class="d-flex justify-content-center m-5">
    <app-ph-spinner width="60px"></app-ph-spinner>
  </div>

  <ng-template #content>
    <div class="summary-content">
      <hr class="my-0 d-lt-none" />
      <div class="row d-none d-md-flex">
        <app-info-card
          class="col-md-6 b-right px-3 pl-md-4"
          [data]="
            createConsultantInfoCard(
              orderDetails.consultantFirstName,
              orderDetails.consultantLastName
            )
          "
        ></app-info-card>
        <app-info-card
          class="col-md-6 px-3 px-md-5 pl-md-5"
          [data]="createHostInfoCard(orderDetails.partyHost)"
        ></app-info-card>
      </div>
      <div class="row d-md-none">
        <app-info-card
          class="col-12 px-3"
          [data]="
            createPartyInfoCard(
              orderDetails.consultantFirstName + ' ' + orderDetails.consultantLastName,
              orderDetails.partyHost
            )
          "
        ></app-info-card>
      </div>
      <div class="d-md-none row"> </div>
      <hr class="my-0 d-lt-none" />
      <div class="row" *ngIf="!!orderDetails">
        <app-info-card
          class="col-12 col-md-6 b-right px-3 pl-md-4"
          [data]="createPersonalInfoCard(orderDetails.personalInformation)"
        ></app-info-card>
        <app-info-card
          class="col-12 col-md-6 px-3 px-md-0 pl-md-5"
          [data]="
            createAddressInfoCard(orderDetails.shippingAddress, AddressCardTitles.shippingInfo)
          "
        ></app-info-card>
      </div>
      <hr class="my-0 d-lt-none" />
      <div class="row">
        <app-info-card
          class="col-12 col-md-6 b-right px-3 pl-md-4"
          [data]="createAddressInfoCard(orderDetails.billingAddress, AddressCardTitles.billingInfo)"
        ></app-info-card>
        <app-info-card
          *ngIf="(orderDetails$ | async).cardFirstFourDigits"
          class="col-12 col-md-6 px-3 px-md-0 pl-md-5"
          [data]="cardNumber$ | async"
        ></app-info-card>
      </div>
      <hr class="my-0 d-lt-none" />
    </div>

    <ng-contaienr *ngIf="(orderDetails$ | async).backOrderItems.length">
      <div class="row my-3 mt-md-5 mb-md-3">
        <div class="col">
          <div class="title">
            <span i18n>To be shipped</span>
          </div>
        </div>
      </div>
      <app-order-details-back-order-items></app-order-details-back-order-items>
    </ng-contaienr>

    <ng-contaienr *ngIf="(orderDetails$ | async).cartons.length">
      <div class="row my-3 mt-md-5 mb-md-3">
        <div class="col">
          <div class="title">
            <span i18n>Carton information</span>
          </div>
        </div>
      </div>
      <app-order-details-cartons></app-order-details-cartons>
    </ng-contaienr>

    <div class="row my-3 mt-md-5 mb-md-0">
      <div class="col">
        <div class="title">
          <span i18n>Product(s)</span>
        </div>
      </div>
    </div>
    <app-order-details-table [orderLines]="orderLines$ | async"></app-order-details-table>
    <div class="row">
      <div class="col-12 d-block d-md-flex justify-content-end m-auto">
        <app-order-details-total
          [promotionalDiscount]="orderDetails.promotionalDiscount"
          [merchandiseTotal]="orderDetails.merchandiseTotal"
          [deliveryFeeTotal]="orderDetails.deliveryFeeTotal"
          [salesTaxTotal]="orderDetails.salesTaxTotal"
          [totalPrice]="orderDetails.totalPrice"
        ></app-order-details-total>
      </div>
    </div>
  </ng-template>
</div>
