import { Injectable } from '@angular/core';
import { ProductService } from '@core/services/product.service';
import { RecipeService } from '@core/services/recipe.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { toPretty, toTitleCase } from '@shared/utils/text-utils';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import * as recipeActions from './recipe.actions';

@Injectable()
export class RecipeEffects {
  fetchRecipes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.fetchRecipes),
      exhaustMap((payload) =>
        this.recipeService.fetchRecipes(payload).pipe(
          map((res) =>
            recipeActions.fetchRecipesSuccess({
              items: res.recipes,
              pageSize: payload.pageSize,
              isFirstPage: payload.isFirstPage,
            }),
          ),
          catchError((error) => of(recipeActions.fetchRecipesFailure({ error }))),
        ),
      ),
    ),
  );

  fetchRecipesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(recipeActions.fetchRecipesFailure),
        tap(() => {
          this.toastr.error($localize`Failed to fetch recipes`, $localize`Recipe fetching error`);
        }),
      ),
    { dispatch: false },
  );

  fetchRecipeCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.fetchRecipeCategories),
      mergeMap(() =>
        this.recipeService.fetchRecipeCategories().pipe(
          map(({ recipeCategories }) =>
            recipeActions.fetchRecipeCategoriesSuccess({ recipeCategories }),
          ),
          catchError((error) => of(recipeActions.fetchRecipeCategoriesFailure({ error }))),
        ),
      ),
    ),
  );

  fetchRecipeCategoriesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(recipeActions.fetchRecipeCategoriesFailure),
        tap(() => {
          this.toastr.error(
            $localize`Failed to fetch recipecategories`,
            $localize`Recipecategory fetching error`,
          );
        }),
      ),
    { dispatch: false },
  );

  fetchRecipeDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.fetchRecipeDetails),
      mergeMap(({ url, date }) =>
        this.recipeService.fetchRecipeDetails(url, date).pipe(
          map((item) => recipeActions.fetchRecipeDetailsSuccess({ item })),
          catchError(() => of(recipeActions.fetchRecipeDetailsFailure())),
        ),
      ),
    ),
  );

  fetchRecipeDetailsSilent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.fetchRecipeDetailsSilent),
      mergeMap(({ url }) =>
        this.recipeService.fetchRecipeDetails(url).pipe(
          map((item) => recipeActions.fetchRecipeDetailsSuccess({ item })),
          catchError(() => of(recipeActions.fetchRecipeDetailsFailure())),
        ),
      ),
    ),
  );

  fetchRecipeDetailsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(recipeActions.fetchRecipeDetailsFailure),
        tap(() => {
          this.toastr.error(
            $localize`Failed to fetch recipedetails`,
            $localize`Recipe details fetching error`,
          );
        }),
      ),
    { dispatch: false },
  );

  fetchRecipeRecommendations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.fetchRecipeRecommendations),
      mergeMap(({ recipeId }) =>
        this.recipeService.fetchRecipeRecommendations(recipeId).pipe(
          map((res) => recipeActions.fetchRecipeRecommendationsSuccess({ items: res.recipes })),
          catchError(() => of(recipeActions.fetchRecipeRecommendationsFailure())),
        ),
      ),
    ),
  );

  fetchRecipeRecommendationsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(recipeActions.fetchRecipeRecommendationsFailure),
        tap(() => {
          this.toastr.error(
            $localize`Failed to fetch recipe recommendations`,
            $localize`Recipe recommendations fetching error`,
          );
        }),
      ),
    { dispatch: false },
  );

  fetchRelatedProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.fetchRelatedProducts),
      mergeMap(({ recipeUrl }) =>
        this.productService.fetchRelatedProducts(recipeUrl).pipe(
          map((res) => recipeActions.fetchRelatedProductsSuccess({ items: res.products })),
          catchError(() => of(recipeActions.fetchRelatedProductsFailure())),
        ),
      ),
    ),
  );

  fetchRelatedProductsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(recipeActions.fetchRelatedProductsFailure),
        tap(() => {
          this.toastr.error(
            $localize`Failed to fetch related products`,
            $localize`Related products fetching error`,
          );
        }),
      ),
    { dispatch: false },
  );

  fetchUserRecipes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.fetchUserRecipes),
      mergeMap(() =>
        this.recipeService.fetchUserRecipes().pipe(
          map((res) =>
            recipeActions.fetchUserRecipesSuccess({
              items: res.recipes.map((r) => ({
                ...r,
                categories: r.categoryUrls
                  .slice(0, 4)
                  .map((url) => toTitleCase(toPretty(url)))
                  .join(', '),
              })),
            }),
          ),
          catchError(() => of(recipeActions.fetchUserRecipesFailure())),
        ),
      ),
    ),
  );

  fetchUserRecipesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(recipeActions.fetchUserRecipesFailure),
        tap(() =>
          this.toastr.error(
            $localize`Failed to fetch user recipes`,
            $localize`Recipes fetching error`,
          ),
        ),
      ),
    { dispatch: false },
  );

  likeRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.likeRecipe),
      exhaustMap(({ id, url }) =>
        this.recipeService.likeRecipe(id).pipe(
          map(() => recipeActions.likeRecipeSuccess()),
          catchError(() => of(recipeActions.likeRecipeFailure({ url }))),
        ),
      ),
    ),
  );

  likeRecipeFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.likeRecipeFailure),
      tap(() => this.toastr.error($localize`Failed like recipe`, $localize`Recipe like error`)),
      map(({ url }) => recipeActions.fetchRecipeDetailsSilent({ url })),
    ),
  );

  dislikeRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.dislikeRecipe),
      mergeMap(({ id, url }) =>
        this.recipeService.dislikeRecipe(id).pipe(
          map(() => recipeActions.dislikeRecipeSuccess()),
          catchError(() => of(recipeActions.dislikeRecipeFailure({ url }))),
        ),
      ),
    ),
  );

  dislikeRecipeFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recipeActions.dislikeRecipeFailure),
      tap(() =>
        this.toastr.error($localize`Failed to dislike recipe`, $localize`Recipe dislike error`),
      ),
      map(({ url }) => recipeActions.fetchRecipeDetailsSilent({ url })),
    ),
  );

  constructor(
    private actions$: Actions,
    private recipeService: RecipeService,
    private productService: ProductService,
    private toastr: ToastrService,
  ) {}
}
