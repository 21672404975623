import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FindConsultantByZipCodeRequest } from '@core/dto/start-now-app.dto';
import { AppState } from '@core/store';
import { selectConsultantStepsSkipped } from '@core/store/start-now-app';
import {
  findConsultantByZipCode,
  updateConsultantStepsSkipped,
} from '@core/store/start-now-app/start-now-app.actions';
import { Store } from '@ngrx/store';
import { NavigationStep } from '@shared/components/stepper-base/navigation-step.model';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Subscription } from 'rxjs';
import { StartNowAppStep } from '../../enums/start-now-app-step.enum';

// TODO: StartNowApp - take care of navigationStep item id and orderNumber handling - the id is used as order number
@Component({
  selector: 'app-start-now-app-consultant-step-wrapper',
  templateUrl: './start-now-app-consultant-step-wrapper.component.html',
  styleUrls: ['./start-now-app-consultant-step-wrapper.component.scss'],
})
export class StartNowAppConsultantStepWrapperComponent implements OnInit, OnChanges, OnDestroy {
  @Input() initialStep: number;
  @Input() steps: NavigationStep[];
  @Output() consultantPhasePassed: EventEmitter<void> = new EventEmitter();
  @Output() stepChanged: EventEmitter<number> = new EventEmitter();
  @Output() navigateToStep: EventEmitter<number> = new EventEmitter<number>();

  public StartNowAppStep = StartNowAppStep;

  public doIKnowAConsultant: boolean;
  public activeStepId: number = 1;
  private minStep: number;
  private maxStep: number;
  private subscriptions: Subscription = new Subscription();

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.store$.select(selectConsultantStepsSkipped).subscribe((consultantStepsSkipped) => {
        this.doIKnowAConsultant = !consultantStepsSkipped;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initialStep?.currentValue) {
      this.activeStepId = this.initialStep ? this.initialStep : this.activeStepId;
    }
    if (changes.steps?.currentValue) {
      this.minStep = this.steps[0].id;
      this.maxStep = this.steps[this.steps.length - 1].id;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onKnowConsultantChoice(doIKnow: boolean): void {
    this.doIKnowAConsultant = doIKnow;
    if (this.doIKnowAConsultant) {
      this.store$.dispatch(updateConsultantStepsSkipped({ consultantStepsSkipped: false }));
      this.increaseStep();
    } else {
      this.store$.dispatch(updateConsultantStepsSkipped({ consultantStepsSkipped: true }));
      if (isMexEnv) {
        this.findDefaultMexConsultant();
        this.consultantPhasePassed.emit();
        this.navigateToStep.emit(StartNowAppStep.AboutYou);
      } else {
        this.increaseStep();
      }
    }
  }

  goToNextStep(): void {
    if (this.steps) {
      if (this.activeStepId < this.maxStep) {
        this.increaseStep();
      } else {
        this.consultantPhasePassed.emit();
        this.stepChanged.emit(1);
      }
    }
  }

  goToPreviousStep(): void {
    if (this.steps) {
      if (this.activeStepId > 0) {
        this.decreaseStep();
      }
    }
  }

  isGoToPreviousDisabled(): boolean {
    return this.activeStepId == this.minStep;
  }

  goToOtherStep(step: number): void {
    this.consultantPhasePassed.emit();
    this.navigateToStep.emit(step);
  }

  private increaseStep(): void {
    if (this.activeStepId < this.maxStep) {
      this.activeStepId++;
      this.stepChanged.emit(1);
    }
  }

  private decreaseStep(): void {
    if (this.activeStepId > this.minStep) {
      this.activeStepId--;
      this.stepChanged.emit(-1);
    }
  }

  private findDefaultMexConsultant() {
    const dummyZipCodeForDefaultMexConsultant = '00000';
    const findConsultantByZipCodeRequest: FindConsultantByZipCodeRequest = {
      zipCode: dummyZipCodeForDefaultMexConsultant,
      pageNumber: 1,
      pageSize: 1,
    };
    this.store$.dispatch(findConsultantByZipCode({ request: findConsultantByZipCodeRequest }));
  }
}
