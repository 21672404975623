<ng-container>
  <a [routerLink]="catalogType + '/' + catalog.urlName">
    <img *ngIf="loading" class="img-fluid" [src]="placeholder" loading="lazy" />
    <img
      class="img-fluid"
      [ngClass]="{ 'd-none': loading }"
      [altText]="catalog.name + ' ' + altSuffix[catalogType]"
      phImage
      [images]="catalog.images"
      [thumbnailDesktop]="thumbnail.categoryListDesktop"
      [thumbnailMobile]="thumbnail.categoryListMobile"
      (load)="onLoad()"
    />
  </a>
  <div class="row">
    <div class="col-12 text-center mb-3 mt-2">
      <a class="catalog-name text-uppercase" [routerLink]="catalogType + '/' + catalog.urlName">{{
        catalog.name
      }}</a>
    </div>
  </div>
</ng-container>
