<div class="container pl-md-0">
  <div class="dropdown-divider w-100 d-md-none"></div>
  <div class="d-flex d-md-none">
    <a routerLink=".." class="text-secondary" i18n>Payment Methods ></a>
    <div class="font-weight-bold ml-1">{{ newPaymentMethodTitle }}</div>
  </div>
  <div class="dropdown-divider w-100 d-md-none"></div>
  <a routerLink=".." type="button" class="d-none d-md-block">
    <i class="bi bi-arrow-left"></i>
    <span i18n> My Payment Methods</span>
  </a>
  <div class="d-flex justify-content-between align-items-center">
    <app-account-header class="d-md-none" title="{{ newPaymentMethodTitle }}"></app-account-header>
    <app-account-header
      class="d-none d-md-block"
      title="{{ newPaymentMethodTitle }}"
    ></app-account-header>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div>
        <div *ngIf="isCardPaymentError" class="mb-5">
          <app-create-card-payment-method-error
            [cardTypeDisplayValue]="cardPaymentType"
            [cardPaymentErrorType]="cardPaymentErrorType"
            [cardInfo]="cardPaymentMethodCardInfo"
            (resetPayment)="resetCardPayment()"
          ></app-create-card-payment-method-error>
        </div>
        <div
          *ngIf="(saveInProgress$ | async) === false; else spinner"
          [ngClass]="{
            'card-payment-wrapper-hide': isCardPaymentError
          }"
        >
          <app-card-payment-wrapper
            [paymentHandler]="paymentHandler"
            [createOnly]="true"
            [paymentProviderType]="paymentProviderType"
            (isPaymentMethodRequestable)="handlePaymentRequestable($event)"
            (isPaymentProviderLoading)="handlePaymentProviderLoading($event)"
            (cardAttempt)="handleCardAttempt($event)"
            #cardPaymentWrapper
          ></app-card-payment-wrapper>
        </div>

        <div *ngIf="isSubmitted && !isPaymentMethodRequestable" class="validation-error-message">
          <span i18n>Please fill in the payment related fields with valid values!</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="my-2 col-12 col-lg-6 d-flex">
      <button i18n class="w-100 btn btn-lg btn-uppercase-lg btn-outline-dark m-1" routerLink="..">
        Cancel
      </button>
      <button
        type="submit"
        i18n
        class="w-100 btn btn-uppercase-lg btn-lg btn-primary m-1"
        [disabled]="!isPaymentMethodRequestable || (saveInProgress$ | async)"
        (click)="onSave()"
      >
        Save changes
      </button>
    </div>
  </div>
</div>

<ng-template #spinner>
  <div class="d-flex justify-content-center">
    <app-ph-spinner class="mt-5" width="100px"> </app-ph-spinner>
  </div>
</ng-template>
