import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressType } from '@core/enums/address-type.enum';
import { AddressValidationErrors } from '@core/enums/address-validation-errors.enum';
import { CreateCardPaymentErrorType } from '@core/enums/create-card-payment-error-type.enum';
import { SnaCreateVoucherPaymentErrorType } from '@core/enums/create-voucher-error-type.enum';
import { PaymentHandlerType } from '@core/enums/payment-handler-type.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PaymentCardType, PaymentType } from '@core/enums/payment-type.enum';
import { CardholderName } from '@core/models/cardholder-name.model';
import { LogCardAttemptData } from '@core/models/log-card-attempt-data.model';
import { AppInitService } from '@core/services/app-init.service';
import { LoggerService } from '@core/services/logger.service';
import { AppState } from '@core/store';
import { CreatedVoucherPaymentMethod } from '@core/store/payment/payment-state-models';
import {
  selectAddressValidationModalData,
  selectAddresses,
  selectCanAddressOverride,
  selectCanChangeSaveCard,
  selectCardPaymentInfo,
  selectCreateVoucherErrorTypes,
  selectCreatedCardPaymentMethodResponse,
  selectCreatedVoucherPaymentMethodResponse,
  selectOrderSummary,
  selectSavedAccount,
  selectSavedCard,
  selectSelectedStarterKit,
  selectSnaCountryStates,
  selectSnaPaymentInfo,
  selectStartNowAppData,
  selectStepProcessing,
  selectVoucherPaymentInfo,
} from '@core/store/start-now-app';
import {
  Address,
  AddressVerification,
  CreatedSnaPaymentMethod,
} from '@core/store/start-now-app/start-now-app-state-models';
import {
  addAddress,
  fetchCountryStates,
  fetchOrderSummary,
  resetAdditionalBillingInformation,
  resetAddressValidationModal,
  resetAddressValidationStep,
  resetVoucherInfo,
  stepProcessing,
  storeCardPaymentInfo,
  storePaymentType,
  storeVoucherPaymentInfo,
  updateAdditionalBillingInformation,
  updateAddressValidationModalValidity,
  updatePaymentProviderType,
  validateAddress,
} from '@core/store/start-now-app/start-now-app.actions';
import { environment } from '@env';
import { Store, select } from '@ngrx/store';
import { CardPaymentWrapperComponent } from '@payment/components/card-payment-wrapper/card-payment-wrapper.component';
import { PaymentHandlerBase } from '@payment/payment-handler/payment-handler-base.model';
import { PaymentHandlerFactory } from '@payment/payment-handler/payment-handler-factory';
import { getPaymentProviderType } from '@payment/payment-handler/payment-provider.utils';
import { TextFieldInputComponent } from '@shared/components/text-field-input/text-field-input.component';
import { countryStatesToSelectOptions } from '@shared/utils/address-utils';
import { StatusCodes } from 'http-status-codes';
import { Observable, combineLatest, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, mergeMap, startWith, take, tap } from 'rxjs/operators';
import { SavedCardInfo } from '../../../../payment/components/saved-card/saved-card-info.model';
import { SnaConsultantSubmitComponent } from '../../sna-consultant-submit/sna-consultant-submit.component';
import { PaymentStepBaseComponent } from './payment-step-base.component';
import { PaymentTypeChangedModalResult } from './payment-type-changed-modal/payment-type-changed-modal-result.model';
import { PaymentTypeChangedModalComponent } from './payment-type-changed-modal/payment-type-changed-modal.component';

@Component({
  selector: 'app-payment-step',
  templateUrl: './payment-step.component.html',
  styleUrls: ['./payment-step.component.scss'],
})
export class PaymentStepComponent
  extends PaymentStepBaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  isPaymentProviderFirstLoading: boolean = true;
  cardholderName: CardholderName;
  public paymentHandler: PaymentHandlerBase;

  @ViewChild('cardPaymentWrapper')
  private paymentWrapperComponent: CardPaymentWrapperComponent;

  @ViewChild('paymentTypeChangedModal')
  private paymentTypeChangedModal: PaymentTypeChangedModalComponent;

  @ViewChild('snaConsultantSubmit')
  private snaConsultantSubmitComponent: SnaConsultantSubmitComponent;

  @ViewChild('firstNameInput') private firstNameInput: TextFieldInputComponent;
  @ViewChild('lastNameInput') private lastNameInput: TextFieldInputComponent;

  constructor(
    private store$: Store<AppState>,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private paymentHandlerFactory: PaymentHandlerFactory,
    private logger: LoggerService,
    injector: Injector,
    appInitService: AppInitService,
  ) {
    super(injector, 'SNA Step - 5 Payment');
    this.isPayPalCardPaymentEnabled = appInitService.Settings.sna.isPayPalCardPaymentEnabled;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.store$.dispatch(stepProcessing({ stepProcessing: false }));
    }, 500);

    this.listenCreditCardBillingAddressValidity();
  }

  ngOnInit(): void {
    this.store$.dispatch(fetchCountryStates());
    this.canAddressOverride$ = this.store$.select(selectCanAddressOverride);
    this.countryStates$ = countryStatesToSelectOptions(this.store$.select(selectSnaCountryStates));
    this.isStepProcessing$ = this.store$.select(selectStepProcessing);
    this.paymentHandler = this.paymentHandlerFactory.getPaymentHandler(
      PaymentHandlerType.StartNowApp,
    );
    this.createVoucherErrorTypes$ = this.store$.select(selectCreateVoucherErrorTypes);
    this.paymentFormGroup = this.createFormGroup();

    this.fetchAndSelectOrderSummary();
    this.initAddressValidation();
    this.listenPaymentType();
    this.listenSavedCard();
    this.listenSavedVoucher();
    this.listenCardTypeValueChanges();
    this.listenForPaymentReadySubmit();
    this.listenValidationResult();
    this.listenAddresses();
    this.subscribeIsMailingAddressValueChanges();
    this.prepopulateStarterKitPrice();
    this.prepopulateAdditionalBillingInformation();
    this.initPaymentProvider();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store$.dispatch(resetAddressValidationModal());
    this.store$.dispatch(resetAddressValidationStep());
  }

  submitStep(): void {
    let isValid: boolean = false;
    this.isSubmitted = true;
    this.paymentFormGroup.markAllAsTouched();
    this.store$.dispatch(resetAddressValidationModal());

    if (
      this.selectedPaymentType == PaymentType.CreditOrDebitCard ||
      this.selectedPaymentType == PaymentType.PayPal
    ) {
      isValid = this.isCardSaved || (this.isPaymentMethodRequestable && !this.isCardPaymentError);
    } else if (this.selectedPaymentType == PaymentType.PayByCash) {
      const isVoucherEmailValid = this.paymentFormGroup.get(this.FormGroup.PaymentVoucher).valid;
      isValid = this.isVoucherSaved || (isVoucherEmailValid && !this.isVoucherPaymentError);
    }

    isValid = isValid && this.getBillingInformationValidity();
    if (isValid) {
      this.store$.dispatch(stepProcessing({ stepProcessing: true }));
      const address: Address = this.paymentFormGroup.get(this.FormGroup.PaymentAddress).value;

      if (this.selectedPaymentType === PaymentType.CreditOrDebitCard) {
        this.store$.dispatch(
          validateAddress({
            address: { ...address, addressType: AddressType.Billing, country: environment.country },
          }),
        );
      } else {
        this.submitPayment();
      }

      this.listenAddressChangeAndResetFlags();
    }
  }

  handlePaymentRequestable(isPaymentMethodRequestable: boolean): void {
    this.isPaymentMethodRequestable = isPaymentMethodRequestable;
    // https://github.com/webcomponents/polyfills/issues/238
    this.cdRef.detectChanges();
  }

  isCardFormValid(): boolean {
    return this.isSubmitted ? this.isPaymentMethodRequestable || this.isCardSaved : true;
  }

  toggleShowMorePaymentImage(): void {
    this.showMorePaymentImage = !this.showMorePaymentImage;
  }

  resetCardPayment(): void {
    this.initSavedCard();
    this.setCardError(CreateCardPaymentErrorType.None);
    this.isPaymentApproved = false;
    if (this.isMexEnv) {
      switch (this.paymentWrapperComponent?.paymentProviderType) {
        case PaymentProviderType.PayPal:
          // As we have already a card payment and we would like to change it we have to reset it
          this.paymentHandler.updateOrder();
          this.resetPayPalComponent();
          break;
        case PaymentProviderType.Nexio:
          this.paymentWrapperComponent?.resetToken(
            this.cardholderName?.firstName,
            this.cardholderName?.lastName,
          );
          break;
      }
    } else if (this.isUsaEnv) {
      this.paymentWrapperComponent?.resetToken(
        this.cardholderName.firstName,
        this.cardholderName.lastName,
      );
    }
  }

  resetVoucherPayment(): void {
    this.initSavedVoucher(false);
    this.setVoucherError(SnaCreateVoucherPaymentErrorType.None);

    // reset stored emails
    this.paymentFormGroup
      .get(this.FormGroup.PaymentVoucher)
      .get(this.FormControl.Email)
      .setValue('');
    this.voucherEmail = '';

    this.store$.dispatch(resetVoucherInfo());
    this.listenSavedVoucher();
  }

  onChangePaymentType(newValue: PaymentType) {
    const hasSavedPayment = this.isCardSaved || this.isVoucherSaved || this.isPaymentApproved;
    if (hasSavedPayment) {
      this.paymentTypeChangedModal.open(this.selectedPaymentType, newValue);
    } else if (!hasSavedPayment && this.selectedPaymentType === PaymentType.CreditOrDebitCard) {
      this.resetAdditionalBillingInformation();
    }
    if (
      this.selectedPaymentType === PaymentType.PayPal &&
      !this.isCardSaved &&
      !this.isPaymentApproved
    ) {
      this.paymentHandler.updateOrder();
    }
    this.setAddressFormAvailability(newValue);
    this.selectedPaymentType = newValue;
  }

  onPaymentTypeModalSelection(result: PaymentTypeChangedModalResult) {
    if (result.isPaymentTypeChangeAccepted) {
      if (
        result.previousPaymentType === PaymentType.CreditOrDebitCard ||
        result.previousPaymentType === PaymentType.PayPal
      ) {
        this.selectedPaymentType = result.currentPaymentType;
        this.resetCardPayment();
        // wait to be able to start the token reset and setting back the CreditOrDebitCard payment type
        setTimeout(() => (this.selectedPaymentType = result.currentPaymentType));
      } else if (result.previousPaymentType === PaymentType.PayByCash) {
        this.resetVoucherPayment();
      }
      if (result.previousPaymentType === PaymentType.CreditOrDebitCard) {
        this.resetAdditionalBillingInformation();
      }
    } else {
      this.setAddressFormAvailability(result.previousPaymentType);
      this.selectedPaymentType = result.previousPaymentType;
    }
  }

  onConsultantSubmitted(isSubmitted): void {
    if (isSubmitted) {
      this.goToNextStep.emit();
    } else {
      this.logger.error('Consultant submission failed');
    }
  }

  dismissAddressValidationModal = (): void => {
    this.store$.dispatch(resetAddressValidationModal());
  };

  finalizeAddress = (validatedFormGroup: FormGroup, isCorrectedAddress: boolean): void => {
    this.canAddressOverride$.pipe(take(1)).subscribe((canOverride) => {
      const address: Address = {
        ...validatedFormGroup.value,
        addressType: AddressType.Billing,
        country: environment.country,
      };
      this.prepopulateAddressForm(address);
      if (canOverride || isCorrectedAddress) {
        this.store$.dispatch(updateAddressValidationModalValidity({ isSuccess: true }));
      } else {
        this.store$.dispatch(validateAddress({ address }));
      }
    });
  };

  paymentApproved(isCreated: boolean): void {
    this.isPaymentApproved = isCreated;
    this.cdRef.detectChanges();
  }

  handleCardAttempt(cardAttemptData: LogCardAttemptData): void {
    this.paymentHandler.logCardAttempt(cardAttemptData.success, cardAttemptData.response);
  }

  handleIsPaymentProviderLoading(isLoading: boolean): void {
    if (this.isPaymentProviderFirstLoading && !isLoading) {
      this.isPaymentProviderFirstLoading = false;
    }
    this.isPaymentProviderLoading = isLoading;
    this.cdRef.detectChanges();
  }

  get disablePaymentInputs(): boolean {
    if (this.isUsaEnv || this.selectedPaymentType === PaymentType.CreditOrDebitCard) {
      return (
        this.paymentFormGroup.get(this.FormGroup.PaymentAddress).invalid ||
        this.paymentFormGroup.get(this.FormGroup.AdditionalBillingInformation).invalid
      );
    }
    return false;
  }

  protected createFormGroup(): FormGroup {
    const addressFromGroup = this.fb.group(this.paymentAddress);
    if (this.isMexEnv) {
      const addressLine3Control = new FormControl('', [Validators.required]);
      addressFromGroup.addControl(this.FormControl.AddressLine3, addressLine3Control);
    }

    return new FormGroup({
      [this.FormGroup.PaymentCard]: this.fb.group(this.paymentCard),
      [this.FormGroup.PaymentPrice]: this.fb.group(this.paymentPrice),
      [this.FormGroup.PaymentVoucher]: this.fb.group(this.paymentVoucher),
      [this.FormGroup.PaymentAddress]: addressFromGroup,
      [this.FormGroup.AdditionalBillingInformation]: this.fb.group(
        this.additionalBillingInformation,
      ),
    });
  }

  private setAddressFormAvailability(paymentType: PaymentType) {
    if (paymentType === PaymentType.CreditOrDebitCard) {
      this.paymentFormGroup.get(this.FormGroup.PaymentAddress).enable({ emitEvent: false });
      this.paymentFormGroup
        .get(this.FormGroup.AdditionalBillingInformation)
        .enable({ emitEvent: false });
    } else {
      this.paymentFormGroup.get(this.FormGroup.PaymentAddress).disable({ emitEvent: false });
      this.paymentFormGroup
        .get(this.FormGroup.AdditionalBillingInformation)
        .disable({ emitEvent: false });
    }
  }

  private resetPayPalComponent(): void {
    // need to force change detection for paypal component reinit
    this.isCardSaved = true;
    this.cdRef.detectChanges();
    setTimeout(() => {
      this.isCardSaved = false;
      this.cdRef.detectChanges();
    });

    // wait to reload the hidden payment component by resetting the error
    setTimeout(
      () =>
        this.paymentWrapperComponent?.resetToken(
          this.cardholderName?.firstName,
          this.cardholderName.lastName,
        ),
    );
  }

  private initAddressValidation(): void {
    this.addressValidation$ = this.store$
      .select(selectAddressValidationModalData)
      .pipe(select((data) => data?.payload));
  }

  private initSavedCard(cardInfo?: SavedCardInfo) {
    this.cardPaymentMethodInfo = cardInfo ? cardInfo : null;
    this.isCardSaved = cardInfo && (cardInfo.card || cardInfo.account) ? true : false;
    if (this.isCardSaved) {
      this.isPaymentProviderLoading = false;
      this.isPaymentProviderFirstLoading = false;
    }
  }

  private initSavedVoucher(isSaved: boolean): void {
    this.isVoucherSaved = isSaved;
  }

  private setCardError(errorType: CreateCardPaymentErrorType) {
    this.cardPaymentErrorType = errorType;
    this.isCardPaymentError = errorType ? errorType !== CreateCardPaymentErrorType.None : false;
  }

  private setVoucherError(errorType: SnaCreateVoucherPaymentErrorType) {
    this.voucherPaymentErrorType = errorType;
    this.isVoucherPaymentError = errorType !== SnaCreateVoucherPaymentErrorType.None;
  }

  private setHttpErrorOnPayment(status: StatusCodes) {
    this.isHttpErrorOnPayment = !this.isCardPaymentError && status !== StatusCodes.OK;
    this.statusCode = status;
  }

  private getVoucherErrorType(response: CreatedVoucherPaymentMethod) {
    /* TODO: request from the BE to send errorType, because based on errorCode on FE can't find
      Currently we send back an Other error type */
    if (response?.error?.errors || (response?.status && response?.status !== StatusCodes.OK)) {
      this.setHttpErrorOnPayment(response.status);
      return SnaCreateVoucherPaymentErrorType.Other;
    }
    return SnaCreateVoucherPaymentErrorType.None;
  }

  private listenAddressChangeAndResetFlags() {
    this.subscriptions.add(
      this.paymentFormGroup
        .get(this.FormGroup.PaymentAddress)
        .valueChanges.pipe(take(1))
        .subscribe(() => {
          this.store$.dispatch(resetAddressValidationModal());
          this.isForbiddenState = false;
          this.addressValidationResultErrorCode = '';
        }),
    );
  }

  private listenPaymentType() {
    this.store$
      .select(selectSnaPaymentInfo)
      .pipe(take(1))
      .subscribe((paymentInfo) => {
        this.selectedPaymentType = paymentInfo.paymentType;
        if (this.selectedPaymentType === PaymentType.PayByCash) {
          this.isPaymentProviderLoading = false;
        }
        this.setAddressFormAvailability(this.selectedPaymentType);
      });
  }

  private listenSavedCard() {
    combineLatest([
      this.store$.select(selectSavedCard),
      this.store$.select(selectSavedAccount),
      this.store$.select(selectCardPaymentInfo),
    ])
      .pipe(
        filter(
          ([card, account, cardPaymentInfo]) =>
            (card !== null || account !== null) &&
            cardPaymentInfo !== null &&
            !!cardPaymentInfo.cardType,
        ),
        take(1),
      )
      .subscribe(([card, account, cardPaymentInfo]) => {
        this.initSavedCard({ card, account });
        this.selectedCardPaymentType = cardPaymentInfo.cardType;
      });

    this.canChangeSavedCard$ = this.store$.select(selectCanChangeSaveCard);
  }

  private listenSavedVoucher() {
    combineLatest([
      this.store$.select(selectCreatedVoucherPaymentMethodResponse),
      this.store$.select(selectVoucherPaymentInfo),
    ])
      .pipe(
        filter(
          ([createdVoucherPaymentMethod, voucherPaymentInfo]) =>
            voucherPaymentInfo !== null &&
            createdVoucherPaymentMethod !== null &&
            !!voucherPaymentInfo.email &&
            !!voucherPaymentInfo.amount,
        ),
        take(1),
      )
      .subscribe(([createdVoucherPaymentMethod, voucherPaymentInfo]) => {
        this.voucherEmail = voucherPaymentInfo.email;
        const isVoucherCreated = voucherPaymentInfo.vouchers?.length > 0;
        this.initSavedVoucher(isVoucherCreated);
        this.setVoucherError(this.getVoucherErrorType(createdVoucherPaymentMethod));
      });
  }

  private listenCardTypeValueChanges() {
    this.subscriptions.add(
      this.paymentFormGroup
        .get(this.FormGroup.PaymentCard)
        .get(this.FormControl.CardType)
        .valueChanges.subscribe((value: string) => {
          this.selectedCardPaymentType = value as PaymentCardType;
        }),
    );
  }

  private listenForPaymentReadySubmit(): void {
    this.subscriptions.add(
      this.listenSuccessfulAddressValidation().subscribe(() =>
        this.submitPayment(this.getActualAddressFromFormGroup()),
      ),
    );
  }

  private submitPayment(address: Address = undefined): void {
    this.store$.dispatch(stepProcessing({ stepProcessing: true }));
    if (this.paymentFormGroup.get(this.FormGroup.AdditionalBillingInformation).enabled) {
      this.updateAdditionalBillingInformation();
    }
    if (address) {
      this.store$.dispatch(addAddress({ address }));
    }
    this.store$.dispatch(storePaymentType({ paymentType: this.selectedPaymentType }));

    if (
      this.selectedPaymentType == PaymentType.CreditOrDebitCard ||
      this.selectedPaymentType == PaymentType.PayPal
    ) {
      if (this.isCardSaved) {
        this.store$.dispatch(stepProcessing({ stepProcessing: false }));
        this.submitConsultant();
      } else {
        this.storeCardPaymentInfo();
        this.requestCardPayment();
      }
    } else if (this.selectedPaymentType == PaymentType.PayByCash) {
      if (this.isVoucherSaved) {
        this.store$.dispatch(stepProcessing({ stepProcessing: false }));
        this.submitConsultant();
      } else {
        this.storeVoucherPaymentInfo();
        this.requestVoucherPayment();
      }
    }
  }

  private submitConsultant() {
    this.snaConsultantSubmitComponent.submit();
  }

  private getBillingInformationValidity(): boolean {
    const addressForm = this.paymentFormGroup.get(this.FormGroup.PaymentAddress);
    const additionalBillingInformationForm = this.paymentFormGroup.get(
      this.FormGroup.AdditionalBillingInformation,
    );
    if (addressForm.enabled && additionalBillingInformationForm.enabled) {
      return addressForm.valid && additionalBillingInformationForm.valid;
    }
    return true;
  }

  private listenSuccessfulAddressValidation(): Observable<[AddressVerification, boolean]> {
    return combineLatest([
      this.addressValidation$,
      this.store$
        .select(selectAddressValidationModalData)
        .pipe(select((modalData) => modalData?.isValid)),
    ]).pipe(filter(([addressValidation, isValid]) => addressValidation?.isValid || isValid));
  }

  private listenAddresses() {
    this.store$
      .select(selectAddresses)
      .pipe(
        take(1),
        filter((addresses) => !!addresses.length),
      )
      .subscribe((addresses) => {
        const billingAddress = addresses.find(
          (address) => address.addressType === AddressType.Billing,
        );
        if (billingAddress) {
          this.prepopulateAddressForm(billingAddress);
        } else {
          const homeAddress = addresses.find((address) => address.addressType === AddressType.Home);
          if (homeAddress) {
            this.prepopulateAddressForm(homeAddress);
          }
        }
      });

    this.listenAddressChangeAndResetFlags();
  }

  private prepopulateAddressForm(address: Address): void {
    this.paymentFormGroup.get(this.FormGroup.PaymentAddress).patchValue(address);
    this.mailingAddress = address;
  }

  private prepopulateStarterKitPrice(): void {
    if (this.isMexEnv) {
      this.store$
        .select(selectSelectedStarterKit)
        .pipe(take(1))
        .subscribe((starterKit) =>
          this.paymentFormGroup
            .get(`${this.FormGroup.PaymentPrice}.${this.FormControl.Price}`)
            .patchValue(starterKit.price),
        );
    }
  }

  private prepopulateAdditionalBillingInformation(): void {
    this.store$
      .select(selectStartNowAppData)
      .pipe(
        select((data) => data?.startNowAppPaymentInfo?.additionalBillingInformation),
        take(1),
        filter((additionalBillingInformation) => !!additionalBillingInformation),
      )
      .subscribe((additionalBillingInformation) => {
        this.paymentFormGroup.get(this.FormGroup.AdditionalBillingInformation).patchValue({
          [this.FormControl.FirstName]: additionalBillingInformation.billingFirstName,
          [this.FormControl.LastName]: additionalBillingInformation.billingLastName,
        });
      });
  }

  private subscribeIsMailingAddressValueChanges() {
    this.subscriptions.add(
      this.paymentFormGroup
        .get(this.GetIsMailingAddress)
        .valueChanges.pipe(distinctUntilChanged())
        .subscribe((value) => {
          if (value) {
            this.paymentFormGroup
              .get(this.FormGroup.PaymentAddress)
              .patchValue(this.mailingAddress);
          } else {
            this.paymentFormGroup
              .get(this.FormGroup.PaymentAddress)
              .patchValue(this.defaultAddress);
          }
        }),
    );

    this.listenAddressChangeAndResetFlags();
  }

  /** Requests a card payment and handles the errors */
  private requestCardPayment() {
    this.subscriptions.add(
      this.requestCardPaymentMethod().subscribe(
        (response: CreatedSnaPaymentMethod) => {
          this.setCardError(response.errorType as CreateCardPaymentErrorType);
          if (response.status) {
            this.setHttpErrorOnPayment(response.status);
          }
          this.cardPaymentMethodInfo = { card: response.card, account: response.account };

          this.store$.dispatch(stepProcessing({ stepProcessing: false }));

          if (response.errorType == CreateCardPaymentErrorType.None) {
            this.submitConsultant();
          }
        },
        () => this.store$.dispatch(stepProcessing({ stepProcessing: false })),
      ),
    );
  }

  /** Requests a voucher payment*/
  private requestVoucherPayment() {
    this.paymentHandler.createVoucher();

    this.subscriptions.add(
      this.store$
        .select(selectCreatedVoucherPaymentMethodResponse)
        .pipe(
          filter((response) => !!response),
          take(1),
        )
        .subscribe(
          (response: CreatedVoucherPaymentMethod) => {
            if (response.vouchers?.length > 0) {
              this.store$.dispatch(stepProcessing({ stepProcessing: false }));
              this.submitConsultant();
            } else {
              this.setVoucherError(this.getVoucherErrorType(response));
            }
          },
          () => this.store$.dispatch(stepProcessing({ stepProcessing: false })),
        ),
    );
  }

  /** Requests a payment method object via dropin third party library and creates a payment method */
  private requestCardPaymentMethod(): Observable<CreatedSnaPaymentMethod> {
    const paymentMethodObject$ = this.paymentWrapperComponent.requestPaymentMethodObject().pipe(
      tap((isSuccessful) => {
        this.paymentWrapperComponent.createPaymentMethod(isSuccessful);
      }),
      take(1),
    );
    const listenCreateMethod$ = this.store$.select(selectCreatedCardPaymentMethodResponse).pipe(
      filter((response: CreatedSnaPaymentMethod) => !!response),
      take(1),
    );

    return paymentMethodObject$.pipe(mergeMap(() => listenCreateMethod$));
  }

  private storeVoucherPaymentInfo(): void {
    const email = this.paymentFormGroup
      .get(this.FormGroup.PaymentVoucher)
      .get(this.FormControl.Email).value;

    const price = this.paymentFormGroup
      .get(this.FormGroup.PaymentPrice)
      .get(this.FormControl.Price).value;

    this.store$.dispatch(
      storeVoucherPaymentInfo({
        payload: {
          amount: price,
          email: email,
          vouchers: [],
          vouchersReceived: null,
        },
      }),
    );
  }

  private storeCardPaymentInfo() {
    this.store$.dispatch(
      storeCardPaymentInfo({
        payload: {
          cardType: this.selectedCardPaymentType,
        },
      }),
    );
  }

  private getActualAddressFromFormGroup(): Address {
    const formAddress = this.paymentFormGroup.get(this.FormGroup.PaymentAddress).value;
    return {
      ...formAddress,
      addressType: AddressType.Billing,
      country: environment.country,
    };
  }

  private listenValidationResult() {
    this.subscriptions.add(
      this.store$
        .select(selectAddressValidationModalData)
        .pipe(
          select((data) => data?.validationResult?.errors),
          filter((errors) => !!errors),
        )
        .subscribe((errors) => {
          this.isForbiddenState = !!errors.find((error) => {
            if (error.errorCode === AddressValidationErrors.ForbiddenState) {
              this.addressValidationResultErrorCode = error.errorCode;
              return true;
            }
            return false;
          });
        }),
    );
  }

  private fetchAndSelectOrderSummary(): void {
    if (this.isUsaEnv) {
      this.store$.dispatch(fetchOrderSummary());
      this.orderSummary$ = this.store$.select(selectOrderSummary);
    }
  }

  private initPaymentProvider(): void {
    this.paymentProvider$ = this.store$
      .select(selectSnaPaymentInfo)
      .pipe(select((paymentInfo) => paymentInfo.provider));

    this.store$
      .select(selectSnaPaymentInfo)
      .pipe(take(1))
      .subscribe((paymentInfo) => {
        if (!paymentInfo.provider) {
          const provider = getPaymentProviderType(this.selectedPaymentType);
          this.store$.dispatch(updatePaymentProviderType({ provider }));
        }
      });
  }

  private updateAdditionalBillingInformation(): void {
    const additionalBillingInformation = this.paymentFormGroup.get(
      this.FormGroup.AdditionalBillingInformation,
    );
    this.store$.dispatch(
      updateAdditionalBillingInformation({
        additionalBillingInformation: {
          billingFirstName: additionalBillingInformation.get(this.FormControl.FirstName).value,
          billingLastName: additionalBillingInformation.get(this.FormControl.LastName).value,
        },
      }),
    );
  }

  private resetAdditionalBillingInformation(): void {
    this.store$.dispatch(resetAdditionalBillingInformation());
    this.paymentFormGroup.get(this.FormGroup.AdditionalBillingInformation).patchValue({
      [this.FormControl.FirstName]: '',
      [this.FormControl.LastName]: '',
    });
  }

  private listenCreditCardBillingAddressValidity(): void {
    const billingAddressForm = this.paymentFormGroup.get(this.FormGroup.PaymentAddress);
    const additionalBillingInfoForm = this.paymentFormGroup.get(
      this.FormGroup.AdditionalBillingInformation,
    );
    this.subscriptions.add(
      combineLatest([
        fromEvent(this.firstNameInput.input.nativeElement, 'blur').pipe(startWith({})),
        fromEvent(this.lastNameInput.input.nativeElement, 'blur').pipe(startWith({})),
        billingAddressForm.statusChanges.pipe(startWith({})),
      ])
        .pipe(
          filter(() => billingAddressForm.valid && additionalBillingInfoForm.valid),
          take(1),
        )
        .subscribe(() => {
          const formValue = additionalBillingInfoForm.value;
          this.cardholderName = {
            firstName: formValue.firstName,
            lastName: formValue.lastName,
          };
          if (this.selectedPaymentType === PaymentType.CreditOrDebitCard) {
            this.paymentWrapperComponent.resetToken(
              this.cardholderName?.firstName,
              this.cardholderName?.lastName,
            );
          }
        }),
    );
  }
}
