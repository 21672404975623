import { HttpErrorResponse } from '@angular/common/http';
import {
  CreatePaymentErrorTypeTranslations,
  CreatePaymentMissingAddressErrorMessage,
} from '@core/constants/create-payment-error-type-translations';
import { phErrorMessages } from '@core/constants/ph-error-messages';
import { PhErrorResponse } from '@core/dto/ph-error';
import { CreateCardPaymentErrorType } from '@core/enums/create-card-payment-error-type.enum';
import { PhExceptionErrorType } from '@core/enums/ph-exception-error-type.enum';
import { getPhError } from './error-utils';

export function getErrorMessage(error: HttpErrorResponse | PhErrorResponse): string {
  const phError = getPhError(error);

  if (phError && phError?.ErrorType && phError.ErrorType in phErrorMessages) {
    return phErrorMessages[phError.ErrorType];
  }

  return $localize`Unknown error`;
}

export function getCreatePaymentMethodFailureMessage(response: PhErrorResponse) {
  let description = null;

  if (response.ErrorType === PhExceptionErrorType.PaymentMethodCreationFailedException) {
    description = CreatePaymentErrorTypeTranslations.get(
      CreateCardPaymentErrorType[getErrorTypeFromPaymentMethodCreationFailedException(response)],
    );
  } else if (response.ErrorType === PhExceptionErrorType.CustomerDefaultAddressNotFoundException) {
    description = CreatePaymentMissingAddressErrorMessage;
  }

  return description;
}

export function getErrorTypeFromPaymentMethodCreationFailedException(response: PhErrorResponse) {
  if (response.ErrorType === PhExceptionErrorType.PaymentMethodCreationFailedException) {
    for (const errorType of Object.keys(CreateCardPaymentErrorType)) {
      if (response.ErrorMessage.includes(errorType)) {
        return errorType;
      }
    }
  }

  return null;
}
