import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PaymentImageSrc } from '@core/constants/payment.consts';

@Component({
  selector: 'app-voucher-instructions',
  templateUrl: './voucher-instructions.component.html',
  styleUrls: ['./voucher-instructions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoucherInstructionsComponent {
  readonly PaymentImageSrc = PaymentImageSrc;
  constructor() {}
}
