<ng-container *ngIf="consultant$ | async as consultant">
  <div class="container-xl root p-3 text-white">
    <div class="row">
      <div class="col-auto">
        <img
          [src]="consultant.profileImage?.url || imagePlaceholder"
          [altText]="consultant.profileImage?.altText"
        />
      </div>
      <div class="col d-flex d-md-block flex-column">
        <div class="row">
          <div class="col title">{{ consultant.displayName }}</div>
        </div>
        <div class="row subtitle flex-grow-1 d-flex">
          <div class="col font-weight-bold" i18n>
            Consultant in {{ consultant.city }}, {{ consultant.state }}
          </div>
          <div class="col-12 col-md-auto d-flex d-md-block align-items-end">
            <ng-container
              [ngTemplateOutlet]="contact"
              [ngTemplateOutletContext]="{ consultant: consultant }"
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #contact let-consultant="consultant">
  <span i18n class="font-italic mr-2">Let's get in touch!</span>
  <a
    *ngIf="consultant.phoneNumber"
    [href]="'tel: ' + consultant.phoneNumber"
    class="px-2"
    data-toggle="tooltip"
    i18n-title
    [title]="
      'Hi! I am your consultant ' +
      consultant.displayName +
      '. Do you have a question? ' +
      consultant.phoneNumber
    "
  >
    <i class="bi bi-telephone-fill text-white"></i>
  </a>
  <a *ngIf="consultant.email" [href]="'mailto:' + consultant.email" class="px-2">
    <i class="bi bi-envelope-fill text-white"></i>
  </a>
  <a *ngIf="consultant.facebookUrl" target="_blank" [href]="consultant.facebookUrl" class="px-2">
    <i class="bi bi-facebook text-white"></i>
  </a>
</ng-template>
