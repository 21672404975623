import { createAction, props } from '@ngrx/store';
import { ReturnDetails, ReturnHistoryItem, SubmitReturn } from './return-state-models';

export enum ReturnActionTypes {
  resetReturnState = '[Return] Reset return state',

  fetchReturnHistory = '[Return] Fetch return history',
  fetchReturnHistorySuccess = '[Return] Fetch return history success',
  fetchReturnHistoryFailure = '[Return] Fetch return history failure',

  fetchReturnDetails = '[Return] Fetch return details',
  fetchReturnDetailsSuccess = '[Return] Fetch return details success',
  fetchReturnDetailsFailure = '[Return] Fetch return details failure',

  submitReturn = '[Return] Submit return',
  submitReturnSuccess = '[Return] Submit return success',
  submitReturnFailure = '[Return] Submit return failure',
}

export const resetReturnState = createAction(ReturnActionTypes.resetReturnState);

export const fetchReturnHistory = createAction(ReturnActionTypes.fetchReturnHistory);
export const fetchReturnHistorySuccess = createAction(
  ReturnActionTypes.fetchReturnHistorySuccess,
  props<{ returnHistory: ReturnHistoryItem[] }>(),
);
export const fetchReturnHistoryFailure = createAction(ReturnActionTypes.fetchReturnHistoryFailure);

export const fetchReturnDetails = createAction(
  ReturnActionTypes.fetchReturnDetails,
  props<{ returnId: string }>(),
);
export const fetchReturnDetailsSuccess = createAction(
  ReturnActionTypes.fetchReturnDetailsSuccess,
  props<{ returnDetails: ReturnDetails }>(),
);
export const fetchReturnDetailsFailure = createAction(ReturnActionTypes.fetchReturnDetailsFailure);

export const submitReturn = createAction(
  ReturnActionTypes.submitReturn,
  props<{ payload: SubmitReturn }>(),
);
export const submitReturnSuccess = createAction(ReturnActionTypes.submitReturnSuccess);
export const submitReturnFailure = createAction(ReturnActionTypes.submitReturnFailure);
