<ng-container>
  <label
    for="{{ identifier }}"
    [ngClass]="{ 'font-weight-bold': !isValidInput || !isValidGroup, 'text-asterix': required }"
    [ngStyle]="labelStyle"
    >{{ label }}</label
  >
  <div class="input-group">
    <input
      #input
      id="{{ identifier }}"
      [type]="passwordFieldTextType ? 'text' : 'password'"
      name="{{ identifier }}"
      class="form-control"
      [ngClass]="{ 'error-border border-right-0': !isValidInput || !isValidGroup }"
      [placeholder]="placeholder"
      (keyup)="onKeyUp($event)"
      [value]="value"
      [maxLength]="maxLength"
    />
    <div class="input-group-append">
      <span
        class="input-group-text bg-transparent error-border border-left-0 border-right-0"
        *ngIf="!isValidInput || !isValidGroup"
      >
        <i class="bi bi-exclamation-triangle error-color"></i>
      </span>
    </div>
    <div class="input-group-append">
      <button
        type="button"
        i18n-aria-label
        aria-label="Show and hide password"
        class="input-group-text border-left-0 pointer"
        [ngClass]="{ 'error-border': !isValidInput || !isValidGroup }"
        (click)="togglePasswordFieldTextType()"
        ><i
          alt=""
          [ngClass]="{
            bi: true,
            'bi-eye-slash': !passwordFieldTextType,
            'bi-eye': passwordFieldTextType
          }"
        ></i
      ></button>
    </div>
  </div>
  <div *ngIf="!isValidInput" class="validation-error-message">
    <span>{{ validationErrorMessage }}</span>
  </div>
  <div *ngIf="!isValidInput && errorMessages" class="validation-error-message">
    <div *ngFor="let item of errorMessages">{{ item }}</div>
  </div>
  <div *ngIf="!isValidGroup" class="validation-error-message">
    <span>{{ noMatchingPasswordWarningText }}</span>
  </div>
</ng-container>
