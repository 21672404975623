<div class="container pl-md-0">
  <app-account-header
    class="d-none d-md-block"
    [title]="title"
    [rightText]="orderDate"
  ></app-account-header>

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeStep">
    <li [ngbNavItem]="0">
      <ng-template ngbNavContent>
        <app-select-order-step [(activeStep)]="activeStep"></app-select-order-step>
      </ng-template>
    </li>
    <li [ngbNavItem]="1">
      <ng-template ngbNavContent>
        <app-select-products-step
          [(activeStep)]="activeStep"
          [(orderInfo)]="orderInfo"
          [(orderLines)]="orderLines"
        ></app-select-products-step>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <ng-template ngbNavContent>
        <app-return-summary-step
          [(activeStep)]="activeStep"
          [orderInfo]="orderInfo"
          [orderLines]="orderLines"
        ></app-return-summary-step>
      </ng-template>
    </li>
  </ul>

  <app-return-stepper-navigator
    [activeStep]="activeStep"
    [steps]="steps"
  ></app-return-stepper-navigator>
  <app-account-header
    class="d-md-none"
    [title]="title"
    [rightText]="orderDate"
  ></app-account-header>

  <div [ngbNavOutlet]="nav" class="mt-4"></div>
</div>
