import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Step } from '@core/models/step.model';
import { NavigationStep } from '@shared/components/stepper-base/navigation-step.model';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss'],
})
export class NavigatorComponent implements OnInit, OnChanges {
  @Input() navSteps: NavigationStep[];
  @Input() activeId: number;
  @Input() disableAllInLastStep: boolean = true;
  @Input() canMoveForward: boolean = true;
  @Output() activeIdChange: EventEmitter<number> = new EventEmitter<number>();

  steps: Step[];
  lastStep: number;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeId'].previousValue) {
      const prevId = changes['activeId'].previousValue;
      const currId = changes['activeId'].currentValue;
      const currStep = this.steps.find((x) => x.id == currId);

      if (currId === this.steps.length && this.disableAllInLastStep) {
        this.steps.forEach((step) => {
          step.completed = true;
          step.active = false;
          step.disabled = true;
        });
        return;
      }

      if (prevId < currId) {
        currStep.disabled = false;

        for (let i = 0; i < this.steps.length; i++) {
          if (prevId <= i + 1 && i + 1 < currId) {
            this.steps[i].touched = false;
            this.steps[i].completed = true;
            this.steps[i].active = false;
          }
        }
      } else {
        for (let i = 0; i < this.steps.length; i++) {
          if (currId < i + 1 && i + 1 <= prevId) {
            this.steps[i].touched = this.canMoveForward;
            this.steps[i].completed = false;
            this.steps[i].active = false;
            this.steps[i].disabled = !this.canMoveForward;
          }
        }
        currStep.completed = false;
      }

      currStep.active = true;
    }
  }

  ngOnInit(): void {
    this.steps = this.navSteps
      .sort((a, b) => a.id - b.id)
      .map(
        (navStep, i) =>
          ({
            id: navStep.id,
            title: navStep.title,
            disabled: i > 0,
            touched: false,
            completed: false,
            active: i === 0,
          }) as Step,
      );
    this.lastStep = this.steps.length - 1;
  }

  changeStepId(selectedStepId: number) {
    this.activeIdChange.emit(selectedStepId);
  }
}
