import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DefaultConsultantImagePlaceholderSrc } from '@core/constants/image-placeholders';
import { FindConsultantRequest, LocateConsultantRequest } from '@core/dto/consultant.dto';
import { ConsultantFinderType } from '@core/enums/consultant-finder-type.enum';
import { AppState } from '@core/store';
import { selectConsultantFinder } from '@core/store/consultant';
import { Consultant, ConsultantFinder } from '@core/store/consultant/consultant-state-models';
import {
  fetchCurrentConsultantByVanityName,
  findConsultant,
  locateConsultant,
} from '@core/store/consultant/consultant.actions';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-consultant-finder-result',
  templateUrl: './consultant-finder-result.component.html',
  styleUrls: ['./consultant-finder-result.component.scss'],
})
export class ConsultantFinderResultComponent implements OnInit, AfterViewChecked {
  @Input() filter: FindConsultantRequest | LocateConsultantRequest;
  @Input() finderType: ConsultantFinderType;
  @Input() loadMoreButtonIsVisible: boolean = true;
  @Output() consultantSelected: EventEmitter<void> = new EventEmitter<void>();

  readonly consultantFinderType = ConsultantFinderType;
  readonly imagePlaceholder = DefaultConsultantImagePlaceholderSrc;
  readonly ButtonText = {
    [ConsultantFinderType.find]: $localize`Load more`,
    [ConsultantFinderType.locate]: $localize`Search again`,
  };

  consultantFinder$: Observable<ConsultantFinder>;
  loading$: Observable<boolean>;
  consultants$: Observable<Consultant[]>;

  @ViewChild('listWrapper') private listWrapper: ElementRef;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.consultantFinder$ = this.store$.select(selectConsultantFinder);
    this.loading$ = this.consultantFinder$.pipe(select((s) => s.loading));
    this.consultants$ = this.consultantFinder$.pipe(select((s) => s.results));
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom();
  }

  selectConsultant(consultant: Consultant) {
    this.store$.dispatch(
      fetchCurrentConsultantByVanityName({
        vanityName: consultant.vanityName,
        filterCountry: true,
      }),
    );
    this.consultantSelected.emit();
  }

  loadMore() {
    if (this.finderType === ConsultantFinderType.find) {
      this.store$.dispatch(findConsultant({ payload: this.filter as FindConsultantRequest }));
    } else if (this.finderType === ConsultantFinderType.locate) {
      this.store$.dispatch(
        locateConsultant({
          payload: {
            ...this.filter,
          } as LocateConsultantRequest,
        }),
      );
    }
  }

  private scrollToBottom(): void {
    if (this.listWrapper?.nativeElement) {
      this.listWrapper.nativeElement.scroll({
        top: this.listWrapper.nativeElement.scrollHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
