export function toPretty(str: string) {
  if (str) {
    return str.replaceAll('-', ' ');
  }

  return '';
}

export function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(' ');
}
