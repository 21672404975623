import { Injectable } from '@angular/core';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { Store } from '@ngrx/store';
import {
  EcPaymentHandlerType,
  PaymentHandlerType,
} from '../../../core/enums/payment-handler-type.enum';
import { ECommercePaymentHandler } from './impl/e-commerce-payment-handler';
import { StartNowAppPaymentHandler } from './impl/start-now-app-payment-handler';
import { PaymentHandlerBase } from './payment-handler-base.model';

@Injectable({
  providedIn: 'root',
})
export class PaymentHandlerFactory {
  constructor(
    public store$: Store<AppState>,
    private appInitService: AppInitService,
  ) {}

  public getPaymentHandler(
    paymentHandlerType: PaymentHandlerType,
    ecPaymentHandlerType?: EcPaymentHandlerType,
  ): PaymentHandlerBase {
    switch (paymentHandlerType) {
      case PaymentHandlerType.ECommerce:
        return new ECommercePaymentHandler(this.store$, this.appInitService, ecPaymentHandlerType);
      case PaymentHandlerType.StartNowApp:
        return new StartNowAppPaymentHandler(this.store$, this.appInitService);
    }
  }
}
