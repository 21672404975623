<ng-template #modal>
  <div class="modal-body">
    <div class="font-size-bigger font-weight-bold text-center mb-3" *ngIf="title">{{ title }}</div>
    <ng-content></ng-content>
  </div>
  <div class="modal-footer d-flex justify-content-around">
    <button type="button" class="btn btn-sm btn-outline-primary text-uppercase" (click)="onNo()">
      {{ noLabel }}
    </button>
    <button
      type="button"
      class="btn btn-sm btn-primary text-uppercase"
      ngbAutofocus
      (click)="onYes()"
    >
      {{ yesLabel }}
    </button>
  </div>
</ng-template>
