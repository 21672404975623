<app-modal #modal [modalConfig]="modalConfig">
  <div *ngIf="updatedOrderItems?.length">
    <h4 class="mb-3" i18n>The following items were updated.</h4>
    <div class="row font-size-smaller text-uppercase font-weight-bold">
      <div class="col col-md-6">
        <p i18n>Product(s)</p>
      </div>
      <div class="col-md-3 d-none d-md-block">
        <p i18n>Previous Price</p>
      </div>
      <div class="col-md-3 d-none d-md-block">
        <p i18n>Actual Price</p>
      </div>
    </div>
    <hr class="mt-0" />
    <app-failed-order-item
      *ngFor="let item of updatedOrderItems"
      [failedOrderItem]="item"
    ></app-failed-order-item>
    <hr />
  </div>

  <div *ngIf="deletedOrderItems?.length">
    <h4 class="mb-2" i18n>The following items were deleted.</h4>
    <div class="row font-size-smaller text-uppercase font-weight-bold">
      <div class="col col-md-6">
        <p i18n>Product(s)</p>
      </div>
    </div>
    <hr class="mt-0" />
    <app-failed-order-item
      *ngFor="let item of deletedOrderItems"
      [failedOrderItem]="item"
      [isUpdatedItem]="false"
    ></app-failed-order-item>
    <hr />
  </div>

  <div class="d-flex justify-content-center mt-2">
    <button class="btn btn-primary text-uppercase w-25" (click)="close()" i18n>Ok</button>
  </div>
</app-modal>
