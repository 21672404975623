import { OrderType } from '@core/enums/order-type.enum';
import { PartyOrderErrorType } from '@core/enums/party-order-error-type.enum';
import { PhExceptionErrorType } from '@core/enums/ph-exception-error-type.enum';
import { OrderStatus } from '@core/enums/shipment-status-enum';
import { ImageDetails } from '@core/models/image.model';
import { Cart, OrderLine } from '@core/store/cart/cart-state-models';
import { ReceivedVoucher } from '../voucher/voucher-state-models';

export interface OrderState {
  loading: boolean;
  isSubmitDisabled: boolean;
  orderHistory: OrderHistoryItem[];
  ccOrderHistory: CCOrderHistoryItem[];
  orderDetails: OrderDetails;
  ccOrderDetails: CCOrderDetails;
  hasPaymentError: boolean;
  submittedOrder: SubmittedOrder;
  partyOrderPayment: PartyOrderPayment;
  shippedCartonDetails: ShippedCartonDetails;
}

export interface OrderHistoryItem {
  orderId: string;
  orderNumber: string;
  total: number;
  createdDate: Date;
  orderStatus: OrderStatus;
  orderType: OrderType;
  consultant: string;
  partyHost?: string;
}

export interface CCOrderHistoryItem {
  masterOrderId: number;
  poeOrderId: string;
  dateReceived: string;
  generatedEmail: string;
  ecommerceStatus: string;
  totalMerchCost: number;
}

export interface OrderDetails {
  orderId: string;
  orderNumber: string;
  createdDate: Date;
  orderStatus: OrderStatus;
  orderType: OrderType;
  isCouponApplied: boolean;
  promotionalDiscount: number;
  merchandiseTotal: number;
  deliveryFeeTotal: number;
  salesTaxTotal: number;
  totalPrice: number;
  orderLines: OrderLineDetails[];
  shippingAddress: OrderAddress;
  billingAddress: OrderAddress;
  personalInformation: OrderCustomer;
  consultantFirstName: string;
  consultantLastName: string;
  cardFirstFourDigits?: string;
  cardLastFourDigits?: string;
  partyHost?: string;
  cartons: OrderDetailsCarton[];
  vouchers: ReceivedVoucher[];
  backOrderItems?: OrderDetailsBackOrderItem[];
}

export interface OrderDetailsCarton {
  id: string;
  name: string;
  trackingNumber?: string;
  trackingUrl: string;
  carrier?: string;
  shippingDate: string;
  weight: number;
  cartonItems: OrderDetailsCartonItem[];
}

export interface OrderDetailsCartonItem {
  id: string;
  sku: string;
  name: string;
  qty: number;
  images: ImageDetails[];
}

export interface OrderDetailsBackOrderItem {
  skuCode: string;
  skuDescription: string;
  name: string;
  quantity: number;
}

export interface OrderCustomer {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface OrderAddress {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3?: string;
  phoneNumber?: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

export interface OrderLineDetails {
  orderLineId: string;
  collectionName: string;
  productName: string;
  sku: string;
  quantity: number;
  originalPrice: number;
  discountedPrice: number;
  total: number;
  images: ImageDetails[];
}

export interface CCOrderDetails {
  masterOrderId: number;
  dateReceived: Date;
  ecommerceStatus: string;
  shippingNameInfo: CustomerNameInfo;
  shippingAddressInfo: AddressInformation;
  products: ConsultantCornerOrderItem[];
  totalMerchandiseCost: number;
  shippingCost: number;
  tax: Tax;
}

export interface CustomerNameInfo {
  firstName: string;
  lastName: string;
}

export interface AddressInformation {
  city: string;
  stateProvince: string;
  zipPostalCode: string;
  address1: string;
  address2: string;
  address3: string;
}

export interface ConsultantCornerOrderItem {
  skuCode: string;
  productName: string;
  quantity: number;
  price: number;
  totalPrice: number;
}

export interface Tax {
  exemptCode: string;
  finalAmount: number;
  percentage: number;
}

export interface SubmittedOrder {
  status: SubmittedOrderStatus;
  submissionErrorType: PhExceptionErrorType;
  submittedOrderId: string;
  cart: Cart;
  updatedItems: FailedOrderItem[];
  deletedItems: FailedOrderItem[];
}

export interface FailedOrderItem extends OrderLine {
  actualPrice: number;
}

export enum SubmittedOrderStatus {
  initial,
  inProgress,
  success,
  failure,
}

export interface PartyOrderPayment {
  fetched: boolean;
  status: SubmittedOrderStatus;
  partyOrderData: PartyOrderData;
  partyOrderError: PartyOrderError;
  createPartyOrderPayPalOrder: CreatePartyOrderPayPalOrder;
}

export interface PartyOrderData {
  hostessName: string;
  orderNumber: number;
  receivedDate: string;
  totalOrderAmount: number;
  paymentReceived: number;
  balanceDue: number;
  consultantBeeNumber: number;
  consultantName: string;
}

export interface PartyOrderError {
  Message: PartyOrderErrorType;
}

export interface CreatePartyOrderPayPalOrder {
  masterOrderId: number;
  amount: number;
}

export interface ShippedCartonDetails {
  shippedCartonDetailsData: ShippedCartonDetailsData;
  shippedCartonError: string;
}

export interface ShippedCartonDetailsData {
  masterOrderId: number;
  shippedCartons: ShippedCarton[];
  backorderItems: ShippedCartonSku[];
  pickingSlipDocuments: PickingSlipDocument[];
}

export interface ShippedCarton {
  carrier: string;
  receiverName: string;
  trackingNumber: string;
  trackingUrl: string;
  cartonNumber: number;
  pdfAvailable?: boolean;
  pickSlipNumber?: number;
  shippingDate: Date;
  skus: ShippedCartonSku[];
  weight: number;
}

export interface ShippedCartonSku {
  skuCode: string;
  skuDescription: string;
  quantity: number;
}

export interface PickingSlipDocument {
  pickSlipNumber: number;
  pdfUrl: string;
}
