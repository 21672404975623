<ng-container>
  <label
    for="{{ identifier }}"
    *ngIf="label"
    [ngClass]="{ 'font-weight-bold': !isValidInput }"
    [ngStyle]="labelStyle"
  >
    {{ label }}
  </label>
  <div class="input-group">
    <select
      class="form-control"
      [disabled]="disabled || readonly"
      [ngClass]="{ 'error-border border-right-0': !isValidInput }"
      id="{{ identifier }}"
      name="{{ identifier }}"
      [value]="value"
      (change)="onSelectChange($event.target.value)"
    >
      <option value="" disabled [selected]="isValueEmpty()" i18n>{{ defaultValue }}</option>
      <option
        *ngFor="let option of options"
        [value]="option?.value || option"
        [selected]="isMatchingWithValue(option?.value || option)"
      >
        {{ option?.name || option }}
      </option>
    </select>
    <div class="input-group-append" *ngIf="!isValidInput">
      <span class="input-group-text bg-transparent error-border border-left-0">
        <i class="bi bi-exclamation-triangle error-color"></i>
      </span>
    </div>
  </div>
  <div *ngIf="!isValidInput && errorMessages" class="validation-error-message">
    <div *ngFor="let item of errorMessages">{{ item }}</div>
  </div>
</ng-container>
