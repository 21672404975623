import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentImageSrc } from '@core/constants/payment.consts';
import { PaymentType } from '@core/enums/payment-type.enum';
import { CheckoutInfoCards } from '@core/models/checkout.model';
import { InfoCardAttributes } from '@core/models/info-card-attributes.model';
import { OrderService } from '@core/services/order.service';
import { VoucherService } from '@core/services/voucher.service';
import { AppState } from '@core/store';
import { selectIsAuthenticated } from '@core/store/auth';
import { Cart } from '@core/store/cart/cart-state-models';
import {
  selectBillingInfo,
  selectIsRegisteredUserWithoutLogin,
  selectPaymentType,
  selectPersonalInfo,
} from '@core/store/checkout';
import { selectCurrentConsultant, selectParty } from '@core/store/consultant';
import { selectSubmittedOrder } from '@core/store/order';
import { selectPaymentInfo } from '@core/store/payment';
import { selectVouchers } from '@core/store/voucher';
import { ReceivedVoucher } from '@core/store/voucher/voucher-state-models';
import { concatLatestFrom } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  createBillingInfoCard,
  createPartyInfoCard,
  createPersonalInfoCard,
  createShippingInfoCard,
} from '@shared/utils/create-shipping-info-card-attributes';
import { getMaskedCardNumber } from '@shared/utils/get-masked-card-number-utils';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-checkout-order-confirmation',
  templateUrl: './checkout-order-confirmation.component.html',
  styleUrls: ['./checkout-order-confirmation.component.scss'],
})
export class CheckoutOrderConfirmationComponent implements OnInit, OnDestroy {
  submittedCart$: Observable<Cart>;
  cardNumber$: Observable<InfoCardAttributes>;
  isAuthenticated$: Observable<boolean>;
  isRegisteredUserWithoutLogin$: Observable<boolean>;
  paymentType$: Observable<PaymentType>;
  vouchers$: Observable<ReceivedVoucher[]>;
  orderNumber$: Observable<string>;
  isRegistrationSectionVisible: boolean;
  checkoutInfoCards$: Observable<CheckoutInfoCards>;

  readonly PaymentType = PaymentType;
  public readonly PaymentImageSrc = PaymentImageSrc;

  private subscription: Subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private voucherService: VoucherService,
    private orderService: OrderService,
  ) {}

  ngOnInit(): void {
    this.vouchers$ = this.store$.select(selectVouchers);
    this.paymentType$ = this.store$.select(selectPaymentType);
    this.isAuthenticated$ = this.store$.select(selectIsAuthenticated);
    this.isRegisteredUserWithoutLogin$ = this.store$.select(selectIsRegisteredUserWithoutLogin);
    this.submittedCart$ = this.store$.select(selectSubmittedOrder).pipe(
      select((x) => x.cart),
      distinctUntilChanged(),
      filter((x) => !!x),
    );
    this.cardNumber$ = this.store$.select(selectPaymentInfo).pipe(
      distinctUntilChanged(),
      filter((p) => !!p),
      map((p) => ({
        title: $localize`Payment information`,
        cardNumber: getMaskedCardNumber(p.firstFour, p.lastFour),
        readOnly: true,
      })),
    );

    this.initOrderNumber();
    this.checkRegistrationSectionVisibility();
    this.initCheckoutInfoCards();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initOrderNumber(): void {
    this.orderNumber$ = this.store$
      .select(selectSubmittedOrder)
      .pipe(map((submittedOrder) => submittedOrder.submittedOrderId));
  }

  download(voucherId: string) {
    this.voucherService.downloadVoucher(voucherId);
  }

  printOrderSummary(orderNumber: string): void {
    this.orderService.printSummary(orderNumber);
  }

  checkRegistrationSectionVisibility(): void {
    this.subscription.add(
      combineLatest([this.isAuthenticated$, this.isRegisteredUserWithoutLogin$]).subscribe(
        ([isAuthenticated, isRegisteredUserWithoutLogin]) => {
          if (isAuthenticated || isRegisteredUserWithoutLogin) {
            this.isRegistrationSectionVisible = false;
          } else {
            this.isRegistrationSectionVisible = true;
          }
        },
      ),
    );
  }

  private initCheckoutInfoCards(): void {
    this.checkoutInfoCards$ = this.store$.select(selectPersonalInfo).pipe(
      concatLatestFrom(() => [
        this.store$.select(selectBillingInfo),
        this.store$.select(selectParty),
        this.store$.select(selectCurrentConsultant),
        this.cardNumber$,
      ]),
      map(([personalInfo, billingInfo, party, currentConsultant, cardNumber]) => ({
        customerInfo: createPersonalInfoCard(personalInfo.billingCustomer, true),
        shippingInfo: createShippingInfoCard(personalInfo, true),
        billingInfo: createBillingInfoCard(billingInfo, true),
        partyInfo: createPartyInfoCard(currentConsultant.fullName, party),
        paymentInfo: { ...cardNumber, readOnly: true },
      })),
    );
  }
}
