import { Component, Input } from '@angular/core';
import { MaintenanceErrorMessage } from '@core/constants/sna-error-messages';
import { getGenericErrorMessage } from '@shared/utils/get-generic-error-message-utils';
import { StatusCodes } from 'http-status-codes';

@Component({
  selector: 'app-sna-http-status-errors',
  templateUrl: './sna-http-status-errors.component.html',
  styleUrls: ['./sna-http-status-errors.component.scss'],
})
export class SnaHttpStatusErrorsComponent {
  @Input() statusCode: StatusCodes;

  readonly MaintenanceErrorMessage = MaintenanceErrorMessage;
  readonly GenericErrorMessage = getGenericErrorMessage();
  readonly StatusCodes = StatusCodes;

  constructor() {}
}
