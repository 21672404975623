<h2 class="mt-3 text-title" i18n>It’s all about you.</h2>

<div class="my-3" *ngIf="isUsaEnv">
  <h6 class="text-subtitle font-italic" i18n>As it should be! Tell us about yourself.</h6>
</div>

<form [formGroup]="aboutYouFormGroup">
  <div class="row">
    <div class="col-12 col-md-4 mb-3">
      <app-text-field-input
        [identifier]="FirstName"
        label="First name"
        i18n-label
        [labelStyle]="FormLabelStyle"
        placeholder="Your first name"
        i18n-placeholder
        [formControlName]="FirstName"
      ></app-text-field-input>
    </div>
    <div class="col-12 col-md-4 mb-3">
      <app-text-field-input
        [identifier]="MiddleName"
        label="Middle name (optional)"
        i18n-label
        [labelStyle]="FormLabelStyle"
        placeholder="Your middle name"
        i18n-placeholder
        [formControlName]="MiddleName"
      ></app-text-field-input>
    </div>
    <div class="col-12 col-md-4 mb-3">
      <app-text-field-input
        [identifier]="LastName"
        label="Last name"
        i18n-label
        [labelStyle]="FormLabelStyle"
        placeholder="Your last name"
        i18n-placeholder
        [formControlName]="LastName"
      ></app-text-field-input>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h6 class="birthday-text my-3" i18n>Birthday – so our team can wish you a happy birthday!</h6>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-6 col-md-2 mb-3">
      <app-ph-select
        [identifier]="Month"
        i18n-label
        defaultValue="Select month"
        i18n-defaultValue
        [labelStyle]="FormLabelStyle"
        [options]="months"
        [formControlName]="Month"
      ></app-ph-select>
    </div>
    <div class="col-6 col-md-2 mb-3">
      <app-ph-select
        [identifier]="Day"
        i18n-label
        defaultValue="Select day"
        i18n-defaultValue
        [labelStyle]="FormLabelStyle"
        [options]="getDaysOfMonth()"
        [formControlName]="Day"
      ></app-ph-select>
    </div>
    <div class="col-6 col-md-2">
      <app-number-field-input
        [identifier]="Year"
        i18n-label
        [labelStyle]="FormLabelStyle"
        placeholder="Enter year of birth"
        i18n-placeholder
        [formControlName]="Year"
        [min]="BirthYearMin"
        [max]="BirthYearMax"
        [onlyPositiveNumbers]="true"
      >
      </app-number-field-input>
    </div>
  </div>

  <div class="mb-5" formGroupName="conditions">
    <div class="row mb-2">
      <div class="col">
        <div class="form-check custom-checkbox">
          <input
            class="form-check-input custom-control-input"
            type="checkbox"
            [formControlName]="OlderThan18"
            id="olderThan18"
          />
          <label
            i18n
            class="form-check-label text-uppercase custom-control-label"
            for="olderThan18"
          >
            I am 18 years of age or older and a legal resident of the u.s. or its territories
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="isMexEnv" class="row mb-2">
      <div class="col">
        <div class="form-check custom-checkbox">
          <input
            class="form-check-input custom-control-input"
            type="checkbox"
            [formControlName]="Policy"
            id="policy"
          />
          <label i18n class="form-check-label text-uppercase custom-control-label" for="policy">
            RFC policy text line with and external link to
            <a routerLink=".">the policy</a> goes here
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="!isValid()" class="validation-error-message">
      <span>{{ checkboxErrorMessage }}</span>
    </div>

    <div *ngIf="aboutYouStep$ | async as aboutYouStep">
      <div
        *ngIf="
          (!!aboutYouStep.statusCode && aboutYouStep.statusCode !== StatusCodes.OK) ||
          !!aboutYouStep.errors.length
        "
        class="validation-error-message"
      >
        <app-sna-http-status-errors
          [statusCode]="aboutYouStep.statusCode"
        ></app-sna-http-status-errors>
      </div>
    </div>
  </div>
</form>
