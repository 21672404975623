import { Injectable } from '@angular/core';
import { VoucherService } from '@core/services/voucher.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { selectVoucherRetryCounter } from '.';
import { AppState } from '..';
import * as voucherActions from './voucher.actions';

const RECEIVE_VOUCHER_RETRY_TIMEOUT = 100;
const RECEIVE_VOUCHER_RETRY_MAX_COUNT = 5;

@Injectable()
export class VoucherEffects {
  //#region Create Voucher
  createVoucher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voucherActions.createVoucher),
      mergeMap(({ email }) =>
        this.voucherService.createVoucher(email).pipe(
          map((res) =>
            voucherActions.createVoucherSuccess({
              vouchers: res.voucherDetails,
            }),
          ),
          catchError((error) => of(voucherActions.createVoucherFailure(error))),
        ),
      ),
    ),
  );

  createVoucherSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voucherActions.createVoucherSuccess),
      map(({ vouchers }) => voucherActions.generateVoucher({ vouchers })),
    ),
  );

  createVoucherFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(voucherActions.createVoucherFailure),
        tap(() =>
          this.toastr.error($localize`Failed to create voucher`, $localize`Voucher creation error`),
        ),
      ),
    { dispatch: false },
  );
  //#endregion

  //#region Generate Voucher
  generateVoucher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voucherActions.generateVoucher),
      mergeMap(({ vouchers }) =>
        this.voucherService.generateVoucher(vouchers).pipe(
          map((voucherTokens) => voucherActions.generateVoucherSuccess({ voucherTokens })),
          catchError(() => of(voucherActions.generateVoucherFailure())),
        ),
      ),
    ),
  );

  generateVoucherSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voucherActions.generateVoucherSuccess),
      map(({ voucherTokens }) => voucherActions.receiveVoucher({ voucherTokens })),
    ),
  );

  generateVoucherFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(voucherActions.generateVoucherFailure),
        tap(() =>
          this.toastr.error(
            $localize`Failed to generate voucher`,
            $localize`Voucher generation error`,
          ),
        ),
      ),
    { dispatch: false },
  );
  //#endregion

  //#region Receive Voucher
  receiveVoucher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voucherActions.receiveVoucher),
      mergeMap(({ voucherTokens: tokens }) =>
        this.voucherService.receiveVoucher({ tokens }).pipe(
          map(
            ({ vouchers }) => {
              const remainingVouchers = vouchers.filter((voucher) => !voucher.hasBarcodeUrl).length;

              if (remainingVouchers === 0) {
                return voucherActions.receiveVoucherSuccess({ vouchers });
              } else {
                return voucherActions.retryReceiveVoucher({ voucherTokens: tokens });
              }
            },
            catchError(() => of(voucherActions.receiveVoucherFailure())),
          ),
        ),
      ),
    ),
  );

  retryReceiveVoucher$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(voucherActions.retryReceiveVoucher),
        concatLatestFrom(() => this.store$.select(selectVoucherRetryCounter)),
        map(([{ voucherTokens }, retryCounter]) => {
          if (retryCounter < RECEIVE_VOUCHER_RETRY_MAX_COUNT) {
            setTimeout(() => {
              this.store$.dispatch(
                voucherActions.receiveVoucher({
                  voucherTokens,
                }),
              );
            }, RECEIVE_VOUCHER_RETRY_TIMEOUT);
          } else {
            this.store$.dispatch(voucherActions.receiveVoucherFailure());
          }
        }),
      ),
    { dispatch: false },
  );

  receiveVoucherFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(voucherActions.receiveVoucherFailure),
        tap(() =>
          this.toastr.error(
            $localize`Failed to receive vouchers.`,
            $localize`Receive voucher error`,
          ),
        ),
      ),
    { dispatch: false },
  );
  //#endregion

  //#regin Prevalidate Voucher
  prevalidateVoucher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voucherActions.prevalidateVoucher),
      switchMap(({ email }) =>
        this.voucherService.prevalidateVoucher(email).pipe(
          map(() => voucherActions.prevalidateVoucherSuccess()),
          catchError((error) => of(voucherActions.prevalidateVoucherFailure({ error }))),
        ),
      ),
    ),
  );

  prevalidateVoucherFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(voucherActions.prevalidateVoucherFailure),
        tap(() =>
          this.toastr.error(
            $localize`Failed to validate voucher`,
            $localize`Voucher validation error`,
          ),
        ),
      ),
    { dispatch: false },
  );

  //#endregion
  constructor(
    private actions$: Actions,
    private voucherService: VoucherService,
    private toastr: ToastrService,
    private store$: Store<AppState>,
  ) {}
}
