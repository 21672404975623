import { BrowserType } from '@core/enums/browser-type.enum';

export function getBrowserType(): BrowserType {
  const agent = window.navigator.userAgent;

  if ((agent.indexOf('Opera') || agent.indexOf('OPR')) != -1) {
    return BrowserType.Opera;
  } else if (agent.indexOf('Edg') != -1) {
    return BrowserType.Edge;
  } else if (agent.indexOf('Chrome') != -1) {
    return BrowserType.Chrome;
  } else if (agent.indexOf('Safari') != -1) {
    return BrowserType.Safari;
  } else if (agent.indexOf('Firefox') != -1) {
    return BrowserType.Firefox;
  } else if (agent.indexOf('MSIE') != -1 || agent.indexOf('Trident') != -1) {
    return BrowserType.IE;
  } else {
    return BrowserType.Other;
  }
}
