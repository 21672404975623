import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Cart } from '@core/store/cart/cart-state-models';
import { isUsaEnv } from '@shared/utils/environment-utils';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cart-totals',
  templateUrl: './cart-totals.component.html',
  styleUrls: ['./cart-totals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartTotalsComponent {
  @Input() cart: Observable<Cart>;

  readonly isUsaEnv = isUsaEnv;

  constructor() {}
}
