<div class="loading d-flex justify-content-center">
  <div class="w-100 h-100 wrapper container">
    <app-ph-spinner
      class="justify-content-center align-items-center d-flex"
      width="100px"
    ></app-ph-spinner>
    <h4 class="mt-3 font-weight-bold text-white text-center" *ngIf="message">
      {{ message }}
    </h4>
  </div>
</div>
