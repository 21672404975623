import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsultantSubmissionErrorType } from '@core/enums/consultant-submission-error-type.enum';
import { AppInitService } from '@core/services/app-init.service';
import { getVoucherErrorMessage } from '@shared/utils/voucher-error-utils';

@Component({
  selector: 'app-create-voucher-payment-error',
  templateUrl: './create-voucher-payment-error.component.html',
  styleUrls: ['./create-voucher-payment-error.component.scss'],
})
export class CreateVoucherPaymentErrorComponent implements OnInit {
  @Input() email: string;
  @Input() errorTypes: ConsultantSubmissionErrorType[] = [];
  @Input() price: number;
  @Output() resetPayment: EventEmitter<void> = new EventEmitter();

  // eslint-disable-next-line max-len
  errorMessage: string = $localize`Voucher creation was not successful, please ignore the e-mail if you got one!`;

  constructor(private appInitService: AppInitService) {}

  ngOnInit(): void {
    this.errorMessage = getVoucherErrorMessage(
      this.errorTypes,
      this.price > this.appInitService.Settings.sna.voucherLimits.dailyLimit ? null : this.email,
      this.appInitService.Settings.sna.voucherLimits,
    );
  }

  resetPaymentClick() {
    this.resetPayment.emit();
  }
}
