import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CountryName } from '@core/constants/country-name';
import { MelissaErrorCode } from '@core/enums/melissa-error-code';
import { ModalConfig } from '@core/models/modal-config.model';
import { AddressVerification } from '@core/store/address/address-state-models';
import { environment } from '@env';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SelectOption } from '../select/select.component';

@Component({
  selector: 'app-address-validation',
  templateUrl: './address-validation.component.html',
  styleUrls: ['./address-validation.component.scss'],
})
export class AddressValidationComponent implements OnInit, OnDestroy {
  @Input() addressName: string;
  @Input() isDefault: boolean;
  @Input() addressId: string;
  @Input() addressValidation$: Observable<AddressVerification>;
  @Input() isForbiddenState: boolean;
  @Input() countryStates$: Observable<SelectOption[]>;
  @Input() finalizeAddress: (formGroup: FormGroup) => void;
  @Input() onDismiss: () => void;

  readonly isMexEnv = isMexEnv;
  readonly AddressLine1 = 'addressLine1';
  readonly AddressLine2 = 'addressLine2';
  readonly AddressLine3 = 'addressLine3';
  readonly City = 'city';
  readonly State = 'state';
  readonly ZipCode = 'zipCode';
  readonly Country = 'country';
  readonly Name = 'name';
  readonly Default = 'default';

  originalAddressFormGroup: FormGroup;
  correctedAddressFormGroup: FormGroup;
  isOriginalAddress: boolean = true;
  codes = MelissaErrorCode;
  validationCategoryAddresses: MelissaErrorCode[];
  validationCategoryLocations: MelissaErrorCode[];
  validationCategoryOther: MelissaErrorCode[];

  constructor(private fb: FormBuilder) {}

  modalConfig: ModalConfig;

  private modalSubscription: Subscription;

  @ViewChild('modal') private modalComponent: ModalComponent;

  ngOnInit(): void {
    this.modalConfig = {
      title: $localize`Address Verification`,
      ngbModalOptions: {
        size: 'lg',
      },
      hideCloseButton: true,
      hideDismissButton: true,
      onDismiss: this.cancel.bind(this),
    };

    this.modalSubscription = this.addressValidation$
      .pipe(filter((data) => !!data && !data.isValid && !!data.correctedAddress))
      .subscribe((data) => {
        this.validationCategoryAddresses = data.ValidationCategoryAddresses;
        this.validationCategoryLocations = data.ValidationCategoryLocations;
        this.validationCategoryOther = data.ValidationCategoryOthers;

        this.modalComponent.open();
        this.originalAddressFormGroup = this.fb.group({
          [this.AddressLine1]: [data.originalAddress.addressLine1, [this.addressValidationFn()]],
          [this.AddressLine2]: [data.originalAddress.addressLine2, [this.addressValidationFn()]],
          [this.City]: [data.originalAddress.city, [this.cityValidationFn()]],
          [this.State]: [data.originalAddress.state, [this.stateValidationFn()]],
          [this.ZipCode]: [data.originalAddress.zipCode, [this.zipValidationFn()]],
          [this.Country]: [CountryName[environment.country]],
          [this.Name]: [this.addressName],
          [this.Default]: [this.isDefault],
        });

        this.correctedAddressFormGroup = this.fb.group({
          [this.AddressLine1]: [data.correctedAddress.addressLine1.trim()],
          [this.AddressLine2]: [data.correctedAddress.addressLine2.trim()],
          [this.City]: [data.correctedAddress.city],
          [this.State]: [data.correctedAddress.state],
          [this.ZipCode]: [data.correctedAddress.zipCode],
          [this.Country]: [CountryName[environment.country]],
          [this.Name]: [this.addressName],
          [this.Default]: [this.isDefault],
        });

        if (this.isMexEnv) {
          const originalAddressLine3Control = new FormControl(
            data.originalAddress.addressLine3 ? data.originalAddress.addressLine3 : '',
            [this.addressValidationFn()],
          );
          const correctedAddressLine3Control = new FormControl(
            data.correctedAddress.addressLine3 ? data.correctedAddress.addressLine3 : '',
          );

          this.originalAddressFormGroup.addControl(this.AddressLine3, originalAddressLine3Control);
          this.correctedAddressFormGroup.addControl(
            this.AddressLine3,
            correctedAddressLine3Control,
          );
        }

        setTimeout(() => this.originalAddressFormGroup.markAllAsTouched());
      });
  }

  ngOnDestroy(): void {
    this.modalSubscription.unsubscribe();
  }

  cancel(): void {
    this.originalAddressFormGroup.markAllAsTouched();
    this.originalAddressFormGroup.updateValueAndValidity();
    this.onDismiss();
    this.modalComponent.close();
  }

  continue(): void {
    if (this.isOriginalAddress) {
      this.finalizeAddress(this.originalAddressFormGroup);
    } else {
      this.finalizeAddress(this.correctedAddressFormGroup);
    }
    this.modalComponent.close();
  }

  public hasZipError(): boolean {
    return this.hasLocationErrorCode(this.codes.AE01) || this.hasLocationErrorCode(this.codes.AC01);
  }

  public hasStateError(): boolean {
    return this.hasLocationErrorCode(this.codes.AE01) || this.hasLocationErrorCode(this.codes.AC02);
  }

  public hasCityError(): boolean {
    return (
      this.hasLocationErrorCode(this.codes.AE01) ||
      this.hasLocationErrorCode(this.codes.AC03) ||
      this.hasLocationErrorCode(this.codes.AC09)
    );
  }

  public hasAddressError(): boolean {
    return (
      this.validationCategoryAddresses != undefined && this.validationCategoryAddresses.length > 0
    );
  }

  public hasAddressErrorCode = (code: MelissaErrorCode): boolean =>
    this.validationCategoryAddresses?.includes(code) ?? false;

  public hasLocationErrorCode(code: MelissaErrorCode): boolean {
    return this.validationCategoryLocations?.some((error) => error.startsWith(code)) ?? false;
  }

  private addressValidationFn(): ValidatorFn {
    return (): ValidationErrors => (this.hasAddressError() ? { melissaAddress: true } : null);
  }

  private zipValidationFn(): ValidatorFn {
    return (): ValidationErrors => (this.hasZipError() ? { melissaZip: true } : null);
  }

  private stateValidationFn(): ValidatorFn {
    return (): ValidationErrors => (this.hasStateError() ? { melissaState: true } : null);
  }

  private cityValidationFn(): ValidatorFn {
    return (): ValidationErrors => (this.hasCityError() ? { melissaCity: true } : null);
  }
}
