import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { NgxPayPalModule } from 'ngx-paypal';
import { BraintreeComponent } from './components/card-payment-wrapper/braintree/braintree.component';
import { CardPaymentWrapperComponent } from './components/card-payment-wrapper/card-payment-wrapper.component';
import { NexioComponent } from './components/card-payment-wrapper/nexio/nexio.component';
import { PayPalComponent } from './components/card-payment-wrapper/pay-pal/pay-pal.component';
import { CreateCardPaymentMethodErrorComponent } from './components/create-card-payment-method-error/create-card-payment-method-error.component';
import { SavedCardComponent } from './components/saved-card/saved-card.component';
import { SavedVoucherComponent } from './components/saved-voucher/saved-voucher.component';
import { OxxoVoucherPaymentModalComponent } from './components/voucher-payment-modal/oxxo-voucher-payment-modal/oxxo-voucher-payment-modal.component';

@NgModule({
  declarations: [
    CardPaymentWrapperComponent,
    BraintreeComponent,
    PayPalComponent,
    NexioComponent,
    SavedCardComponent,
    CreateCardPaymentMethodErrorComponent,
    OxxoVoucherPaymentModalComponent,
    SavedVoucherComponent,
  ],
  imports: [CommonModule, SharedModule, NgxPayPalModule, FormsModule],
  exports: [
    CardPaymentWrapperComponent,
    SavedCardComponent,
    CreateCardPaymentMethodErrorComponent,
    OxxoVoucherPaymentModalComponent,
    SavedVoucherComponent,
  ],
})
export class PaymentModule {}
