import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ContactUsRequest, ContactUsResponse } from '@core/dto/contact-us.dto';
import { Locale } from '@core/enums/locale';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  baseUrl: string = environment.ecommerceBackendUri;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {}

  sendEmail(contactUsRequest: ContactUsRequest): Observable<ContactUsResponse> {
    return this.http.post<ContactUsResponse>(`${this.baseUrl}api/contactus`, contactUsRequest, {
      params: new HttpParams({
        fromObject: { culture: getCulture(this.localeId) },
      }),
    });
  }
}
