import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { ValidateRegistrationCodeRequest } from '@core/dto/start-now-app.dto';
import { FormErrorTypes } from '@core/enums/form-error-type.enum';
import { AppState } from '@core/store';
import { selectRegistrationCodeValidity, selectStartNowAppData } from '@core/store/start-now-app';
import {
  resetRegistrationCode,
  resetRegistrationCodeValidity,
  updateRegistrationCode,
  validateRegistrationCode,
} from '@core/store/start-now-app/start-now-app.actions';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-registration-code-step',
  templateUrl: './registration-code-step.component.html',
  styleUrls: ['./registration-code-step.component.scss'],
})
export class RegistrationCodeStepComponent
  extends StartNowStepBaseComponent
  implements OnInit, OnDestroy
{
  passwordForm: FormGroup;
  registrationCodeValidity$: Observable<boolean>;
  readonly Password: string = 'password';
  readonly ErrorMessages = {
    [FormErrorTypes.required]: FormErrorMessages.snaRequired,
  };

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
    injector: Injector,
  ) {
    super(injector, 'SNA Step - 0.4 Registration Code');
  }

  ngOnInit(): void {
    this.store$.dispatch(resetRegistrationCode());
    this.passwordForm = this.createFormGroup();
    this.registrationCodeValidity$ = this.store$.select(selectRegistrationCodeValidity);
    this.checkRegistrationCodeValidity();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store$.dispatch(resetRegistrationCodeValidity());
  }

  submitStep(): void {
    this.goToNextStep.emit();
  }

  public previousStepClick(): void {
    this.goToPreviousStep.emit();
  }

  public nextStepClick(): void {
    this.passwordForm.markAllAsTouched();
    if (this.passwordForm.valid) {
      this.validateRegistrationCode();
    }
  }

  protected createFormGroup(): FormGroup {
    return this.fb.group({
      [this.Password]: ['', Validators.required],
    });
  }

  private checkRegistrationCodeValidity(): void {
    this.subscriptions.add(
      this.registrationCodeValidity$
        .pipe(
          filter((isValid) => isValid),
          take(1),
        )
        .subscribe(() => {
          const registrationCode: string = this.passwordForm.get(this.Password).value;
          this.store$.dispatch(updateRegistrationCode({ registrationCode }));
          this.submitStep();
        }),
    );
  }

  private validateRegistrationCode(): void {
    this.store$
      .select(selectStartNowAppData)
      .pipe(
        select((data) => data?.selectedConsultant?.beeNumber),
        take(1),
      )
      .subscribe((prowessId) => {
        const request: ValidateRegistrationCodeRequest = {
          prowessId: prowessId,
          registrationCode: this.passwordForm.get(this.Password).value,
        };
        this.store$.dispatch(validateRegistrationCode({ validateRegistrationCode: request }));
      });
  }
}
