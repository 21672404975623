import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';
import { LanguagesLocale } from '@core/constants/locale';
import { LanguageDropdownEntry } from '@core/models/language.dropdown.entry';
import { environment } from '@env';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent {
  languages: LanguageDropdownEntry[];
  locale = LanguagesLocale;
  currentLocale: string;

  constructor(@Inject(LOCALE_ID) public localeId: string) {
    this.languages = environment.languages;
    this.currentLocale = localeId;
  }

  handleLanguageClick(language: LanguageDropdownEntry): void {
    const url = new URL(window.location.href);
    const queryParams = url.searchParams;
    queryParams.set('lid', language.lid.toString());
    url.search = queryParams.toString();
    window.location.href = url.toString();
  }
}
