import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

const flyIn = transition(
  'inactive => active',
  animate(
    '400ms ease-in',
    keyframes([
      style({
        transform: 'translate3d(100%, 0, 0) skewX(-20deg)',
        opacity: 0,
      }),
      style({
        transform: 'skewX(20deg)',
        opacity: 1,
      }),
      style({
        transform: 'skewX(-5deg)',
        opacity: 1,
      }),
      style({
        transform: 'none',
        opacity: 1,
      }),
    ]),
  ),
);

const flyOut = transition(
  'active => removed',
  animate(
    '400ms ease-out',
    keyframes([
      style({
        opacity: 1,
      }),
      style({
        transform: 'translate3d(100%, 0, 0) skewX(30deg)',
        opacity: 0,
      }),
    ]),
  ),
);

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[app-toast]',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        }),
      ),
      flyIn,
      flyOut,
    ]),
  ],
  preserveWhitespaces: false,
})
export class ToastComponent extends Toast {
  isIframe = window.self !== window.top;

  @HostBinding('class') classes = this.isIframe ? 'd-none' : '';

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
