<app-modal #modal [modalConfig]="modalConfig">
  <div class="container" *ngIf="productQuickView$ | async as product">
    <div class="row">
      <div class="col-12 col-md-7">
        <app-media-gallery
          [images$]="productImageSlides$"
          [videos$]="productVideoSlides$"
          size="sm"
        ></app-media-gallery>
      </div>
      <div class="details col-12 col-md-5">
        <button
          class="btn-wishlist float-right"
          [ngClass]="{
            bi: true,
            'bi-heart': !isWishlisted,
            'bi-heart-fill': isWishlisted
          }"
          (click)="toggleWish(product.sku)"
          aria-label="Add to wishlist"
          i18n-aria-label
        ></button>
        <div>
          <span class="text-collection d-block text-uppercase mb-2">{{ product.collection }}</span>
          <span class="text-product d-block font-weight-bold mb-2">{{ product.name }}</span>
          <span class="text-sku d-block mb-4">{{ product.sku }}</span>
          <app-product-price-message [product]="product"></app-product-price-message>
          <app-product-price [product]="product"></app-product-price>
        </div>
        <div class="row mx-0 my-3 d-flex align-items-end">
          <ng-container *ngIf="productUtil.getAddToCartButtonVisibility$(product) | async">
            <div class="col-3 pl-0">
              <label class="text-double-dot" for="quantitySelector" i18n>Qty</label>
              <select id="quantitySelector" class="form-control" [(ngModel)]="quantity">
                <option *ngFor="let i of quantities" [ngValue]="i">
                  {{ i }}
                </option>
              </select>
            </div>
            <button
              class="btn btn-primary col-8 offset-1 text-uppercase"
              (click)="addToCart(product)"
              i18n
            >
              Add to cart
              <i class="bi bi-plus-lg ml-2"></i>
            </button>
          </ng-container>
          <button
            class="btn btn-primary col-12 mt-3 text-uppercase"
            i18n
            (click)="viewFullDetails()"
          >
            View full details
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal>
