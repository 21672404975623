<app-full-page-spinner *ngIf="loading$ | async"></app-full-page-spinner>
<app-party-order-payment-order-information></app-party-order-payment-order-information>
<div class="mb-5">
  <h5 class="font-weight-bold my-4" i18n> Credit Card Billing Address </h5>
  <form [formGroup]="addressForm">
    <div class="row">
      <div class="my-2 col-12 col-md-4">
        <div class="form-group">
          <app-text-field-input
            [identifier]="FormData.FirstName"
            label="First name"
            i18n-label
            labelAsterix="true"
            placeholder="First name"
            i18n-placeholder
            [formControlName]="FormData.FirstName"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
      <div class="my-2 col-12 col-md-4">
        <div class="form-group">
          <app-text-field-input
            [identifier]="FormData.LastName"
            label="Last name"
            i18n-label
            labelAsterix="true"
            placeholder="Last name"
            i18n-placeholder
            [formControlName]="FormData.LastName"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="my-2 col-12 col-md-4">
        <div class="form-group">
          <app-text-field-input
            [identifier]="FormData.AddressLine1"
            label="Address Line 1"
            i18n-label
            labelAsterix="true"
            placeholder="Address line 1"
            i18n-placeholder
            [formControlName]="FormData.AddressLine1"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
      <div class="my-2 col-12 col-md-4">
        <div class="form-group">
          <app-text-field-input
            [identifier]="FormData.AddressLine2"
            label="Address Line 2"
            i18n-label
            placeholder="Address line 2"
            i18n-placeholder
            [formControlName]="FormData.AddressLine2"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
      <ng-container *ngIf="isMexEnv">
        <div class="my-2 col-12 col-md-4">
          <div class="form-group">
            <app-text-field-input
              [identifier]="FormData.AddressLine3"
              label="Address Line 3"
              i18n-label
              placeholder="Address line 3"
              i18n-placeholder
              [formControlName]="FormData.AddressLine3"
              [isDataPrivate]="true"
            >
            </app-text-field-input>
          </div>
        </div>
      </ng-container>
      <div class="my-2 col-12 col-md-4">
        <div class="form-group">
          <app-text-field-input
            [identifier]="FormData.City"
            label="City"
            i18n-label
            labelAsterix="true"
            placeholder="City"
            i18n-placeholder
            [formControlName]="FormData.City"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
      <div class="my-2 col-6 col-md-4">
        <div class="form-group">
          <app-select
            [identifier]="FormData.State"
            label="State"
            i18n-label
            [formControlName]="FormData.State"
            [options]="countryStates$ | async"
          ></app-select>
        </div>
      </div>
      <div class="my-2 col-4 col-md-2">
        <div class="form-group">
          <app-text-field-input
            [identifier]="FormData.ZipCode"
            label="ZIP Code"
            i18n-label
            labelAsterix="true"
            placeholder="Zip Code"
            i18n-placeholder
            [formControlName]="FormData.ZipCode"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="my-2 col-12 col-md-4">
        <div class="form-group">
          <app-text-field-input
            [identifier]="FormData.EmailAddress"
            label="E-mail"
            i18n-label
            labelAsterix="true"
            placeholder="E-mail"
            type="email"
            i18n-placeholder
            [formControlName]="FormData.EmailAddress"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
      <div class="my-2 col-12 col-md-4">
        <div class="form-group">
          <app-text-field-input
            [identifier]="FormData.PhoneNumber"
            label="Mobile Number"
            i18n-label
            labelAsterix="true"
            placeholder="Mobile Number"
            i18n-placeholder
            type="tel"
            [formControlName]="FormData.PhoneNumber"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="row">
  <div class="col-6 col-md-4">
    <button class="btn btn-outline-dark text-uppercase w-100" (click)="onCancel()" i18n>
      Cancel
    </button>
  </div>
  <div class="col-6 col-md-4">
    <button
      class="btn btn-primary text-uppercase w-100"
      (click)="onNext()"
      [disabled]="addressForm.invalid"
      i18n
      >Credit Card Details</button
    >
  </div>
</div>
