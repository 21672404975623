/* eslint-disable @typescript-eslint/naming-convention */
export type BlogListSortType = { [key in keyof typeof BlogListSortOption]: string };

export const BlogListSortOption = {
  NewestFirst: $localize`Most recent`,
  NameAscending: $localize`Name: A - Z`,
  NameDescending: $localize`Name: Z - A`,
};

export const defaultBlogListSortOption: BlogListSortType =
  'NewestFirst' as unknown as BlogListSortType;
