import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { ValidEmailRegExp, ValidPhoneRegExp } from '@core/constants/patterns';
import { FormErrorTypes } from '@core/enums/form-error-type.enum';
import { InputMaxLength } from '@core/enums/input-max-length.enum';
import { PhoneNumberType } from '@core/enums/phone-number-type.enum';
import { ConnectWithYouStepModel } from '@core/models/start-now-app.model';
import { LogRocketService } from '@core/services/logrocket.service';
import { AppState } from '@core/store';
import {
  selectContactInfoIsNextEnabled,
  selectContactStep,
  selectContactUniquenessCheck,
  selectStartNowAppData,
  selectStepProcessing,
} from '@core/store/start-now-app';
import {
  ContactInfo,
  ContactStep,
  ContactUniquenessCheck,
} from '@core/store/start-now-app/start-now-app-state-models';
import {
  emailUniquenessCheck,
  phoneNumberUniquenessCheck,
  resetContactStep,
  resetEmailValidity,
  resetPhoneNumberValidity,
  updateStartNowAppContactInfo,
} from '@core/store/start-now-app/start-now-app.actions';
import { select, Store } from '@ngrx/store';
import { emailValidator } from '@shared/utils/email-validator-utils';
import { isUsaEnv } from '@shared/utils/environment-utils';
import { matchValidator } from '@shared/utils/validation.utils';
import { StatusCodes } from 'http-status-codes';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-connect-with-you-step',
  templateUrl: './connect-with-you-step.component.html',
  styleUrls: ['./connect-with-you-step.component.scss'],
})
export class ConnectWithYouStepComponent
  extends StartNowStepBaseComponent
  implements OnInit, OnDestroy
{
  readonly Email: string = 'email';
  readonly EmailConfirm: string = 'emailConfirm';
  readonly CellPhoneNumber: string = 'cellPhoneNumber';
  readonly HomeNumber: string = 'homeNumber';
  readonly Whatsapp: string = 'whatsapp';
  readonly SMS: string = 'sms';
  readonly emailConfirmErrorMessage = $localize`Please confirm your email address.
  It must match the email address above.`;
  readonly validEmailRegExp = ValidEmailRegExp;
  readonly validPhoneRegExp = ValidPhoneRegExp;
  readonly CellPhoneNumberValidationText = $localize`This cell phone number already exists in our
  system. Please provide a different phone number`;
  readonly HomeNumberValidationText = $localize`This home number already exists in our system.
  Please provide a different phone number`;
  readonly PhoneNumberType = PhoneNumberType;
  readonly EmailValidationText = $localize`This email address already exists in our system.
  Please provide a different email address`;
  readonly StatusCodes = StatusCodes;
  readonly FormErrorTypes = FormErrorTypes;
  readonly ErrorMessages = {
    [FormErrorTypes.required]: FormErrorMessages.snaRequired,
    [FormErrorTypes.pattern]: $localize`Cannot start with 1`,
    [FormErrorTypes.maxlength]: FormErrorMessages.maxlength,
    [FormErrorTypes.minlength]: FormErrorMessages.minlength,
    [FormErrorTypes.email]: FormErrorMessages.email,
  };
  readonly isUsaEnv = isUsaEnv;

  connectWithYouFormGroup: FormGroup;
  isSubmitted: boolean = false;
  contactUniquenessCheck$: Observable<ContactUniquenessCheck>;
  stepProcessing$: Observable<boolean>;
  contactStep$: Observable<ContactStep>;

  private connectWithYouDefault: ConnectWithYouStepModel = {
    email: [
      '',
      [
        Validators.required,
        emailValidator(this.validEmailRegExp),
        Validators.maxLength(InputMaxLength.Fifty),
      ],
    ],
    emailConfirm: ['', [Validators.required, Validators.maxLength(InputMaxLength.Fifty)]],
    cellPhoneNumber: [
      '',
      [
        Validators.required,
        Validators.maxLength(InputMaxLength.Ten),
        Validators.minLength(InputMaxLength.Ten),
        Validators.pattern(this.validPhoneRegExp),
      ],
    ],
    homeNumber: [
      '',
      [
        Validators.maxLength(InputMaxLength.Ten),
        Validators.minLength(InputMaxLength.Ten),
        Validators.pattern(this.validPhoneRegExp),
      ],
    ],
    whatsapp: [this.isMexEnv],
    sms: [this.isUsaEnv],
  };

  constructor(
    private formBuilder: FormBuilder,
    private store$: Store<AppState>,
    private logRocketService: LogRocketService,
    injector: Injector,
  ) {
    super(injector, 'SNA Step - 2 Connect With You');
  }

  ngOnInit(): void {
    this.contactUniquenessCheck$ = this.store$.select(selectContactUniquenessCheck);
    this.connectWithYouFormGroup = this.createFormGroup();
    this.prepopulateForm();
    this.stepProcessing$ = this.store$.select(selectStepProcessing);
    this.contactStep$ = this.store$.select(selectContactStep);
    this.updateContactInfoIfEmailIsValid();
    this.isNextEnabledListener();
    this.uniquenessListener();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store$.dispatch(resetContactStep());
  }

  submitStep(): void {
    this.connectWithYouFormGroup.markAllAsTouched();
    this.connectWithYouFormGroup.updateValueAndValidity();
    this.isSubmitted = true;

    if (this.connectWithYouFormGroup.valid) {
      this.store$.dispatch(
        emailUniquenessCheck({ email: this.connectWithYouFormGroup.get(this.Email).value }),
      );

      const cellPhoneNumber = this.connectWithYouFormGroup.get(this.CellPhoneNumber).value;
      const homeNumber = this.connectWithYouFormGroup.get(this.HomeNumber).value;

      this.phoneNumberUniquenessCheck(cellPhoneNumber, this.PhoneNumberType.Mobile);
      if (!!homeNumber) {
        this.phoneNumberUniquenessCheck(homeNumber, this.PhoneNumberType.Home);
      }
      this.resetEmailErrorSubscriptions();
      this.resetPhoneNumberErrorSubscription(this.CellPhoneNumber, this.PhoneNumberType.Mobile);
      this.resetPhoneNumberErrorSubscription(this.HomeNumber, this.PhoneNumberType.Home);
    }
  }

  protected createFormGroup(): FormGroup {
    return this.formBuilder.group(this.connectWithYouDefault, {
      validators: matchValidator(this.EmailConfirm, this.Email, this.FormErrorTypes.emailConfirm),
    });
  }

  private prepopulateForm() {
    this.store$
      .select(selectStartNowAppData)
      .pipe(
        take(1),
        select((startNowAppData) => startNowAppData?.contactInfo),
      )
      .subscribe((contactInfo) => {
        if (contactInfo) {
          this.connectWithYouFormGroup.setValue({
            email: contactInfo.email,
            emailConfirm: contactInfo.emailConfirm,
            cellPhoneNumber: contactInfo.cellPhoneNumber,
            homeNumber: contactInfo.homeNumber ? contactInfo.homeNumber : null,
            whatsapp: contactInfo.whatsapp,
            sms: contactInfo.sms,
          });
        }
      });
  }

  private updateContactInfoIfEmailIsValid() {
    this.subscriptions.add(
      this.contactUniquenessCheck$
        .pipe(
          filter((contactUniquenessCheck) => {
            if (!!this.connectWithYouFormGroup.get(this.HomeNumber).value) {
              return (
                contactUniquenessCheck.emailIsValid &&
                contactUniquenessCheck.phoneValidity[PhoneNumberType.Mobile] &&
                contactUniquenessCheck.phoneValidity[PhoneNumberType.Home]
              );
            } else {
              return (
                contactUniquenessCheck.emailIsValid &&
                contactUniquenessCheck.phoneValidity[PhoneNumberType.Mobile]
              );
            }
          }),
        )
        .subscribe(() => {
          const homeNumber = this.connectWithYouFormGroup.get(this.HomeNumber).value;
          const contactInfo: ContactInfo = {
            email: this.connectWithYouFormGroup.get(this.Email).value,
            emailConfirm: this.connectWithYouFormGroup.get(this.EmailConfirm).value,
            cellPhoneNumber: this.connectWithYouFormGroup.get(this.CellPhoneNumber).value,
            homeNumber: homeNumber === '' ? null : homeNumber,
            whatsapp: this.connectWithYouFormGroup.get(this.Whatsapp).value,
            sms: this.connectWithYouFormGroup.get(this.SMS).value,
          };
          this.store$.dispatch(resetContactStep());
          this.store$.dispatch(
            updateStartNowAppContactInfo({
              contactInfo,
            }),
          );
          this.logRocketService.logRocketIdentify(contactInfo.email);
        }),
    );
  }

  private isNextEnabledListener(): void {
    this.subscriptions.add(
      this.store$
        .select(selectContactInfoIsNextEnabled)
        .pipe(filter((isNextEnabled) => isNextEnabled))
        .subscribe(() => {
          this.goToNextStep.emit();
        }),
    );
  }

  private uniquenessListener(): void {
    this.subscriptions.add(
      this.contactUniquenessCheck$.pipe(distinctUntilChanged()).subscribe((uniquenessCheck) => {
        if (uniquenessCheck.phoneValidity.Home === false) {
          this.connectWithYouFormGroup.get(this.HomeNumber).setErrors({ uniqueness: false });
        }
        if (uniquenessCheck.phoneValidity.Mobile === false) {
          this.connectWithYouFormGroup.get(this.CellPhoneNumber).setErrors({ uniqueness: false });
        }
      }),
    );
  }

  private phoneNumberUniquenessCheck(phoneNumber: string, phoneNumberType: PhoneNumberType): void {
    this.store$.dispatch(
      phoneNumberUniquenessCheck({
        request: {
          phoneNumber,
          phoneNumberType,
        },
      }),
    );
  }

  private resetEmailErrorSubscriptions(): void {
    this.subscriptions.add(
      this.connectWithYouFormGroup
        .get(this.Email)
        .valueChanges.pipe(take(1))
        .subscribe(() => this.store$.dispatch(resetEmailValidity())),
    );
  }

  private resetPhoneNumberErrorSubscription(field: string, phoneNumberType: PhoneNumberType): void {
    this.subscriptions.add(
      this.connectWithYouFormGroup
        .get(field)
        .valueChanges.pipe(take(1))
        .subscribe(() => {
          this.store$.dispatch(resetPhoneNumberValidity({ phoneNumberType }));
          this.connectWithYouFormGroup.get(field).updateValueAndValidity({ emitEvent: false });
        }),
    );
  }
}
