<app-modal #checkerModal [modalConfig]="modalConfig">
  <ng-container *ngIf="currentConsultant$ | async as current; else spinner">
    <ng-container *ngIf="assignedConsultant$ | async as connected; else spinner">
      <div class="p-3">
        <div class="row">
          <div class="col-12 text-center" i18n>
            We recognized you have an
            <strong>
              active connection with <span class="no-wrap">{{ connected.fullName }}.</span>
            </strong>
            You are on the
            <strong>
              consultant website for <span class="no-wrap">{{ current.fullName }}.</span>
            </strong>
            Please choose an option below to continue.
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-4 mb-1">
            <app-action-card
              icon="people"
              primary="true"
              i18n-actionLabel
              actionLabel="My consultant's site"
              (actionClick)="fetchConsultant(connected.vanityName)"
              i18n
            >
              Go to
              <strong>
                <span class="no-wrap">{{ connected.fullName }}'s</span>
              </strong>
              website
            </app-action-card>
          </div>
          <div class="col-12 col-md-4 mb-1">
            <app-action-card
              icon="person-check"
              i18n-actionLabel
              actionLabel="Change consultant"
              (actionClick)="openConfirmDialog(current.beeNumber)"
              i18n
            >
              Change my consultant connection to
              <strong>
                <span class="no-wrap">{{ current.fullName }}</span>
              </strong>
            </app-action-card>
          </div>
          <div class="col-12 col-md-4 mb-1">
            <app-action-card
              icon="cart2"
              i18n-actionLabel
              actionLabel="Place order here"
              (actionClick)="close()"
              i18n
            >
              Place just this one time order with
              <strong>
                <span class="no-wrap">{{ current.fullName }}</span>
              </strong>
            </app-action-card>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</app-modal>

<app-confirm-modal
  #confirmModal
  i18n-title
  title="Consultant Connection Change"
  (yesClick)="onConfirm($event)"
  (noClick)="onCancel()"
  i18n-noLabel
  i18n-yesLabel
  noLabel="Cancel"
  yesLabel="Confirm"
>
  <div class="text-center" i18n>
    You are making a change to your consultant connection. Your new consultant will be
    <strong>{{ (currentConsultant$ | async)?.fullName }}</strong
    >.
  </div>
</app-confirm-modal>

<ng-template #spinner>
  <div class="d-flex justify-content-center m-5"><app-ph-spinner></app-ph-spinner></div>
</ng-template>
