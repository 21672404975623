import produce from 'immer';
import { VoucherState } from './voucher-state-models';
import { VoucherActionTypes } from './voucher.actions';

export const initialState: VoucherState = {
  loading: false,
  email: null,
  vouchers: [],
  retryCounter: -1,
  error: null,
  prevalidated: false,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case VoucherActionTypes.resetVoucherStore:
      return initialState;

    case VoucherActionTypes.createVoucher:
      draft.loading = true;
      draft.retryCounter = initialState.retryCounter;
      return;

    case VoucherActionTypes.receiveVoucher:
      draft.loading = true;
      return;

    case VoucherActionTypes.receiveVoucherFailure:
    case VoucherActionTypes.generateVoucherFailure:
      draft.loading = false;
      return;

    case VoucherActionTypes.createVoucherFailure:
      draft.loading = false;
      draft.error = { errors: action.error.errors };
      return;

    case VoucherActionTypes.setVoucherEmail:
      draft.email = action.email;
      return;

    case VoucherActionTypes.retryReceiveVoucher:
      draft.retryCounter++;
      return;

    case VoucherActionTypes.receiveVoucherSuccess:
      draft.loading = false;
      draft.vouchers = action.vouchers;
      return;

    case VoucherActionTypes.resetVoucherErrors:
      draft.error = initialState.error;
      draft.prevalidated = false;
      return;

    case VoucherActionTypes.prevalidateVoucher:
      draft.prevalidated = false;
      return;

    case VoucherActionTypes.prevalidateVoucherSuccess:
      draft.prevalidated = true;
      return;

    case VoucherActionTypes.prevalidateVoucherFailure:
      draft.prevalidated = true;
      draft.error = action.error?.error;
      return;

    default:
      return draft;
  }
}, initialState);
