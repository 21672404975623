import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CarrierTypeName } from '@core/constants/carrier-type-name';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { AppState } from '@core/store';
import { selectOrderDetailCartons } from '@core/store/order';
import { OrderDetailsCarton } from '@core/store/order/order-state-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-order-details-cartons',
  templateUrl: './order-details-cartons.component.html',
  styleUrls: ['./order-details-cartons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsCartonsComponent implements OnInit {
  readonly thumbnail = Thumbnail;
  readonly CarrierName = CarrierTypeName;

  cartons$: Observable<OrderDetailsCarton[]>;
  collapseState: { [key: string]: boolean } = {};

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.cartons$ = this.store$.select(selectOrderDetailCartons);
  }

  getCollapseText(id: string) {
    return this.collapseState?.[id] ? $localize`Show less` : $localize`Show more`;
  }

  hasProp(key: string) {
    return key in this.collapseState;
  }
}
