import { AbstractControl, FormGroup } from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { ValidationError } from '@core/store/common-store.model';

export function validInput(formControl: AbstractControl): boolean {
  if (!formControl.touched) {
    return true;
  }

  // in case of form control is disabled the valid = false
  if (formControl.disabled) {
    return true;
  }

  return formControl.valid;
}

export function validGroup(form: FormGroup, errorType: string): boolean {
  let isValid = true;

  if (form.errors) {
    Object.keys(form.errors).forEach((key) => {
      if (key === errorType) {
        isValid = false;
      }
    });
  }

  return isValid;
}

export function getValidationErrorMessageList(
  formControl: AbstractControl,
  errorMessages: { [key: string]: string },
): string[] {
  let result: string[] = [];
  const errors = formControl?.errors;

  if (errors) {
    Object.keys(errors).forEach((key) => {
      if (errorMessages[key]) {
        result.push(errorMessages[key]);
      }
    });
  }

  return result;
}

export function getFormValidationErrorMessage(
  formGroup: FormGroup,
  controlName: string,
  errorMessages: { [key: string]: string } = FormErrorMessages,
): string {
  const formControl = formGroup.get(controlName);
  const result = getValidationErrorMessageList(formControl, errorMessages);
  return result.length ? result.join('\r\n') : '';
}

export function matchValidator(
  controlName: string,
  matchingControlName: string,
  errorType: string,
) {
  return (formGroup: FormGroup): ValidationError => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (!control.touched || !matchingControl.touched) {
      return null;
    }
    if (control.value !== matchingControl.value) {
      return { [errorType]: true };
    } else {
      return null;
    }
  };
}
