<div class="container">
  <div class="row">
    <div class="col-12">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeId">
        <li ngbNavItem="blogs">
          <a ngbNavLink routerLink="blogs" class="text-center" i18n>Browse articles</a>
          <div *ngIf="activeId === 'blogs'" class="arrow-down center"></div>
        </li>
        <li ngbNavItem="recipes">
          <a ngbNavLink routerLink="recipes" class="text-center" i18n>Browse recipes</a>
          <div *ngIf="activeId === 'recipes'" class="arrow-down center"></div>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-3">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
