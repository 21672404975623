import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppInitService } from './app-init.service';

@Injectable({
  providedIn: 'root',
})
export class CartFeatureGuardService implements CanActivate {
  constructor(
    private router: Router,
    private appInitService: AppInitService,
  ) {}

  canActivate(): boolean {
    if (this.appInitService.Settings.ec.isCartEnabled) {
      return true;
    } else {
      this.router.navigateByUrl('/');
      return false;
    }
  }
}
