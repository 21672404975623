import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SnaConsultantProfileImagePlaceholderSrc } from '@core/constants/image-placeholders';
import { Consultant } from '@core/store/consultant/consultant-state-models';

@Component({
  selector: 'app-consultant-box',
  templateUrl: './consultant-box.component.html',
  styleUrls: ['./consultant-box.component.scss'],
})
export class ConsultantBoxComponent implements OnInit {
  @Input()
  boxHasButton: boolean = false;

  @Input()
  consultant: Consultant = null;

  @Input()
  isSelected: boolean = false;

  @Output()
  selectedConsultant: EventEmitter<Consultant> = new EventEmitter<Consultant>();

  readonly PlaceholderImageSrc = SnaConsultantProfileImagePlaceholderSrc;
  selectedLabel = $localize`selected`;
  selectLabel = $localize`select`;

  buttonLabel = '';
  address: string;

  ngOnInit(): void {
    if (!this.boxHasButton) {
      this.selectedConsultant.emit(this.consultant);
    }
    this.address = this.consultant?.city + ', ' + this.consultant?.state;

    // TODO: handle if there are no consultants EC-3456
  }

  public selectConsultantAsRecruiter(): void {
    this.selectedConsultant.emit(this.consultant);
  }
}
