import { InjectionToken } from '@angular/core';
import { Country } from '@core/enums/country.enum';
import { environment } from '@env';

export const isMexEnv = environment.country === Country.Mexico;
export const isUsaEnv = environment.country === Country.Usa;
export const logrocketId = `${environment.logRocketId}/${environment.environmentName}`;

export const IS_MEX_ENV = new InjectionToken<boolean>('Is Mexico environment', {
  providedIn: 'root',
  factory: (): boolean => environment.country === Country.Mexico,
});

export const IS_USA_ENV = new InjectionToken<boolean>('Is USA environment', {
  providedIn: 'root',
  factory: (): boolean => environment.country === Country.Usa,
});
