import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-return-step-navigation-buttons',
  templateUrl: './return-step-navigation-buttons.component.html',
  styleUrls: ['./return-step-navigation-buttons.component.scss'],
})
export class ReturnStepNavigationButtonsComponent {
  @Input() negativeButtonLabel: string = $localize`Back`;
  @Input() positiveButtonLabel: string = $localize`Next`;
  @Input() positiveButtonDisabled: boolean = false;
  @Output() negativeButtonClick: EventEmitter<void> = new EventEmitter();
  @Output() positiveButtonClick: EventEmitter<void> = new EventEmitter();

  constructor() {}

  previousStepClick(): void {
    this.negativeButtonClick.emit();
  }

  nextStepClick(): void {
    this.positiveButtonClick.emit();
  }
}
