<ng-container [ngSwitch]="linkStyle">
  <ng-container *ngSwitchCase="'internal_primary'">
    <a
      class="link text-uppercase"
      (click)="scrollToTopOfThePage(link)"
      [target]="linkTarget"
      [routerLink]="[link.url]"
    >
      {{ link.title }}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'internal_secondary'">
    <a
      class="link"
      (click)="scrollToTopOfThePage(link)"
      [target]="linkTarget"
      [routerLink]="[link.url]"
      [hidden]="linkIsHidden(link.url)"
    >
      {{ link.title }}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'external_primary'">
    <a class="link text-uppercase" target="_blank" href="{{ link.url }}">
      {{ link.title }}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="'external_secondary'"
    ><a class="link" target="_blank" href="{{ link.url }}">{{ link.title }}</a></ng-container
  >
</ng-container>
