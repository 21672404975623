<ng-container *ngIf="(ccOrderHistory$ | async)?.length">
  <div class="ml-2 mt-4 mb-3">
    <h3 i18n>Orders submitted by my Consultant</h3>
    <div i18n>You can see those orders here which were submitted by your consultant for you.</div>
  </div>

  <!-- Mobile design -->
  <div class="d-md-none">
    <ng-container *ngFor="let order of ccOrderHistory$ | async">
      <div class="mb-2">
        <div class="row background-gray">
          <div class="col order-id py-2">
            <strong>
              <span class="text-double-dot" i18n>Order ID</span>{{ order.poeOrderId }}</strong
            >
          </div>
          <div class="col-auto py-2">
            <p class="text-nowrap uppercase order-status m-0">
              {{ order.ecommerceStatus }}
            </p>
          </div>

          <div class="col-12 pb-2">
            <span class="d-block">
              <span class="text-double-dot" i18n>Order date</span
              >{{ order.dateReceived | date: 'longDate' }}
            </span>
          </div>
          <div class="col pb-2">
            <button
              *ngIf="enableActionMethod; else linkAction"
              class="btn btn-primary"
              (click)="emitAction(order.poeOrderId)"
            >
              <i class="mr-1" [ngClass]="actionIcon"></i>
              <span>{{ actionText }}</span>
            </button>
            <ng-template #linkAction>
              <button class="btn btn-primary" routerLink="{{ ActionPath }}{{ order.poeOrderId }}">
                <i class="mr-1" [ngClass]="actionIcon"></i>
                <span>{{ actionText }}</span>
              </button>
            </ng-template>
          </div>
          <div class="col-auto pb-2 d-flex align-items-center">
            <span class="total-price">{{ order.totalMerchCost | phCurrency }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- Desktop design -->
  <div class="d-none d-md-flex">
    <app-sortable-table
      class="w-100"
      [columns]="columns"
      [rows]="ccOrderHistoryTableData$ | async"
    ></app-sortable-table>
  </div>
</ng-container>
