import { environment } from '@env';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { WebsiteModeState } from './website-mode/website-mode-state-models';

import { AddressEffects } from '@core/store/address/address.effects';
import { PaymentEffects } from '@core/store/payment/payment.effects';
import { AuthEffects } from './auth/auth.effects';
import { BlogEffects } from './blog/blog.effects';
import { CartEffects } from './cart/cart.effects';
import { CheckoutEffects } from './checkout/checkout.effects';
import { ConditionalContentEffects } from './conditional-content/conditional-content.effects';
import { ConsultantEffects } from './consultant/consultant.effects';
import { ContactUsEffects } from './contact-us/contact-us.effects';
import { FooterEffects } from './footer/footer.effects';
import { MagazineEffects } from './magazine/magazine.effects';
import { OrderEffects } from './order/order.effects';
import { ProductEffects } from './product/product.effects';
import { RecipeEffects } from './recipe/recipe.effects';
import { ReturnEffects } from './return/return.effects';
import { SearchEffects } from './search/search.effects';
import { ShareEffects } from './share/share.effects';
import { StartNowAppEffects } from './start-now-app/start-now-app.effects';
import { UserEffects } from './user/user.effects';
import { VoucherEffects } from './voucher/voucher.effects';
import { WishlistEffects } from './wishlist/wishlist.effects';

import * as fromAddress from './address/address.reducer';
import * as fromAuth from './auth/auth.reducer';
import * as fromBlog from './blog/blog.reducer';
import * as fromCart from './cart/cart.reducer';
import * as fromCheckout from './checkout/checkout.reducer';
import * as fromConditionalContent from './conditional-content/conditional-content.reducer';
import * as fromConsultant from './consultant/consultant.reducer';
import * as fromContactUs from './contact-us/contact-us.reducer';
import * as fromFooter from './footer/footer.reducer';
import * as fromMagazine from './magazine/magazine.reducer';
import * as fromOrder from './order/order.reducer';
import * as fromPayment from './payment/payment.reducer';
import * as fromProductReview from './product-review/product-review.reducer';
import * as fromProduct from './product/product.reducer';
import * as fromRecipe from './recipe/recipe.reducer';
import * as fromReturn from './return/return.reducer';
import * as fromSearch from './search/search.reducer';
import * as fromShare from './share/share.reducer';
import * as fromStartNowApp from './start-now-app/start-now-app.reducer';
import * as fromUser from './user/user.reducer';
import * as fromVoucher from './voucher/voucher.reducer';
import * as fromWebsiteMode from './website-mode/website-mode.reducer';
import * as fromWishlist from './wishlist/wishlist.reducer';

import { PaymentState } from '@core/store/payment/payment-state-models';
import { AddressState } from './address/address-state-models';
import { AuthState } from './auth/auth-state-models';
import { BlogState } from './blog/blog-state-models';
import { CartState } from './cart/cart-state-models';
import { CheckoutState } from './checkout/checkout-state-models';
import { ConditionalContentState } from './conditional-content/conditional-content-state-models';
import { ConsultantState } from './consultant/consultant-state-models';
import { ContactUsState } from './contact-us/contact-us-state-models';
import { FooterState } from './footer/footer.reducer';
import { MagazineState } from './magazine/magazine.reducer';
import { OrderState } from './order/order-state-models';
import { ProductReviewState } from './product-review/product-review-state-models';
import { ProductReviewEffects } from './product-review/product-review.effect';
import { ProductState } from './product/product-state-models';
import { RecipeState } from './recipe/recipe-state-models';
import { ReturnState } from './return/return-state-models';
import { SearchState } from './search/search-state-models';
import { ShareState } from './share/share-state-models';
import { StartNowAppState } from './start-now-app/start-now-app-state-models';
import { UserState } from './user/user-state-models';
import { VoucherState } from './voucher/voucher-state-models';
import { WishlistState } from './wishlist/wishlist-state-models';

export interface AppState {
  authState: AuthState;
  conditionalContentState: ConditionalContentState;
  footerState: FooterState;
  magazineState: MagazineState;
  userState: UserState;
  productState: ProductState;
  productReviewState: ProductReviewState;
  cartState: CartState;
  addressState: AddressState;
  consultantState: ConsultantState;
  websiteModeState: WebsiteModeState;
  recipeState: RecipeState;
  wishlistState: WishlistState;
  blogState: BlogState;
  checkoutState: CheckoutState;
  searchState: SearchState;
  orderState: OrderState;
  contactUsState: ContactUsState;
  paymentState: PaymentState;
  startNowAppState: StartNowAppState;
  shareState: ShareState;
  returnState: ReturnState;
  voucherState: VoucherState;
}

export const effects = [
  UserEffects,
  AuthEffects,
  FooterEffects,
  MagazineEffects,
  ProductEffects,
  ProductReviewEffects,
  ConditionalContentEffects,
  CartEffects,
  AddressEffects,
  ConsultantEffects,
  RecipeEffects,
  WishlistEffects,
  BlogEffects,
  OrderEffects,
  CheckoutEffects,
  SearchEffects,
  OrderEffects,
  ContactUsEffects,
  PaymentEffects,
  StartNowAppEffects,
  ShareEffects,
  ReturnEffects,
  VoucherEffects,
];

export const reducers: ActionReducerMap<AppState> = {
  authState: fromAuth.reducer,
  conditionalContentState: fromConditionalContent.reducer,
  footerState: fromFooter.reducer,
  magazineState: fromMagazine.reducer,
  userState: fromUser.reducer,
  productState: fromProduct.reducer,
  productReviewState: fromProductReview.reducer,
  cartState: fromCart.reducer,
  addressState: fromAddress.reducer,
  consultantState: fromConsultant.reducer,
  websiteModeState: fromWebsiteMode.reducer,
  recipeState: fromRecipe.reducer,
  wishlistState: fromWishlist.reducer,
  blogState: fromBlog.reducer,
  orderState: fromOrder.reducer,
  checkoutState: fromCheckout.reducer,
  searchState: fromSearch.reducer,
  contactUsState: fromContactUs.reducer,
  paymentState: fromPayment.reducer,
  startNowAppState: fromStartNowApp.reducer,
  shareState: fromShare.reducer,
  returnState: fromReturn.reducer,
  voucherState: fromVoucher.reducer,
};

export function getReducers(): ActionReducerMap<AppState> {
  return reducers;
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
