export enum PaymentHandlerType {
  ECommerce,
  StartNowApp,
}

export enum EcPaymentHandlerType {
  CheckoutOrder,
  CreatePaymentMethod,
  PartyOrder,
}
