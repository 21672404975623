import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToFixedPipe } from '@shared/pipes/to-fixed.pipe';
import { AppRoutingModule } from 'app/app-routing.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxMaskModule } from 'ngx-mask';
import { AccountHeaderComponent } from './components/account-header/account-header.component';
import { ActionCardComponent } from './components/action-card/action-card.component';
import { AddressErrorMessagesComponent } from './components/address-validation/address-validation-error-messages/address-error-messages.component';
import { AddressValidationComponent } from './components/address-validation/address-validation.component';
import { InfoCardComponent } from './components/app-info-card/app-info-card.component';
import { BreadcrumbWrapperComponent } from './components/breadcrumb-wrapper/breadcrumb-wrapper.component';
import { CircularProgressComponent } from './components/circular-progress/circular-progress.component';
import { ConditionalContentByPageCategoryComponent } from './components/conditional-content-by-page-category/conditional-content-by-page-category.component';
import { ConditionalContentComponent } from './components/conditional-content/conditional-content.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { CurrencyFieldInputComponent } from './components/currency-field-input/currency-field-input.component';
import { FullPageSpinnerComponent } from './components/full-page-spinner/full-page-spinner.component';
import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { NumberFieldInputComponent } from './components/number-field-input/number-field-input.component';
import { OxxoVoucherContainerComponent } from './components/oxxo-voucher-container/oxxo-voucher-container.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { PhSelectComponent } from './components/ph-select/ph-select.component';
import { PhSpinnerComponent } from './components/ph-spinner/ph-spinner.component';
import { PlusMinusCounterComponent } from './components/plus-minus-counter/plus-minus-counter.component';
import { ProductPriceMessageComponent } from './components/product-price-message/product-price-message.component';
import { ProductPriceComponent } from './components/product-price/product-price.component';
import { RelatedCarouselComponent } from './components/related-carousel/related-carousel.component';
import { RomanceContentWrapperComponent } from './components/romance-content-wrapper/romance-content-wrapper.component';
import { NavigatorComponent } from './components/ruler-stepper/navigator/navigator.component';
import { StepRulerComponent } from './components/ruler-stepper/step-ruler/step-ruler.component';
import { StepComponent } from './components/ruler-stepper/step/step.component';
import { SelectComponent } from './components/select/select.component';
import { EmailShareModalComponent } from './components/share-buttons/email-share-modal/email-share-modal.component';
import { ShareButtonsComponent } from './components/share-buttons/share-buttons.component';
import { SortableTableActionComponent } from './components/sortable-table/sortable-table-action/sortable-table-action.component';
import { SortableTableHeaderDirective } from './components/sortable-table/sortable-table-header.directive';
import { SortableTableComponent } from './components/sortable-table/sortable-table.component';
import { SsnNumberFieldInputComponent } from './components/ssn-number-field-input/ssn-number-field-input.component';
import { TextFieldInputComponent } from './components/text-field-input/text-field-input.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ToastComponent } from './components/toast/toast.component';
import { VoucherInstructionsComponent } from './components/voucher-instructions/voucher-instructions.component';
import { InputTrimDirective } from './directives/input-trim/input-trim.directive';
import { PhImageDirective } from './directives/ph-image/ph-image.directive';
import { PhInnerHtmlDirective } from './directives/ph-inner-html/ph-inner-html.directive';
import { FillUrlsPipe } from './pipes/fill-urls.pipe';
import { LocalizeAbsUrlsPipe } from './pipes/localize-abs-urls.pipe';
import { PhCurrencyPipe } from './pipes/ph-currency.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeResourceUrlPipe } from './pipes/safe-url.pipe';

@NgModule({
  declarations: [
    ToastComponent,
    TextFieldInputComponent,
    NumberFieldInputComponent,
    PasswordInputComponent,
    CurrencyFieldInputComponent,
    TextareaComponent,
    SelectComponent,
    AccountHeaderComponent,
    ConditionalContentComponent,
    ToFixedPipe,
    PhCurrencyPipe,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    ModalComponent,
    PhSpinnerComponent,
    SortableTableComponent,
    SortableTableHeaderDirective,
    PlusMinusCounterComponent,
    RelatedCarouselComponent,
    CircularProgressComponent,
    AddressValidationComponent,
    InfoCardComponent,
    ConfirmModalComponent,
    BreadcrumbWrapperComponent,
    PhImageDirective,
    ActionCardComponent,
    ConditionalContentByPageCategoryComponent,
    ShareButtonsComponent,
    EmailShareModalComponent,
    SortableTableActionComponent,
    AddressErrorMessagesComponent,
    SsnNumberFieldInputComponent,
    ProductPriceComponent,
    ProductPriceMessageComponent,
    FullPageSpinnerComponent,
    InputTrimDirective,
    InfoModalComponent,
    VoucherInstructionsComponent,
    OxxoVoucherContainerComponent,
    PhInnerHtmlDirective,
    NotFoundPageComponent,
    PageTitleComponent,
    RomanceContentWrapperComponent,
    FillUrlsPipe,
    LocalizeAbsUrlsPipe,
    PhSelectComponent,
    StepComponent,
    StepRulerComponent,
    NavigatorComponent,
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    NgCircleProgressModule.forRoot(),
  ],
  exports: [
    NgbModule,
    ToastComponent,
    TextFieldInputComponent,
    NumberFieldInputComponent,
    PasswordInputComponent,
    CurrencyFieldInputComponent,
    TextareaComponent,
    SelectComponent,
    AccountHeaderComponent,
    ConditionalContentComponent,
    ToFixedPipe,
    PhCurrencyPipe,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    ModalComponent,
    PhSpinnerComponent,
    SortableTableComponent,
    PlusMinusCounterComponent,
    RelatedCarouselComponent,
    CircularProgressComponent,
    AddressValidationComponent,
    InfoCardComponent,
    ConfirmModalComponent,
    BreadcrumbWrapperComponent,
    PhImageDirective,
    ActionCardComponent,
    ConditionalContentByPageCategoryComponent,
    ShareButtonsComponent,
    SsnNumberFieldInputComponent,
    ProductPriceComponent,
    ProductPriceMessageComponent,
    FullPageSpinnerComponent,
    InputTrimDirective,
    InfoModalComponent,
    VoucherInstructionsComponent,
    OxxoVoucherContainerComponent,
    PhInnerHtmlDirective,
    PageTitleComponent,
    RomanceContentWrapperComponent,
    FillUrlsPipe,
    LocalizeAbsUrlsPipe,
    PhSelectComponent,
    StepComponent,
    StepRulerComponent,
    NavigatorComponent,
  ],
})
export class SharedModule {}
