import { Injectable } from '@angular/core';
import { MagazineService } from '@core/services/magazine.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as magazineActions from './magazine.actions';

@Injectable()
export class MagazineEffects {
  fetchMagazinesPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(magazineActions.fetchMagazinesPage),
      mergeMap((params) =>
        this.magazineService.fetchMagazinePage(params.pageStart, params.pageSize).pipe(
          map((magazines) =>
            magazineActions.fetchMagazinePageSuccess({
              magazines,
              pageSize: params.pageSize,
            }),
          ),
          catchError((error) => of(magazineActions.fetchMagazinesPageError({ error }))),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private magazineService: MagazineService) {}
}
