import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { defaultRecipeListSortOption, RecipeListSortType } from '@core/enums/recipe-list-sort-type';
import { AppState } from '@core/store';
import {
  selectAllRecipesFetched,
  selectIsRecipeCategoriesFetched,
  selectRecipeCategories,
  selectRecipes
} from '@core/store/recipe';
import { RecipeCategory, RecipeItem } from '@core/store/recipe/recipe-state-models';
import {
  fetchRecipeCategories,
  fetchRecipes,
  resetRecipes,
} from '@core/store/recipe/recipe.actions';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, tap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-recipe-list-page',
  templateUrl: './recipe-list-page.component.html',
  styleUrls: ['./recipe-list-page.component.scss'],
})
export class RecipeListPageComponent implements OnInit, OnDestroy {
  recipes$: Observable<RecipeItem[]>;
  recipeCategories$: Observable<RecipeCategory[]>;
  pageNumber: number = 1;
  pageSize: number = 12;
  fetchNextSubscription: Subscription;
  routeActicationSubscription: Subscription;
  scrollDown$ = new Subject<number>();
  categoryUrl: string;
  sortType: RecipeListSortType = defaultRecipeListSortOption;
  isRecipeCategoriesFetched$: Observable<boolean>;

  constructor(private store$: Store<AppState>, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.recipeCategories$ = this.store$.select(selectRecipeCategories);
    this.isRecipeCategoriesFetched$ = this.store$.select(selectIsRecipeCategoriesFetched);

    this.routeActicationSubscription = combineLatest([
      this.activatedRoute.paramMap,
      this.recipeCategories$,
    ])
      .pipe(
        filter(([pMap, recipeCategories]) => this.recipeCategoryIsValid(pMap, recipeCategories)),
      )
      .subscribe(([pMap]) => {
        this.categoryUrl = this.getCategoryUrlParam(pMap);
        this.fetchFirstPage();
      });

    this.store$.dispatch(fetchRecipeCategories());
    this.recipes$ = this.store$.select(selectRecipes);
    this.fetchNextPagesOnScrollDown();
  }

  ngOnDestroy(): void {
    this.fetchNextSubscription.unsubscribe();
    this.routeActicationSubscription.unsubscribe();
    this.store$.dispatch(resetRecipes());
  }

  onScrollDown(): void {
    this.scrollDown$.next(this.pageNumber);
  }

  sortChangeHandler(key: RecipeListSortType): void {
    this.sortType = key;
    this.fetchFirstPage();
  }

  private fetchFirstPage() {
    this.pageNumber = 1;
    this.store$.dispatch(
      fetchRecipes({
        categoryUrl: this.categoryUrl,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        isFirstPage: true,
        sortType: this.sortType,
      }),
    );
    this.pageNumber++;
  }

  private fetchNextPagesOnScrollDown(): void {
    this.fetchNextSubscription = this.scrollDown$
      .pipe(
        withLatestFrom(this.store$.select(selectAllRecipesFetched)),
        filter(([, isAllFetched]) => !isAllFetched),
        tap(([pageNumber]) => {
          this.store$.dispatch(
            fetchRecipes({
              categoryUrl: this.categoryUrl,
              pageNumber: pageNumber,
              pageSize: this.pageSize,
              isFirstPage: false,
              sortType: this.sortType,
            }),
          );
          this.pageNumber++;
        }),
      )
      .subscribe();
  }

  private recipeCategoryIsValid(pMap: ParamMap, recipeCategories: RecipeCategory[]) {
    return (
      recipeCategories.length > 1 &&
      recipeCategories.some((r) => r.urlName === this.getCategoryUrlParam(pMap))
    );
  }

  private getCategoryUrlParam(pMap: ParamMap) {
    return pMap.get('category') || '';
  }
}
