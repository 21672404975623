<app-confirm-modal
  #modal
  i18n-title
  title="Shipping Address Required"
  (yesClick)="onConfirm()"
  (noClick)="navigateToShippingAddress()"
  i18n-noLabel
  i18n-yesLabel
  noLabel="Go to shipping address"
  yesLabel="Ok"
>
  <div class="text-center">
    <p class="font-size-bigger" i18n
      >Due to fraud protection, please add a shipping address to be able to save a new card
    </p>
  </div>
</app-confirm-modal>
