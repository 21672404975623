import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DEFAULT_PAGE_TITLE } from '@core/constants/page-title';
import {
  ConditionalContent,
  MetaPagePreviewImage,
} from '@core/store/conditional-content/conditional-content-state-models';

@Component({
  selector: 'app-conditional-content',
  template: '',
  styles: [],
})
// Please add container class to the wrapper div when using ConditionalContentComponent.
export class ConditionalContentComponent implements OnChanges, OnDestroy {
  @Input() conditionalContent: ConditionalContent[];

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private titleService: Title,
    private metaTagService: Meta,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.conditionalContent?.currentValue) {
      this.clearComponent();
      changes.conditionalContent.currentValue.forEach((content, index) => {
        if (
          index === 0 &&
          content.metaPagePreviewImage?.length &&
          content.metaPageTitle &&
          content.metaPageDescription
        ) {
          this.createMetaElement(
            content.metaPageTitle,
            content.metaPageDescription,
            content.metaPagePreviewImage[0],
          );
        }
        this.createDivElement(content.content);
        this.createScriptElement(content.codeInjection);
        this.createStyleElement(content.styleInjection);
      });
    }
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(DEFAULT_PAGE_TITLE);
  }

  private clearComponent(): void {
    this.renderer.selectRootElement(this.el.nativeElement).innerHTML = '';
  }

  private createDivElement(content: string): void {
    const div = this.renderer.createElement('div');
    div.innerHTML = content;
    this.renderer.appendChild(this.el.nativeElement, div);
  }

  private createScriptElement(codeInjection: string): void {
    const script = this.renderer.createElement('script');
    script.innerHTML = codeInjection;
    this.renderer.appendChild(this.el.nativeElement, script);
  }

  private createStyleElement(styleInjection: string): void {
    const style = this.renderer.createElement('style');
    style.innerHTML = styleInjection;
    this.renderer.appendChild(this.el.nativeElement, style);
  }

  private createMetaElement(
    metaPageTitle: string,
    metaPageDescription: string,
    metaPagePreviewImage: MetaPagePreviewImage,
  ): void {
    this.titleService.setTitle(metaPageTitle);
    this.metaTagService.updateTag({ name: 'description', content: metaPageDescription });
    this.metaTagService.updateTag({ name: 'og:image', content: metaPagePreviewImage.url });
  }
}
