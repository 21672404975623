<ng-container *ngIf="orderLine">
  <div class="row d-lt-none my-2" [class.checked-bg]="orderLine.checked">
    <div class="col-5 d-flex align-items-center">
      <div class="form-group mb-0 mr-2">
        <input
          *ngIf="!readOnly"
          id="order-line-selector"
          type="checkbox"
          [checked]="orderLine.checked"
          (click)="toggleOrderLine()"
        />
      </div>
      <div class="py-1 flex-shrink-0">
        <img
          phImage
          [images]="orderLine.images"
          [thumbnailDesktop]="thumbnail.productCheckoutDesktop"
          [thumbnailMobile]="thumbnail.productCheckoutMobile"
          class="img-thumbnail mr-2"
        />
      </div>
      <div class="d-flex flex-column align-self-center">
        <div class="category-name font-size-smaller">{{ orderLine.collectionName }}</div>
        <div class="product-name">
          {{ orderLine.productName }}
        </div>
        <div class="sku font-size-smaller">#{{ orderLine.sku }}</div>
      </div>
    </div>

    <div class="col-3 d-flex flex-column justify-content-center">
      <div class="font-size-smaller">{{ orderLine.price | phCurrency }}</div>
    </div>

    <div class="col-4 align-self-center font-size-smaller">
      <div *ngIf="readOnly; else reasonSelect">{{ orderLine.reason }}</div>
    </div>
  </div>

  <div class="row d-block d-md-none">
    <div class="col-12">
      <div class="mobile-container">
        <div class="root p-2">
          <img
            class="align-self-center w-100"
            phImage
            [images]="orderLine.images"
            [thumbnailDesktop]="thumbnail.productCheckoutDesktop"
            [thumbnailMobile]="thumbnail.productCheckoutMobile"
          />
          <div class="d-flex flex-column">
            <div class="category-name">{{ orderLine.collectionName }}</div>
            <div class="product-name">{{ orderLine.productName }}</div>
            <div class="sku mb-2">#{{ orderLine.sku }}</div>
            <button
              *ngIf="!readOnly"
              class="btn btn-outline-dark return-button"
              (click)="toggleOrderLine()"
              [class.active]="orderLine.checked"
            >
              <div class="d-flex align-items-center">
                <span i18n>Return</span>
                <i *ngIf="orderLine.checked" class="bi bi-x-circle ml-1 p-0"></i>
              </div>
            </button>
          </div>
          <div class="d-flex flex-column justify-content-end align-items-end">
            <div class="price">{{ orderLine.price | phCurrency }}</div>
          </div>
        </div>
        <div class="combo-box-container">
          <div *ngIf="readOnly; else reasonSelect">{{ orderLine.reason }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #reasonSelect>
  <app-select
    identifier="reason-of-return"
    [options]="returnReasonList"
    defaultValue="Pick a reason"
    i18n-defaultValue
    (change)="changeReturnReason($event)"
    [value]="orderLine.reason"
  ></app-select>
</ng-template>
