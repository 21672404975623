import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-us-success',
  templateUrl: './contact-us-success.component.html',
  styleUrls: ['./contact-us-success.component.scss'],
})
export class ContactUsSuccessComponent {
  constructor() {}
}
