import { Component, OnInit } from '@angular/core';
import { DefaultImagePlaceholderSrc } from '@core/constants/image-placeholders';
import { AppState } from '@core/store';
import { selectCurrentConsultant } from '@core/store/consultant';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  templateUrl: './my-story-page.component.html',
  styleUrls: ['./my-story-page.component.scss'],
})
export class MyStoryPageComponent implements OnInit {
  imagePlaceholder = DefaultImagePlaceholderSrc;
  consultant$: Observable<Consultant>;
  subscription: Subscription;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.consultant$ = this.store$.select(selectCurrentConsultant);
  }
}
