<div class="row">
  <div class="col-12 col-md-10 mt-4">
    <div *ngIf="paymentResult === SubmittedOrderStatus.success">
      <span class="po-submit success">
        <span i18n>Your party order payment has been accepted by Princess House!</span>
        <i class="bi bi-check-circle-fill"></i
      ></span>
    </div>

    <div *ngIf="paymentResult === SubmittedOrderStatus.failure">
      <span class="po-submit failed">
        <span i18n>Party Order payment submit failed!</span> <i class="bi bi-x-circle-fill"></i
      ></span>
    </div>
  </div>
</div>

<div class="row mt-4">
  <div class="col-6 col-md-5">
    <button class="btn btn-outline-dark text-uppercase w-100 h-100" (click)="onBackToSearch()" i18n>
      Add more payments
    </button>
  </div>
  <div class="col-6 col-md-5">
    <button class="btn btn-primary text-uppercase w-100 h-100" (click)="onGoToHome()" i18n>
      Go to Home Page
    </button>
  </div>
</div>
