import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppcuesService } from '@core/services/appcues.service';
import { AppState } from '@core/store';
import { selectUser } from '@core/store/user';
import { Store } from '@ngrx/store';
import { Subscription, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-appcues-initializer',
  template: '',
})
export class AppcuesInitializerComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private appcuesService: AppcuesService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([this.appcuesService.initAppcues(), this.store$.select(selectUser)])
        .pipe(filter(([appcues]) => appcues))
        .subscribe(([, user]) => {
          this.appcuesService.setAppcuesIdentityForLoggedInUser(user);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
