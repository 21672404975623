import { Component, Input } from '@angular/core';
import { OrderDetailsCartonItem } from '@core/store/order/order-state-models';

@Component({
  selector: 'app-carton-items',
  templateUrl: './carton-items.component.html',
  styleUrls: ['./carton-items.component.scss'],
})
export class CartonItemsComponent {
  @Input() cartonItems: OrderDetailsCartonItem[];

  constructor() {}
}
