<div>
  <a routerLink="../.." type="button" class="d-none d-md-block">
    <i class="bi bi-arrow-left"></i>
    <span i18n> My Orders</span>
  </a>
</div>

<div *ngIf="loading$ | async; else content" class="d-flex justify-content-center m-5">
  <app-ph-spinner width="60px"></app-ph-spinner>
</div>

<ng-template #content>
  <div *ngIf="ccOrderDetails$ | async as ccOrderDetails">
    <!-- Header -->
    <div>
      <div class="d-none d-md-flex justify-content-between align-items-center">
        <app-account-header title="{{ poeOrderId }}"></app-account-header>
        <div>
          <span class="text-collection d-block info-block mb-3">
            <span class="text-double-dot" i18n>Order receive date</span
            >{{ ccOrderDetails.dateReceived | date: 'longDate' }} |
            <strong>{{ OrderStatusLocale[ccOrderDetails.ecommerceStatus] }}</strong>
          </span>
        </div>
      </div>
      <div class="d-md-none">
        <div class="row order-text-row mb-0">
          <div class="col">
            <a routerLink=".." class="text-secondary" i18n>My Orders ></a>
            <span class="font-weight-bold ml-1" i18n>
              Order #{{ ccOrderDetails.masterOrderId }}
            </span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="font-weight-bold order-id">
              <a routerLink=".."><i class="bi bi-arrow-left"></i></a>
              <span class="ml-2" i18n>Order #{{ ccOrderDetails.masterOrderId }}</span>
            </div>

            <div class="order-date">
              <span>
                <span class="text-double-dot" i18n>Order receive date</span
                >{{ ccOrderDetails.dateReceived | date: 'longDate' }} |
                <strong>{{ OrderStatusLocale[ccOrderDetails.ecommerceStatus] }}</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Summary -->
    <div class="summary-content">
      <hr class="my-0 d-lt-none" />
      <div class="row d-none d-md-flex">
        <app-info-card
          class="col-md-6 b-right px-3 pl-md-4"
          [data]="createCustomerInfoCard(ccOrderDetails.shippingNameInfo)"
        ></app-info-card>
        <app-info-card
          class="col-md-6 px-3 px-md-5 pl-md-5"
          [data]="
            createShippingAddressInfoCard(
              ccOrderDetails.shippingAddressInfo,
              AddressCardTitles.shippingInfo
            )
          "
        ></app-info-card>
      </div>
      <hr class="my-0 d-lt-none" />
    </div>

    <!-- Products -->
    <div>
      <div class="row my-3 mt-md-5 mb-md-0">
        <div class="col">
          <div class="title">
            <span i18n>Product(s)</span>
          </div>
        </div>
      </div>

      <app-cc-order-details-table
        [ccOrderItems]="(ccOrderDetails$ | async)?.products"
      ></app-cc-order-details-table>
    </div>

    <!-- Total -->
    <div class="row">
      <div class="col-12 d-block d-md-flex justify-content-end m-auto">
        <app-cc-order-details-total
          [merchandiseTotal]="ccOrderDetails.totalMerchandiseCost"
          [deliveryFeeTotal]="ccOrderDetails.shippingCost"
          [salesTaxTotal]="ccOrderDetails.tax.finalAmount"
          [totalPrice]="getTotal(ccOrderDetails)"
        ></app-cc-order-details-total>
      </div>
    </div>
  </div>
</ng-template>
