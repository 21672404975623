import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AppState } from '@core/store';
import { selectOrderDetailBackOrderItems } from '@core/store/order';
import { OrderDetailsBackOrderItem } from '@core/store/order/order-state-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-order-details-back-order-items',
  templateUrl: './order-details-back-order-items.component.html',
  styleUrls: ['./order-details-back-order-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsBackOrderItemsComponent implements OnInit {
  backOrderItems$: Observable<OrderDetailsBackOrderItem[]>;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.backOrderItems$ = this.store$.select(selectOrderDetailBackOrderItems);
  }
}
