<div class="footer-content-primary ph-footer shade-{{ shade }}">
  <div class="py-3 d-flex flex-wrap justify-content-center">
    <ul class="footer-links row justify-content-center p-0 m-0">
      <li
        *ngFor="let link of links | async"
        [ngClass]="{ secondary: isSecondary }"
        class="separator-right link-highlight"
      >
        <app-footerlink
          class="px-2"
          [linkTarget]="linkTarget"
          [link]="link"
          [isSecondary]="isSecondary"
        ></app-footerlink>
      </li>
    </ul>
  </div>
</div>
