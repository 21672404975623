import { Component } from '@angular/core';
import { AppState } from '@core/store';
import { selectPartyOrderData } from '@core/store/order';
import { PartyOrderData } from '@core/store/order/order-state-models';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-party-order-payment-order-information',
  templateUrl: './party-order-payment-order-information.component.html',
  styleUrls: ['./party-order-payment-order-information.component.scss'],
})
export class PartyOrderPaymentOrderInformationComponent extends BaseComponent {
  public partyOrderData$: Observable<PartyOrderData>;

  constructor(private store$: Store<AppState>) {
    super();

    this.partyOrderData$ = this.store$
      .select(selectPartyOrderData)
      .pipe(filter((partyOrderData) => !!partyOrderData));
  }
}
