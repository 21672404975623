import { CreateCardPaymentErrorType } from '@core/enums/create-card-payment-error-type.enum';

export const CreatePaymentErrorTypeTranslations: Map<CreateCardPaymentErrorType, string> = new Map<
  CreateCardPaymentErrorType,
  string
>([
  [CreateCardPaymentErrorType.ProcessorDeclined, $localize`Processor declined`],
  [CreateCardPaymentErrorType.GatewayRejected, $localize`Gateway rejected`],
  [CreateCardPaymentErrorType.CvvVerificationFailed, $localize`Hard declined credit card!`],
  [
    CreateCardPaymentErrorType.CvvVerificationNotSupportedOrFailed,
    $localize`Hard declined credit card!`,
  ],
  [
    CreateCardPaymentErrorType.CvvAndAvsVerificationNotSupportedOrFailed,
    $localize`Hard declined credit card!`,
  ],
  [
    CreateCardPaymentErrorType.CvvAndAvsPostalCodeVerificationFailed,
    $localize`Hard declined credit card!`,
  ],
  [CreateCardPaymentErrorType.AvsVerificationNotSupportedOrFailed, $localize`Verification failed`],
  [
    CreateCardPaymentErrorType.AvsPostalCodeVerificationFailed,
    $localize`Hard declined credit card!`,
  ],
  [CreateCardPaymentErrorType.AvsStreetAddressVerificationFailed, $localize`Address invalid`],
  [CreateCardPaymentErrorType.PaymentMethodNotPresent, $localize`Payment method not present`],
  [CreateCardPaymentErrorType.VerificationFailed, $localize`Card Verification Failed`],
  [CreateCardPaymentErrorType.ValidationFailed, $localize`Card Validation Failed`],
  [
    CreateCardPaymentErrorType.PaymentMethodNotCreditCard,
    $localize`Payment Method Not Credit Card`,
  ],
  [CreateCardPaymentErrorType.Unrecognized, $localize`Unrecognized`],
  [CreateCardPaymentErrorType.Unknown, $localize`Unknown`],
  [
    CreateCardPaymentErrorType.PaymentMethodTypeMismatch,
    $localize`Selected card type do not match with the card type`,
  ],
  [
    CreateCardPaymentErrorType.PaymentPlatformCustomerNotFound,
    $localize`Payment platform customer not found`,
  ],
  [CreateCardPaymentErrorType.MissingParameter, $localize`Missing parameter`],
  [CreateCardPaymentErrorType.InvalidParameter, $localize`Invalid parameter`],
  [CreateCardPaymentErrorType.AuthorizationFailed, $localize`Authorization failed`],
]);

export const CreatePaymentMissingAddressErrorMessage = $localize`Missing shipping address`;
