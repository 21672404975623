<app-modal #modal [modalConfig]="modalConfig">
  <div class="text-center">
    <span>
      <i class="bi bi-exclamation-triangle"></i>
    </span>
    <h3 class="title mt-2 mb-3" i18n> We didn't find any consultant </h3>
    <div class="text-center mb-3">
      <span i18n>Please check your search criteria</span>
    </div>
    <button class="btn btn-primary text-uppercase" (click)="closeModal()" i18n> Close </button>
  </div>
</app-modal>
