import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AddressValidationErrorMessages } from '@core/constants/address-validation-error-messages';
import { MaintenanceErrorMessage } from '@core/constants/sna-error-messages';
import { AddressType } from '@core/enums/address-type.enum';
import { AddressValidationErrors } from '@core/enums/address-validation-errors.enum';
import { Country } from '@core/enums/country.enum';
import { InputMaxLength } from '@core/enums/input-max-length.enum';
import { LanguagePreferenceType } from '@core/enums/language-preference-type.enum';
import { AppState } from '@core/store';
import {
  selectAddressInfo,
  selectAddressInfoIsNextEnabled,
  selectAddressValidationModalData,
  selectAddressValidationStep,
  selectCanAddressOverride,
  selectSnaCountryStates,
} from '@core/store/start-now-app';
import {
  Address,
  AddressValidationStep,
  AddressVerification,
} from '@core/store/start-now-app/start-now-app-state-models';
import {
  fetchCountryStates,
  fetchStartNowAppStarterKits,
  resetAddressValidationModal,
  resetAddressValidationStep,
  stepProcessing,
  updateStartNowAppAddressInfo,
  validateAddress,
} from '@core/store/start-now-app/start-now-app.actions';
import { environment } from '@env';
import { select, Store } from '@ngrx/store';
import { SelectOption } from '@shared/components/select/select.component';
import { countryStatesToSelectOptions } from '@shared/utils/address-utils';
import { getGenericErrorMessage } from '@shared/utils/get-generic-error-message-utils';
import { validInput } from '@shared/utils/validation.utils';
import { StatusCodes } from 'http-status-codes';
import { combineLatest, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-wait-business-tools-step',
  templateUrl: './wait-business-tools-step.component.html',
  styleUrls: ['./wait-business-tools-step.component.scss'],
})
export class WaitBusinessToolsStepComponent
  extends StartNowStepBaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Output() showConsultantList: EventEmitter<void> = new EventEmitter<void>();
  readonly HomeAddressFormGroup: string = 'homeAddressFormGroup';
  readonly ShippingAddressFromGroup: string = 'shippingAddressFormGroup';
  readonly AddressLine1: string = 'address1';
  readonly AddressLine2: string = 'address2';
  readonly AddressLine3: string = 'address3';
  readonly City: string = 'city';
  readonly State: string = 'state';
  readonly ZipCode: string = 'zip';
  readonly AddressValidationErrorMessages = AddressValidationErrorMessages;
  readonly GenericErrorMessage = getGenericErrorMessage();
  readonly MaintenanceErrorMessage = MaintenanceErrorMessage;
  readonly StatusCodes = StatusCodes;
  readonly Country: Country = environment.country;
  readonly LanguagePreferred: string = 'languagePreference';
  readonly LanguagePreferenceType = LanguagePreferenceType;

  public formSubmitted: boolean = false;
  public canAddressOverride$: Observable<boolean>;
  addressFormGroup: FormGroup;
  countryStates$: Observable<SelectOption[]>;
  useHomeAddressForShipping: boolean = true;
  addressValidation$: Observable<AddressVerification>;
  addressStep$: Observable<AddressValidationStep>;
  noCorrectedAddress: boolean = false;
  isForbiddenState: boolean = false;
  addressValidationResultErrorCode: string;

  constructor(
    private store$: Store<AppState>,
    private fb: FormBuilder,
    injector: Injector,
  ) {
    super(injector, 'SNA Step - 3 Wait Business Tools');
  }

  ngOnInit(): void {
    this.store$.dispatch(fetchCountryStates());
    this.canAddressOverride$ = this.store$.select(selectCanAddressOverride);
    this.countryStates$ = countryStatesToSelectOptions(this.store$.select(selectSnaCountryStates));
    this.addressStep$ = this.store$.select(selectAddressValidationStep);

    this.addressValidation$ = this.store$
      .select(selectAddressValidationModalData)
      .pipe(select((data) => data?.payload));

    this.listenAddressValidation();
    this.listenSubmitProcesses();
    this.listenValidationResult();

    this.addressFormGroup = this.createFormGroup();

    this.subscriptions.add(
      this.addressFormGroup
        .get(this.LanguagePreferred)
        .valueChanges.subscribe((value) =>
          this.store$.dispatch(fetchStartNowAppStarterKits({ starterKitLanguageCode: value })),
        ),
    );

    if (this.isMexEnv) {
      this.addressFormGroup
        .get(this.LanguagePreferred)
        ?.setValue(this.LanguagePreferenceType.Mexico);
    }
    this.addressFormGroup.get(this.ShippingAddressFromGroup)?.disable({ emitEvent: false });
  }

  ngAfterViewInit(): void {
    this.prepopulateForm();
  }

  ngOnDestroy(): void {
    this.store$.dispatch(resetAddressValidationModal());
    this.store$.dispatch(resetAddressValidationStep());
    super.ngOnDestroy();
  }

  submitStep(): void {
    this.formSubmitted = true;
    this.addressFormGroup.markAllAsTouched();
    this.addressFormGroup.updateValueAndValidity();

    const addressToValidate = this.useHomeAddressForShipping
      ? this.addressFormGroup.get(this.HomeAddressFormGroup).value
      : this.addressFormGroup.get(this.ShippingAddressFromGroup).value;

    if (this.addressFormGroup.valid) {
      this.store$.dispatch(stepProcessing({ stepProcessing: true }));
      const addressType = this.useHomeAddressForShipping ? AddressType.Home : AddressType.Shipping;
      this.store$.dispatch(
        validateAddress({
          address: { ...addressToValidate, addressType, country: environment.country },
        }),
      );

      this.listenAddressChangeAndResetForbiddenState();
    }
  }

  listenSubmitProcesses() {
    this.subscriptions.add(
      combineLatest([
        this.store$.select(selectAddressValidationStep),
        this.store$.select(selectAddressInfoIsNextEnabled),
      ])
        .pipe(
          filter(([step, isNextEnabled]) => step.isAddressValidationSuccess && isNextEnabled),
          take(1),
        )
        .subscribe(() => {
          this.store$.dispatch(stepProcessing({ stepProcessing: false }));
          this.goToNextStep.emit();
        }),
    );
  }

  /** Finalize address processes by address-validation-modal.
   * If the user entered a wrong address by this modal can choose the user the wrong or the corrected one */
  finalizeAddress = (
    validatedFormGroup: FormGroup,
    isCorrectedAddress: boolean,
    isPostOfficeBox: boolean,
  ): void => {
    this.canAddressOverride$.pipe(take(1)).subscribe((canOverride) => {
      const address: Address = validatedFormGroup.value;
      const homeAndShippingAddress = this.createShippingHomeAddressArray({
        ...address,
        isPostOfficeBox,
      });
      const languagePreferred = this.addressFormGroup.get(this.LanguagePreferred).value;
      if (canOverride || isCorrectedAddress) {
        if (this.useHomeAddressForShipping) {
          this.addressFormGroup.get(this.HomeAddressFormGroup)?.patchValue(address);
        } else {
          this.addressFormGroup.get(this.ShippingAddressFromGroup)?.patchValue(address);
        }
        this.store$.dispatch(stepProcessing({ stepProcessing: true }));
        this.store$.dispatch(
          updateStartNowAppAddressInfo({
            addresses: homeAndShippingAddress,
            languagePreferred,
          }),
        );
      } else {
        const addressType = this.useHomeAddressForShipping
          ? AddressType.Home
          : AddressType.Shipping;
        this.store$.dispatch(
          validateAddress({
            address: { ...address, addressType: addressType, country: environment.country },
          }),
        );
      }
    });
  };

  dismissAddressValidationModal = (): void => {
    this.store$.dispatch(resetAddressValidationModal());
  };

  toggleShippingAddressActivity(useSameAddress: boolean): void {
    if (useSameAddress) {
      this.addressFormGroup.get(this.ShippingAddressFromGroup)?.disable({ emitEvent: false });
    } else {
      this.addressFormGroup.get(this.ShippingAddressFromGroup)?.enable({ emitEvent: false });
    }
  }

  get radioButtonValidInput(): boolean {
    return validInput(this.addressFormGroup.get(this.LanguagePreferred));
  }

  protected createFormGroup(): FormGroup {
    const homeAddressFormGroup = this.createAddressFormGroup();
    const shippingAddressFormGroup = this.createAddressFormGroup();

    if (this.isMexEnv) {
      homeAddressFormGroup.addControl(
        this.AddressLine3,
        new FormControl('', [Validators.maxLength(InputMaxLength.Fifty)]),
      );

      shippingAddressFormGroup.addControl(
        this.AddressLine3,
        new FormControl('', [Validators.maxLength(InputMaxLength.Fifty)]),
      );
    }

    return this.fb.group({
      [this.HomeAddressFormGroup]: homeAddressFormGroup,
      [this.ShippingAddressFromGroup]: shippingAddressFormGroup,
      [this.LanguagePreferred]: ['', Validators.required],
    });
  }

  private createAddressFormGroup(): FormGroup {
    return this.fb.group({
      [this.AddressLine1]: ['', [Validators.required, Validators.maxLength(InputMaxLength.Fifty)]],
      [this.AddressLine2]: ['', [Validators.maxLength(InputMaxLength.Fifty)]],
      [this.City]: ['', [Validators.required, Validators.maxLength(InputMaxLength.Fifty)]],
      [this.State]: ['', [Validators.required]],
      [this.ZipCode]: ['', [Validators.required, Validators.maxLength(InputMaxLength.Ten)]],
    });
  }

  private prepopulateForm() {
    this.store$
      .select(selectAddressInfo)
      .pipe(
        take(1),
        filter((addressInfo) => !!addressInfo?.addresses?.length),
      )
      .subscribe(({ addresses, languagePreferred }) => {
        let isShippingAddress = false;
        addresses.forEach((address) => {
          if (address.addressType === AddressType.Home) {
            this.addressFormGroup.get(this.HomeAddressFormGroup)?.patchValue(address);
          } else if (address.addressType === AddressType.Shipping) {
            this.addressFormGroup.get(this.ShippingAddressFromGroup)?.patchValue(address);
            isShippingAddress = true;
          }
        });

        this.addressFormGroup
          .get(this.LanguagePreferred)
          ?.setValue(languagePreferred, { emitEvent: false });
        this.useHomeAddressForShipping = !isShippingAddress;
        this.toggleShippingAddressActivity(!isShippingAddress);
      });
  }

  private listenAddressValidation() {
    this.subscriptions.add(
      this.addressValidation$
        .pipe(filter((addressValidation) => addressValidation?.isValid))
        .subscribe((addressValidation) => {
          const shippingAddress = {
            ...this.selectShippingAddress(),
            isPostOfficeBox: addressValidation.isPostOfficeBox,
          };
          const homeAndShippingAddress = this.createShippingHomeAddressArray(shippingAddress);
          const languagePreferred = this.addressFormGroup.get(this.LanguagePreferred).value;
          this.store$.dispatch(
            updateStartNowAppAddressInfo({
              addresses: homeAndShippingAddress,
              languagePreferred,
            }),
          );
        }),
    );
  }

  private createShippingHomeAddressArray(shippingAddress: Address): Address[] {
    if (this.useHomeAddressForShipping) {
      return [
        {
          ...shippingAddress,
          addressType: AddressType.Home,
          country: this.Country,
        },
      ];
    }

    return [
      {
        ...this.addressFormGroup.get(this.HomeAddressFormGroup).value,
        addressType: AddressType.Home,
        country: this.Country,
      },
      {
        ...shippingAddress,
        addressType: AddressType.Shipping,
        country: this.Country,
      },
    ];
  }

  private selectShippingAddress(): Address {
    if (this.useHomeAddressForShipping) {
      return this.addressFormGroup.get(this.HomeAddressFormGroup).value;
    }
    return this.addressFormGroup.get(this.ShippingAddressFromGroup).value;
  }

  private listenValidationResult() {
    this.subscriptions.add(
      this.store$
        .select(selectAddressValidationModalData)
        .pipe(
          select((data) => data?.validationResult?.errors),
          filter((errors) => !!errors),
        )
        .subscribe((errors) => {
          this.isForbiddenState = !!errors.find((error) => {
            if (error.errorCode === AddressValidationErrors.ForbiddenState) {
              this.addressValidationResultErrorCode = error.errorCode;
              return true;
            }
            return false;
          });
        }),
    );
  }

  private listenAddressChangeAndResetForbiddenState() {
    this.subscriptions.add(
      this.addressFormGroup.valueChanges.pipe(take(1)).subscribe(() => {
        this.isForbiddenState = false;
        this.addressValidationResultErrorCode = '';
      }),
    );
  }
}
