import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecipeListSortOptions, RecipeListSortType } from '@core/enums/recipe-list-sort-type';
import { RecipeCategory } from '@core/store/recipe/recipe-state-models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-recipe-list-header',
  templateUrl: './recipe-list-header.component.html',
  styleUrls: ['./recipe-list-header.component.scss'],
})
export class RecipeListHeaderComponent implements OnInit, OnDestroy {
  @Output()
  sortChange: EventEmitter<RecipeListSortType>;

  @Input() recipeCategories: RecipeCategory[];

  sortOptions: { key: string; name: string }[];
  selectedCategoryName: string;
  selectedCategoryUrl: string;
  selectedSortOption = RecipeListSortOptions.NewestFirst;

  activatedRouteSubscription: Subscription;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.sortChange = new EventEmitter<RecipeListSortType>();
    this.sortOptions = Object.keys(RecipeListSortOptions).map((key) => ({
      key,
      name: RecipeListSortOptions[key],
    }));
    this.selectedCategoryName = $localize`All Recipes`;
    this.selectedCategoryUrl = '';
  }

  ngOnInit(): void {
    this.activatedRouteSubscription = this.activatedRoute.paramMap.subscribe((pMap) => {
      const categoryNameParam = pMap.get('category');
      const selectedCategory = this.recipeCategories?.find((x) => x.urlName == categoryNameParam);
      if (selectedCategory === undefined) {
        this.router.navigate(['/recipes-more/recipes']);
      } else {
        this.selectedCategoryUrl = selectedCategory.urlName;
        this.selectedCategoryName = selectedCategory.title;
      }
    });
  }

  ngOnDestroy(): void {
    this.activatedRouteSubscription.unsubscribe();
  }

  sortOrderClickHandler(key: RecipeListSortType) {
    this.selectedSortOption = RecipeListSortOptions[String(key)];
    this.sortChange.emit(key);
  }
}
