/* eslint-disable max-len */
import { CreateCardPaymentErrorType } from '@core/enums/create-card-payment-error-type.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PaymentCardType } from '@core/enums/payment-type.enum';
import { PhExceptionErrorType } from '@core/enums/ph-exception-error-type.enum';
import { StatusCodes } from 'http-status-codes';
import { GeneralPhErrorResponse } from '../common-store.model';

export interface PaymentState {
  customerId: string;
  partyOrderCustomerId: string;
  loading: boolean;
  paymentInfo: PaymentInfo;
  cardInfo: CardPaymentInfo;
  createdCardPaymentMethod?: CreatedCardPaymentMethod;
  hasSavedCard: boolean;
  paymentMethods: {
    loading: boolean;
    items: PaymentMethodItem[];
    createFailed: boolean;
  };
}

export interface PaymentMethodItem {
  id: string;
  cardName: string;
  cardNumber: string;
  cardType: string;
  expiryDate: string;
  isExpired: boolean;
  type: PaymentMethodType;
  provider: PaymentProviderType;
}

export enum PaymentMethodType {
  default = 'Default',
  other = 'Set Default',
}

export interface ClientTokenResponse {
  clientToken: string;
  customerId: string;
}

export interface PaymentInfo {
  /** Braintree nonce */
  nonce?: string | undefined;

  /** Nexio save card data */
  cardSaveRequest?: CardSaveRequest;

  /** PayPal */
  createPartyOrderPayPalOrder?: CreatePartyOrderPayPalOrder | undefined;

  /** The order could be SNA submitted order or PayPal order for Checkout and PartyOrder*/
  order?: CreatedOrder;

  firstFour?: string;
  lastFour?: string;
  email?: string;
  deviceData?: string;
  cardholderName?: string;
  expirationMonth?: string;
  expirationYear?: string;
  default?: boolean;
}

export interface CreatedCardPaymentMethod {
  id: number;
  message: string;
  /** In SNA sends CreateCardPaymentErrorType, but in EC Checkout and Party Order Payment sends PaymentMethodCreationFailedException */
  errorType: CreateCardPaymentErrorType | PhExceptionErrorType;
  responseCode: string;
  token: string;
  errors: PaymentError[];
  card: PaymentMethodCardInfo;
  account: PaymentMethodAccountInfo;
  status?: StatusCodes;
}

export interface CreatePartyOrderPayPalOrder {
  masterOrderId: number;
  amount: number;
}

export interface CreatePayPalPaymentMethod {
  payPalOrderId: string;
  customerId: string;
  amount: string;
  deviceData: string;
  provider: PaymentProviderType;
}

export interface CardPaymentInfo {
  clientToken?: string;
  cardType?: PaymentCardType;
}

export interface CreatedVoucherPaymentMethod extends Partial<GeneralPhErrorResponse> {
  applicationRegistrationId: number;
  vouchers: Voucher[];
}

export interface Voucher {
  token?: string;
  voucherUrl?: string;
}

export interface PaymentError {
  attribute: string;
  code: string;
  message: string;
}

export interface PaymentMethodCardInfo {
  cardholderName: string;
  lastFour: string;
  firstFour: string;
  maskedNumber: string;
  cardType: string;
  expirationDate: string;
  isExpired: boolean;
  expirationYear: number;
  expirationMonth: number;
  createdAt: Date;
  isDefault: boolean;
}

export interface PaymentMethodAccountInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export interface CreatedOrder {
  orderId: string;
}

export interface CardSaveRequest {
  cardSaved?: CardSavedEvent;
  error?: CardSavedErrorEvent;
}

export interface CardSavedEvent {
  card?: CardSavedEventCard;
  cardType?: string;
  data?: CardSavedEventData;
  merchantId?: string;
  shouldUpdateCard?: boolean;
  token?: CardToken;
  avsResult?: AvsResults;
  cvcResult?: CvcResults;
}

export interface CardSavedErrorEvent {
  error?: number;
  message?: string;
  merchantId?: string;
  cvcResults?: CvcResults;
  avsResults?: AvsResults;
}

export interface CardSavedEventCard {
  cardHolderName?: string;
  cardType?: string;
  expirationMonth?: number;
  expirationYear?: number;
}
export interface CardSavedEventData {
  customer?: PaymentCustomer;
}

export interface CardToken {
  cardType?: string;
  firstSix?: string;
  lastFour?: string;
  token?: string;
}

export interface CvcResults {
  matchCvv?: boolean;
  error?: boolean;
  gatewayMessage?: GatewayMessage;
}

export interface AvsResults {
  matchAddress?: boolean;
  matchPostal?: boolean;
  error?: boolean;
  gatewayMessage?: GatewayMessage;
}

export interface GatewayMessage {
  cvvresponse?: string;
  avsresponse?: string;
  message?: string;
}

export interface PaymentCustomer {
  billToAddressOne?: string;
  billToAddressTwo?: string;
  billToCity?: string;
  billToCountry?: string;
  billToPhone?: string;
  billToPostal?: string;
  billToState?: string;
  birthDate?: string;
  createdAtDate?: string;
  customerRef?: string;
  email?: string;
  firstName?: string;
  invoice?: string;
  lastName?: string;
  orderDate?: string;
  orderNumber?: string;
  phone?: string;
  shipToAddressOne?: string;
  shipToAddressTwo?: string;
  shipToCity?: string;
  shipToCountry?: string;
  shipToPhone?: string;
  shipToPostal?: string;
  shipToState?: string;
}
