import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DEFAULT_PAGE_DESCRIPTION, DEFAULT_PAGE_TITLE } from '@core/constants/page-title';

@Component({
  selector: 'app-page-title',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent implements OnChanges, OnDestroy {
  @Input() title: string | string[];
  @Input() delimiter: string = ', ';
  @Input() defaultPageTitle = DEFAULT_PAGE_TITLE;
  @Input() description: string;
  @Input() defaultPageDescription = DEFAULT_PAGE_DESCRIPTION;

  constructor(
    private titleService: Title,
    private meta: Meta,
  ) {}

  ngOnChanges(): void {
    if (this.title) {
      Array.isArray(this.title)
        ? this.titleService.setTitle(this.title.join(this.delimiter))
        : this.titleService.setTitle(this.title);
    }

    if (this.description) {
      this.meta.updateTag({ name: 'description', content: this.description });
    }
  }

  ngOnDestroy(): void {
    this.titleService.setTitle(this.defaultPageTitle);
    this.meta.updateTag({ name: 'description', content: this.defaultPageDescription });
  }
}
