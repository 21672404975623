<ng-container *ngIf="consultantParties?.length">
  <form [formGroup]="partyFormGroup">
    <div class="row justify-content-center mt-3">
      <div
        [ngClass]="{
          'col d-flex justify-content-center': true,
          'btn-group': (isMobile$ | async) === false,
          'btn-group-vertical': (isMobile$ | async) === true
        }"
      >
        <div class="d-flex border border-dark">
          <div class="d-flex align-items-center h5 py-2 my-0 icon px-3">
            <i class="bi bi-person-circle"></i>
          </div>
          <div class="d-md-flex bg-light font-weight-bold align-items-center py-2 px-3">
            <span i18n>
              Would you like this order to be credit to a host of a specific party?
            </span>
            <div class="ml-md-3 d-flex align-items-center justify-content-around">
              <label ngbButtonLabel class="my-0 px-0 shadow-none">
                <input
                  class="radio-input"
                  type="radio"
                  [id]="PartyFormKeys.isOrderCreditToHostParty + '_yes'"
                  [name]="PartyFormKeys.isOrderCreditToHostParty"
                  [formControlName]="PartyFormKeys.isOrderCreditToHostParty"
                  (click)="yesClick()"
                  [value]="true"
                />
                <span i18n>Yes</span>
              </label>
              <label ngbButtonLabel class="my-0 ml-2 px-0 shadow-none">
                <input
                  class="radio-input"
                  type="radio"
                  [id]="PartyFormKeys.isOrderCreditToHostParty + '_no'"
                  [name]="PartyFormKeys.isOrderCreditToHostParty"
                  [formControlName]="PartyFormKeys.isOrderCreditToHostParty"
                  (click)="noClick()"
                  [value]="false"
                />
                <span i18n>No</span>
              </label>
            </div>
          </div>
        </div>
        <div
          *ngIf="showSpecificPartyDropdown"
          class="btn-group"
          ngbDropdown
          role="group"
          aria-label="Button group with nested dropdown"
        >
          <button type="button" class="btn btn-outline-primary" ngbDropdownToggle>{{
            (selectedParty$ | async)?.partyName || DefaultDropdownText
          }}</button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button
              *ngFor="let party of consultantParties"
              ngbDropdownItem
              [ngClass]="{ selected: (selectedParty$ | async)?.partyName === party.partyName }"
              (click)="partyClickHandler(party)"
            >
              {{ party.partyName }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-1">
      <div class="col d-flex justify-content-center">
        <div
          *ngIf="!validInput(partyFormGroup, PartyFormKeys.isOrderCreditToHostParty)"
          class="validation-error-message"
        >
          <span>{{
            getValidationError(partyFormGroup, PartyFormKeys.isOrderCreditToHostParty)
          }}</span>
        </div>
        <div
          *ngIf="!validInput(partyFormGroup, PartyFormKeys.specificParty)"
          class="validation-error-message"
        >
          <span>{{ getValidationError(partyFormGroup, PartyFormKeys.specificParty) }}</span>
        </div>
      </div>
    </div>
  </form>
</ng-container>
