import { InfoCardAttributes } from '@core/models/info-card-attributes.model';
import { Address } from '@core/store/address/address-state-models';
import { BillingAddressModel, PersonalInfo } from '@core/store/checkout/checkout-state-models';
import { Party } from '@core/store/consultant/consultant-state-models';
import { OrderAddress, OrderCustomer } from '@core/store/order/order-state-models';
import { UserModel } from '@core/store/user/user-state-models';

export function createPersonalInfoCard(
  billingCustomer: UserModel | OrderCustomer,
  readOnly: boolean,
): InfoCardAttributes {
  return {
    title: $localize`Personal information`,
    name: `${billingCustomer.firstName} ${billingCustomer.lastName}`,
    email: billingCustomer.email,
    phone: billingCustomer.phoneNumber,
    readOnly,
  };
}

export function createShippingInfoCard(personalInfo: PersonalInfo, readOnly): InfoCardAttributes {
  const title = $localize`Shipping information`;
  return createAddressCard(
    personalInfo.shippingAddress.address,
    personalInfo.shippingAddress.firstName,
    personalInfo.shippingAddress.lastName,
    title,
    readOnly,
    personalInfo.shippingAddress.phoneNumber,
  );
}

// TODO: PHS-2439 - Use billing address form store
export function createBillingInfoCard(
  billingInfo: BillingAddressModel,
  readOnly: boolean,
): InfoCardAttributes {
  const title = $localize`Billing information`;
  return createAddressCard(
    billingInfo.address,
    billingInfo.firstName,
    billingInfo.lastName,
    title,
    readOnly,
  );
}

export function createAddressCardFromOrderAddress(
  address: OrderAddress,
  title: string,
): InfoCardAttributes {
  return createAddressCard(
    {
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      addressLine3: address.addressLine3,
      city: address.city,
      state: address.state,
      zipCode: address.zipCode,
    } as Address,
    address.firstName,
    address.lastName,
    title,
    true,
  );
}

export function createPartyInfoCard(consultantName: string, party: Party): InfoCardAttributes {
  const title = party ? $localize`Party information` : $localize`Consultant information`;
  if (party?.hostName) {
    return {
      title,
      consultant: consultantName,
      host: party.hostName,
      readOnly: true,
    };
  }
  return { consultant: consultantName, title };
}

export function createConsultantInfoCard(
  consultantFirstName: string,
  consultantLastName: string,
): InfoCardAttributes {
  return { consultant: consultantFirstName + ' ' + consultantLastName };
}

export function createHostInfoCard(host: string): InfoCardAttributes {
  return { host: host };
}

function createAddressCard(
  address: Address,
  firstName: string,
  lastName: string,
  title: string,
  readOnly: boolean,
  phoneNumber: string = '',
): InfoCardAttributes {
  const addressLine = createAddressLine(
    address.addressLine1,
    address.addressLine2,
    address.addressLine3,
  );
  return {
    title: `${title}`,
    name: `${firstName} ${lastName}`,
    phone: phoneNumber,
    addressLine1: `${addressLine},`,
    addressLine2: `${address.city}, ${address.state} ${address.zipCode}`,
    readOnly,
  };
}

function createAddressLine(
  addressLine1: string,
  addressLine2: string,
  addressLine3: string,
): string {
  let addressLine: string = addressLine1;
  if (!!addressLine2) {
    addressLine = `${addressLine} ${addressLine2}`;
  }
  if (!!addressLine3) {
    addressLine = `${addressLine} ${addressLine3}`;
  }
  return addressLine;
}
