import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { RecipeDetailsResponse, RecipesResponse } from '@core/dto/recipe.dto';
import { RecipeCategoriesResponse } from '@core/dto/tag.dto';
import { Locale } from '@core/enums/locale';
import { LoadRecipesProps } from '@core/models/load-recipes.model';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecipeService {
  baseUrl: string = environment.ecommerceBackendUri;
  culture: Locale;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {
    this.culture = getCulture(localeId);
  }

  fetchRecipes(payload: LoadRecipesProps): Observable<RecipesResponse> {
    const date = moment.utc();
    return this.http.get<RecipesResponse>(this.baseUrl + 'api/recipe', {
      params: new HttpParams({
        fromObject: {
          pageNumber: payload.pageNumber.toString(),
          pageSize: payload.pageSize.toString(),
          date: date.toString(),
          categoryUrl: payload.categoryUrl,
          ...(payload.sortType ? { sortType: String(payload.sortType) } : {}),
          culture: this.culture,
        },
      }),
    });
  }

  fetchRecipeCategories(): Observable<RecipeCategoriesResponse> {
    return this.http.get<RecipeCategoriesResponse>(this.baseUrl + 'api/recipe/categories', {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }

  fetchRelatedRecipes(sku: string, date: string = null): Observable<RecipesResponse> {
    return this.http.get<RecipesResponse>(`${this.baseUrl}api/recipe/recommendation/${sku}`, {
      params: new HttpParams({ fromObject: { date, culture: this.culture } }),
    });
  }

  fetchRecipeRecommendations(recipeId: string): Observable<RecipesResponse> {
    return this.http.get<RecipesResponse>(`${this.baseUrl}api/recipe/${recipeId}/similar`, {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }

  fetchRecipeDetails(url: string, date: string = null): Observable<RecipeDetailsResponse> {
    return this.http.get<RecipeDetailsResponse>(`${this.baseUrl}api/recipe/${url}`, {
      params: new HttpParams({
        fromObject: { date, culture: this.culture },
      }),
    });
  }

  likeRecipe(id: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.baseUrl}api/recipe/${id}/like`,
      {},
      {
        params: new HttpParams({
          fromObject: { culture: this.culture },
        }),
      },
    );
  }

  dislikeRecipe(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}api/recipe/${id}/like`, {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }

  fetchUserRecipes(): Observable<RecipesResponse> {
    return this.http.get<RecipesResponse>(`${this.baseUrl}api/recipe/likes`, {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }
}
