import {
  Component,
  EventEmitter,
  Injector,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsultantLocatorErrorMessage } from '@core/constants/consultant-locator-error-message';
import { FindConsultantByZipCodeRequest } from '@core/dto/start-now-app.dto';
import { ConsultantLocatorErrorType } from '@core/enums/consultant-locator-error-type.enum';
import { LanguagePreferenceType } from '@core/enums/language-preference-type.enum';
import { SuccessfulStatus } from '@core/enums/successful-status.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import {
  selectConsultantFinderStep,
  selectReCaptchaIsValid,
  selectStepProcessing,
} from '@core/store/start-now-app';
import { ConsultantFinder } from '@core/store/start-now-app/start-now-app-state-models';
import {
  findConsultantByZipCode,
  resetFindConsultantByZipCodeErrorType,
  resetReCaptchaValidation,
} from '@core/store/start-now-app/start-now-app.actions';
import { select, Store } from '@ngrx/store';
import { getFormValidationErrorMessage, validInput } from '@shared/utils/validation.utils';
import { Observable } from 'rxjs/internal/Observable';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { SnaReCaptchaComponent } from '../../sna-recaptcha/sna-recaptcha.component';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-find-consultant-by-zip-code',
  templateUrl: './find-consultant-by-zip-code.component.html',
  styleUrls: ['./find-consultant-by-zip-code.component.scss'],
})
export class FindConsultantByZipCodeComponent
  extends StartNowStepBaseComponent
  implements OnInit, OnDestroy
{
  @Output() knowConsultantChoice: EventEmitter<boolean> = new EventEmitter();

  readonly LanguagePreferenceType = LanguagePreferenceType;
  readonly SuccessfulStatus = SuccessfulStatus;
  readonly FindConsultantFormKeys = {
    zipCode: 'zipCode',
    languagePreference: 'languagePreference',
  };
  readonly ConsultantLocatorErrorMessage = ConsultantLocatorErrorMessage;

  noConsultantFound: boolean = false;
  stepProcessing$: Observable<boolean>;
  findConsultantByZipCodeError$: Observable<ConsultantLocatorErrorType>;
  consultantFinderResult$: Observable<ConsultantFinder>;
  reCaptchaValidation$: Observable<SuccessfulStatus>;
  findConsultantByZipCodeForm: FormGroup;

  getFormValidationErrorMessage = getFormValidationErrorMessage;

  @ViewChild('reCaptcha') private reCaptcha: SnaReCaptchaComponent;

  constructor(
    private store$: Store<AppState>,
    private fb: FormBuilder,
    private appInitService: AppInitService,
    injector: Injector,
  ) {
    super(injector, 'SNA Step - 0.2 Find a Consultant by Zip Code');
  }

  ngOnInit(): void {
    this.store$.dispatch(resetReCaptchaValidation());
    this.stepProcessing$ = this.store$.select(selectStepProcessing).pipe(distinctUntilChanged());
    this.consultantFinderResult$ = this.store$
      .select(selectConsultantFinderStep)
      .pipe(select((consultantFinderStep) => consultantFinderStep?.consultantFinderResult));
    this.findConsultantByZipCodeError$ = this.store$
      .select(selectConsultantFinderStep)
      .pipe(select((finderStep) => finderStep.findConsultantByZipCodeError));

    this.findConsultantByZipCodeForm = this.createFormGroup();

    this.listenConsultantFinder();
    this.listenReCaptchaValidation();
    this.listenFormChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.store$.dispatch(resetFindConsultantByZipCodeErrorType());
  }

  submitStep(): void {
    const zipCode = this.findConsultantByZipCodeForm.get(this.FindConsultantFormKeys.zipCode).value;

    const languagePreference = this.findConsultantByZipCodeForm.get(
      this.FindConsultantFormKeys.languagePreference,
    ).value;

    this.findConsultantByZipCode(zipCode, languagePreference);
  }

  listenFormChanges() {
    this.subscriptions.add(
      this.findConsultantByZipCodeForm.valueChanges.subscribe(() => {
        this.formChanged();
      }),
    );
  }

  formChanged() {
    this.noConsultantFound = false;
  }

  get radioButtonValidInput(): boolean {
    return validInput(
      this.findConsultantByZipCodeForm.get(this.FindConsultantFormKeys.languagePreference),
    );
  }

  public previousStepClick(): void {
    this.goToPreviousStep.emit();
  }

  public nextStepClick(): void {
    this.findConsultantByZipCodeForm.markAllAsTouched();

    if (this.findConsultantByZipCodeForm.valid) {
      if (this.appInitService.Settings.sna.isReCaptchaEnabled) {
        this.reCaptcha.validateReCaptcha();
      } else {
        this.submitStep();
      }
    }
  }

  protected createFormGroup(): FormGroup {
    return this.fb.group({
      [this.FindConsultantFormKeys.zipCode]: ['', [Validators.required]],
      [this.FindConsultantFormKeys.languagePreference]: ['', [Validators.required]],
    });
  }

  private findConsultantByZipCode(zipCode: string, languagePreference: string): void {
    const findConsultantByZipCodeRequest: FindConsultantByZipCodeRequest = {
      zipCode: zipCode,
      preferredLanguageCode: languagePreference,
      pageNumber: 1,
      pageSize: 3,
    };
    this.store$.dispatch(findConsultantByZipCode({ request: findConsultantByZipCodeRequest }));
  }

  private listenConsultantFinder(): void {
    this.subscriptions.add(
      this.consultantFinderResult$
        .pipe(
          tap(({ items, hasMore }) => {
            if (!hasMore && items.length === 0) {
              this.noConsultantFound = true;
            }
          }),
          filter((res) => !!res?.items?.length),
        )
        .subscribe(() => {
          this.goToNextStep.emit();
        }),
    );
  }

  private listenReCaptchaValidation(): void {
    this.reCaptchaValidation$ = this.store$.select(selectReCaptchaIsValid);

    this.subscriptions.add(
      this.reCaptchaValidation$
        .pipe(filter((validateSuccessful) => validateSuccessful === SuccessfulStatus.Success))
        .subscribe(() => this.submitStep()),
    );
  }
}
