import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NexioEventType } from '@core/enums/nexio-event-types.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { NexioDropinUi } from '@core/models/dropin-instance.model';
import { LoggerService } from '@core/services/logger.service';
import {
  CardSavedErrorEvent,
  CardSavedEvent,
  PaymentInfo,
} from '@core/store/payment/payment-state-models';
import { environment } from '@env';
import { CardPaymentProviderBaseComponent } from '@payment/components/card-payment-provider-base-component';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nexio',
  templateUrl: './nexio.component.html',
  styleUrls: ['./nexio.component.scss'],
})
export class NexioComponent
  extends CardPaymentProviderBaseComponent<NexioDropinUi>
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @ViewChild('NexioCardIframe') nexioIframe: ElementRef<HTMLElement>;
  readonly NexioFrameId: string = 'nexio-frame-element-id';
  readonly NexioFrameWidth: string = '100%';
  readonly NexioFrameHeight: number = 500;
  public readonly NexioFrameBorder = 0;

  private readonly IframeSaveCardUrl: string = `${environment.nexioPaymentApiUri}/pay/v3/saveCard`;
  private readonly ReturnHtml: string = '&shouldReturnHtml=true';
  private readonly NexioInitTimeoutMilliseconds: number = 500;

  constructor(
    public loggerService: LoggerService,
    private toastr: ToastrService,
  ) {
    super(loggerService);
  }

  ngOnInit(): void {
    this.loading.emit(true);
  }

  ngAfterViewInit(): void {
    this.subscriptions.add();
    this.initDropinUiByToken(
      PaymentProviderType.Nexio,
      this.cardholderName?.firstName,
      this.cardholderName?.lastName,
    );
  }

  get dropInInstance(): NexioDropinUi {
    return this._dropInInstance as NexioDropinUi;
  }

  get dropInContainer(): ElementRef<HTMLElement> {
    return this.nexioIframe;
  }

  @HostListener('window:message', ['$event'])
  nexioIframeChangeListener(event: any): void {
    if (event.origin === environment.nexioPaymentApiUri) {
      const eventType = event.data.event;
      const eventData = event.data.data;
      let paymentInfo: PaymentInfo | undefined;
      switch (eventType) {
        case NexioEventType.Loaded:
          this.loading.emit(false);
          break;
        case NexioEventType.ErrorEvent:
          const cardSavedError = eventData as CardSavedErrorEvent;
          this.loggerService.error(`Nexio card payment error: ${cardSavedError?.message}`);

          paymentInfo = {
            cardSaveRequest: {
              cardSaved: null,
              error: cardSavedError,
            },
          } as PaymentInfo;
          this.paymentHandler.handleRequestedPaymentMethodObject(null, paymentInfo);
          this.requestPaymentSuccessSubject.next(false);
          break;

        case NexioEventType.FromValidationEvent:
          this.isPaymentMethodRequestable.emit(eventData?.isFormValid);
          break;

        case NexioEventType.CardSavedEvent:
          const cardSaved = eventData as CardSavedEvent;
          paymentInfo = {
            cardSaveRequest: {
              cardSaved: cardSaved,
              error: null,
            },
            cardholderName: cardSaved.card.cardHolderName,
            firstFour: cardSaved.token.firstSix.substring(0, 4),
            lastFour: cardSaved.token.lastFour,
            expirationMonth: String(cardSaved.card.expirationMonth),
            expirationYear: String(cardSaved.card.expirationYear),
          } as PaymentInfo;
          this.paymentHandler.handleRequestedPaymentMethodObject(null, paymentInfo);
          this.requestPaymentSuccessSubject.next(true);
          break;
      }
    }
  }

  initDropinInstance(token: string): void {
    this.initIframeSource(token);
  }

  requestPaymentMethodObject(): Observable<boolean> {
    this.submitNexioIframe();
    return this.requestPaymentSuccessSubject.asObservable();
  }

  resetToken(cardholderFirstName: string = '', cardholderLastName: string = ''): void {
    this.loading.emit(true);
    this.paymentHandler.resetToken();
    this.cleanIframeSource();
    this.initDropinUiByToken(PaymentProviderType.Nexio, cardholderFirstName, cardholderLastName);
  }

  public getCardPaymentInputs(): Array<HTMLElement> | undefined {
    const iframe = this.nexioIframe?.nativeElement;
    if (iframe !== undefined) return [iframe];
  }

  private initIframeSource(token: string): void {
    if (!!token) {
      const oneTimeUseToken = `?token=${token}`;
      const url = this.IframeSaveCardUrl + oneTimeUseToken + this.ReturnHtml;

      setTimeout(() => {
        if (!!this.nexioIframe) {
          const htmlIframe = this.nexioIframe.nativeElement as HTMLIFrameElement;
          htmlIframe.src = url;
          this._dropInInstance = htmlIframe;
          this.dropInComponentInitialized.emit(this);
        } else {
          this.loggerService.error('Nexio: Not initialized Iframe!');
        }
      }, this.NexioInitTimeoutMilliseconds);
    } else {
      this.loggerService.error('Nexio: Not initialized token');
    }
  }

  private cleanIframeSource(): void {
    const htmlIframe = this.nexioIframe.nativeElement as HTMLIFrameElement;
    htmlIframe.src = 'about:blank';
  }

  private submitNexioIframe(): void {
    this.dropInInstance.contentWindow?.postMessage('posted', environment.nexioPaymentApiUri);
  }
}
