import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  CreateProductReviewRequest,
  FetchProductReviewRequest,
  FetchProductReviewResponse,
  ProductReviewResponse,
} from '@core/dto/product-review.dto';
import { Locale } from '@core/enums/locale';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { removeNilProperties } from '@shared/utils/object-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductReviewService {
  baseUrl: string = environment.ecommerceBackendUri;
  culture: Locale;

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private localeId: Locale,
  ) {
    this.culture = getCulture(localeId);
  }

  fetchProductReviews(request: FetchProductReviewRequest): Observable<FetchProductReviewResponse> {
    const { sku, ...params } = request;
    return this.http.get<FetchProductReviewResponse>(`${this.baseUrl}api/product/${sku}/reviews`, {
      params: new HttpParams({
        fromObject: removeNilProperties({
          ...params,
          pageNumber: params.pageNumber?.toString(),
          pageSize: params.pageSize.toString(),
        }),
      }),
    });
  }

  createProductReview(request: CreateProductReviewRequest): Observable<ProductReviewResponse> {
    return this.http.post<ProductReviewResponse>(`${this.baseUrl}api/product/review`, request, {
      params: new HttpParams({
        fromObject: { culture: this.culture },
      }),
    });
  }
}
