import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CheckoutPages } from '@core/enums/checkout-page-id.enum';
import { AppState } from '@core/store';
import { resetCheckoutState } from '@core/store/checkout/checkout.actions';
import { resetSubmittedOrderState } from '@core/store/order/order.actions';
import { resetCardPaymentInfo, resetPaymentInfo } from '@core/store/payment/payment.actions';
import { resetVoucherStore } from '@core/store/voucher/voucher.actions';
import { Store } from '@ngrx/store';
import { NavigationStep } from '@shared/components/stepper-base/navigation-step.model';
import { scrollToTop } from '@shared/utils/scroll-utils';
import { CheckoutPaymentComponent } from './checkout-payment/checkout-payment.component';
import { CheckoutPersonalInfoComponent } from './checkout-personal-info/checkout-personal-info.component';

// TODO: could use StepperBaseComponent

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss'],
})
export class CheckoutPageComponent implements OnInit, OnDestroy {
  readonly titlePrefix = $localize`Checkout`;
  readonly CheckoutPages = CheckoutPages;

  activeNavId: number;
  navigationStep: NavigationStep[];
  activeStepTitle: string;
  nextStepTitle: string;

  @ViewChild(CheckoutPersonalInfoComponent) private personalInfo: CheckoutPersonalInfoComponent;
  @ViewChild(CheckoutPaymentComponent) private payment: CheckoutPaymentComponent;

  constructor(private store$: Store<AppState>) {}

  navigateTo(stepId: number): void {
    scrollToTop();
    this.activeNavId = stepId;
    this.changeStepTitles(stepId);
  }

  ngOnInit(): void {
    scrollToTop();
    this.navigationStep = CheckoutPageComponent.initNavigationStep();
    this.activeNavId = CheckoutPages.PersonaInfo;
    this.changeStepTitles(CheckoutPages.PersonaInfo);
  }

  ngOnDestroy(): void {
    this.store$.dispatch(resetPaymentInfo());
    this.store$.dispatch(resetSubmittedOrderState());
    this.store$.dispatch(resetCardPaymentInfo());
    this.store$.dispatch(resetVoucherStore());
    this.store$.dispatch(resetCheckoutState());
  }

  activeIdChange(activeId: number) {
    switch (this.activeNavId) {
      case CheckoutPages.PersonaInfo:
        this.personalInfo.next(activeId);
        break;
      case CheckoutPages.Payment:
        this.payment.next(activeId);
        break;
      default:
        this.activeNavId = activeId;
        break;
    }
  }

  private static initNavigationStep(): NavigationStep[] {
    // TODO: the titles are duplicated, because the components are also defining them
    return [
      { id: CheckoutPages.PersonaInfo, title: $localize`Personal info` },
      { id: CheckoutPages.OrderSummary, title: $localize`Order Summary` },
      { id: CheckoutPages.Payment, title: $localize`Payment` },
      { id: CheckoutPages.OrderConfirmation, title: $localize`Order Confirmation` },
    ].sort((a, b) => a.id - b.id);
  }

  private changeStepTitles(stepId: number): void {
    this.activeStepTitle = this.navigationStep.find((navStep) => navStep.id === stepId).title;
    this.nextStepTitle = this.navigationStep.find((navStep) => navStep.id === stepId + 1)?.title;
  }
}
