import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { B2cSigninComponent } from './components/b2c-signin/b2c-signin.component';
import { LoginComponent } from './components/login/login.component';

@NgModule({
  declarations: [LoginComponent, B2cSigninComponent],
  imports: [CommonModule, RouterModule, AuthRoutingModule, ReactiveFormsModule, SharedModule],
  exports: [LoginComponent],
})
export class AuthModule {}
