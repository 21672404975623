import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from '@core/services/auth.service';
import { environment } from '@env';

import { Observable, from, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HttpBearerTokenInterceptor implements HttpInterceptor {
  constructor(
    private msalService: MsalService,
    private authService: AuthService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.msalService.instance.getAllAccounts().length > 0 && this.isBackendRequest(req)) {
      const accessTokenRequest = {
        scopes: [environment.b2c.customerScope],
        account: this.msalService.instance.getAllAccounts()[0],
      };
      return from(this.msalService.instance.acquireTokenSilent(accessTokenRequest)).pipe(
        first(),
        catchError(() => {
          this.authService.logOut();
          return throwError('Failed to get access token');
        }),
        switchMap((result) => {
          const authReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${result.accessToken}`,
            },
          });
          return next.handle(authReq);
        }),
      );
    }

    return next.handle(req);
  }

  private isBackendRequest(request: HttpRequest<any>): boolean {
    return (
      request.url.includes('api') &&
      (request.url.includes(environment.ecommerceBackendUri) ||
        request.url.includes(environment.startNowAppBackendUri))
    );
  }
}
