/* eslint-disable max-len */
import { Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlagImageSrc } from '@core/constants/image-placeholders';
import { FindConsultantByCodeRequest } from '@core/dto/start-now-app.dto';
import { SnaConsultantFinderType } from '@core/enums/consultant-finder-type.enum';
import { Country } from '@core/enums/country.enum';
import { SuccessfulStatus } from '@core/enums/successful-status.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import {
  selectConsultantFinderStep,
  selectReCaptchaIsValid,
  selectSnaCountryStates,
  selectStepProcessing,
} from '@core/store/start-now-app';
import { ConsultantFinder } from '@core/store/start-now-app/start-now-app-state-models';
import {
  fetchCountryStates,
  findConsultantByArea,
  findConsultantByConsultantCode,
  resetFindConsultant,
  resetNoFindConsultantModal,
  resetReCaptchaValidation,
  updateConfirmRecruiter,
  updateRegistrationCodeStepSkipped,
} from '@core/store/start-now-app/start-now-app.actions';
import { environment } from '@env';
import { select, Store } from '@ngrx/store';
import { SelectOption } from '@shared/components/select/select.component';
import { countryStatesToSelectOptions } from '@shared/utils/address-utils';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { SnaReCaptchaComponent } from '../../sna-recaptcha/sna-recaptcha.component';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-select-consultant-step',
  templateUrl: './select-consultant-step.component.html',
  styleUrls: ['./select-consultant-step.component.scss'],
})
export class SelectConsultantStepComponent
  extends StartNowStepBaseComponent
  implements OnInit, OnDestroy
{
  @Input() doIKnowAConsultant: boolean;

  stepProcessing$: Observable<boolean>;
  reCaptchaValidation$: Observable<SuccessfulStatus>;
  public title: string;
  public selectedCountry: Country = environment.country;
  countryStates$: Observable<SelectOption[]>;
  invitationCodeForm: FormGroup;
  findConsultantByLocationForm: FormGroup;
  consultantFinderType = SnaConsultantFinderType;
  submitFinderType: SnaConsultantFinderType;
  activeNavId: number = SnaConsultantFinderType.InvitationCode;
  consultantFinderResult$: Observable<ConsultantFinder>;
  noConsultantFindModalOpen$: Observable<boolean>;
  findIsSuccess$: Observable<boolean>;
  Country = Country;
  tooManyResult: boolean = false;
  isEmptyForm: boolean = false;

  readonly EnvironmentCountry: Country = environment.country;
  readonly SuccessfulStatus = SuccessfulStatus;
  readonly FlagImageSrc = FlagImageSrc;
  readonly InvitationCode: string = 'invitationCode';
  readonly ByArea: string = 'byArea';
  readonly FirstName: string = 'firstName';
  readonly LastName: string = 'lastName';
  readonly City: string = 'city';
  readonly State: string = 'state';
  readonly PhoneNumber: string = 'phoneNumber';
  readonly Title =
    environment.country === Country.Usa
      ? $localize`Find your consultant`
      : $localize`Your consultant’s country`;

  readonly SubTitles = {
    [this.ByArea]:
      environment.country === Country.Usa
        ? $localize`Please search for your Consultant by name and/or City`
        : $localize`Please select your Consultant’s country and search one by name and/or City`,
    [this.InvitationCode]:
      environment.country === Country.Usa
        ? $localize`Please enter the application invitation code provided by your Consultant to continue.`
        : $localize`Please select your Consultant’s country and enter the application invitation code
      provided by your Consultant to continue.`,
  };

  protected subscriptions: Subscription = new Subscription();
  @ViewChild('reCaptcha') private reCaptcha: SnaReCaptchaComponent;

  constructor(
    private store$: Store<AppState>,
    private fb: FormBuilder,
    private appInitService: AppInitService,
    injector: Injector,
  ) {
    super(injector, 'SNA Step - 0.2 Select Consultant');
  }

  ngOnInit(): void {
    this.store$.dispatch(resetReCaptchaValidation());
    this.stepProcessing$ = this.store$.select(selectStepProcessing).pipe(distinctUntilChanged());
    this.store$.dispatch(fetchCountryStates());
    this.countryStates$ = countryStatesToSelectOptions(this.store$.select(selectSnaCountryStates));
    this.initializeForms();
    this.findIsSuccess$ = this.store$
      .select(selectConsultantFinderStep)
      .pipe(select((consultantFinder) => consultantFinder?.findIsSuccess));
    this.consultantFinderResult$ = this.store$
      .select(selectConsultantFinderStep)
      .pipe(select((consultantFinderStep) => consultantFinderStep?.consultantFinderResult));
    this.noConsultantFindModalOpen$ = this.store$
      .select(selectConsultantFinderStep)
      .pipe(select((consultantFinderStep) => consultantFinderStep?.noConsultantFinderModalOpen));
    this.title = this.SubTitles[this.selectedCountry];
    this.listenConsultantFinder();
    this.store$.dispatch(updateConfirmRecruiter({ confirmRecruiter: false }));
    this.listenReCaptchaValidation();
    this.listenFindValidator();
  }

  public submitStep(): void {
    this.goToNextStep.emit();
  }

  public previousStepClick(): void {
    this.goToPreviousStep.emit();
  }

  public nextStepClick(): void {
    if (
      this.selectedCountry !== this.EnvironmentCountry ||
      this.activeNavId === this.consultantFinderType.InvitationCode
    ) {
      this.invitationCodeForm.markAllAsTouched();
      if (this.invitationCodeForm.valid) {
        this.validateReCaptcha(SnaConsultantFinderType.InvitationCode);
      }
    } else {
      this.isEmptyForm = this.isAreaFormEmpty();

      if (!this.isEmptyForm) {
        this.validateReCaptcha(SnaConsultantFinderType.ByArea);
      }
      this.listenLocatorFormChanges();
    }
  }

  onDismissModal = (): void => {
    this.store$.dispatch(resetNoFindConsultantModal());
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onCountryChange(value: Country): void {
    this.selectedCountry = value;
    this.store$.dispatch(resetFindConsultant());
  }

  protected createFormGroup(): FormGroup {
    throw new Error('Method not implemented.');
  }

  private isAreaFormEmpty(): boolean {
    let isEmptyForm: boolean = true;
    Object.keys(this.findConsultantByLocationForm.controls).forEach((key) => {
      isEmptyForm = isEmptyForm && !this.findConsultantByLocationForm.get(key).value;
    });
    return isEmptyForm;
  }

  private dispatchConsultantFind(): void {
    if (this.submitFinderType === SnaConsultantFinderType.InvitationCode) {
      const invitationCodeRequest: FindConsultantByCodeRequest = {
        vanityName: this.invitationCodeForm.get(this.InvitationCode).value,
        country: this.selectedCountry,
      };

      this.store$.dispatch(findConsultantByConsultantCode({ request: invitationCodeRequest }));
    } else if (this.submitFinderType === SnaConsultantFinderType.ByArea) {
      const formValues = this.findConsultantByLocationForm.value;

      this.store$.dispatch(
        findConsultantByArea({
          consultantFinder: {
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            city: formValues.city,
            state: formValues.state,
            mobilePhoneNumber: formValues.phoneNumber,
            pageNumber: 1,
            pageSize: 3,
            country: this.selectedCountry,
          },
        }),
      );
    }
  }

  private listenConsultantFinder(): void {
    this.subscriptions.add(
      this.consultantFinderResult$.pipe(filter((res) => !!res?.items?.length)).subscribe((res) => {
        const formValue = this.findConsultantByLocationForm.value;
        if (
          this.activeNavId !== this.consultantFinderType.InvitationCode &&
          !formValue.city &&
          !formValue.phoneNumber &&
          !formValue.state &&
          res.hasMore
        ) {
          this.tooManyResult = true;
          this.store$.dispatch(resetFindConsultant());
        } else {
          if (this.activeNavId === this.consultantFinderType.InvitationCode) {
            const registrationCode: string = this.invitationCodeForm.get(this.InvitationCode).value;
            this.store$.dispatch(
              updateRegistrationCodeStepSkipped({
                skipRegistrationCodeStep: true,
                registrationCode,
              }),
            );
          }
          this.submitStep();
        }
      }),
    );
  }

  private listenLocatorFormChanges(): void {
    this.subscriptions.add(
      this.findConsultantByLocationForm.valueChanges.pipe(take(1)).subscribe(() => {
        this.tooManyResult = false;
        this.isEmptyForm = false;
      }),
    );
  }

  private initializeForms() {
    this.invitationCodeForm = this.fb.group({
      [this.InvitationCode]: ['', Validators.required],
    });

    this.findConsultantByLocationForm = this.fb.group({
      [this.FirstName]: [''],
      [this.LastName]: [''],
      [this.City]: [''],
      [this.State]: [''],
      [this.PhoneNumber]: [''],
    });
  }

  private listenFindValidator(): void {
    this.subscriptions.add(
      this.findIsSuccess$
        .pipe(filter((findIsSuccess) => !findIsSuccess))
        .subscribe(() =>
          this.invitationCodeForm.get(this.InvitationCode).setErrors({ notFound: true }),
        ),
    );
  }

  private listenReCaptchaValidation(): void {
    this.reCaptchaValidation$ = this.store$.select(selectReCaptchaIsValid);

    this.subscriptions.add(
      this.reCaptchaValidation$
        .pipe(filter((validateSuccess) => validateSuccess === SuccessfulStatus.Success))
        .subscribe(() => this.dispatchConsultantFind()),
    );
  }

  private validateReCaptcha(finderType: SnaConsultantFinderType): void {
    this.submitFinderType = finderType;
    if (this.appInitService.Settings.sna.isReCaptchaEnabled) {
      this.reCaptcha.validateReCaptcha();
    } else {
      this.dispatchConsultantFind();
    }
  }
}
