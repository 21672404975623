import {
  ConsultantResponse,
  FindConsultantRequest,
  LocateConsultantRequest,
} from '@core/dto/consultant.dto';
import { PhException } from '@core/models/ph-exception';
import { createAction, props } from '@ngrx/store';
import { Consultant, Party } from './consultant-state-models';

export enum ConsultantActionTypes {
  fetchCurrentConsultantByVanityName = '[Consultant] Fetch current consultant by vanity name',
  fetchCurrentConsultantByLoginName = '[Consultant] Fetch current consultant by login name',
  fetchCurrentConsultantSuccess = '[Consultant] Fetch current consultant success',
  fetchCurrentConsultantFailure = '[Consultant] Fetch current consultant failure',
  resetCurrentConsultant = '[Consultant] Reset current consultant state',
  // eslint-disable-next-line max-len
  resetFetchCurrentConsultantSuccessfulStatus = '[Consultant] Reset fetch current consultant successful status',
  fetchAssignedConsultant = '[Consultant] Fetch assigned consultant',
  fetchAssignedConsultantSuccess = '[Consultant] Fetch assigned consultant success',
  fetchAssignedConsultantFailure = '[Consultant] Fetch assigned consultant failure',
  resetAssignedConsultant = '[Consultant] Reset assigned consultant',

  fetchAssignedConsultantOnCheckout = '[Consultant] Fetch assigned consultant on checkout',
  // eslint-disable-next-line max-len
  fetchAssignedConsultantOnCheckoutSuccess = '[Consultant] Fetch assigned consultant on checkout success',
  // eslint-disable-next-line max-len
  fetchAssignedConsultantOnCheckoutFailure = '[Consultant] Fetch assigned consultant on checkout failure',

  findConsultant = '[Consultant] Find consultant',
  findConsultantSuccess = '[Consultant] Find consultant success',
  findConsultantFailure = '[Consultant] Find consultant failure',
  locateConsultant = '[Consultant] Locate consultant',
  locateConsultantSuccess = '[Consultant] Locate consultant success',
  locateConsultantFailure = '[Consultant] Locate consultant failure',
  resetConsultantFinderState = '[Consultant] Reset consultant finder state',
  resetConsultantLocatorErrorType = '[Consultant] Reset consultant locator error type',

  fetchConsultantParties = '[Consultant] Fetch consultant parties',
  fetchConsultantPartiesSuccess = '[Consultant] Fetch consultant parties success',
  fetchConsultantPartiesFailure = '[Consultant] Fetch consultant parties failure',
  resetConsultantPartyState = '[Consultant] Reset consultant party state',
  selectParty = '[Consultant] Select party',
  resetParty = '[Consultant] Reset party',
  resetPartyAndIsOrderCreditToHostParty = '[Consultant] Reset party and isOrderAsCreditToHostParty',
  selectIsOrderCreditToHostParty = '[Consultant] Select order as credit to a host of a party',
  consultantCheckingInProgress = '[Consultant] Consultant checking is in progress',
  consultantCheckingIsDone = '[Consultant] Consultant checking is done',
  setIsOutdatedData = '[Consultant] Set isOutdatedData',
  resetOpenPartyVerificationFailedModal = '[Consultant] Reset open party verification failed modal',
}

/**
 * Fetch Consultant
 */
export const fetchCurrentConsultantByVanityName = createAction(
  ConsultantActionTypes.fetchCurrentConsultantByVanityName,
  props<{ vanityName: string; filterCountry: boolean }>(),
);

export const fetchCurrentConsultantByLoginName = createAction(
  ConsultantActionTypes.fetchCurrentConsultantByLoginName,
  props<{ loginName: string; filterCountry: boolean }>(),
);

export const fetchCurrentConsultantSuccess = createAction(
  ConsultantActionTypes.fetchCurrentConsultantSuccess,
  props<{ consultant: ConsultantResponse; isPartyEnabled: boolean }>(),
);

export const fetchCurrentConsultantFailure = createAction(
  ConsultantActionTypes.fetchCurrentConsultantFailure,
  props<{ error: PhException; searchedVanityName: string }>(),
);

export const resetCurrentConsultant = createAction(
  ConsultantActionTypes.resetCurrentConsultant,
  (isCurrentConsultantFetched = false) => ({ isCurrentConsultantFetched }),
);

export const resetFetchCurrentConsultantSuccessfulStatus = createAction(
  ConsultantActionTypes.resetFetchCurrentConsultantSuccessfulStatus,
);

/**
 * Assigned consultant
 */
export const fetchAssignedConsultant = createAction(ConsultantActionTypes.fetchAssignedConsultant);

export const fetchAssignedConsultantSuccess = createAction(
  ConsultantActionTypes.fetchAssignedConsultantSuccess,
  props<{ consultant: ConsultantResponse }>(),
);

export const fetchAssignedConsultantFailure = createAction(
  ConsultantActionTypes.fetchAssignedConsultantFailure,
);

export const resetAssignedConsultant = createAction(ConsultantActionTypes.resetAssignedConsultant);

/**
 * Fetch assigned consultant on checkout
 */
export const fetchAssignedConsultantOnCheckout = createAction(
  ConsultantActionTypes.fetchAssignedConsultantOnCheckout,
);
export const fetchAssignedConsultantOnCheckoutSuccess = createAction(
  ConsultantActionTypes.fetchAssignedConsultantOnCheckoutSuccess,
  props<{ consultant: Consultant }>(),
);
export const fetchAssignedConsultantOnCheckoutFailure = createAction(
  ConsultantActionTypes.fetchAssignedConsultantOnCheckoutFailure,
);

/**
 * Find consultant
 */
export const findConsultant = createAction(
  ConsultantActionTypes.findConsultant,
  props<{ payload: FindConsultantRequest }>(),
);
export const findConsultantSuccess = createAction(
  ConsultantActionTypes.findConsultantSuccess,
  props<{ hasMore: boolean; results: Consultant[] }>(),
);
export const findConsultantFailure = createAction(ConsultantActionTypes.findConsultantFailure);

export const locateConsultant = createAction(
  ConsultantActionTypes.locateConsultant,
  props<{ payload: LocateConsultantRequest }>(),
);
export const locateConsultantSuccess = createAction(
  ConsultantActionTypes.locateConsultantSuccess,
  props<{ hasMore: boolean; results: Consultant[] }>(),
);
export const locateConsultantFailure = createAction(
  ConsultantActionTypes.locateConsultantFailure,
  props<{ error: any }>(),
);
export const resetConsultantLocatorErrorType = createAction(
  ConsultantActionTypes.resetConsultantLocatorErrorType,
);

export const resetFindConsultantState = createAction(
  ConsultantActionTypes.resetConsultantFinderState,
);

/**
 * Consultant party
 */
export const fetchConsultantParties = createAction(ConsultantActionTypes.fetchConsultantParties);
export const fetchConsultantPartiesSuccess = createAction(
  ConsultantActionTypes.fetchConsultantPartiesSuccess,
  props<{ parties: Party[]; selectedParty: Party }>(),
);
export const fetchConsultantPartiesFailure = createAction(
  ConsultantActionTypes.fetchConsultantPartiesFailure,
);
export const resetConsultantPartyState = createAction(
  ConsultantActionTypes.resetConsultantPartyState,
);
export const selectParty = createAction(
  ConsultantActionTypes.selectParty,
  props<{ party: Party }>(),
);
export const resetParty = createAction(ConsultantActionTypes.resetParty);
export const resetPartyAndIsOrderCreditToHostParty = createAction(
  ConsultantActionTypes.resetPartyAndIsOrderCreditToHostParty,
);
export const selectIsOrderCreditToHostParty = createAction(
  ConsultantActionTypes.selectIsOrderCreditToHostParty,
  props<{ isOrderCreditToHostParty: boolean }>(),
);
export const consultantCheckingIsDone = createAction(
  ConsultantActionTypes.consultantCheckingIsDone,
);
export const consultantCheckingInProgress = createAction(
  ConsultantActionTypes.consultantCheckingInProgress,
);

/**
 * Set isOutdatedData
 */
export const setIsOutdatedData = createAction(
  ConsultantActionTypes.setIsOutdatedData,
  props<{ isOutdatedData: boolean }>(),
);

/**
 * Reset open party verification failed modal
 */
export const resetOpenPartyVerificationFailedModal = createAction(
  ConsultantActionTypes.resetOpenPartyVerificationFailedModal,
);
