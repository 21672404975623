import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Step } from '@core/models/step.model';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
})
export class StepComponent {
  @Input() step: Step;
  @Output() changeStep: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  changeStepId() {
    this.changeStep.emit(this.step.id);
  }
}
