<div class="d-flex justify-content-center" *ngIf="isCurrentConsultantFetchLoading; else content">
  <app-ph-spinner class="mt-5" width="100px"> </app-ph-spinner>
</div>

<ng-template #content>
  <ng-container *ngIf="isStartNowAppDisabled === false">
    <ng-container *ngIf="!isFinalized">
      <ng-container *ngIf="!isCurrentConsultantFetchLoading">
        <app-start-now-app-consultant-step-wrapper
          *ngIf="!consultantPhasePassed"
          [steps]="consultantFinderSteps"
          [initialStep]="globalStep"
          (stepChanged)="stepChanged($event)"
          (navigateToStep)="goToStep($event)"
          (consultantPhasePassed)="onConsultantPhaseFinished()"
        ></app-start-now-app-consultant-step-wrapper>

        <app-start-now-app-stepper
          *ngIf="consultantPhasePassed"
          [steps]="NavigationSteps.slice(4, 12)"
          [initStep]="globalStep"
          (stepChanged)="stepChanged($event)"
          (moveBackToInitialNavigationPosition)="onMoveBackToConsultantPhase()"
          (navigateToStep)="goToStep($event)"
        ></app-start-now-app-stepper>
      </ng-container>
    </ng-container>

    <app-congratulation-step *ngIf="isFinalized"></app-congratulation-step>

    <app-start-now-app-offline-modal
      #offlineModal
      [paymentInfo]="paymentInfo$ | async"
      [country]="country"
      [applicationRegistrationId]="applicationRegistrationId$ | async"
    ></app-start-now-app-offline-modal>

    <app-sna-query-param-checker></app-sna-query-param-checker>
  </ng-container>

  <app-sna-disabled-page
    *ngIf="isStartNowAppDisabled"
    [isProwessDown]="isProwessDown"
  ></app-sna-disabled-page>
</ng-template>
