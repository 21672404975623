import { Component, Inject, Input, LOCALE_ID, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FooterLink } from '@core/store/footer/footer-state-models';
import { scrollToTop } from '@shared/utils/scroll-utils';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-footerlink',
  templateUrl: './footerlink.component.html',
  styleUrls: ['./footerlink.component.scss'],
})
export class FooterlinkComponent implements OnDestroy {
  @Input() link: FooterLink;
  @Input() isSecondary: Boolean;
  @Input() linkTarget: '_blank' | '_slef' = '_blank';

  private route: string;
  private subscription: Subscription;

  public get linkStyle(): string {
    return `${this.isExternal ? 'external' : 'internal'}_${
      this.isSecondary ? 'secondary' : 'primary'
    }`;
  }

  private get isExternal(): boolean {
    return this.link.url.startsWith('http') ?? true;
  }

  constructor(@Inject(LOCALE_ID) private localeId: string, private router: Router) {
    this.subscription = this.router.events
      .pipe(
        startWith(new NavigationEnd(0, window.location.pathname, window.location.pathname)),
        filter((e) => e instanceof NavigationEnd),
      )
      .subscribe((e: NavigationEnd) => {
        this.route = e.url.replace(`/${this.localeId}`, '').split('?')[0];
      });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  linkIsHidden(url: string): boolean {
    if (url === '/clear-consultant') {
      switch (this.route) {
        case '/checkout':
          return true;

        default:
          return false;
      }
    } else {
      return false;
    }
  }

  scrollToTopOfThePage(link: FooterLink): void {
    if (link.url.startsWith('/')) {
      scrollToTop();
    }
  }
}
