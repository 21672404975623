import { ConsultantLocatorErrorType } from '@core/enums/consultant-locator-error-type.enum';
import { SuccessfulStatus } from '@core/enums/successful-status.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ConsultantState } from './consultant-state-models';

export const consultantStateFeatureKey = 'consultantState';

export const selectConsultantStateFeature =
  createFeatureSelector<ConsultantState>(consultantStateFeatureKey);

export const selectCurrentConsultant = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.currentConsultant : null),
);

export const selectHasCurrentConsultant = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? !!state.currentConsultant : false),
);

export const selectConsultantState = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state : null),
);

export const selectAssignedConsultant = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.assignedConsultant : null),
);

export const selectConsultantFinder = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.consultantFinder : null),
);

export const selectConsultantFinderResultCount = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.consultantFinder.results?.length : 0),
);

export const selectCurrentConsultantParties = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) =>
    state?.currentConsultant?.parties ? state.currentConsultant?.parties : [],
);

export const selectCurrentConsultantPartiesLoading = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.partiesLoading : false),
);

export const selectParty = createSelector(selectConsultantStateFeature, (state: ConsultantState) =>
  state ? state.party : null,
);

export const selectIsOrderCreditToHostParty = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.isOrderCreditToHostParty : null),
);

export const selectIsConsultantCheckingDone = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.isConsultantCheckingDone : false),
);

export const selectIsCurrentConsultantFetched = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.isCurrentConsultantFetched : false),
);

export const selectIsAssignedConsultantFetched = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.isAssignedConsultFetched : false),
);
export const selectIsCurrentConsultantCleared = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.isCurrentConsultantCleared : false),
);

export const selectFetchCurrentConsultantSuccessfulStatus = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) =>
    state ? state.fetchCurrentConsultantSuccessfulStatus : SuccessfulStatus.BeforeCall,
);

export const selectConsultantLocatorErrorType = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) =>
    state ? state.consultantLocatorErrorType : ConsultantLocatorErrorType.None,
);

export const selectConsultantFinancialState = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.currentConsultant?.financialState : null),
);

export const selectConsultantFinderLoading = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.consultantFinder.loading : false),
);

export const selectIsOutdatedData = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.isOutdatedData : true),
);

export const selectOpenPartyVerificationFailedModal = createSelector(
  selectConsultantStateFeature,
  (state: ConsultantState) => (state ? state.openPartyVerificationFailedModal : false),
);
