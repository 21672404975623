/* eslint-disable no-unused-vars */
import { ClientTokenRequest, LogCardAttemptRequest } from '@core/dto/order.dto';
import { ReceiveVouchersRequest } from '@core/dto/start-now-app.dto';
import { EcPaymentHandlerType } from '@core/enums/payment-handler-type.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { selectCart } from '@core/store/cart';
import { selectPaymentType } from '@core/store/checkout';
import { selectPartyOrderDataForPayPalOrder } from '@core/store/order';
import { logCardAttempt } from '@core/store/order/order.actions';
import {
  selectClientToken,
  selectCustomerId,
  selectPayPalOrder,
  selectPaymentInfo,
} from '@core/store/payment';
import { PaymentInfo } from '@core/store/payment/payment-state-models';
import {
  createCheckoutPayPalOrder,
  createPartyOrderPayPalOrder,
  createPaymentMethod,
  fetchClientToken,
  fetchPartyOrderClientToken,
  resetCardPaymentInfo,
  resetCreatedPaymentMethod,
  storePaymentInfo,
  storePaymentInfoFailure,
  updateCheckoutPayPalOrder,
} from '@core/store/payment/payment.actions';
import { Store, select } from '@ngrx/store';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';
import { PaymentHandlerBase } from '../payment-handler-base.model';
import { getPaymentProviderType } from '../payment-provider.utils';
export class ECommercePaymentHandler implements PaymentHandlerBase {
  constructor(
    public store$: Store<AppState>,
    private appInitService: AppInitService,
    private ecPaymentHandlerType?: EcPaymentHandlerType,
  ) {}

  public initToken(
    paymentProvider: PaymentProviderType,
    cardholderFirstName = '',
    cardholderLastName = '',
  ): Observable<string> {
    const payload: ClientTokenRequest = {
      provider: paymentProvider,
      firstName: cardholderFirstName || undefined,
      lastName: cardholderLastName || undefined,
    };
    switch (this.ecPaymentHandlerType) {
      case EcPaymentHandlerType.CheckoutOrder:
      case EcPaymentHandlerType.CreatePaymentMethod:
        this.store$.dispatch(fetchClientToken({ payload }));
        break;

      case EcPaymentHandlerType.PartyOrder:
        this.store$.dispatch(fetchPartyOrderClientToken({ payload }));
        break;
    }
    return this.store$.select(selectClientToken).pipe(
      filter((token) => !!token),
      distinctUntilChanged((prevToken, currToken) => prevToken === currToken),
      take(1),
    );
  }

  /* TODO: On check-out page if needed the token reset while the component is live implement it,
    otherwise if just the component is reinitialized (show/hide) will get a new token.
  */
  resetToken(): void {
    this.store$.dispatch(resetCardPaymentInfo());
  }

  isCvvRequired(): boolean {
    switch (this.ecPaymentHandlerType) {
      case EcPaymentHandlerType.CheckoutOrder:
      case EcPaymentHandlerType.CreatePaymentMethod:
        return this.appInitService.Settings.ec.isCvvRequired;
      case EcPaymentHandlerType.PartyOrder:
        return this.appInitService.Settings.ec.isCvvRequiredPartyOrder;
    }
  }

  isAvsRequired(): boolean {
    switch (this.ecPaymentHandlerType) {
      case EcPaymentHandlerType.CheckoutOrder:
      case EcPaymentHandlerType.CreatePaymentMethod:
        return this.appInitService.Settings.ec.isAvsRequired;
      case EcPaymentHandlerType.PartyOrder:
        return this.appInitService.Settings.ec.isAvsRequiredPartyOrder;
    }
  }

  collectRiskDataFromInput(): boolean {
    switch (this.ecPaymentHandlerType) {
      case EcPaymentHandlerType.CheckoutOrder:
        return this.appInitService.Settings.ec.checkoutCollectRiskDataFromInput;
      case EcPaymentHandlerType.CreatePaymentMethod:
        return false;
      case EcPaymentHandlerType.PartyOrder:
        return this.appInitService.Settings.ec.partyOrderCollectRiskDataFromInput;
    }
  }

  createOrder(): Observable<string> {
    if (isMexEnv) {
      switch (this.ecPaymentHandlerType) {
        case EcPaymentHandlerType.CheckoutOrder:
          return this.store$.select(selectPayPalOrder).pipe(
            take(1),
            switchMap((orderId) => {
              if (orderId) {
                this.store$.dispatch(updateCheckoutPayPalOrder({ orderId }));
                return this.store$.select(selectPayPalOrder).pipe(
                  filter((updateOrderId) => !!updateOrderId && updateOrderId !== orderId),
                  take(1),
                );
              } else {
                this.store$.dispatch(createCheckoutPayPalOrder());
                return this.store$.select(selectPayPalOrder).pipe(
                  filter((createOrderId) => !!createOrderId),
                  take(1),
                );
              }
            }),
          );
        case EcPaymentHandlerType.PartyOrder:
          return combineLatest([
            this.store$.select(selectPartyOrderDataForPayPalOrder),
            this.store$.select(selectPayPalOrder),
          ]).pipe(
            take(1),
            switchMap(([createPartyOrderPayPalOrderData, orderId]) => {
              this.store$.dispatch(
                createPartyOrderPayPalOrder({ payload: createPartyOrderPayPalOrderData }),
              );
              return this.store$.select(selectPayPalOrder).pipe(
                // wait the new created order id that can't null and the same as the previous
                filter((createdOrderId) => !!createdOrderId && createdOrderId !== orderId),
                take(1),
              );
            }),
          );
      }
    }
  }

  updateOrder(): void {
    if (isMexEnv) {
      switch (this.ecPaymentHandlerType) {
        case EcPaymentHandlerType.CheckoutOrder:
          this.store$
            .select(selectPayPalOrder)
            .pipe(
              take(1),
              filter((orderId) => !!orderId),
            )
            .subscribe((orderId) => this.store$.dispatch(updateCheckoutPayPalOrder({ orderId })));
          break;
        case EcPaymentHandlerType.PartyOrder:
          /** Here not needed update order, because the authorization and transaction happens in
            the same time at submit and the account/card data is not saved and money not held by authorization hold
          */
          this.store$
            .select(selectPartyOrderDataForPayPalOrder)
            .pipe(take(1))
            .subscribe((cratePartyOrderPayPalOrder) => {
              this.store$.dispatch(
                createPartyOrderPayPalOrder({ payload: cratePartyOrderPayPalOrder }),
              );
            });
          break;
      }
    }
  }

  public handleRequestedPaymentMethodObject(error: object, paymentInfo: PaymentInfo): void {
    if (error) {
      this.store$.dispatch(storePaymentInfoFailure({ error }));
    } else {
      this.store$.dispatch(storePaymentInfo({ paymentInfo }));
    }
  }

  public createPaymentMethod(): void {
    this.store$.dispatch(resetCreatedPaymentMethod());

    switch (this.ecPaymentHandlerType) {
      case EcPaymentHandlerType.CheckoutOrder:
        combineLatest([
          this.store$.select(selectPaymentInfo),
          this.store$.select(selectCustomerId),
          this.store$.select(selectCart).pipe(select((cart) => cart.totalPrice)),
          this.store$.select(selectPaymentType),
        ])
          .pipe(
            filter(([paymentInfo, ,]) => !!paymentInfo),
            take(1),
          )
          .subscribe(([paymentInfo, customerId, amount, paymentType]) => {
            this.store$.dispatch(
              createPaymentMethod({
                payload: {
                  nonce: paymentInfo?.nonce,
                  deviceData: paymentInfo.deviceData,
                  payPalOrderId: paymentInfo?.order?.orderId,
                  customerId: customerId,
                  amount: amount,
                  cardSaveRequest: paymentInfo.cardSaveRequest,
                  provider: getPaymentProviderType(paymentType),
                },
              }),
            );
          });
    }
  }

  // TODO: implement voucher if needed for checkout-page
  createVoucher(): void {}

  // TODO: implement voucher if needed for checkout-page
  receiveVouchers(vouchers: ReceiveVouchersRequest): void {}

  receiveVoucher(token: string): void {}

  // TODO: implement voucher if needed for checkout-page
  listenReceivedVouchers(): Observable<boolean> {
    return of(false);
  }

  // TODO: implement voucher if needed for checkout-page
  resetReceiveVoucher(): void {}

  logCardAttempt(
    isSuccess: boolean,
    response: string,
    provider: PaymentProviderType = PaymentProviderType.Default,
    prowessMasterOrderId?: number,
  ): void {
    const request: LogCardAttemptRequest = {
      prowessMasterOrderId: prowessMasterOrderId,
      success: isSuccess,
      response: response,
    };
    this.store$.dispatch(logCardAttempt({ request }));
  }
}
