<router-outlet (activate)="onActivate()"></router-outlet>

<!-- If the app is opened in iframe for b2c login, we should disable the initializer in this way the toasters, modals! -->
<ng-container *ngIf="!isIframe">
  <app-back-forward-cache-initializer></app-back-forward-cache-initializer>
  <app-party-initializer></app-party-initializer>
  <app-gtm-initializer></app-gtm-initializer>
  <app-open-party-notification-initializer></app-open-party-notification-initializer>
  <app-auth-initializer
    *ngIf="userUtilService.isUserFeatureEnabled$ | async"
  ></app-auth-initializer>
  <app-cart-initializer
    *ngIf="productUtilService.isShoppingEnabled$ | async"
  ></app-cart-initializer>
  <app-consultant-initializer></app-consultant-initializer>
  <app-replicated-site-initializer></app-replicated-site-initializer>
  <app-wishlist-initializer
    *ngIf="
      (productUtilService.isShoppingEnabled$ | async) &&
      (userUtilService.isUserFeatureEnabled$ | async)
    "
  ></app-wishlist-initializer>
  <app-logrocket-initializer></app-logrocket-initializer>
  <app-appcues-initializer *ngIf="appSettings.isAppcuesEnabled"></app-appcues-initializer>
  <app-title-initializer></app-title-initializer>
  <app-website-mode-initializer></app-website-mode-initializer>
  <app-cookiebot-initializer></app-cookiebot-initializer>
  <app-datadog-initializer *ngIf="appSettings.isDatadogEnabled"></app-datadog-initializer>
  <app-footer-link-initializer></app-footer-link-initializer>
  <app-accessibe-initializer *ngIf="feature.isAccessibeEnabled"></app-accessibe-initializer>
  <app-not-allowed-to-place-order-notification-initializer
    *ngIf="isMexEnv"
  ></app-not-allowed-to-place-order-notification-initializer>
</ng-container>
