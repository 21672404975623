<a
  routerLink="/shopping-cart"
  [ngClass]="{
    'position-relative': true,
    'd-inline-flex': true,
    disabled: (quantityCount$ | async) === 0
  }"
>
  <img class="icon mr-1 img-fluid" src="assets/images/header/SHOPPING_BAG.svg" alt="cart" />
  <div
    class="d-flex align-items-center justify-content-center cart-number-style"
    *ngIf="quantityCount$ | async as quantityCount"
  >
    <span class="badge badge-pill badge-primary" *ngIf="quantityCount < 100; else countPlus">
      {{ quantityCount }}
    </span>
    <ng-template #countPlus>
      <span class="badge badge-pill badge-primary">99+</span>
    </ng-template>
  </div>
</a>
