<div class="row justify-content-between">
  <div class="col-6">
    <button class="btn btn-outline-dark text-uppercase w-100" (click)="previousStepClick()">
      {{ negativeButtonLabel }}
    </button>
  </div>
  <div class="col-6">
    <button
      class="btn btn-primary text-uppercase w-100"
      (click)="nextStepClick()"
      [disabled]="positiveButtonDisabled"
    >
      {{ positiveButtonLabel }}
    </button>
  </div>
</div>
