import { Inject, Injectable } from '@angular/core';
import { AppSettings } from '@core/models/app-settings.model';
import { APP_CONFIG } from '@shared/utils/app-config-util';

export interface IAppInitService {
  Settings: AppSettings;
  isStartNowAppDisabled: () => boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AppInitService implements IAppInitService {
  // TODO EC-3876
  // readonly Settings: AppSettings = new AppSettings();

  constructor(
    // TODO EC-3876
    // private logger: LoggerService,
    // private ecConfigurationService: ConfigurationService,
    // private snaConfigurationService: StartNowAppService,
    @Inject(APP_CONFIG) public readonly Settings: AppSettings,
  ) {}

  // TODO EC-3876
  // initEcConfiguration(): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     this.ecConfigurationService
  //       .fetchConfiguration()
  //       .toPromise()
  //       .then(
  //         (response) => {
  //           this.Settings.ec = response;
  //           resolve();
  //         },
  //         (err) => {
  //           reject(err);
  //         },
  //       )
  //       .catch((error) => {
  //         this.logger.log('EC Configuration get failed', error);
  //       });
  //   });
  // }

  // initSnaConfiguration(): Promise<void> {
  //   return new Promise<void>((resolve, reject) => {
  //     this.snaConfigurationService
  //       .fetchStartNowAppConfiguration()
  //       .toPromise()
  //       .then(
  //         (response) => {
  //           this.Settings.sna = response;
  //           resolve();
  //         },
  //         (err) => {
  //           reject(err);
  //         },
  //       )
  //       .catch((error) => {
  //         this.logger.log('SNA Configuration get failed', error);
  //       });
  //   });
  // }

  public isStartNowAppDisabled(): boolean {
    return this.Settings.sna.isRegistrationDisabled || this.Settings.sna.isProwessDown;
  }
}
