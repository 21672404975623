import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '@core/store';
import { selectBlogDetails } from '@core/store/blog';
import { BlogDetailsItem } from '@core/store/blog/blog-state-models';
import { fetchBlogDetails, resetBlogDetails } from '@core/store/blog/blog.actions';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
})
export class BlogDetailsComponent implements OnInit, OnDestroy {
  blogUrl: string;
  blogDetailsItem$: Observable<BlogDetailsItem>;
  loading$: Observable<boolean>;

  subscriptions: Subscription = new Subscription();

  constructor(private store$: Store<AppState>, private activatedRoute: ActivatedRoute) {
    this.blogUrl = this.activatedRoute.snapshot.paramMap.get('blogUrl');
  }

  ngOnInit(): void {
    this.blogDetailsItem$ = this.store$
      .select(selectBlogDetails)
      .pipe(select((details) => details?.item));

    this.loading$ = this.store$
      .select(selectBlogDetails)
      .pipe(select((details) => details?.isLoading));

    this.subscriptions.add(
      this.activatedRoute.paramMap.subscribe((pMap) => {
        this.blogUrl = pMap.get('blogUrl');
        this.store$.dispatch(fetchBlogDetails({ url: this.blogUrl }));
      }),
    );
  }

  ngOnDestroy(): void {
    this.store$.dispatch(resetBlogDetails());
    this.subscriptions.unsubscribe();
  }
}
