import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { scrollToTop } from '@shared/utils/scroll-utils';
import { BaseComponent } from '../base-component/base-component';
import { NavigationStep } from './navigation-step.model';

@Component({
  template: '',
})
export abstract class StepperBaseComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() stepChanged: EventEmitter<number> = new EventEmitter();

  public activeStepId: number;
  public stepProcessing: boolean = false;
  protected activeStepTitle: string;
  protected nextStepTitle: string;
  protected initialStepId: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _steps: ReadonlyArray<NavigationStep>;

  // @Inject(Object) - https://angular.io/errors/NG2003
  protected constructor(@Inject(Object) steps?: NavigationStep[]) {
    super();
    this.initSteps(steps);
  }

  get navigationSteps(): Readonly<NavigationStep[]> {
    return this._steps;
  }

  get navigationStepLength(): number {
    return this._steps.length;
  }

  ngOnInit(): void {
    this.initialStepId = this.initialStepId
      ? this.navigationSteps.find((step) => step.id === this.initialStepId).orderNumber
      : this.navigationSteps[0].orderNumber;
    this.activeStepId = this.initialStepId;
    this.changeStepTitles(this.initialStepId);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  navigateTo(stepId: number): void {
    scrollToTop();
    this.activeStepId = stepId;
    this.changeStepTitles(stepId);
  }

  protected changeStepTitles(stepId: number): void {
    if (this.navigationSteps) {
      this.activeStepTitle = this.navigationSteps.find(
        (navStep) => navStep.orderNumber === stepId,
      ).title;
      this.nextStepTitle = this.navigationSteps.find(
        (navStep) => navStep.orderNumber === stepId + 1,
      )?.title;
    }
  }

  /** Gets the next step id based on the given id if there is a next one, otherwise returns the
   *  given one. If there aren't defined steps return an undefined. */
  protected getNextStepId(currentId: number): number {
    if (this.navigationSteps) {
      const currentIndex = this.getCurrentStepIndex(currentId);
      if (currentIndex !== -1 && currentIndex < this.navigationSteps.length - 1) {
        return this.navigationSteps[currentIndex + 1].orderNumber;
      } else return currentId;
    } else return undefined;
  }

  /** Gets the previous step id based on the given id if there is a previous one, otherwise returns the
   *  given one. If there aren't defined steps return an undefined */
  protected getPreviousStepId(currentId: number): number {
    if (this.navigationSteps) {
      const currentIndex = this.getCurrentStepIndex(currentId);
      if (currentIndex !== -1 && currentIndex >= 1) {
        return this.navigationSteps[currentIndex - 1].orderNumber;
      } else return currentId;
    } else return undefined;
  }

  protected initSteps(steps: NavigationStep[]): void {
    if (steps) {
      this._steps = steps.sort((a, b) => a.id - b.id);
      this._steps.forEach((navigationStep, i) => (navigationStep.orderNumber = i + 1));
    }
  }

  private getCurrentStepIndex(currentId: number) {
    return this.navigationSteps.findIndex((item) => item.orderNumber === currentId);
  }
}
