import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CategoryConditionalContentPageComponent } from './components/category-conditional-content-page/category-conditional-content-page.component';
import { ConditionalContentPageComponent } from './components/conditional-content-page/conditional-content-page.component';

import { ConditionalContentRoutingModule } from './conditional-content-routing.module';

@NgModule({
  declarations: [ConditionalContentPageComponent, CategoryConditionalContentPageComponent],
  imports: [CommonModule, SharedModule, ConditionalContentRoutingModule],
})
export class ConditionalContentModule {}
