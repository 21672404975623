import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { PasswordPattern, ValidEmailRegExp } from '@core/constants/patterns';
import { URL } from '@core/constants/url.const';
import { ConsultantResponse } from '@core/dto/consultant.dto';
import { FormErrorTypes } from '@core/enums/form-error-type.enum';
import { InputMaxLength } from '@core/enums/input-max-length.enum';
import { PhExceptionErrorType } from '@core/enums/ph-exception-error-type.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { selectCurrentConsultant, selectHasCurrentConsultant } from '@core/store/consultant';
import { selectRegistrationFailedErrorType, selectUserLoading } from '@core/store/user';
import { registerUser, resetRegistrationFailedErrorType } from '@core/store/user/user.actions';
import { Store } from '@ngrx/store';
import { emailValidator } from '@shared/utils/email-validator-utils';
import { hideRecaptchaBadge, showRecaptchaBadge } from '@shared/utils/recaptcha-utils';
import { matchValidator } from '@shared/utils/validation.utils';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly URL = URL;
  readonly PhErrorTypes = PhExceptionErrorType;
  readonly FormErrorTypes = FormErrorTypes;
  readonly Name = 'name';
  readonly FirstName = 'firstName';
  readonly LastName = 'lastName';
  readonly Email = 'email';
  readonly EmailConfirm = 'emailConfirm';
  readonly PhoneNumber = 'phoneNumber';
  readonly Password = 'password';
  readonly PasswordConfirm = 'passwordConfirm';
  readonly Subscriptions = 'subscriptions';
  readonly Newsletters = 'newsletters';
  readonly ShareWithConsultant = 'shareWithConsultant';
  readonly ErrorMessages = {
    [this.FormErrorTypes.pattern]: FormErrorMessages.passwordPattern,
    [this.FormErrorTypes.required]: FormErrorMessages.required,
  };

  form: FormGroup;
  disableSubmit$: Observable<boolean>;
  errorType$: Observable<PhExceptionErrorType>;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
    private reCaptchaService: ReCaptchaV3Service,
    private appInitService: AppInitService,
  ) {}

  onSubmit(): void {
    if (this.form.valid) {
      this.subscriptions.add(
        this.reCaptchaService
          .execute('importantAction')
          .pipe(
            filter((token) => !!token || !this.appInitService.Settings.ec.isRecaptchaEnabled),
            take(1),
          )
          .subscribe((token: string) => {
            this.store$.dispatch(registerUser({ payload: this.form.value, token }));
          }),
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.createUserRegistrationForm();
    this.errorType$ = this.store$.select(selectRegistrationFailedErrorType);
    this.disableSubmit$ = combineLatest([
      this.store$.select(selectUserLoading),
      this.errorType$,
    ]).pipe(map(([loading, error]) => loading || error === this.PhErrorTypes.ValidationException));
    let consultant$: Observable<ConsultantResponse> = this.store$.select(selectCurrentConsultant);
    let hasCurrentConsultant$: Observable<boolean> = this.store$.select(selectHasCurrentConsultant);

    this.subscriptions.add(
      combineLatest([consultant$, hasCurrentConsultant$]).subscribe(
        ([consultant, hasConsultant]) => {
          this.form.patchValue({
            activeConsultantId: hasConsultant ? consultant?.id : 1,
          });
        },
      ),
    );
  }

  ngAfterViewInit(): void {
    showRecaptchaBadge();
    this.resetRegistrationError();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    hideRecaptchaBadge();
    this.store$.dispatch(resetRegistrationFailedErrorType());
  }

  private createUserRegistrationForm(): void {
    this.form = this.fb.group({
      [this.Name]: this.fb.group({
        [this.FirstName]: ['', [Validators.required, Validators.maxLength(InputMaxLength.Fifty)]],
        [this.LastName]: ['', [Validators.required, Validators.maxLength(InputMaxLength.Fifty)]],
      }),
      [this.Email]: this.fb.group(
        {
          [this.Email]: ['', [Validators.required, emailValidator(ValidEmailRegExp)]],
          [this.EmailConfirm]: ['', [Validators.required, emailValidator(ValidEmailRegExp)]],
        },
        {
          validators: matchValidator(
            this.Email,
            this.EmailConfirm,
            this.FormErrorTypes.emailConfirm,
          ),
        },
      ),
      [this.Password]: this.fb.group(
        {
          [this.Password]: ['', [Validators.required, Validators.pattern(PasswordPattern)]],
          [this.PasswordConfirm]: ['', [Validators.required]],
        },
        {
          validators: matchValidator(
            this.Password,
            this.PasswordConfirm,
            this.FormErrorTypes.passwordConfirm,
          ),
        },
      ),
      [this.Subscriptions]: this.fb.group({
        [this.Newsletters]: [false],
      }),
      [this.PhoneNumber]: '',
      [this.ShareWithConsultant]: [true],
    });
  }

  private resetRegistrationError(): void {
    this.subscriptions.add(
      combineLatest([
        this.form.get(this.Email).get(this.Email).valueChanges,
        this.form.get(this.Email).get(this.EmailConfirm).valueChanges,
      ]).subscribe(() => this.store$.dispatch(resetRegistrationFailedErrorType())),
    );
  }
}
