<div *ngIf="loading$ | async; else content" class="d-flex justify-content-center m-5">
  <app-ph-spinner width="60px"></app-ph-spinner>
</div>

<ng-template #content>
  <div class="d-md-none">
    <ng-container *ngFor="let order of orderHistory$ | async">
      <div class="mb-2">
        <div class="row background-gray">
          <div class="col order-id py-2">
            <strong>
              <span class="text-double-dot" i18n>Order ID</span>{{ order.orderNumber }}</strong
            >
          </div>
          <div class="col-auto py-2">
            <p class="text-nowrap uppercase order-status m-0">
              {{ order.orderStatus }}
            </p>
          </div>

          <div class="col-12 pb-2">
            <span class="d-block">
              <span class="text-double-dot" i18n>Order date</span
              >{{ order.createdDate | date: 'longDate' }}
            </span>
          </div>
          <div
            *ngIf="!!order.consultantFirstName && !!order.consultantLastName"
            class="col-12 pb-2"
          >
            <span class="d-block font-weight-bold" i18n>Consultant</span>
            <span class="d-block">
              {{ order.consultantFirstName + ' ' + order.consultantLastName }}
            </span>
          </div>
          <div *ngIf="!!order.partyHost" class="col-12 pb-2">
            <span class="d-block font-weight-bold" i18n>Host</span>
            <span class="d-block"> {{ order.partyHost }} </span>
          </div>
          <div class="col pb-2">
            <button
              *ngIf="enableActionMethod; else linkAction"
              class="btn btn-primary"
              (click)="emitAction(order.orderId)"
            >
              <i class="mr-1" [ngClass]="actionIcon"></i>
              <span>{{ actionText }}</span>
            </button>
            <ng-template #linkAction>
              <button class="btn btn-primary" routerLink="{{ order.orderId }}">
                <i class="mr-1" [ngClass]="actionIcon"></i>
                <span>{{ actionText }}</span>
              </button>
            </ng-template>
          </div>
          <div class="col-auto pb-2 d-flex align-items-center">
            <span class="total-price">{{ order.total | phCurrency }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="d-none d-md-flex">
    <app-sortable-table
      class="w-100"
      [columns]="columns"
      [rows]="orderHistoryTableData$ | async"
    ></app-sortable-table>
  </div>
</ng-template>
