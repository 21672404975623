<app-modal #modal [modalConfig]="modalConfig">
  <div class="root" *ngIf="(addToCartConfirmModal$ | async)?.data as product">
    <img
      phImage
      [images]="product.images"
      [thumbnailDesktop]="thumbnail.productListDesktop"
      [thumbnailMobile]="thumbnail.productListMobile"
      width="100%"
    />
    <div class="d-flex flex-column">
      <div class="category-name mb-2">{{ product.collection | titlecase }}</div>
      <div class="product-name mb-2">{{ product.productName }}</div>
      <div class="sku mb-2">{{ product.sku }}</div>
      <div class="item-price">
        <app-product-price-message [product]="product"></app-product-price-message>
        <app-product-price [product]="product"></app-product-price>
      </div>
      <div class="flex-grow-1 text-double-dot"><span i18n>Qty</span> {{ product.quantity }}</div>
      <div class="total-price">{{ totalPrice | phCurrency }}</div>
    </div>
  </div>
</app-modal>
