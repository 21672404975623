export enum CreateCardPaymentErrorType {
  None = 'None',
  ProcessorDeclined = 'ProcessorDeclined',
  GatewayRejected = 'GatewayRejected',
  CvvVerificationFailed = 'CvvVerificationFailed',
  CvvVerificationNotSupportedOrFailed = 'CvvVerificationNotSupportedOrFailed',
  CvvAndAvsVerificationNotSupportedOrFailed = 'CvvAndAvsVerificationNotSupportedOrFailed',
  CvvAndAvsPostalCodeVerificationFailed = 'CvvAndAvsPostalCodeVerificationFailed',
  AvsVerificationNotSupportedOrFailed = 'AvsVerificationNotSupportedOrFailed',
  AvsPostalCodeVerificationFailed = 'AvsPostalCodeVerificationFailed',
  AvsStreetAddressVerificationFailed = 'AvsStreetAddressVerificationFailed',
  PaymentMethodNotPresent = 'PaymentMethodNotPresent',
  VerificationFailed = 'VerificationFailed',
  ValidationFailed = 'ValidationFailed',
  PaymentMethodNotCreditCard = 'PaymentMethodNotCreditCard',
  Unrecognized = 'Unrecognized',
  Unknown = 'Unknown',
  PaymentMethodTypeMismatch = 'PaymentMethodTypeMismatch',
  PaymentPlatformCustomerNotFound = 'PaymentPlatformCustomerNotFound',
  MissingParameter = 'MissingParameter',
  AuthorizationFailed = 'AuthorizationFailed',
  InvalidParameter = 'InvalidParameter',
}
