import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ConsultantFinancialStateEnum } from '@core/enums/consultant-financial-state.enum';
import { AppState } from '@core/store';
import {
  selectCurrentConsultant,
  selectIsCurrentConsultantCleared,
  selectIsCurrentConsultantFetched,
} from '@core/store/consultant';
import { Store } from '@ngrx/store';
import { isMexEnv } from '@shared/utils/environment-utils';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { AppInitService } from './app-init.service';

@Injectable({
  providedIn: 'root',
})
export class UserFeatureGuardService implements CanActivate {
  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private appInitService: AppInitService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store$.select(selectIsCurrentConsultantFetched).pipe(
      withLatestFrom(
        this.store$.select(selectIsCurrentConsultantCleared),
        this.store$.select(selectCurrentConsultant),
      ),
      filter(
        ([isCurrentConsultantFetched, isCurrentConsultantCleared]) =>
          isCurrentConsultantFetched || isCurrentConsultantCleared,
      ),
      map(([, , currentConsultant]) => {
        switch (true) {
          case !this.appInitService.Settings.ec.isUserEnabled:
          case isMexEnv &&
            currentConsultant?.financialState ===
              ConsultantFinancialStateEnum.NotAllowedToPlaceOrders:
            this.router.navigateByUrl('/');
            return false;

          default:
            return true;
        }
      }),
    );
  }
}
