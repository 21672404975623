<div class="container pl-md-0">
  <app-ph-spinner *ngIf="loading$ | async; else content"></app-ph-spinner>
  <ng-template #content>
    <ng-container *ngIf="returnDetails$ | async as returnDetials">
      <a routerLink=".." type="button" class="d-none d-md-block">
        <i class="bi bi-arrow-left"></i>
        <span i18n> My Returns</span>
      </a>
      <app-account-header
        class="d-none d-md-block"
        [title]="title"
        [rightText]="returnDate"
      ></app-account-header>

      <app-order-information [readOnly]="true" [orderInfo]="returnInfo"></app-order-information>
      <div class="w-100">
        <app-return-order-lines
          [readOnly]="true"
          [orderLines]="productLines"
        ></app-return-order-lines>
      </div>
    </ng-container>
  </ng-template>
</div>
