<div class="container pl-md-0">
  <app-account-header i18n-title title="My Profile"></app-account-header>
  <form [formGroup]="profileForm">
    <div class="row">
      <div class="my-2 col-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="profileFormKey.firstName"
            label="First name"
            labelAsterix="true"
            i18n-label
            placeholder="Your first name goes here"
            i18n-placeholder
            validationErrorMessage="Please enter your first name"
            i18n-validationErrorMessage
            [formControlName]="profileFormKey.firstName"
          >
          </app-text-field-input>
        </div>
      </div>
      <div class="my-2 col-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="profileFormKey.lastName"
            label="Last name"
            labelAsterix="true"
            i18n-label
            placeholder="Your last name goes here"
            i18n-placeholder
            validationErrorMessage="Please enter your last name"
            i18n-validationErrorMessage
            [formControlName]="profileFormKey.lastName"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="my-2 col-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="profileFormKey.email"
            label="Email address"
            type="email"
            i18n-label
            placeholder="Your email address goes here"
            i18n-placeholder
            validationErrorMessage="Please enter a valid email address"
            i18n-validationErrorMessage
            [formControlName]="profileFormKey.email"
            readonly="true"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
      <div class="my-2 col-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="profileFormKey.phoneNumber"
            label="Mobile number"
            type="tel"
            i18n-label
            placeholder="Your mobile number goes here"
            i18n-placeholder
            validationErrorMessage="Please enter a valid mobile number"
            i18n-validationErrorMessage
            [formControlName]="profileFormKey.phoneNumber"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>
    <hr class="w-100" />
    <button
      (click)="changePassword()"
      i18n
      class="btn btn-uppercase-lg btn-lg btn-primary"
      type="button"
      >Change password</button
    >
    <hr class="w-100" />
    <div class="row">
      <div class="my-2 col-12">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [formControlName]="profileFormKey.newsletters"
            id="newsletters"
          />
          <label i18n class="form-check-label" for="newsletters">
            Subscribe to the Princess House Newsletter
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="my-2 col-12">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [formControlName]="profileFormKey.shareWithConsultant"
            id="shareWithConsultant"
          />
          <label i18n class="form-check-label" for="shareWithConsultant">
            Share my contact information with my Consultant
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="my-2 col-12 col-lg-6 d-flex">
        <button
          (click)="onCancel()"
          i18n
          class="w-100 btn btn-lg btn-uppercase-lg btn-outline-dark m-1"
          [disabled]="disableSave$ | async"
          >Cancel</button
        >
        <button
          (click)="onSave()"
          i18n
          class="w-100 btn btn-uppercase-lg btn-lg btn-primary m-1"
          [disabled]="disableSave$ | async"
        >
          Save changes
        </button>
      </div>
    </div>
  </form>
</div>
