<app-page-title
  *ngIf="blogDetailsItem$ | async as blogDetailsItem"
  [title]="blogDetailsItem.title"
  [description]="blogDetailsItem.description"
></app-page-title>
<div class="d-flex justify-content-center m-5">
  <ng-container *ngIf="loading$ | async; else content">
    <app-ph-spinner></app-ph-spinner>
  </ng-container>
</div>

<ng-template #content>
  <div class="container" *ngIf="blogDetailsItem$ | async as blog">
    <h5 class="font-weight-bold">{{ blog.title }}</h5>
    <app-romance-content-wrapper [html]="blog.content"></app-romance-content-wrapper>
  </div>
</ng-template>
