export enum ProductType {
  ItemNoLongerAvailable = 1,
  ActiveInCatalog,
  CatalogItemOutOfStock,
  HostSpecialInCatalog,
  HostSpecialOutOfCatalog,
  CustomerSpecial,
  RegularCustomerSpecial,
  HealthySavingsSpecial,
  SurpriseBuy,
  ECommerceCustomerSpecial,
  ThreeBooking,
  BuyOneGetOne50,
  CollectAndSave,
  HostBookSpec,
  ComingSoon,
}
