import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss'],
})
export class CircularProgressComponent {
  @Input() currentStep: number;
  @Input() numberOfSteps: number;

  constructor() {}

  getTitle() {
    return $localize`${this.currentStep} of ${this.numberOfSteps}`;
  }

  getPercent() {
    return (this.currentStep / this.numberOfSteps) * 100;
  }
}
