import { FormErrorTypes } from '@core/enums/form-error-type.enum';

export const FormErrorMessages = {
  [FormErrorTypes.required]: $localize`This field is required`,
  [FormErrorTypes.snaRequired]: $localize`Please fill out!`,
  [FormErrorTypes.email]: $localize`Please enter a valid email address`,
  [FormErrorTypes.emailConfirm]: $localize`Email confirmation does not match`,
  [FormErrorTypes.maxlength]: $localize`This field is too long`,
  [FormErrorTypes.minlength]: $localize`This field is too short`,
  [FormErrorTypes.nameExists]: $localize`Address with this name exists. Choose another name.`,
  [FormErrorTypes.restRequired]: $localize`At least one interest is required.`,
  [FormErrorTypes.passwordConfirm]: $localize`Password confirmation does not match`,
  [FormErrorTypes.passwordPattern]: $localize`Password doesn't meet the requirements`,
  [FormErrorTypes.interestRequired]: $localize`At least one interest is required.`,
  [FormErrorTypes.max]: $localize`You must be older than 18 years!`,
  [FormErrorTypes.dropdownRequired]: $localize`Specific Party dropdown selection is required.`,
};
