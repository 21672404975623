import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PromotionMessageLocale } from '@core/constants/promotion-message-locale';
import { PromotionMessage } from '@core/enums/promotion-message.enum';
import { AppState } from '@core/store';
import { selectAddSurpriseBuyLoading } from '@core/store/cart';
import { addSurpriseBuy } from '@core/store/cart/cart.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cart-promotion-message',
  templateUrl: './cart-promotion-message.component.html',
  styleUrls: ['./cart-promotion-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartPromotionMessageComponent {
  @Input() message: PromotionMessage;

  readonly PromotionMessage = PromotionMessage;
  readonly PromotionMessageLocale = PromotionMessageLocale;

  addSurpriseBuyLoading$: Observable<boolean>;

  constructor(private store$: Store<AppState>) {
    this.addSurpriseBuyLoading$ = this.store$.select(selectAddSurpriseBuyLoading);
  }

  addSurpriseBuy() {
    this.store$.dispatch(addSurpriseBuy());
  }
}
