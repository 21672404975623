import { Injectable } from '@angular/core';
import { AppInitService } from '@core/services/app-init.service';
import { environment } from '@env';
import { NgxCookiebotConfig } from '@halloverden/ngx-cookiebot';

@Injectable({
  providedIn: 'root',
})
export class CookiebotConfigSerivce extends NgxCookiebotConfig {
  blockingMode: 'manual' = 'manual';
  cbId: string = environment.cookieBotId;
  cdn: 'com' | 'eu' | string = 'com';
  culture?: string = $localize`en`;
  loadScript: boolean;

  constructor(appInitService: AppInitService) {
    super();
    this.loadScript = appInitService.Settings.ec.isCookieBotEnabled;
  }
}
