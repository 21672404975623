<app-full-page-spinner
  *ngIf="(stepProcessing$ | async) || isPaymentProviderFirstLoading"
  [message]="isPaymentProviderFirstLoading ? '' : loadingWarningMessage"
></app-full-page-spinner>

<app-oxxo-voucher-payment-modal
  #voucherPaymentModal
  [paymentHandler]="paymentHandler"
  (closed)="onVoucherModalGotIt()"
></app-oxxo-voucher-payment-modal>

<app-consultant-submit-failed-modal
  [statusCode]="submitSnaStatusCode$ | async"
  #errorModal
></app-consultant-submit-failed-modal>
