import { Component, Input } from '@angular/core';
import { isUsaEnv } from '@shared/utils/environment-utils';

@Component({
  selector: 'app-cc-order-details-total',
  templateUrl: './cc-order-details-total.component.html',
  styleUrls: ['./cc-order-details-total.component.scss'],
})
export class CcOrderDetailsTotalComponent {
  @Input() merchandiseTotal: number;
  @Input() deliveryFeeTotal: number;
  @Input() salesTaxTotal: number;
  @Input() totalPrice: number;

  readonly isUsaEnv = isUsaEnv;

  constructor() {}
}
