import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MobileMedia } from '@core/constants/screen-sizes';
import { TableSortDirection } from '@core/enums/table-sort-direction.enum';
import { SortableTableColumn } from '@core/models/sortable-table-column.model';
import { AppState } from '@core/store';
import { selectAddresses, selectLoading } from '@core/store/address';
import { Address, AddressType } from '@core/store/address/address-state-models';
import { fetchAddresses } from '@core/store/address/address.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss'],
})
export class AddressesComponent implements OnInit {
  addresses$: Observable<Address[]>;
  loading$: Observable<boolean>;

  addressType = AddressType;

  columns: SortableTableColumn[] = [
    { field: 'name', headerName: $localize`Address Name`, sortable: true },
    { field: 'type', headerName: $localize`Type`, sortable: true, sort: TableSortDirection.asc },
    { field: 'address', headerName: $localize`Address`, sortable: true, dataPrivate: true },
    { field: 'city', headerName: $localize`City`, sortable: true, dataPrivate: true },
    { field: 'state', headerName: $localize`State`, sortable: true },
    { field: 'zipCode', headerName: $localize`Zip`, sortable: true },
    {
      field: 'id',
      headerName: '',
      action: true,
      actionIcon: 'bi bi-pen',
      ariaLabel: $localize`Edit shipping address`,
    },
  ];

  constructor(private store$: Store<AppState>, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.store$.dispatch(fetchAddresses());

    this.addresses$ = this.store$.select(selectAddresses);
    this.loading$ = this.store$.select(selectLoading);
  }

  isInMobileView(): boolean {
    return this.breakpointObserver.isMatched(MobileMedia);
  }
}
