/* eslint-disable indent */
import {
  CreateOrderRequest,
  CreatePaymentMethodRequest,
  CreateVoucherRequest,
  ReceiveVoucherRequest,
  ReceiveVouchersRequest,
  SaveLogCardAttemptRequest,
  SubmitApplicantAddress,
  UpdateOrderRequest,
} from '@core/dto/start-now-app.dto';
import { AddressType } from '@core/enums/address-type.enum';
import { PaymentMethodType } from '@core/enums/payment-method-type.enum';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PaymentCardType } from '@core/enums/payment-type.enum';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { PaymentInfo } from '@core/store/payment/payment-state-models';
import {
  selectClientToken,
  selectDepositPaymentValue,
  selectOrderSummary,
  selectPayPalOrder,
  selectReducedDepositPaymentValue,
  selectSecurityKey,
  selectStartNowAppData,
  selectVoucherPaymentInfo,
} from '@core/store/start-now-app';
import { Address } from '@core/store/start-now-app/start-now-app-state-models';
import {
  createOrder,
  createPaymentMethod,
  createVoucher,
  fetchClientToken,
  logCardAttempt,
  receiveVouchers,
  resetClientToken,
  resetPaymentMethod,
  resetReceiveVoucher,
  storeExternalPaymentInfo,
  storeExternalPaymentInfoFailure,
  updateOrder,
} from '@core/store/start-now-app/start-now-app.actions';
import { Store, select } from '@ngrx/store';
import {
  mapContactInfoToSubmitContactInfoDto,
  mapUserInfoToSubmitUserInfoParamsDto,
} from '@shared/utils/create-submit-consultant-info-utils';
import { isMexEnv, isUsaEnv } from '@shared/utils/environment-utils';
import { Observable } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { PaymentHandlerBase } from '../payment-handler-base.model';

export class StartNowAppPaymentHandler implements PaymentHandlerBase {
  constructor(
    public store$: Store<AppState>,
    private appInitService: AppInitService,
  ) {}

  public initToken(
    paymentProvider: PaymentProviderType,
    cardholderFirstName = '',
    cardholderLastName = '',
  ): Observable<string> {
    this.store$
      .select(selectStartNowAppData)
      .pipe(
        select((data) => data.applicationRegistrationId),
        take(1),
      )
      .subscribe((applicationRegistrationId) => {
        this.store$.dispatch(
          fetchClientToken({
            payload: {
              applicationRegistrationId,
              provider: paymentProvider,
              firstName: cardholderFirstName || undefined,
              lastName: cardholderLastName || undefined,
            },
          }),
        );
      });

    return this.store$.select(selectClientToken).pipe(
      filter((token) => !!token),
      take(1),
    );
  }

  resetToken(): void {
    this.store$.dispatch(resetClientToken());
  }

  isCvvRequired(): boolean {
    return this.appInitService.Settings.sna.isCvvRequired;
  }

  isAvsRequired(): boolean {
    return this.appInitService.Settings.sna.isAvsRequired;
  }

  collectRiskDataFromInput(): boolean {
    return this.appInitService.Settings.sna.collectRiskDataFromInput;
  }

  createOrder(): Observable<string> {
    this.store$
      .select(selectStartNowAppData)
      .pipe(
        filter((data) => !!data.startNowAppPaymentInfo.cardPaymentInfo),
        withLatestFrom(
          this.store$.select(selectDepositPaymentValue),
          this.store$.select(selectReducedDepositPaymentValue),
        ),

        take(1),
      )
      .subscribe(([data, depositPaymentValue, reducedDepositPaymentValue]) => {
        const totalAmount = data.selectedStarterKit.isDepositPayment
          ? depositPaymentValue
          : data.selectedStarterKit.isReducedDepositPayment
            ? reducedDepositPaymentValue
            : data.selectedStarterKit.price;
        const createOrderRequest: CreateOrderRequest = {
          address: this.selectHomeAddress(data.addressInfo.addresses),
          amount: totalAmount,
          phoneNumber: data.contactInfo.cellPhoneNumber,
          firstName: data.userInfo.firstName,
          lastName: data.userInfo.lastName,
          email: data.contactInfo.email,
          item: data.selectedStarterKit,
          applicationRegistrationId: data.applicationRegistrationId,
        };
        if (!!data?.startNowAppPaymentInfo?.externalPaymentInfo?.order?.orderId) {
          const updateOrderRequest: UpdateOrderRequest = {
            ...createOrderRequest,
            orderId: data.startNowAppPaymentInfo.externalPaymentInfo.order.orderId,
          };
          this.store$.dispatch(updateOrder({ payload: updateOrderRequest }));
        } else {
          this.store$.dispatch(createOrder({ payload: createOrderRequest }));
        }
      });

    return this.store$.select(selectPayPalOrder).pipe(
      filter((order) => !!order),
      take(1),
    );
  }

  updateOrder(): void {
    this.store$
      .select(selectStartNowAppData)
      .pipe(
        take(1),
        filter(
          (data) =>
            !!data.startNowAppPaymentInfo.cardPaymentInfo &&
            !!data.startNowAppPaymentInfo.externalPaymentInfo?.order?.orderId,
        ),
      )
      .subscribe((data) => {
        const totalAmount = data.selectedStarterKit.price;
        const updateOrderRequest: UpdateOrderRequest = {
          address: this.selectHomeAddress(data.addressInfo.addresses),
          amount: totalAmount,
          phoneNumber: data.contactInfo.cellPhoneNumber,
          firstName: data.userInfo.firstName,
          lastName: data.userInfo.lastName,
          email: data.contactInfo.email,
          item: data.selectedStarterKit,
          applicationRegistrationId: data.applicationRegistrationId,
          orderId: data.startNowAppPaymentInfo.externalPaymentInfo.order.orderId,
        };
        this.store$.dispatch(updateOrder({ payload: updateOrderRequest }));
      });
  }

  public handleRequestedPaymentMethodObject(error: object, paymentInfo: PaymentInfo): void {
    if (error) {
      this.store$.dispatch(storeExternalPaymentInfoFailure({ error }));
    } else {
      this.store$.dispatch(storeExternalPaymentInfo({ paymentInfo }));
    }
  }

  public createPaymentMethod(): void {
    this.store$.dispatch(resetPaymentMethod());

    this.store$
      .select(selectStartNowAppData)
      .pipe(
        filter((data) => !!data.startNowAppPaymentInfo.externalPaymentInfo),
        withLatestFrom(
          this.store$.select(selectDepositPaymentValue),
          this.store$.select(selectOrderSummary),
          this.store$.select(selectReducedDepositPaymentValue),
        ),
        take(1),
      )
      .subscribe(([data, depositPaymentValue, orderSummary, reducedDepositPaymentValue]) => {
        const totalCost = isUsaEnv ? orderSummary.totalCost : data.selectedStarterKit.price;
        const params: CreatePaymentMethodRequest = {
          customerId: data.startNowAppPaymentInfo.customerId,
          amount: data.selectedStarterKit.isDepositPayment
            ? depositPaymentValue
            : data.selectedStarterKit.isReducedDepositPayment
              ? reducedDepositPaymentValue
              : totalCost,
          paymentMethodType: this.getPaymentMethodType(
            data.startNowAppPaymentInfo.cardPaymentInfo.cardType,
          ),
          nonceFromTheClient: data.startNowAppPaymentInfo.externalPaymentInfo.nonce,
          orderId: data.startNowAppPaymentInfo.externalPaymentInfo.order?.orderId,
          deviceData: data.startNowAppPaymentInfo.externalPaymentInfo.deviceData,
          starterKit: {
            starterKitId: data.selectedStarterKit.id,
          },
          isDepositPayment: !!data.selectedStarterKit.isDepositPayment,
          isReducedDepositPayment: !!data.selectedStarterKit.isReducedDepositPayment,
          isZeroDepositPayment: !!data.selectedStarterKit.isZeroDepositPayment,
          recruiterConsultantId: data.selectedConsultant?.beeNumber,
          userInfo: mapUserInfoToSubmitUserInfoParamsDto(data.userInfo),
          addressInfo: {
            addresses: data.addressInfo.addresses,
            languagePreferred: data.addressInfo.languagePreferred,
          },
          contactInfo: mapContactInfoToSubmitContactInfoDto(data.contactInfo),
          applicationRegistrationId: data.applicationRegistrationId,
          registrationCode: data.registrationCode,
          securityKey: data.securityKey,
          provider: data.startNowAppPaymentInfo.provider,
          cardSaveRequest: data.startNowAppPaymentInfo.externalPaymentInfo.cardSaveRequest,
          billingFirstName:
            data.startNowAppPaymentInfo.additionalBillingInformation?.billingFirstName,
          billingLastName:
            data.startNowAppPaymentInfo.additionalBillingInformation?.billingLastName,
        };

        this.store$.dispatch(createPaymentMethod({ payload: params }));
      });
  }

  createVoucher(): void {
    this.store$
      .select(selectStartNowAppData)
      .pipe(
        filter(
          (data) =>
            !!data.userInfo &&
            !!data.startNowAppPaymentInfo.voucherPaymentInfo &&
            data.startNowAppPaymentInfo.voucherPaymentInfo.amount !== 0 &&
            !!data.startNowAppPaymentInfo.voucherPaymentInfo.email,
        ),
        take(1),
      )
      .subscribe((data) => {
        const payload: CreateVoucherRequest = {
          amount: data.selectedStarterKit.price,
          email: data.startNowAppPaymentInfo.voucherPaymentInfo.email,
          starterKitId: data.selectedStarterKit.id,
          recruiterConsultantId: data.selectedConsultant?.beeNumber,
          userInfo: mapUserInfoToSubmitUserInfoParamsDto(data.userInfo),
          addressInfo: {
            addresses: data.addressInfo.addresses,
            languagePreferred: data.addressInfo.languagePreferred,
          },
          contactInfo: mapContactInfoToSubmitContactInfoDto(data.contactInfo),
          applicationRegistrationId: data.applicationRegistrationId,
          registrationCode: data.registrationCode,
          securityKey: data.securityKey,
        };

        this.store$.dispatch(createVoucher({ payload }));
      });
  }

  receiveVoucher(token: string): void {
    const payload: ReceiveVoucherRequest = {
      token: token,
    };
    this.store$.dispatch(receiveVouchers({ vouchers: { vouchers: [payload] } }));
  }

  receiveVouchers(vouchers: ReceiveVouchersRequest): void {
    this.store$.dispatch(receiveVouchers({ vouchers }));
  }

  listenReceivedVouchers(): Observable<boolean> {
    return this.store$.select(selectVoucherPaymentInfo).pipe(
      select((voucherPaymentInfo) => voucherPaymentInfo.vouchersReceived),
      filter((vouchersReceived) => vouchersReceived !== null),
    );
  }

  resetReceiveVoucher(): void {
    this.store$.dispatch(resetReceiveVoucher());
  }

  logCardAttempt(
    isSuccess: boolean,
    response: string,
    provider: PaymentProviderType = PaymentProviderType.Default,
  ): void {
    this.store$
      .select(selectStartNowAppData)
      .pipe(
        select((data) => data.applicationRegistrationId),
        withLatestFrom(this.store$.select(selectSecurityKey)),
        take(1),
      )
      .subscribe(([applicationRegistrationId, securityKey]) => {
        const request: SaveLogCardAttemptRequest = {
          applicationRegistrationId: Number(applicationRegistrationId),
          success: isSuccess,
          response: response,
          securityKey: securityKey,
          provider,
        };
        this.store$.dispatch(logCardAttempt({ request }));
      });
  }

  private getPaymentMethodType(cardType: PaymentCardType): PaymentMethodType {
    const mexicoPaymentMethodType =
      cardType == PaymentCardType.CreditCard
        ? PaymentMethodType.CreditCard
        : PaymentMethodType.DebitCard;
    const usaPaymentMethodType = PaymentMethodType.CreditOrDebitCard;

    return isMexEnv ? mexicoPaymentMethodType : usaPaymentMethodType;
  }

  private selectHomeAddress(addresses: Address[]): SubmitApplicantAddress {
    return addresses.find((address) => address.addressType === AddressType.Home);
  }
}
