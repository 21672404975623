export enum PaymentType {
  CreditOrDebitCard = 'CreditOrDebitCard',
  PayPal = 'PayPal',
  PayByCash = 'PayByCash',
  Finance = 'Finance',
}

export enum PaymentCardType {
  CreditCard = 'CreditCard',
  DebitCard = 'DebitCard',
}

export enum PaymentAmountType {
  Balance = 'Balance',
  Custom = 'Custom',
}
