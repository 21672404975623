import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { AppState } from '@core/store';
import { selectIsAllMagazinesFetched, selectMagazines } from '@core/store/magazine';
import { Magazine } from '@core/store/magazine/magazine-state-models';
import * as magazineActions from '@core/store/magazine/magazine.actions';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-magazine-list',
  templateUrl: './magazine-list.component.html',
  styleUrls: ['./magazine-list.component.scss'],
})
export class MagazineListComponent implements OnInit, OnDestroy {
  magazines$: Observable<Magazine[]>;

  private readonly magazineFetchPageSize: number = 6;

  private isAllMagazinesFetched: Boolean = false;
  private nextFetchShouldStartFrom: number = 0;

  private storeSubject: Subject<void> = new Subject<void>();

  constructor(private store$: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const viewAs = this.route.snapshot.queryParamMap.get(RouterQueryParams.viewAs);
    this.magazines$ = this.store$.select(selectMagazines(viewAs));
    this.store$
      .select(selectIsAllMagazinesFetched)
      .pipe(takeUntil(this.storeSubject))
      .subscribe((x) => (this.isAllMagazinesFetched = x));

    this.nextFetchShouldStartFrom = 0;
    this.fetchNextPage();
  }

  ngOnDestroy(): void {
    this.store$.dispatch(magazineActions.resetMagazineStore());
    this.storeSubject.next();
    this.storeSubject.complete();
  }

  public onScrollDown(): void {
    if (this.isAllMagazinesFetched) {
      return;
    }

    this.nextFetchShouldStartFrom += this.magazineFetchPageSize;
    this.fetchNextPage();
  }

  private fetchNextPage(): void {
    this.store$.dispatch(
      magazineActions.fetchMagazinesPage({
        pageStart: this.nextFetchShouldStartFrom,
        pageSize: this.magazineFetchPageSize,
      }),
    );
  }
}
