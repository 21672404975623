import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentMethodTypeLocale } from '@core/constants/payment-method-type-locale';
import { MobileMedia } from '@core/constants/screen-sizes';
import { TableSortDirection } from '@core/enums/table-sort-direction.enum';
import { SortableTableColumn } from '@core/models/sortable-table-column.model';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { selectAddresses } from '@core/store/address';
import { Address } from '@core/store/address/address-state-models';
import { fetchAddresses } from '@core/store/address/address.actions';
import { selectPaymentMethodsState } from '@core/store/payment';
import { PaymentMethodItem, PaymentMethodType } from '@core/store/payment/payment-state-models';
import {
  deletePaymentMethod,
  fetchPaymentMethods,
  setAsDefault,
} from '@core/store/payment/payment.actions';
import { Store, select } from '@ngrx/store';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddressRequiredModalComponent } from './address-required-modal/address-required-modal.component';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent implements OnInit, OnDestroy {
  readonly paymentMethodType = PaymentMethodType;
  readonly PaymentMethodTypeLocale = PaymentMethodTypeLocale;

  readonly defaultCardTypeStyle = {
    background: '#626262',
    color: 'white',
    pointerEvents: 'none',
  };

  readonly cardTypeStyle = {
    cursor: 'pointer',
    border: '1px solid #626262',
    borderRadius: '3px',
    color: '#626262',
    fontSize: '0.8rem',
    justifyContent: 'center',
    padding: '0px 3px',
  };

  subscriptions: Subscription = new Subscription();
  paymentMethods: PaymentMethodItem[];
  addresses$: Observable<Address[]>;
  loading$: Observable<boolean>;
  loadingCombined$: Observable<boolean>;

  columns: SortableTableColumn[] = [
    {
      field: 'cardName',
      headerName: $localize`Cardholder name`,
      sortable: true,
      sort: TableSortDirection.asc,
    },
    { field: 'cardNumber', headerName: $localize`Card number`, sortable: true },
    {
      field: 'expiryDate',
      headerName: $localize`Expiry date`,
      sortable: true,
      styles: this.getExpiryDateFieldStyle.bind(this),
      tooltip: this.getTooltip.bind(this),
      warningIcon: this.expiredCondition.bind(this),
    },
    {
      field: 'type',
      headerName: '',
      sortable: true,
      action: true,
      styles: this.getTypeFieldStyle.bind(this),
      actionMethod: this.setAsDefault.bind(this),
      actionField: 'id',
      ariaLabel: $localize`Make default`,
    },
    {
      field: 'id',
      headerName: '',
      action: true,
      actionIcon: 'bi bi-trash',
      actionMethod: this.delete.bind(this),
      ariaLabel: $localize`Remove payment method`,
    },
  ];

  @ViewChild('confirmModal') private confirmModal: ConfirmModalComponent;
  @ViewChild('addressRequiredModal') private addressRequiredModal: AddressRequiredModalComponent;

  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private appInitService: AppInitService,
  ) {}

  ngOnInit(): void {
    this.store$.dispatch(fetchPaymentMethods());
    this.store$.dispatch(fetchAddresses());

    const paymentMethodState$ = this.store$.select(selectPaymentMethodsState);
    this.loading$ = paymentMethodState$.pipe(select((state) => state.loading));
    this.addresses$ = this.store$.select(selectAddresses);

    this.subscriptions.add(
      paymentMethodState$.pipe(select((state) => state.items)).subscribe((items) => {
        this.paymentMethods = items;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /** Add new card payment method */
  addCard() {
    const isAddressRequiredForAddCard = this.appInitService.Settings.ec.isFraudCheckRequired;
    if (isAddressRequiredForAddCard) {
      this.subscriptions.add(
        this.addresses$.pipe(take(1)).subscribe((addresses) => {
          if (addresses.length == 0) {
            this.addressRequiredModal.open();
          } else {
            this.navigateToAddNewPaymentMethod();
          }
        }),
      );
    } else {
      this.navigateToAddNewPaymentMethod();
    }
  }

  navigateToAddNewPaymentMethod() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  isInMobileView(): boolean {
    return this.breakpointObserver.isMatched(MobileMedia);
  }

  delete(paymentMethodItemId: string) {
    const paymentMethod = this.paymentMethods.find((pm) => pm.id === paymentMethodItemId);
    if (paymentMethod) this.confirmModal.open(paymentMethod);
  }

  expiredCondition(row: PaymentMethodItem) {
    return row.isExpired;
  }

  getTooltip(row: PaymentMethodItem) {
    if (row.isExpired) {
      return $localize`This payment method is expired!`;
    }
    return null;
  }

  getRowStyle(row: PaymentMethodItem) {
    if (row.isExpired) {
      return { background: 'pink' };
    }
    return {};
  }

  setAsDefault(paymentMethodItemId: string) {
    const paymentMethod = this.paymentMethods.find((pm) => pm.id === paymentMethodItemId);
    if (paymentMethod?.type !== PaymentMethodType.default && !paymentMethod.isExpired) {
      this.store$.dispatch(
        setAsDefault({ token: paymentMethod.id, provider: paymentMethod.provider }),
      );
    }
  }

  getCardTypeStyle(type: PaymentMethodType) {
    let mobileCardTypeStyle = this.cardTypeStyle;
    if (type === this.paymentMethodType.default) {
      mobileCardTypeStyle = { ...mobileCardTypeStyle, ...this.defaultCardTypeStyle };
    }
    return mobileCardTypeStyle;
  }

  onDeleteConfirm(item: PaymentMethodItem) {
    this.store$.dispatch(deletePaymentMethod({ token: item.id, provider: item.provider }));
  }

  private getExpiryDateFieldStyle(row: PaymentMethodItem): object {
    return row.isExpired ? { color: 'red' } : {};
  }

  private getTypeFieldStyle(row: PaymentMethodItem): object {
    if (row.isExpired) {
      return { visibility: 'hidden', width: 0, height: 0 };
    }

    return row.type === PaymentMethodType.default
      ? { ...this.cardTypeStyle, ...this.defaultCardTypeStyle }
      : this.cardTypeStyle;
  }
}
