import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FormErrorTypes } from '@core/enums/form-error-type.enum';

export function dropdownRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const selectedValue = control.value;
    if (!selectedValue) {
      return { [FormErrorTypes.dropdownRequired]: true };
    }
    return null;
  };
}
