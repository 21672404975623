import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-ph-spinner',
  templateUrl: './ph-spinner.component.html',
  styleUrls: ['./ph-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhSpinnerComponent {
  @Input() width: string = '50px';
  constructor() {}
}
