import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MaxProductQty } from '@core/constants/product.const';
import { MediaSuffix } from '@core/enums/media-suffix.enum';
import { ImageDetails } from '@core/models/image.model';
import { ModalConfig } from '@core/models/modal-config.model';
import { VimeoVideoDetails } from '@core/models/vimeo-video-details';
import { AppState } from '@core/store';
import { addToCart } from '@core/store/cart/cart.actions';
import { selectProductQuickView, selectProductQuickViewVideos } from '@core/store/product';
import { ProductQuickView } from '@core/store/product/product-state-models';
import { resetProductQuickView } from '@core/store/product/product.actions';
import { selectWishlist } from '@core/store/wishlist';
import { addToWishList, deleteFromWishlist } from '@core/store/wishlist/wishlist.actions';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { AltTextUtilService } from '@shared/utils/alt-text-util.service';
import { ProductUtilService } from '@shared/utils/product-util.service';

import { combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-product-quickview-dialog',
  templateUrl: './product-quickview-dialog.component.html',
  styleUrls: ['./product-quickview-dialog.component.scss'],
})
export class ProductQuickviewDialogComponent implements OnInit, OnDestroy {
  feature = environment.feature;
  subscriptions: Subscription = new Subscription();
  productQuickView$: Observable<ProductQuickView>;
  modalConfig: ModalConfig = {
    ngbModalOptions: {
      size: 'lg',
    },
    hideCloseButton: true,
    hideDismissButton: true,
    onDismiss: this.onDismiss.bind(this),
  };

  productDetailsUrl: string;
  isWishlisted: boolean;

  productImageSlides$: Observable<ImageDetails[][]>;
  productVideoSlides$: Observable<VimeoVideoDetails[]>;

  quantities: number[] = Array(MaxProductQty)
    .fill(0)
    .map((q, i) => i + 1);
  quantity: number = this.quantities[0];

  @ViewChild('modal') private modalComponent: ModalComponent;

  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private altTextUtil: AltTextUtilService,
    public productUtil: ProductUtilService,
  ) {}

  ngOnInit(): void {
    this.productQuickView$ = this.store$.select(selectProductQuickView);
    this.subscriptions.add(
      this.productQuickView$
        .pipe(distinctUntilChanged((prev, curr) => prev?.sku === curr?.sku))
        .subscribe((data) => {
          if (data) {
            this.productDetailsUrl = this.productUtil.createProductDetailsUrl(
              data.collectionUrlName,
              data.productUrlName,
              data.sku,
            );

            this.modalComponent.open();
          }
        }),
    );

    this.subscriptions.add(
      combineLatest([this.store$.select(selectWishlist), this.productQuickView$])
        .pipe(filter(([, data]) => !!data))
        .subscribe(([wishList, data]) => {
          const wishlistedItem = wishList.find((wishListItem) => wishListItem.sku === data.sku);
          if (!!wishlistedItem) {
            this.isWishlisted = true;
          } else {
            this.isWishlisted = false;
          }
        }),
    );

    this.productImageSlides$ = this.productQuickView$.pipe(
      filter((i) => !!i),
      map((i) => {
        const { name: productName, collection: collectionName } = i;
        return [
          this.altTextUtil.getProductImagesWithAltText(i.primaryImages, {
            productName,
            collectionName,
            suffix: MediaSuffix.primaryImage,
          }),
          ...i.additionalImages.map((image) =>
            this.altTextUtil.getProductImagesWithAltText(image, {
              productName,
              collectionName,
              suffix: MediaSuffix.secondaryImage,
            }),
          ),
        ];
      }),
    );

    this.productVideoSlides$ = this.store$.select(selectProductQuickViewVideos).pipe(
      withLatestFrom(this.productQuickView$),
      map(([videos, { name: productName, collection: collectionName }]) =>
        videos.map((video) =>
          this.altTextUtil.getVimeoDetailsWithAltText(video, { productName, collectionName }),
        ),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onDismiss(): void {
    this.store$.dispatch(resetProductQuickView());
  }

  viewFullDetails(): void {
    this.modalComponent.dismiss();
    this.router.navigate([this.productDetailsUrl]);
  }

  toggleWish(sku) {
    if (this.isWishlisted) {
      this.store$.dispatch(deleteFromWishlist({ sku }));
    } else {
      this.store$.dispatch(addToWishList({ sku }));
    }
    this.isWishlisted = !this.isWishlisted;
  }

  addToCart = (product: ProductQuickView): void => {
    this.store$.dispatch(
      addToCart({
        payload: {
          images: product.primaryImages,
          collection: product.collection,
          productName: product.name,
          sku: product.sku,
          discountedPrice: product.discountedPrice,
          price: product.price,
          quantity: this.quantity,
          categoryNames: product.categoryNames,
          type: product.type,
        },
      }),
    );
    this.modalComponent.dismiss();
  };
}
