export enum FormErrorTypes {
  required = 'required',
  email = 'email',
  emailConfirm = 'emailConfirm',
  maxlength = 'maxlength',
  minlength = 'minlength',
  nameExists = 'nameExists',
  restRequired = 'restRequired',
  passwordConfirm = 'passwordConfirm',
  pattern = 'pattern',
  passwordPattern = 'passwordPattern',
  interestRequired = 'interestRequired',
  max = 'max',
  dropdownRequired = 'dropdownRequired',
  snaRequired = 'snaRequired',
}
