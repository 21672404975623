import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { PaymentImageSrc } from '@core/constants/payment.consts';
import { ReceiveVoucherRequest } from '@core/dto/start-now-app.dto';
import { NounsType } from '@core/enums/nouns-type.enum';
import { ModalConfig } from '@core/models/modal-config.model';
import { NounsMessages } from '@core/models/nouns-messages.model';
import { LoggerService } from '@core/services/logger.service';
import { Voucher } from '@core/store/payment/payment-state-models';
import { PaymentHandlerBase } from '@payment/payment-handler/payment-handler-base.model';
import { ModalComponent } from '@shared/components/modal/modal.component';
import cloneDeep from 'lodash.clonedeep';
import { Subscription } from 'rxjs';

/**
 * Modal for voucher payment that is supported by Oxxo. Here the vouchers separately are opened in iFrames and they
 * are activated by generating the barCodeUrl.
 */
@Component({
  selector: 'app-oxxo-voucher-payment-modal',
  templateUrl: './oxxo-voucher-payment-modal.component.html',
  styleUrls: ['./oxxo-voucher-payment-modal.component.scss'],
})
export class OxxoVoucherPaymentModalComponent implements OnDestroy {
  @Input() paymentHandler: PaymentHandlerBase;
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  public readonly PaymentImageSrc = PaymentImageSrc;
  public readonly NounsType = NounsType;

  /** In case of prod as we get barCodeUrls (activate the vouchers), the processes by iFrame takes more time and
   * we have to wait a bit them, before we automatically close this modal */
  public readonly OxxoOperationDelay = 1000;

  /** There is a chance that the Oxxo operation waiting delay is not enough, so we will check if there are
   barCodeUrls, if there are not then we will wait by retrying to receive in the meantime keep alive the iFrames*/
  public readonly ReceiveRetry = 4;

  /** In some cases for the first iframe load can not activate the voucher, but for the second can*/
  public readonly ReloadVouchers = true;

  public isVoucherLoading: boolean = true;
  public vouchers: Voucher[] = [];
  public activatedVoucherTokens: string[] = [];
  public oxxoOperationDelayPeriod = 0;
  public readonly VoucherMessages: NounsMessages = {
    [NounsType.Singular]: $localize`Your voucher is ready, you will have access to
    it via e-mail or click link on below:`,
    [NounsType.Plural]: $localize`Your vouchers are ready, you will have access to
    them via e-mail or click links on below:`,
  };

  public readonly ModalConfig: ModalConfig = {
    title: $localize`Voucher`,
    hideDismissButton: true,
    hideCloseButton: true,
    hideHeaderDismissButton: true,
    ngbModalOptions: {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    },
  };

  protected areVouchersReloaded: boolean = false;
  protected subscriptions: Subscription = new Subscription();
  protected receiveVoucherRetryCounter: number = 0;
  protected listenVouchersSubscription: Subscription;

  @ViewChild('modal') private modalComponent: ModalComponent;

  constructor(private logger: LoggerService) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /** Handle loaded vouchers: after that a voucher is loaded in the iframe, here is checked if all of them are loaded.
   * With a delay mechanism we are communicating with the BE to check if that on PayPal side the voucher is also
   * activated after that in iframe is loaded, because after the loading the activation process takes time
   */
  handleLoadedVoucher(voucherToken: string) {
    if (voucherToken) {
      if (this.activatedVoucherTokens.indexOf(voucherToken) === -1 && !!voucherToken) {
        this.activatedVoucherTokens.push(voucherToken);
      }
      if (this.activatedVoucherTokens.length === this.vouchers.length) {
        this.handleReceiveVoucherMechanism();
      }
    } else if (this.activatedVoucherTokens.length) {
      this.handleReceiveVoucherMechanism();
    } else {
      this.reloadVouchers();
    }
  }

  onClose(): void {
    // As the modal can be reopened more times on the same component have to unsubscribe just the current subscription
    this.listenVouchersSubscription.unsubscribe();
    this.closed.emit();
    this.modalComponent?.close();
  }

  openVoucher(link: string): void {
    window.open(link);
  }

  async open(vouchers: Voucher[]): Promise<boolean> {
    this.vouchers = vouchers;
    this.receiveVoucherRetryCounter = 0;
    this.oxxoOperationDelayPeriod = this.OxxoOperationDelay;

    // Listen the received status just when the modal is opened
    this.listenReceivedVoucher();

    return await this.modalComponent.open();
  }

  /** Handle the receive voucher BE check by a delay mechanism */
  private handleReceiveVoucherMechanism() {
    setTimeout(() => {
      this.receiveVouchers();
    }, this.oxxoOperationDelayPeriod);
  }

  /** Listen received (activated) vouchers with a retry logic. If they are activated, then automatically close the
   modal. Otherwise may be that the vouchers by the iFrames are loaded, but not processed yet and by a retry logic we
   will give time for processing.
   */
  private listenReceivedVoucher() {
    this.listenVouchersSubscription = this.paymentHandler
      .listenReceivedVouchers()
      .subscribe((vouchersReceived) => {
        if (vouchersReceived) {
          this.isVoucherLoading = false;

          /* TODO: solution for PHS-27: close automatically the modal, to avoid unfinished submit state in case of
           closing the tab. Later-on may be the modal will be removed and in the background will the handled the
           Voucher activation */
          this.onClose();
        } else {
          // Retry logic until the iframe activates the voucher
          this.receiveVoucherRetryCounter++;
          if (this.receiveVoucherRetryCounter <= this.ReceiveRetry) {
            this.oxxoOperationDelayPeriod += this.OxxoOperationDelay / 2;
            this.logger.warn(
              'Retry receive voucher until wait for Oxxo voucher activation by iFrame',
            );
            setTimeout(() => {
              this.receiveVouchers();
            }, this.oxxoOperationDelayPeriod);
          } else {
            if (this.ReloadVouchers && !this.areVouchersReloaded) {
              this.reloadVouchers();
            } else {
              this.onClose();
            }
          }
        }
      });

    this.subscriptions.add(this.listenVouchersSubscription);
  }

  private receiveVouchers() {
    // Clean up the store to be notified by the existing subscriber
    this.paymentHandler.resetReceiveVoucher();

    let vouchers: ReceiveVoucherRequest[] = [];
    this.activatedVoucherTokens.forEach((token) => {
      vouchers = [...vouchers, { token: token }];
    });

    this.paymentHandler.receiveVouchers({ vouchers });
  }

  private reloadVouchers() {
    this.logger.warn(
      'Failed to activate the voucher by the first iframe loading, try it for the second time',
    );
    const vouchersToReload = cloneDeep(this.vouchers);
    this.vouchers = [];
    // waits for the oxxo-container component to re-init by removing the existing vouchers
    setTimeout(() => {
      this.vouchers = vouchersToReload;
      this.receiveVoucherRetryCounter = 0;
      this.oxxoOperationDelayPeriod = this.OxxoOperationDelay;
      this.areVouchersReloaded = true;
    });
  }
}
