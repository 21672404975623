import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContactUsState } from './contact-us-state-models';

export const contactUsFeatureKey = 'contactUsState';

export const selectContactUsStateFeature =
  createFeatureSelector<ContactUsState>(contactUsFeatureKey);

export const selectContactUsSendingMessage = createSelector(
  selectContactUsStateFeature,
  (state: ContactUsState) => (state ? state.sendingMessage : null),
);
