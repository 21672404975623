import { ContactUsRequest } from '@core/dto/contact-us.dto';
import { createAction, props } from '@ngrx/store';

export enum ContactUsActionType {
  sendEmail = '[Contact Us] Send email',
  sendEmailSuccess = '[Contact Us] Send email Success',
  sendEmailFailure = '[Contact Us] Send email Failure',

  resetContactUsState = '[Contact Us] Reset contact us state',
}

export const sendEmail = createAction(
  ContactUsActionType.sendEmail,
  props<{ contactUsRequest: ContactUsRequest }>(),
);
export const sendEmailSuccess = createAction(ContactUsActionType.sendEmailSuccess);
export const sendEmailFailure = createAction(ContactUsActionType.sendEmailFailure);

export const resetContactUsState = createAction(ContactUsActionType.resetContactUsState);
