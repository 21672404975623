<app-modal #offlineModal [modalConfig]="offlineModalConfig">
  <div class="p-3">
    <div class="row">
      <div class="col-12 text-center">
        <i class="bi bi-exclamation-triangle text-danger h1"></i>
        <div class="mt-3">
          {{ message }}
        </div>
        <div *ngIf="message === offlineMessages.AfterCardPayment">
          <a href="tel:{{ phoneNumber }}" class="b-block">{{ phoneNumber }}</a>
          <span class="text-double-dot d-block" i18n>Your application reference number is</span>
          <span class="font-weight-bold">
            {{ applicationRegistrationId }}
          </span>
        </div>
      </div>
    </div>
  </div>
</app-modal>
