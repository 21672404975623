<app-modal #reviewModal [modalConfig]="modalConfig">
  <div class="row align-items-center" *ngIf="!!productDetailsItem">
    <div class="col-3 pr-0">
      <img
        class="img-fluid"
        phImage
        [images]="productDetailsItem.primaryImages || imagePlaceholder"
        [thumbnailDesktop]="Thumbnail.productListDesktop"
        [thumbnailMobile]="Thumbnail.productListMobile"
      />
    </div>
    <div class="col">
      <div>{{ productDetailsItem.collection }}</div>
      <div class="font-weight-bold">
        {{ productDetailsItem.name }}
      </div>
      <div>#{{ productDetailsItem.sku }}</div>
    </div>
    <div class="col-4 align-items-end text-right">
      <div *ngIf="readOnly; else rateInput">
        <span class="font-weight-bold" i18n>Rating</span>
      </div>
      <ng-template #rateInput>
        <span class="font-weight-bold" i18n>Rate this item</span>
      </ng-template>
      <app-star-rating
        [rate]="rate"
        [readonly]="readOnly"
        (rateChange)="onRateChange($event)"
      ></app-star-rating>
    </div>
  </div>
  <form [formGroup]="reviewFormGroup">
    <div class="row mt-4">
      <div class="col col-md-8">
        <app-text-field-input
          [identifier]="ReviewTitle"
          [label]="reviewTitleLabel"
          [formControlName]="ReviewTitle"
          [placeholder]="ReviewTitlePlaceHolder"
          [readonly]="readOnly"
        ></app-text-field-input>
      </div>
    </div>
    <div class="mt-4 d-flex justify-content-between">
      <div>
        <label [for]="Review">{{ reviewContentLabel }}</label>
      </div>
      <div *ngIf="!readOnly; else reviewDate" class="form-check custom-checkbox">
        <input
          class="form-check-input custom-control-input"
          type="checkbox"
          [formControlName]="IsAnonymous"
          [id]="IsAnonymous"
        />
        <label class="form-check-label custom-control-label" [for]="IsAnonymous" i18n>
          Anonymous review
        </label>
      </div>
      <ng-template #reviewDate>
        <ng-template *ngIf="isMexEnv; else usaDate">
          {{ review.date | date: 'dd/MM/yyyy' }}
        </ng-template>
        <ng-template #usaDate>
          {{ review.date | date: 'MM/dd/yyyy' }}
        </ng-template>
      </ng-template>
    </div>
    <div class="row">
      <div class="col">
        <app-textarea
          [identifier]="Review"
          [formControlName]="Review"
          [placeholder]="ReviewContentPlaceHolder"
          [readonly]="readOnly"
          rows="5"
        ></app-textarea>
      </div>
    </div>
  </form>

  <div
    class="row"
    [ngClass]="{
      'justify-content-center': readOnly
    }"
  >
    <div
      class="col"
      [ngClass]="{
        'col-4': readOnly
      }"
    >
      <button class="btn btn-outline-dark text-uppercase w-100 h-100" (click)="onDismiss()">
        {{ modalDismissText }}
      </button>
    </div>
    <div *ngIf="!readOnly" class="col">
      <button
        id="addReview"
        class="btn btn-primary text-uppercase w-100 h-100"
        (click)="addReview()"
        [disabled]="!rate"
        i18n
      >
        Add Review
      </button>
    </div>
  </div>
</app-modal>
