/* eslint-disable max-len */
import { CreateVoucherErrorFrequency } from '@core/constants/create-voucher-error-frequency';
import { ConsultantSubmissionErrorType } from '@core/enums/consultant-submission-error-type.enum';
import { EcCreateVoucherPaymentErrorType } from '@core/enums/create-voucher-error-type.enum';
import { VoucherLimits } from '@core/models/app-settings.model';

type Error = EcCreateVoucherPaymentErrorType | ConsultantSubmissionErrorType;

function CreateVoucherErrorMessage(frequency: string, amount: number, email: string): string {
  if (email) {
    return $localize`${frequency} voucher limit on ${email} reached, the amount should be less than or equal to ${amount}`;
  } else {
    return $localize`${frequency} voucher limit reached, the amount should be less than or equal to ${amount}`;
  }
}

export function getVoucherErrorMessage(
  errors: Error[],
  email: string,
  voucherLimits: VoucherLimits,
): string {
  if (!errors?.length) {
    return null;
  }

  switch (true) {
    case errors.includes(EcCreateVoucherPaymentErrorType.DailyLimitReached):
    case errors.includes(ConsultantSubmissionErrorType.DailyVoucherAmountReached):
      return CreateVoucherErrorMessage(
        CreateVoucherErrorFrequency.daily,
        voucherLimits.dailyLimit,
        email,
      );

    case errors.includes(EcCreateVoucherPaymentErrorType.MonthlyLimitReached):
    case errors.includes(ConsultantSubmissionErrorType.MonthlyVoucherAmountReached):
      return CreateVoucherErrorMessage(
        CreateVoucherErrorFrequency.monthly,
        voucherLimits.monthlyLimit,
        email,
      );

    case errors.includes(EcCreateVoucherPaymentErrorType.YearlyLimitReached):
    case errors.includes(ConsultantSubmissionErrorType.YearlyVoucherAmountReached):
      return CreateVoucherErrorMessage(
        CreateVoucherErrorFrequency.yearly,
        voucherLimits.yearlyLimit,
        email,
      );

    default:
      return null;
  }
}
