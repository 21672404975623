<ng-template #starTemplate let-fill="fill">
  <span class="star" [class.full-star]="fill === 100">
    <span class="half-star" [style.width.%]="fill">
      <em class="bi bi-star-fill star-color"></em>
    </span>
    <em class="bi bi-star star-color cursor"></em>
  </span>
</ng-template>

<ngb-rating
  [rate]="rate"
  (rateChange)="onRateChange($event)"
  [starTemplate]="starTemplate"
  [readonly]="readonly"
  [max]="max"
></ngb-rating>
