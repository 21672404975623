import { WebsiteMode } from '@core/enums/website-mode.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectWebsiteMode } from './../website-mode/index';
import { FooterState } from './footer.reducer';

export const footerStateFeatureKey = 'footerState';

export const selectFooterFeature = createFeatureSelector<FooterState>(footerStateFeatureKey);

export const selectPrimaryFooterLinks = createSelector(
  selectFooterFeature,
  selectWebsiteMode,
  /* eslint-disable indent */
  (state: FooterState, websiteMode: WebsiteMode) =>
    state && state.primaryFooterLinks
      ? state.primaryFooterLinks.filter(
          (s) => (Number(s.websiteMode) | websiteMode) === Number(s.websiteMode),
        )
      : null,
  /* eslint-enable indent */
);

export const selectSecondaryFooterLinks = createSelector(
  selectFooterFeature,
  selectWebsiteMode,
  /* eslint-disable indent */
  (state: FooterState, websiteMode: WebsiteMode) =>
    state && state.secondaryFooterLinks
      ? state.secondaryFooterLinks.filter(
          (s) => (Number(s.websiteMode) | websiteMode) === Number(s.websiteMode),
        )
      : null,
  /* eslint-enable indent */
);
