import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['/star-rating.component.scss'],
})
export class StarRatingComponent {
  readonly MaxStar = 5;

  @Input() rate = 0;
  @Input() readonly = true;
  @Input() max = this.MaxStar;
  @Output() rateChange: EventEmitter<number> = new EventEmitter();

  onRateChange($event: number) {
    this.rateChange.emit($event);
  }
}
