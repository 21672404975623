import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VimeoDto } from '@core/dto/vimeo.dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VimeoService {
  readonly VimeoApiUrl: string = 'https://vimeo.com/api/oembed.json';

  fetchVimeoThumbnail(id: string): Observable<string> {
    return this.http
      .get<VimeoDto>(`${this.VimeoApiUrl}?url=https://vimeo.com/${id}`)
      .pipe(map((response) => response.thumbnail_url_with_play_button));
  }

  constructor(private http: HttpClient) {}
}
