export enum Thumbnail {
  categoryListDesktop = 'CategoryListDesktop',
  categoryListMobile = 'CategoryListMobile',
  productListDesktop = 'ProductListDesktop',
  productListMobile = 'ProductListMobile',
  productDetailMainDesktop = 'ProductDetailMainDesktop',
  productDetailMainMobile = 'ProductDetailMainMobile',
  productDetailThumbnailDesktop = 'ProductDetailThumbnailDesktop',
  carouselDesktop = 'CarouselDesktop',
  carouselMobile = 'CarouselMobile',
  productCheckoutDesktop = 'ProductCheckoutDesktop',
  productCheckoutMobile = 'ProductCheckoutMobile',
  productCcDesktop = 'ProductCcDesktop',
  recipeListDesktop = 'RecipeListDesktop',
  recipeListMobile = 'RecipeListMobile',
  recipeMainDesktop = 'RecipeMainDesktop',
  recipeMainMobile = 'RecipeMainMobile',
}
