import { environment } from '@env';
import { MetaReducer } from '@ngrx/store';
import produce from 'immer';
import { Magazine } from './magazine-state-models';
import { MagazineActionTypes } from './magazine.actions';

export interface MagazineState {
  items: Magazine[];
  isAllFetched: boolean;
  error: any;
}

export const initialState: MagazineState = {
  items: [],
  isAllFetched: false,
  error: null,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case MagazineActionTypes.fetchMagazinePageSuccess:
      draft.items = draft.items
        .concat(action.magazines)
        .sort((a, b) => +new Date(b.dateCreated) - +new Date(a.dateCreated));
      draft.isAllFetched = action.magazines.length < action.pageSize;
      return draft;

    case MagazineActionTypes.fetchMagazinesPageError:
      draft.error = action.error;
      return draft;

    case MagazineActionTypes.resetMagazineStore:
      draft.items = initialState.items;
      draft.isAllFetched = initialState.isAllFetched;
      return draft;

    default:
      return draft;
  }
}, initialState);

export const metaReducers: MetaReducer<MagazineState>[] = !environment.production ? [] : [];
