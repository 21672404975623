<ng-container *ngIf="control">
  <label
    *ngIf="!!label"
    [for]="identifier"
    [ngClass]="{ 'font-weight-bold': !valid || !validGroup, 'text-asterix': labelAsterix }"
    [ngStyle]="labelStyle"
    >{{ label }}</label
  >
  <div class="input-group">
    <input
      [id]="identifier"
      type="text"
      class="form-control"
      [ngClass]="{ 'error-border border-right-0': control.touched && !control.valid }"
      placeholder="XXX-XX-XXXX"
      [formControl]="control"
      maxlength="11"
      data-private
      mask="XXX-XX-0000"
      [hiddenInput]="true"
      [patterns]="ssnPattern"
      [dropSpecialCharacters]="false"
    />
    <div class="input-group-append" *ngIf="control.touched && !control.valid">
      <span class="input-group-text bg-transparent error-border border-left-0">
        <i class="bi bi-exclamation-triangle error-color"></i>
      </span>
    </div>
  </div>
  <div *ngIf="control.touched && !control.valid" class="validation-error-message">
    <span>{{ validationErrorMessage }}</span>
  </div>
</ng-container>
