import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultantRoutes } from '@core/constants/consultant-routes';
import { AppState } from '@core/store';
import { selectParty } from '@core/store/consultant';
import { Party } from '@core/store/consultant/consultant-state-models';
import { resetParty } from '@core/store/consultant/consultant.actions';
import { Store } from '@ngrx/store';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-leave-party-popup',
  templateUrl: './leave-party-popup.component.html',
  styleUrls: ['./leave-party-popup.component.scss'],
})
export class LeavePartyPopupComponent implements OnInit {
  party$: Observable<Party>;
  @ViewChild('modal') private modal: ConfirmModalComponent;

  constructor(private store$: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.party$ = this.store$.select(selectParty);
  }

  open() {
    this.modal.open();
  }

  leaveParty() {
    this.store$.dispatch(resetParty());
    this.router.navigate([ConsultantRoutes.MyOpenPartiesRoute]);
  }
}
