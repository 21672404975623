import {
  AfterViewInit,
  Component,
  ElementRef,
  Host,
  Input,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { getValidationErrorMessageList, validInput } from '@shared/utils/validation.utils';

@Component({
  selector: 'app-number-field-input',
  templateUrl: './number-field-input.component.html',
  styleUrls: ['./number-field-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumberFieldInputComponent,
      multi: true,
    },
  ],
})
export class NumberFieldInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  identifier: string;

  @Input()
  label: string;

  @Input()
  placeholder: string;

  @Input()
  formErrorMessages: { [key: string]: string } = FormErrorMessages;

  @Input()
  formControlName: string;

  @Input()
  readonly: boolean = false;

  @Input()
  labelStyle: any = {};

  @Input() min: number;

  @Input() max: number;

  @Input() onlyPositiveNumbers: boolean = false;

  @ViewChild('input') input: ElementRef;

  disabled = false;
  control: AbstractControl;

  getErrorMessages = getValidationErrorMessageList;

  value: string;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  ngAfterViewInit(): void {
    this.control = this.controlContainer?.control.get(this.formControlName);
  }

  onChange: (value: string) => void = () => {};
  onTouched = () => {};

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateValue(event: any): void {
    this.value = event.target.value;
    this.onChange(this.value);
    this.onTouched();
  }

  onKeyDown(event: any) {
    if (this.onlyPositiveNumbers && event.key === '-') {
      event.preventDefault();
    }
  }

  get isValidInput(): boolean {
    if (this.control) {
      return validInput(this.control);
    }
    return true;
  }

  get errorMessages(): string[] {
    return getValidationErrorMessageList(this.control, this.formErrorMessages);
  }
}
