import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ConditionalContentsResponse } from '@core/dto/conditional-content.dto';
import { Locale } from '@core/enums/locale';
import { WebsiteMode } from '@core/enums/website-mode.enum';
import { ConditionalContent } from '@core/store/conditional-content/conditional-content-state-models';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { keysToCamelCase } from '@shared/utils/object-transform-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConditionalContentService {
  baseUrl: string = environment.ecommerceBackendUri;
  culture: Locale;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private localeId: Locale) {
    this.culture = getCulture(localeId);
  }

  fetchConditionalContentByUrlName(urlName: string): Observable<ConditionalContent[]> {
    let params = this.getParams(`UrlName eq '${urlName}'`);
    return this.http
      .get<ConditionalContentsResponse>(this.baseUrl + 'api/default/conditionalcontents', params)
      .pipe(map((res) => keysToCamelCase(res.value)));
  }

  fetchConditionalContentByPageCategory(
    pageCategory: string,
    date: string,
  ): Observable<ConditionalContent[]> {
    return this.http
      .get<ConditionalContentsResponse>(this.baseUrl + 'api/conditional-content', {
        params: new HttpParams({
          fromObject: { culture: this.culture, pageCategory, date },
        }),
      })
      .pipe(
        map((res) => keysToCamelCase(res.value)),
        map((conditionalContents) =>
          conditionalContents.map((conditionalContent) => ({
            ...conditionalContent,
            websiteMode: conditionalContent.websiteMode.reduce(
              (accumulator, a) => accumulator + WebsiteMode[a],
              0,
            ),
          })),
        ),
      );
  }

  private getParams($filter: string = 'true'): object {
    return {
      params: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        sf_culture: this.culture,
        $expand: 'MetaPagePreviewImage($select=Url, ThumbnailUrl), PageCategories($select=Name)',
        $select:
          'Title,EndDate,StartDate,Content,WebsiteMode,StyleInjection,' +
          'CodeInjection,DisplaySequence,MetaPageTitle,MetaPageDescription,UrlName',
        $orderBy: 'DisplaySequence',
        $filter,
      },
    };
  }
}
