import { Component, Input } from '@angular/core';
import { isUsaEnv } from '@shared/utils/environment-utils';

@Component({
  selector: 'app-order-details-total',
  templateUrl: './order-details-total.component.html',
  styleUrls: ['./order-details-total.component.scss'],
})
export class OrderDetailsTotalComponent {
  @Input() promotionalDiscount: number;
  @Input() merchandiseTotal: number;
  @Input() deliveryFeeTotal: number;
  @Input() salesTaxTotal: number;
  @Input() totalPrice: number;

  readonly isUsaEnv = isUsaEnv;

  constructor() {}
}
