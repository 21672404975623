import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { Magazine } from './magazine-state-models';

export enum MagazineActionTypes {
  fetchMagazinesPage = '[Magazine] Fetch magazines page',
  fetchMagazinePageSuccess = '[Magazine] Fetch magazines page success',
  fetchMagazinesPageError = '[Magazine] Fetch magazines page error',
  resetMagazineStore = '[MagazineList Component] Reset magazine store',
}

export const fetchMagazinesPage = createAction(
  MagazineActionTypes.fetchMagazinesPage,
  props<{ pageStart: number; pageSize: number }>(),
);

export const fetchMagazinePageSuccess = createAction(
  MagazineActionTypes.fetchMagazinePageSuccess,
  props<{ magazines: Magazine[]; pageSize: number }>(),
);

export const fetchMagazinesPageError = createAction(
  MagazineActionTypes.fetchMagazinesPageError,
  props<{ error: HttpErrorResponse }>(),
);

export const resetMagazineStore = createAction(MagazineActionTypes.resetMagazineStore);
