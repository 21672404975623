export const GenericErrorMessage = {
  title: $localize`Something went wrong.`,
  message: $localize`Please try again later!`,
};

export const SnaDisabledErrorMessage = {
  title: $localize`Maintenance in progress.`,
  message: $localize`Please try again later!`,
};

export const CheckSnaCanSubmitGenericErrorMessage = {
  title: $localize`Consultant submission check failed`,
  message: $localize`Please try again later or contact Princess House customer service!`,
};

export const SnaSubmitGenericErrorMessage = {
  title: $localize`Consultant submission failed`,
  message: $localize`Please try again later or contact Princess House customer service!`,
};

export const MaintenanceErrorMessage = $localize`We apologize, due to a temporary
maintenance we currently cannot process new applications. Please try later.`;
