import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DefaultImagePlaceholderSrc } from '@core/constants/image-placeholders';
import { CreateProductReviewRequest } from '@core/dto/product-review.dto';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { ModalConfig } from '@core/models/modal-config.model';
import { AppState } from '@core/store';
import { ProductReview } from '@core/store/product-review/product-review-state-models';
import { createProductReview } from '@core/store/product-review/product-review.action';
import { ProductDetailsItem } from '@core/store/product/product-state-models';
import { Store } from '@ngrx/store';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { isMexEnv } from '@shared/utils/environment-utils';

@Component({
  selector: 'app-product-review-modal',
  templateUrl: './product-review-modal.component.html',
  styleUrls: ['./product-review-modal.component.scss'],
})
export class ProductReviewModalComponent implements OnInit, OnChanges {
  @Input() productDetailsItem: ProductDetailsItem;
  @Input() readOnly: boolean;
  @Input() review?: ProductReview;

  readonly isMexEnv = isMexEnv;
  readonly ReviewTitle: string = 'reviewTitle';
  readonly Review: string = 'review';
  readonly IsAnonymous: string = 'isAnonymous';
  readonly ReviewTitlePlaceHolder = $localize`Give your review a catchy title`;
  readonly ReviewContentPlaceHolder = $localize`Share your experience with this product!\
  What did you like or dislike about it? Would you recommend it to others? Write your review here.`;
  readonly imagePlaceholder = DefaultImagePlaceholderSrc;
  readonly Thumbnail = Thumbnail;

  reviewTitleLabel = '';
  reviewContentLabel = '';
  modalDismissText = '';
  rate: number = 0;
  reviewFormGroup: FormGroup;
  modalConfig: ModalConfig = {
    title: '',
    hideCloseButton: true,
    hideDismissButton: true,
    onDismiss: this.onDismiss.bind(this),
    ngbModalOptions: {
      size: 'md',
    },
  };

  @ViewChild('reviewModal') private reviewModal: ModalComponent;

  constructor(private fb: FormBuilder, private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.reviewFormGroup = this.createForm();
    if (this.readOnly && this.review) {
      this.initReadOnlyModal();
    } else {
      this.modalConfig.title = $localize`Add Review`;
      this.modalDismissText = $localize`Cancel`;
      this.reviewTitleLabel = $localize`Review Title (optional)`;
      this.reviewContentLabel = $localize`Review (optional)`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.readOnly && this.reviewFormGroup && changes.review.currentValue) {
      this.initReadOnlyModal();
    }
  }

  openModal(): void {
    if (this.readOnly && this.review) {
      this.initReadOnlyModal();
    }
    this.reviewModal.open();
  }

  onDismiss(): void {
    this.reviewModal?.close();
    this.resetForm();
  }

  addReview(): void {
    if (!!this.rate) {
      const request: CreateProductReviewRequest = {
        rating: this.rate,
        reviewHeadline: this.reviewFormGroup.get(this.ReviewTitle).value,
        reviewText: this.reviewFormGroup.get(this.Review).value,
        sku: this.productDetailsItem.sku,
        isAnonymous: this.reviewFormGroup.get(this.IsAnonymous).value,
      };
      this.store$.dispatch(createProductReview({ request }));
      this.onDismiss();
    }
  }

  onRateChange(event): void {
    this.rate = event;
  }

  private initReadOnlyModal(): void {
    this.reviewTitleLabel = $localize`Review Title`;
    this.reviewContentLabel = $localize`Review`;
    this.modalConfig.title = this.review.firstName
      ? $localize`${this.review.firstName}'s Review`
      : $localize`Anonymous`;
    this.modalDismissText = $localize`Close`;
    this.rate = this.review.rating;
    this.reviewFormGroup?.get(this.ReviewTitle).setValue(this.review.reviewHeadline);
    this.reviewFormGroup?.get(this.Review).setValue(this.review.reviewText);
  }

  private createForm(): FormGroup {
    return this.fb.group({
      [this.ReviewTitle]: [''],
      [this.Review]: [''],
      [this.IsAnonymous]: [false],
    });
  }

  private resetForm(): void {
    this.reviewFormGroup.setValue({
      [this.ReviewTitle]: '',
      [this.Review]: '',
      [this.IsAnonymous]: false,
    });
    this.rate = 0;
  }
}
