import { Component, OnInit } from '@angular/core';
import { URL } from '@core/constants/url.const';
import { FooterBackgroundShade } from '@core/enums/footer-bg-shade.enum';
import { AppState } from '@core/store';
import { selectSecondaryFooterLinks } from '@core/store/footer';
import { FooterLink } from '@core/store/footer/footer-state-models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-start-now-app-layout',
  templateUrl: './start-now-app-layout.component.html',
  styleUrls: ['./start-now-app-layout.component.scss'],
})
export class StartNowAppLayoutComponent implements OnInit {
  readonly shade = FooterBackgroundShade;

  links$: Observable<FooterLink[]>;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.links$ = this.store$
      .select(selectSecondaryFooterLinks)
      .pipe(
        map((links) =>
          links?.filter((link) => [URL.PrivacyPolicy, URL.TermsOfUse].includes(link.url)),
        ),
      );
  }
}
