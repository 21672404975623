import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalConfig } from '@core/models/modal-config.model';
import { AppState } from '@core/store';
import { selectOpenPartyVerificationFailedModal } from '@core/store/consultant';
import { resetOpenPartyVerificationFailedModal } from '@core/store/consultant/consultant.actions';
import { Store } from '@ngrx/store';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-party-verification-failed-modal',
  templateUrl: './party-verification-failed-modal.component.html',
  styleUrls: ['./party-verification-failed-modal.component.scss'],
})
export class PartyVerificationFailedModalComponent implements AfterViewInit, OnDestroy {
  @Output() navigateBack: EventEmitter<void> = new EventEmitter<void>();

  readonly ModalConfig: ModalConfig = {
    title: $localize`Technical Error`,
    hideCloseButton: true,
    hideDismissButton: true,
    hideHeaderDismissButton: true,
    ngbModalOptions: {
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-width',
    },
  };

  @ViewChild('modal') private modal: ModalComponent;
  private subscriptions: Subscription = new Subscription();

  constructor(private store$: Store<AppState>) {}

  close(): void {
    this.modal.close();
    this.store$.dispatch(resetOpenPartyVerificationFailedModal());
    this.navigateBack.emit();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.store$
        .select(selectOpenPartyVerificationFailedModal)
        .pipe(filter((open) => open))
        .subscribe(() => {
          this.modal.open();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
