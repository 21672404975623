<app-modal #modal [modalConfig]="modalConfig">
  <div *ngIf="userAddresses?.length === 0">
    <span i18n>
      You don't have any saved addresses. You can add one under your account page's My Shipping
      Addresses menu.
    </span>
  </div>
  <div class="container">
    <div class="row" ngbRadioGroup [(ngModel)]="selectedAddressId">
      <div class="col-md-6 pb-3" *ngFor="let address of userAddresses">
        <div class="row address mr-md-1">
          <div class="d-flex align-items-center mx-3">
            <label class="p-0 m-0 shadow-none" ngbButtonLabel>
              <input
                id="{{ address.id }}"
                class="radio-button"
                ngbButton
                type="radio"
                [value]="address.id"
              />
            </label>
          </div>
          <label for="{{ address.id }}">
            <div class="my-2" for="{{ address.id }}">
              <div class="font-weight-bold">
                <span>{{ address.name }}</span>
                <span *ngIf="address.firstName && address.lastName">
                  - {{ address.firstName }} {{ address.lastName }}</span
                >
              </div>
              <div class="font-weight-bold" data-private>
                {{ address.addressLine1 }} {{ address.addressLine2 }}
              </div>
              <div class="font-weight-light small-text">
                <span data-private>{{ address.city }},</span>
                <span>{{ address.state }}, {{ address.zipCode }}</span>
              </div>
            </div>
          </label>
        </div>
        <div *ngIf="address.default" class="default-badge mr-3">Default</div>
      </div>
    </div>

    <div *ngIf="userAddresses?.length > 0" class="row mx-md-1">
      <div class="col-md"></div>
      <button class="btn col-md-auto btn-primary text-uppercase" (click)="continue()" i18n>
        Use the selected address
      </button>
    </div>
  </div>
</app-modal>
