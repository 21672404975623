import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phCurrency',
})
export class PhCurrencyPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    @Inject(DEFAULT_CURRENCY_CODE) private currencyCode,
  ) {}

  transform(
    value: number,
    currencyCode: string = this.currencyCode,
    digitsInfo: string = '0.2-2',
    locale: string = this.localeId,
  ): string | null {
    return formatCurrency(
      value,
      locale,
      getCurrencySymbol(currencyCode, 'narrow'),
      currencyCode,
      digitsInfo,
    );
  }
}
