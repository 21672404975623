import { MelissaErrorCode } from '@core/enums/melissa-error-code';

export interface AddressState {
  loading: boolean;
  addressIsFetched: boolean;
  addresses: Address[];
  countryStates: CountryState[];
  saveInProgress: boolean;
  modals: {
    addressValidation: AddressValidation;
  };
}

export interface Address {
  id: number;
  name: string;
  firstName?: string;
  lastName?: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3?: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  default: boolean;
  type: AddressType;
  address: string;
}

export interface ExtendedAddressInformation {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  address: Address;
}

export interface CountryState {
  name: string;
  code: string;
}

export interface AddressValidation {
  saveSuccessful: boolean;
  addressVerificationResult: AddressVerification;
}

export interface AddressVerification {
  isValid: boolean;
  originalAddress: Address;
  ValidationCategoryAddresses: MelissaErrorCode[];
  ValidationCategoryLocations: MelissaErrorCode[];
  ValidationCategoryOthers: MelissaErrorCode[];
  correctedAddress: Address;
}

export enum AddressType {
  default = 'Default',
  other = 'Other',
}
