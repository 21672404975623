import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EventProperties } from '@core/models/appcues.model';
import { UserModel } from '@core/store/user/user-state-models';
import { environment } from '@env';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppInitService } from './app-init.service';

@Injectable({
  providedIn: 'root',
})
export class AppcuesService {
  private renderer: Renderer2;
  private loadedSubject: BehaviorSubject<boolean>;

  constructor(
    private router: Router,
    private rendererFactory: RendererFactory2,
    private appInitService: AppInitService,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.loadedSubject = new BehaviorSubject(false);
  }

  initAppcues(): Observable<boolean> {
    this.loadAppcues();
    return this.loadedSubject;
  }

  isAppcuesLoaded(): Observable<boolean> {
    return this.loadedSubject.asObservable();
  }

  setAppcuesIdentityForLoggedInUser(user: UserModel): void {
    if (!user) {
      return;
    }

    // This can be changed later depending what info is useful for PH
    window.Appcues.identify(user!.email, {
      customerId: user!.email!,
      firstName: user!.firstName!,
      lastName: user!.lastName!,
      phoneNumber: user.phoneNumber!,
      environment: environment.environmentName,
    });
  }

  setAppcuesIdentityForAnonymousUser(): void {
    window.Appcues.anonymous();
  }

  track(eventName: string, eventProperties?: EventProperties): void {
    this.appInitService.Settings.ec.isAppcuesEnabled &&
      window.Appcues.track(eventName, eventProperties);
  }

  private loadAppcues() {
    const script = this.renderer.createElement('script');
    script.onload = () => {
      this.listenUrlChanges();
      this.loadedSubject.next(true);
    };

    script.setAttribute('src', `//fast.appcues.com/${environment.appcuesId}.js`);

    this.renderer.appendChild(document.head, script);
  }

  private listenUrlChanges() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.Appcues.page();
      }
    });
  }
}
