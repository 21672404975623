import { WebsiteMode } from '@core/enums/website-mode.enum';
import { createAction, props } from '@ngrx/store';

export enum WebsiteModeActionTypes {
  setWebsiteMode = '[WebsiteMode] Set Website Mode',
}

/**
 * Set Website Mode
 */
export const setWebsiteMode = createAction(
  WebsiteModeActionTypes.setWebsiteMode,
  props<{ websiteMode: WebsiteMode }>(),
);
