<app-order-information [readOnly]="true" [(orderInfo)]="orderInfo"></app-order-information>
<div class="w-100">
  <app-return-order-lines
    [readOnly]="true"
    [orderLines]="checkedOrderLines"
  ></app-return-order-lines>
</div>

<div class="row mt-3">
  <div class="col-12 col-md-6">
    <app-return-step-navigation-buttons
      (negativeButtonClick)="activeStepChange.emit(activeStep - 1)"
      (positiveButtonClick)="submitReturn()"
      positiveButtonLabel="Submit return"
      i18n-positiveButtonLabel
    ></app-return-step-navigation-buttons>
  </div>
</div>

<app-info-modal #infoModal i18n-title title="Thank you!" (actionClick)="confirm()">
  <div class="text-center"><i class="bi bi-check2-circle"></i></div>
  <div class="text-center" i18n
    >Your return request were successful. Our team will get in touch with you soon!</div
  >
</app-info-modal>
