import { HttpErrorResponse } from '@angular/common/http';
import { ClientTokenRequest } from '@core/dto/order.dto';
import {
  CreatePayPalOrderResponse,
  CreatePaymentMethodRequest,
  CreatePaymentMethodResponse,
  UpdatePayPalOrderResponse,
} from '@core/dto/payment.dto';
import { PhErrorResponse } from '@core/dto/ph-error';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { PaymentCardType } from '@core/enums/payment-type.enum';
import { createAction, props } from '@ngrx/store';
import {
  ClientTokenResponse,
  CreatePartyOrderPayPalOrder,
  PaymentInfo,
  PaymentMethodItem,
} from './payment-state-models';

export enum PaymentActionTypes {
  fetchClientToken = '[Payment] Fetch Client Token',
  fetchClientTokenSuccess = '[Payment] Fetch Client Token Success',
  fetchClientTokenFailure = '[Payment] Fetch Client Token Failure',

  fetchPartyOrderClientToken = '[Payment] Fetch Party Order Client Token',
  fetchPartyOrderClientTokenSuccess = '[Payment] Fetch Party Order Client Token Success',
  fetchPartyOrderClientTokenFailure = '[Payment] Fetch Party Order Client Token Failure',

  resetCardPaymentInfo = '[Payment] Reset Card Payment Info',

  fetchPaymentMethods = '[Payment] Fetch payment methods',
  fetchPaymentMethodsSuccess = '[Payment] Fetch payment methods success',
  fetchPaymentMethodsFailure = '[Payment] Fetch payment methods failure',
  resetPaymentMethods = '[Payment] Reset payment methods',

  storePaymentInfo = '[Payment] Store Payment Info',
  storePaymentInfoSuccess = '[Payment] Store Payment Info Success',
  storePaymentInfoFailure = '[Payment] Store payment Info Failure',
  resetPaymentInfo = '[Payment] Reset Payment Info',
  storeCardPaymentInfo = '[Payment] Store Card Payment Info',

  createPaymentMethod = '[Payment] Create Payment Method',
  createPaymentMethodSuccess = '[Payment] Create Payment Method Success',
  createPaymentMethodFailure = '[Payment] Create Payment Method Failure',
  resetCreatedPaymentMethod = '[Payment] Reset Created Payment Method',

  setAsDefault = '[Payment] Set payment method as default',
  setAsDefaultSuccess = '[Payment] Set Payment Method as Default Success',
  setAsDefaultFailure = '[Payment] Set Payment Method as Default Failure',

  deletePaymentMethod = '[Payment] Delete payment method',
  deletePaymentMethodSuccess = '[Payment] Delete payment method success',
  deletePaymentMethodFailure = '[Payment] Delete payment method failure',

  createCheckoutPayPalOrder = '[Payment] Create Checkout PayPal order',
  createCheckoutPayPalOrderSuccess = '[Payment] Create Checkout PayPal order success',
  createCheckoutPayPalOrderFailure = '[Payment] Create Checkout PayPal order failure',

  updateCheckoutPayPalOrder = '[Payment] Update Checkout PayPal order',
  updateCheckoutPayPalOrderSuccess = '[Payment] Update Checkout PayPal order success',
  updateCheckoutPayPalOrderFailure = '[Payment] Update Checkout PayPal order failure',

  createPartyOrderPayPalOrder = '[Payment] Create Party Order PayPal order',
  createPartyOrderPayPalOrderSuccess = '[Payment] Create Party Order PayPal order success',
  createPartyOrderPayPalOrderFailure = '[Payment] Create Party Order PayPal order failure',
}

/**
 * Fetch client token
 **/
export const fetchClientToken = createAction(
  PaymentActionTypes.fetchClientToken,
  props<{ payload: ClientTokenRequest }>(),
);
export const fetchClientTokenSuccess = createAction(
  PaymentActionTypes.fetchClientTokenSuccess,
  props<{ payload: ClientTokenResponse }>(),
);
export const fetchClientTokenFailure = createAction(
  PaymentActionTypes.fetchClientTokenFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Fetch party order client token
 */
export const fetchPartyOrderClientToken = createAction(
  PaymentActionTypes.fetchPartyOrderClientToken,
  props<{ payload: ClientTokenRequest }>(),
);
export const fetchPartyOrderClientTokenSuccess = createAction(
  PaymentActionTypes.fetchPartyOrderClientTokenSuccess,
  props<{ payload: ClientTokenResponse }>(),
);
export const fetchPartyOrderClientTokenFailure = createAction(
  PaymentActionTypes.fetchPartyOrderClientTokenFailure,
  props<{ error: PhErrorResponse }>(),
);

export const resetCardPaymentInfo = createAction(PaymentActionTypes.resetCardPaymentInfo);

/**
 * Fetch payment methods
 */
export const fetchPaymentMethods = createAction(PaymentActionTypes.fetchPaymentMethods);
export const fetchPaymentMethodsSuccess = createAction(
  PaymentActionTypes.fetchPaymentMethodsSuccess,
  props<{ paymentMethods: PaymentMethodItem[] }>(),
);
export const fetchPaymentMethodsFailure = createAction(
  PaymentActionTypes.fetchPaymentMethodsFailure,
  props<{ error: PhErrorResponse }>(),
);
export const resetPaymentMethods = createAction(PaymentActionTypes.resetPaymentMethods);

/**
 * Store payment info
 **/
export const storePaymentInfo = createAction(
  PaymentActionTypes.storePaymentInfo,
  props<{ paymentInfo: PaymentInfo }>(),
);
export const storePaymentInfoSuccess = createAction(
  PaymentActionTypes.storePaymentInfoSuccess,
  props<{ payload: PaymentInfo }>(),
);
export const storePaymentInfoFailure = createAction(
  PaymentActionTypes.storePaymentInfoFailure,
  props<{ error: any }>(),
);
export const resetPaymentInfo = createAction(PaymentActionTypes.resetPaymentInfo);

export const storeCardPaymentInfo = createAction(
  PaymentActionTypes.storeCardPaymentInfo,
  props<{ payload: PaymentCardType }>(),
);

/**
 * Create Payment Method
 **/
export const createPaymentMethod = createAction(
  PaymentActionTypes.createPaymentMethod,
  props<{ payload: CreatePaymentMethodRequest }>(),
);
export const createPaymentMethodSuccess = createAction(
  PaymentActionTypes.createPaymentMethodSuccess,
  props<{ payload: CreatePaymentMethodResponse }>(),
);
export const createPaymentMethodFailure = createAction(
  PaymentActionTypes.createPaymentMethodFailure,
  props<{ error: HttpErrorResponse }>(),
);

export const resetCreatedPaymentMethod = createAction(PaymentActionTypes.resetCreatedPaymentMethod);

export const setAsDefault = createAction(
  PaymentActionTypes.setAsDefault,
  props<{ token: string; provider: PaymentProviderType }>(),
);
export const setAsDefaultSuccess = createAction(PaymentActionTypes.setAsDefaultSuccess);
export const setAsDefaultFailure = createAction(PaymentActionTypes.setAsDefaultFailure);

export const deletePaymentMethod = createAction(
  PaymentActionTypes.deletePaymentMethod,
  props<{ token: string; provider: PaymentProviderType }>(),
);
export const deletePaymentMethodSuccess = createAction(
  PaymentActionTypes.deletePaymentMethodSuccess,
);
export const deletePaymentMethodFailure = createAction(
  PaymentActionTypes.deletePaymentMethodFailure,
);

/**
 * Create PayPal Order for Checkout
 **/
export const createCheckoutPayPalOrder = createAction(PaymentActionTypes.createCheckoutPayPalOrder);
export const createCheckoutPayPalOrderSuccess = createAction(
  PaymentActionTypes.createCheckoutPayPalOrderSuccess,
  props<{ payload: CreatePayPalOrderResponse }>(),
);
export const createCheckoutPayPalOrderFailure = createAction(
  PaymentActionTypes.createCheckoutPayPalOrderFailure,
  props<{ error: any }>(),
);

/**
 * Update PayPal Order for Checkout
 **/
export const updateCheckoutPayPalOrder = createAction(
  PaymentActionTypes.updateCheckoutPayPalOrder,
  props<{ orderId: string }>(),
);
export const updateCheckoutPayPalOrderSuccess = createAction(
  PaymentActionTypes.updateCheckoutPayPalOrderSuccess,
  props<{ payload: UpdatePayPalOrderResponse }>(),
);
export const updateCheckoutPayPalOrderFailure = createAction(
  PaymentActionTypes.updateCheckoutPayPalOrderFailure,
  props<{ error: any }>(),
);

/**
 * Create PayPal Order for Party Order
 **/
export const createPartyOrderPayPalOrder = createAction(
  PaymentActionTypes.createPartyOrderPayPalOrder,
  props<{ payload: CreatePartyOrderPayPalOrder }>(),
);
export const createPartyOrderPayPalOrderSuccess = createAction(
  PaymentActionTypes.createPartyOrderPayPalOrderSuccess,
  props<{ payload: CreatePayPalOrderResponse }>(),
);
export const createPartyOrderPayPalOrderFailure = createAction(
  PaymentActionTypes.createPartyOrderPayPalOrderFailure,
  props<{ error: any }>(),
);
