import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Host,
  Input,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { getValidationErrorMessageList, validInput } from '@shared/utils/validation.utils';
import { SelectOption } from '../select/select.component';

@Component({
  selector: 'app-ph-select',
  templateUrl: './ph-select.component.html',
  styleUrls: ['./ph-select.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: PhSelectComponent, multi: true }],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PhSelectComponent implements ControlValueAccessor, AfterViewInit {
  @Input() identifier: string;
  @Input() label: string;
  @Input() formErrorMessages: { [key: string]: string } = FormErrorMessages;
  @Input() formControlName: string;
  @Input() options: SelectOption[] | string[];
  @Input() labelStyle: Record<string, unknown> = {};
  @Input() defaultValue: any = $localize`Select`;
  @Input() value: string;
  @Input() readonly: boolean = false;
  @Input() clearable: boolean = false;

  disabled: boolean = false;
  control: AbstractControl;

  getErrorMessages = getValidationErrorMessageList;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  ngAfterViewInit(): void {
    this.control = this.controlContainer?.control.get(this.formControlName);
  }

  onChange: (value: string) => void = () => {};

  onTouched = () => {};

  writeValue(value: string): void {
    this.value = value === '' ? null : value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onSelectChange(value: string): void {
    this.onChange(value);
  }

  get isValidInput(): boolean {
    if (this.control) {
      return validInput(this.control);
    }
    return true;
  }

  get errorMessages(): string[] {
    return getValidationErrorMessageList(this.control, this.formErrorMessages);
  }
}
