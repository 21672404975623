<hr class="d-md-none mb-0" />
<div class="d-flex justify-content-between">
  <h5 class="font-weight-bold title my-3 my-md-2">{{ title }}</h5>
  <div *ngIf="!stepProcessing && !consultantDataCanNotBeModified">
    <a
      class="d-md-block d-none text-uppercase font-weight-bold pointer my-2"
      (click)="navigate()"
      i18n
    >
      Change
    </a>
    <a class="d-md-none title my-2" (click)="navigate()">
      <i class="bi bi-pencil-square"></i>
    </a>
  </div>
</div>
<hr class="d-md-none mt-0" />
<div class="card" *ngIf="address">
  <div class="ph-row p-md-2">
    <span class="text-double-dot" i18n>Address Line 1</span>
    <strong data-private>{{ address.address1 }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2" *ngIf="!!address.address2">
    <span class="text-double-dot" i18n>Address Line 2</span>
    <strong data-private>{{ address.address2 }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2" *ngIf="!!address.address3">
    <span class="text-double-dot" i18n>Address Line 3</span>
    <strong data-private>{{ address.address3 }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2">
    <span class="text-double-dot" i18n>City</span>
    <strong data-private>{{ address.city }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2">
    <span class="text-double-dot" i18n>State</span>
    <strong>{{ stateName }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2">
    <span class="text-double-dot" i18n>ZIP Code</span>
    <strong>{{ address.zip }}</strong>
  </div>
</div>
