import { Injectable } from '@angular/core';
import { ReturnService } from '@core/services/return.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { convertToLocalizedDate } from '@shared/utils/localize-date-utils';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AppState } from '..';
import * as returnActions from './return.actions';

@Injectable()
export class ReturnEffects {
  fetchReturnHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(returnActions.fetchReturnHistory),
      mergeMap(() =>
        this.returnService.fetchReturnHistory().pipe(
          map((res) =>
            returnActions.fetchReturnHistorySuccess({
              returnHistory: res.returns.map((returnItem) => ({
                ...returnItem,
                submittedDate: convertToLocalizedDate(returnItem.submittedDate),
              })),
            }),
          ),
          catchError(() => of(returnActions.fetchReturnHistoryFailure())),
        ),
      ),
    ),
  );

  fetchReturnHistoryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(returnActions.fetchReturnHistoryFailure),
        tap(() => {
          this.toastr.error($localize`Return history fetch failed`, $localize`Error`);
        }),
      ),
    { dispatch: false },
  );

  submitReturn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(returnActions.submitReturn),
      mergeMap(({ payload }) =>
        this.returnService.submitReturn(payload).pipe(
          map(() => returnActions.submitReturnSuccess()),
          catchError(() => of(returnActions.submitReturnFailure())),
        ),
      ),
    ),
  );

  submitReturnFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(returnActions.submitReturnFailure),
        tap(() => {
          this.toastr.error($localize`Return request failed`, $localize`Error`);
        }),
      ),
    { dispatch: false },
  );

  fetchReturnDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(returnActions.fetchReturnDetails),
      mergeMap(({ returnId }) =>
        this.returnService.fetchReturnDetails(returnId).pipe(
          map((res) =>
            returnActions.fetchReturnDetailsSuccess({
              returnDetails: { ...res, submittedDate: convertToLocalizedDate(res.submittedDate) },
            }),
          ),
          catchError(() => of(returnActions.fetchReturnDetailsFailure())),
        ),
      ),
    ),
  );

  fetchReturnDetailsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(returnActions.fetchReturnDetailsFailure),
        tap(() => {
          this.toastr.error($localize`Return details fetch failed`, $localize`Error`);
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private returnService: ReturnService,
    private toastr: ToastrService,
    private store$: Store<AppState>,
  ) {}
}
