import { PaymentType } from '@core/enums/payment-type.enum';
import produce from 'immer';
import { CheckoutState } from './checkout-state-models';
import { CheckoutActionTypes } from './checkout.actions';

export const initialState: CheckoutState = {
  personalInfo: null,
  saveSuccessful: false,
  personalInfoModal: {
    addressValidation: null,
  },
  billingInfo: null,
  addressErrorType: null,
  loginModal: {
    isRegistered: false,
    checkEmailFailedErrorType: null,
  },
  savedAddressesModal: {
    currentAddress: null,
  },
  emailChecked: false,
  isLoading: false,
  paymentType: PaymentType.CreditOrDebitCard,
  isRegisteredUserWithoutLogin: false,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case CheckoutActionTypes.updatePersonalInfo:
      draft.isLoading = true;
      return;

    case CheckoutActionTypes.updatePersonalInfoSuccess:
      draft.personalInfo = action.personalInfo;
      draft.saveSuccessful = true;
      draft.isLoading = false;
      return;

    case CheckoutActionTypes.updatePersonalInfoFailure:
      if (action.error.ErrorType) {
        draft.addressErrorType = action.error.ErrorType;
      }
      draft.isLoading = false;
      return;

    case CheckoutActionTypes.cancelPersonalInfoUpdate:
      draft.isLoading = false;
      return;

    case CheckoutActionTypes.resetCheckoutState:
      return initialState;

    case CheckoutActionTypes.resetSaveSuccessful:
      draft.saveSuccessful = false;
      return draft;

    case CheckoutActionTypes.checkoutAddressValidation:
      draft.personalInfoModal.addressValidation = action.addressValidation;
      return;

    case CheckoutActionTypes.resetCheckoutAddressValidationModal:
      draft.personalInfoModal = initialState.personalInfoModal;
      return;

    case CheckoutActionTypes.openSavedAddressesModal:
      draft.savedAddressesModal.currentAddress = action.currentAddress;
      return;

    case CheckoutActionTypes.resetSavedAddressesModal:
      draft.savedAddressesModal = initialState.savedAddressesModal;
      return;

    case CheckoutActionTypes.checkUserByEmail:
      draft.emailChecked = false;
      return;

    case CheckoutActionTypes.checkUserByEmailSuccess:
      draft.loginModal.isRegistered = true;
      draft.emailChecked = true;
      return;

    case CheckoutActionTypes.checkUserByEmailFailure:
      draft.loginModal.checkEmailFailedErrorType = action.error?.ErrorType;
      draft.emailChecked = true;
      return;

    case CheckoutActionTypes.resetEmailCheck:
      draft.loginModal.checkEmailFailedErrorType =
        initialState.loginModal.checkEmailFailedErrorType;
      draft.emailChecked = initialState.emailChecked;
      return;

    case CheckoutActionTypes.resetLoginModal:
      draft.loginModal.isRegistered = false;
      return;

    case CheckoutActionTypes.setPaymentType:
      draft.paymentType = action.paymentType;
      return;

    case CheckoutActionTypes.userContinuesWithoutLogin:
      draft.isRegisteredUserWithoutLogin = true;
      return draft;

    case CheckoutActionTypes.storeBillingAddress:
      draft.billingInfo = action.billingInfo;
      return;

    default:
      return draft;
  }
}, initialState);
