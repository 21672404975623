import { SsnBlockEndIndex } from '@core/enums/ssn-block-end-index.enum';

export const NumberSeparator: string = '-';
export const PlaceholderCharacter: string = 'X';

export function ssnMasking(ssnNumber: string): string {
  let formattedValue: string = '';
  if (ssnNumber) {
    for (let i = 0; i < ssnNumber.length; ++i) {
      if (i < SsnBlockEndIndex.Second + 1 && ssnNumber[i] !== NumberSeparator) {
        formattedValue += PlaceholderCharacter;
      } else {
        formattedValue += ssnNumber[i];
      }
    }
  }
  return formattedValue;
}
