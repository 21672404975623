import { HttpErrorResponse } from '@angular/common/http';
import { ProductRecommendationResponse } from '@core/dto/product-details.dto';
import { RecipeResponse } from '@core/dto/recipe.dto';
import { LoadRecipesProps } from '@core/models/load-recipes.model';
import { createAction, props } from '@ngrx/store';
import { RecipeCategory, RecipeDetailsItem, RecipeItem } from './recipe-state-models';

export enum RecipeActionTypes {
  fetchRecipes = '[Recipe] Fetch Recipes',
  fetchRecipesSuccess = '[Recipe] Fetch recipes success',
  fetchRecipesFailure = '[Recipe] Fetch recipes failure',
  fetchRecipeCategories = '[Recipe] Fetch recipe categories',
  fetchRecipeCategoriesSuccess = '[Recipe] Fetch recipe categories success',
  fetchRecipeCategoriesFailure = '[Recipe] Fetch recipe categories failure',
  resetRecipes = '[Recipe] reset recipes',
  fetchRecipeDetails = '[Recipe] Fetch recipe details',
  fetchRecipeDetailsSilent = '[Recipe] Fetch recipe details silent',
  fetchRecipeDetailsSuccess = '[Recipe] Fetch recipe details success',
  fetchRecipeDetailsFailure = '[Recipe] Fetch recipe details failure',
  fetchRecipeRecommendations = '[Recipe] Fetch recipe recommendations',
  fetchRecipeRecommendationsSuccess = '[Recipe] Fetch recipe recommendations success',
  fetchRecipeRecommendationsFailure = '[Recipe] Fetch recipe recommendations failure',
  fetchRelatedProducts = '[Recipe] Fetch recipe related products',
  fetchRelatedProductsSuccess = '[Recipe] Fetch recipe related products success',
  fetchRelatedProductsFailure = '[Recipe] Fetch recipe related products failure',
  resetRecipeDetails = '[Recipe] Reset recipe details',

  fetchUserRecipes = '[Recipe] Fetch user recipes',
  fetchUserRecipesSuccess = '[Recipe] Fetch user recipes success',
  fetchUserRecipesFailure = '[Recipe] Fetch user recipes failure',

  likeRecipe = '[Recipe] Like recipe',
  likeRecipeSuccess = '[Recipe] Like recipe success',
  likeRecipeFailure = '[Recipe] Like recipe failure',

  dislikeRecipe = '[Recipe] Dislike recipe',
  dislikeRecipeSuccess = '[Recipe] Dislike recipe success',
  dislikeRecipeFailure = '[Recipe] Dislike recipe failure',
}

export const fetchRecipes = createAction(RecipeActionTypes.fetchRecipes, props<LoadRecipesProps>());

export const fetchRecipesSuccess = createAction(
  RecipeActionTypes.fetchRecipesSuccess,
  props<{ items: RecipeResponse[]; pageSize: number; isFirstPage?: boolean }>(),
);

export const fetchRecipesFailure = createAction(
  RecipeActionTypes.fetchRecipesFailure,
  props<{ error: HttpErrorResponse }>(),
);

export const fetchRecipeCategories = createAction(RecipeActionTypes.fetchRecipeCategories);
export const fetchRecipeCategoriesSuccess = createAction(
  RecipeActionTypes.fetchRecipeCategoriesSuccess,
  props<{ recipeCategories: RecipeCategory[] }>(),
);
export const fetchRecipeCategoriesFailure = createAction(
  RecipeActionTypes.fetchRecipeCategoriesFailure,
  props<{ error: HttpErrorResponse }>(),
);

export const resetRecipes = createAction(RecipeActionTypes.resetRecipes);

/**
 * Recipe details
 */
export const fetchRecipeDetails = createAction(
  RecipeActionTypes.fetchRecipeDetails,
  props<{ url: string; date: string }>(),
);
export const fetchRecipeDetailsSilent = createAction(
  RecipeActionTypes.fetchRecipeDetailsSilent,
  props<{ url: string }>(),
);
export const fetchRecipeDetailsSuccess = createAction(
  RecipeActionTypes.fetchRecipeDetailsSuccess,
  props<{ item: RecipeDetailsItem }>(),
);
export const fetchRecipeDetailsFailure = createAction(RecipeActionTypes.fetchRecipeDetailsFailure);
export const resetRecipeDetails = createAction(RecipeActionTypes.resetRecipeDetails);

/**
 * Recipe recommendations
 */
export const fetchRecipeRecommendations = createAction(
  RecipeActionTypes.fetchRecipeRecommendations,
  props<{ recipeId }>(),
);
export const fetchRecipeRecommendationsSuccess = createAction(
  RecipeActionTypes.fetchRecipeRecommendationsSuccess,
  props<{ items: RecipeResponse[] }>(),
);
export const fetchRecipeRecommendationsFailure = createAction(
  RecipeActionTypes.fetchRecipeRecommendationsFailure,
);

/**
 * Related products
 */
export const fetchRelatedProducts = createAction(
  RecipeActionTypes.fetchRelatedProducts,
  props<{ recipeUrl }>(),
);
export const fetchRelatedProductsSuccess = createAction(
  RecipeActionTypes.fetchRelatedProductsSuccess,
  props<{ items: ProductRecommendationResponse[] }>(),
);
export const fetchRelatedProductsFailure = createAction(
  RecipeActionTypes.fetchRelatedProductsFailure,
);

/**
 * User Recipes
 */
export const fetchUserRecipes = createAction(RecipeActionTypes.fetchUserRecipes);
export const fetchUserRecipesSuccess = createAction(
  RecipeActionTypes.fetchUserRecipesSuccess,
  props<{ items: RecipeItem[] }>(),
);
export const fetchUserRecipesFailure = createAction(RecipeActionTypes.fetchUserRecipesFailure);

export const likeRecipe = createAction(
  RecipeActionTypes.likeRecipe,
  props<{ id: string; url: string }>(),
);
export const likeRecipeSuccess = createAction(RecipeActionTypes.likeRecipeSuccess);
export const likeRecipeFailure = createAction(
  RecipeActionTypes.likeRecipeFailure,
  props<{ url: string }>(),
);

export const dislikeRecipe = createAction(
  RecipeActionTypes.dislikeRecipe,
  props<{ id: string; url: string }>(),
);
export const dislikeRecipeSuccess = createAction(RecipeActionTypes.dislikeRecipeSuccess);
export const dislikeRecipeFailure = createAction(
  RecipeActionTypes.dislikeRecipeFailure,
  props<{ url: string }>(),
);
