import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ConfigurationResponse as ECConfigurationResponse } from '@core/dto/configuration.dto';
import { ConfigurationResponse as SNAConfigurationResponse } from '@core/dto/start-now-app.dto';
import { environment } from '@env';
import { APP_CONFIG } from '@shared/utils/app-config-util';
import { AppModule } from './app/app.module';

// TODO EC-3876
// if (environment.production) {
//   enableProdMode();
// }

// platformBrowserDynamic()
//   .bootstrapModule(AppModule)
//   .catch((err) => console.error(err));

Promise.all([
  fetch(`${environment.ecommerceBackendUri}api/configuration`).then((value) => value.json()),
  fetch(`${environment.startNowAppBackendUri}api/Configuration`).then((value) => value.json()),
]).then(([ec, sna]: [ECConfigurationResponse, SNAConfigurationResponse]) => {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic([{ provide: APP_CONFIG, useValue: { ec, sna } }])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
