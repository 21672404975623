<div class="root d-flex flex-column align-items-center p-2 p-md-4">
  <div class="icon-wrapper mb-1 mb-md-3"><i class="bi bi-{{ icon }}"></i></div>
  <div class="text-center flex-grow-1 mb-3"><ng-content></ng-content></div>
  <button
    [ngClass]="{
      btn: true,
      'text-uppercase': true,
      'btn-primary': primary,
      'btn-outline-primary': !primary
    }"
    (click)="actionClick.emit($event)"
    [disabled]="disabled"
  >
    {{ actionLabel }}
  </button>
</div>
