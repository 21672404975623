import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountryName } from '@core/constants/country-name';
import { CountryStatesResponse } from '@core/dto/country-state.dto';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountryStateService {
  baseUrl: string = environment.ecommerceBackendUri;

  constructor(private http: HttpClient) {}

  fetchStatesByCountry(): Observable<CountryStatesResponse> {
    return this.http.get<CountryStatesResponse>(
      `${this.baseUrl}api/user/address/states/${CountryName[environment.country]}`,
    );
  }
}
