/* eslint-disable max-len */
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ConsultantFinderComponent } from '@consultant/consultant-finder/consultant-finder.component';
import { FindConsultantRequest, LocateConsultantRequest } from '@core/dto/consultant.dto';
import { ConsultantFinderType } from '@core/enums/consultant-finder-type.enum';
import { ConsultantLocatorErrorType } from '@core/enums/consultant-locator-error-type.enum';
import { Locale } from '@core/enums/locale';
import { ModalConfig } from '@core/models/modal-config.model';
import { AppState } from '@core/store';
import {
  selectConsultantFinder,
  selectConsultantFinderLoading,
  selectConsultantLocatorErrorType,
} from '@core/store/consultant';
import {
  locateConsultant,
  resetConsultantLocatorErrorType,
} from '@core/store/consultant/consultant.actions';
import { Store } from '@ngrx/store';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-consultant-finder-modal',
  templateUrl: './consultant-finder-modal.component.html',
  styleUrls: ['./consultant-finder-modal.component.scss'],
})
export class ConsultantFinderModalComponent implements OnInit, OnDestroy {
  @Input() zipCode: string;
  @Input() hasAutomaticAssignOption: boolean = false;
  @Input() mandatory: boolean = false;
  @Input() showExtraHint: boolean = false;
  @Input()
  consultantFinderText: string = $localize`Please find a consultant using the below options.`;
  @Output() consultantSelected: EventEmitter<void> = new EventEmitter<void>();

  readonly LocatorErrorTypes = ConsultantLocatorErrorType;
  consultantLocatorError$: Observable<ConsultantLocatorErrorType>;
  loading$: Observable<boolean>;
  finderType: ConsultantFinderType;
  loadMoreButtonIsVisible: boolean;
  consultantFinderType = ConsultantFinderType;
  finderIsValid: boolean = false;
  filter: FindConsultantRequest | LocateConsultantRequest;
  skipFinderModal: boolean = false;
  nextStepListener: Subscription;

  optionsConfig: ModalConfig;
  finderConfig: ModalConfig;
  resultConfig: ModalConfig;

  readonly extraHint = {
    [ConsultantFinderType.find]: $localize`If you know a Consultant we can help connect you to their website for future shopping.`,
    [ConsultantFinderType.locate]: $localize`If you don't know a Consultant, we can help connect you with one for future shopping.`,
  };

  @ViewChild('optionsModal') private optionsModal: ModalComponent;
  @ViewChild('finderModal') private finderModal: ModalComponent;
  @ViewChild('resultModal') private resultModal: ModalComponent;
  @ViewChild('consultantFinder') private consultantFinder: ConsultantFinderComponent;

  constructor(
    private store$: Store<AppState>,
    @Inject(LOCALE_ID) private localeId: Locale,
  ) {}

  ngOnInit(): void {
    this.initModalConfigs();

    this.consultantLocatorError$ = this.store$.select(selectConsultantLocatorErrorType);
    this.loading$ = this.store$.select(selectConsultantFinderLoading);
  }

  ngOnDestroy(): void {
    this.nextStepListener?.unsubscribe();
  }

  open() {
    this.optionsModal.open();
  }

  openFinder(type: ConsultantFinderType, loadMoreButtonIsVisible: boolean) {
    this.skipFinderModal = false;
    this.finderType = type;
    this.loadMoreButtonIsVisible = loadMoreButtonIsVisible;
    this.optionsModalClose();
    this.finderModal.open();
  }

  closeFinder() {
    this.finderModal.close();
    this.optionsModal.open();
  }

  search() {
    this.consultantFinder.submit();

    this.finderModal.close();
    this.resultModal.open();
  }

  resultBack() {
    this.resultModal.close();
    if (this.skipFinderModal) {
      this.optionsModal.open();
    } else {
      this.finderModal.open();
    }
  }

  onFinderValidChange(valid: boolean) {
    this.finderIsValid = valid;
  }

  onFinderSubmitted(value: FindConsultantRequest | LocateConsultantRequest) {
    this.filter = value;
  }

  notNow() {
    this.optionsModalClose();
  }

  autoAssign() {
    this.loadMoreButtonIsVisible = false;
    this.skipFinderModal = true;
    this.store$.dispatch(
      locateConsultant({
        payload: { zipCode: this.zipCode, cultureCode: this.localeId, pageNumber: 1, pageSize: 1 },
      }),
    );

    this.listenNextStep();
  }

  closeResult() {
    this.resultModal.close();
    this.consultantSelected.emit();
  }

  private initModalConfigs() {
    this.optionsConfig = {
      title: $localize`Assign consultant`,
      hideCloseButton: true,
      hideDismissButton: true,
      hideHeaderDismissButton: this.mandatory,
      ngbModalOptions: {
        size: 'lg',
        modalDialogClass: 'modal',
        backdrop: 'static',
        keyboard: !this.mandatory,
      },
    };

    this.finderConfig = {
      title: $localize`Find consultant`,
      hideCloseButton: true,
      hideDismissButton: true,
      hideHeaderDismissButton: this.mandatory,
      ngbModalOptions: {
        size: 'lg',
        modalDialogClass: 'modal',
        backdrop: 'static',
        keyboard: !this.mandatory,
      },
    };

    this.resultConfig = {
      title: $localize`Consultant(s)`,
      hideCloseButton: true,
      hideDismissButton: true,
      hideHeaderDismissButton: this.mandatory,
      ngbModalOptions: {
        size: 'lg',
        modalDialogClass: 'modal',
        backdrop: 'static',
        keyboard: !this.mandatory,
      },
    };
  }

  private listenNextStep(): void {
    this.nextStepListener = this.store$
      .select(selectConsultantFinder)
      .pipe(
        filter((finder) => !!finder.results.length),
        take(1),
      )
      .subscribe(() => {
        this.optionsModalClose();
        this.resultModal.open();
      });
  }

  private optionsModalClose(): void {
    this.nextStepListener?.unsubscribe();
    this.store$.dispatch(resetConsultantLocatorErrorType());
    this.optionsModal.close();
  }
}
