<div class="container h-auto rounded mb-4">
  <div class="row">
    <div class="col-4 col-md-4 text-center description-text p-0 square-box">
      <img
        [class]="{
          icon: !!consultant?.profileImage?.url,
          placeholder: !consultant?.profileImage?.url
        }"
        [src]="consultant?.profileImage?.url || PlaceholderImageSrc"
        [alt]="consultant?.profileImage?.altText"
      />
    </div>
    <div class="col d-flex flex-column" [ngClass]="{ 'consultant-container': boxHasButton }">
      <div>
        <div class="consultant-name mt-2 mt-md-3" [ngClass]="{ 'mt-lg-5': !boxHasButton }">
          <span>{{ this.consultant?.fullName }}</span>
        </div>
        <div class="consultant-address">{{ address }}</div>
      </div>

      <button
        *ngIf="boxHasButton"
        class="btn btn-outline-primary text-uppercase select-button w-50 mb-3"
        [ngClass]="{ active: isSelected }"
        (click)="selectConsultantAsRecruiter()"
      >
        {{ isSelected ? selectedLabel : selectLabel }}
      </button>
    </div>
  </div>
</div>
