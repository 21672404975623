import { Injectable } from '@angular/core';
import { SocialSecurityNumberUrlRegExp } from '@core/constants/patterns';
import { AppState, metaReducers } from '@core/store';
import { MetaReducer } from '@ngrx/store';
import { logrocketId } from '@shared/utils/environment-utils';
import {
  deleteAddressDetails,
  deleteAddressesDetails,
  deleteContactInfo,
} from '@shared/utils/logrocket-utils';
import cloneDeep from 'lodash.clonedeep';
import LogRocket from 'logrocket';
import createNgrxMiddleware from 'logrocket-ngrx';
import { AppInitService } from './app-init.service';

@Injectable({
  providedIn: 'root',
})
export class LogRocketService {
  private readonly Token: string = 'token';
  private readonly Authorization: string = 'Authorization';
  private readonly MaskedSocialSecurityNumberUrl: string =
    '/socialsecuritynumberisvalid/**number**';

  constructor(private appInitService: AppInitService) {}

  private readonly LogRocketMiddleware = createNgrxMiddleware(LogRocket, {
    actionSanitizer: function (action: any) {
      let restrictedAction = cloneDeep(action);
      if (restrictedAction?.ssnNumber) {
        delete restrictedAction.ssnNumber;
      }
      if (restrictedAction?.request?.phoneNumber) {
        delete restrictedAction.request.phoneNumber;
      }
      if (restrictedAction?.email) {
        delete restrictedAction.email;
      }
      if (restrictedAction?.contactInfo) {
        restrictedAction.contactInfo = deleteContactInfo(restrictedAction.contactInfo);
      }
      if (restrictedAction?.address) {
        restrictedAction.address = deleteAddressDetails(restrictedAction.address);
      }
      if (restrictedAction?.addressValidation?.payload?.originalAddress) {
        restrictedAction.addressValidation.payload.originalAddress = deleteAddressDetails(
          restrictedAction.addressValidation.payload.originalAddress,
        );
      }
      if (restrictedAction?.addressValidation?.addressVerificationResult?.originalAddress) {
        restrictedAction.addressValidation.addressVerificationResult.originalAddress =
          deleteAddressDetails(
            restrictedAction.addressValidation.addressVerificationResult.originalAddress,
          );
      }
      if (restrictedAction?.addressValidation?.shippingAddressVerificationResult?.originalAddress) {
        restrictedAction.addressValidation.shippingAddressVerificationResult.originalAddress =
          deleteAddressDetails(
            restrictedAction.addressValidation.shippingAddressVerificationResult.originalAddress,
          );
      }
      if (restrictedAction?.addressValidation?.payload?.correctedAddress) {
        restrictedAction.addressValidation.payload.correctedAddress = deleteAddressDetails(
          restrictedAction.addressValidation.payload.correctedAddress,
        );
      }
      if (restrictedAction?.addressValidation?.addressVerificationResult?.correctedAddress) {
        restrictedAction.addressValidation.addressVerificationResult.correctedAddress =
          deleteAddressDetails(
            restrictedAction.addressValidation.addressVerificationResult.correctedAddress,
          );
      }
      if (
        restrictedAction?.addressValidation?.shippingAddressVerificationResult?.correctedAddress
      ) {
        restrictedAction.addressValidation.shippingAddressVerificationResult.correctedAddress =
          deleteAddressDetails(
            restrictedAction.addressValidation.shippingAddressVerificationResult.correctedAddress,
          );
      }
      if (restrictedAction?.addresses?.length) {
        restrictedAction.addresses = deleteAddressesDetails(restrictedAction.addresses);
      }
      if (restrictedAction?.payload?.addressInfo?.addresses?.length) {
        restrictedAction.payload.addressInfo.addresses = deleteAddressesDetails(
          restrictedAction.payload.addressInfo.addresses,
        );
      }
      if (restrictedAction?.payload?.contactInfo) {
        restrictedAction.payload.contactInfo = deleteContactInfo(
          restrictedAction.payload.contactInfo,
        );
      }
      if (restrictedAction?.payload?.userInfo) {
        delete restrictedAction.payload.userInfo.governmentId;
      }
      if (restrictedAction?.personalInfo?.billingAddress?.address) {
        restrictedAction.personalInfo.billingAddress.address = deleteAddressDetails(
          restrictedAction.personalInfo.billingAddress.address,
        );
      }
      if (restrictedAction?.personalInfo?.shippingAddress?.address) {
        restrictedAction.personalInfo.shippingAddress.address = deleteAddressDetails(
          restrictedAction.personalInfo.shippingAddress.address,
        );
      }
      if (restrictedAction?.personalInfo?.billingCustomer) {
        restrictedAction.personalInfo.billingCustomer = deleteContactInfo(
          restrictedAction.personalInfo.billingCustomer,
        );
      }
      if (restrictedAction?.currentAddress) {
        restrictedAction.currentAddress = deleteAddressDetails(restrictedAction.currentAddress);
      }
      if (restrictedAction?.contactUsRequest) {
        delete restrictedAction.contactUsRequest.phone;
        delete restrictedAction.contactUsRequest.email;
        delete restrictedAction.contactUsRequest.emailConfirm;
        delete restrictedAction.contactUsRequest.addressLine1;
        delete restrictedAction.contactUsRequest.addressLine2;
        delete restrictedAction.contactUsRequest.addressLine3;
        delete restrictedAction.contactUsRequest.city;
      }
      if (restrictedAction?.payload?.shippingAddress) {
        restrictedAction.payload.shippingAddress = deleteAddressDetails(
          restrictedAction.payload.shippingAddress,
        );
      }
      if (restrictedAction?.payload?.billingAddress) {
        restrictedAction.payload.billingAddress = deleteAddressDetails(
          restrictedAction.payload.billingAddress,
        );
      }
      if (restrictedAction?.payload?.personalInformation) {
        restrictedAction.payload.personalInformation = deleteContactInfo(
          restrictedAction.payload.personalInformation,
        );
      }
      if (restrictedAction?.returnDetails?.shippingAddress) {
        restrictedAction.returnDetails.shippingAddress = deleteAddressDetails(
          restrictedAction.returnDetails.shippingAddress,
        );
      }
      if (restrictedAction?.payload?.emailTo) {
        delete restrictedAction.payload.emailTo;
      }
      if (restrictedAction?.payload?.email) {
        delete restrictedAction.payload.email;
      }
      if (restrictedAction?.payload?.phoneNumber) {
        delete restrictedAction.payload.phoneNumber;
      }
      if (restrictedAction?.user) {
        restrictedAction.user = deleteContactInfo(restrictedAction.user);
      }
      return restrictedAction;
    },
    stateSanitizer: function (state: any) {
      let restrictedState = cloneDeep(state);
      let snaAddressValidationModal =
        restrictedState?.startNowAppState?.addressValidationModal?.payload;
      let userInfo = restrictedState?.startNowAppState?.startNowAppData?.userInfo;
      let addressInfo = restrictedState?.startNowAppState?.startNowAppData?.addressInfo;
      let contactInfo = restrictedState?.startNowAppState?.startNowAppData?.contactInfo;
      let voucherPaymentInfo =
        restrictedState?.startNowAppState?.startNowAppData?.startNowAppPaymentInfo
          ?.voucherPaymentInfo;
      let addressState = restrictedState?.addressState;
      let checkoutState = restrictedState?.checkoutState;
      let orderState = restrictedState?.orderState;
      let returnState = restrictedState?.returnState;
      let userState = restrictedState?.userState;
      let voucherState = restrictedState?.voucherState;

      if (userInfo) {
        delete userInfo.ssnNumber;
      }
      if (snaAddressValidationModal?.originalAddress) {
        snaAddressValidationModal.originalAddress = deleteAddressDetails(
          snaAddressValidationModal.originalAddress,
        );
      }
      if (snaAddressValidationModal?.correctedAddress) {
        snaAddressValidationModal.correctedAddress = deleteAddressDetails(
          snaAddressValidationModal.correctedAddress,
        );
      }
      if (addressInfo?.addresses.length) {
        addressInfo.addresses = deleteAddressesDetails(addressInfo.addresses);
      }
      if (contactInfo) {
        contactInfo = deleteContactInfo(contactInfo);
      }
      if (voucherPaymentInfo) {
        delete voucherPaymentInfo.email;
      }
      if (addressState.addresses?.length) {
        addressState.addresses = deleteAddressesDetails(addressState.addresses);
      }
      if (addressState?.modals?.addressValidation?.addressVerificationResult?.correctedAddress) {
        addressState.modals.addressValidation.addressVerificationResult.correctedAddress =
          deleteAddressDetails(
            addressState.modals.addressValidation.addressVerificationResult.correctedAddress,
          );
      }
      if (addressState?.modals?.addressValidation?.addressVerificationResult?.originalAddress) {
        addressState.modals.addressValidation.addressVerificationResult.originalAddress =
          deleteAddressDetails(
            addressState.modals.addressValidation.addressVerificationResult.originalAddress,
          );
      }
      if (checkoutState?.personalInfo?.billingAddress?.address) {
        checkoutState.personalInfo.billingAddress.address = deleteAddressDetails(
          checkoutState.personalInfo.billingAddress.address,
        );
      }
      if (checkoutState?.personalInfo?.shippingAddress?.address) {
        checkoutState.personalInfo.shippingAddress.address = deleteAddressDetails(
          checkoutState.personalInfo.shippingAddress.address,
        );
      }
      if (checkoutState?.personalInfo?.billingCustomer) {
        checkoutState.personalInfo.billingCustomer = deleteContactInfo(
          checkoutState.personalInfo.billingCustomer,
        );
      }
      if (
        checkoutState?.personalInfoModal?.addressValidation?.shippingAddressVerificationResult
          ?.originalAddress
      ) {
        // eslint-disable-next-line max-len
        checkoutState.personalInfoModal.addressValidation.shippingAddressVerificationResult.originalAddress =
          deleteAddressesDetails(
            checkoutState.personalInfoModal.addressValidation.shippingAddressVerificationResult
              .originalAddress,
          );
      }
      if (
        checkoutState?.personalInfoModal?.addressValidation?.shippingAddressVerificationResult
          ?.correctedAddress
      ) {
        // eslint-disable-next-line max-len
        checkoutState.personalInfoModal.addressValidation.shippingAddressVerificationResult.correctedAddress =
          deleteAddressDetails(
            checkoutState.personalInfoModal.addressValidation.shippingAddressVerificationResult
              .correctedAddress,
          );
      }
      if (checkoutState?.savedAddressesModal?.currentAddress) {
        checkoutState.savedAddressesModal.currentAddress = deleteAddressDetails(
          checkoutState.savedAddressesModal.currentAddress,
        );
      }
      if (orderState?.orderDetails?.shippingAddress) {
        orderState.orderDetails.shippingAddress = deleteAddressDetails(
          orderState.orderDetails.shippingAddress,
        );
      }
      if (orderState?.orderDetails?.billingAddress) {
        orderState.orderDetails.billingAddress = deleteAddressDetails(
          orderState.orderDetails.billingAddress,
        );
      }
      if (orderState?.orderDetails?.personalInformation) {
        orderState.orderDetails.personalInformation = deleteContactInfo(
          orderState.orderDetails.personalInformation,
        );
      }
      if (returnState?.returnDetails?.shippingAddress) {
        returnState.returnDetails.shippingAddress = deleteAddressDetails(
          returnState.returnDetails.shippingAddress,
        );
      }
      if (userState?.user) {
        userState.user = deleteContactInfo(userState.user);
      }
      if (voucherState?.email) {
        delete voucherState.email;
      }
      return restrictedState;
    },
  });

  initLogRocket(): void {
    if (this.appInitService.Settings.ec.isLogrocketEnabled) {
      LogRocket.init(logrocketId, {
        // Hide or remove sensitive information
        network: {
          requestSanitizer: this.requestSanitizer.bind(this),
          responseSanitizer: this.responseSanitizer.bind(this),
        },
      });
    }
  }

  logRocketIdentify(id: string) {
    if (this.appInitService.Settings.ec.isLogrocketEnabled) {
      LogRocket.identify(id);
    }
  }

  getMetaReducers(): MetaReducer<AppState>[] {
    if (this.appInitService.Settings.ec.isLogrocketEnabled) {
      return metaReducers.concat([this.LogRocketMiddleware]);
    }
    return metaReducers;
  }

  private jsonParse(body: string) {
    try {
      return JSON.parse(body);
    } catch {
      return null;
    }
  }

  private requestSanitizer(request) {
    if (request.url.toLowerCase().indexOf(this.Token) !== -1) {
      return;
    }
    if (request.body) {
      let body = this.jsonParse(request.body);

      if (request.url.includes('passwordReset') && body?.userName) {
        delete body.userName;
      }
      if (body?.userInfo) {
        delete body.userInfo.governmentId;
      }
      if (body?.email) {
        delete body.email;
      }
      if (body?.emailConfirm) {
        delete body.emailConfirm;
      }
      if (body?.phoneNumber) {
        delete body.phoneNumber;
      }
      if (body?.address1) {
        delete body.address1;
      }
      if (body?.address2) {
        delete body.address2;
      }
      if (body?.address3) {
        delete body.address3;
      }
      if (body?.addressLine1) {
        delete body.addressLine1;
      }
      if (body?.addressLine2) {
        delete body.addressLine2;
      }
      if (body?.addressLine3) {
        delete body.addressLine3;
      }
      if (body?.city) {
        delete body.city;
      }
      if (body?.contactInfo) {
        body.contactInfo = deleteContactInfo(body.contactInfo);
      }
      if (body?.address) {
        body.address = deleteAddressDetails(body.address);
      }
      if (body?.shippingAddress) {
        body.shippingAddress = deleteAddressDetails(body.shippingAddress);
      }
      if (body?.addressInfo?.addresses) {
        body.addressInfo.addresses = deleteAddressesDetails(body.addressInfo.addresses);
      }
      if (body?.billingAddress) {
        body.billingAddress = deleteAddressDetails(body.billingAddress);
      }
      if (body?.billingCustomer) {
        body.billingCustomer = deleteContactInfo(body.billingCustomer);
      }
      if (body?.shippingAddress) {
        body.shippingAddress = deleteAddressDetails(body.shippingAddress);
      }
      request.body = body ? body : request.body;
    }
    request.url = request.url.replace(
      SocialSecurityNumberUrlRegExp,
      this.MaskedSocialSecurityNumberUrl,
    );
    request.headers[this.Authorization] = '';
    return request;
  }

  private responseSanitizer(response) {
    if (response.body) {
      let body = this.jsonParse(response.body);
      if (body?.clientToken) {
        delete body.clientToken;
      }
      if (body?.email) {
        delete body.email;
      }
      if (body?.phoneNumber) {
        delete body.phoneNumber;
      }
      if (body?.payload?.originalAddress) {
        body.payload.originalAddress = deleteAddressDetails(body.payload.originalAddress);
      }
      if (body?.payload?.correctedAddress) {
        body.payload.correctedAddress = deleteAddressDetails(body.payload.correctedAddress);
      }
      if (body?.addresses?.length) {
        body.addresses = deleteAddressesDetails(body.addresses);
      }
      if (body?.addressVerificationResult?.correctedAddress) {
        body.addressVerificationResult.correctedAddress = deleteAddressDetails(
          body.addressVerificationResult.correctedAddress,
        );
      }
      if (body?.shippingAddressVerificationResult?.correctedAddress) {
        body.shippingAddressVerificationResult.correctedAddress = deleteAddressDetails(
          body.shippingAddressVerificationResult.correctedAddress,
        );
      }
      if (body?.addressVerificationResult?.originalAddress) {
        body.addressVerificationResult.originalAddress = deleteAddressDetails(
          body.addressVerificationResult.originalAddress,
        );
      }
      if (body?.shippingAddressVerificationResult?.originalAddress) {
        body.shippingAddressVerificationResult.originalAddress = deleteAddressDetails(
          body.shippingAddressVerificationResult.originalAddress,
        );
      }
      response.body = body ? body : response.body;
    }
    return response;
  }
}
