<div class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <h2 i18n>Create an Account</h2>
      </div>
    </div>
    <div class="row mt-4" [formGroupName]="Name">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="FirstName"
            label="First name"
            labelAsterix="true"
            i18n-label
            placeholder="Your first name goes here"
            i18n-placeholder
            [formControlName]="FirstName"
          >
          </app-text-field-input>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="LastName"
            label="Last name"
            labelAsterix="true"
            i18n-label
            placeholder="Your last name goes here"
            i18n-placeholder
            [formControlName]="LastName"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>
    <div class="row mt-3" [formGroupName]="Email">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="Email"
            label="Email address"
            labelAsterix="true"
            type="email"
            i18n-label
            placeholder="Your email address goes here"
            i18n-placeholder
            validationErrorMessage="Please enter a valid email address"
            i18n-validationErrorMessage
            [formControlName]="Email"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="EmailConfirm"
            label="Re-enter email address"
            labelAsterix="true"
            type="email"
            i18n-label
            placeholder="Your email address goes here"
            i18n-placeholder
            [groupValidationErrorType]="FormErrorTypes.emailConfirm"
            [formControlName]="EmailConfirm"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
      <div
        *ngIf="(errorType$ | async) === PhErrorTypes.ValidationException"
        class="col-12 validation-error-message"
      >
        <span i18n>
          This e-mail cannot be used in Ecommerce because it is already registered on a PH
          consultant!
        </span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="PhoneNumber"
            label="Mobile number"
            type="tel"
            i18n-label
            placeholder="Your phone number goes here"
            i18n-placeholder
            validationErrorMessage="Please enter a valid phone number"
            i18n-validationErrorMessage
            [formControlName]="PhoneNumber"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>
    <div class="row mt-3" [formGroupName]="Password">
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <app-password-input
            [identifier]="Password"
            label="Password"
            required="true"
            i18n-label
            [formErrorMessages]="ErrorMessages"
            [formControlName]="Password"
          ></app-password-input>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="form-group">
          <app-password-input
            [identifier]="PasswordConfirm"
            label="Re-enter password"
            required="true"
            i18n-label
            [groupValidationErrorType]="FormErrorTypes.passwordConfirm"
            [formControlName]="PasswordConfirm"
          ></app-password-input>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <ng-template *ngTemplateOutlet="passwordStrengthValidationMessage"></ng-template>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <span class="text-asterix mr-1"> </span>
        <span i18n>Required</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col" [formGroupName]="Subscriptions">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="newsletterChkbox"
            [formControlName]="Newsletters"
          />
          <label class="form-check-label font-size-16 my-1" for="newsletterChkbox" i18n>
            Subscribe to the Princess House newsletter
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [formControlName]="ShareWithConsultant"
            id="shareWithConsultantChkbox"
          />
          <label class="form-check-label font-size-16 my-1" for="shareWithConsultantChkbox" i18n>
            Share my contact information with my Consultant
          </label>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-sm-12 col-md-6">
        <button
          type="submit"
          class="btn btn-lg btn-primary btn-uppercase-lg w-100"
          [disabled]="disableSubmit$ | async"
          i18n
        >
          Create account
        </button>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <p i18n
          >By creating an account, you agree to the
          <a class="font-weight-bold" [routerLink]="URL.TermsOfUse"
            >Princess House's Terms of Use</a
          >
          and
          <a class="font-weight-bold" [routerLink]="URL.PrivacyPolicy">Privacy Policy</a>
        </p>
      </div>
    </div>
  </form>
</div>

<ng-template #passwordStrengthValidationMessage>
  <p class="p-0 m-0 font-weight-bold text-double-dot" i18n>Password must</p>
  <ul class="mb-0">
    <li i18n>8 or more characters</li>
    <li i18n>Upper & lowercase letters</li>
    <li i18n>At least one number: 0-9</li>
    <li i18n>At least one symbol: {{ '!@#^&()+_,.{}?-' }}</li>
  </ul>
</ng-template>
