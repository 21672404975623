import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { ConditionalContent } from './conditional-content-state-models';

export enum ConditionalContentActionTypes {
  fetchConditionalContentByUrlName = '[Conditional Content] Fetch conditional content by urlName',
  // eslint-disable-next-line max-len
  fetchConditionalContentByPageCategory = '[Conditional Content] Fetch conditional content by pageCateogry',
  fetchConditionalContentSuccess = '[Conditional Content] Fetch conditional content success',
  fetchConditionalContentError = '[Conditional Content] Fetch conditional content error',
  resetConditionalContentState = '[Conditional Content] Reset conditional content state',
}

export const fetchConditionalContentByUrlName = createAction(
  ConditionalContentActionTypes.fetchConditionalContentByUrlName,
  props<{ urlName: string }>(),
);

export const fetchConditionalContentByPageCategory = createAction(
  ConditionalContentActionTypes.fetchConditionalContentByPageCategory,
  props<{ pageCategory: string; viewAs: string }>(),
);

export const fetchConditionalContentSuccess = createAction(
  ConditionalContentActionTypes.fetchConditionalContentSuccess,
  props<{ content: ConditionalContent[] }>(),
);

export const fetchConditionalContentError = createAction(
  ConditionalContentActionTypes.fetchConditionalContentError,
  props<{ error: HttpErrorResponse }>(),
);

export const resetConditionalContentState = createAction(
  ConditionalContentActionTypes.resetConditionalContentState,
);
