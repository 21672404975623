import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FooterBackgroundShade } from '@core/enums/footer-bg-shade.enum';
import { environment } from '@env';

@Component({
  selector: 'app-footer-bottom',
  templateUrl: './footer-bottom.component.html',
  styleUrls: ['../footer.common.scss', './footer-bottom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterBottomComponent implements OnInit {
  @Input() shade: FooterBackgroundShade;
  readonly feature = environment.feature;
  constructor() {}

  currentYear: number;

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }
}
