export enum StartNowAppStep {
  DoYouKnowConsultant = 1,
  FindConsultantByZipCode = 2,
  SelectConsultant = 2,
  ConfirmConsultant = 3,
  RegistrationCode = 4,
  AboutYou = 5,
  ConnectWithYou = 6,
  WaitBusinessTools = 7,
  SelectStarterKit = 8,
  PasswordBackofficePortal = 9,
  ConsultantAgreement = 10,
  DoubleCheck = 11,
  Payment = 12,
  Congratulation = 13,
}

export enum NetworkStatus {
  Online = 'online',
  Offline = 'offline',
}
