import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from '@base/components/layout/default-layout/default-layout.component';
import { RecipeFeatureGuardService } from '@core/services/recipe-feature-guard.service';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { BlogListPageComponent } from './components/blog-list-page/blog-list-page.component';
import { RecipeDetailsComponent } from './components/recipe-details/recipe-details.component';
import { RecipeListPageComponent } from './components/recipe-list-page/recipe-list-page.component';
import { RecipeMoreCatalogPageComponent } from './components/recipe-more-catalog-page/recipe-more-catalog-page.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [RecipeFeatureGuardService],
    children: [
      {
        path: 'recipes-more',
        component: RecipeMoreCatalogPageComponent,
        children: [
          {
            path: 'recipes',
            component: RecipeListPageComponent,
            data: {
              title: $localize`Browse list of recipes by category`,
              description: $localize`Browse list of recipes by category - Description`,
            },
          },
          { path: 'recipes/:category', component: RecipeListPageComponent },
          {
            path: 'blogs',
            component: BlogListPageComponent,
            data: {
              title: $localize`PH - Blogs Page - Title`,
              description: $localize`PH - Blogs Page - Description`,
            },
          },
          { path: 'blogs/:category', component: BlogListPageComponent },
        ],
      },
      { path: 'recipe/detail/:recipeUrl', component: RecipeDetailsComponent },
      { path: 'blog/detail/:blogUrl', component: BlogDetailsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RecipeMoreRoutingModule {}
