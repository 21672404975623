/* eslint-disable no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreatePaymentMethodRequest,
  PaymentMethodsResponse,
  UpdatePayPalOrderRequest,
  UpdatePayPalOrderResponse,
} from '@core/dto/payment.dto';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { environment } from '@env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  baseUrl: string = environment.ecommerceBackendUri;
  options = { withCredentials: true };

  fetchPaymentMethods(): Observable<PaymentMethodsResponse> {
    return this.http.get<PaymentMethodsResponse>(`${this.baseUrl}api/user/payment/methods`);
  }

  createPaymentMethod(request: CreatePaymentMethodRequest): Observable<any> {
    return this.http.post<boolean>(
      `${this.baseUrl}api/order/payment/paymentmethod`,
      request,
      this.options,
    );
  }

  setAsDefault(token: string, provider: PaymentProviderType): Observable<any> {
    return this.http.put<boolean>(
      `${this.baseUrl}api/user/payment/methods/${token}/${provider}`,
      {},
    );
  }

  deletePaymentMethod(token: string, provider: PaymentProviderType): Observable<boolean> {
    return this.http.delete<boolean>(
      `${this.baseUrl}api/user/payment/methods/${token}/${provider}`,
    );
  }

  createCheckoutPayPalOrder() {
    return this.http.post<any>(
      `${this.baseUrl}api/order/payment/createpaypalorder`,
      {},
      this.options,
    );
  }

  updateCheckoutPayPalOrder(
    request: UpdatePayPalOrderRequest,
  ): Observable<UpdatePayPalOrderResponse> {
    return this.http.post<UpdatePayPalOrderResponse>(
      `${this.baseUrl}api/order/payment/updatepaypalorder`,
      request,
      this.options,
    );
  }

  createPartyOrderPayPalOrder(masterOrderId: number, amount: number) {
    return this.http.post<any>(
      `${this.baseUrl}api/partyorder/createpaypalorder`,
      {
        masterOrderId,
        amount,
      },
      this.options,
    );
  }

  constructor(private http: HttpClient) {}
}
