import { AfterViewInit, Component, Host, Input, Optional, SkipSelf } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FormErrorMessages } from '@core/constants/form-error-messages';
import { getValidationErrorMessageList, validInput } from '@shared/utils/validation.utils';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextareaComponent,
      multi: true,
    },
  ],
})
export class TextareaComponent implements ControlValueAccessor, AfterViewInit {
  @Input() identifier: string;
  @Input() label: string;
  @Input() formErrorMessages: { [key: string]: string } = FormErrorMessages;
  @Input() formControlName: string;
  @Input() labelAsterix: boolean = false;
  @Input() placeholder: string = '';
  @Input() readonly: boolean = false;
  @Input() rows: number = 2;

  disabled = false;
  value: string;
  control: AbstractControl;

  getErrorMessages = getValidationErrorMessageList;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  ngAfterViewInit(): void {
    this.control = this.controlContainer?.control.get(this.formControlName);
  }

  onChange: (value: string) => void = () => {};
  onTouched = () => {};

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onKeyUp(event: any): void {
    this.value = event.target.value;
    this.onChange(this.value);
    this.onTouched();
  }

  get isValidInput(): boolean {
    if (this.control) {
      return validInput(this.control);
    }
    return true;
  }

  get errorMessages(): string[] {
    return getValidationErrorMessageList(this.control, this.formErrorMessages);
  }
}
