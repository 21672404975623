import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Locale } from '@core/enums/locale';
import { PaymentProviderType } from '@core/enums/payment-provider-type.enum';
import { BraintreeExtendedOverrides } from '@core/models/braintree-override-fields.model';
import { BraintreeDropinUi } from '@core/models/dropin-instance.model';
import { LogCardAttemptData } from '@core/models/log-card-attempt-data.model';
import { LoggerService } from '@core/services/logger.service';
import { PaymentInfo } from '@core/store/payment/payment-state-models';
import { CardPaymentProviderBaseComponent } from '@payment/components/card-payment-provider-base-component';
import { getLanguage } from '@shared/utils/locale-utils';
import * as braintree from 'braintree-web-drop-in';
import { Dropin, cardPaymentMethodPayload } from 'braintree-web-drop-in';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

/**
 * Braintree card payment component where the paymentHandler input is required!
 */
@Component({
  selector: 'app-braintree',
  templateUrl: './braintree.component.html',
  styleUrls: ['./braintree.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BraintreeComponent
  extends CardPaymentProviderBaseComponent<BraintreeDropinUi>
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Output()
  cardAttempt: EventEmitter<LogCardAttemptData> = new EventEmitter<LogCardAttemptData>();

  @ViewChild('BraintreeDropInContainer') braintreeDivContainer: ElementRef<HTMLElement>;

  constructor(
    @Inject(LOCALE_ID) private localeId: Locale,
    public loggerService: LoggerService,
    private toastr: ToastrService,
  ) {
    super(loggerService);
  }

  ngOnInit(): void {
    this.loading.emit(true);
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.initDropinUiByToken(
        PaymentProviderType.Braintree,
        this.cardholderName?.firstName,
        this.cardholderName?.lastName,
      ),
    );
  }

  public get dropInInstance(): BraintreeDropinUi {
    return this._dropInInstance as BraintreeDropinUi;
  }

  public get dropInContainer(): ElementRef<HTMLElement> {
    return this.braintreeDivContainer;
  }

  public initDropinInstance(
    token: string,
    cardholderName: string = '',
    isCvvRequired: boolean,
    isAvsRequired: boolean,
    collectRiskDataFromInput: boolean,
  ): void {
    this.loadBraintreeDropIn(
      token,
      isCvvRequired,
      isAvsRequired,
      collectRiskDataFromInput,
      cardholderName,
    );
  }

  public requestPaymentMethodObject(): Observable<boolean> {
    this.dropInInstance.requestPaymentMethod((err, payload: cardPaymentMethodPayload) => {
      const isSuccess: boolean = err ? false : true;
      this.cardAttempt.emit({ success: isSuccess, response: JSON.stringify(err) });
      const paymentInfo = err ? undefined : this.buildPaymentInfo(payload);
      this.paymentHandler.handleRequestedPaymentMethodObject(err, paymentInfo);
      this.requestPaymentSuccessSubject.next(isSuccess);
    });

    return this.requestPaymentSuccessSubject.asObservable();
  }

  public resetToken(cardholderFirstName: string = '', cardholderLastName: string = ''): void {
    this.dropInInstance.teardown().then(() => {
      this.loading.emit(true);
      this.paymentHandler.resetToken();
      this.initDropinUiByToken(
        PaymentProviderType.Braintree,
        cardholderFirstName,
        cardholderLastName,
      );
    });
  }

  public getCardPaymentInputs(): Array<HTMLElement> | undefined {
    if (this.braintreeDivContainer === undefined) return;
    const iframes = Array.from(
      this.braintreeDivContainer.nativeElement.querySelectorAll('iframe'),
    ) as Array<HTMLElement>;
    const inputs = Array.from(this.braintreeDivContainer.nativeElement.querySelectorAll('input'));

    return iframes.concat(inputs);
  }

  private loadBraintreeDropIn(
    token: string,
    isCvvRequired: boolean,
    isAvsRequired: boolean,
    collectRiskDataFromInput: boolean,
    cardholderName: string = '',
  ): void {
    if (token) {
      const lang = getLanguage(this.localeId);
      const callback = (err, dropInInstance: Dropin): void => {
        if (err) {
          this.toastr.error(
            $localize`Failed to finalize Braintree payment! Please retry or contact the admin!`,
            $localize`Card payment error!`,
          );
          this.loggerService.error(err);
          return;
        }

        if (dropInInstance.isPaymentMethodRequestable()) {
          this.isPaymentMethodRequestable.emit(true);
        }

        this.handleIsPaymentMethodRequestable(dropInInstance);
        this.checkInputFocusability();

        this._dropInInstance = dropInInstance;
        this.loading.emit(false);
        this.dropInComponentInitialized.emit(this);
      };

      braintree.create(
        {
          authorization: token,
          container: this.dropInContainer.nativeElement,
          dataCollector: true,
          locale: lang,
          preselectVaultedPaymentMethod: true,
          card: {
            overrides: this.getBraintreeOverrides(
              isCvvRequired,
              isAvsRequired,
              collectRiskDataFromInput,
              cardholderName,
            ),
            cardholderName: {
              required: true,
            },
          },
        },
        callback,
      );
    } else {
      this.loggerService.error('Braintree: Not initialized token');
    }
  }

  private handleIsPaymentMethodRequestable(dropInInstance: Dropin) {
    dropInInstance.on('paymentMethodRequestable', () => {
      this.isPaymentMethodRequestable.emit(true);
    });
    dropInInstance.on('noPaymentMethodRequestable', () => {
      this.isPaymentMethodRequestable.emit(false);
    });
  }

  private buildPaymentInfo(payload: cardPaymentMethodPayload) {
    return <PaymentInfo>{
      nonce: payload.nonce,
      firstFour: payload.details.bin.substr(0, 4),
      lastFour: payload.details.lastFour,
      deviceData: this.encodeDeviceData(payload.deviceData),
      cardholderName: payload.details.cardholderName,
      expirationMonth: payload.details.expirationMonth,
      expirationYear: payload.details.expirationYear,
      default: this.makeDefault,
    };
  }

  // TODO: PHS-4246 - Use braintree.cardCreateOptions['overrides'] model instead of custom one
  private getBraintreeOverrides(
    isCvvRequired: boolean,
    isAvsRequired: boolean,
    collectRiskDataFromInput: boolean,
    cardholderName: string = '',
  ): BraintreeExtendedOverrides {
    let result: BraintreeExtendedOverrides = {
      fields: {
        postalCode: {
          minlength: 5,
        },
        cardholderName: {
          prefill: cardholderName,
        },
      },
    };

    if (!isCvvRequired) {
      result.fields.cvv = null;
    }

    // PHS-9137 - As a hotfix, hiding the ZIP code is disabled, originally: isAvsRequired && !collectRiskDataFromInput
    const showPostalCodeField = true;

    if (!showPostalCodeField) {
      result.fields.postalCode = null;
    }

    return result;
  }

  /** Encode Braintree deviceData.
   * @deviceData a string that contains a json ex: "{\"correlation_id\":\"d159f01c3cd272dcb03d0939964ef77d\"}"
   */
  private encodeDeviceData(deviceData: string | undefined | null): string | undefined | null {
    /* Have to encode the deviceData otherwise it will be rejected by Azure Application Gateway WAF.
    On BE will be decoded */
    return deviceData ? encodeURIComponent(deviceData) : undefined;
  }
}
