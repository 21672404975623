import { WebsiteMode } from '@core/enums/website-mode.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as moment from 'moment';
import { selectWebsiteMode } from '../website-mode';
import { ConditionalContentState } from './conditional-content-state-models';

export const conditionalContentStateFeatureKey = 'conditionalContentState';

export const selectConditionalContentFeature = createFeatureSelector<ConditionalContentState>(
  conditionalContentStateFeatureKey,
);

export const selectConditionalContent = (date: string) =>
  createSelector(
    selectConditionalContentFeature,
    selectWebsiteMode,
    /* eslint-disable indent*/
    (state: ConditionalContentState, websiteMode: WebsiteMode) =>
      state && state.conditionalContent
        ? state.conditionalContent.filter(
            (content) =>
              (Number(content.websiteMode) | websiteMode) === Number(content.websiteMode) &&
              filterByDate(date, content.startDate, content.endDate),
          )
        : null,
    /* eslint-enable indent*/
  );

export const selectConditionalContentByPageCategory = (pageCategory: string) =>
  createSelector(
    selectConditionalContentFeature,
    selectWebsiteMode,
    /* eslint-disable indent */
    (state: ConditionalContentState, websiteMode: WebsiteMode) =>
      state && state.conditionalContent
        ? state.conditionalContent.filter(
            (content) =>
              (Number(content.websiteMode) | websiteMode) === Number(content.websiteMode) &&
              content.pageCategories.some((category) => category.name === pageCategory),
          )
        : null,
    /* eslint-enable indent */
  );

export const selectConditionalContentState = createSelector(
  selectConditionalContentFeature,
  (state: ConditionalContentState) => (state ? state : null),
);

function filterByDate(date: string, startDate: string, endDate: string): boolean {
  if (date) {
    return moment.utc(date).isAfter(startDate) && moment.utc(date).isBefore(endDate);
  }
  return moment.utc().isAfter(startDate) && moment.utc().isBefore(endDate);
}
