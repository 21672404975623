<app-page-title
  title="New Consultant Application - Step {{ activeStepId }} of 8"
  i18n-title
  default="New Consultant Application"
  i18n-default
></app-page-title>
<div class="container">
  <div class="row">
    <div class="col">
      <app-start-now-app-progress-bar [step]="activeStepId"></app-start-now-app-progress-bar>
      <ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom-0" [(activeId)]="activeStepId">
        <li [ngbNavItem]="navigationSteps[0].orderNumber">
          <ng-template ngbNavContent>
            <app-about-you-step #step (goToNextStep)="goToNextStep()"></app-about-you-step>
          </ng-template>
        </li>
        <li [ngbNavItem]="navigationSteps[1].orderNumber">
          <ng-template ngbNavContent>
            <app-connect-with-you-step
              #step
              (goToNextStep)="goToNextStep()"
            ></app-connect-with-you-step>
          </ng-template>
        </li>
        <li [ngbNavItem]="navigationSteps[2].orderNumber">
          <ng-template ngbNavContent>
            <app-wait-business-tools-step
              #step
              (showConsultantList)="showConsultantList()"
              (goToNextStep)="goToNextStep()"
            ></app-wait-business-tools-step>
          </ng-template>
        </li>
        <li [ngbNavItem]="navigationSteps[3].orderNumber">
          <ng-template ngbNavContent>
            <app-select-starter-kit-step
              #step
              (goToNextStep)="goToNextStep()"
            ></app-select-starter-kit-step>
          </ng-template>
        </li>
        <li [ngbNavItem]="navigationSteps[4].orderNumber">
          <ng-template ngbNavContent>
            <app-password-backoffice-portal-step
              #step
              (goToNextStep)="goToNextStep()"
            ></app-password-backoffice-portal-step>
          </ng-template>
        </li>
        <li [ngbNavItem]="navigationSteps[5].orderNumber">
          <ng-template ngbNavContent>
            <app-consultant-agreement-step
              #step
              (goToNextStep)="goToNextStep()"
            ></app-consultant-agreement-step>
          </ng-template>
        </li>
        <li [ngbNavItem]="navigationSteps[6].orderNumber">
          <ng-template ngbNavContent>
            <app-double-check-step
              #step
              (goToNextStep)="goToNextStep()"
              (goToStep)="goToStep($event)"
            ></app-double-check-step>
          </ng-template>
        </li>
        <li [ngbNavItem]="navigationSteps[7].orderNumber">
          <ng-template ngbNavContent>
            <app-payment-step #step (goToNextStep)="goToNextStep()"></app-payment-step>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
  <div [ngbNavOutlet]="nav"></div>
  <app-back-forth-buttons
    [nextProcessing]="stepProcessing$ | async"
    [isBackButtonDisabled]="consultantDataCanNotBeModified$ | async"
    [isFinalStep]="activeStepId === navigationStepLength || isFinalStep"
    (navigateBack)="previousStepClick()"
    (navigateForward)="nextStepClick()"
  ></app-back-forth-buttons>
</div>
