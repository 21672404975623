import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConsultantRoutes } from '@core/constants/consultant-routes';
import { AppState } from '@core/store';
import {
  selectCurrentConsultant,
  selectCurrentConsultantPartiesLoading,
} from '@core/store/consultant';
import { Consultant, Party } from '@core/store/consultant/consultant-state-models';
import { selectParty } from '@core/store/consultant/consultant.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './my-open-parties-pages.component.html',
  styleUrls: ['./my-open-parties-pages.component.scss'],
})
export class MyOpenPartiesPagesComponent implements OnInit {
  currentConsultant$: Observable<Consultant>;
  partiesLoading$: Observable<boolean>;

  myStoryPageRoute = ConsultantRoutes.MyStoryPageRoute;

  constructor(private store$: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.currentConsultant$ = this.store$.select(selectCurrentConsultant);
    this.partiesLoading$ = this.store$.select(selectCurrentConsultantPartiesLoading);
  }

  selectParty(party: Party) {
    this.store$.dispatch(selectParty({ party }));
    this.router.navigateByUrl('/products');
  }
}
