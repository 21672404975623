import produce from 'immer';
import { BlogState } from './blog-state-models';
import { BlogActionTypes } from './blog.actions';

export const initialState: BlogState = {
  blogs: {
    items: [],
    isAllFetched: false,
  },
  blogCategories: [{ title: 'All Blogs', urlName: '' }],
  isBlogCategoriesFetched: false,
  blogDetails: {
    item: null,
    isLoading: false,
  },
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case BlogActionTypes.fetchBlogsSuccess:
      draft.blogs.items = action.isFirstPage
        ? action.items
        : [...draft.blogs.items, ...action.items];
      draft.blogs.isAllFetched = action.isFirstPage
        ? initialState.blogs.isAllFetched
        : action.items.length < action.pageSize;
      return draft;

    case BlogActionTypes.resetBlogs:
      draft.blogs = initialState.blogs;
      return draft;

    case BlogActionTypes.fetchBlogCategories:
      draft.isBlogCategoriesFetched = false;
      return;

    case BlogActionTypes.fetchBlogCategoriesSuccess:
      draft.isBlogCategoriesFetched = true;
      draft.blogCategories = [...initialState.blogCategories, ...action.blogCategories];
      return draft;

    case BlogActionTypes.fetchBlogCategoriesFailure:
      draft.isBlogCategoriesFetched = false;
      return;

    case BlogActionTypes.fetchBlogDetails:
      draft.blogDetails.isLoading = true;
      return draft;

    case BlogActionTypes.fetchBlogDetailsSuccess:
      draft.blogDetails.isLoading = false;
      draft.blogDetails.item = action.item;
      return draft;

    case BlogActionTypes.fetchBlogDetailsFailure:
      draft.blogDetails.isLoading = false;
      return draft;

    case BlogActionTypes.resetBlogDetails:
      draft.blogDetails = initialState.blogDetails;
      return draft;

    default:
      return draft;
  }
}, initialState);
