import { isArray } from './array-utils';

export const isObject = (o) => o === Object(o) && !isArray(o) && typeof o !== 'function';

export function removeNilProperties(obj: any): any {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== null && value !== undefined),
  );
}

export function isNotNil(obj: any): boolean {
  return obj !== null && obj !== undefined;
}
