<ng-container *ngIf="productDetailsItem">
  <div class="row pb-4">
    <div class="col-3 d-none d-md-block">
      <ng-container [ngTemplateOutlet]="reviewFilter"> </ng-container>
    </div>

    <div class="col-md-6 col-sm-12 d-flex flex-column align-items-center">
      <div class="font-size-bigger"
        ><span i18n>Customer Reviews:</span>
        {{ productDetailsItem.rating ? (productDetailsItem.rating | number: '1.1-1') : 0 }}
        <span i18n>out of 5</span></div
      >
      <div>{{ productDetailsItem.numberOfRatings }} <span i18n>ratings </span></div>
    </div>

    <div class="d-block d-md-none col-12 d-flex flex-column align-items-center pt-3">
      <ng-container [ngTemplateOutlet]="reviewFilter"> </ng-container>
    </div>

    <div class="d-none d-md-block col-3" *ngIf="isAuthenticated$ | async">
      <div class="row justify-content-end">
        <ng-container [ngTemplateOutlet]="addReview"></ng-container>
      </div>
    </div>
  </div>

  <app-product-reviews-carousel
    [reviews$]="productReviews$"
    [productDetailsItem]="productDetailsItem"
    (loadNextPage)="onLoadReviewsNextPage($event)"
  ></app-product-reviews-carousel>

  <div
    class="d-block d-md-none col-12 d-flex justify-content-center pt-3"
    *ngIf="isAuthenticated$ | async"
  >
    <ng-container [ngTemplateOutlet]="addReview"></ng-container>
  </div>

  <ng-template #reviewFilter>
    <div ngbDropdown>
      <button class="btn btn-outline-primary" ngbDropdownToggle>{{
        filterLabel[selectedCulture]
      }}</button>
      <div id="sortProductReview" ngbDropdownMenu aria-labelledby="dropdown">
        <button
          *ngFor="let option of reviewFilterOptions"
          ngbDropdownItem
          [ngClass]="{ selected: selectedCulture === option }"
          (click)="fetchReviews(option)"
        >
          {{ filterLabel[option] }}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #addReview>
    <button
      id="add-review-button"
      class="btn btn-primary text-uppercase"
      (click)="onAddReview()"
      i18n
    >
      Add review
    </button>
  </ng-template>
</ng-container>
